import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";
import { ISite } from "../../interfaces/site";
import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { Row, Col } from "react-bootstrap";
import SiteForm from "./SiteForm";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";

const set = new MySettings();

export interface IPersonState {
  loading: boolean;
  data: ISite;
  redirect: string;
}

interface ISitePage extends RouteComponentProps<any>, IPageProps {}
const SitePage: React.FC<ISitePage> = (props) => {
  const [site, setSite] = useState<ISite>({
    connectwiseManageAgreementsId: "",
    connectwiseManageCompaniesId: "",
    county: "",
    id: "",
    line1: "",
    line2: "",
    customersId: "",
    description: "",
    city: "",
    postCode: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const getSite = async () => {
      try {
        if (props.match.params.id !== "new" && isMounted) {
          const siteGetResponse: ISite = await set.siteGet(
            props.match.params.id
          );

          setSite(siteGetResponse);
        } else if (isMounted) {
          setSite((prevState: ISite) => {
            return {
              ...prevState,
              customersId:
                props.resellerID !== undefined ? props.resellerID : "",
              id: "new",
            };
          });
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getSite();

    return () => {
      isMounted = false;
    };
  }, [props.match.params.id, props.resellerID]);

  const submitForm = async (data: ISite) => {
    setLoading(true);

    if (data.id !== "new") {
      try {
        await set.sitesUpdate(data);

        const notification: INotification = {
          title: "Success",
          data: "Site updated.",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "submitForm",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
        props.history.push("../sites");
      } catch (error) {
        console.log(error);
        const notification: INotification = {
          title: "Failed",
          data: error,
          variant: NOTIFICATION_TYPE.danger,
          srcFunction: "submitForm",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      }

      setLoading(false);
    } else {
      try {
        await set.sitesAdd(data);
        const notification: INotification = {
          title: "Success",
          data: "Site created.",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "submitForm",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
        props.history.push("../sites");
      } catch (error) {
        console.log(error);
        const notification: INotification = {
          title: "Failed",
          data: error,
          variant: NOTIFICATION_TYPE.danger,
          srcFunction: "submitForm",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      }
    }
    setLoading(false);
  };

  const deleteSite = async () => {
    const res = window.confirm("Are you sure you want to delete this site?");
    if (res) {
      try {
        setLoading(true);
        await set.sitesRemove(site.id);
        setLoading(false);
        const notification: INotification = {
          title: "Success",
          data: "Site Deleted",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "submitForm",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
        props.history.push("../sites");
      } catch (error) {
        console.log(error);
        const notification: INotification = {
          title: "Failed",
          data: error,
          variant: NOTIFICATION_TYPE.danger,
          srcFunction: "submitForm",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      }
    }
  };

  let sitesURL = "/voip/portal/sites";
  if (props.resellerID) {
    sitesURL = "/voip/portal/reseller/" + props.resellerID + "/sites";
  }
  return loading ? (
    <Loading />
  ) : (
    <div className="content">
      <PageHead
        resellerID={props.resellerID}
        title={site.line1 ? site.line1 : "New Site"}
        parents={[{ title: "Sites", url: sitesURL }]}
      ></PageHead>

      <Row>
        <Col md="12">
          <SiteForm data={site} onSubmit={submitForm} onDelete={deleteSite} />
        </Col>
      </Row>
    </div>
  );
};

export default SitePage;

import React from "react";

import { Field, Form } from "react-final-form";
import { IAccessGroup } from "../../interfaces/access-group";
import { IPerson } from "../../interfaces/people";
import MfaResetButton from "../MfaResetButton";
import UrlButton from "../UrlButton";

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IExtension } from "../../interfaces/extension";
import { ISite } from "../../interfaces/site";
import { IWallboardLayout } from "../../interfaces/wallboards";
import { IAppLoginAttempt } from "../../static/settings";
import AssociateUserButton from "../associateUserButton";

import SelectAdapter from "../misc/form/SelectAdapter";

import PasswordResetButton from "../PasswordResetButton";

import VoipTable from "../VoipTable";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiTextInput from "../MuiComponents/MuiTextInput";
import MuiCheckbox from "../MuiComponents/MuiCheckbox";
import MuiCheckboxList from "../MuiComponents/MuiCheckboxList";

export interface IFormPersonProp {
  data: IPerson;
  loginAttempts: IAppLoginAttempt[];
  accessGroups: IAccessGroup[];
  wallboards: IWallboardLayout[];
  submitFunction: (values: IPerson) => void;
  sites: ISite[];
  extensions?: IExtension[];
  resellerID?: string;
}
const PersonForm: React.FC<IFormPersonProp> = ({
  submitFunction,
  loginAttempts,
  accessGroups,
  sites,
  wallboards,
  data,
  extensions,
  resellerID,
  data: { id, portalAccessGroups, usesMFA },
}) => {
  const col = [
    {
      Header: "Date",
      accessor: "datetime",
      sortable: true,
      Cell: (props: any) => {
        return props.value.replace("T", " ");
      },
      cssClass: "none",
    },
    {
      Header: "Device Name",
      accessor: "deviceName",
      sortable: true,
    },
    {
      Header: "IP",
      accessor: "ipAddress",
      sortable: true,
    },
    {
      Header: "Username",
      accessor: "username",
      sortable: true,
    },
    {
      Header: "Server Hostname",
      accessor: "voipServerHostname",
      sortable: true,
    },
    {
      Header: "Success",
      accessor: "success",
      sortable: true,
      Cell: (props: any) => {
        if (props.value === true) {
          return (
            <div className="text-center">
              <FontAwesomeIcon icon={faCheck} />
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <FontAwesomeIcon icon={faTimes} />
            </div>
          );
        }
      },
    },
  ];

  //PBB 2024-03-24 deal with extensions that no longer exist
  let extensionIds:string[] = []
  if(extensions){
    for(let i=0;i<extensions.length;i++){
      const extension=extensions[i]
      if(data.callRecordingsAllowedExtensions){
        for(let j=0;j<data.callRecordingsAllowedExtensions.length;j++){
          const id=data.callRecordingsAllowedExtensions[j]
          if(id===extension.id){
            extensionIds.push(id)
            break
          }
        }
      }
    }
  }
  data.callRecordingsAllowedExtensions = extensionIds

  return (
    <Form
      onSubmit={submitFunction}
      initialValues={data}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ flexDirection: "row-reverse" }}
            >
              <Typography variant="h6">General</Typography>
            </AccordionSummary>
            <AccordionDetails
            // style={{ flexDirection: "column" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <Typography variant="h6">Personal Infomation</Typography>
                  <br />
                  <MuiTextInput
                    outlinedLabel="Title"
                    name="title"
                    required={false}
                  />
                </Grid>
                <Grid item xs={11}>
                  <MuiTextInput
                    outlinedLabel="First Name"
                    name="firstName"
                    required={false}
                  />
                </Grid>
                <Grid item xs={11}>
                  <MuiTextInput
                    outlinedLabel="Last Name"
                    name="lastName"
                    required={false}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <Typography variant="h6">Contact Info</Typography>
                  <br />
                  <MuiTextInput
                    outlinedLabel="Email Address"
                    name="emailAddress"
                    required={false}
                  />
                </Grid>
                <Grid item xs={11}>
                  <MuiTextInput
                    outlinedLabel="Telephone"
                    name="telephone"
                    required={false}
                  />
                </Grid>
                <Grid item xs={11} style={{ marginLeft: "20px" }}>
                  <MuiCheckbox
                    name="vrSendSMS"
                    label="Send SMS For VIP Receptionist Messages"
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <Typography variant="h6">Address</Typography>
                  <br />
                  <MuiTextInput
                    outlinedLabel="Line 1"
                    name="line1"
                    required={false}
                  />
                </Grid>
                <Grid item xs={11}>
                  <MuiTextInput
                    outlinedLabel="Line 2"
                    name="line2"
                    required={false}
                  />
                </Grid>
                <Grid item xs={11}>
                  <MuiTextInput
                    outlinedLabel="Line 3"
                    name="line3"
                    required={false}
                  />
                </Grid>
              </Grid>
              {sites && sites.length > 0 && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Associated Extension</Typography>
                      <br />
                      <Typography>
                        Please enter the site and number of the extension this person should be associated with.
                      </Typography>
                      <br />
                      <Field
                        name="associatedExtensionSiteId"
                        component="select"
                      >
                        <option value="">None</option>
                        {sites.map((site) => {
                          return (
                            <option key={site.id} value={site.id}>
                              {site.description}
                            </option>
                          );
                        })}
                        <Field
                          name="destination"
                          options={sites}
                          component={SelectAdapter}
                          placeholder="Number"
                        />
                      </Field>
                      <br />
                      <br />
                      <MuiTextInput outlinedLabel="Extension number or device name. E.g 200 or 200tablet." name="associatedExtension" required={false} />
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                </>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ flexDirection: "row-reverse" }}
            >
              <Typography variant="h6">Portal Access</Typography>
              <br />
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <MuiCheckbox name="portalAccess" label="Portal Access" />
                </Grid>
                <Grid item xs={8} style={{paddingTop:'1em'}}>
                  Enabling this will allow the user to log in to the portal.
                </Grid>
                <Grid item xs={4}>
                  <MuiCheckbox name="usesMFA" label="Enable 2FA" />
                </Grid>
                <Grid item xs={8} style={{paddingTop:'1em'}}>
                  Enabling this will force the user to set up a 2FA app or device when they next log in.
                </Grid>
                <Grid item xs={4}>
                  <MuiTextInput outlinedLabel="Password" name="password" required={false}/>
                </Grid>
                <Grid item xs={8} style={{paddingTop:'1em'}}>
                  Use this box to set the password for this user. Leave the box blank if no change is required.
                </Grid>
                <Grid item xs={4}>
                  <PasswordResetButton id={id} />
                </Grid>
                <Grid item xs={8} style={{paddingTop:'2em'}}>
                  Use this button to send a password reset email to this user.
                </Grid>
                <Grid item xs={4}>
                  <MfaResetButton id={id} />
                </Grid>
                <Grid item xs={8} style={{paddingTop:'2em'}}>
                  Use this button to reset the 2FA for a user who has lost their 2FA app or device.
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">Portal Access Groups</Typography>
                  {accessGroups && (
                    <MuiCheckboxList
                      fieldName="portalAccessGroups"
                      data={accessGroups.map((group) => ({
                        label: group.name,
                        value: group.name,
                      }))}
                    />
                  )}
                </Grid>
              </Grid>
              <br />
              <br />
              {extensions && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Call Recordings</Typography>
                    <br />
                    <Typography>
                      Please indicate the extensions from which this user can
                      see call recordings. Leave them all unticked to give them
                      access to all recordings.
                      {extensions && (
                        <MuiCheckboxList
                          fieldName="callRecordingsAllowedExtensions"
                          data={extensions
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((extension) => ({
                              label: extension.callerid,
                              value: extension.id,
                            }))}
                        />
                      )}
                    </Typography>
                    <MuiCheckbox name="callRecordingsBlockPassengerConnect" label="Block this user from seeing passenger connect recordings?" />
                  </Grid>
                </Grid>
              )}
              <br />
              <br />
              {wallboards && wallboards.length > 0 && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Wallboards</Typography>
                    <br />
                    <MuiCheckboxList
                      data={wallboards.map((wallboard) => ({
                        label: wallboard.name,
                        value: wallboard.id,
                      }))}
                      fieldName="wallboards"
                    />
                  </Grid>
                </Grid>
              )}
              <br />
              <br />

              {/* {resellerID && ( */}
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Associate With User</Typography>
                    <AssociateUserButton id={id} buttonLabel="Impersonate this user" openInNewTab={false} />
                  </Grid>
                </Grid>
                <br />
                <br />
              </>
              {/* )} */}

              {/* {id !== "new" && ( */}
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">iOS Companion App</Typography>
                    <br />
                    <Typography>
                      If you have our iOS app installed you can click the button
                      below to generate a URL to add your account to the app.
                      Once you have the URL email it to your iPhone and then
                      click on it. The VIP VoIP companion app should open
                      automatically and add the account.
                    </Typography>
                    <br />
                  </Grid>
                  <UrlButton customersContactsId={id} />
                </Grid>
              </>
              {/* )}   */}
            </AccordionDetails>
          </Accordion>

          <Toolbar style={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitting || pristine}
            >
              {id === "new" ? "Save" : "Update"}
            </Button>
          </Toolbar>

              {(id!=='new' && loginAttempts) && (<Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ flexDirection: "row-reverse" }}
            >
              <Typography variant="h6">App Login Attempts</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <VoipTable
                    sortByStart="datetime"
                    sortReverse={true}
                    title="App Logins"
                    data={loginAttempts}
                    col={col}
                    multiSelect={false}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
            </AccordionDetails>
          </Accordion>)}
        </form>
      )}
    />
  );
};
export default PersonForm;

import React from "react";
import { Card } from "react-bootstrap";

import { Form } from "react-final-form";

import VipTextFinalField from "../../components/misc/form/VipTextFinalField";

const SiteForm = ({ onBack, data, onSubmit, onDelete }: any) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="content test-new-customer-start">
            <Card>
              <Card.Body>
                <fieldset>
                  <div className="form-group">
                    <label>Description</label>

                    <VipTextFinalField
                      fieldName="description"
                      placeholder="Description"
                      required={true}
                    />
                  </div>

                  <div className="form-group">
                    <label>Address line 1</label>
                    <VipTextFinalField
                      fieldName="line1"
                      placeholder="Line 1"
                      required={true}
                    />
                  </div>
                  <div className="form-group">
                    <label>Address line 2</label>
                    <VipTextFinalField
                      fieldName="line2"
                      placeholder="Line 2"
                      required={false}
                    />
                  </div>
                  <div className="form-group">
                    <label>City</label>
                    <VipTextFinalField
                      fieldName="city"
                      placeholder="City"
                      required={true}
                    />
                  </div>

                  <div className="form-group">
                    <label>County</label>
                    <VipTextFinalField
                      fieldName="county"
                      placeholder="County"
                      required={false}
                    />
                  </div>
                  <div className="form-group">
                    <label>Postcode</label>
                    <VipTextFinalField
                      fieldName="postCode"
                      placeholder="Postcode"
                      required={true}
                    />
                  </div>
                  <div className="form-group">
                    <label>UPRN</label>
                    <VipTextFinalField
                      fieldName="uprn"
                      placeholder="UPRN"
                      required={false}
                    />
                  </div>
                </fieldset>
              </Card.Body>
              <Card.Footer>
                <div className="d-flex flex-rowd-flex justify-content-between">
                  {data.id !== "new" && (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={onDelete}
                      disabled={submitting}
                    >
                      Delete
                    </button>
                  )}
                  {onBack && (
                    <button className="btn btn-primary" type="button" onClick={onBack}>Back</button>
                  )}
                  &nbsp;
                  <button
                    className="btn btn-primary noleft"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Submit
                  </button>
                </div>
              </Card.Footer>
            </Card>
          </div>
        </form>
      )}
    />
  );
};

export default SiteForm;

import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import Loading from "./loading";
import { Button, ButtonGroup } from "@material-ui/core";
import { Card } from "react-bootstrap";

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
interface IWizardProps {
  initialValues: any;
  onSubmit(data: any): void;
  menuBottom?: boolean;
  pageMenu?: boolean;
  startIndex?: number;
}
interface IWizardState {
  values: any;
  page: number;
  pageMenu: boolean;
  menuBottom: boolean;
  startIndex: number;
  loading: boolean;
}
class Wizard extends React.Component<IWizardProps, IWizardState> {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }: any) => children;

  constructor(props: any) {
    super(props);

    let menuBottom: boolean = true;
    if (props.menuBottom !== undefined) {
      menuBottom = props.menuBottom;
    }
    let pageMenu: boolean = false;
    if (props.pageMenu !== undefined) {
      pageMenu = props.pageMenu;
    }
    let startIndex: number = 0;
    if (props.startIndex !== undefined) {
      startIndex = props.startIndex;
    }
    this.state = {
      loading: false,
      page: startIndex,
      pageMenu: pageMenu,
      menuBottom: menuBottom,
      startIndex: startIndex,
      values: props.initialValues || {},
    };
  }

  next = (values: any, goto?: string) => {
    if (goto === "SUBMIT_NOW") {
      this.props.onSubmit(values);
    } else {
      const children = React.Children.toArray(this.props.children);
      console.log("Wizard:next", values, goto, children);
      if (children !== undefined && children !== null) {
        if (goto !== undefined) {
          let ind: number;
          if (goto === null) {
            ind = this.state.page;
          } else {
            ind = children.findIndex((something: any) => {
              return something.props.n === goto;
            });
          }

          //  console.log(ind);
          this.setState((state: any) => ({
            loading: false,
            page: ind,
            values,
          }));
        } else {
          this.setState((state: any) => ({
            page: Math.min(state.page + 1, children.length - 1),
            loading: false,
            values,
          }));
        }
      }
    }
  };

  gotoPage = (goto: string) => {
    const children = React.Children.toArray(this.props.children);
    const ind = children.findIndex((something: any) => {
      return something.props.n === goto;
    });
    this.setState((state: any) => ({
      page: ind,
    }));
  };
  previous = () => {
    this.setState((state: any) => ({
      page: Math.max(state.page - 1, 0),
    }));
  };
  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values: any) => {
    const activePage: any = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    if (activePage !== undefined && activePage !== null) {
      return activePage.props.validate ? activePage.props.validate(values) : {};
    }
  };

  handleSubmit = (values: any) => {
    //  console.log("Wizard:handelSubmit", values.SUBMITNOW);
    const activePage: any = React.Children.toArray(this.props.children)[
      this.state.page
    ];

    if (activePage !== undefined && activePage !== null) {
      // if theres a custom on submit
      if (activePage.props.onSubmit !== undefined) {
        this.setState({ loading: true });
        activePage.props.onSubmit(values, (valuesS: any, goto?: string) => {
          values = valuesS;

          const { children, onSubmit } = this.props;
          const { page } = this.state;
          let isLastPage = page === React.Children.count(children) - 1;
          if (values.service === "numberPort") {
            isLastPage = page === React.Children.count(children) - 2;
          }

          if (isLastPage) {
            return onSubmit(values);
          } else {
            this.next(values, goto);
          }
        });
      } else {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        let isLastPage = page === React.Children.count(children) - 1;
        if (values.service === "numberPort") {
          isLastPage = page === React.Children.count(children) - 2;
        }

        if (isLastPage) {
          return onSubmit(values);
        } else {
          this.next(values);
        }
      }
    }
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage: any = React.Children.toArray(children)[page];
    //  console.log(activePage);
    let r: any = null;
    if (activePage.props.render) {
      r = activePage.props.render;
    }

    let isLastPage = page === React.Children.count(children) - 1;
    if (values.service === "numberPort") {
      isLastPage = page === React.Children.count(children) - 2;
    }

    return (
      <Card>
        <Form
          initialValues={values}
          validate={this.validate}
          onSubmit={this.handleSubmit}
          mutators={{
            ...arrayMutators,
          }}
        >
          {({
            handleSubmit,
            form: {
              mutators: { push, pop },
            }, // injected from final-form-arrays above
            pristine,
            form,
            submitting,
            values,
          }) => {
            const something: any[] = React.Children.toArray(
              this.props.children
            ).map((u: any) => {
              //  console.log(u);
              let n: any = {};
              if (u !== null && u !== undefined && u.props !== undefined) {
                if (u.props.n !== undefined) {
                  n.n = u.props.n;
                }
                if (u.props.nt !== undefined) {
                  n.nt = u.props.nt;
                }
              }

              return n;
            });
            //    console.log(something)

            return (
              <form onSubmit={handleSubmit}>
                <>
                  {this.state.loading ? (
                    <>
                      <Loading />
                    </>
                  ) : (
                    <>
                      {!this.state.menuBottom && (
                        <>
                          <div className="d-flex flex-rowd-flex justify-content-between">
                            {page > 0 ? (
                              <Button variant="contained" color="primary" type="button" onClick={this.previous} endIcon={<ArrowBackIcon />}>Previous</Button>
                            ) : (
                              <div></div>
                            )}
                            {this.state.pageMenu ? (
                              <ButtonGroup>
                                {something.map((i: any, index: number) => {
                                  if (index !== this.state.page) {
                                    return (
                                      <Button
                                        key={index}
                                        variant="outlined"
                                        onClick={(e: any) => {
                                          e.preventDefault();
                                          this.gotoPage(i.n); // gotopage
                                        }}
                                      >
                                        {i.nt}
                                      </Button>
                                    );
                                  } else {
                                    return (
                                      <Button key={index} variant="contained">
                                        {i.nt}
                                      </Button>
                                    );
                                  }
                                })}
                              </ButtonGroup>
                            ) : (
                              <></>
                            )}
                            {!isLastPage ? (
                              <Button variant="contained" color="primary" type="submit" endIcon={<ArrowForwardIcon />}>Next</Button>

                            ) : (
                              <Button variant="contained" color="primary" type="submit">Submit</Button>
                            )}
                          </div>
                        </>
                      )}

                      {r !== null ? (
                        <Card.Body>
                          {r(values, push, pop, form, this)}
                        </Card.Body>
                      ) : (
                        <Card.Body>{activePage}</Card.Body>
                      )}

                      {this.state.menuBottom && (
                        <Card.Footer>
                          <div className="d-flex flex-rowd-flex justify-content-between">
                            {page > 0 ? (
                              <Button variant="contained" color="primary" type="button" onClick={this.previous} endIcon={<ArrowBackIcon />}>Previous</Button>

                            ) : (
                              <div></div>
                            )}
                            {this.state.pageMenu ? (
                              <ButtonGroup>
                                {something.map((i: any, index: number) => {
                                  if (index !== this.state.page) {
                                    return (
                                      <Button
                                        key={index}
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                          this.gotoPage(i.n); // gotopage
                                        }}
                                      >
                                        {i.nt}
                                      </Button>
                                    );
                                  } else {
                                    return (
                                      <Button key={index} variant="contained" color="primary">
                                        {i.nt}
                                      </Button>
                                    );
                                  }
                                })}
                              </ButtonGroup>
                            ) : (
                              <></>
                            )}
                            {!isLastPage ? (
                              <Button variant="contained" color="primary" type="submit" endIcon={<ArrowForwardIcon />}>Next</Button>
                            ) : (
                              <Button variant="contained" color="primary" type="submit">Submit</Button>
                            )}
                          </div>
                        </Card.Footer>
                      )}
                    </>
                  )}
                </>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </form>
            );
          }}
        </Form>
      </Card>
    );
  }
}

export default Wizard;

import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import App from "../../App";
import Loading from "../../components/loading";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import MySettings from "../../static/settings";

import { Button, Card, Form } from "react-bootstrap";
import { IVirtualReceptionistMessage } from "../../interfaces/virtualreceptionist";

export interface IPhoneBookNumberFormProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent) => Promise<void>;
  contact: IVirtualReceptionistMessage;
}

interface ParamTypes {
  numbersId: string;
  customersContactsId: string;
}
const set = new MySettings();

const NewCustomer: React.FC<IVirtualReceptionistMessage> = (props) => {
  let history = useHistory();

  const { numbersId } = useParams<ParamTypes>();
  const { customersContactsId } = useParams<ParamTypes>();
  // const [data, setData] = useState("");
  const [message, setMessage] = useState("");
  const [callersName, setCallersName] = useState("");
  //  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  // const [address1, setAddress1] = useState("");
  // const [address2, setAddress2] = useState("");
  // const [address3, setAddress3] = useState("");
  // const [address4, setAddress4] = useState("");
  // const [postcode, setPostcode] = useState("");
  const [telephone, setTelephone] = useState("");
  //  const [fax, setFax] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const payload = {
      callersName,
      jobTitle: "",
      company,
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      postcode: "",
      telephone: "",
      fax: "",
      emailAddress: "",
      message: "",
      id: "",
      customersId: "",
      customersSitesId: "",
      numbersId: numbersId,
      datetime: "",
      customersContactsId: customersContactsId,
    };
    console.log("payload", payload);

    try {
      const data = await set.vrMessageAdd(payload);
      console.log("data", data);
      const notification: INotification = {
        title: "Success",
        data: "Message saved.",
        variant: NOTIFICATION_TYPE.success,
        srcFunction: "onSubmit",
        srcData: window.location.pathname,
        srcComponent: "NewCustomer",
      };
      App.newNotification(notification);
      history.push("/virtualreceptionisthome");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      const notification: INotification = {
        title: "Error",
        data: "Something Went Wrong",
        variant: NOTIFICATION_TYPE.warning,
        srcFunction: "onSubmit",
        srcData: window.location.pathname,
        srcComponent: "NewCustomer",
      };

      App.newNotification(notification);
    }
  };

  return (
    <Form>
      <div style={{ height: "50px" }}></div>
      {loading ? (
        <Loading />
      ) : (
        <Card style={{ width: "500px" }}>
          <Card.Header>
            <h2>New Message</h2>
          </Card.Header>

          <Card.Body className="form-card-body">
            <Form.Group controlId="name">
              <Form.Label>Caller Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Caller Name"
                value={callersName}
                onChange={(e) => setCallersName(e.target.value)}
              />
            </Form.Group>

            {/* <Form.Group controlId="job">
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Job Title"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
              />
            </Form.Group> */}

            <Form.Group controlId="company">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                placeholder="Company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </Form.Group>

            {/* <Form.Group controlId="Address 1">
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address 1"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="Address 2">
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address 2"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
              />
            </Form.Group> 
            <Form.Group controlId="Address 3">
              <Form.Label>Address 3</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address 3"
                value={address3}
                onChange={(e) => setAddress3(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="Address 4">
              <Form.Label>Address 4</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address 4"
                value={address4}
                onChange={(e) => setAddress4(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="postcode">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Postcode"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
              />
            </Form.Group> */}

            <Form.Group controlId="telephone">
              <Form.Label>Telephone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Telephone"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </Form.Group>

            {/* <Form.Group controlId="fax">
              <Form.Label>Fax</Form.Label>
              <Form.Control
                type="text"
                placeholder="fax"
                value={fax}
                onChange={(e) => setFax(e.target.value)}
              />
            </Form.Group> */}

            <Form.Group controlId="emailAddress">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Email Address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                type="text"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Card.Body>

          <Card.Footer className="text-center">
            <Button variant="primary" type="button" onClick={onSubmit}>
              Save
            </Button>
          </Card.Footer>
        </Card>
      )}
    </Form>
  );
};

export default NewCustomer;

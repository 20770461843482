import React, { useState } from "react";

import { ButtonGroup } from "react-bootstrap";

import { Field, Form } from "react-final-form";
import VipTextFinalField from "../misc/form/VipTextFinalField";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const SidebarInvoiceDetails = ({ data, onSubmit }: any) => {
  const [formEna, setFormEna] = useState<boolean>(false);

  const hideSubmit = (payload: any) => {
    setFormEna(false);
    return onSubmit(payload);
  };
  /*
 invoicesalutation:string
    invoicefirstName:string
    invoicelastName:string
    invoicetelephoneNumber:string
    invoiceemailAddress:string


    invoiceaddressLine1:string
    invoiceaddressLine2:string
    invoicecity:string
    invoicecounty:string
    invoicepostCode:string

  */

  const updateData = {
    id: data.id,
    invoicesalutation: data.invoicesalutation,
    invoicefirstName: data.invoicefirstName,
    invoicelastName: data.invoicelastName,
    invoicetelephoneNumber: data.invoicetelephoneNumber,
    invoiceemailAddress: data.invoiceemailAddress,

    invoiceaddressLine1: data.invoiceaddressLine1,
    invoiceaddressLine2: data.invoiceaddressLine2,
    invoicecity: data.invoicecity,
    invoicecounty: data.invoicecounty,
    invoicepostCode: data.invoicepostCode,
    sendAllToCloudManage:data.sendAllToCloudManage ? ['sendAllToCloudManage'] : []
  };
  if (formEna === false) {
    //Not displaying form
    return (
      <div className="sidebarDiv">
        <div className="sidebarTitle">Salutation</div>
        <div className="sidebarText">{data.invoicesalutation}</div>
        <div className="sidebarTitle">First Name</div>
        <div className="sidebarText">{data.invoicefirstName}</div>
        <div className="sidebarTitle">Last Name</div>
        <div className="sidebarText">{data.invoicelastName}</div>
        <div className="sidebarTitle">Telephone</div>
        <div className="sidebarText">{data.invoicetelephoneNumber}</div>
        <div className="sidebarTitle">Email</div>
        <div className="sidebarText">
          <a href={"mailto:" + data.invoiceemailAddress}>
            {" "}
            {data.invoiceemailAddress}
          </a>
        </div>
        <div className="sidebarTitle">Address Line 1</div>
        <div className="sidebarText">{data.invoiceaddressLine1}</div>
        <div className="sidebarTitle">Address Line 2</div>
        <div className="sidebarText">{data.invoiceaddressLine2}</div>
        <div className="sidebarTitle">City</div>
        <div className="sidebarText">{data.invoicecity}</div>
        <div className="sidebarTitle">County</div>
        <div className="sidebarText">{data.invoicecounty}</div>
        <div className="sidebarTitle">Post Code</div>
        <div className="sidebarText">{data.invoicepostCode}</div>
        <div className="side-button">
          <button
            className="btn btn-outline-primary sidebarSubButton"
            onClick={() => setFormEna(true)}
          >
            Edit invoice details
          </button>
        </div>
      </div>
    );
  } else {
    //form
    return (
      <div className="sidebarDiv">
        <Form
          onSubmit={hideSubmit}
          initialValues={updateData}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="sidebarTitle">Salutation</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="invoicesalutation"
                  placeholder="Salutation"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">First Name</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="invoicefirstName"
                  placeholder="First Name"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">Last Name</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="invoicelastName"
                  placeholder="Last Name"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">Telephone</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="invoicetelephoneNumber"
                  placeholder="Telephone"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">Email</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="invoiceemailAddress"
                  placeholder="Email"
                  required={true}
                />
              </div>

              <div className="sidebarTitle">Address Line 1</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="invoiceaddressLine1"
                  placeholder="Address Line 1"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">Address Line 2</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="invoiceaddressLine2"
                  placeholder="Address Line 2"
                  required={false}
                />
              </div>
              <div className="sidebarTitle">City</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="invoicecity"
                  placeholder="City"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">County</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="invoicecounty"
                  placeholder="County"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">Post Code</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="invoicepostCode"
                  placeholder="County"
                  required={true}
                />
              </div>

              <div className="sidebarTitle">Cloud Manage</div>
              <div className="sidebarText">
              <Field name={"sendAllToCloudManage"} value={"sendAllToCloudManage"} type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox {...input} color="primary" required={false} />
                    }
                    label={"Send all invoices lines?"}
                  />
                )}
              </Field>
              </div>

              <div className="side-button">
                <ButtonGroup>
                  <button
                    className="btn btn-primary sidebarFormButton"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setFormEna(false)}
                    className="btn btn-outline-primary sidebarFormButton"
                  >
                    Cancel
                  </button>
                </ButtonGroup>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
};

export default SidebarInvoiceDetails;

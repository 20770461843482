import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import App from "../App";
import Loading from "../components/loading";
import { NOTIFICATION_TYPE } from "../enum/notification-type";
import { INotification } from "../interfaces/notification";
import MySettings from "../static/settings";

const set = new MySettings();

const HelpPage: React.FC<{}> = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData: { notes: string }) => {
    // console.log("formData", formData);

    setLoading(true);

    try {
      await set.portalHelpRequest(formData.notes);
      const notification: INotification = {
        title: "Enquiry Submitted",
        data: "The enquiry has been successfully submitted.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "HelpPage",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification);
    } catch (err) {
      App.newNotificationError("Failed to submit enquiry " + err.data);
    }
    setLoading(false);
  };

  return (
    <div className="content mt-5">
      {loading ? (
        <Loading />
      ) : (
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <Card.Header>
                  <h5 className="my-auto">Need Help?</h5>
                </Card.Header>
                <Card.Body>
                  <p className="mt-3 mb-4">
                    Struggling to figure out how to achieve a certain task? Why not try our
                    video tutorials on <a href="https://www.youtube.com/@vipvoip6607" target="_blank">Youtube</a>.
                  </p>
                  <p className="mt-3 mb-4">
                    If you are having problems using this portal or would just
                    like some guidance then please fill out this form and our
                    support team will contact you.
                  </p>
                  <label>Enquiry:</label>
                  <Field name="notes" component="textarea">
                    {({ input, meta }) => (
                      <>
                        <fieldset className="mainContent">
                          <textarea
                            {...input}
                            id={input.name.replace(".", "_")}
                            className="form-control"
                            rows={3}
                          />
                        </fieldset>
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </>
                    )}
                  </Field>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Button
                    variant="primary"
                    className="noleft"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Submit
                  </Button>
                </Card.Footer>
              </Card>
            </form>
          )}
        />
      )}
    </div>
  );
};
export default HelpPage;

import * as React from "react";
import MySettings, { IOffice365AuthedResponse } from "../static/settings";
import Loading from "../components/loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IPageProps } from "../interfaces/page-props";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Form } from "react-bootstrap";
import App from "../App";
import { NOTIFICATION_TYPE } from "../enum/notification-type";
import { INotification } from "../interfaces/notification";
import { useEffect, useState } from "react";
const set = new MySettings();
interface RouteParams {}
const ActionStepPage: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = () => {
  const [loading, setLoading] = useState(true);
  const [newsettings, setSettings] = useState<IOffice365AuthedResponse>({
    authURL:'',
    authed:false,
    actionStepCreateFileNotes:false,
    actionStepCreateTimeEntries:false
  });

  useEffect(() => {
    let isMounted = true;

    const getSettings = async () => {
      try {
        const data = await set.actionStepIsAuthed();
        if (isMounted) {
          setSettings(data);
          setLoading(false);
        }
      } catch (error) {
        App.newNotificationError(
          "Failed to load Action Step configuration " + error.data
        );
      }
    };

    getSettings();

    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    try {
      const updatedsettings = await set.actionStepUpdateSettings(newsettings);
      setSettings(updatedsettings);
      const notification: INotification = {
        title: "Configuration Updated",
        data: "The Action Step configuration has been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "ActionStepPage",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification);
    } catch (err) {
      App.newNotificationError(
        "Failed to update the Action Step configuration " + err.data
      );
    }
    setLoading(false);
  }

  const onCreateFileNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...newsettings,
      actionStepCreateFileNotes: event.currentTarget.checked,
    })
  }

  const onCreateTimeEntriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...newsettings,
      actionStepCreateTimeEntries: event.currentTarget.checked,
    });
  }

  return (<div className="content">
        {loading === true ? (<Loading />) : (<Row>
          <br />
          <Col md={{ span: 12 }}>
            <Card>
              <Card.Header>Action Step</Card.Header>
              <Card.Body>
                {newsettings.authed ? (
                  <div>Your Action Step account is paired.<br />If you need to unpair click the link below</div>
                ) : (
                  <div>Click the link below to pair your Action Step account to your VIP VoIP account.</div>
                )}
              </Card.Body>
              <Card.Footer style={{textAlign:'center',color:'white'}}>
                {newsettings.authed ? (
                  <a className="btn btn-primary" href="/voip/api/actionstep/redirectv1?code=unpair">Click Here To Unpair</a>
                ) : (
                  <a className="btn btn-primary" href={newsettings.authURL}>Click Here To Pair</a>
                )}
              </Card.Footer>
            </Card>
            <br />
            <Form onSubmit={onSubmit}>
              <Card>
                <Card.Header>Action Step Settings</Card.Header>
                <Card.Body>
                  <Form.Group controlId="actionStepCreateFileNotes">
                    <Form.Label>Create file notes:</Form.Label>
                    <Form.Control
                      type="checkbox"
                      checked={newsettings.actionStepCreateFileNotes}
                      onChange={onCreateFileNotesChange}
                      name="actionStepCreateFileNotes"
                    />
                  </Form.Group>
                  <Form.Group controlId="actionStepCreateTimeEntries">
                    <Form.Label>Create time entries:</Form.Label>
                    <Form.Control
                      type="checkbox"
                      checked={newsettings.actionStepCreateTimeEntries}
                      onChange={onCreateTimeEntriesChange}
                      name="actionStepCreateTimeEntries"
                    />
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Button variant="primary" type="submit">
                    Update Configuration
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
        </Row>)}
      </div>)
}
export default ActionStepPage;

import * as React from 'react';
import { Field } from 'react-final-form';
import makeId from '../../components/misc/form/makeId';
import VipTextFinalField from '../../components/misc/form/VipTextFinalField';
import MySettings from '../../static/settings';

import ScrollToTop from '../../components/misc/ScrollToTop';
import { CircularProgress } from '@material-ui/core';

const validateMe: boolean = true;

const set = new MySettings();

const InvoiceForm = (values: any, push: any, pop: any, form: any, thisX: any) => {

  return <>
<ScrollToTop></ScrollToTop>
    <div className="wizard2__right-heading-div">
      <p className="wizard2__right-heading">Invoice Details</p>
    </div>


    <div className="form-row">
      <div className="form-group col">
        <label className="form-label">Salutation or Title (Mr/Mrs)</label>
        <VipTextFinalField fieldName="invTitle" placeholder="" required={false} />
      </div>
    </div>

    <div className="form-row">
      <div className="form-group col">
        <label className="form-label">First Name</label>
        <VipTextFinalField fieldName="invFirstName" placeholder="" required={validateMe} />

      </div>
    </div>

    <div className="form-row">
      <div className="form-group col">
        <label className="form-label">Last Name</label>
        <VipTextFinalField fieldName="invLastName" placeholder="" required={validateMe} />
      </div>
    </div>



    <div className="form-row">
      <div className="form-group col">
        <label className="form-label">Postcode</label>

        <VipTextFinalField fieldName="invPostCode" placeholder="" required={validateMe}></VipTextFinalField>
      </div>


      <button onClick={(e) => {
        e.preventDefault();
        console.log(values)
        if (!values.invPostCode || values.invPostCode === '') {
          alert("Please enter post code.")
        } else {
          form.change('i_looking_lookup', true);
          setTimeout(() => {
            set.postCodeLookUp(values.invPostCode).then(res => {
              console.log(res);
              form.change('i_looking_lookup', false);
              form.change('invLookup', res)
            }).catch(err => {
              form.change('invManualAddress', true)
              form.change('i_looking_lookup', false);
              alert("Lookup Failed, please enter manually");
            })
          }, 2000)
        }


      }} className="find-address" type="button">
        {values.i_looking_lookup === true ? (<CircularProgress color="primary" />) : ('Find Address')}</button>
    </div>


    {values.invLookup !== undefined && <>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">Choose Address</label>
          <Field component="select" name="lookupIndex">
            {({ input, meta, inputOnChange }) => (
              <>
                <select {...input} id={input.name.replace('.', '_')} className="form-control" autoComplete={"autocomplete_off_hack_" + makeId(5)} onChange={(evt: any) => {
                  const val = evt.currentTarget.value;

                  const chosen: any = values.lookup.items[val];


                  console.log("selAddress", val, chosen);

                  input.onChange(evt);
                  inputOnChange && inputOnChange(evt);
                  if (chosen === undefined) {
                    form.change('invLine1', '')
                    form.change('invLine2', '')
                    //  form.change('postCode', '')
                    form.change('invCounty', '')
                    form.change('invCity', '')
                  } else {
                    form.change('invLine1', chosen.line1)
                    form.change('invLine2', chosen.line2)
                    //    form.change('postCode', chosen.postCode)
                    form.change('invCounty', chosen.county)
                    form.change('invCity', chosen.city)
                  }

                }}>
                  <option></option>
                  {values.invLookup.items.map((map: any, index: number) => {
                    return <option key={index} value={index}>{map.line1}, {map.line2}, {map.city}</option>
                  })}
                </select>
                {meta.error && meta.touched && <span>{meta.error}</span>}
              </>
            )}
          </Field>
        </div>
      </div>
    </>}




    {values.invManualAddress !== true && <>
      <div className="form-row">
        <p onClick={(e) => {
          e.preventDefault();
          form.change('invManualAddress', !values.invManualAddress)

        }} className="enter-address-manual">Enter address manually</p>
      </div>
    </>}






    {values.invManualAddress === true && <>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">Address line 1</label>

          <VipTextFinalField fieldName="invLine1" placeholder="" required={validateMe}></VipTextFinalField>
        </div>
      </div>



      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">Address line 2</label>
          <VipTextFinalField fieldName="invLine2" placeholder="" required={false} />

        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">City</label>
          <VipTextFinalField fieldName="invCity" placeholder="" required={validateMe} />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">County</label>
          <VipTextFinalField fieldName="invCounty" placeholder="" required={validateMe} />
        </div>
      </div>

    </>}





    <div className="form-row">
      <div className="form-group col">
        <label className="form-label">Phone Number</label>

        <VipTextFinalField fieldName="invPhone" placeholder="" required={validateMe}></VipTextFinalField>
      </div>
    </div>




    <div className="form-row">
      <div className="form-group col">
        <label className="form-label">Email Address</label>

        <VipTextFinalField fieldName="invEmail" placeholder="" required={validateMe}></VipTextFinalField>
      </div>
    </div>
  </>
}


export default InvoiceForm
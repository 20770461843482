import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import {
  IPhonebookContact,
  IPhonebookNumbersHttpResponse,
} from "../../interfaces/phonebook-number";
import { IPhonebook } from "../../interfaces/phonebook";

import PageHead from "../../components/PageHead";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";

import { RouteComponentProps } from "react-router-dom";

import Error from "../../components/error";
import App from "../../App";

import { IconButton, Grid } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";

import MuiDropdownButton, { IDropdownButtonProps } from "../../components/MuiComponents/MuiDropdownButton";
const set = new MySettings();

interface RouteParams {
  id: string;
}

enum PageState {
  ShowTable,
  Loading,
  NetworkError,
  NoResults,
}
const PhoneBook: React.FC<RouteComponentProps<RouteParams> & IPageProps> = ({
  match,
  resellerID,
  session,
}) => {


  const [pageState, setPageState] = useState(PageState.Loading);



  const [phoneBook, setPhoneBook] = useState<IPhonebook>({
    id: "",
    safeCustomersName: "",
    customersId: "",
    name: "",
    syncWith365: false,
  });
  const [numbers, setNumbers] = useState<IPhonebookContact[]>([]);


  // console.log("STATE", phoneBook);
  // console.log("PROPS", props);

  useEffect(() => {
    let isMounted = true;

    const getNumbers = async () => {
      try {
        const data: IPhonebookNumbersHttpResponse = await set.getPhoneBook(
          match.params.id
        );
        if (isMounted) {
          setNumbers(data.records);
          setPhoneBook(data.phonebook);

          setPageState(PageState.ShowTable);
        }
      } catch (error) {
        console.log(error);
        setPageState(PageState.NetworkError);
      }
    };

    getNumbers();

    return () => {
      isMounted = false;
    };
  }, [match.params.id]);

  const handleNumberDelete = async (phoneBookNumberID: string) => {
    if (
      window.confirm(
        "Are you sure you want to remove this entry from the phone book?"
      )
    ) {
      try {

        await set.deletePhoneBookRecordEntry(phoneBookNumberID);

        setNumbers(numbers.filter((number) => number.id !== phoneBookNumberID));

        const notification: INotification = {
          title: "Success",
          data: "Phonebook Contact Deleted",
          variant: NOTIFICATION_TYPE.success,
          srcFunction: "handleNumberDelete",
          srcData: window.location.pathname,
          srcComponent: "PhoneBook",
        };

        App.newNotification(notification);
      } catch (error) {
        console.log(error);
        const notification: INotification = {
          title: "Error",
          data: "Something Went Wrong",
          variant: NOTIFICATION_TYPE.warning,
          srcFunction: "handleNumberDelete",
          srcData: window.location.pathname,
          srcComponent: "PhoneBook",
        };

        App.newNotification(notification);
      }
     // setLoading(false);
    }
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "number1",
      label: "Number 1",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "number2",
      label: "Number 2",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "number3",
      label: "Number 3",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "id",
      label: " ",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ align: "right" }),
        customBodyRenderLite: (dataIndex: number) => {
          const element = numbers[dataIndex];

          let numberEditLink =
            "../phonebookNumber/" + phoneBook.id + "/" + element.id;

          return <><IconButton color="primary" type="button" href={numberEditLink}>
            <EditIcon /></IconButton>
            <IconButton color="error" type="button" onClick={(ev) => {
              ev.preventDefault();
              element.id && handleNumberDelete(element.id);

            }}>
              <DeleteIcon /></IconButton>
          </>
        }
      },
    },
  ];

  let newurl = "/phonebooknumber/" + phoneBook.id + "/new";
  let ldapurl = "/phonebooksettings/" + phoneBook.id;
  let entriesUrl = "/phonebookupload/" + phoneBook.id;
  if (resellerID && resellerID.length > 0) {
    newurl = "/reseller/" + resellerID + newurl;
    ldapurl = "/reseller/" + resellerID + ldapurl;
    entriesUrl = "/reseller/" + resellerID + entriesUrl;
  }





  const RenderSwitch = () => {
    switch (pageState) {
      case PageState.Loading:
        return <Loading />;

      case PageState.ShowTable:
        return (
          <MuiTableWrapper
            title=""
            data={numbers}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: false,
              search: true,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "name",
                direction: "asc",
              },
            }}
          />
        );


      case PageState.NetworkError:
        return <Error />;
      default:
        return <></>;
    }
  };
  const dropdownOptions: IDropdownButtonProps["options"] = [];

    //         {
    //           title: "New Contact",
    //           link: newurl,
    //           isExternal: false,
    //         },
    //         {
    //           title: "365 And LDAP Settings",
    //           link: ldapurl,
    //           isExternal: false,
    //         },
    //         {
    //           title: "Upload Entries",
    //           link: entriesUrl,
    //           isExternal: false,
    //         },
  dropdownOptions.push({
    label: "New Contact",
    link: newurl,
    target: "",
  });
  dropdownOptions.push({
    label: "365 And LDAP Settings",
    link: ldapurl,
    target: "",
  });
  dropdownOptions.push({
    label: "Upload Entries",
    link: entriesUrl,
    target: "",
  });

  return (<Grid container spacing={3} alignContent="flex-start">
  <Grid item xs={12}>
    <PageHead
     resellerID={resellerID}
     title={
       phoneBook !== undefined && phoneBook.name !== undefined
         ? phoneBook.name
         : ""
     }
     parents={[{ title: "Phone Books", url: "../PhoneBooks" }]}
      subpages={[]}
    >
      <MuiDropdownButton label="More Options" options={dropdownOptions} />
    </PageHead>
  </Grid>


  <Grid item xs={12}>
    <RenderSwitch />
  </Grid>
</Grid>



    // <div className="content">
    //   <PageHead
    //     resellerID={resellerID}
    //     title={
    //       phoneBook !== undefined && phoneBook.name !== undefined
    //         ? phoneBook.name
    //         : ""
    //     }
    //     parents={[{ title: "Phone Books", url: "../PhoneBooks" }]}
    //   >
    //     <VipBurgerMenu
    //       options={[
    //         {
    //           title: "New Contact",
    //           link: newurl,
    //           isExternal: false,
    //         },
    //         {
    //           title: "365 And LDAP Settings",
    //           link: ldapurl,
    //           isExternal: false,
    //         },
    //         {
    //           title: "Upload Entries",
    //           link: entriesUrl,
    //           isExternal: false,
    //         },
    //       ]}
    //     />
    //   </PageHead>

    //   {loading ? (
    //     <Loading />
    //   ) : error ? (
    //     <Error />
    //   ) : numbers.length > 0 ? (
    //     <>
    //       {resellerID === undefined &&
    //         session.userType === "resellersContacts" ? (
    //         <ResellerRedirect />
    //       ) : (
    //         <MuiTableWrapper
    //         title=""
    //         data={numbers}
    //         columns={columns}
    //         options={{
    //           filterType: "checkbox",
    //           selectableRows: "none",
    //           responsive: "standard",
    //           selectToolbarPlacement: "none",

    //           elevation: 0,
    //           print: false,
    //           download: false,
    //           search: true,
    //           filter: false,
    //           viewColumns: false,
    //           sortOrder: {
    //             name: "name",
    //             direction: "asc",
    //           },
    //         }}
    //       />
    //       )}
    //     </>
    //   ) : (
    //     <div className="text-center">
    //       <img src={image} className="img-fluid" alt="No Results Found" />
    //       <br />
    //       <br />
    //       <h2>No contacts found</h2>
    //       <p>This phone book has no contacts. Would you like to add one?</p>
          

    //         <Button href={newurl} variant="outlined" className="mt-4" startIcon={ <PersonAddIcon />}>
             
    //           New Contact
    //         </Button>

    //     </div>
    //   )}
    // </div>
  );
};
export default PhoneBook;

import React, { useState } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Button, ProgressBar } from "react-bootstrap";
import Status from "./Status";
import Loading from "../../components/loading";

const WizardClean: React.FC<any> = ({
  onSubmit,
  children,
  status,
  loading,
}) => {
  const [page, setPage] = useState(0);
  const [values, setValues] = useState<any | undefined>(undefined);
  const activePage = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;

  const complete = (page / (React.Children.count(children) - 1)) * 100;

  const next = (values: any) => {
    setPage(Math.min(page + 1, React.Children.count(children)));
    setValues(values);
  };

  const previous = () => {
    setPage(Math.max(page - 1, 0));
  };

  const handleSubmit = (values: any) => {
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    } else {
      next(values);
    }
  };

  return (
    <Form
      initialValues={values}
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({
        handleSubmit,
        submitting,
        values,
        form,
        form: {
          mutators: { push, pop },
        },
      }) => (
        <form
          onSubmit={handleSubmit}
          className="wizard2__body  container-wizard"
        >
          <div className="wizard1">
            <div className="status">
              <div className="status__top">
                <ul>
                  <p className="status__heading">Service Setup</p>
                  {values.service === "voipService" && (
                    <Status
                      page={page}
                      setPage={setPage}
                      array={status.voipService}
                    />
                  )}
                  {values.service === "numberPort" && (
                    <Status
                      page={page}
                      setPage={setPage}
                      array={status.numberPort}
                    />
                  )}
                  {values.service === "voipIntergration" && (
                    <Status
                      page={page}
                      setPage={setPage}
                      array={status.voipIntergration}
                    />
                  )}
                  {values.service === "buyPhones" && (
                    <Status
                      page={page}
                      setPage={setPage}
                      array={status.buyPhones}
                    />
                  )}
                  {values.service === "internetServiceEnquiry" && (
                    <Status
                      page={page}
                      setPage={setPage}
                      array={status.internetServiceEnquiry}
                    />
                  )}
                  {values.service === "virtualReceptionistAdd" && (
                    <Status
                      page={page}
                      setPage={setPage}
                      array={status.virtualReceptionistAdd}
                    />
                  )}
                  {values.service === "resellerTeamsCallRecordingAdd" && (
                    <Status
                      page={page}
                      setPage={setPage}
                      array={status.resellerTeamsCallRecordingAdd}
                    />
                  )}
                </ul>
              </div>

              <div className="status__bottom">
                <div>
                  <div className="status__progress">
                    <p>Progress</p>
                    <p>{complete.toFixed(0)}%</p>
                  </div>

                  <div>
                    <ProgressBar now={complete} />
                  </div>
                </div>
              </div>
            </div>

            <div className="wizard1__main">
              <div className="wizard1__main-post">
                {loading ? (
                  <Loading />
                ) : activePage.props.render ? (
                  <>{activePage.props.render(values, push, pop, form)}</>
                ) : (
                  <>{activePage}</>
                )}

                <div className="wizard2__right-buttons">
                  {page > 0 && (
                    <Button
                      type="button"
                      variant="outline-primary"
                      className="button__back"
                      onClick={previous}
                    >
                      Back
                    </Button>
                  )}
                  {!isLastPage && (
                    <Button className="button__next" type="submit">
                      Next
                    </Button>
                  )}
                  {isLastPage && (
                    <Button
                      type="submit"
                      className="button__next"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </form>
      )}
    </Form>
  );
};

export default WizardClean;

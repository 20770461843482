import React, { useState, useEffect } from "react";
import { IPageProps } from "../../interfaces/page-props";
import {
  IMeetingRoom,
  IMeetingRoomGetResponse,
} from "../../interfaces/meeting-rooms";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import MySettings from "../../static/settings";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";

import { parseISO } from "date-fns";
import { Card } from "react-bootstrap";

const set = new MySettings();

const VoipServiceMeetingRoom: React.FC<IPageProps> = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [meetingRoom, setMeetingRoom] = useState<IMeetingRoom>();

  // console.log("PBB", props);
  // console.log("ROOM", meetingRoom);

  useEffect(() => {
    let mounted = true;

    const getMeetingRoom = async () => {
      try {
        const result: IMeetingRoomGetResponse = await set.meetingRoomGet(
          props.match.params.id
        );
        console.log("RESULT", result);
        if (mounted) {
          setIsLoading(false);
          setMeetingRoom(result.item);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getMeetingRoom();
    return () => {
      mounted = false;
    };
  }, [props.match.params.id]);

  return (
    <div className="content">
      {isLoading || meetingRoom === undefined ? (
        <Loading />
      ) : (
        <>
          <PageHead
            title={meetingRoom.name}
            resellerID={props.resellerID}
            parents={[
              {
                title: "Meeting Rooms",
                url: `/meetingrooms/`,
              },
            ]}
          ></PageHead>
          <Card>
            <div className="text-center">
              <Card.Body>
                {meetingRoom.ddis.some((ddi) => ddi.name !== "") && (
                  <ul>
                    <h5>DDIs:</h5>
                    {meetingRoom.ddis.map((ddi) => (
                      <li key={ddi.name + ddi.pin}>
                        DDI: {ddi.name}, PIN: {ddi.pin}
                      </li>
                    ))}
                  </ul>
                )}
                {meetingRoom.internalDDIs.some((ddi) => ddi.name !== "") && (
                  <ul>
                    <h5>Internal DDIs:</h5>
                    {meetingRoom.internalDDIs.map((ddi) => (
                      <li key={ddi.name}> DDI: {ddi.name}</li>
                    ))}
                  </ul>
                )}
                {meetingRoom.invites && (
                  <ul>
                    <h5>Email Invites:</h5>
                    {meetingRoom.invites.map((invite) => (
                      <li key={invite}>{invite}</li>
                    ))}
                  </ul>
                )}

                {meetingRoom.startDateTime && (
                  <>
                    <h5>Scheduled At:</h5>
                    <div>{parseISO(meetingRoom.startDateTime).toString()}</div>
                  </>
                )}
              </Card.Body>
              <Card.Footer>
                <a
                  href={meetingRoom.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="outline-primary" className="mb-2 noleft">
                    <FaIcon icon="user-plus" color="primary" className="mr-1" />
                    Join This Meeting
                  </Button>
                </a>
              </Card.Footer>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default VoipServiceMeetingRoom;

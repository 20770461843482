import * as React from "react";
import { format } from "date-fns";
import { IPageProps } from "../interfaces/page-props";
import { RouteComponentProps } from "react-router-dom";
import MuiTableWrapper from "../components/MuiComponents/MuiTableWrapper";
import PageHead from "../components/PageHead";
import { Button, Grid } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Form,Field } from "react-final-form";
import { FormApi, SubmissionErrors } from "final-form";
import DateFnsUtils from "@date-io/date-fns";
interface RouteParams {}
interface AuditItem {
  datetime:string
  event:string
  user:string
  ip:string
  data:string
}

const PBXAuditTrailPage: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = () => {

  const data:AuditItem[]=[{datetime:'2023-06-01T09:01:12',event:'Login',user:'david@microlink.com',ip:'192.168.1.1',data:''},
  {datetime:'2023-06-01T09:04:33',event:'Remove',user:'david@microlink.com',ip:'192.168.1.1',data:'type=user&number=2001'},
  {datetime:'2023-06-01T10:00:04',event:'Logout',user:'david@microlink.com',ip:'192.168.1.1',data:''}]
  const columns=[{
    name: "datetime",
    label: "Date",
    options: {
      filter: true,
      sort: true,
      customBodyRenderLite: (dataIndex: number) => {
        const element = data[dataIndex];
        return format(new Date(element.datetime), "yyyy-MM-dd HH:mm:ss");
      },
    },
  },{
    name:'event',label:'Event'
  },{
    name:'user',label:'User'
  },{
    name:'ip',label:'IP'
  },{name:'data',label:'Data'}]

  return (<div className="content">
        <PageHead
          resellerID=""
          title="Microlink Audit Trail"
          parents={[]}
          subpages={[]}
        >
        </PageHead>
        <Form render={() => (
      <form>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3}>

          <Grid item xs={2}>
            <Field name="startDate">
              {({ input: { onChange, value } }) => (
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={value}
                  onChange={onChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }} />
              )}
            </Field>
          </Grid>

          <Grid item xs={2}>
            <Field name="startTime">
              {({ input: { onChange, value } }) => (
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  value={value}
                  onChange={onChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }} />
              )}
            </Field>
          </Grid>

          <Grid item xs={1} />

          <Grid item xs={2}>
            <Field name="endDate">
              {({ input: { onChange, value } }) => (
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={value}
                  onChange={onChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }} />
              )}
            </Field>
          </Grid>

          <Grid item xs={2}>
            <Field name="endTime">
              {({ input: { onChange, value } }) => (
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  value={value}
                  onChange={onChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }} />
              )}
            </Field>
          </Grid>

          <Grid item xs={1} />

          <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Search
                </Button>
              </Grid>
        </Grid>
        </MuiPickersUtilsProvider>
      </form>
   ) } onSubmit={function (values: Record<string, any>, form: FormApi<Record<string, any>, Partial<Record<string, any>>>, callback?: ((errors?: SubmissionErrors) => void) | undefined): void | SubmissionErrors | Promise<SubmissionErrors> {
      throw new Error("Function not implemented.");
    } } />

  
<br />
        



        <MuiTableWrapper options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: false,
              search: false,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "datetime",
                direction: "asc",
              },
            }} columns={columns} data={data} title={undefined}  />
      </div>)
}
export default PBXAuditTrailPage;

import React, { ChangeEvent } from "react";
import { Field, FormSpy } from "react-final-form";

import {  FormControl,  FormHelperText,  IconButton,  MenuItem, Paper, Select,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldArray } from "react-final-form-arrays";
import App from "../../../App";
import { INotification } from "../../../interfaces/notification";
import MySettings from "../../../static/settings";
import { NOTIFICATION_TYPE } from "../../../enum/notification-type";
import GetSiteOptionsMui from "../../../components/MuiComponents/getOptionsMui";


const set = new MySettings();
interface iFindNumbers2Prop {
    customers: any[]
}
const FindNumbers2 = ({ customers }: iFindNumbers2Prop) => {



    return <>

        <FormSpy
            subscription={{
                // dirtySinceLastSubmit: true,
                // submitting: true,

                values: true
            }}
        >
            {({ values, form }) => (
                <>


                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="asLabel">#</TableCell>
                                    <TableCell className="asLabel">Number</TableCell>
                                    <TableCell className="asLabel">Customer</TableCell>
                                    <TableCell className="asLabel">Site</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray name="numbers">
                                    {({ fields }) =>
                                        fields.map((number, index) => (
                                            <TableRow key={number}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{values.numbers[index] && values.numbers[index].number}</TableCell>
                                                <TableCell>
                                                    <Field
                                                        component="select"
                                                        name={`${number}.customersId`}
                                                    >
                                                        {({ input, meta }) => (
                                                            <FormControl fullWidth>
                                                              
                                                                <Select
                                                                fullWidth
                                                                   {...input}
                                                                    onChange={(event: ChangeEvent<{ value: unknown }>) => {
                                                                        input.onChange(event.target.value as string);
                                                                    
                                                                    }}
                                                                    id={input.name.replace(".", "_")}
                                                                    className="form-control"
                                                                >
                                                                    <MenuItem></MenuItem>
                                                                    {customers.map((customer) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={customer.id}
                                                                                key={customer.id}
                                                                            >
                                                                                {customer.name}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {meta.error && meta.touched && (
                                                                    <FormHelperText>{meta.error}</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </TableCell>
                                                <TableCell>
                                                    <Field
                                                        component="select"
                                                        name={`${number}.customersSitesId`}
                                                    >
                                                        {({ input, meta, inputOnChange }) => (
                                                            <>
                                                                {GetSiteOptionsMui({
                                                                    servicesOnly: true,
                                                                    onEmpty: () => {
                                                                        alert("This customer has no active VoIP services. If you want to allocate a number for this customer you will have to request a voip service for them first from their customer record.")
                                                                    },
                                                                    customerID:
                                                                        values.numbers[index].customersId,
                                                                    input: input,
                                                                    meta: meta,
                                                                    addBlank: true,
                                                                    inputOnChange: inputOnChange,
                                                                    onChange: (val: string) => {
                                                                        //console.log("onchange",, values.number.customersSitesId);
                                                                        //s console.log(val, prevVal);

                                                                        set
                                                                            .siteGet(val)
                                                                            .then((site) => {
                                                                                form.change(
                                                                                    `${number}.customersSites`,
                                                                                    site
                                                                                ); // listeners not notified#
                                                                            })
                                                                            .catch((exception) => {
                                                                                const notification: INotification =
                                                                                {
                                                                                    title: "Error",
                                                                                    data: exception.data,
                                                                                    variant:
                                                                                        NOTIFICATION_TYPE.danger,
                                                                                    srcFunction:
                                                                                        "componentDidUpdate",
                                                                                    srcData:
                                                                                        window.location.pathname,
                                                                                    srcComponent: "App",
                                                                                };
                                                                                App.newNotification(notification);

                                                                                //  input.onChange(evt);
                                                                                //   inputOnChange && inputOnChange(evt);
                                                                            });
                                                                    },
                                                                })}
                                                            </>
                                                        )}
                                                    </Field>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton

                                                        color="secondary"
                                                        onClick={() => fields.remove(index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </FieldArray>
                            </TableBody>
                        </Table>
                    </TableContainer>


                </>)}
        </FormSpy>
    </>
}

export default FindNumbers2;
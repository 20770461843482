import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { IPhonebookContact } from "../../interfaces/phonebook-number";

export interface IPhoneBookNumberFormProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent) => Promise<void>;
  contact: IPhonebookContact;
}

const PhoneBookNumberForm: React.FC<IPhoneBookNumberFormProps> = ({
  contact,
  handleChange,
  onSubmit,
}) => {
  // console.log("FORM PROPS", props);
  return (
    <Form onSubmit={onSubmit}>
      <Card>
        <Card.Header>
          <h2>Phonebook Contact</h2>
        </Card.Header>

        <Card.Body className="form-card-body">
          <h5>Personal Infomation</h5>

          <Form.Group controlId="name">
            <Form.Label>Contact Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Contact Name"
              value={contact.name}
              onChange={handleChange}
            />
          </Form.Group>

          <h5>Contact Info</h5>

          <Form.Group controlId="number1">
            <Form.Label>First Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Number"
              value={contact.number1}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="number2">
            <Form.Label>Second Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Second Number"
              value={contact.number2}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="number3">
            <Form.Label>Third Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Third Number"
              value={contact.number3}
              onChange={handleChange}
            />
          </Form.Group>
        </Card.Body>

        <Card.Footer className="text-center">
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default PhoneBookNumberForm;

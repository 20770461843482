import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";
import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { Row, Col } from "react-bootstrap";
import PageHead from "../../components/PageHead";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { IExtension } from "../../interfaces/extension";
import Error from "../../components/error";
import App from "../../App";
import { RouteComponentProps } from "react-router-dom";
import {
  IVoiceRouting,
  IVoiceRoutingHttpResponse,
} from "../../interfaces/voiceroutings";
import FormVoiceRouting from "../../components/oldForms/form-voice-routing";

const settings = new MySettings();

interface MatchParams {
  id: string;
  customersSitesServicesId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export interface IVoiceRoutingState {
  report: IVoiceRouting;
  extensions: IExtension[];
}

const newVoiceRouting: IVoiceRouting = {
  customersSitesServicesId: "",
  id: "", // unique id, generated by the server
  destination: "", // where the call will be routed
  text: "", // the text to match
};

const VoiceRouting: React.FC<IPageProps & Props> = (props) => {
  const [data, setData] = useState<IVoiceRoutingState>({
    report: newVoiceRouting,
    extensions: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const getReport = async (
      reportId: string,
      customersSitesServicesId: string
    ) => {
      try {
        if (reportId !== "new") {
          const resultOfVoiceRoutingGet: IVoiceRoutingHttpResponse =
            await settings.voiceRoutingGet(reportId);

          const resultOfExtensionsGet = await settings.extensionsGet(
            resultOfVoiceRoutingGet.item.customersSitesServicesId,[]
          );

          if (isMounted) {
            setData((currentState) => ({
              ...currentState,
              report: resultOfVoiceRoutingGet.item,
              extensions: resultOfExtensionsGet.items,
            }));
          }
        } else {
          const resultOfExtensionsGet = await settings.extensionsGet(
            customersSitesServicesId,[]
          );

          if (isMounted) {
            setData((currentState) => ({
              ...currentState,

              extensions: resultOfExtensionsGet.items,
              report: {
                ...newVoiceRouting,
                customersSitesServicesId: customersSitesServicesId,
              },
            }));
          }
        }
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    };

    getReport(
      props.match.params.id,
      props.match.params.customersSitesServicesId
    );

    return () => {
      isMounted = false;
    };
  }, [
    props.match.params.id,
    props.match.params.customersSitesServicesId,
    props.resellerID,
  ]);

  const makeNotification = (note: INotification) => {
    return App.newNotification(note);
  };

  const handleSubmit = async (): Promise<void> => {
    let voiceRoutingsUrl = "../voiceroutings";

    setLoading(true);
    if (data.report.id === "") {
      // console.log("add call", data);
      try {
        const resultOfVoiceRoutingsAdd = await settings.voiceRoutingsAdd(
          data.report
        );
        {
          const alert: INotification = {
            title: "Success",
            data: "Routing Created!",
            variant: NOTIFICATION_TYPE.success,
            srcComponent: "VoiceRouting",
            srcData: "success",
            srcFunction: "handleSubmit",
          };
          makeNotification(alert);

          setData((currentState) => ({
            ...currentState,
            data: resultOfVoiceRoutingsAdd,
          }));

          props.history.push(voiceRoutingsUrl);
        }
      } catch (error) {
        setLoading(false);
        const alert: INotification = {
          title: "Problem",
          data: error,
          variant: NOTIFICATION_TYPE.danger,
          srcComponent: "VoiceRouting",
          srcData: "error",
          srcFunction: "handleSubmit",
        };
        makeNotification(alert);
      }
    } else {
      //update an existing routing
      try {
        const resultOfVoiceRoutingsUpdate = await settings.voiceRoutingsUpdate(
          data.report
        );
        {
          const alert: INotification = {
            title: "Success",
            data: "Routing Saved!",
            variant: NOTIFICATION_TYPE.success,
            srcComponent: "VoiceRoute",
            srcData: "success",
            srcFunction: "handleSubmit",
          };
          makeNotification(alert);

          setData((currentState) => ({
            ...currentState,
            data: resultOfVoiceRoutingsUpdate,
          }));

          props.history.push(voiceRoutingsUrl);
        }
      } catch (error) {
        setLoading(false);
        const alert: INotification = {
          title: "Problem",
          data: error,
          variant: NOTIFICATION_TYPE.danger,
          srcComponent: "VoiceRouting",
          srcData: "error",
          srcFunction: "handleSubmit",
        };
        makeNotification(alert);
      }

      setLoading(false);
    }
  };

  //this bit is needed otherwise when you edit a form field nothing happens
  const reportUpdateHandler = (field: string, value: any): void => {
    const copy = { ...data.report, [field]: value };
    setData((currentState) => ({
      ...currentState,
      report: copy,
    }));
  };

  let title: string;
  if (data.report.id.length > 0) {
    title = data.report.text;
  } else {
    title = "New Routing";
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="content">
        <PageHead
          resellerID={props.resellerID}
          title={title}
          parents={[{ title: "Voice Routings", url: "../voiceroutings" }]}
        ></PageHead>

        {error ? (
          <Error />
        ) : (
          <Row>
            <Col md="12">
              <FormVoiceRouting
                data={data}
                handleSubmit={handleSubmit}
                reportUpdateHandler={reportUpdateHandler}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default VoiceRouting;

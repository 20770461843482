import { INotification } from "./interfaces/notification";
import { NOTIFICATION_TYPE } from "./enum/notification-type";
import App from "./App";

const catchMe = (thisx: any, exception: any, redirect: boolean, error?: boolean) => {
    if (error === undefined) {
        error = true;
    }
    thisx.setState({ loading: false, error: true });
    const notification: INotification = { title: "Error", data: exception.data, variant: NOTIFICATION_TYPE.danger, srcFunction: "componentDidUpdate", srcData: window.location.pathname, srcComponent: 'App' };
    if(exception.data === 'Not logged in.'){
        notification.srcComponent='LOGGEDOUT';
    }
    
    
    App.newNotification(notification)
    console.log(exception)
    if (redirect === true || exception.data === 'Not logged in.') {
        console.log(thisx.props.history)

        if (thisx.props.reloadMenu) {
            thisx.props.reloadMenu().then((ee: any) => {
                console.log(ee);
        //        setTimeout(() => {
                    if (thisx.props.history) {
                        let url = '/';
                        if (exception.data === 'Not logged in.') {
                            url = '/login?loop=1';
                        }
                        console.log(url)
                        thisx.props.history.push(url);
                    }
      //          }, 500)

            })
        }

    }

};

export default catchMe


import MySettings from "../../static/settings";
import { IPageProps } from "../../interfaces/page-props";
import { IRetailTariffBand } from "../../interfaces/customer";
import { RouteComponentProps } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Loading from "../../components/loading";
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";
import PageHead from "../../components/PageHead";
import { Grid } from "@material-ui/core";
import Error from "../../components/error";
enum PageState {
  ShowTable,
  Loading,
  NetworkError,
  NoResults,
}
const set = new MySettings();

interface IStandardTaiffState {
  bands: IRetailTariffBand[];
}

interface IStandardTariffPage extends RouteComponentProps<any>, IPageProps { }


const StandardTariff = (props: IStandardTariffPage) => {
  const [pageState, setPageState] = useState(PageState.Loading);
  const [data, setData] = useState<IStandardTaiffState>();


  const isMounted = useRef(true);

  useEffect(() => {
    const getStuff = async () => {
      const tarifs = await set
        .resellerStandardRetailTariff()


      setData({ ...data, bands: tarifs.items })

      setPageState(PageState.ShowTable);

    }
    if (isMounted.current && !data?.bands.length) {
      try {
        getStuff();
      } catch (error) {
        setPageState(PageState.NetworkError);
      }
    }

    return () => {
      isMounted.current = false;
    };
  }, [data, pageState, props.resellerID, props.session.userType]);


  /*
      {
          Header: "Description",
          accessor: "description",
          sortable: true,
        },
        {
          Header: "Price",
          accessor: "price",
          sortable: true,
        },
        {
          Header: "Pence Per Call",
          accessor: "pencePerCall",
          sortable: true,
        },
  */


  const columns = [
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "price",
      label: "Price Per Minuite",
      options: {
        filter: false,
        sort: true,

      },
    },
    {
      name: "pencePerCall",
      label: "Pence Per Call",
      options: {
        filter: false,
        sort: true,

      },
    },
  ]
  const RenderSwitch = () => {
    switch (pageState) {
      case PageState.Loading:
        return <Loading />;

      case PageState.ShowTable:
        return (
          <MuiTableWrapper
            title=""
            data={data?.bands}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: false,
              search: true,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "datetime",
                direction: "asc",
              },
            }}
          />
        );


      case PageState.NetworkError:
        return <Error />;
      default:
        return <></>;
    }
  };
  return <Grid container spacing={3} alignContent="flex-start">
    <Grid item xs={12}>
      <PageHead
        resellerID={props.resellerID}
        title="Standard Tariff"
        parents={[{ title: "Tariffs", url: "../../Tariffs" }]}
        subpages={[]}
      >

      </PageHead>
    </Grid>


    <Grid item xs={12}>
      <RenderSwitch />
    </Grid>
  </Grid>
}

export default StandardTariff;

/*


export default class StandardTariff extends React.Component<
  IStandardTariffPage,
  IStandardTaiffState
> {
  public id: string = "";
  private _isMounted: boolean = false;
  constructor(props: any) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      bands: [],
    };
  }

  public render() {
    const { loading, error, bands } = this.state;

    const col: any[] = [
      {
        Header: "Description",
        accessor: "description",
        sortable: true,
      },
      {
        Header: "Price",
        accessor: "price",
        sortable: true,
      },
      {
        Header: "Pence Per Call",
        accessor: "pencePerCall",
        sortable: true,
      },
    ];

    return (
      <div className="content">
        <PageHead
          title="Standard Tariff"
          parents={[{ title: "Tariffs", url: "../../Tariffs" }]}
        ></PageHead>

        {loading === true ? (
          <Loading />
        ) : (
          <>
            {error === true ? (
              <Error />
            ) : (
              <Col md="12" lg="12">
                <>
                  <VoipTable
                    title="standard tariff bands"
                    sortByStart="name"
                    data={bands}
                    col={col}
                    multiSelect={false}
                  />
                </>
              </Col>
            )}
          </>
        )}
      </div>
    );
  }

  // take data from form and posts it
  public handlePost = (data: any): void => {};

  componentWillUnmount() {
    this._isMounted = false;
  }
  public componentDidMount = () => {
    this._isMounted = true;
    this.get({});
  };

  // get numbers list
  public get = (data: any): void => {
    this._isMounted && this.setState({ loading: true });

    //  console.log(this.state)
    this._isMounted &&
      set
        .resellerStandardRetailTariff()
        .then((response) => {
          this._isMounted &&
            this.setState({ loading: false, bands: response.items });
        })
        .catch((exception) => {
          //  console.log(exception);
          catchME(this, exception, false);
        });
  };
}
*/
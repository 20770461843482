import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import PageHead from "../../components/PageHead";
import { Button } from "react-bootstrap";
import MySettings from "../../static/settings";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { ISite, ISitesGetHttpResponse } from "../../interfaces/site";
import ResellerRedirect from "../../components/ResellerRedirect";

import Loading from "../../components/loading";
import { IPageProps } from "../../interfaces/page-props";
import VipBurgerMenu from "../../components/VipBurgerMenu";
import Pin from "../NewCustomerWizard/images/map-pin.png";
import "../../sass/style.scss";

library.add(faChevronDown, faChevronUp);
const set = new MySettings();

interface ISitesPage extends RouteComponentProps<any>, IPageProps {}

const SitesPage: React.FC<ISitesPage> = (props) => {
  const [sites, setSites] = useState<ISite[]>([]);
  const [loading, setLoading] = useState(true);

  // console.log("sites", sites);
  // console.log("props", props);

  // const componentDidMount = () => {
  //   if (props.session.userType === "resellersContacts") {
  //     if (props.resellerID !== undefined) {
  //       get();
  //     } else {
  //       setLoading(false);
  //     }
  //   } else {
  //     get();
  //   }
  // };

  useEffect(() => {
    let isMounted = true;

    const getSites = async () => {
      try {
        const sitesGetResult: ISitesGetHttpResponse = await set.sitesGet(
          props.resellerID || "",
          false,
          false
        );
        if (isMounted) {
          setSites(sitesGetResult.items);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getSites();

    return () => {
      isMounted = false;
    };
  }, [props.resellerID]);

  let linkN: string = "./site/new";

  return (
    <>
      <div className="content">
        <PageHead resellerID={props.resellerID} title="Sites" parents={[]}>
          <VipBurgerMenu
            options={[{ title: "New Site", link: linkN, isExternal: false }]}
          />
        </PageHead>

        {loading ? (
          <Loading />
        ) : props.resellerID === undefined &&
          props.session.userType === "resellersContacts" ? (
          <ResellerRedirect />
        ) : (
          <>
            <div className="wizard5-main">
              <div className="row">
                <div className="wizard5-infoinfodashed">
                  <img
                    className="sitesImg"
                    src={Pin}
                    alt={"pin"}
                    width="47px"
                    height="90px"
                  />
                  <br />
                  <Button
                    className="button__sites"
                    onClick={() => {
                      props.history.push("./site/new");
                    }}
                  >
                    Add New Site
                  </Button>
                </div>

                {sites.map((site: any, index: number) => {
                  return (
                    <div key={index} className="wizard5-info">
                      <p className="wizard5__info-heading">
                        {site.description}
                      </p>

                      <div className="wizard5__info-data">
                        <p>{site.line1}</p>
                        <p>{site.line2}</p>
                        <p>{site.city}</p>
                        <p>{site.county}</p>
                        <p>{site.postcode}</p>
                      </div>
                      <Button
                        className="button__viewsite"
                        variant="outline-primary"
                        onClick={() => {
                          props.history.push(`./site/${site.id}`);
                        }}
                      >
                        View Site
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SitesPage;



import * as React from 'react';
import Form from 'react-bootstrap/Form';


import { INumber } from '../../interfaces/number';
import Button from 'react-bootstrap/Button';

const updateState = <T extends string>(key: keyof INumber, value: T) => (
  prevState: INumber
): INumber => ({
  ...prevState,
  [key]: value
})

export interface IFormNumberProp{
  data:INumber;
  submitFunction: ()=> Promise<boolean>;
}


export default class FormNumber extends React.Component<IFormNumberProp, INumber> {

  constructor(props:any) {
      super(props);

      this.state =props.data;
    //  this.state.enabled = true;
  }

  public handleSubmit = (event:React.FormEvent): void =>{
    event.preventDefault();
    this.props.submitFunction();
   // return this.postLogin();
  }
  public handleChange = (event:any)=>{
    this.setState( updateState(event.target.id.toString(), event.target.value));
  }
  public getData= ():INumber=>{
    return this.state;
  }
  public render(){
    const {
      ownerName, 
      ehaBussuffix, 
      ehaForename,
      ehaHonours,
      ehaLocality,
      ehaName,
      ehaPostcode,
      ehaPremises,
      ehaThoroughfare,
      ehaTitle,
      forwarding
    } = this.state;
   // console.log(notifications);
    return (
      <>
    
      <Form onSubmit={this.handleSubmit}>

        <Form.Group controlId="ownerName">
          <Form.Label>Owner Name</Form.Label>
          <Form.Control type="text" placeholder="Owner Name" value={ownerName} onChange={this.handleChange}/>
        </Form.Group>

        <Form.Group controlId="ehaBussuffix">
          <Form.Label>Bussuffix</Form.Label>
          <Form.Control type="text" placeholder="Bussuffix" value={ehaBussuffix} onChange={this.handleChange}/>
        </Form.Group>
   
        <Form.Group controlId="ehaTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" placeholder="Title" value={ehaTitle} onChange={this.handleChange}/>
        </Form.Group>
   
        <Form.Group controlId="ehaForename">
          <Form.Label>Forename</Form.Label>
          <Form.Control type="text" placeholder="Forename" value={ehaForename} onChange={this.handleChange}/>
        </Form.Group>
   
        <Form.Group controlId="ehaHonours">
          <Form.Label>Honours</Form.Label>
          <Form.Control type="text" placeholder="Honors" value={ehaHonours} onChange={this.handleChange}/>
        </Form.Group>
   
        <Form.Group controlId="ehaName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Name" value={ehaName} onChange={this.handleChange}/>
        </Form.Group>
   
        <Form.Group controlId="ehaPostcode">
          <Form.Label>Postcode</Form.Label>
          <Form.Control type="text" placeholder="Postcode" value={ehaPostcode} onChange={this.handleChange}/>
        </Form.Group>        
   
        <Form.Group controlId="ehaLocality">
          <Form.Label>Locality</Form.Label>
          <Form.Control type="text" placeholder="Locality" value={ehaLocality} onChange={this.handleChange}/>
        </Form.Group>            
        
        <Form.Group controlId="ehaPremises">
          <Form.Label>Premises</Form.Label>
          <Form.Control type="text" placeholder="Premises" value={ehaPremises} onChange={this.handleChange}/>
        </Form.Group>            
             
        <Form.Group controlId="ehaThoroughfare">
          <Form.Label>Thoroughfare</Form.Label>
          <Form.Control type="text" placeholder="Thoroughfare" value={ehaThoroughfare} onChange={this.handleChange}/>
        </Form.Group>            
     
                   
        <Form.Group controlId="forwarding">
          <Form.Label>forwarding</Form.Label>
          <Form.Control type="text" placeholder="forwarding" value={forwarding} onChange={this.handleChange}/>
        </Form.Group>

        <Button variant="primary" type="submit">
          Save
        </Button>
     </Form>

  
      </>
     
    );
  }
}



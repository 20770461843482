import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerAdapter = ({ input: { onChange, value }, ...rest }: any) => (
  <DatePicker
    selected={value}
    className="form-group"
    placeholderText={rest.placeholder}
    onChange={(date: Date) => onChange(date)}
    {...rest}
    customInput={<input type="text" className="form-control" />}
  />
);

export default DatePickerAdapter;

import React from "react";
import Switch from "react-switch";

interface IVipSwitchProps {
  isOn: boolean;
  handleToggle: any;
}

const VipSwitch: React.FC<IVipSwitchProps> = (props) => {
  let checked = false
  if(props.isOn===true){
    checked = true
  }
  return (
    <label>
      <Switch
        onChange={props.handleToggle}
        checked={checked}
        // onColor="#86d3ff"
        onColor="#B8BABE"
        offColor="#F6B1B4"
        activeBoxShadow=""
        onHandleColor="#1D57D0"
        offHandleColor="#F96B6B"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        height={14}
        width={34}
        className="react-switch"
        id="switch"
      />
    </label>
  );
};

export default VipSwitch;

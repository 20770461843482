
import {  IEmailSubReportDNDChanges } from "../../interfaces/email-reports";


import { Grid } from "@material-ui/core";
import MuiTitle from "../misc/form/MuiTitle";
import VipTimeFieldMui from "../VipTimeFieldMui";
import { useState } from "react";



export interface iEmailReportDNDProps {
  data: IEmailSubReportDNDChanges;
  submitFunction: (field: string, data: any) => void;
}

const EmailReportDND = ({data, submitFunction}:iEmailReportDNDProps) => {
  const [state, setState] = useState<IEmailSubReportDNDChanges>(() => {
    //  console.log(hotDesks)
    const xState = { ...data };
    if (!xState.startTime) {
      xState.startTime = "08:00:00"
      submitFunction("startTime","08:00:00")
    }
    if (!xState.endTime) {
      xState.endTime = "18:00:00"
      submitFunction("endTime","18:00:00")
    }
    


    return xState;
  });
  const setField = (field: string, value: any) => {
    console.log("subform", field, value);
    const stateUp: any = { ...state };
    stateUp[field] = value;
    setState(stateUp);
    submitFunction(field, value);
  }
  return <Grid container spacing={2}>
    <Grid item xs={6}>
      <MuiTitle title="Start Time" />
      <VipTimeFieldMui
        field="startTime"
        value={state.startTime ? state.startTime : "08:00:00"}
        updateField={setField}
      />

    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="End Time" />
      <VipTimeFieldMui
        field="endTime"
        value={state.startTime ? state.endTime : "18:00:00"}
        updateField={setField}
      />
    </Grid>
  </Grid>
}

export default EmailReportDND;

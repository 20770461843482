
import * as React from 'react';


import { Form, Field } from 'react-final-form';
import { ISearchFormProps } from '../../interfaces/search-form';
import { Row, Col } from 'react-bootstrap';

import VipTextFinalField from '../../components/misc/form/VipTextFinalField';

const NumberTrackersSearchForm: React.FunctionComponent<ISearchFormProps> = PROP => {

    /*
        let arrayOfNumbers: any[] = [];
    
        arrayOfNumbers = PROP.array.map((dd: any) => {
            const thing = { value: dd.name, label: dd.name };
            return thing;
        });
    */
    const submit = (data: any) => {

        //   data.endDate = data.toDate.getFullYear() + "-" + appendLeadingZeroes(data.toDate.getMonth() + 1) + "-" + appendLeadingZeroes(data.toDate.getDate());
        //  data.startDate = data.fromDate.getFullYear() + "-" + appendLeadingZeroes(data.fromDate.getMonth() + 1) + "-" + appendLeadingZeroes(data.fromDate.getDate());
        PROP.onSubmit(data)
    }
    return <Form
        onSubmit={submit}
        initialValues={PROP.data}
        render={({ handleSubmit, form, submitting, pristine, values }) => (

            <form onSubmit={handleSubmit}>
                <Row>
                    {PROP !== undefined && PROP.services !== undefined && PROP.services.length > 1 && (<Col>
                        <Field component="select" name="customersSitesId">
                            {({ input, meta }) => (
                                <div>
                                    <select {...input} id={input.name.replace('.', '_')} className="form-control">
                                        {PROP.services !== undefined && PROP.services.map(serv => {
                                            return (<option key={serv.id} value={serv.id}>{serv.siteName}</option>)
                                        })}

                                    </select>
                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                </div>
                            )}

                        </Field>
                    </Col>)}

                    <Col>
                        <Field component="select" name="owner">
                            {({ input, meta }) => (
                                <div>
                                    <select {...input} id={input.name.replace('.', '_')} className="form-control">
                                        {PROP.customers !== undefined && PROP.customers.map(serv => {
                                            return (<option key={serv.id} value={serv.id}>{serv.name}</option>)
                                        })}

                                    </select>
                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                </div>
                            )}

                        </Field>
                    </Col>
                    <Col>
                        <VipTextFinalField fieldName="area" placeholder="Area" required={false} />

                    </Col>
                    <Col>
                        <VipTextFinalField fieldName="notes" placeholder="Notes" required={false} />
                    </Col>
                    <Col className="text-right">
                        <button
                            className="btn btn-outline-primary"
                            type="submit"
                            disabled={submitting || pristine}>Search</button>
                    </Col>
                </Row>

            </form>
        )} />
}



export default NumberTrackersSearchForm;
import React from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field } from "react-final-form";
import {
  FormControl,
  makeStyles,
  TextField,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import requiredValidator from "../misc/form/requiredValidator";

const useStyles = makeStyles((_theme) => ({
  formControl: {
    minWidth: 180,
  },
}));

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        maxHeight: "42px",
      },
    },

    // @ts-ignore
    MuiAutocomplete: {
      input: {
        marginTop: "-4px",
      },
    },

    MuiInputLabel: {
      outlined: {
        marginTop: "2px",
        transform: "translate(14px, 15px)",
      },
    },
  },
});

interface ISelectProps {
  name: string;
  options: string[];
  label?: string;
  getOptionLabel?:(T:any)=>string;
  getOptionSelected?:(option:any, value:any)=>boolean

  required?: boolean;
}

const MuiAutocompleteSelect: React.FC<ISelectProps> = ({
  name,
  options,
  label,
  getOptionLabel,
  getOptionSelected,
   required
}) => {
  const [inputValue, setInputValue] = React.useState("");

  
  const addFieldParams:any = { };

  if(required){
    addFieldParams.validate = requiredValidator;
  }
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Field name={name} {...addFieldParams}>
        {({ input: { onChange, value }, meta}) =>{ 
          
          const autocompleteProps:any = {
            value,
            onChange:(_event:any, newValue:any) => {
              onChange(newValue);
            },
            inputValue:inputValue,
            onInputChange:(_event:any, newValue:any) => {
              setInputValue(newValue);
            },
            id:"autocomplete",
            options:options  
            
            
          }
          if(getOptionSelected!==undefined){
            autocompleteProps.getOptionSelected = getOptionSelected;
          }
          if(getOptionLabel!==undefined){
            autocompleteProps.getOptionLabel = getOptionLabel;
          }
          
          return (
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Autocomplete  
              fullWidth
              {...autocompleteProps}
  
              renderInput={(params) => (
                <TextField {...params} label={label} variant="outlined"  error={
                  (meta.error && meta.touched) ||
                  (!!meta.submitError)
                }
                helperText={
                  meta.error && meta.touched
                    ? meta.error
                    : !!meta.submitError
                    ? meta.submitError
                    : ""
                }/>
              )}
            />
          </FormControl>
        )}}
      </Field>
    </ThemeProvider>
  );
};
export default MuiAutocompleteSelect;

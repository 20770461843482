import React, {  } from 'react';
import { IBranding } from '../interfaces/branding';
import { IVoipSession } from '../interfaces/interfaces';

//const something  = await set.brandingGet(window.location.hostname);
const defaultTheme: IBranding = { emailAddress: 'default', logo: '', name: '', navigationBarLogo:'', privacyPolicyURL: './privacy', brandPortalDomain:'', telephoneNumber:'' };
const defaultSession: IVoipSession = { topMenu:[], oldPortalLink:'', emailAddress: 'default', loggedIn: false, userName: 'default', userType: 'default', usesMFA:false };



export function createBrandingCtx<ContextType>() {
  const MainContext = React.createContext<ContextType | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(MainContext);
    if (!c) throw new Error("useCtx must be inside a Provider with a value");
    return c;
  }
  return [useCtx, MainContext] as const;
}



type MainContextType = {
  data: IMainContext
  setData: (brand: IMainContext) => void;
}


export interface IMainContext {
  branding: IBranding;
  session: IVoipSession;
}

//const BrandingContext = React.createContext<BrandingContextType | undefined>(undefined);

const [useMain, MainContext] = createBrandingCtx<MainContextType>();


MainContext.displayName = 'MainContext';
type Props = {
  children: React.ReactNode;
};
export const MainProvider = ({ children }: Props) => {

  const [data, setData] = React.useState({ session: defaultSession, branding: defaultTheme })

  return (
    <MainContext.Provider value={{ data, setData }}>{children}</MainContext.Provider>
  );
};




export { useMain, MainContext };
import React from "react";
import { Breadcrumb } from "react-bootstrap";

import { Helmet } from "react-helmet";

import { useMain } from "../context/main";

import "../sass/pagehead.scss";

export interface IPageHeadProps {
  title: string;
  text?: string;
  parents?: any[];
  subpages?: any[];
}

type TPageHead = {
  title: string;
  customersMode?: Boolean;
  text?: string;
  isHome?: boolean;
  parents?: any[];
  subpages?: any[];
  resellerID?: string;
};

const PageHead: React.FunctionComponent<TPageHead> = ({
  title,
  customersMode,
  text,
  parents = [],
  subpages,
  children,
  isHome,
  resellerID,
}) => {
  //console.log("PageHead", subpages, parents, customersMode);
  const context = useMain();
  const { session } = context.data;

  if (isHome) {
  } else {
    if (session.userType === "resellersContacts" && resellerID !== undefined) {
      if (parents && parents.length > 0) {
        parents.unshift({
          title: "My Dashboard",
          url: "/voip/portal/reseller/" + resellerID,
        });
      } else {
        parents = [
          { title: "My Dashboard", url: "/voip/portal/reseller/" + resellerID },
        ];
      }
    } else {
      if (parents && parents.length > 0) {
        parents.unshift({ title: "My Dashboard", url: "/voip/portal/" });
      } else {
        parents = [{ title: "My Dashboard", url: "/voip/portal/" }];
      }
    }
  }
  if(title==null){
    title=''
  }
  return (
    <div className="text-left pageHead">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="d-flex flex-rowd-flex justify-content-between margin40">
        <div>
          <h2 className="pageHeadH2">
            <Breadcrumb>
              {parents !== undefined && parents.length >= 0 && (
                <>
                  {parents.map((value, index) => {
                    let link = value.url;
                    //    console.log(link);
                    if (session.userType === "resellersContacts") {
                      if (resellerID !== undefined) {
                        //link = link.replace("../", '../../');
                      }
                    }
                    return (
                      <Breadcrumb.Item
                        href={link}
                        key={value.title}
                        className="breadcrumb-link"
                      >
                        {value.title}
                      </Breadcrumb.Item>
                    );
                  })}
                  {parents.length ? (
                    <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                  ) : (
                    <Breadcrumb.Item className="breadcrumb-home">
                      {title}
                    </Breadcrumb.Item>
                  )}
                </>
              )}
            </Breadcrumb>
          </h2>
        </div>
        <div className="d-flex flex-row justify-content-end text-right">
          <div>{children}</div>

          {subpages !== undefined && subpages.length > 0 && (
            <>
              <div>
                {subpages.map((page) => {
                  let link = page.to;

                  if (
                    session.userType === "resellersContacts" &&
                    page.cls !== true
                  ) {
                    if (session.activeCustomerID !== undefined) {
                      link += "?resellerID=" + session.activeCustomerID;
                    }
                  }
                  return (
                    <Breadcrumb.Item
                      href={link}
                      key={page.title}
                      className="breadcrumb-link"
                    >
                      {page.title}
                    </Breadcrumb.Item>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      {text && <p>{text}</p>}
    </div>
  );
};
export default PageHead;

import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import { NavDropdown } from "react-bootstrap";

import { useMain } from "../context/main";
import TopMenu from "./TopMenu";
import image from "../compiledSVG/Help.svg";
import { useStore } from "./tours/TwoFaTour";
import { IBranding } from "../interfaces/branding";
import MySettings from "../static/settings";

library.add(faSignOutAlt);

const Navigation = () => {
  // console.log(props)
  const context = useMain();
  const [branding, setBranding] = useState<IBranding>({
      brandPortalDomain: "",
      emailAddress: "default",
      logo: "",
      name: "default",
      navigationBarLogo:'',
      privacyPolicyURL: "./privacy",
      telephoneNumber: "",
      bundleAlertEmailAddress:'',
      bundleAlertPercentage:0,
      overrideNavigationBarColour:false,
      navigationBarColour:''
  });
  useEffect(() => {
    //this is here because the branding info doesn't seem to come through from the useMain context correctly
    async function getBranding(){
      try{
        const set = new MySettings();
        const newBranding=await set.brandingGet(window.location.hostname)
        setBranding(newBranding)
      }catch(err){
  
      }
    }
    getBranding()
  },[])
  const { session } = context.data;
  const { userName, loggedIn } = session;
  const opened = useStore((state) => state.opened);
  const remoteControl = useStore((state) => state.remoteControl);

  let logoName = "Vip Voip";
  let logoSrc = process.env.REACT_APP_PATH + "svg/vip-logo.svg";

  if (branding !== undefined) {
    logoSrc = "/voip/uploadFile?file=" + branding.logo;
    logoName = branding.name;
    if(branding.navigationBarLogo?.length>0){
      logoSrc = "/voip/uploadFile?file=" + branding.navigationBarLogo;
    }
  }


  let homeLink = "/";

  let bg="light"
  let style={}
  if(branding.overrideNavigationBarColour){
    bg=''
    style={backgroundColor:branding.navigationBarColour}
  }
  return (
    <Navbar bg={bg} expand="md" collapseOnSelect={true} style={style}>
      <Navbar.Brand>
        <Link to={homeLink} className="nav-link brandingNavbar">
          <img
            className="branding-logo img-fluid"
            src={logoSrc}
            alt={logoName}
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="profile-navbar-nav" />

      {loggedIn && (
        <Navbar.Collapse id="profile-navbar-nav" className="ml-3">
          <Nav className="mr-auto">
            <TopMenu items={session.topMenu} depth={0} />
          </Nav>
          <Link to="/help" className="helpLink">
            <img src={image} alt="help icon" />
          </Link>
          <span className="divider" />
          <Nav>
            <NavDropdown
              title={userName}
              id="basic-nav-dropdown-profile"
              className="mr-1 my-auto userDropdown"
              alignRight
              show={remoteControl ? opened : undefined}
            >
              <NavDropdown.Item className="profileLink">
                <Link to="/profile">Profile</Link>
              </NavDropdown.Item>
              {session.userType === "resellersContacts" && (
                <NavDropdown.Item>
                  <Link to="/resellerProfile">Reseller Profile</Link>
                </NavDropdown.Item>
              )}
              {session.canSeeCustomerProfile===true && (
                <NavDropdown.Item>
                  <Link to='/customerProfile'>Customer Profile</Link>
                </NavDropdown.Item>
              )}

              <NavDropdown.Item>
                <Link id="logOut" to="/logout">
                  Log Out <FaIcon icon="sign-out-alt" />
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};

export default Navigation;

import React, { useState, useEffect } from "react";
import VoipTable from "../components/VoipTable";
import MySettings from "../static/settings";
import Loading from "../components/loading";

const set = new MySettings();

const TariffTable = () => {
  const [bandlist, setBandlist] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    let isMounted = true;

    const getBand = async () => {
      try {
        if (isMounted) {
          const band = await set.resellerFullRetailTariff();
          for(let i=0;i<band.items.length;i++){
            band.items[i].wholesalePencePerMinute=Math.round(band.items[i].wholesalePencePerMinute*100)/100
            band.items[i].wholesalePencePerCall=Math.round(band.items[i].wholesalePencePerCall*100)/100
            band.items[i].pencePerCall=Math.round(band.items[i].pencePerCall*100)/100
            band.items[i].price=Math.round(band.items[i].price*100)/100
          }
          setBandlist(band.items);
          setLoading(false);
        }
        // setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
        // setError(true);
      }
    };
    getBand();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const col: any[] = [
    {
      Header: "Description",
      accessor: "description",
      sortable: true,
    },
    {
      Header: "Wholesale Pence Per Call",
      accessor: "wholesalePencePerCall",
      sortable: true,
      cssClass: "text-center"
    },
    {
      Header: "Wholesale Pence Per Minute",
      accessor: "wholesalePencePerMinute",
      sortable: true,
      cssClass: "text-center"
    },
    {
      Header: "Retail Pence Per Call",
      accessor: "pencePerCall",
      sortable: true,
      cssClass: "text-center"
    },
    {
      Header: "Retail Pence Per Minute",
      accessor: "price",
      sortable: true,
      cssClass: "text-center"
    },
  ];
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ maxHeight: "400px", overflow: "auto" }}>
          <VoipTable
            title="standard tariff bands"
            sortByStart="description"
            data={bandlist}
            col={col}
            multiSelect={false}
            showResultsCount={false}
            holderClass=""
          />
        </div>
      )}
    </>
  );
};

export default TariffTable;

import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import Loading from "../components/loading";
import VipSidebar from "../components/VipSidebar/VipSidebar";
import NotFound from "./NotFound";
import { PageDeclares } from "./pagedeclare";

import catchME from "../catchME";

import Error from "../components/error";
import { IBranding } from "../interfaces/branding";
import { IMenuOption } from "../interfaces/menu-option";
import { IPageProps } from "../interfaces/page-props";
import MySettings from "../static/settings";

/*
Adding a new page
-add route to the list of const
-add to Route for reseller
-add to App.tsx menuOpts e.g
menuOpt.push({
                title:'Phonebook LDAP Settings',
                path:RESELLER_ROUTE_PHONE_BOOK_LDAP_SETTINGS,
                visibleTop:false,
                visibleAdmin:false,
                visibleDash:false
              });

-create page declaration
*/
export const RESELLER_ROUTE_PHONE_BOOK_LDAP_SETTINGS =
  "/reseller/:rid/phonebooksettings/:id";
export const RESELLER_ROUTE_PHONE_BOOK_UPLOAD =
  "/reseller/:rid/phonebookupload/:phoneBookId";
export const RESELLER_ROUTE_PROVISIONING_VIEW_URLS = "/viewprovisioningurls";
export const RESELLER_ROUTE_PROVISIONING_VIEW_CUSTOMER_URLS =
  "/reseller/:rid/viewprovisioningurls";
export const RESELLER_ROUTE_CUSTOMER_ALLOW_DIAL_CODES =
  "/reseller/:rid/allowdialcodes";
export const RESELLER_ROUTE_CUSTOMER_ALLOW_DIAL_CODES_NEW =
  "/reseller/:rid/allowdialcodesnew";
export const RESELLER_ROUTE_PSA_CONFIGURATION = "/psaconfiguration";
export const RESELLER_ROUTE_PSA_CUSTOMER_MATCHING = "/psacustomermatching";
export const RESELLER_ROUTE_PSA_PRODUCT_MATCHING = "/psaproductmatching";
export const RESELLER_ROUTE_PSA_RECONCILIATION = "/psareconciliation";

const set = new MySettings();
interface IResellerHomeState {
  loading: boolean;
  error: boolean;
  customer: any;
}

interface IResellerHome extends RouteComponentProps<any>, IPageProps {
  /* other props for ChildComponent */
  reloadMenu(): Promise<boolean>;
  menuOptions: IMenuOption[];
  branding: IBranding;
  reloadBranding(): Promise<boolean>;
}

export default class ResellerHome extends React.Component<
  IResellerHome,
  IResellerHomeState
> {
  _isMounted = false;
  public id: string = "";
  constructor(props: any) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      customer: null,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    //  console.log(this.props.re);
    const id = this.props.match.params.id;
    this.id = id;
    this._isMounted &&
      set
        .resellerCustomerDetailsGet(id)
        .then((cust) => {
          this._isMounted && this.setState({ loading: false, customer: cust });
        })
        .catch((exception) => {
          catchME(this, exception, true);
        });
    // console.log(requestedID);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  public render() {
    const { loading, error, customer } = this.state;

    // console.log(this.props, this.state)

    if (loading) {
      return <Loading />;
    } else if (error) {
      return <Error />;
    } else {
      const pageDeclare = new PageDeclares(
        this,
        this.props.session,
        this.props.menuOptions,
        this.props.branding,
        this.props.reloadMenu,
        this.props.reloadBranding,
        this.id,
        customer
      );
      //   console.log(this.props);
      // const url = this.props.location.pathname;
      return (
        <>
          <VipSidebar resellerID={this.id} initialCustomer={customer} />

          <Switch>
            <Route
              exact
              path="/reseller/:rid/"
              component={pageDeclare.homePage}
            />
            <Route
              path="/reseller/:rid/virtualreceptionist/homepage"
              component={pageDeclare.receptionistPage}
            />
            <Route
              path="/reseller/:rid/virtualReceptionistSummary/:numberId"
              component={pageDeclare.chatPage}
            />
            <Route
              path="/reseller/:rid/virtualReceptionistMessage/:id"
              component={pageDeclare.chatMessagePage}
            />
            <Route
              path="/reseller/:rid/voipservice/:id/provisioning"
              component={pageDeclare.voipServiceProvisioningPage}
            />
            <Route
              path="/reseller/:rid/:customersSitesServicesId/voicerouting/:id"
              component={pageDeclare.voiceRoutingPage}
            />

            <Route
              path="/reseller/:rid/:customersSitesServicesId/emailreport/:id"
              component={pageDeclare.emailReportPage}
            />

            <Route
              path="/reseller/:rid/:customersSitesServicesId/voiceroutings"
              component={pageDeclare.voiceRoutingsPage}
            />

            <Route
              path="/reseller/:rid/:customersSitesServicesId/emailreports"
              component={pageDeclare.emailReportsPage}
            />

            <Route
              path="/reseller/:rid/number/:id/:mode"
              component={pageDeclare.numberPage}
            />
            <Route
              path="/reseller/:rid/number/:id"
              component={pageDeclare.numberPage}
            />
            <Route
              path="/reseller/:rid/numbers"
              component={pageDeclare.numbersPage}
            />
            <Route
              path="/reseller/:rid/allocateNumbers"
              component={pageDeclare.resellerAllocateNumbers}
            />
            <Route
              path="/reseller/:rid/numberTrackers"
              component={pageDeclare.numberTrackersPage}
            />
            <Route
              path="/reseller/:rid/numberTracker/:id"
              component={pageDeclare.numberTrackerPage}
            />
            <Route
              path="/reseller/:rid/phonebookNumber/:phonebookID/:numberID"
              component={pageDeclare.phonebookNumberPage}
            />
            <Route
              path="/reseller/:rid/phonebook/:id"
              component={pageDeclare.phonebookPage}
            />
            <Route
              path={RESELLER_ROUTE_PHONE_BOOK_LDAP_SETTINGS}
              component={pageDeclare.phonebookLDAPSettingsPage}
            />
            <Route
              path={RESELLER_ROUTE_PHONE_BOOK_UPLOAD}
              component={pageDeclare.phoneBookUpload}
            />
            <Route
              path={RESELLER_ROUTE_PROVISIONING_VIEW_URLS}
              component={pageDeclare.provisioningViewURLsPage}
            />
            <Route
              path={RESELLER_ROUTE_PSA_CONFIGURATION}
              component={pageDeclare.psaConfigurationPage}
            />
            <Route
              path={RESELLER_ROUTE_PSA_CUSTOMER_MATCHING}
              component={pageDeclare.psaCustomerMatchingPage}
            />
            <Route
              path={RESELLER_ROUTE_PSA_PRODUCT_MATCHING}
              component={pageDeclare.psaProductMatchingPage}
            />
            {/*<Route
              path={RESELLER_ROUTE_PSA_RECONCILIATION}
              component={pageDeclare.psaReconciliationPage}
            />*/}
            <Route
              path={RESELLER_ROUTE_PROVISIONING_VIEW_CUSTOMER_URLS}
              component={pageDeclare.provisioningViewURLsPage}
            />
            <Route
              path={RESELLER_ROUTE_CUSTOMER_ALLOW_DIAL_CODES}
              component={pageDeclare.customerAllowDialCodesPage}
            />
            <Route
              path={RESELLER_ROUTE_CUSTOMER_ALLOW_DIAL_CODES_NEW}
              component={pageDeclare.customerAllowDialCodesNewPage}
            />
            <Route
              path="/reseller/:rid/phonebooks"
              component={pageDeclare.phonebooksPage}
            />
            <Route
              path="/reseller/:rid/phonebooksnew"
              component={pageDeclare.phonebooksFormPage}
            />
            <Route
              path="/reseller/:rid/profile"
              component={pageDeclare.profilePage}
            />
            <Route
              path="/reseller/:rid/resellerProfile"
              component={pageDeclare.resellerProfilePage}
            />
            <Route
              path="/reseller/:rid/areacoderouting"
              component={pageDeclare.areaCodeRoutingPage}
            />
            <Route
              path="/reseller/:rid/callrecordings"
              component={pageDeclare.callRecordingPage}
            />
            <Route
              path="/reseller/:rid/calllogs"
              component={pageDeclare.callLogPage}
            />
            <Route
              path="/reseller/:rid/people"
              component={pageDeclare.peoplePage}
            />
            <Route
              path="/reseller/:rid/provisioning"
              component={pageDeclare.devicesPage}
            />
            <Route
              path="/reseller/:rid/incomingCallStats"
              component={pageDeclare.incomingCallStatsPage}
            />
            <Route
              path="/reseller/:rid/person/:id"
              component={pageDeclare.personPage}
            />
            <Route
              path="/reseller/:rid/device/:id"
              component={pageDeclare.devicePage}
            />
            <Route
              path="/reseller/:rid/emailreports"
              component={pageDeclare.emailReportsPage}
            />
            <Route
              path="/reseller/:rid/emailreport/:id"
              component={pageDeclare.emailReportPage}
            />
            <Route
              path="/reseller/:rid/reseller"
              component={pageDeclare.resellerPage}
            />
            <Route
              path="/reseller/:rid/customer/:id/:form"
              component={pageDeclare.customerPage}
            />
            <Route
              path="/reseller/:rid/permission/:id"
              component={pageDeclare.permssionPage}
            />
            <Route
              path="/reseller/:rid/permissions"
              component={pageDeclare.permssionsPage}
            />
            <Route
              path="/reseller/:rid/sites"
              component={pageDeclare.sitesPage}
            />
            <Route
              path="/reseller/:rid/site/:id"
              component={pageDeclare.sitePage}
            />
            <Route
              path="/reseller/:rid/newService/:id"
              component={pageDeclare.newServicePage}
            />
            <Route
              path="/reseller/:rid/service/:id/:pageMode"
              component={pageDeclare.servicePage}
            />
            <Route
              path="/reseller/:rid/invoices"
              component={pageDeclare.resellerInvoicesPage}
            />
            <Route
              path="/reseller/:rid/invoice/:id"
              component={pageDeclare.resellerInvoicePage}
            />
            <Route
              path="/reseller/:rid/meetingrooms/:id"
              component={pageDeclare.voipServiceMeetingRoom}
            />
            <Route
              path="/reseller/:rid/meetingrooms"
              component={pageDeclare.voipServiceMeetingRooms}
            />
            <Route
              path="/reseller/:rid/meetingroomsadd"
              component={pageDeclare.voipServiceMeetingRoomsForm}
            />
            <Route
              path="/reseller/:rid/wallboards"
              component={pageDeclare.wallboardsListPage}
            />
            <Route
              path="/reseller/:rid/wallboard/:id"
              component={pageDeclare.wallboardPage}
            />
            <Route
              path="/reseller/:rid/internetserviceenquiry/:id/summary"
              component={pageDeclare.internetServiceEnquiry}
            />
            <Route
              path="/reseller/:customersid/teamscallrecordingservice/:id/summary"
              component={pageDeclare.teamscallrecordingservice}
            />

            <Route component={NotFound} />
          </Switch>
        </>
      );
    }
  }
}

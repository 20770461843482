import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import MySettings from "../../static/settings";
import Loading from "../../components/loading";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import PageHead from "../../components/PageHead";
import ResellerRedirect from "../../components/ResellerRedirect";

import {
  IMeetingRoomsGetResponse,
  IMeetingRoom,
} from "../../interfaces/meeting-rooms";
import { IPageProps } from "../../interfaces/page-props";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import App from "../../App";

const set = new MySettings();
const svgSrc = "/voip/portal/svg/phonebook-img.svg";

const VoipServiceMeetingRooms: React.FC<IPageProps> = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [meetingRooms, setMeetingRooms] = useState<IMeetingRoom[]>([]);
  const isMounted = useRef(true);

  console.log("PROPS", props);

  useEffect(() => {
    const getMeetingRooms = async () => {
      try {
        const result: IMeetingRoomsGetResponse = await set.meetingRoomsGet(
          props.resellerID
        );
        console.log("RESULT", result);
        if (isMounted.current) {
          setIsLoading(false);
          setMeetingRooms(result.items);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getMeetingRooms();
    return () => {
      isMounted.current = false;
    };
  }, [props.resellerID]);

  const handleMeetingRoomDeletion = async (MeetingRoomID: string) => {
    console.log("ID", MeetingRoomID);
    const dialogResp = window.confirm(
      "Are you sure you want to remove this meeting room?"
    );
    if (dialogResp === true) {
      try {
        setIsLoading(true);
        const result = await set.meetingRoomsRemove(MeetingRoomID);
        console.log(result);

        setMeetingRooms(
          meetingRooms.filter((room) => room.id !== MeetingRoomID)
        );

        const notification: INotification = {
          title: "Success",
          data: "Meeting Room Deleted Successfully!",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "VoipServiceMeetingRooms",
          srcData: "success",
          srcFunction: "handleMeetingRoomDeletion",
        };
        App.newNotification(notification);
      } catch (error) {
        console.log(error);

        const notification: INotification = {
          title: "Failed",
          data: "Meeting Room Deletion Went Wrong!",
          variant: NOTIFICATION_TYPE.warning,
          srcComponent: "VoipServiceMeetingRooms",
          srcData: "fail",
          srcFunction: "handleMeetingRoomDeletion",
        };
        App.newNotification(notification);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="content">
      <PageHead title="Meeting Rooms" resellerID={props.resellerID}>
        <Link to="./meetingroomsadd">
          <Button variant="outline-primary">New Meeting Room</Button>
        </Link>
      </PageHead>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {props.resellerID === undefined &&
          props.session.userType === "resellersContacts" ? (
            <ResellerRedirect />
          ) : (
            <div className="dashboardItems">
              {meetingRooms.map((room: IMeetingRoom) => (
                <div className="dashboard_item">
                  <Link to={`./meetingrooms/${room.id}`}>
                    <img
                      src={svgSrc}
                      className="img-fluid"
                      alt="Meeting Rooms"
                    />
                    <h4 className="mt-2 mb-4">{room.name}</h4>
                  </Link>
                  <a href={room.url} target="_blank" rel="noopener noreferrer">
                    <Button variant="outline-primary" className="mb-2 noleft">
                      <FaIcon
                        icon="user-plus"
                        color="primary"
                        className="mr-1"
                      />
                      Join This Meeting
                    </Button>
                  </a>
                  <br />
                  <Button
                    className="noleft"
                    type="button"
                    variant="outline-danger"
                    onClick={() => {
                      handleMeetingRoomDeletion(room.id);
                    }}
                  >
                    <FaIcon icon="user-minus" color="red" />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VoipServiceMeetingRooms;

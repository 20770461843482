import * as React from "react";

import Loading from "../../components/loading";
import MySettings from "../../static/settings";
import { IPageProps } from "../../interfaces/page-props";
import PageHead from "../../components/PageHead";

import catchME from "../../catchME";
import Error from "../../components/error";
import ResellerProfileForm from "./ResellerProfileForm";
import { RouteComponentProps } from "react-router-dom";
import { IBranding } from "../../interfaces/branding";
import App from "../../App";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";

interface IResellerProfileState {
  loading: boolean;
  error: boolean;
  branding: IBranding
}

const set = new MySettings();

interface IResellerProfilePage extends RouteComponentProps<any>, IPageProps {
  reloadBranding(): Promise<boolean>;
}
export default class ResellerProfile extends React.Component<
  IResellerProfilePage,
  IResellerProfileState & any
> {
  public state: IResellerProfileState & any = {
    loading: false,
    error: false,
    branding:{
      logo:'',
      name:'',
      emailAddress:'',
      privacyPolicyURL:'',
      brandPortalDomain:'',
      telephoneNumber: '',
      removeLogo: false
    }
  };

  public componentDidMount = () => {
    this.getBranding()
  }

  public async getBranding(){
    this.setState({...this.state,loading:true})
    try{
      let branding = await set.resellerBrandingGet()
      this.setState({...this.state,branding:branding})
    }catch(err){
      this.setState({...this.state,error:true})
      App.newNotificationError('Could not get branding information.')
    }
    this.setState({...this.state,loading:false})
  }

  public redirectToHome = () => {
    if (this.state.history) {
      this.state.history.push(`/`);
    }
  };

  public submitBranding = (branding: any) => {
    this.setState({...this.state,loading:true})
    branding.bundleAlertPercentage=parseInt(branding.bundleAlertPercentage)
    if(branding.bundleAlertPercentage==null || isNaN(branding.bundleAlertPercentage)){
      branding.bundleAlertPercentage=0
    }
    set
      .resellerBrandingUpdate(
        branding.name,
        branding.brandPortalDomain,
        branding.emailAddress,
        branding.removeLogo,
        branding.newLogo,
        branding.newLogoFileName,
        branding.removeNavigationBarLogo,
        branding.newNavigationBarLogo,
        branding.newNavigationBarFileName,
        branding.bundleAlertEmailAddress,
        branding.bundleAlertPercentage,
        branding.overrideNavigationBarColour,
        branding.navigationBarColour
      )
      .then((resp) => {
        // console.log(resp);
        const notification: INotification = {
          title: "Success",
          data: "Reseller profile updated.",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification);
        this.getBranding()
      })
      .catch((exception) => {
        catchME(this, exception, true);
      });
  };

  public render() {
    if (this.state.error) {
      return <Error />
    }else if (this.state.loading) {
      return <Loading />
    } else {
      return (
        <div className="content">
          <PageHead
            resellerID={this.props.resellerID}
            title="Reseller Profile"
            parents={[]}
          ></PageHead>
          <ResellerProfileForm
            data={this.state.branding}
            onSubmit={(data: any) => {
              this.submitBranding(data);
            }}
          />
        </div>
      );
    }
  }
}


import * as React from 'react';
import { Button } from 'react-bootstrap';

import { Field, Form } from 'react-final-form';
import makeId from '../../components/misc/form/makeId';

// ({ data, onSubmit }: any)

import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import FormGroupAdapter from '../../components/FormGroupAdaptor';
import { IFileUpload } from '../../interfaces/interfaces';



export interface IRequestNumberPortPopupProps {
    data: any;
    onSubmit: any;

}

export interface IRequestNumberPortPopupState {
    data: any;
    visible: boolean;
}

export default class RequestNumberPortPopup extends React.Component<IRequestNumberPortPopupProps, IRequestNumberPortPopupState> {
    public state: any = {
        data: [],
        visible: false,
    };

    public setVisible = (bool: boolean) => {
        this.setState({ visible: bool });
    }
    // eslint-disable-next-line 
    constructor(props: any) {
        super(props);
    }

    public submit = (data: any) => {
        data.siteIndex = 0;
        this.setState({ visible: false });
        this.props.onSubmit('newNumberPort', data)
    }
    public render() {
        if (this.state.visible === false) {
            return <></>
        } else {
            return <div className="overlay">

                <div className="overlay-content">

                    <Form
                        onSubmit={this.submit}
                        initialValues={this.props.data}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (

                            <form onSubmit={handleSubmit}>


                                <div className="popupContent text-left test-new-customer-start WHITEN">

                                    <fieldset>


                                        <legend>Request Number Port</legend>
                                        <a href="/voip/src/templates/NumberPortTemplate.docx" download>
                                            <Button variant="outline-primary" className="mb-2">
                                                Download Number Port Form
                                                <FaIcon
                                                    icon="file-download"
                                                    color="primary"
                                                    className="ml-2"
                                                />
                                            </Button>
                                        </a>
                                        <div className="form-group">
                                            <p>Please fill out and upload the template provided:</p>
                                            <Field
                                                name="newFile"
                                                component={FormGroupAdapter}
                                                inputOnChange={(sdata: any) => {
                                                    //  console.log("on change", sdata);

                                                    const reader = new FileReader();
                                                    const file = sdata.target.files[0];

                                                    reader.readAsDataURL(file);
                                                    reader.addEventListener("load", function () {
                                                        // console.log("onload", reader.result);
                                                        if (typeof reader.result === "string") {
                                                            const numberPortTemplate: IFileUpload = {
                                                                base64: reader.result,
                                                                filename: file.name,
                                                            };
                                                            form.change("numberPortTemplate", [
                                                                numberPortTemplate,
                                                            ]);
                                                        }
                                                    });
                                                }}
                                                placeholder="Upload file"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Numbers to port</label>

                                            <Field name="numbersToPort" component="textarea" placeholder="0114 44444">
                                                {({ input, meta }) => (
                                                    <>
                                                        <textarea autoComplete={"autocomplete_off_hack_" + makeId(5)} {...input} id={input.name.replace('.', '_')} className="form-control">

                                                        </textarea>
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                    </>
                                                )}
                                            </Field>
                                        </div>

                                        <div className="form-group">
                                            <label>Is this a multiline number?</label>
                                            <Field name="multilineNumber" component="input" type="checkbox" >
                                                {({ input, meta }) => (
                                                    <input type="checkbox" {...input} id={input.name.replace('.', '_')} autoComplete={"autocomplete_off_hack_" + makeId(5)} className="form-check-label ml-1"></input>

                                                )}
                                            </Field>

                                        </div>
                                    </fieldset>

                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={submitting || pristine}>Port Number</button>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => this.setState({ visible: false })}
                                    >Cancel</button>



                                </div>
                            </form>
                        )} />

                </div></div>

        }

    }
}




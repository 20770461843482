import React, { useEffect, useState } from "react";
import "react-table/react-table.css";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";

import { Link, RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Error from "../../components/error";
import { ICustomer } from "../../interfaces/customer";
import { INumber } from "../../interfaces/number";
import ReceptionistSearchForm from "./receptionistSearchForm";
library.add(faWindowClose, faEdit);

export interface INumberState {
  // newPerson: IPerson;
  loading: boolean;
  customer: ICustomer[];
  error: boolean;
}

const set = new MySettings();
interface ICustomerPage extends RouteComponentProps<any>, IPageProps { }

const CustomerPage: React.FC<ICustomerPage> = (props) => {
  const [data, setData] = useState<INumber[]>([]);
  const [isFirstSearch, setIsFirstSearch] = useState(true);
  const [formValues, setFormValues] = useState();


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // console.log("STATE", resellerPeople);

  const handleSearch = async (searchField: any) => {
    try {
      setLoading(true);

      const customerGetResponse = await set.vrCustomersGet(searchField.query);

      if (isFirstSearch) {
        setFormValues(searchField);
      }

      setIsFirstSearch(false);
      setData(customerGetResponse);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    let isMounted = true;
    //console.log(props);

    const customerGet = async () => {
      try {
        //TODO pass in the search query here
        const customerGetResponse = await set.vrCustomersGet("");
        // console.log("DATA", NumberGetResponse);

        if (isMounted) {
          setData(customerGetResponse);
          console.log(customerGetResponse);

          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };

    customerGet();

    return () => {
      isMounted = false;
    };
  }, []);

  const col = [
    {
      Header: "Customer",
      accessor: "ownerName",
      sortable: true,
    },
    {
      Header: "Greeting",
      accessor: "vrAnswerAs",
      sortable: true,
    },
    {
      Header: "Special Instructions",
      accessor: "vrSpecialInstructions",
      sortable: true,
    },
    {
      Header: "",
      accessor: "id", // String-based value accessors!
      Cell: (props: any) => {
        // console.log(props);
        const link: string = "/vrcustomer/" + props.obj.id;
        return (
          <Link
            to={link}
            className="btn btn-primary"
            onClick={(e) => e.stopPropagation()}
          >
            Select Customer
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <div style={{ width: "300px", marginTop: "30px" }}>
          <ReceptionistSearchForm
            data={formValues}
            onSubmit={handleSearch}
          ></ReceptionistSearchForm>
        </div>

        {loading ? (
          <>
            <div style={{ height: "150px" }}></div>
            <Loading />
          </>
        ) : error ? (
          <Error />
        ) : (
          <>
            <div style={{ height: "50px" }}></div>
            <VoipTable
              title="VR Receptionist"
              data={data}
              col={col}
              sortByStart="name"
              multiSelect={false}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CustomerPage;

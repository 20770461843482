import * as React from "react";

import { Field, Form } from "react-final-form";
import Loading from "../../components/loading";
import SelectAdapter from "../../components/misc/form/SelectAdapter";

import VipTextFinalField from "../../components/misc/form/VipTextFinalField";

import MySettings, { IAreaCode } from "../../static/settings";
// ({ data, onSubmit }: any)

const set = new MySettings();

export interface IAddNewNumberPopupProps {
  data: any;
  onSubmit: any;
  areaCodes: IAreaCode[];
}

export interface IAddNewNumberPopupState {
  data: any;
  loading: boolean;
  visible: boolean;
}

export default class AddNewNumberPopup extends React.Component<
  IAddNewNumberPopupProps,
  IAddNewNumberPopupState
> {
  public state: any = {
    data: [],
    visible: false,
    loading: false,
  };

  // eslint-disable-next-line 
  constructor(props: any) {
    super(props);
  }

  //do not remove, needed by other parts of the app
  public setVisible = (visible: boolean) => {
    this.setState({ visible })
  }

  public submitNew = (data: any) => {
    if (data.areaCode == null || data.areaCode.value == null) {
      return
    }
    this.setState({ loading: true });
    let x: any[] = [];
    set
      .resellerNumbersAlist(data.numbersAmt, data.areaCode.value, false)
      .then((list) => {
        //  values.numbers = [];
        //  delete values.exception;
        for (let index = 0; index < list.items.length; index++) {
          // const num: INumberAllocationRequest = { number: list.items[index], customersId: '', customersSitesId: '' }
          //    values.numbers.push(num);
          x.push({ numberType: "New Number", number: list.items[index], siteIndex: 0 });
        }
        this.setState({ visible: false, loading: false });
        this.props.onSubmit("newNumber", x);
      })
      .catch((exception) => {
        console.log(exception);
        // values.exception = exception;
        // delete values.numbers;
        // next(values);
      });

    //
  };
  public render() {
    if (!this.state.visible) {
      return <></>;
    } else {
      let arrayOfCodes: any[] = [];

      arrayOfCodes = this.props.areaCodes.map((dd: any) => {
        const thing = { value: dd.id, label: dd.city + "(" + dd.id + ")" };
        return thing;
      });

      return (
        <div className="overlay">
          <div className="overlay-content">
            {this.state.loading ? (
              <Loading />
            ) : (
              <Form
                onSubmit={this.submitNew}
                initialValues={this.props.data}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="popupContent text-left test-new-customer-start WHITEN">
                      <fieldset>
                        <legend>New Phone Number</legend>

                        <div className="form-group">
                          <label>How many numbers do you require?</label>
                          <VipTextFinalField
                            type="number"
                            fieldName="numbersAmt"
                            placeholder=""
                            required={true}
                          />
                        </div>
                        {/*
                          <div className="form-group">
                            <label>Consecutive Numbers</label>

                            <Field
                              name="consecutiveNumbers"
                              component="input"
                              type="checkbox"
                            
                              {({ input, meta }) => (
                                <input
                                  autoComplete={
                                    "autocomplete_off_hack_" + makeId(5)
                                  }
                                  type="checkbox"
                                  {...input}
                                  id={input.name.replace(".", "_")}
                                  className="form-check-label ml-1"
                                ></input>
                              )}
                            </Field>
                          </div>
                        */}
                        <div className="form-group">
                          <Field
                            name="areaCode"
                            options={arrayOfCodes}
                            component={SelectAdapter}
                            placeholder="Type area code or city name"
                          />
                        </div>
                      </fieldset>

                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={submitting || pristine}
                      >
                        Add Number
                      </button>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => this.setState({ visible: false })}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
              />
            )}
          </div>
        </div>
      );
    }
  }
}

import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import image from "../compiledSVG/undraw_people_search_wctu.svg";

const pStyle = {
  fontSize: "16px",
  color: "#5b6688",
  marginTop: "2.5rem",
};
const imgStyle = {
  marginTop: "4rem",
};

const SearchPlease = (props: any) => {
  return (
    <Row>
      <Col>
        <div className="text-center">
          <img
            style={imgStyle}
            src={image}
            className="img-fluid mb-3"
            alt="No Results Found"
          />
          <p style={pStyle}>{props.msg ? props.msg : "Please use the form above to search."}</p>
          {props.children && (
            <div className="d-flex justify-content-center mt-3">
              {props.children}
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default SearchPlease;

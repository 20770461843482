import React from "react";

import { Card } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";

import { IMenuOption } from "../interfaces/menu-option";
import PageHead from "../components/PageHead";
import { IPageProps } from "../interfaces/page-props";

import TwoFaJoyride from "../components/tours/TwoFaTour";
import ResellerNotes from "./ResellerNotes";
const compare = (a: IMenuOption, b: IMenuOption) => {
  return a.title > b.title ? 1 : b.title > a.title ? -1 : 0;
};
interface IHomeProps extends RouteComponentProps<any>, IPageProps {
  /* other props for ChildComponent */
  reloadMenu(): Promise<boolean>;
  menuOptions: IMenuOption[];
}

const Home: React.FC<IHomeProps> = (props) => {
  console.log("homeprops", props.menuOptions);
  console.log(localStorage.getItem("type"));

  const dashItems: any[] = [];
  // console.log("dashItems", dashItems);
  if (props.menuOptions !== undefined) {
    let dashData: IMenuOption[] = [];
    if (props.session.userType === "resellersContacts") {
      // reseller acc

      if (props.resellerID !== undefined) {
        // customers home
        dashData = props.menuOptions.filter((x) => x.visibleDash === true);
      } else {
        // resellery home
        dashData = props.menuOptions.filter((x) => x.visibleAdmin === true);
      }
    } else {
      // not a reseller

      dashData = props.menuOptions.filter((x) => x.visibleDash === true);
    }

    dashData.sort(compare);
    // console.log("dashData", dashData);
    let key = 0;
    dashData.map((opt: IMenuOption) => {
      const name = opt.title.split(" ").join("");
      let url = opt.path;

      if (props.session.userType === "resellersContacts") {
        // reseller acc

        if (props.resellerID !== undefined) {
          url = url.replace(":rid", props.resellerID);
        } else {
          url = url.replace("/reseller/:rid", "");
        }
      }

      let svgSrc: string = "/voip/portal/svg/" + name + ".svg";
      if (props.resellerID !== undefined) {
        svgSrc = "/voip/portal/svg/" + name + ".svg";
      }
      dashItems.push(
        <Card className="dashboard_item whiteBox" key={key}>
          {opt.isExternal ? (
            <a href={opt.path} target="_new">
              <img src={svgSrc} className="img-fluid mb-4" alt={opt.title} />
              <h6>{opt.title}</h6>
            </a>
          ) : (
            <Link id={name} to={url}>
              <img src={svgSrc} className="img-fluid mb-4" alt={opt.title} />
              <h6>{opt.title}</h6>
            </Link>
          )}
        </Card>
      );
      key++;
      return true;
    });
  }

  return (
    <div className="content">
      <PageHead
        resellerID={props.resellerID}
        isHome={true}
        title="My Dashboard"
        parents={[]}
      ></PageHead>      

      {props.session.userType === "customersContacts" && <TwoFaJoyride />}
      {props.resellerID && <ResellerNotes resellerID={props.resellerID} />}

      <div className="dashboardItems">{dashItems}</div>
    </div>
  );
};

export default Home;

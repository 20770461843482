import * as React from "react";
import { Field, FieldInputProps } from "react-final-form";
import StandardRetailTariffTable from "../../components/StandardRetailTariffTable";
import requiredValidator from "../../components/misc/form/requiredValidator";
import { Col, Row } from "react-bootstrap";
import { IDemoPageState } from "./Demo";
import { ITariff } from "../../interfaces/customer";
import { FormApi } from "final-form";
const TariffFormA = (
  values: any,
  parentState: IDemoPageState,
  form:FormApi<any, Partial<any>>
) => {
  const onVoipServiceTypeChange = (e:React.ChangeEvent<HTMLInputElement>,input:FieldInputProps<string, HTMLElement>) => {
    input.onChange(e); //final-form's onChange
    const voipServiceType=e.target.value
    let tariffId=''
    let bundleSizeId=''
    if(values.tariffType==='bundle'){
      let currentBundleTariffs=parentState.tariffBundles
      if(voipServiceType==='Domestic'){
        currentBundleTariffs=parentState.domesticBundleTariffs
      }
      if(currentBundleTariffs.length>0){
        bundleSizeId=currentBundleTariffs[0].id
      }
    }else{
      let currentPPMTariffs=parentState.tariffs
      if(voipServiceType==='Domestic'){
        currentPPMTariffs=parentState.domesticPPMTariffs
      }
      if(currentPPMTariffs.length>0){
        tariffId=currentPPMTariffs[0].id
      }
    }
    form.change('tariff',tariffId)
    form.change('bundleSizeId',bundleSizeId)
  }
  
  let currentBundleTariffs = parentState.tariffBundles
  let currentPPMTariffs = parentState.tariffs
  if(values.voipServiceType==='Domestic'){
    currentBundleTariffs = parentState.domesticBundleTariffs
    currentPPMTariffs = parentState.domesticPPMTariffs
  }
      return (<>
        <div className="wizard4__right-heading-div">
          <p className="wizard4__right-heading">Tariff</p>
        </div>
  
        
  
        <Row>
          Please decide if this customer is a business or domestic customer.<br />
          Domestic customers are restricted to a single user but have acces to domestic only call tariffs.
        </Row>
        <Row>
          <Col>
            <Field
              name="voipServiceType"
              component="input"
              type="radio"
              value="Business"
              validate={requiredValidator}
            >
              {({ input }) => {
                return (
                  <input {...input}
                    onChange={(e) => {
                      onVoipServiceTypeChange(e,input)
                    }}
                  />
                );
              }}
            </Field>
            <label title="" className="tarif_label">
              {" "}
              Business
            </label>
          </Col>
          <Col>
            <Field
              name="voipServiceType"
              component="input"
              type="radio"
              value="Domestic"
              validate={requiredValidator}
            >
              {({ input }) => {
                return (
                  <input {...input}
                    onChange={(e) => {
                      onVoipServiceTypeChange(e,input)
                    }}
                  />
                );
              }}
            </Field>
            <label title="" className="tarif_label">
              {" "}
              Domestic
            </label>
          </Col>
        </Row>

        <Row>
          &nbsp;
        </Row>
  
        <Row>
          Please choose the call tariff for the customer.
        </Row>
  
        <Row>
          <Col>
            <Field
              name="tariffType"
              component="input"
              type="radio"
              value="PPM"
              validate={requiredValidator}
            >
              {({ input }) => {
                return (
                  <input
                    {...input}
                    onChange={(e) => {
                      input.onChange(e); //final-form's onChange
                      form.change('bundleSizeId','')
                      if(currentPPMTariffs.length>0){
                        form.change('tariff',currentPPMTariffs[0].id)
                      }
                    }}
                  />
                );
              }}
            </Field>
            <label title="" className="tarif_label">
              {" "}
              Pence Per Minute
            </label>
          </Col>
          <Col>
            <Field
              name="tariffType"
              component="input"
              type="radio"
              value="bundle"
              validate={requiredValidator}
            >
              {({ input, meta }) => {
                return (
                  <input
                    {...input}
                    onChange={(e) => {
                      input.onChange(e); //final-form's onChange
                      form.change('tariff','')
                      if(currentBundleTariffs.length>0){
                        form.change('bundleSizeId',currentBundleTariffs[0].id)
                      }
                    }}
                  />
                );
              }}
            </Field>
            <label title="" className="tarif_label">
              {" "}
              Call Bundles
            </label>
          </Col>
        </Row>

        <Row>
          &nbsp;
        </Row>
  
          {values.tariffType === "PPM" && (
            <div className="form-row">
              <label className="mr-sm-2 form-label sr-only">Preference</label>
              <Field name="tariff" component="select">
                {({ input, meta }) => (
                  <>
                    <select
                      {...input}
                      id={input.name.replace(".", "_")}
                      className="form-control"
                      onChange={(e) => {
                        input.onChange(e); //final-form's onChange
                      }}
                    >
                      <option value="na">Standard Retail Tariff</option>
                      {currentPPMTariffs.map((t: ITariff, index: number) => {
                        return (
                          <option key={t.id} value={t.id}>
                            {t.name+' - '+t.description}
                          </option>
                        );
                      })}
                    </select>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </>
                )}
              </Field>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <StandardRetailTariffTable />
              </div>
            </div>
          )}
  
          {values.tariffType === "bundle" && (
            <>
              <div className="form-group">
                <div className="my-1 col-auto">
                  <label className="mr-sm-2 form-label">Bundle Size</label>
                  <Field component="select" name="bundleSizeId">
                    {({ input, meta }) => (
                      <div>
                        <select
                          {...input}
                          id={input.name.replace(".", "_")}
                          className="form-control"
                          onChange={(e) => {
                            input.onChange(e); //final-form's onChange
                          }}
                        >
                          {currentBundleTariffs.map((tariff: ITariff) => {
                            return (
                              <option key={tariff.id} value={tariff.id}>
                                {tariff.name+' - '+tariff.description}
                              </option>
                            );
                          })}
                        </select>
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            </>
          )}
      </>)
};

export default TariffFormA;

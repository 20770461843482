import React, { useEffect, useState } from "react";
import MySettings from "../static/settings";

import { IPageProps } from "../interfaces/page-props";
import Loading from "../components/loading";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import PageHead from "../components/PageHead";
import { INotification } from "../interfaces/notification";
import { NOTIFICATION_TYPE } from "../enum/notification-type";
import { RouteComponentProps } from "react-router-dom";
import App from "../App";
import { IConnectwiseContactsAuthedResponse } from "../static/settings";

const set = new MySettings();

interface RouteParams {}

const ConnectwiseContactsPage: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = () => {
  const [newsettings, setSettings] = useState<IConnectwiseContactsAuthedResponse>({
    connectwiseContactsEnabled: false,
    connectwiseContactsCompany: "",
    connectwiseContactsPublicKey: "",
    connectwiseContactsPrivateKey: "",
    connectwiseContactsValidated: false,
    connectwiseContactsURL:'',
    connectwiseContactsLastSync:''
  });

  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    let isMounted = true;

    const getSettings = async () => {
      try {
        const data = await set.connectwiseContactsIsAuthed();
        if (isMounted) {
          setSettings(data);
          setLoading(false);
        }
      } catch (error) {
        App.newNotificationError(
          "Failed to load Connectwise configuration " + error.data
        );
      }
    };

    getSettings();

    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    try {
      const updatedsettings = await set.connectwiseContactsConfigureUpdate(newsettings);
      setSettings(updatedsettings);
      const notification: INotification = {
        title: "Configuration Updated",
        data: "The Connectwise configuration has been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification);
    } catch (err) {
      App.newNotificationError(
        "Failed to update the Connectwise configuration " + err.data
      );
    }
    setLoading(false);
  }

  const onEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...newsettings,
      connectwiseContactsEnabled: event.currentTarget.checked,
    });
  };

  const onStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.currentTarget.name;
    let settings: { [index: string]: any } = { ...newsettings };
    settings[fieldName] = event.currentTarget.value;
    let settings2: any = { ...settings };
    setSettings(settings2);
  };

  return (
    <div className="content">
      <PageHead title="Connectwise Contact Fetch Configuration" parents={[]}></PageHead>

      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={onSubmit}>
          <Row className="whiteBox">
            <Col md="12" lg="12">
              <Card>
                <Card.Header>
                  <h2>Connectwise Contact Fetch Configuration</h2>
                </Card.Header>
                <Card.Body className="form-card-body">
                  {newsettings.connectwiseContactsValidated ? (
                    <div>Credentials validated. Your contacts should be fetched from Connectwise in to a phone book called Connectwise roughly once per hour. The last sync was at {newsettings.connectwiseContactsLastSync.replace('T',' ')}</div>
                  ) : (
                    <div>Not validated. Your contacts will not be synchronised until the credentials have been validated. To validate the credentials please submit valid credentials.</div>
                  )}
                  The below credentials should have been made available to you from Connectwise when you set up your API access.
                  <Form.Group controlId="connectwiseContactsEnabled">
                    <Form.Label>Enable Connectwise Contact Sync:</Form.Label>
                    <Form.Control
                      type="checkbox"
                      checked={newsettings.connectwiseContactsEnabled}
                      onChange={onEnabledChange}
                      name="connectwiseContactsEnabled"
                    />
                  </Form.Group>
                  <Form.Group controlId="connectwiseContactsURL">
                    <Form.Label>Connectwise Base URL (can be left blank if you are in the EU):</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={newsettings.connectwiseContactsURL}
                      onChange={onStringChange}
                      name="connectwiseContactsURL"
                    />
                  </Form.Group>
                  <Form.Group controlId="connectwiseContactsCompany">
                    <Form.Label>Connectwise Company:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={newsettings.connectwiseContactsCompany}
                      onChange={onStringChange}
                      name="connectwiseContactsCompany"
                    />
                  </Form.Group>
                  <Form.Group controlId="connectwiseContactsPublicKey">
                    <Form.Label>Connectwise Public Key:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={newsettings.connectwiseContactsPublicKey}
                      onChange={onStringChange}
                      name="connectwiseContactsPublicKey"
                    />
                  </Form.Group>
                  <Form.Group controlId="privateKey">
                    <Form.Label>Connectwise Private Key:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={newsettings.connectwiseContactsPrivateKey}
                      onChange={onStringChange}
                      name="connectwiseContactsPrivateKey"
                    />
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Button variant="primary" type="submit">
                    Update Configuration
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};
export default ConnectwiseContactsPage;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";

import { RouteComponentProps } from "react-router-dom";
import { NOTIFICATION_TYPE } from "../enum/notification-type";
import { INotification } from "../interfaces/notification";
import { IPageProps } from "../interfaces/page-props";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import queryString from "query-string";
import Helmet from "react-helmet";
import App from "../App";
import BrandedLogo from "../components/BrandedLogo";
import Loading from "../components/loading";
import MySettings from "../static/settings";

interface ILoginPasswordResetProps
  extends RouteComponentProps<any>,
    IPageProps {}

const set = new MySettings();

const LoginPasswordReset: React.FC<ILoginPasswordResetProps> = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userNameSetFromLocation, setUserNameSetFromLocation] = useState(false);
  useEffect(() => {
    if (userNameSetFromLocation) {
    } else {
      let username = "";
      const parsed = queryString.parse(window.location.search);
      if (parsed.username != null) {
        if (typeof parsed.username === "string") {
          username = parsed.username;
        } else if (parsed.username.length > 0) {
          username = parsed.username[0];
        }
      }
      if (userName !== username) {
        setUserName(username);
      }
      setUserNameSetFromLocation(true);
    }
  }, [
    userName,
    setUserName,
    userNameSetFromLocation,
    setUserNameSetFromLocation,
  ]);

  if (props.session.loggedIn) {
    props.history.push("/");
  }

  const startResetPassword = async (
    emailAddress: string,
    isReseller: boolean
  ) => {
    const resetRequest = {
      emailAddress: emailAddress,
      isReseller: isReseller,
    };
    setIsLoading(true);
    try {
      const data = await axios.post(set.getResetPassword(), resetRequest, {
        withCredentials: true,
      });
      if (data.data.success) {
        const notification: INotification = {
          title: "Password Reset",
          data: "Your password has been reset. Please check your email for a link to set your new password.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "LoginPasswordReset",
          srcData: "success",
          srcFunction: "startResetPassword",
        };
        App.newNotification(notification);
      } else {
        let errorMessage =
          "There was a problem resetting your password. Please try again.";
        if (data.data.error) {
          errorMessage = errorMessage + " " + data.data.error;
        }
        App.newNotificationError(errorMessage);
      }
    } catch (err) {
      App.newNotificationError(
        "There was a problem resetting your password. Please try again."
      );
    }
    setIsLoading(false);
  };

  return <>      
    <Helmet>
    <title>{props && props.branding && props.branding.name} Password Reset</title>
  </Helmet>

    <Form
      onSubmit={async ({ emailAddress, isReseller }) => {
        await startResetPassword(emailAddress, isReseller);
      }}
      initialValues={{ emailAddress: userName }}
      validate={(values) => {
        const errors: any = {};

        const requiredFields = ["emailAddress"];

        requiredFields.forEach((requiredField) => {
          if (!values[requiredField]) {
            errors[requiredField] = "Required.";
          }
        });

        return errors;
      }}
      render={({ handleSubmit, submitting, dirtySinceLastSubmit }) => (
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "368px", margin: "auto" }}
          noValidate
        >
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} style={{ marginBottom: "32px" }}>
              <Box display="flex" justifyContent="center">
                <BrandedLogo />
              </Box>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "16px" }}>
              <Typography variant="h6" align="center">
                Please enter the email address.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Email Address</Typography>
              <Field name="emailAddress">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="emailAddress"
                    fullWidth
                    required
                    type="email"
                    variant="outlined"
                    size="small"
                    error={
                      (meta.error && meta.touched) ||
                      (!!meta.submitError &&
                        !dirtySinceLastSubmit &&
                        !submitting)
                    }
                    helperText={
                      meta.error && meta.touched
                        ? meta.error
                        : !!meta.submitError &&
                          !dirtySinceLastSubmit &&
                          !submitting
                        ? meta.submitError
                        : ""
                    }
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field name="isReseller" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ name: "isReseller" }}
                        {...input}
                        value="isReseller"
                        color="primary"
                      />
                    }
                    label="Are you a reseller?"
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              {isLoading ? (
                <Loading size="80px" />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isLoading}
                  type="submit"
                  size="large"
                  style={{ height: "56px" }}
                >
                  Reset Password
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    />
    </>;
};

export default LoginPasswordReset;

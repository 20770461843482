

import { Grid } from "@material-ui/core";
import MuiTitle from "../misc/form/MuiTitle";
import VipTimeFieldMui from "../VipTimeFieldMui";
import { useState } from "react";
import MuiToggleBoolControlled from "../misc/form/MuiToggleBoolControlled";

import { IEmailSubReportCustomPresenceChanges } from "../../interfaces/email-reports";


export interface iEmailReportCustomPresenceProp {
  data: IEmailSubReportCustomPresenceChanges;
  submitFunction: (field: string, data: any) => void;
}
const EmailReportCustomPresence = ({ data, submitFunction }: iEmailReportCustomPresenceProp) => {
  const [state, setState] = useState<IEmailSubReportCustomPresenceChanges>(() => {
    //  console.log(hotDesks)
    const xState = { ...data };
    if (!xState.startTime) {
      xState.startTime = "08:00:00"
      submitFunction("startTime","08:00:00")
    }
    if (!xState.endTime) {
      xState.endTime = "18:00:00"
      submitFunction("endTime","18:00:00")
      
    }
    if (!xState.customPresenceHideLoggedOut) {
      xState.customPresenceHideLoggedOut = false;
      submitFunction("customPresenceHideLoggedOut",false)
    }

    if (!xState.customPresenceShowOnlyHotDesk) {
      xState.customPresenceShowOnlyHotDesk = false;
      submitFunction("customPresenceShowOnlyHotDesk",false)
    }



    return xState;
  });
  const setField = (field: string, value: any) => {
    console.log("subform", field, value);
    const stateUp: any = { ...state };
    stateUp[field] = value;
    setState(stateUp);
    submitFunction(field, value);
  }
  return <Grid container spacing={2}>

    <Grid item xs={6}>
      <MuiTitle title="Start Time" />
      <VipTimeFieldMui
        field="startTime"
        value={state.startTime ? state.startTime : "08:00:00"}
        updateField={setField}
      />

    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="End Time" />
      <VipTimeFieldMui
        field="endTime"
        value={state.startTime ? state.endTime : "18:00:00"}
        updateField={setField}
      />
    </Grid>

    <Grid item xs={3}>
      <MuiTitle title="Show only hot desk agents?" />
      <MuiToggleBoolControlled value={state.customPresenceHideLoggedOut} setValue={(ev) => {
        setField("customPresenceHideLoggedOut", !state.customPresenceHideLoggedOut);
      }} />

    </Grid>
    <Grid item xs={3}>
      <MuiTitle title="Hide agents who did not log in?" />
      <MuiToggleBoolControlled value={state.customPresenceShowOnlyHotDesk} setValue={(ev) => {
        setField("customPresenceShowOnlyHotDesk", !state.customPresenceShowOnlyHotDesk);
      }} />

    </Grid>

  </Grid>
}
export default EmailReportCustomPresence;
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import App from "../App";
import Loading from "../components/loading";
import ScrollToTop from "../components/misc/ScrollToTop";
import { NOTIFICATION_TYPE } from "../enum/notification-type";
import MySettings from "../static/settings";

interface IInternetServiceEnqiryAcceptQuote {
  quoteAccepted: string
}

interface IInternetServiceEnquiryRejectQuotes {
  quoteRejectedNotes: string
}

interface IInternetServiceEnqiryQuoteLine {
  productName: string
  quantity: number
  wholesalePrice: number
  retailPrice: number
}

interface IInternetServiceEnquiryQuote {
  contractLengthMonths: number
  oneOffLines: IInternetServiceEnqiryQuoteLine[]
  recurringLines: IInternetServiceEnqiryQuoteLine[]
}

const set = new MySettings();

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const InternetServiceEnquiry: React.FC<any> = ({
  match,
  history: {
    location: { state },
  },
}) => {
  const [inquiry, setInquiry] = useState(state || {});
  const [loading, setLoading] = useState(true);

  const getService = async (isMounted: any) => {
    try {
      const result = await set.resellerCustomerDetailsGet(match.params.rid);
      const filteredResult = result.services.filter(
        (service: { id: string }) => service.id === match.params.id
      );
      // console.log("result", result);
      // console.log("filteredResult", filteredResult);

      if (isMounted.isMounted) {
        setInquiry(filteredResult[0]);
        setLoading(false);
      }
    } catch (error) {
      App.newNotificationError("Problem loading service.");
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = {
      isMounted: true
    };

    if (!state) {
      getService(isMounted);
    } else {
      setLoading(false);
    }

    return () => {
      isMounted.isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, match.params.rid, state]);

  const onSubmit = async (data: IInternetServiceEnqiryAcceptQuote) => {
    try {
      setLoading(true);
      await set.resellerInternetServiceEnquiryAccept(
        match.params.id,
        data.quoteAccepted, ''
      );
      App.newNotification({
        title: "Success",
        data: "Quote accepted successfully.",
        variant: NOTIFICATION_TYPE.success,
        srcFunction: "handleSubmit",
        srcData: window.location.pathname,
        srcComponent: "InternetServiceEnquiry",
      });
      getService({ isMounted: true });
    } catch (error) {
      App.newNotificationError("Problem accepting quote.");
    }
  }

  const onSubmitRejectAll = async (data: IInternetServiceEnquiryRejectQuotes) => {
    try {
      setLoading(true);
      await set.resellerInternetServiceEnquiryAccept(
        match.params.id,
        '-1', data.quoteRejectedNotes
      );
      App.newNotification({
        title: "Success",
        data: "Quote rejected successfully.",
        variant: NOTIFICATION_TYPE.success,
        srcFunction: "handleSubmit",
        srcData: window.location.pathname,
        srcComponent: "InternetServiceEnquiry",
      });
      getService({ isMounted: true });
    } catch (error) {
      App.newNotificationError("Problem rejecting quote.");
    }
  }

  // console.log("service props", match);
  // console.log("service state", inquiry);

  return (
    <div className="content mt-5">
      <ScrollToTop></ScrollToTop>
      {loading ? (
        <Loading />
      ) : (
        <Card>
          <Card.Header>
            <h2 className="my-auto">{inquiry.name}</h2>
          </Card.Header>
          <Card.Body>
            <div>Created at:</div>
            <h5 className="mb-3">
              {format(new Date(inquiry.dateCreated), "yyyy-MM-dd")}
            </h5>
            <div>New or existing?</div>
            <h5 className="mb-3">
              {capitalizeFirstLetter(
                inquiry.internetServiceEnquiryNewOrExisting
              )}
            </h5>
            <div>Reseller email address</div>
            <h5 className="mb-3">
              {inquiry.internetServiceEnquiryResellerEmailAddress}
            </h5>
            <div>Details</div>
            <h5 className="mb-3">
              {inquiry.internetServiceEnquirySpeedRequired}
            </h5>
            <div>Notes</div>
            <h5 className="mb-3">
              {inquiry.notes}
            </h5>
            <div>Site:</div>
            <h5 className="mb-3">{inquiry.siteName}</h5>
            <div>Status:</div>
            <h5 className="mb-3">{inquiry.status}</h5>

            {inquiry.status === "Pending" &&
              inquiry.internetServiceEnquiryQuotes != null && inquiry.internetServiceEnquiryQuotes.length > 0 ? (
              <>
                <Form onSubmit={onSubmit} render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <h5>
                        To accept this quote and start the order process please choose one of the following quotes:
                      </h5>
                      {inquiry.internetServiceEnquiryQuotes.map((quote: IInternetServiceEnquiryQuote, index: number) => (
                        <Row key={index}>
                          <Col>
                            <h5>Contract Length</h5>
                            {quote.contractLengthMonths} Months
                          </Col>
                          <Col>
                            <h5>Setup Fees</h5>
                            {quote.oneOffLines.map((line, index) => (<div key={index}>{line.quantity} X {line.productName} &pound;{line.wholesalePrice.toFixed(2)} (RRP &pound;{line.retailPrice.toFixed(2)})</div>))}
                          </Col>
                          <Col>
                            <h5>Monthly Fees</h5>
                            {quote.recurringLines.map((line, index) => (<div key={index}>{line.quantity} X {line.productName} &pound;{line.wholesalePrice.toFixed(2)} (RRP &pound;{line.retailPrice.toFixed(2)})</div>))}
                          </Col>
                          <Col>
                            <Field
                              name="quoteAccepted"
                              component="input"
                              type="radio"
                              value={index + ''}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Button
                        className="noleft"
                        type="submit"
                      >
                        Accept
                      </Button>
                    </div>
                  </form>
                )} />
                <Form onSubmit={onSubmitRejectAll} render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <h5>
                        To reject the quotes above please give some feedback and click Reject.
                      </h5>
                      <Row>
                        <Col>
                          Rejection notes: <Field name="quoteRejectedNotes" component="input" type="text" />
                        </Col>
                      </Row>
                      <Button
                        className="noleft"
                        type="submit"
                      >
                        Reject
                      </Button>
                    </div>
                  </form>
                )} />
              </>
            ) : inquiry.status === 'Accepted' ? (
              <div>
                The quote has been accepted.
                <br />
                <div dangerouslySetInnerHTML={{ __html: inquiry.internetServiceEnquiryAcceptedQuoteString }} />
              </div>
            ) : (
              <div>The quote has been rejected.</div>
            )}
          </Card.Body>
        </Card>
      )}
    </div>
  );
};
export default InternetServiceEnquiry;

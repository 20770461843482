
import * as React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useState } from 'react'
import 'date-fns';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'react-day-picker/lib/style.css';
import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
// Simple footer component

// table object properties
export interface IVipDateieldProps {
  placeholder: string;
  updateField: (payload: any) => void;
}

//
export interface IVipTimeFieldState {
  selectedDay: any;
}


function parseDate(str: any, format: any, locale: any) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date: any, format: any, locale: any) {
  return dateFnsFormat(date, format, { locale });
}



export default class VipDateField extends React.Component<IVipDateieldProps, any> {
  myRef: any;

  constructor(props: any) {
    super(props);

    this.state = { selectedDay: undefined };
    this.handleDayChangeStart.bind(this);
  }



  public handleDayChangeStart = (day: any, modifiers: any, dayPickerInput: any) => {
    //const input = dayPickerInput.getInput();

    // console.log(day,dayPickerInput , input);
    if (day === undefined) {

    } else {
      this.setState({ selectedDay: day });
      //  dayPickerInput.focus();
      this.props.updateField(day)
    }

  }
  handleDayChange = (selectedDay: any, modifiers: any, dayPickerInput: any) => {
    // const input = dayPickerInput.getInput();
    this.setState({
      selectedDay,
      //  isEmpty: !input.value.trim(),
      isValidDay: typeof selectedDay !== 'undefined',
      isDisabled: modifiers.disabled === true,
    });
  }


  public onBlur = () => {
    // console.log('sss');
  }


  public render() {
    //  const {currentHr, currentMin} = this.state;



    const cssClasesForDatePickers = {
      container: 'form-control2',           // The overlay's container
      overlayWrapper: 'DayPickerInput-OverlayWrapper',
      overlay: 'DayPickerInput-Overlay'
    }
    const FORMAT = 'dd/MM/yyyy';
    return (
      <DayPickerInput
        classNames={cssClasesForDatePickers}

        placeholder={this.props.placeholder}
        //   component={(props: any) => <input className="form-control callLogsDateFrom" {...props} />}
        onDayChange={this.handleDayChangeStart}
        formatDate={formatDate}
        format={FORMAT}
        parseDate={parseDate} />)
  }
}


export const MuiVipDatePicker = ({ placeholder, updateField }: IVipDateieldProps) => {
  const [selectedDate, setSelectedDate] = useState(() => {
    console.log(placeholder)
    var parts = placeholder.split("/");
    var dt = new Date(parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10));
    return dt
  })
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    updateField(date)
  };
  return <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      disableToolbar
      variant="inline"
      format="dd/MM/yyyy"
      margin="normal"
      fullWidth
      id="date-picker-inline"
      value={selectedDate}
      onChange={handleDateChange}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
    />

  </MuiPickersUtilsProvider>
}

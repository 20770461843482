import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { format, formatDuration, intervalToDuration } from "date-fns";

import { Grid } from "@material-ui/core";
import IncomingCallStatsSearchForm from "./IncomingCallStatsSearchForm";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import Error from "../../components/error";
import ResellerRedirect from "../../components/ResellerRedirect";
import SearchPlease from "../../components/SearchPlease";
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";

import MySettings from "../../static/settings";

import {
  IIncomingCallStatsHttpResponse,
  IIncomingCallStat,
  IIncomingCallStatsHttpRequest,
  IIncomingCallStatsSearchForm,
} from "../../interfaces/incoming-call-stats";
import {
  INumberTrackersGetHttpResponse,
  INumberTracker,
  INumbersHttpResponse,
  INumber,
} from "../../interfaces/number";
import { IPageProps } from "../../interfaces/page-props";
import App from "../../App";

export interface ICallStatState {
  numbers: string[];
  customers: string[];
  areas: string[];
  stats: IIncomingCallStat[];
}

const set = new MySettings();

interface IIncomingCallStatsPage extends RouteComponentProps<any>, IPageProps {}

enum PageState {
  ShowTable,
  Loading,
  NetworkError,
  NoResults,
  FirstLoad,
}

const IncomingCallStats: React.FC<IIncomingCallStatsPage> = (props) => {
  const [data, setData] = useState<ICallStatState>({
    numbers: [],
    customers: [],
    areas: [],
    stats: [],
  });

  const [pageState, setPageState] = useState(PageState.Loading);

  useEffect(() => {
    const getData = async () => {
      try {
        const numberSearchResults: INumbersHttpResponse =
          await set.numberSearch("", "", props.resellerID);

        const numberTrackersGetResults: INumberTrackersGetHttpResponse =
          await set.numberTrackersGet("", "", "", props.resellerID);

        const numbersList = numberSearchResults.numbers.map(
          (number: INumber) => number.name
        );

        const customersList = numberTrackersGetResults.items
          .map((customer: INumberTracker) => customer.owner)
          .filter((customer) => customer !== "");

        const areasList = numberTrackersGetResults.items
          .map((area: INumberTracker) => area.area)
          .filter((area) => area !== "");

        setData((prevState: ICallStatState) => {
          return {
            ...prevState,
            numbers: ["All Numbers", ...numbersList],
            customers: ["All Customers", ...customersList],
            areas: ["All Areas", ...areasList],
          };
        });

        setPageState(PageState.FirstLoad);
      } catch (error) {
        setPageState(PageState.NetworkError);
      }
    };

    getData();
  }, [props.resellerID, props.session.userType]);

  const onSubmit = async (searchData: IIncomingCallStatsSearchForm) => {
    const search: IIncomingCallStatsHttpRequest = {
      startTime: format(searchData.startTime, "yyyy-MM-dd"),
      endTime: format(searchData.endTime, "yyyy-MM-dd"),
      owner: searchData.owner === "All Customers" ? "" : searchData.owner,
      destination:
        searchData.destination === "All Numbers" ? "All" : searchData.destination,
      area: searchData.area === "All Areas" ? "" : searchData.owner,
      notes: searchData.notes,
      customersId: props.resellerID,
    };
    
    try {
      setPageState(PageState.Loading);
      const incomingCallStats: IIncomingCallStatsHttpResponse =
        await set.incomingCallStatsGet(search);

      setData((prevState: ICallStatState) => {
        return {
          ...prevState,
          stats: incomingCallStats.items,
        };
      });

      setData({ ...data, stats: incomingCallStats.items });

      setPageState(PageState.ShowTable);
    } catch (error) {
      if(error!=null){
        let err=error as any
        if(err.data!=null){
          App.newNotificationError(err.data)
        }
      }
      setPageState(PageState.NetworkError);
    }
  };

  const columns = [
    {
      name: "datetime",
      label: "Time/Date",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => {
          const element = data.stats[dataIndex];
          return format(new Date(element.datetime), "yyyy-MM-dd HH:mm:ss");
        },
      },
    },
    {
      name: "destination",
      label: "Destination",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "source",
      label: "Source",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "owner",
      label: "Customer",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "area",
      label: "Area",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "note",
      label: "Note",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "timeToAnswer",
      label: "TTA",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => {
          const element = data.stats[dataIndex];

          const dateFnsDurationObj = intervalToDuration({
            start: 0,
            end: element.duration * 1000,
          });

          if (dateFnsDurationObj.days) {
            dateFnsDurationObj.hours! += dateFnsDurationObj.days * 24;
          }

          const formatted = formatDuration(dateFnsDurationObj, {
            format: dateFnsDurationObj.hours
              ? ["hours", "minutes", "seconds"]
              : ["minutes", "seconds"],
            zero: true,
            delimiter: ":",
            locale: {
              formatDistance: (_token, count) => String(count).padStart(2, "0"),
            },
          });

          return formatted;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "extension",
      label: "Ext",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  if (
    props.resellerID === undefined &&
    props.session.userType === "resellersContacts"
  )
    return <ResellerRedirect />;

  const RenderSwitch = () => {
    switch (pageState) {
      case PageState.Loading:
        return <Loading />;

      case PageState.ShowTable:
        return (
          <MuiTableWrapper
            title="Incoming Call Stats"
            data={data.stats}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: false,
              search: false,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "datetime",
                direction: "asc",
              },
            }}
          />
        );

      case PageState.FirstLoad:
        return (
          <SearchPlease msg="Please choose a number and begin searching." />
        );

      case PageState.NetworkError:
        return <Error />;
      default:
        return <></>;
    }
  };

  return (
    <Grid container spacing={3} alignContent="flex-start">
      <Grid item xs={12}>
        <PageHead
          resellerID={props.resellerID}
          title="Incoming Call Stats"
          parents={[{ title: "Call Logs", url: "calllogs" }]}
        />
      </Grid>

      <Grid item xs={12}>
        <IncomingCallStatsSearchForm
          areas={data.areas}
          numbers={data.numbers}
          customers={data.customers}
          onSubmit={onSubmit}
        />
      </Grid>

      <Grid item xs={12}>
        <RenderSwitch />
      </Grid>
    </Grid>
  );
};

export default IncomingCallStats;

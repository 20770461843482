import { Dialog, DialogTitle } from "@material-ui/core";

interface iMuiModal {
    open: boolean;
    title: string;
    onClose: () => void;
    children: JSX.Element;
}


const MuiModal = ({ open, onClose, title, children }: iMuiModal) => {

    console.log(open)

    return <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle>{title}</DialogTitle>
        {children}
    </Dialog>
}

export default MuiModal;
import React, { useState, useEffect, useRef } from "react";
import { addDays, format, formatDuration, intervalToDuration, startOfDay } from "date-fns";
import { RouteComponentProps } from "react-router-dom";

import { IPageProps } from "../../interfaces/page-props";
import { ICallLogsFormState } from "../../interfaces/calllog";
import {
  IVoipServicesRequest,
  IVoipService,
} from "../../interfaces/interfaces";
import { ICallLog } from "../../interfaces/calllog";
import { ICallLogsHttpResponse } from "../../interfaces/calllog";

import MySettings from "../../static/settings";

import { Grid } from "@material-ui/core";
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";
import CallLogSearchForm from "./CallLogSearchForm";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import ResellerRedirect from "../../components/ResellerRedirect";
import Error from "../../components/error";
import SearchPlease from "../../components/SearchPlease";
import MuiDropdownButton, {
  IDropdownButtonProps,
} from "../../components/MuiComponents/MuiDropdownButton";



enum PageState {
  ShowTable,
  Loading,
  NetworkError,
  NoResults,
  FirstLoad,
}

const set = new MySettings();

interface ICallLogPage extends RouteComponentProps<any>, IPageProps { }

const CallLog: React.FC<ICallLogPage> = (props) => {
  const [pageState, setPageState] = useState(PageState.Loading);
  const [services, setServices] = useState<IVoipService[]>([]);
  const [results, setResults] = useState<ICallLog[]>([])
  const [downloadLink, setDownloadLink] = useState<string>();
  const isMounted = useRef(true);


  const [currentSearch, setCurrentSearch] = useState<any>();

  const onSubmitSearch = async (values: ICallLogsFormState): Promise<void> => {
    setCurrentSearch(
      values
    )
    const reformattedValues = {
      ...values,
      startDate: format(new Date(Date.parse(values.startDate)), "yyyy-MM-dd"),
      endDate: format(new Date(Date.parse(values.endDate)), "yyyy-MM-dd"),
      startTime: format(new Date(Date.parse(values.startTime)), "HH:mm:ss"),
      endTime: format(new Date(Date.parse(values.endTime)), "HH:mm:ss"),
    };

    setPageState(PageState.Loading);

    try {
      let downloadLink = set.getCallLogDownloadHref();
      downloadLink +=
        "?json=false&splitDateTime=false&incoming=" +
        values.incoming +
        "&customersServiceId=" +
        values.customersSitesServicesId +
        "&startDate=" +
        reformattedValues.startDate +
        "&endDate=" +
        reformattedValues.endDate
        +"&extension="+reformattedValues.extension;

      setDownloadLink(downloadLink);

      const result: ICallLogsHttpResponse = await set.callLogsGet(
        reformattedValues
      );

      setResults(result.items);

      setPageState(PageState.ShowTable);
    } catch (error) {
      setPageState(PageState.NetworkError);
    }
  }

  useEffect(() => {
    if (isMounted.current && services.length===0) {
      try {
        const getServices = async () => {
          const dateMemo = new Date();
          const startOfDateMemo = startOfDay(dateMemo);
          const dateMemoOneDayAhead = addDays(dateMemo, 1);

          const result: IVoipServicesRequest = await set.getVoipServicesCall(
            false,
            props.session.userType === "resellersContacts"
              ? props.resellerID
              : undefined
          );

          setServices(result.services);
          const inital:any={
            missedOnly: false,
            incoming: true,
            splitDateTime: false,
            customersSitesServicesId: result.services?.length ? result.services[0].id : "",
            number: "",

            startTime: startOfDateMemo.toISOString(),
            endTime: startOfDateMemo.toISOString(),
            startDate: dateMemo.toISOString(),
            endDate: dateMemoOneDayAhead.toISOString()
          }
          await onSubmitSearch(inital)
        };
        getServices();
      } catch (error) {
        setPageState(PageState.NetworkError);
      }
    }

    return () => {
      isMounted.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, pageState, props.resellerID, props.session.userType]);

  const dropdownOptions: IDropdownButtonProps["options"] = [];

  if (results.length > 0) {
    dropdownOptions.push({
      label: "Download",
      link: downloadLink,
      target: '_blank'
    });
  }

  dropdownOptions.push({
    label: "Incoming Call Stats",
    link: "./incomingcallstats",
    target: ''
  });

  const columns = [
    {
      name: "datetime",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => {
          const element = results[dataIndex];
          return format(new Date(element.datetime), "yyyy-MM-dd HH:mm:ss");
        },
      },
    },
    {
      name: "source",
      label: "Source",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "destination",
      label: "Dest",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => {
          const element = results[dataIndex];

          const dateFnsDurationObj = intervalToDuration({
            start: 0,
            end: element.duration * 1000,
          });

          if (dateFnsDurationObj.days) {
            dateFnsDurationObj.hours! += dateFnsDurationObj.days * 24;
          }

          const formatted = formatDuration(dateFnsDurationObj, {
            format: dateFnsDurationObj.hours
              ? ["hours", "minutes", "seconds"]
              : ["minutes", "seconds"],
            zero: true,
            delimiter: ":",
            locale: {
              formatDistance: (_token, count) => String(count).padStart(2, "0"),
            },
          });

          return formatted;
        },
      },
    },
    {
      name: "timeToAnswer",
      label: "TTA",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "extension",
      label: "Ext",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "extensionCallerId",
      label: "Ext CallerID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "notes",
      label: "Notes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "area",
      label: "Area",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const RenderSwitch = () => {
    switch (pageState) {
      case PageState.Loading:
        return <Loading />;

      case PageState.ShowTable:
        return (
          <MuiTableWrapper
            title=""
            data={results}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: false,
              search: true,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "datetime",
                direction: "asc",
              },
            }}
          />
        );

      case PageState.FirstLoad:
        return <SearchPlease />;

      case PageState.NetworkError:
        return <Error />;
      default:
        return <></>;
    }
  };

  if (
    props.resellerID === undefined &&
    props.session.userType === "resellersContacts"
  ) {
    return <ResellerRedirect />;
  }

  return (
    <Grid container spacing={3} alignContent="flex-start">
      <Grid item xs={12}>
        <PageHead
          resellerID={props.resellerID}
          title="Call Logs"
          parents={[]}
          subpages={[]}
        >
          <MuiDropdownButton label="More Options" options={dropdownOptions} />
        </PageHead>
      </Grid>

      <Grid item xs={12}>
        <CallLogSearchForm
        currentSearch={currentSearch}
          services={services}
          onSubmit={onSubmitSearch}
        />
      </Grid>

      <Grid item xs={12}>
        <RenderSwitch />
      </Grid>
    </Grid>
  );
};

export default CallLog;

import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { IResellerInvoice } from "../../interfaces/reseller-invoice";
import VoipTable from "../../components/VoipTable";

type TInvoice = {
  invoice: IResellerInvoice;
};
const ResellerInvoiceBasic: React.FunctionComponent<TInvoice> = ({
  invoice,
}) => {
  const col = [
    {
      Header: "Qty",
      accessor: "qty",
      sortable: true,
    },
    {
      Header: "Name",
      accessor: "name",
      sortable: true,
    },
    {
      Header: "Unit Value",
      accessor: "value",
      sortable: true,
    },
    {
      Header: "Sub Value",
      accessor: "subValue",
      sortable: true,
    },
    {
      Header: "VAT",
      accessor: "vatValue",
      sortable: true,
    },
    {
      Header: "Total",
      accessor: "totalValue",
      sortable: true,
    },
  ];
  const generateMarkup = (string: string) => {
    //   console.log("gen", string);
    return { __html: string.replace(/\n/g, "<br />") };
  };

  const makeUK = (date: string) => {
    let xx = "";

    let explode2 = date.split("T")[0];
    let explode1 = explode2.split("-");

    xx = explode1[2] + "/" + explode1[1] + "/" + explode1[0];
    return xx;
  };
  if (invoice !== undefined) {
    return (
      <>
        <Row>
          <Col>
            <p>Number: {invoice.displayNumber}</p>
            <p>Date: {makeUK(invoice.date)}</p>
            <p>Status: {invoice.status}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Invoice</p>
            <p
              dangerouslySetInnerHTML={generateMarkup(invoice.invoiceAddress)}
            ></p>
          </Col>
          <Col className="text-center">
            <p>Delivery</p>
            <p
              dangerouslySetInnerHTML={generateMarkup(invoice.deliveryAddress)}
            ></p>
          </Col>
          <Col className="text-right">
            <p>Sender</p>
            <p
              dangerouslySetInnerHTML={generateMarkup(invoice.senderAddress)}
            ></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Invoice Items</h2>
            <VoipTable
              title="Invoice Items"
              data={invoice.lines}
              col={col}
              multiSelect={false}
              showResultsCount={false}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 0, span: 10 }} className="paddedTop text-center">
            {invoice.paymentTerms}
          </Col>
          <Col md={{ offset: 0, span: 2 }}>
            <div className="d-flex flex-rowd-flex justify-content-between">
              <div>
                <b>Sub-Total</b>
              </div>
              <div> £{invoice.subValue.toFixed(2)}</div>
            </div>

            <div className="d-flex flex-rowd-flex justify-content-between">
              <div>
                <b>Carriage</b>
              </div>
              <div> £{invoice.carriage.toFixed(2)}</div>
            </div>
            <div className="d-flex flex-rowd-flex justify-content-between">
              <div>
                <b>Carriage VAT @ 20%</b>
              </div>
              <div> £{invoice.carriageVAT.toFixed(2)}</div>
            </div>
            <div className="d-flex flex-rowd-flex justify-content-between">
              <div>
                <b> VAT @ 20%</b>
              </div>
              <div> £{invoice.vatValue.toFixed(2)}</div>
            </div>
            <div className="d-flex flex-rowd-flex justify-content-between">
              <div>
                <b>Total</b>
              </div>
              <div> £{invoice.totalValue.toFixed(2)}</div>
            </div>
          </Col>
        </Row>
      </>
    );
  } else {
    return <></>;
  }
};

export default ResellerInvoiceBasic;

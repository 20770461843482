import React from "react";

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from "@material-ui/core";
import { Link } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';

interface iFinishedScreenProp {
    data: any;
}

const FinishedScreen = ({ data }: iFinishedScreenProp) => {

    return <>

        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell >#</TableCell>
                        <TableCell >Number</TableCell>
                        <TableCell align="center" >Response</TableCell>
                        <TableCell ></TableCell>


                    </TableRow>
                </TableHead>
                <TableBody>
 
                    {data.return && data.return.map((number: any, index: number) => (
                        <TableRow key={number}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell >{number.NUMBER.name}</TableCell>
                            <TableCell align="center" >
                                {number.ERROR ? number.ERROR : <>Successfully allocated.</>}
                            </TableCell>
                            <TableCell align="right">


                                {number.NUMBER && <Button endIcon={<EditIcon />} variant="outlined" color="primary" component={Link} to={`./number/${number.NUMBER.id}/0`} >Edit Number</Button>}


                            </TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </TableContainer>

        <Typography style={{ marginTop: 20, marginBottom: 1, textAlign: 'center' }}>
            
            <Button style={{marginTop:10, marginBottom:10}} variant="outlined" color="primary" component={Link} to={`./numbers`}>
            
            Back to Numbers
            
            </Button>
        </Typography>

    </>

}


export default FinishedScreen
import React from "react";
import { Card } from "react-bootstrap";
const ApiKeys = ({ data }: any) => {
  return (
    <Card>
      <Card.Header>
        <h2 className="my-auto">API Keys</h2>
      </Card.Header>
      <Card.Body>
        {data.map((key:string) => (
          <div>{key}</div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default ApiKeys;

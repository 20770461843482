
import * as React from 'react';

import {Table, Dropdown } from 'react-bootstrap';

// ({ data, onSubmit }: any)
const PermissionsForm = (values: any, push: any, pop: any, form: any, thisX: any) => {

  const deleteItem=(index:number)=>{
    let update:any[] = [...values.permissions];
    update.splice(index, 1);
    form.change('permissions', update)
  }


  return <>

    <div className="wizard3__right-heading-div">
      <p className="wizard3__right-heading">Permissions</p>
       
    </div>

    <div className="wizard3__permissions">

      <p className="text-permissions">Set permission levels for this customer's different user types. The defaults will usually be fine and can be changed later.</p>
      <div className="wizard3__right-heading-div">
        <p className="wizard3__right-p">Current Groups</p>
        <button onClick={(e) => {
          e.preventDefault();
          thisX.perm.setVisible(true);
        }} className="button__group">
          <span className="plus-icon">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      Add New Group</button>
      </div>
    </div>

    <div>
      <Table responsive="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th></th>

          </tr>
        </thead>
        <tbody>
          {values.permissions.map((data: any, index: number) => {
            return <tr key={index}>
              <td className="groupName">{data.name}</td>
              <td className="userDescription">{data.description}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="link" className="button__options" id="dropdown-basic">

                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                  <Dropdown.Item as='button' onClick={(e:any)=>{
                      e.preventDefault();
                      console.log(data);
                      deleteItem(index);
                    }}>Remove</Dropdown.Item>
                    <Dropdown.Item as='button' onClick={(e:any)=>{
                      e.preventDefault();
                      console.log(data);
                      thisX.perm.setVisible(true, {...data, index:index});
                    }}>Edit</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>

            </tr>
          })}



          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </Table>


    </div>



  </>
}


export default PermissionsForm
import { Box, Button, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Fragment, ReactNode, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Loading from "../loading";

interface iWizardStep {
    id: string;
    visible?: (data: any) => boolean;
    title: string | ((data: any) => Promise<string>) | ((data: any) => string);
    formContents: ReactNode | ((data: any) => Promise<ReactNode>) | ((data: any) => ReactNode);
    optional?: boolean;
    onSubmit?: (data: any) => Promise<any>;

}
const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface iMuiWizardProps {
    steps: iWizardStep[];
    finishScreen?: (data: any) => ReactNode;
}

interface iMuiWizarsStepProp {
    error: string;
    activeStep: number;
    totalSteps: number;
    data: any;
    onSubmit: (submit: any) => void;
    formContent: ReactNode;
    handleBack: () => void;
    handleNext: () => void;
}

const MuiWizardStep = ({ error, data, onSubmit, formContent, activeStep, totalSteps, handleBack, }: iMuiWizarsStepProp) => {



    return <Form
        onSubmit={onSubmit}
        initialValues={data}
        mutators={{
            ...arrayMutators,
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (

            <form onSubmit={handleSubmit}>


                <Typography style={{ marginTop: 2, marginBottom: 10 }}>Step {activeStep + 1}</Typography>
                {error !== '' && <Alert severity="error" style={{ marginBottom: 20, margin: 5, }}>{error}</Alert>}
                {formContent && formContent}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        variant="outlined"
                        color="secondary"

                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {/* {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )} */}
                    <Button type="submit" variant="outlined"
                        color="primary">
                        {activeStep === totalSteps - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
            </form>

        )} />
}


const MuiWizard = ({ steps, finishScreen }: iMuiWizardProps) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const [data, setData] = useState<any>({})
    const isStepOptional = (step: number) => {
        const stepD = steps[step];
        if (stepD.optional === true) {
            return true;
        } else {
            return false;
        }
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setLoading(true);
        setError("");
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setLoading(false);
    };

    // const handleSkip = () => {
    //     if (!isStepOptional(activeStep)) {
    //         // You probably want to guard against something like this,
    //         // it should never occur unless someone's actively trying to break something.
    //         throw new Error("You can't skip a step that isn't optional.");
    //     }

    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped((prevSkipped) => {
    //         const newSkipped = new Set(prevSkipped.values());
    //         newSkipped.add(activeStep);
    //         return newSkipped;
    //     });
    // };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };


    const stepsA = steps.filter(step => {
        if (step.visible) {
            return step.visible && step.visible(data)
        } else {
            return true;
        }
    })



    const activeStepObject = stepsA[activeStep];



    return (
        <Box style={{
            width: '100%',
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.08)",
            padding: " 24px 12px",
        }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Stepper activeStep={activeStep}>
                    {stepsA.map((step, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: ReactNode;
                        } = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel {...labelProps}>
                                    {typeof step.title === 'function' ? step.title(data) : step.title}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>



                {activeStep === stepsA.length ? (
                    <Fragment>

                        {finishScreen ? <>
                            {finishScreen(data)}
                        </> : <Typography style={{ marginTop: 2, marginBottom: 1, textAlign:'center' }}>
                            All steps completed - you&apos;re finished


                        </Typography>
                        }

                    </Fragment>
                ) : (
                    <Fragment>

                        {loading === false ? <MuiWizardStep
                            data={data}
                            activeStep={activeStep}
                            error={error}
                            totalSteps={stepsA.length}
                            formContent={typeof activeStepObject.formContents === 'function' ? activeStepObject.formContents(data) : activeStepObject.formContents}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            onSubmit={async (data) => {
                                try {
                                    console.log(data)
                                    setError("");
                                    setLoading(true);
                                    if (activeStepObject.onSubmit) {

                                        try {
                                            const dataD = await activeStepObject.onSubmit(data);
                                            setData((state: any) => { return { ...state, ...dataD } })
                                            handleNext();
                                            setLoading(false);
                                        } catch (ex: any) {
                                            console.log(ex);
                                            setError(ex);
                                            setData((state: any) => { return { ...state, ...data } })
                                            setLoading(false);
                                        }

                                    } else {
                                        setData((state: any) => { return { ...state, ...data } })
                                        handleNext();
                                        setLoading(false);
                                    }



                                } catch (error: any) {
                                    setLoading(false);
                                    setError(error.toString());
                                    console.log(error)
                                }
                            }}
                        /> : <Loading />
                        }

                    </Fragment>
                )}
            </MuiPickersUtilsProvider>
        </Box>
    );
}


export default MuiWizard;
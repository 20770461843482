

import * as React from 'react';

import { Row, Col } from 'react-bootstrap';
import { IResellerInvoice } from '../../interfaces/reseller-invoice';
import ResellerInvoiceSummaryItem from './ResellerInvoiceSummaryItem';


type TInvoice = {
    invoice: IResellerInvoice
}


const ResellerInvoiceSummary: React.FunctionComponent<TInvoice> = ({ invoice }) => {
 //  console.log(invoice);
    if (invoice !== undefined) {
        return (<>
            
            <Row>
                <Col>

                    <h2>Customer Summary</h2>
                    <Row>
                        {invoice.customerInvoices.map((inv)=>{
                            return (<ResellerInvoiceSummaryItem invoice={inv} invoicesId={invoice.id} />);
                        })}
                    </Row>
                </Col>
            </Row>
            
        </>);
    } else {
        return (<></>);
    }

};

export default ResellerInvoiceSummary;
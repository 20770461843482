import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IExtensionsHttpResponse } from "../interfaces/extension";
import MySettings from "../static/settings";


const set = new MySettings();

type option = {
  value: {
    id: string;
  };
  label: string;
};

const GetExtensionOptions = ({
  input,
  customersSitesId,
  defaultV,
  ...rest
}: any) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<option[]>([]);
  const [selectedOption, setSelectedOption] = useState<option | null>(null);

  // console.log("state GetExtensionOptions", data);
  // console.log("props GetExtensionOptions", input);
  // console.log("selectedOption", selectedOption);

  useEffect(() => {
    const getExtensions = async () => {
      try {
        const result: IExtensionsHttpResponse =
          await set.provisionableDevicesGetForCustomerSite(
            customersSitesId,
            true
          );
        // console.log("data GetExtensionOptions", result);
        const reshapedResult = result.items.map(
          (item: { id: string; callerid: string }) => ({
            value: {
              id: item.id,
            },
            label: item.callerid,
          })
        );

        reshapedResult.unshift({
          value: {
            id: "",
          },
          label: "",
        });

        reshapedResult.sort(compare);

        setData(reshapedResult);

        const selected = reshapedResult.find(
          (item: { value: { id: string } }) => item.value.id === defaultV
        );

        if (selected) {
          setSelectedOption(selected);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getExtensions();
  }, [customersSitesId, defaultV]);

  const compare = (a: option, b: option) => {
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
  };

  const handleChange = (selectedOption: option) => {
    input.onChange(selectedOption.value.id);
    setSelectedOption(selectedOption);
  };

  return (
    <>
      {data.length > 0 ? (
        <Select
          options={data}
          {...input}
          {...rest}
          searchable
          value={selectedOption}
          onChange={handleChange}
          isLoading={loading}
          styles={{ option: (styles) => ({ minHeight: 40, ...styles }) }}
        />
      ) : (
        <p>
          You are not currently associated with any extensions. Please contact
          your VoIP provider.
        </p>
      )}
    </>
  );
};

export default GetExtensionOptions;

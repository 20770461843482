import React from "react";

import Nav from "react-bootstrap/Nav";

import { ButtonGroup, DropdownButton, NavDropdown } from "react-bootstrap";
import "../sass/topmenu.scss";
import { ITopMenuEntry } from "../interfaces/menu-option";

interface ITopMenuProps {
  items: ITopMenuEntry[];
  depth: number;
}

const TopMenu: React.FC<ITopMenuProps> = ({ items, depth = 0 }) => {
  if (!items || !items.length) {
    return null;
  }

  return (
    <>
      {items.map((item: ITopMenuEntry) =>
        item.children.length ? (
          depth === 0 ? (
            <NavDropdown
              key={item.label}
              title={item.label}
              id="basic-nav-dropdown"
              className="topMenuNavDropdown"
            >
              <TopMenu items={item.children} depth={depth + 1} />
            </NavDropdown>
          ) : (
            <DropdownButton
              key={item.label}
              as={ButtonGroup}
              id="dropdown-button-drop-right"
              className="navDropdownButton"
              drop="right"
              variant="link"
              title={item.label}
            >
              <TopMenu items={item.children} depth={depth + 1} />
            </DropdownButton>
          )
        ) : depth === 0 ? (
          <Nav.Link
            target={item.target}
            key={item.label}
            href={item.path}
            className="topMenuNavLink"
          >
            {item.label}
          </Nav.Link>
        ) : (
          <NavDropdown.Item href={item.path} key={item.label} target={item.target}>
            {item.label}
          </NavDropdown.Item>
        )
      )}
    </>
  );
};

export default TopMenu;

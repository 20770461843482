import React from "react";

import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";

import DatePickerAdapter from "../../components/misc/form/DatePickerAdapter";
import requiredValidator from "../../components/misc/form/requiredValidator";
import VipTextFinalField from "../../components/misc/form/VipTextFinalField";

export interface IInvoiceSearchForm {
  fromDate: Date;
  toDate: Date;
  number: string;
}

export interface IInvoiceSearchFormProps {
  data?: IInvoiceSearchForm;
  onSubmit: (data: IInvoiceSearchForm) => void;
}

const ResellerInvoicesAndCreditNotesSearchForm: React.FC<IInvoiceSearchFormProps> = ({
  onSubmit,
  data,
}: any) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="text-left test-reseller-invoices-serch">
            <Row>
              <Col>
                <Field
                  name="fromDate"
                  validate={requiredValidator}
                  dateFormat="dd/MM/yyyy"
                  component={DatePickerAdapter}
                  placeholder="From Date"
                />
              </Col>
              <Col>
                <Field
                  name="toDate"
                  dateFormat="dd/MM/yyyy"
                  component={DatePickerAdapter}
                  placeholder="To Date"
                />
              </Col>

              <Col>
                <VipTextFinalField
                  fieldName="number"
                  placeholder="Number"
                  required={false}
                />
              </Col>
              <Col className="text-right">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={submitting}
                >
                  Search
                </button>
              </Col>
            </Row>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </div>
        </form>
      )}
    />
  );
};

export default ResellerInvoicesAndCreditNotesSearchForm;


import * as React from 'react';


import customerwizard from '../NewCustomerWizard/images/customer-wizard-img@2x.png';
// ({ data, onSubmit }: any)
const GreetingForm = (values: any, push: any, pop: any, form: any) => {

    return <>


    <p className="wizard1__main-heading">Welcome to the new customer<br></br> creation wizard</p>

    <img className="wizard1__main-img"
      src={customerwizard}
      alt={"welcome"}
      width="70%"
      height="70%" />
    <div className="wizard1__main-buttons">
      <button className="button-primary">Get Started</button>
      <button className="button-secondary">Cancel</button>
    </div>


  </>
}


export default GreetingForm
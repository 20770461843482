import React, { useState, useEffect } from "react";
import { IMeetingRoom } from "../../interfaces/meeting-rooms";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import MySettings from "../../static/settings";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PageHead from "../../components/PageHead";
import Loading from "../../components/loading";
import { InputGroup, FormControl, Card } from "react-bootstrap";

import SitesPicker from "../../components/SitesPicker";
import App from "../../App";
import VipTimeField from "../../components/VipTimeField";
import VipDateField from "../../components/VipDateField";
import { parse, formatISO, getMinutes, getHours, set } from "date-fns";
import dateFnsFormat from "date-fns/format";

const SET = new MySettings();

const VoipServiceMeetingRoomsForm: React.FC<IMeetingRoom> = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newMeetingRoom, setNewMeetingRoom] = useState<IMeetingRoom>({
    id: "",
    name: "",
    url: "",
    customersSitesId: "",
    ddis: [],
    internalDDIs: [],
    invites: [],
    startDateTime: "",
  });
  const [meetingRoomResponse, setMeetingRoomResponse] = useState<IMeetingRoom>({
    id: "",
    name: "",
    url: "",
    customersSitesId: "",
    ddis: [],
    internalDDIs: [],
    invites: [],
    startDateTime: "",
  });
  const [newInvite, setNewInvite] = useState("");
  const [newDay, setNewDay] = useState<number | Date>(new Date());
  const [newTime, setNewTime] = useState<any>(new Date(2020, 0, 0, 12, 0, 0));

  // console.log("PROPS", props);
  // console.log("NewDay", newDay);
  // console.log("newTime", newTime);
  // console.log("INVITES", newMeetingRoom.invites);
  // console.log("newInvite", newInvite);

  useEffect(() => {
    if (newDay && newTime) {
      console.log("USE FC Effec");
      let combinedDate = set(newDay, {
        hours: getHours(newTime),
        minutes: getMinutes(newTime),
        seconds: 0o0,
      });

      let formattedDate = { startDateTime: formatISO(combinedDate) };

      console.log("DATE", formattedDate);

      setNewMeetingRoom((prevState) => {
        return { ...prevState, ...formattedDate };
      });
    }
  }, [newDay, newTime]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setIsLoading(true);
    try {
      const roomCopyWithUpdatedInput = {
        ...newMeetingRoom,
        invites: [...newMeetingRoom.invites, newInvite],
      };
      const result = await SET.meetingRoomsAdd(roomCopyWithUpdatedInput);
      setMeetingRoomResponse(result);

      console.log("RESULT", meetingRoomResponse);

      const notification: INotification = {
        title: "Success",
        data: "Meeting Room Created Successfully!",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "MeetingRoomsForm",
        srcData: "success",
        srcFunction: "handleSubmit",
      };

      App.newNotification(notification);
      let parts = window.location.pathname.split("/");
      if (parts) parts.shift();
      parts.shift();
      parts.shift();
      parts.pop();
      let newlocation = parts.join("/") + "/meetingrooms/" + result.id;
      if (newlocation.indexOf("/") !== 0) {
        newlocation = "/" + newlocation;
      }
      props.history.push(newlocation);
    } catch (error) {
      console.log(error);

      const notification: INotification = {
        title: "Failed",
        data: "Meeting Room Creation Went Wrong!",
        variant: NOTIFICATION_TYPE.warning,
        srcComponent: "MeetingRoomsForm",
        srcData: "success",
        srcFunction: "handleSubmit",
      };

      App.newNotification(notification);
    }
    setIsLoading(false);
  };

  const onSiteChosen = (siteId: string) => {
    setNewMeetingRoom({
      ...newMeetingRoom,
      customersSitesId: siteId,
    });
  };

  const handleChange = (event: any) => {
    setNewMeetingRoom({
      ...newMeetingRoom,
      name: event.target.value,
    });
  };

  const handleInviteChange = (event: any) => {
    setNewInvite(event.target.value);
  };

  const addInvite = () => {
    setNewMeetingRoom({
      ...newMeetingRoom,
      invites: [...newMeetingRoom.invites, newInvite],
    });

    setNewInvite("");
  };

  const removeInvite = (invite: string) => {
    const filtered = newMeetingRoom.invites.filter((inv) => inv !== invite);

    setNewMeetingRoom({
      ...newMeetingRoom,
      invites: filtered,
    });
  };

  const handleDayChange = (day: any) => {
    console.log("handleDayChange", day, typeof day);
    setNewDay(day);
  };

  const handleTimeChange = (field: string, value: any) => {
    let result = parse(value, "HH:mm:ss", new Date());

    setNewTime(result);
  };

  return (
    <div className="content">
      <PageHead title="New Meeting Room"></PageHead>

      {isLoading ? (
        <Loading />
      ) : (
        <Card>
          <Form onSubmit={handleSubmit} className="searchForm">
          <Card.Body>
              <Form.Group controlId="name">
                <Form.Label>New Meeting Room Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Room Name"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Label>Meeting Room Site</Form.Label>
              <SitesPicker {...props} onSiteChosen={onSiteChosen} />

              <Form.Group className="mt-3">
                <Form.Label>New Meeting Room Start Date</Form.Label>
                <VipTimeField
                  field="Time"
                  value={"12:00"}
                  updateField={handleTimeChange}
                  timeArray={[...Array(60).keys()]}
                />
                <VipDateField
                  placeholder={`${dateFnsFormat(new Date(), "dd/MM/yyyy")}`}
                  updateField={handleDayChange}
                />
              </Form.Group>

              <Form.Label>Invite Emails</Form.Label>
              {newMeetingRoom.invites.map((invite: any) => {
                return (
                  <div
                    key={invite}
                    className="d-flex flex-rowd-flex justify-content-between"
                  >
                    <div className="my-auto">{invite}</div>
                    <div className="text-right">
                      <Button
                        variant="outline-danger"
                        onClick={() => {
                          removeInvite(invite);
                        }}
                        className="my-1"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                );
              })}

              <InputGroup>
                <FormControl
                  id="newEmail"
                  type="email"
                  placeholder="Email"
                  value={newInvite}
                  onChange={handleInviteChange}
                  aria-label="Text input with radio button"
                />
                <InputGroup.Append>
                  <Button
                    onClick={() => {
                      addInvite();
                    }}
                    variant="outline-primary"
                  >
                    Add Email Invite
                  </Button>
                </InputGroup.Append>
              </InputGroup>
          </Card.Body>
          <Card.Footer className="text-center">
            <Button variant="primary" type="submit">
              Create Meeting Room
            </Button>
          </Card.Footer>
          </Form>
        </Card>
      )}
    </div>
  );
};

export default VoipServiceMeetingRoomsForm;


const ukNumberValidator = (value: string) => {
    let re = /^((\+44\s?\d{4}|\(?\d{5}\)?)\s?\d{6})|((\+44\s?|0)7\d{3}\s?\d{6})$/;
    let found;
    if(value!==undefined){
        found = value.match(re);
    }
    return (found ? undefined : 'Must be a UK Phone number')
} 
//	
export default ukNumberValidator;
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { IPhonebook, IPhonebookAddResponse } from "../../interfaces/phonebook";
import { Card } from "react-bootstrap";
import App from "../../App";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import MySettings from "../../static/settings";
import Loading from "../loading";
import { IPageProps } from "../../interfaces/page-props";
import { RouteComponentProps } from "react-router-dom";
import PageHead from "../PageHead";

const set = new MySettings();

const PhonebookForm: React.FC<IPageProps & RouteComponentProps> = ({
  resellerID,
  history,
}) => {
  const [phonebookName, setPhonebookName] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    let payload: IPhonebook = {
      id: "",
      name: phonebookName,
      customersId: "",
      syncWith365: false,
      safeCustomersName: "",
    };
    if (resellerID) {
      payload.customersId = resellerID;
    }
    try {
      const data = await set.doAPIRequest<IPhonebookAddResponse>(
        set.getPhoneBooksAdd(),
        payload
      );

      console.log("data", data);
      const notification: INotification = {
        title: "Success",
        data: "Phonebook created.",
        variant: NOTIFICATION_TYPE.success,
        srcFunction: "handleSubmit",
        srcData: window.location.pathname,
        srcComponent: "PhonebookForm",
      };
      App.newNotification(notification);
      if (data.record.id) {
        history.push("phonebook/" + data.record.id);
      }
    } catch (error) {
      console.log(error);

      const notification: INotification = {
        title: "Something went wrong!",
        data: "The phone book could not be created.",
        variant: NOTIFICATION_TYPE.warning,
        srcFunction: "handleSubmit",
        srcData: window.location.pathname,
        srcComponent: "PhonebookForm",
      };
      App.newNotification(notification);
    }
    setLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhonebookName(event.target.value);
  };

  return (
    <div className="content">
      <PageHead resellerID={resellerID} title="New Phone Book" parents={[]} />
      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Card>
            <Card.Header>
              <h2 className="my-auto">New Phonebook</h2>
            </Card.Header>

            <Card.Body className="form-card-body">
              <Form.Group controlId="Phonebook Name">
                <Form.Label>New Phonebook Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter Phonebook Name"
                  value={phonebookName}
                  onChange={handleChange}
                />
              </Form.Group>
            </Card.Body>

            <Card.Footer className="text-center">
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Card.Footer>
          </Card>
        </Form>
      )}
    </div>
  );
};
export default PhonebookForm;

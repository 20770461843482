import React from "react";

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { Field } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  menuItem: {
    minHeight: 36,
  },
}));

interface ISelectProps {
  name: string;
  options: { value: number | string | boolean; label: string }[];
  label?: string;
  required?: boolean;
  emptyFirstOption?: boolean;
}

const MuiSelect: React.FC<ISelectProps> = ({
  name,
  options,
  label,
  emptyFirstOption,
  required = false,
}) => {
  const classes = useStyles();
  return (
    <Field name={name}>
      {({ input: { name, onChange, value, ...restInput }, meta, ...rest }) => (
        <FormControl
          variant="outlined"
          className={classes.formControl}
          fullWidth
        >
          {label && <InputLabel id="select-outlined-label">{label}</InputLabel>}
          <Select
            {...rest}
            inputProps={restInput}
            onChange={onChange}
            value={value}
            labelId="select-helper-label"
            id="select-helper"
            label={label}
            required={required}
          >
            {options && emptyFirstOption && (
              <MenuItem value={""} className={classes.menuItem} />
            )}

            {options &&
              options.map((opt) => {
                return (
                  <MenuItem
                    key={opt.value + opt.label}
                    // ts ignore here because of the boolean value
                    // @ts-ignore
                    value={opt.value}
                    className={classes.menuItem}
                  >
                    {opt.label}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
    </Field>
  );
};
export default MuiSelect;

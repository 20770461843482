import * as React from "react";

import { IEmailSubReportCallRecordings } from "../../interfaces/email-reports";
import { useState } from "react";
import { IExtension } from "../../interfaces/extension";
import VipExtensionSelector from "../VipExtensionSelector";
import { Grid, MenuItem, Select, } from "@material-ui/core";
import VipTimeFieldMui from "../VipTimeFieldMui";
import TextFieldWithState from "../misc/form/MuiTextFieldWithState";
import MuiTitle from "../misc/form/MuiTitle";

export interface SomeProps {
  data: IEmailSubReportCallRecordings;
  extensions: IExtension[];
  hotDesks: IExtension[]
  submitFunction: (field: string, data: any) => void;
}

const EmailReportCallRecordings = ({ data, submitFunction, extensions, hotDesks }: SomeProps) => {
  const allExtensions=extensions.concat(hotDesks)
  const [state, setState] = useState<IEmailSubReportCallRecordings>(() => {
    //  console.log(hotDesks)
    const xState = { ...data };
    if (!xState.startTime) {
      xState.startTime = "08:00:00"
      submitFunction("startTime", "08:00:00")
    }
    if (!xState.endTime) {
      xState.endTime = "18:00:00"
      submitFunction("endTime", "18:00:00")
    }
    if (!xState.callRecordingsSortBy) {
      xState.callRecordingsSortBy = ""
    }

    return xState;
  });
  const setField = (field: string, value: any) => {
    console.log("subform", field, value);
    const stateUp: any = { ...state };
    stateUp[field] = value;
    setState(stateUp);
    submitFunction(field, value);
  }

  console.log(data, submitFunction, extensions)
  return (<Grid container spacing={2}>
    <Grid item xs={6}>
      <MuiTitle title="Start Time" />
      <VipTimeFieldMui
        field="startTime"
        value={state.startTime}
        updateField={setField}
      />

    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="End Time" />
      <VipTimeFieldMui
        field="endTime"
        value={state.endTime}
        updateField={setField}
      />


    </Grid>


    <Grid item xs={6}>
      <MuiTitle title="Minimum duration in seconds of a call to appear on the report" />

      <TextFieldWithState required={false} field="callRecordingsMinimumDurationSeconds" data={state.callRecordingsMinimumDurationSeconds ? state.callRecordingsMinimumDurationSeconds : 0} setField={(f, value) => {
        setField("callRecordingsMinimumDurationSeconds", parseInt(value))
      }} type="number" placeholder="seconds" />





    </Grid>

    <Grid item xs={6}>
      <MuiTitle title="Sort recordings by" />

      <Select
        variant='outlined'
        fullWidth
        value={state.callRecordingsSortBy}
        onChange={(value: any) => {
          setField("callRecordingsSortBy", value.target.value)
        }}
      >
        <MenuItem value={"dateAscending"}>Date Ascending</MenuItem>
        <MenuItem value={"durationDescending"}>Duration Descending</MenuItem>
      </Select>

    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="Extensions To Include (Leave empty to include all)" />

      <VipExtensionSelector
        buttonText="Click here to choose extensions."
        updateField={setField}
        field="callRecordingsExtensionsToInclude"
        value={state.callRecordingsExtensionsToInclude}
        extensions={allExtensions} />

    </Grid>
  </Grid>);
};

export default EmailReportCallRecordings;

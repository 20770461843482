import React, { useEffect, useRef, useState } from "react";

import { Col, ButtonGroup } from "react-bootstrap";

import PageHead from "../../components/PageHead";
import { IPageProps } from "../../interfaces/page-props";

import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";
import MySettings from "../../static/settings";
import { Link, RouteComponentProps } from "react-router-dom";

import {
  ICreditNotesGetResponse,
  IResellerCreditNote,
} from "../../interfaces/reseller-invoice";
import ResellerInvoicesAndCreditNotesSearchForm, {
  IInvoiceSearchForm,
} from "./ResellerInvoicesAndCreditNotesSearchForm";

import Error from "../../components/error";

import { addMonths, format } from "date-fns";

const set = new MySettings();

interface IResellerCreditNotesPage
  extends RouteComponentProps<any>,
    IPageProps {}

const ResellerCreditNotesPage: React.FC<IResellerCreditNotesPage> = (props) => {
  const [creditNotes, setCreditNotes] = useState<IResellerCreditNote[]>([]);
  const initialSearch = useRef({
    fromDate: addMonths(new Date(), -1),
    toDate: new Date(),
    number: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    handleSearch(initialSearch.current);
  }, []);

  const handleSearch = async (searchParams: IInvoiceSearchForm) => {
    try {
      setLoading(true);

      const response: ICreditNotesGetResponse = await set.creditNotesGet(
        format(searchParams.fromDate, "yyyy-MM-dd"),
        format(searchParams.toDate, "yyyy-MM-dd"),
        searchParams.number
      );

      setCreditNotes(response.items);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const col = [
    {
      Header: "Number",
      accessor: "displayNumber",
      sortable: true,
    },
    {
      sortable: true,
      Header: "Date",
      cssClass: "text-left",
      accessor: "date", // String-based value accessors!
      Cell: (props: any) => {
        //   console.log(props.value);
        let explode2 = props.value.split("T")[0];
        let explode1 = explode2.split("-");

        return (
          <>
            {explode1[2]}/{explode1[1]}/{explode1[0]}{" "}
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      sortable: true,
    },
    {
      Header: "Value",
      accessor: "totalValue",
      sortable: true,
      decimalPad: true,
    },
    {
      Header: "Payment",
      accessor: "paymentType",
      sortable: true,
    },
    {
      Header: "",
      accessor: "id", // String-based value accessors!
      Cell: (props: any) => {
        //     console.log(props);
        const note = "/creditnote/" + props.value;

        return (
          <ButtonGroup aria-label="Basic example">
            <Link to={note} className="btn btn-info">
              View Credit Note
            </Link>
          </ButtonGroup>
        );
      },
    },
  ];

  return (
    <div className="content">
      {
        <Col md="12" className="text-left">
          <PageHead
            // resellerID={props.resellerID}
            isHome={false}
            customersMode={false}
            title="Credit Notes"
          ></PageHead>

          {error ? (
            <Error />
          ) : (
            <>
              <ResellerInvoicesAndCreditNotesSearchForm
                data={initialSearch.current}
                onSubmit={handleSearch}
              />
              {loading ? (
                <Loading />
              ) : (
                <VoipTable
                  title="Credit Notes"
                  data={creditNotes}
                  col={col}
                  multiSelect={false}
                />
              )}
            </>
          )}
        </Col>
      }
    </div>
  );
};
export default ResellerCreditNotesPage;

import { createTheme } from "@material-ui/core/styles";
import { Shadows } from "@material-ui/core/styles/shadows";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1D57D0",
    },
  },
  shadows: Array(25).fill("none") as Shadows,
  overrides: {
    MuiSvgIcon: {
      root: {
        stroke: "#F4F7FD",
        strokeWidth: 1,
      },
    },
    MuiCard: {
      root: {
        padding: "15px",
        boxShadow: "0 2px 24px 0 rgba(0,0,0,0.08)",
      },
    },
    MuiPaper: {
      root: {
        boxShadow: "0 2px 24px 0 rgba(0,0,0,0.08)",
      },
    },

    MuiGrid: {
      container: {
        maxWidth: "1140px",
      },
    },
    MuiTableHead: {
      root: {
        fontSize: 14,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
      },
    },

    MuiOutlinedInput: {
      root: {
        maxHeight: "42px",
      },
      input: {
        marginTop: "16px",
        marginBottom: "16px",
      },
    },

    MuiInputBase: {
      input: {
        maxHeight: "42px",
        margin: "0",
        padding: "0",
      },

      root: {
        maxHeight: "42px",
      },
    },

    MuiInputLabel: {
      outlined: {
        marginTop: "2px",
        transform: "translate(14px, 15px)",
      },
    },

    MuiSelect: {
      root: {
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },

    MuiFormControlLabel: {
      label: {
        fontSize: 16,
        color: "#2B2B36",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 16,
      },
    },
  },
  typography: {
    fontSize: 16,
    button: {
      textTransform: "none",
      fontWeight: 400,
    },
    h3: {
      fontFamily: "Montserrat",
      fontSize: 34,
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Montserrat",
      fontSize: 22,
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Montserrat",
      fontSize: 22,
      fontWeight: 500,
    },
    h6: {
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 14,
      color: "#5B6688",
    },
    subtitle2: {
      fontSize: 16,
      color: "#5B6688",
      fontWeight: 400,
    },
  },
});

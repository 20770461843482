import * as React from "react";

import Loading from "../../components/loading";
import MySettings, { IAreaCode } from "../../static/settings";
import { IPageProps } from "../../interfaces/page-props";
import PageHead from "../../components/PageHead";
import { forEachOf } from "async-es";
import catchME from "../../catchME";
import Error from "../../components/error";

import AllocateNumberForm from "./AllocateNumberForm";

import { Row, Col } from "react-bootstrap";
import { INumber } from "../../interfaces/number";
import App from "../../App";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { RouteComponentProps } from "react-router-dom";

interface IResellerAllocateNumbersState {
  loading: boolean;
  error: boolean;
  customers: any[];
  feedback: any[];
  areaCodes: IAreaCode[];
}

const set = new MySettings();

interface IResellerAllocateNumbersPage
  extends RouteComponentProps<any>,
    IPageProps {}
export default class ResellerAllocateNumbers extends React.Component<
  IResellerAllocateNumbersPage,
  IResellerAllocateNumbersState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      customers: [],
      areaCodes: [],
      feedback: [],
    };
  }

  public render() {
    const { loading, error, areaCodes, customers, feedback } = this.state;
    if (loading === true) {
      return <Loading />;
    } else {
      // console.log(feedback, feedback.length, customers)
      return (
        <div className="content">
          <PageHead
            resellerID={this.props.resellerID}
            title="Allocate Number"
            parents={[
              {
                title: "Numbers",
                url: "./numbers",
              },
            ]}
          ></PageHead>

          {error === true ? (
            <Error />
          ) : (
            <>
              {feedback.length === 0 ? (
                <Row>
                  <Col lg={{ offset: 1, span: 10 }}>
                    <AllocateNumberForm
                      data={{}}
                      areaCodes={areaCodes}
                      customers={customers}
                      onSubmit={(data: any) => {
                        // console.log("data", data);
                        this.setState({ loading: true });
                        let Res: any[] = [];

                        forEachOf(
                          data.toEHA,
                          (value: any, key: number, callback: any) => {
                            // console.log(value);
                            set
                              .numberEHAUpdate(value)
                              .then((respone: INumber) => {
                                //   console.log(respone);
                                Res.push(respone);
                                callback();
                              })
                              .catch((err: any) => {
                                Res.push({ err });
                                callback();
                              });
                          },
                          (err: any) => {
                            if (err) console.error(err.message);
                            // `sizes` is now a map of image sizes

                            const notification: INotification = {
                              title: "Success",
                              data: "Numbers allocated.",
                              variant: NOTIFICATION_TYPE.info,
                              srcFunction: "componentDidUpdate",
                              srcData: window.location.pathname,
                              srcComponent: "App",
                            };
                            App.newNotification(notification, true);

                            if (this.props.history) {
                              this.props.history.push(`/numbers`);
                            }
                            //    console.log("end", Res);
                            this.setState({ feedback: Res, loading: false });
                          }
                        );
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      );
    }
  }

  public componentDidMount = () => {
    // const requestedID = this.props.match.params.id;
    // this.id = requestedID;
    // console.log("componentDidMount");
    this.loadBase();
  };

  public loadBase = () => {
    set
      .areaCodesGet()
      .then((areaCodes) => {
        set
          .resellerCustomerSearch("",false)
          .then((response) => {
            response.items.sort((a,b)=>{
              return a.item.name.localeCompare(b.item.name)
            })
            let customers = response.items.map((item: any) => {
              let newD = { ...item.item, score: item.score };
              return newD;
            });

            this.setState({
              loading: false,
              customers: customers,
              areaCodes: areaCodes.areaCodes,
            });
          })
          .catch((exception) => {
            catchME(this, exception, true);
          });
      })
      .catch((exception) => {
        catchME(this, exception, true);
      });
  };
}

import React, { useState } from "react";

import { Link, RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import VoipTable from "../../components/VoipTable";
import {
  ICustomer, ICustomersSearch, ICustomersSearchItem, ICustomersSearchItems
} from "../../interfaces/customer";
import { IPageProps } from "../../interfaces/page-props";
import MySettings from "../../static/settings";

import { Button } from "react-bootstrap";
import Error from "../../components/error";
import SearchPlease from "../../components/SearchPlease";
import CustomersSearchForm from "./CustomersSearchForm";

import "../../sass/customers.scss";

const set = new MySettings();

export interface IResellerState {
  displayEditForm: boolean;
  resellerSearch: ICustomersSearch;
  results: ICustomer[];
  initialized: boolean;
}

interface IResellerProps extends RouteComponentProps<any>, IPageProps { }

const Customer: React.FC<IResellerProps> = ({ resellerID, history }) => {
  const [results, setResults] = useState<ICustomer[]>([]);

  const [isFirstSearch, setIsFirstSearch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formValues, setFormValues] = useState<ICustomersSearch>();

  const handleSearch = async (searchField: ICustomersSearch) => {
    try {
      setLoading(true);
      let showArchived=false
      if(searchField.showArchived && searchField.showArchived.length>0 && searchField.showArchived[0]==='showArchived'){
        showArchived=true
      }
      const resellerCustomerSearchResult: ICustomersSearchItems =
        await set.resellerCustomerSearch(searchField.query,showArchived);

      const reshapedArray = resellerCustomerSearchResult.items.map(
        (item: ICustomersSearchItem) => {
          return { ...item.item, score: item.score };
        }
      );

      if (isFirstSearch) {
        setFormValues(searchField);
      }

      setIsFirstSearch(false);
      setResults(reshapedArray);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  const col = [
    {
      Header: "Name",
      accessor: "name",
      sortable: true,
    },
    {
      cssClass: "text-left",
      Header: "Status",
      headAlign: "center",
      accessor: "archivedDateTime",
      sortable: true,
      Cell: (props: any) => {
        let active = true;
        if (props.value) {
          active = false;
        }

        if (active) {
          return <span className="activeTable">Active</span>;
        } else {
          return <span className="inactiveTable">Archived</span>;
        }
      },
    },
    {
      Header: "Primary Contact",
      accessor: "generallastName",
      sortable: true,
    },
    {
      Header: "Primary Contact Phone",
      accessor: "generaltelephoneNumber",
      sortable: true,
    },
    {
      Header: "Primary Contact Email",
      accessor: "generalemailAddress",
      sortable: true,
    },
    {
      Header: "",
      accessor: "id", // String-based value accessors!
      Cell: (props: any) => {
        // console.log(props);
        const link = "/reseller/" + props.value;
        return (
          <Link
            to={link}
            className="btn btn-primary"
            onClick={(e) => e.stopPropagation()}
          >
            Select Customer
          </Link>
        );
      },
    },
  ];
  let linkN: string = "/newcustomer";

  return (
    <div className="content">
      <PageHead
        resellerID={resellerID}
        isHome={true}
        customersMode={true}
        title="Customers"
      >
        {isFirstSearch ? (
          <Link to={linkN}>
            <Button type="button" variant="outline-primary">
              New Customer
            </Button>
          </Link>
        ) : (
          <CustomersSearchForm data={formValues} onSubmit={handleSearch} showArchived={true}>
            <Link to={linkN}>
              <Button type="button" variant="outline-primary">
                New Customer
              </Button>
            </Link>
          </CustomersSearchForm>
        )}
      </PageHead>

      {loading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : isFirstSearch ? (
        <SearchPlease msg={"Please type a customer name to begin searching."}>
          <CustomersSearchForm onSubmit={handleSearch} showArchived={false} />
        </SearchPlease>
      ) : (
        <>
          {
            <VoipTable
              rowClickLink={"customers"}
              history={history}
              title="Customers"
              data={results}
              col={col}
              sortByStart="score"
              multiSelect={false}
            />
          }
        </>
      )}
    </div>
  );
};

export default Customer;

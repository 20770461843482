
import Button from "react-bootstrap/Button";


import { ISelectOption } from "../../interfaces/interfaces";
import {
  IProvisioningSettings, ProvisioningSettingKeyType, ProvisioningSettingLDAPVersion,
  ProvisioningSettingLocalTimeZone,
  ProvisioningSettingPhoneLanguage, ProvisioningSettingsDesktopAppExtensionsToShow, ProvisioningSettingTransportMethod
} from "../../interfaces/provisioning";
import ReviewCollapse from "../../pages/Service/ReviewCollapse";
import VipProvisioningCustomFields from "../VipProvisioningCustomFields";
import VipProvisioningYealink from "../VipProvisioningYealinkFirmwares";
import VipProvisioningFieldMui from "../VipProvisioningFieldMui";
import { memo, useState } from "react";
import Loading from "../loading";



export interface IFormProvisioningProp {
  resellerMode: boolean;
  showYeaLinkFirmware: boolean;
  data: any;
  submitFunction: (data: IProvisioningSettings) => void;
  updateFieldOverride?: (fieldName: string, data: any) => void;
}




const enumToOptions = function (
  keys: string[],
  values: string[]
): ISelectOption[] {
  const options: ISelectOption[] = [];
  for (var i = 0; i < keys.length; i++) {
    let selectOption = {
      label: keys[i],
      value: values[i],
    };
    options.push(selectOption);
  }
  return options;
};




const transportSelectOptions = enumToOptions(
  Object.keys(ProvisioningSettingTransportMethod),
  Object.values(ProvisioningSettingTransportMethod)
);
const ldapVersionOptions = enumToOptions(
  Object.keys(ProvisioningSettingLDAPVersion),
  Object.values(ProvisioningSettingLDAPVersion)
);
const localTimeZoneOptions = enumToOptions(
  Object.keys(ProvisioningSettingLocalTimeZone),
  Object.values(ProvisioningSettingLocalTimeZone)
);
const phoneLanguageOptions = enumToOptions(
  Object.keys(ProvisioningSettingPhoneLanguage),
  Object.values(ProvisioningSettingPhoneLanguage)
);
const programmableKey3TypeOptions = enumToOptions(Object.keys(ProvisioningSettingKeyType),Object.values(ProvisioningSettingKeyType))
const desktopAppExtensionsToShowOptions = enumToOptions(Object.keys(ProvisioningSettingsDesktopAppExtensionsToShow), Object.values(ProvisioningSettingsDesktopAppExtensionsToShow))
const lineKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]






const FormProvisioning = ({ resellerMode, showYeaLinkFirmware, data, submitFunction, updateFieldOverride }: IFormProvisioningProp) => {
  const [isSaving, setIsSaving] = useState(false)
  const [state, setState] = useState<any>(() => data)


  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    setIsSaving(true)
    submitFunction(state);
  };
  const updateField = (fieldName: any, payload: any) => {

    setState((prev: any) => {
     // console.log("fp-updateField-", fieldName, payload);
      const stateC = { ...prev };

      stateC[fieldName] = payload;
      return stateC;
    });
    if (updateFieldOverride !== undefined) {
      updateFieldOverride(fieldName, payload);
    }

  };


  return <>
    {console.log("render form")}
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.yealinkTemplateVersion}
      dataField="yealinkTemplateVersion"
      title="Yealink Template Version"
      type="string"
    />

    <ReviewCollapse header="Line Keys" initalOpen={false} content={<>
      {lineKeys.map((val, valIndex) => {

        const line = state[`linekey_${val}_line`]

        return <div key={valIndex}>
          <VipProvisioningFieldMui

            updateField={updateField}
            data={state[`linekey_${val}_type`]}
            dataField={`linekey_${val}_type`}
            title={`Line Key ${val} Type`}
            selectOptions={[{ value: "0", label: "Disabled" },{ value: "16", label: "BLF" },{ value: "15", label: "Line" },{value:"13",label:"Speed Dial"}]}
            type="select"
          />
          <VipProvisioningFieldMui

            updateField={updateField}
            data={line}
            dataField={`linekey_${val}_line`}
            title={`Line Key ${val} Line`}
            type="number"
          />
          <VipProvisioningFieldMui

            updateField={updateField}
            data={state[`linekey_${val}_label`]}
            dataField={`linekey_${val}_label`}
            title={`Line Key ${val} Label`}
            type="string"
          />
          <VipProvisioningFieldMui

            updateField={updateField}
            data={state[`linekey_${val}_value`]}
            dataField={`linekey_${val}_value`}
            title={`Line Key ${val} value`}
            type="string"
          />
          <hr className="mb-3" />
        </div>
      })}

    </>} />


    <ReviewCollapse header="Account 1" initalOpen={false} content={<>
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.transportMethod}
        dataField="transportMethod"
        title="Account 1 Transport"
        type="select"
        selectOptions={transportSelectOptions}
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.srtp}
        dataField="srtp"
        title="Account 1 SRTP"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.account1MissedCallLog}
        dataField="account1MissedCallLog"
        title="Account 1 Missed Call Log"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.useCallerIdAsLabel}
        dataField="useCallerIdAsLabel"
        title="Use caller ID as phone label?"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.account1Label}
        dataField="account1Label"
        title="Account 1 Label"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.account1DisplayName}
        dataField="account1DisplayName"
        title="Account 1 Display Name"
        type="string"
      />

    </>} />

    <ReviewCollapse header="Account 2" initalOpen={false} content={<>
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.account2TransportMethod}
        dataField="account2TransportMethod"
        title="Account 2 Transport"
        type="select"
        selectOptions={transportSelectOptions}
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.account2Srtp}
        dataField="account2Srtp"
        title="Account 2 SRTP"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.account2MissedCallLog}
        dataField="account2MissedCallLog"
        title="Account 2 Missed Call Log"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.account2Label}
        dataField="account2Label"
        title="Account 2 Label"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.account2DisplayName}
        dataField="account2DisplayName"
        title="Account 2 Display Name"
        type="string"
      />
    </>} />

    <ReviewCollapse header="LDAP" initalOpen={false} content={<>
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapEnable}
        dataField="ldapEnable"
        title="Enable LDAP"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapNameFilter}
        dataField="ldapNameFilter"
        title="LDAP Name Filter"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapNumberFilter}
        dataField="ldapNumberFilter"
        title="LDAP Number Filter"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapHost}
        dataField="ldapHost"
        title="LDAP Host"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapPort}
        dataField="ldapPort"
        title="LDAP Port"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapBase}
        dataField="ldapBase"
        title="LDAP Base"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapUser}
        dataField="ldapUser"
        title="LDAP User"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapPassword}
        dataField="ldapPassword"
        title="LDAP Password"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapMaxHits}
        dataField="ldapMaxHits"
        title="LDAP Max Hits"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapNameAttributes}
        dataField="ldapNameAttributes"
        title="LDAP Name Attributes"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapNumberAttributes}
        dataField="ldapNumberAttributes"
        title="LDAP Number Attributes"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapDisplayName}
        dataField="ldapDisplayName"
        title="LDAP Display Name"
        type="string"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapVersion}
        dataField="ldapVersion"
        title="LDAP Version"
        type="select"
        selectOptions={ldapVersionOptions}
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapCallInLookup}
        dataField="ldapCallInLookup"
        title="LDAP Look Up Incoming Calls"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.ldapSort}
        dataField="ldapSort"
        title="LDAP Alphabetical Sort"
        type="boolean"
      />
    </>} />


    <hr className="mb-3" />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.networkDhcpHostName}
      dataField="networkDhcpHostName"
      title="DHCP Host Name"
      type="string"
    />
    <hr className="mb-3" />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.programmablekey3Type}
      dataField="programmablekey3Type"
      title="Programmable Key 3 Type"
      type="select"
      selectOptions={programmableKey3TypeOptions}
    />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.localTimeTimeZone}
      dataField="localTimeTimeZone"
      title="Time Zone"
      type="select"
      selectOptions={localTimeZoneOptions}
    />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.customStatusesJson}
      dataField="customStatusesJson"
      title="Custom Statuses"
      type="CustomStatuses"
    />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.phoneLanguage}
      dataField="phoneLanguage"
      title="Phone Language"
      type="select"
      selectOptions={phoneLanguageOptions}
    />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.featuresSpeakerModeEnable}
      dataField="featuresSpeakerModeEnable"
      title="Speaker Mode Enabled?"
      type="boolean"
    />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.wuiHttpEnable}
      dataField="wuiHttpEnable"
      title="HTTP Admin Panel Enabled?"
      type="boolean"
    />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.wuiHttpsEnable}
      dataField="wuiHttpsEnable"
      title="HTTPS Admin Panel Enabled?"
      type="boolean"
    />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.phoneSettingMissedCallPowerLedFlashEnable}
      dataField="phoneSettingMissedCallPowerLedFlashEnable"
      title="Power Light Flash After Missed Call?"
      type="boolean"
    />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.featuresVoiceMailPopupEnable}
      dataField="featuresVoiceMailPopupEnable"
      title="Voice Mail Pop Up Notifications?"
      type="boolean"
    />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.staticSecurityAdminPassword}
      dataField="staticSecurityAdminPassword"
      title="Admin Panel Password"
      type="string"
    />
    <VipProvisioningFieldMui

      updateField={updateField}
      data={state.enabledDirectories}
      dataField="enabledDirectories"
      title="Enabled Directories"
      type="checkBoxList"
      payload={[
        { id: "LDAP", name: "LDAP" },
        { id: "History", name: "History" },
        { id: "Remote Phone Book", name: "Remote Phone Book" },
        { id: "Blacklist", name: "Blacklist" },
        { id: "Local Directory", name: "Local Directory" },
      ]}
    />


    <ReviewCollapse header="Desktop App" initalOpen={false} content={<>
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.desktopAppAutomaticUpdatesDisabled}
        dataField="desktopAppAutomaticUpdatesDisabled"
        title="Desktop App Automatic Updates Disabled"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.desktopAppAutomaticAnswerDisabled}
        dataField="desktopAppAutomaticAnswerDisabled"
        title="Desktop App Automatic Answer Disabled"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.desktopAppCloseWindowDisabled}
        dataField="desktopAppCloseWindowDisabled"
        title="Desktop App Close Window Disabled"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.desktopAppCallFileOnAnswer}
        dataField="desktopAppCallFileOnAnswer"
        title="Desktop App Generate Call File On Answer"
        type="boolean"
      />
      <VipProvisioningFieldMui

        updateField={updateField}
        data={state.desktopAppExtensionsToShow}
        dataField="desktopAppExtensionsToShow"
        title="Desktop App Extensions To Show"
        type="select"
        selectOptions={desktopAppExtensionsToShowOptions}
      />
    </>} />

    {resellerMode && (
      <>
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.featuresShowActionUriOption}
          dataField="featuresShowActionUriOption"
          title="Show Action Uri Option"
          type="boolean"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.featuresActionUriLimitIp}
          dataField="featuresActionUriLimitIp"
          title="Action Uri Allow IP List"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlSetupCompleted}
          dataField="actionUrlSetupCompleted"
          title="Action URL Setup Complete"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlRegistered}
          dataField="actionUrlRegistered"
          title="Action URL Registered"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlUnregistered}
          dataField="actionUrlUnregistered"
          title="Action URL Unregistered"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlRegisterFailed}
          dataField="actionUrlRegisterFailed"
          title="Action URL Register Failed"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlOffHook}
          dataField="actionUrlOffHook"
          title="Action URL Off Hook"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlOnHook}
          dataField="actionUrlOnHook"
          title="Action URL On Hook"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlIncomingCall}
          dataField="actionUrlIncomingCall"
          title="Action URL Incoming Call"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlOutgoingCall}
          dataField="actionUrlOutgoingCall"
          title="Action URL Outgoing Call"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlCallEstablished}
          dataField="actionUrlCallEstablished"
          title="Action URL Call Established"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlDndOn}
          dataField="actionUrlDndOn"
          title="Action URL DND On"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlDndOff}
          dataField="actionUrlDndOff"
          title="Action URL DND Off"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlAlwaysFwdOn}
          dataField="actionUrlAlwaysFwdOn"
          title="Action URL Always Forward On"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlAlwaysFwdOff}
          dataField="actionUrlAlwaysFwdOff"
          title="Action URL Always Forward Off"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlBusyFwdOn}
          dataField="actionUrlBusyFwdOn"
          title="Action URL Busy Forward On"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlBusyFwdOff}
          dataField="actionUrlBusyFwdOff"
          title="Action URL Busy Forward Off"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlNoAnswerFwdOn}
          dataField="actionUrlNoAnswerFwdOn"
          title="Action URL No Answer Forward On"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlNoAnswerFwdOff}
          dataField="actionUrlNoAnswerFwdOff"
          title="Action URL No Answer Forward Off"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlTransferCall}
          dataField="actionUrlTransferCall"
          title="Action URL Transfer Call"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlBlindTransferCall}
          dataField="actionUrlBlindTransferCall"
          title="Action URL Blind Transfer Call"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlAttendedTransferCall}
          dataField="actionUrlAttendedTransferCall"
          title="Action URL Attended Transfer Call"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlHold}
          dataField="actionUrlHold"
          title="Action URL Hold"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlUnhold}
          dataField="actionUrlUnhold"
          title="Action URL Unhold"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlHeld}
          dataField="actionUrlHeld"
          title="Action URL Held"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlUnheld}
          dataField="actionUrlUnheld"
          title="Action URL Unheld"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlMute}
          dataField="actionUrlMute"
          title="Action URL Mute"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlUnmute}
          dataField="actionUrlUnmute"
          title="Action URL Unmute"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlMissedCall}
          dataField="actionUrlMissedCall"
          title="Action URL Missed Call"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlCallTerminated}
          dataField="actionUrlCallTerminated"
          title="Action URL Call Terminated"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlBusyToIdle}
          dataField="actionUrlBusyToIdle"
          title="Action URL Busy To Idle"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlIdleToBusy}
          dataField="actionUrlIdleToBusy"
          title="Action URL Idle To Busy"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlIpChange}
          dataField="actionUrlIpChange"
          title="Action URL IP Change"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlForwardIncomingCall}
          dataField="actionUrlForwardIncomingCall"
          title="Action URL Forward Incoming Call"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlRejectIncomingCall}
          dataField="actionUrlRejectIncomingCall"
          title="Action URL Reject Incoming Call"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlAnswerNewIncomingCall}
          dataField="actionUrlAnswerNewIncomingCall"
          title="Action URL Answer New Incoming Call"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlTransferFinished}
          dataField="actionUrlTransferFinished"
          title="Action URL Transfer Finished"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlTransferFailed}
          dataField="actionUrlTransferFailed"
          title="Action URL Transfer Failed"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlSetupAutopFinish}
          dataField="actionUrlSetupAutopFinish"
          title="Action URL Setup Auto Provision Finish"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlCallWaitingOn}
          dataField="actionUrlCallWaitingOn"
          title="Action URL Call Waiting On"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlCallWaitingOff}
          dataField="actionUrlCallWaitingOff"
          title="Action URL Call Waiting Off"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlHeadset}
          dataField="actionUrlHeadset"
          title="Action URL Headset"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlHandfree}
          dataField="actionUrlHandfree"
          title="Action URL Hands Free"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlCancelCallout}
          dataField="actionUrlCancelCallout"
          title="Action URL Cancel Callout"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlRemoteBusy}
          dataField="actionUrlRemoteBusy"
          title="Action URL Remote Busy"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlCallRemoteCanceled}
          dataField="actionUrlCallRemoteCanceled"
          title="Action URL Call Remove Cancelled"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlPeripheralInformation}
          dataField="actionUrlPeripheralInformation"
          title="Action URL Peripheral Infomation"
          type="string"
        />
        <VipProvisioningFieldMui

          updateField={updateField}
          data={state.actionUrlVpnIp}
          dataField="actionUrlVpnIp"
          title="Action URL VPN IP"
          type="string"
        />
      </>
    )}
    <ReviewCollapse header="Custom Fields" initalOpen={false} content={<>

      <VipProvisioningCustomFields
        value={data.customFields}
        updateField={updateField}
      />
    </>} />
    {showYeaLinkFirmware && <ReviewCollapse header="Yealink Firmwares" initalOpen={false} content={<>

      <VipProvisioningYealink
        value={data.yealinkFirmwares}
        updateField={updateField}
      />
    </>} />}
    {updateFieldOverride === undefined && (
      <div style={{ textAlign: 'center' }}>
        {isSaving ? (<Loading />) : (<Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>)}
      </div>
    )}


  </>
}



export default memo(FormProvisioning, (a, b) => false)


/*extends Component<
  IFormProvisioningProp,
  IProvisioningSettings
> {
  constructor(props: any) {
    super(props);

    state = props.data;
    //  state.enabled = true;

    handleSubmit.bind(this);

    updateField.bind(this);
    getData.bind(this);
  }


  public render() {


    return (
      <>

        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="yealinkTemplateVersion"
          title="Yealink Template Version"
          type="string"
        />
        <ReviewCollapse header="Line Keys" initalOpen={false} content={<>
          {lineKeys.map((val, valIndex) => {
            return <div key={valIndex}>
              <VipProvisioningFieldMui
                
                updateField={updateField}
                data={data}
                dataField={`linekey_${val}_type`}
                title={`Line Key ${val} Type`}
                selectOptions={[{ value: "0", label: "Disabled" }, { value: "16", label: "BLF" }]}
                type="select"
              />
              <VipProvisioningFieldMui
                
                updateField={updateField}
                data={data}
                dataField={`linekey_${val}_line`}
                title={`Line Key ${val} Line`}
                type="number"
              />
              <VipProvisioningFieldMui
                
                updateField={updateField}
                data={data}
                dataField={`linekey_${val}_label`}
                title={`Line Key ${val} Label`}
                type="string"
              />
              <VipProvisioningFieldMui
                
                updateField={updateField}
                data={data}
                dataField={`linekey_${val}_value`}
                title={`Line Key ${val} value`}
                type="string"
              />
              <hr className="mb-3" />
            </div>
          })}

        </>} />



        <ReviewCollapse header="Account 1" initalOpen={false} content={<>
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="transportMethod"
            title="Account 1 Transport"
            type="select"
            selectOptions={transportSelectOptions}
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="srtp"
            title="Account 1 SRTP"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="account1MissedCallLog"
            title="Account 1 Missed Call Log"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="useCallerIdAsLabel"
            title="Use caller ID as phone label?"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="account1Label"
            title="Account 1 Label"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="account1DisplayName"
            title="Account 1 Display Name"
            type="string"
          />

        </>} />

        <ReviewCollapse header="Account 2" initalOpen={false} content={<>
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="account2TransportMethod"
            title="Account 2 Transport"
            type="select"
            selectOptions={transportSelectOptions}
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="account2Srtp"
            title="Account 2 SRTP"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="account2MissedCallLog"
            title="Account 2 Missed Call Log"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="account2Label"
            title="Account 2 Label"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="account2DisplayName"
            title="Account 2 Display Name"
            type="string"
          />
        </>} />

        <ReviewCollapse header="LDAP" initalOpen={false} content={<>
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapEnable"
            title="Enable LDAP"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapNameFilter"
            title="LDAP Name Filter"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapNumberFilter"
            title="LDAP Number Filter"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapHost"
            title="LDAP Host"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapPort"
            title="LDAP Port"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapBase"
            title="LDAP Base"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapUser"
            title="LDAP User"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapPassword"
            title="LDAP Password"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapMaxHits"
            title="LDAP Max Hits"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapNameAttributes"
            title="LDAP Name Attributes"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapNumberAttributes"
            title="LDAP Number Attributes"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapDisplayName"
            title="LDAP Display Name"
            type="string"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapVersion"
            title="LDAP Version"
            type="select"
            selectOptions={ldapVersionOptions}
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapCallInLookup"
            title="LDAP Look Up Incoming Calls"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="ldapSort"
            title="LDAP Alphabetical Sort"
            type="boolean"
          />
        </>} />


        <hr className="mb-3" />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="networkDhcpHostName"
          title="DHCP Host Name"
          type="string"
        />
        <hr className="mb-3" />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="programmablekey3Type"
          title="Programmable Key 3 Type"
          type="select"
        />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="localTimeTimeZone"
          title="Time Zone"
          type="select"
          selectOptions={localTimeZoneOptions}
        />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="customStatusesJson"
          title="Custom Statuses"
          type="CustomStatuses"
        />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="phoneLanguage"
          title="Phone Language"
          type="select"
          selectOptions={phoneLanguageOptions}
        />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="featuresSpeakerModeEnable"
          title="Speaker Mode Enabled?"
          type="boolean"
        />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="wuiHttpEnable"
          title="HTTP Admin Panel Enabled?"
          type="boolean"
        />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="wuiHttpsEnable"
          title="HTTPS Admin Panel Enabled?"
          type="boolean"
        />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="phoneSettingMissedCallPowerLedFlashEnable"
          title="Power Light Flash After Missed Call?"
          type="boolean"
        />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="featuresVoiceMailPopupEnable"
          title="Voice Mail Pop Up Notifications?"
          type="boolean"
        />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="staticSecurityAdminPassword"
          title="Admin Panel Password"
          type="string"
        />
        <VipProvisioningFieldMui
          
          updateField={updateField}
          data={data}
          dataField="enabledDirectories"
          title="Enabled Directories"
          type="checkBoxList"
          payload={[
            { id: "LDAP", name: "LDAP" },
            { id: "History", name: "History" },
            { id: "Remote Phone Book", name: "Remote Phone Book" },
            { id: "Blacklist", name: "Blacklist" },
            { id: "Local Directory", name: "Local Directory" },
          ]}
        />


        <ReviewCollapse header="Desktop App" initalOpen={false} content={<>
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="desktopAppAutomaticUpdatesDisabled"
            title="Desktop App Automatic Updates Disabled"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="desktopAppAutomaticAnswerDisabled"
            title="Desktop App Automatic Answer Disabled"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="desktopAppCloseWindowDisabled"
            title="Desktop App Close Window Disabled"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="desktopAppCallFileOnAnswer"
            title="Desktop App Generate Call File On Answer"
            type="boolean"
          />
          <VipProvisioningFieldMui
            
            updateField={updateField}
            data={data}
            dataField="desktopAppExtensionsToShow"
            title="Desktop App Extensions To Show"
            type="select"
            selectOptions={desktopAppExtensionsToShowOptions}
          />
        </>} />

        {props.resellerMode && (
          <>
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="featuresShowActionUriOption"
              title="Show Action Uri Option"
              type="boolean"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="featuresActionUriLimitIp"
              title="Action Uri Allow IP List"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlSetupCompleted"
              title="Action URL Setup Complete"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlRegistered"
              title="Action URL Registered"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlUnregistered"
              title="Action URL Unregistered"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlRegisterFailed"
              title="Action URL Register Failed"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlOffHook"
              title="Action URL Off Hook"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlOnHook"
              title="Action URL On Hook"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlIncomingCall"
              title="Action URL Incoming Call"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlOutgoingCall"
              title="Action URL Outgoing Call"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlCallEstablished"
              title="Action URL Call Established"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlDndOn"
              title="Action URL DND On"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlDndOff"
              title="Action URL DND Off"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlAlwaysFwdOn"
              title="Action URL Always Forward On"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlAlwaysFwdOff"
              title="Action URL Always Forward Off"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlBusyFwdOn"
              title="Action URL Busy Forward On"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlBusyFwdOff"
              title="Action URL Busy Forward Off"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlNoAnswerFwdOn"
              title="Action URL No Answer Forward On"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlNoAnswerFwdOff"
              title="Action URL No Answer Forward Off"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlTransferCall"
              title="Action URL Transfer Call"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlBlindTransferCall"
              title="Action URL Blind Transfer Call"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlAttendedTransferCall"
              title="Action URL Attended Transfer Call"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlHold"
              title="Action URL Hold"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlUnhold"
              title="Action URL Unhold"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlHeld"
              title="Action URL Held"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlUnheld"
              title="Action URL Unheld"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlMute"
              title="Action URL Mute"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlUnmute"
              title="Action URL Unmute"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlMissedCall"
              title="Action URL Missed Call"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlCallTerminated"
              title="Action URL Call Terminated"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlBusyToIdle"
              title="Action URL Busy To Idle"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlIdleToBusy"
              title="Action URL Idle To Busy"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlIpChange"
              title="Action URL IP Change"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlForwardIncomingCall"
              title="Action URL Forward Incoming Call"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlRejectIncomingCall"
              title="Action URL Reject Incoming Call"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlAnswerNewIncomingCall"
              title="Action URL Answer New Incoming Call"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlTransferFinished"
              title="Action URL Transfer Finished"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlTransferFailed"
              title="Action URL Transfer Failed"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlSetupAutopFinish"
              title="Action URL Setup Auto Provision Finish"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlCallWaitingOn"
              title="Action URL Call Waiting On"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlCallWaitingOff"
              title="Action URL Call Waiting Off"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlHeadset"
              title="Action URL Headset"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlHandfree"
              title="Action URL Hands Free"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlCancelCallout"
              title="Action URL Cancel Callout"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlRemoteBusy"
              title="Action URL Remote Busy"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlCallRemoteCanceled"
              title="Action URL Call Remove Cancelled"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlPeripheralInformation"
              title="Action URL Peripheral Infomation"
              type="string"
            />
            <VipProvisioningFieldMui
              
              updateField={updateField}
              data={data}
              dataField="actionUrlVpnIp"
              title="Action URL VPN IP"
              type="string"
            />
          </>
        )}
        <ReviewCollapse header="Custom Fields" initalOpen={false} content={<>

          <VipProvisioningCustomFields
            value={data.customFields}
            updateField={updateField}
          />
        </>} />
        {props.showYeaLinkFirmware && <ReviewCollapse header="Yealink Firmwares" initalOpen={false} content={<>

          <VipProvisioningYealink
            value={data.yealinkFirmwares}
            updateField={updateField}
          />
        </>} />}
        {props.updateFieldOverride === undefined && (
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        )}


      </>
    );
  }
}
*/
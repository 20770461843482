import { memo, useState } from "react";
import {
  IEmailSubReport,
  IEmailSubReportAgentSummary, IEmailSubReportAutoAttendantOptions, IEmailSubReportCallLogs, IEmailSubReportCallPaths, IEmailSubReportCallRecordings, IEmailSubReportCustomPresenceChanges, IEmailSubReportDNDChanges, IEmailSubReportHotDeskLogins,
  IEmailSubReportLostCalls,
  IEmailSubReportNumberTrackers,
  IEmailSubReportPerDDIPerHour,

} from "../../interfaces/email-reports";
import { IExtension } from "../../interfaces/extension";
import { INumber } from "../../interfaces/number";
import EmailReportCustomPresence from "./email-report-agent-custom-presence";
import EmailReportDND from "./email-report-agent-dnd";
import EmailReportAgentSummary from "./email-report-agent-summary";
import EmailReportAttendantOptions from "./email-report-attendant-options";
import EmailReportCallLogs from "./email-report-call-logs";
import EmailReportCallPaths from "./email-report-call-paths";
import EmailReportCallRecordings from "./email-report-call-recordings";
import EmailReportHotDeskLogins from "./email-report-hot-desk-logins";
import EmailReportLostCalls from "./email-report-lost-calls";
import EmailReportNumberTrackers from "./email-report-number-trackers";
import EmailReportPerDDIPerHour from "./email-report-per-ddi-per-hour";
import { Box, Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import React from "react";

export interface IEmailSubReportProps {
  data: IEmailSubReport;
  dataIndex: number;
  extensions: IExtension[];
  hotDesks: IExtension[];
  ddis: INumber[];
  submitFunction: (field: string, data: any) => void;
  deleteSubReport: (reportID: number) => void;
}


const FormEmailSubReport = ({ data, dataIndex, extensions, hotDesks, ddis, submitFunction, deleteSubReport }: IEmailSubReportProps) => {


  const [state, setState] = useState<IEmailSubReport>(() => {
    const xState = { ...data };

    return xState;
  })

  const handleReportData = (field: string, data: any) => {
    console.log(field, data)
    const stateUp: any = { ...state };
    stateUp[field] = data;
    setState(stateUp);
    submitFunction(field, data);
  };


  const renderReportType = (reportType: string): any => {
    switch (reportType) {
      case "Auto Attendant Options":
        return <EmailReportAttendantOptions
          data={state as IEmailSubReportAutoAttendantOptions}
          submitFunction={handleReportData}
        />
      case "Call Logs":
        return <EmailReportCallLogs
          data={state as IEmailSubReportCallLogs}
          submitFunction={handleReportData}
        />
      case "Call Paths":
        return <EmailReportCallPaths
          data={state as IEmailSubReportCallPaths}
          submitFunction={handleReportData}
        />
      case "Call Recordings":
        return <EmailReportCallRecordings
          extensions={extensions}
          hotDesks={hotDesks}
          data={state as IEmailSubReportCallRecordings}
          submitFunction={handleReportData}

        />
      case "Custom Presence":
        return <EmailReportCustomPresence
          data={state as IEmailSubReportCustomPresenceChanges}
          submitFunction={handleReportData}
        />
      case "DND Changes":
        return <EmailReportDND
          data={state as IEmailSubReportDNDChanges}
          submitFunction={handleReportData}
        />
      case "Hot Desk Logins":
        return <EmailReportHotDeskLogins
          data={state as IEmailSubReportHotDeskLogins}
          submitFunction={handleReportData}
        />
      case "Lost Calls":
        return <EmailReportLostCalls
          data={state as IEmailSubReportLostCalls}
          submitFunction={handleReportData}
        />
      case "Number Trackers":
        return <EmailReportNumberTrackers
          extensions={extensions}
          data={state as IEmailSubReportNumberTrackers}
          submitFunction={handleReportData}
        />
      case "Per DDI Per Hour":
        return <EmailReportPerDDIPerHour data={state as IEmailSubReportPerDDIPerHour} submitFunction={handleReportData} />
      default:
        return <EmailReportAgentSummary
          ddis={ddis}
          extensions={extensions}
          hotDesks={hotDesks}
          data={state as IEmailSubReportAgentSummary}
          submitFunction={handleReportData}
        />
    }
  }





  return (<Box component={'div'} >
    {renderReportType(state.reportType)}

    <Box component={'div'}  style={{width: "auto", textAlign:"right", marginTop: 20,  marginBottom: 20}}>
      <Button

        variant="outlined"
        color="secondary"
        onClick={(event: any) => {
          event.preventDefault();
          deleteSubReport(dataIndex);
        }}
        className="noleft"
        endIcon={<DeleteIcon />}
      >
        Remove Sub-Report
      </Button>
    </Box>
  </Box>
  );

}
export default memo(FormEmailSubReport)



import React, { useState, useEffect, useRef } from "react";
import Wizard from "../../components/Wizard";
import { FieldArray } from "react-final-form-arrays";
import {
  IRetailTariffBand,
  ITariff,
  ICustomer,
} from "../../interfaces/customer";
import VoipTable from "../../components/VoipTable";
import { Link } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import image from "../../compiledSVG/undraw_people_search_wctu.svg";
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import MuiTextInput from "../../components/MuiComponents/MuiTextInput";
import MuiCheckbox from "../../components/MuiComponents/MuiCheckbox";

type TTariffForm = {
  data: ITariff;
  onSubmit: any;
  bands: IRetailTariffBand[];
  customers: ICustomer[];
};

const TariffForm: React.FunctionComponent<TTariffForm> = ({
  data,
  bands,
  customers,
  onSubmit,
}) => {
  const [search, setSearch] = useState("");
  let [key, setKey] = useState(1);
  const [bandlist, setBandlist] = useState<any>([]);
  const filter = (e: string) => {
    setBandlist(
      bands.filter(
        (t) => t.description.toLowerCase().indexOf(e.toLowerCase()) !== -1
      )
    );
    setKey(Math.floor(Math.random() * 100));
    console.log(e);
    console.log(bandlist);
  };

  useEffect(() => {
    setBandlist(bands);
  }, [bands]);


  data.bands = data.bands.map((i) => {
    if (i.freeMinutes !== undefined)
      i.freeMinutesStr = i.freeMinutes.toString();
    if (i.price !== undefined) i.priceStr = i.price.toString();
    if (i.pencePerCall !== undefined)
      i.pencePerCallStr = i.pencePerCall.toString();
    return i;
  });

  if (data.incomingSalesPrice !== undefined) {
    data.incomingSalesPriceStr = data.incomingSalesPrice.toString();
  }
  if (data.incomingSalesPriceMobile !== undefined) {
    data.incomingSalesPriceMobileStr = data.incomingSalesPriceMobile.toString();
  }

  if (data.freeMinutes !== undefined) {
    data.freeMinutesStr = data.freeMinutes.toString();
  }
  let pageMenu = false;
  if (data.id !== "") {
    pageMenu = true;
  }

  const scollToRef = useRef<HTMLDivElement>();
  const tableHeadStyle = { padding: "0px", margin: '0px', textDecoration: 'none', color: '#5b6688', fontSize: "14px", fontWeight: 'normal' }
  return (
    <Wizard
      initialValues={data}
      onSubmit={onSubmit}
      menuBottom={false}
      pageMenu={pageMenu}
    >
      <Wizard.Page
        n="DETAILS"
        nt="Details"
        t={1}
        validate={(values: any) => {
          const errors: any = {};
          /* if (!values.purchaced) {
                 errors.purchaced = 'Required'
             }*/
          return errors;
        }}
        render={(values: any, push: any, pop: any, form: any) => (
          <>
            <fieldset>
              {values.id === "" && <legend>Details</legend>}
              <div className="form-group">
                <label>Name</label>
                <MuiTextInput
                  name="name"
                  placeholder="Name"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <MuiTextInput
                  name="description"
                  placeholder="Description"
                  required={false}
                />
              </div>
            </fieldset>
          </>
        )}
        onSubmit={(values: any, next: any) => {
          console.log(values);
          next(values);
        }}
      ></Wizard.Page>

      <Wizard.Page
        n="OUT"
        nt="Outgoing Call Charges"
        validate={(values: any) => {
          const errors: any = {};
          /*if (!values.purchaced) {
                errors.purchaced = 'Required'
            }*/
          return errors;
        }}
        render={(values: any, push: any, pop: any, form: any) => {
          const col: any[] = [
            {
              Header: "Description",
              accessor: "description",
              sortable: true,
            },
            {
              Header: "Wholesale Pence Per Call",
              accessor: "wholesalePencePerCall",
              sortable: true,
            },
            {
              Header: "Wholesale Pence Per Call",
              accessor: "wholesalePencePerMinute",
              sortable: true,
            },
            {
              Header: "Retail Pence Per Minute",
              accessor: "price",
              sortable: true,
            },
            {
              Header: "Retail Pence Per Call",
              accessor: "pencePerCall",
              sortable: true,
            },
            {
              Header: "",
              accessor: "id", // String-based value accessors!
              Cell: (props: any) => {
                let index = -1;

                if (values && values.brands) {
                  index = values.bands.findIndex(
                    (ddd: any) => ddd.description === props.obj.description
                  );
                }
                //  console.log(props, values.bands, props.obj.description, index)
                if (index === -1) {
                  return (
                    <Button
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={() => {
                        push("bands", { description: props.obj.description })
                        scollToRef && scollToRef.current && scollToRef.current.scrollIntoView && scollToRef.current.scrollIntoView();
                      }}
                    >
                      Override Price
                    </Button>
                  );
                } else {
                  return <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={() => {
                      scollToRef && scollToRef.current && scollToRef.current.scrollIntoView && scollToRef.current.scrollIntoView();
                    }}
                  >
                    Edit Override Price
                  </Button>;
                }
              },
            },
          ];

          return (
            <>
              <fieldset>

                {values.id === "" && <legend>Outgoing Call Charges</legend>}

                <legend>Retail Bands Overridden</legend>
                <div ref={scollToRef}></div>


                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={tableHeadStyle}>Description</TableCell>
                        <TableCell style={tableHeadStyle} align="center">Pence Per Minute</TableCell>
                        <TableCell style={tableHeadStyle} align="center">Pence Per Call</TableCell>
                        <TableCell style={tableHeadStyle} align="center">Free Minutes</TableCell>
                        <TableCell style={tableHeadStyle} align="center">Inc in Free Mins</TableCell>
                        <TableCell style={tableHeadStyle} align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <FieldArray name="bands">
                        {({ fields }) =>
                          fields.map((number, index) => (
                            <TableRow key={number}>
                              <TableCell>{values.bands[index].description}</TableCell>
                              <TableCell align="right">
                                <MuiTextInput
                                  type="number"
                                  name={`${number}.priceStr`}
                                  step=".01"
                                  placeholder=""
                                  required={true}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <MuiTextInput
                                  type="number"
                                  name={`${number}.pencePerCallStr`}
                                  step=".01"
                                  placeholder=""
                                  required={true}
                                />
                              </TableCell>

                              <TableCell align="right">
                                <MuiTextInput
                                  type="number"
                                  name={`${number}.freeMinutesStr`}
                                  placeholder=""
                                  required={true}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <MuiCheckbox
                                  name={`${number}.includeInOverallFreeMinutes`}
                                />
                              </TableCell>

                              <TableCell align="right">
                                <IconButton

                                  onClick={() => fields.remove(index)}
                                >
                                  <DeleteIcon style={{ color: "red" }} />

                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </FieldArray>
                    </TableBody>
                  </Table>
                </TableContainer>


                <div>
                  <label>Overall Free Minutes</label>
                  <MuiTextInput
                    type="number"
                    name="freeMinutesStr"
                    placeholder=""
                    required={true}
                  />
                </div>

                <legend>
                  Standard Tariffs{" "}
                  <span>
                    <input
                      value={search}
                      onChange={(e) => [
                        setSearch(e.target.value),
                        filter(e.target.value),
                      ]}
                      style={{
                        left: 0,
                        margin: "20px 0",
                        width: "70%",
                        height: "30px",
                        fontSize: "15px",
                        padding: "15px",
                        outline: "none",
                        borderRadius: "5px",
                        marginLeft: "20px",
                      }}
                      placeholder="Search"
                      type="text"
                    />
                  </span>
                </legend>
                <VoipTable
                  title="standard tariff bands"
                  sortByStart="name"
                  data={bandlist}
                  col={col}
                  multiSelect={false}
                  showResultsCount={false}
                  holderClass=""
                  key={key}
                />

              </fieldset>
            </>
          );
        }}
        onSubmit={(values: ITariff, next: any) => {
          //          console.log(values);

          values.bands = values.bands.map((i) => {
            if (i.freeMinutesStr !== undefined)
              i.freeMinutes = parseFloat(i.freeMinutesStr);
            if (i.priceStr !== undefined) i.price = parseFloat(i.priceStr);
            if (i.pencePerCallStr !== undefined)
              i.pencePerCall = parseFloat(i.pencePerCallStr);

            return i;
          });

          if (values.freeMinutesStr !== undefined) {
            values.freeMinutes = parseFloat(values.freeMinutesStr);
          }
          next(values);
        }}
      ></Wizard.Page>
      <Wizard.Page
        n="IN"
        nt="Incoming Call Charges"
        validate={(values: any) => {
          const errors: any = {};
          /*if (!values.purchaced) {
                errors.purchaced = 'Required'
            }*/
          return errors;
        }}
        render={(values: any, push: any, pop: any, form: any) => {
          /*
    verrideIncomingDefault:boolean
    incomingSalesPriceMobile:number
    incomingSalesPrice:number  const scollToRef = useRef();
    freeMinutes:number
 */

          return (
            <>
              <fieldset>
                {values.id === "" && <legend>Incoming Call Charges</legend>}
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={tableHeadStyle}>Prefix</TableCell>
                        <TableCell style={tableHeadStyle} align="right">Override?</TableCell>
                        <TableCell style={tableHeadStyle} align="center">Sales Price (PPM)</TableCell>
                        <TableCell style={tableHeadStyle} align="center">Call From Mobile Sales Price (PPM)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>


                      <TableRow>
                        <TableCell>
                        0800, 0808
                        </TableCell>
                        <TableCell align="right">
                        <MuiCheckbox name="overrideIncomingDefault" />
                        </TableCell>
                        <TableCell>
                        <MuiTextInput
                          type="number"
                          step=".01"
                          name="incomingSalesPriceStr"
                          placeholder=""
                          required={true}
                          disabled={!values.overrideIncomingDefault}
                        />
                        </TableCell>
                        <TableCell>
                        <MuiTextInput
                          type="number"
                          step=".01"
                          name="incomingSalesPriceMobileStr"
                          placeholder=""
                          required={true}
                          disabled={!values.overrideIncomingDefault}
                        />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
            </fieldset >
            </>
  );
}}
onSubmit = {(values: any, next: any) => {
  if (values.incomingSalesPriceStr !== undefined) {
    values.incomingSalesPrice = parseFloat(
      values.incomingSalesPriceStr
    );
  }

  if (values.incomingSalesPriceMobileStr !== undefined) {
    values.incomingSalesPriceMobile = parseFloat(
      values.incomingSalesPriceMobileStr
    );
  }
  if (values.id === "") {
    // is new
    next(values, "SUBMIT_NOW");
  } else {
    next(values);
  }

  //  values.moded = 'dfssdfsd'
}}
      ></Wizard.Page >
  <Wizard.Page
    n="CUSTOMERS"
    nt="Customers"
    validate={(values: any) => {
      const errors: any = {};

      return errors;
    }}
    render={(values: any, push: any, pop: any, form: any) => {
      const col: any[] = [
        {
          Header: "Name",
          accessor: "name",
          sortable: true,
        },
        {
          Header: "",
          accessor: "id", // String-based value accessors!
          Cell: (props: any) => {
            const link = "/reseller/" + props.value;
            return <Link className="btn btn-primary" to={link}>
              Select Customer
            </Link>
          },
        },
      ];

      return (
        <>
          <fieldset>
            {customers.length > 0 ? (
              <VoipTable
                title="tariff customers"
                sortByStart="name"
                data={customers}
                col={col}
                multiSelect={false}
                showResultsCount={false}
                holderClass=""
              />
            ) : (
              <div className="text-center">
                <img
                  src={image}
                  className="img-fluid"
                  alt="No Results Found"
                />
                <br />
                <br />
                <h2>
                  There are currently no customers associated with this
                  tariff.
                </h2>
                <p>
                  Not seeing what you were expecting? Try again or contact
                  support.
                </p>
              </div>
            )}
          </fieldset>
        </>
      );
    }}
    onSubmit={(values: any, next: any) => {
      next(values);
    }}
  ></Wizard.Page>
    </Wizard >
  );
};

export default TariffForm;

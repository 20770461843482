

import * as React from 'react';

import { Row, Col } from 'react-bootstrap';
//import VoipTable from '../../components/VoipTable';
import { IMinutesReport } from '../../interfaces/reseller-invoice';
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";

interface IMinutesReportProps {
    items:IMinutesReport[]
}

const ResellerInvoiceMinutesReport: React.FunctionComponent<IMinutesReportProps> = ({items}) => {
    const columns = [{
      label:'Name',
      name:'name'
    },{
      label:'UK Geo Landline Minutes',
      name:'ukgeolandlineMinutes'
    },{
      label:'UK Mobile Minutes',
      name:'ukmobileMinutes'
    },{
      label:'UK Special Minutes',
      name:'ukspecialMinutes'
    },{
      label:'International Minutes',
      name:'internationalMinutes'
    }
    ]
    /*const cols = [
        {
          Header: "Name",
          accessor: "name",
          sortable: true,
        },
        {
          Header: "UK Geo Landline Minutes",
          accessor: "ukgeolandlineMinutes",
          sortable: true,
          cssClass:'text-center'
        },
        {
          Header: "UK Mobile Minutes",
          accessor: "ukmobileMinutes",
          sortable: true,
          cssClass:'text-center'
        },
        {
          Header: "UK Special Minutes",
          accessor: "ukspecialMinutes",
          sortable: true,
          cssClass:'text-center'
        },
        {
          Header: "International Minutes",
          accessor: "internationalMinutes",
          sortable: true,
          cssClass:'text-center'
        }
      ];*/
      let totalUKGeoMinutes=0
      let totalUKMobileMinutes=0
      let totalUKSpecialMinutes=0
      let totalInternationalMinutes=0
      for(var i=0;i<items.length;i++){
        var item=items[i]
        totalUKGeoMinutes=totalUKGeoMinutes+item.ukgeolandlineMinutes;
        totalUKMobileMinutes=totalUKMobileMinutes+item.ukmobileMinutes;
        totalUKSpecialMinutes=totalUKSpecialMinutes+item.ukspecialMinutes;
        totalInternationalMinutes=totalInternationalMinutes+item.internationalMinutes;
      }
        return (
          <>
          <Row>
            <Col>Total UK Geographic Minutes: {totalUKGeoMinutes}</Col>
            <Col>Total UK Mobile Minutes: {totalUKMobileMinutes}</Col>
            <Col>Total UK Special Minutes: {totalUKSpecialMinutes}</Col>
            <Col>Total International Minutes: {totalInternationalMinutes}</Col>
          </Row>
          <Row>
                <Col>
                    {/*<VoipTable
                    title="Minutes Report"
                    data={items}
                    col={cols}
                    multiSelect={false}
                    showResultsCount={false}
                    sortByStart="name"
                    /> */}
                    <MuiTableWrapper
            title=""
            data={items}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: true,
              search: true,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "datetime",
                direction: "asc",
              },
            }}
          />
                </Col>
            </Row>           
          </>
            
        )
}

export default ResellerInvoiceMinutesReport;
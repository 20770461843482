
import * as React from 'react';

import MySettings, { } from '../../static/settings';

import { IPageProps } from '../../interfaces/page-props';

import FormNumber from '../../components/oldForms/form-number';
import Loading from '../../components/loading';
import { ICustomer, ICustomerAddRequest, ICustomerTariffUpdateRequest, ITariff, ITariffsGetResponse } from '../../interfaces/customer';
import CustomerEditCustomerForm from './CustomerEditCustomerForm';
import CustomerNewCustomerForm from './CustomerNewCustomerForm';
import CustomerEditTariffForm from './CustomerEditTariffForm';
import catchME from '../../catchME';
import Error from '../../components/error';
import { RouteComponentProps } from 'react-router-dom';
import App from '../../App';
import { INotification } from '../../interfaces/notification';
import { NOTIFICATION_TYPE } from '../../enum/notification-type';

const set = new MySettings();

export interface ICustomerState {
  initialized: boolean;
  id: string;
  loading: boolean;
  data: ICustomer;
  mode: string;
  tariff: ITariff;
  tariffs: ITariff[];
  error: boolean;
}








const blankCustomer: ICustomer = { 
  id: '', reseller: '', name: '', notes: '', inactive: false, portalAccess: false, dailyCallSpendLimit: 0, dailyCallHardSpendLimit: 0, ldapName: '', provisioningId: '', invoiceaddressLine1: '', invoiceaddressLine2: '', invoicecity: '', invoicecounty: '', invoiceemailAddress: '', invoicefirstName: '', invoicelastName: '', invoicepostCode: '', invoicesalutation: '', invoicetelephoneNumber: '',
  deliveryAddressLine1: '', deliveryAddressLine2: '', deliverycity: '', deliverycounty: '', deliverypostCode: '', generalemailAddress: '', generalfirstName: '', generallastName: '', generalsalutation: '', generaltelephoneNumber: '', monthlyAlertLimit: 0
};
interface ICustomerPage extends RouteComponentProps<any>, IPageProps { }
export default class CustomerPage extends React.Component<ICustomerPage, ICustomerState> {
  public id: string = '';
  public formNumber: FormNumber = new FormNumber({});
  constructor(props: any) {
    super(props);

    this.get.bind(this);
    this.onSubmitNewCustomer.bind(this);
    this.onSubmitTariffCustomer.bind(this);

    this.state = {
      initialized: false,
      error: false,
      id: '',
      mode: '',
      loading: true,
      data: { ...blankCustomer },
      tariffs: [],
      tariff: {
        id: "",
        description: '',
        billingType: "",
        name: "",
        resellersId: "",
        incomingSalesPriceMobile: 0,
        incomingSalesPrice: 0,
        freeMinutes: 0,
        overrideIncomingDefault: false,
        bands: []
      }
    };
  }



  public render() {
    const { loading, data, tariff, error, tariffs } = this.state;

    if (loading === true) {
      return <Loading />
    } else {

      const SwitchTest = () => {

        switch (this.state.mode) {
          case 'details':
            return <CustomerEditCustomerForm data={data} onSubmit={this.onSubmitNewCustomer} />;
          case 'tariff':
            return <CustomerEditTariffForm customer={data} data={tariff} onSubmit={this.onSubmitTariffCustomer} tariffs={tariffs} />;
          case 'start':
            return <CustomerNewCustomerForm data={null} onSubmit={this.onSubmitNewCustomer} />;
          default:
            return <Error />;
        }

      }


      return (
        <>
          {error === true ? (<Error />) : (<SwitchTest />)}

        </>
      );
    }

  }


  public onSubmitNewCustomer = (cust: ICustomerAddRequest) => {
    //  console.log("onSubmitNewCustomer", cust)
    this.setState({ loading: true });
    set.resellerCustomerAdd(cust).then(resp => {

      this.setState({ loading: false });
      const alert: INotification = { title: "Success", data: "Customer created!", variant: NOTIFICATION_TYPE.success, srcComponent: "Login", srcData: "success", srcFunction: "postLogin" };
      App.newNotification(alert);

      if (this.props.history) {
        this.props.history.push(`/reseller/` + resp.id);
      }
    }).catch(exception => {
      console.log(exception);
      catchME(this, exception, false);
    });

  }
  public onSubmitTariffCustomer = (cust: any) => {
    console.log("onSubmitTariffCustomer", cust)

    let req: ICustomerTariffUpdateRequest = {
      id: cust.CustomerID,
      tariff: cust.tariff,
      tariffType: cust.tariffType,
      numExtraBundles: cust.numExtraBundles,
      bundleNumberOverride: cust.bundleNumberOverride,
      bundleSizeId: cust.bundleSizeId,
      voipServiceType:cust.voipServiceType
    }

    this.setState({ loading: true });

    set.resellerCustomerTariffUpdate(req).then(resp => {

      this.setState({ loading: false });
      if (this.props.history) {
        this.props.history.push(`/reseller/` + resp.id);
      }
      const alert: INotification = { title: "Success", data: "Customer tariff updated!", variant: NOTIFICATION_TYPE.success, srcComponent: "Login", srcData: "success", srcFunction: "postLogin" };
      App.newNotification(alert);
    }).catch(exception => {
      console.log(exception);
      catchME(this, exception, false);
    });
  }

  public componentDidMount = () => {
    //  console.log("params", this.props.match.params)
  //  const requestedID = this.props.match.params.id;
    this.get(this.props.match.params.id, this.props.match.params.form);
    //  console.log(requestedID);
  }





  // get person
  public get = (idA: string, form: string) => {
    this.id = idA;
    let stateup: any = { mode: form, loading: false };
    if (idA === 'new') {
      stateup.data = { ...blankCustomer };
      this.setState(stateup);

    } else {
      set.resellerCustomerDetailsGet(idA).then(res => {
        console.log(res);
        stateup.data = res.customer;
        stateup.tariff = res.tariff;
        set.extensionsGetForCustomer(idA,[],'').then(ress => {
          stateup.data.extensionsForCustomer = ress.items.length;
          set.resellerCustomerTariffs(idA,false).then((data: ITariffsGetResponse) => {
              stateup.tariffs = data.items;
              this.setState(stateup);
            }).catch(exception => {
              console.log(exception);
              catchME(this, exception, false);
            });
        }).catch(exception => {
          console.log(exception);
          catchME(this, exception, false);
        });


      }).catch(exception => {
        console.log(exception);
        catchME(this, exception, false);
      });

    }


  }
}

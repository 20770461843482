import React, { useEffect, useState } from "react";
import { IPageProps } from "../../interfaces/page-props";
import MySettings, {
  IVoipServiceCallRecordingsUpdateRequest
} from "../../static/settings";

import { Button, ButtonGroup, Card, Col} from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { IExtension } from "../../interfaces/extension";
import { CallRecordingsUnits, IVoipService } from "../../interfaces/interfaces";
import { INotification } from "../../interfaces/notification";
import AddCreditForm from "./AddCreditForm";
import ApiKeys from "./ApiKeys";
import CallRecordingForm from "./CallRecordingForm";
import UpdateLimitsForm from "./UpdateLimitsForm";

import App from "../../App";
import Error from "../../components/error";
import VipBurgerMenu, { IBurgerOption } from "../../components/VipBurgerMenu";
import {
  IPrepayTransaction,
  IPrepayTransactionAddRequest
} from "../../interfaces/prepay";
import ResellerNotes from "../ResellerNotes";
import PrepayTransactions from "./PrepayTransactions";
import VoIPServiceSettingsForm from "./VoIPServiceSettingsForm";
import Cab9Wallboard from "./Cab9Wallboard";

interface PageModeProps {
  mode:PAGE_MODE
}

const set = new MySettings();
export interface IProvisionState {
  data: IVoipService;
  transactions: IPrepayTransaction[];
  displayBase: boolean;
  displayCallRecordingForm: boolean;
  displayAPIKeys: boolean;
  displayUpdateLimits: boolean;
  displayTramsactons: boolean;
  displayAddCredit: boolean;
  title: string;
  extensions: IExtension[];
  apiKeys: string[];
  pageMode: PAGE_MODE;
}

const blankServ: IVoipService = {
  prepay: false,
  prepayCutOffLimit: 0,
  lowBalanceAlertThreshold: 0,
  status: "",
  eurosoftCallRecordingFTPServerUseFTPS: false,
  callRecordingWebHookAddress: "",
  callRecordingWebHookToken: "",
  vaultGrowsIndefinitely: false,
  vaultSize: 0,
  eurosoftCallRecordingFTPServerPort: 0,
  eurosoftCallRecordingFTPServerUsername: "",
  eurosoftCallRecordingFTPServerPassword: "",
  eurosoftCallRecordingFTPServerPath: "",
  defaultSearchInDays: 0,
  measureSizeBy: CallRecordingsUnits.Gigabytes,
  excludedExtensions: [],
  eurosoftCallRecordingFTPServerAddress: "",
  name: "",
  id: "",
  siteName: "",
  useCallerIdAsLabel: false,
  provisioningSettings: {
    customFields: [], extraAccounts: [],
    yealinkFirmwares: []
  },
  hostname: "",
  vipHostname: "",
  sipPort: "",
  iaxPort: "",
  tlsPort: "",
  logins: [],
  matchCraftEnabled: false,
  matchCraftLogs: [],
  callRecordingsAPILog: []
};

enum PAGE_MODE {
  summary = "summary",
  callrec = "callrec",
  apiKeys = "apiKeys",
  limits = "limits",
  addCredit = "addCredit",
  transactions = "transactions",
  voipservicesettings = "voipservicesettings",
  cab9wallboard = "cab9wallboard"
}

interface IServicePage extends RouteComponentProps<any>, IPageProps { }

const ServicePage: React.FC<IServicePage> = ({
  resellerID,
  match,
  history,
}) => {
  const [serviceData, setServiceData] = useState<IProvisionState>({
    title: "",
    extensions: [],
    transactions: [],
    apiKeys: [],
    displayBase: true,
    displayCallRecordingForm: false,
    displayAPIKeys: false,
    displayUpdateLimits: false,
    displayAddCredit: false,
    displayTramsactons: false,
    data: blankServ,
    pageMode: match.params.pageMode ? match.params.pageMode : PAGE_MODE.summary,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // console.log("STATE", serviceData);
  // console.log("PROPS", resellerID);

  useEffect(() => {
    let isMounted = true;

    const getService = async () => {
      try {
        const extensions = await set.extensionsGet(match.params.id, ['EX','HD','US']);

        if (match.params.id !== "new") {
          const voipServices: IVoipService = await set.voipServiceGet(
            match.params.id
          );

          // console.log("voipservices", voipServices);

          const stateUpdate: any = {};

          if (voipServices) {
            stateUpdate.data = voipServices;
          }
          stateUpdate.extensions = extensions.items;

          switch (serviceData.pageMode) {
            case PAGE_MODE.cab9wallboard:
              stateUpdate.title = "Integrations";
              break;
            case PAGE_MODE.summary:
              stateUpdate.title = "Summary";
              break;
            case PAGE_MODE.addCredit:
              stateUpdate.title = "Add Credit";
              break;
            case PAGE_MODE.callrec:
              stateUpdate.title = "Call Recording";
              break;
            case PAGE_MODE.voipservicesettings:
              stateUpdate.title = "VoIP Service Settings";
              break;
            case PAGE_MODE.limits:
              stateUpdate.title = "Prepay Limits";
              break;
            case PAGE_MODE.transactions:
              stateUpdate.title = "Transactions";
              set.getPrepayTransactions(match.params.id).then((data) => {
                stateUpdate.transactions = data.transactions;
              });
              break;
            case PAGE_MODE.apiKeys:
              stateUpdate.title = "API Keys";
              set.voipServiceAPIKeysGet(match.params.id).then((respA) => {
                stateUpdate.apiKeys = respA.keys;
                if (isMounted) {
                  setServiceData((prevState) => {
                    return {
                      ...prevState,
                      ...stateUpdate,
                    };
                  });
                  setLoading(false);
                }
              });
              break;
          }
          if (isMounted) {
            setServiceData((prevState) => {
              return {
                ...prevState,
                ...stateUpdate,
              };
            });
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };

    getService();

    return () => {
      isMounted = false;
    };
  }, [match.params.id, serviceData.pageMode]);

  const saveService = async (
    service: IVoipServiceCallRecordingsUpdateRequest
  ) => {
    try {
      setLoading(true);
      window.scrollTo(0, 0);
      let voipServiceCallRecordingsUpdate: IVoipService = await set.voipServiceCallRecordingsUpdate(service);
      setServiceData({
        ...serviceData,
        data: voipServiceCallRecordingsUpdate,
        displayCallRecordingForm: false,
      });
      setLoading(false);

      const notification: INotification = {
        title: "Success",
        data:"Settings updated.",
        variant: NOTIFICATION_TYPE.info,
        srcFunction: "componentDidUpdate",
        srcData: window.location.pathname,
        srcComponent: "App",
      };
      App.newNotification(notification);
      goBack();
    } catch (error) {
      console.log(error);
    }
  };

  const togglePrepay = async () => {
    try {
      setLoading(true);
      const result = await set.voipServicePrepayToggle(match.params.id);

      if (result.prepay) {
        const notification: INotification = {
          title: "Success",
          data: "Prepay enabled",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      } else {
        const notification: INotification = {
          title: "Success",
          data: "Prepay disabled",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      }

      const updatedService = {
        ...serviceData.data,
        prepay: result.prepay,
        prepayCutOffLimit: result.prepayCutOffLimit,
        lowBalanceAlertThreshold: result.lowBalanceAlertThreshold,
      };

      setServiceData({ ...serviceData, data: updatedService });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const submitPrepay = (data: any) => {
    const payload: IPrepayTransactionAddRequest = {
      amount: data.amount,
      customersSitesServicesId: match.params.id,
      description: data.description,
    };

    set.prepayTransactionsAdd(payload);
  };

  const goBack = () => {
    history.push("./summary");
  };

  let burgerOpts: IBurgerOption[] = [];

  burgerOpts.push({
    title: serviceData.data.prepay ? "Disable Prepay" : "Enable Prepay",
    onClick: () => {
      togglePrepay();
    },
    isExternal: false,
  });

  burgerOpts.push({
    title: "",
    link: "",
    isExternal: false,
    isDivider: true,
  });

  burgerOpts.push({
    title: "Provisioning Settings",
    link:
      "/reseller/" +
      resellerID +
      "/voipservice/" +
      serviceData.data.id +
      "/provisioning",
    isExternal: false,
  });

  burgerOpts.push({
    title: "Call Recording Settings",
    link:
      "/reseller/" +
      resellerID +
      "/service/" +
      serviceData.data.id +
      "/" +
      PAGE_MODE.callrec,
    isExternal: false,
  });

  burgerOpts.push({
    title: "VoIP Service Settings",
    link:
      "/reseller/" +
      resellerID +
      "/service/" +
      serviceData.data.id +
      "/" +
      PAGE_MODE.voipservicesettings,
    isExternal: false,
  });

  burgerOpts.push({
    title: "Email Reports",
    link:
      "/reseller/" + resellerID + "/" + serviceData.data.id + "/emailreports",
    isExternal: false,
  });

  /*
  PBB 2024-04-17 removed since no one uses this and we have no idea if it works
  burgerOpts.push({
    title: "Voice Routings",
    link:
      "/reseller/" + resellerID + "/" + serviceData.data.id + "/voiceroutings",
    isExternal: false,
  });
  */

  burgerOpts.push({
    title: "API Keys",
    link:
      "/reseller/" +
      resellerID +
      "/service/" +
      serviceData.data.id +
      "/" +
      PAGE_MODE.apiKeys,
    isExternal: false,
  });

  burgerOpts.push({
    title: "Update Block List",
    link: "/reseller/" + resellerID + "/allowdialcodes",
    /* onClick:function(){
      const notification: INotification = { title: "Block List Update", data: 'Updating...', variant: NOTIFICATION_TYPE.info, srcFunction: "componentDidUpdate", srcData: window.location.pathname, srcComponent: 'App' };
      App.newNotification(notification)
      set.resellerUpdateCustomerBlockList(serviceData.data.id)
        .then(function(response){
          if(response.success){
            const notification: INotification = { title: "Block List Update", data: 'Updated', variant: NOTIFICATION_TYPE.success, srcFunction: "componentDidUpdate", srcData: window.location.pathname, srcComponent: 'App' };
            App.newNotification(notification)
          }else{
            const notification: INotification = { title: "Block List Update", data: 'Update failed.', variant: NOTIFICATION_TYPE.warning, srcFunction: "componentDidUpdate", srcData: window.location.pathname, srcComponent: 'App' };
            App.newNotification(notification)
          }
        })
        .catch(function(response){
          const notification: INotification = { title: "Block List Update", data: response, variant: NOTIFICATION_TYPE.danger, srcFunction: "componentDidUpdate", srcData: window.location.pathname, srcComponent: 'App' };
          App.newNotification(notification)
        })
    },*/
    isExternal: false,
  });

  burgerOpts.push({
    title:"Integrations",
    link:'/reseller/'+resellerID+'/service/'+serviceData.data.id+'/'+PAGE_MODE.cab9wallboard,
    isExternal:false
  })


  if (serviceData.data.prepay) {
    burgerOpts.push({
      title: "Prepay Transactions",
      link:
        "/service/" +
        serviceData.data.id +
        "/" +
        PAGE_MODE.transactions +
        "?resellerID=" +
        resellerID,
      isExternal: false,
    });

    burgerOpts.push({
      title: "Prepay Top-Up",
      link:
        "/service/" +
        serviceData.data.id +
        "/" +
        PAGE_MODE.addCredit +
        "?resellerID=" +
        resellerID,
      isExternal: false,
    });

    burgerOpts.push({
      title: "Prepay Limits",
      link:
        "/service/" +
        serviceData.data.id +
        "/" +
        PAGE_MODE.limits +
        "?resellerID=" +
        resellerID,
      isExternal: false,
    });
  }

  const onClickPauseServer = async () => {
    //alert('This feature has been temporarily disabled while we investigate a fault.')
    const pauseServer=window.confirm("Continuing will disable all extensions on this server for a minimum of 7 days.\nWhile the server is paused you can configure the server ready for the customer going live.\nAre you sure you want to pause this server?")
    if(pauseServer){
      setIsLoading(true);
      try{
        let updatedService = await set.resellerPauseServer(serviceData.data.id)
        setServiceData({...serviceData,data:updatedService})
        App.newNotificationSuccess('VoIP service has been paused.')
      }catch(err:any){
        let message='Something went wrong.'
        if(err && err.data && typeof err.data==='string'){
          message=err.data
        }else{
          message=message+' This is most likely a permissions issue.'
        }
        App.newNotificationError(message)
      }
      setIsLoading(false);
    }
  }

  const onClickUnpauseServer = async () => {
    const pauseServer=window.confirm("Continuing will enable all extensions on this server.\nAre you sure you want to unpause this server?")
    if(pauseServer){
      setIsLoading(true);
      try{
        let updatedService = await set.resellerPauseServer(serviceData.data.id)
        setServiceData({...serviceData,data:updatedService})
        App.newNotificationSuccess('VoIP service has been unpaused.')
      }catch(err:any){
        let message='Something went wrong.'
        if(err && err.data && typeof err.data==='string'){
          message=err.data
        }else{
          message=message+' This is most likely a permissions issue.'
        }
        App.newNotificationError(message)
      }
      setIsLoading(false);
    }
  }

  const config = async () => {
    setIsLoading(true);
    try{
      let data = await set.drogonLogin(serviceData.data.id, "admin2")
      if (data.url) {
        const notification: INotification = {
          title: "Success",
          data: "Request Successful.",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
        window.open(data.url, "_blank");
      } else {
        App.newNotificationError('Request failed.')
      }
    }catch(err:any){
      let message='Something went wrong.'
      if(err && err.data && typeof err.data==='string'){
        message=message+' '+err.data
      }else{
        message=message+' This is most likely a permissions issue.'
      }
      App.newNotificationError(message)
    }
    setIsLoading(false);
  };

  const PageMode = (props:PageModeProps) => {
    switch (props.mode) {
      case PAGE_MODE.summary:
        return (
          <Card>
            {isLoading ? (<><Loading /></>
            ) : (
              <Card.Body>
                <div className="d-flex flex-rowd-flex justify-content-between">
                  <div>
                    Server: {serviceData.data.hostname}
                    <br />
                    Status: {serviceData.data.status}
                  </div>
                  <div>
                    <div>
                      <Button onClick={config} variant="outline-primary">
                        Configure Server
                      </Button>
                    </div>
                  </div>
                </div>
                <br />
                {serviceData.data.canUnpauseDate ? (
                  <div className="d-flex flex-rowd-flex justify-content-between">
                    <div>
                      Paused: {serviceData.data.pausedDateTime?.replace('T', ' ')} by {serviceData.data.pausedBy} <br />
                      Can unpause: {serviceData.data.canUnpauseDate}<br />
                    </div>
                    <div>
                      <Button onClick={onClickUnpauseServer} variant="outline-primary">
                        Unpause Server
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-rowd-flex justify-content-between">
                    {serviceData.data.unpausedDateTime ? (
                      <div>
                        Unpaused: {serviceData.data.unpausedDateTime.replace('T',' ')} by {serviceData.data.unpausedBy}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div>
                      <Button onClick={onClickPauseServer} variant="outline-primary">
                        Pause Server
                      </Button>
                    </div>
                  </div>
                )}
                <br />
                <div className="d-flex flex-rowd-flex justify-content-between">
                  <div>VIP Domain: </div>
                  <div>{serviceData.data.vipHostname}</div>
                </div>
                <div className="d-flex flex-rowd-flex justify-content-between">
                  <div>SIP Port: </div>
                  <div>{serviceData.data.sipPort}</div>
                </div>
                <div className="d-flex flex-rowd-flex justify-content-between">
                  <div>IAX Port: </div>
                  <div>{serviceData.data.iaxPort}</div>
                </div>
                <div className="d-flex flex-rowd-flex justify-content-between">
                  <div>TLS Port: </div>
                  <div>{serviceData.data.tlsPort}</div>
                </div>
                <br />
                <div className="d-flex flex-rowd-flex justify-content-between">
                  <div>Call charges so far this month (excluding today): </div>
                  <div>{callChargesThisMonthString}</div>
                </div>
                {serviceData.data.matchCraftEnabled && (<>
                  <br />
                  <div className="sidebarServiceName">MatchCraft Logs</div>
                  {(serviceData.data.matchCraftLogs.length>0 ? (
                    <>
                    {serviceData.data.matchCraftLogs.map((line,index)=>{
                      return <div key={index}>{line}</div>
                    })}
                    </>
                  ) : (
                    <div>No MatchCraft logs for this server.</div>
                  ))}
                </>)}
                <br />
                <div className="sidebarServiceName">Logins</div>
                {(serviceData.data.logins && serviceData.data.logins.length>0) ? (<>{serviceData.data.logins.map((login,index)=>{
                  return <div key={index}>{login.datetime.replace('T',' ')} {login.email} {login.ip}</div>
                })}</>) : (<div>No logins for this server.</div>)}
                
              </Card.Body>
            )}
          </Card>
        );

      case PAGE_MODE.apiKeys:
        return (
          <Col md={{ span: 12, offset: 0 }}>
            <ApiKeys data={serviceData.apiKeys} />
          </Col>
        );

      case PAGE_MODE.transactions:
        return (
          <Col md={{ span: 12, offset: 0 }}>
            <PrepayTransactions data={serviceData.transactions} />
          </Col>
        );

      case PAGE_MODE.addCredit:
        return (
          <Col md={{ span: 12, offset: 0 }}>
            <AddCreditForm
              data={serviceData.data}
              onSubmit={(data: any) => {
                submitPrepay(data);
              }}
            />
          </Col>
        );
      case PAGE_MODE.cab9wallboard:
        return (<Cab9Wallboard serviceId={serviceData.data.id} />)
      case PAGE_MODE.callrec:
        return (
          <Col md={{ span: 12, offset: 0 }}>
            <CallRecordingForm
              data={serviceData.data}
              extensions={serviceData.extensions}
              onSubmit={(data: any) => {
                saveService(data);
              }}
              goBack={goBack}
            />
          </Col>
        );
      case PAGE_MODE.voipservicesettings:
        return (
          <Col md={{ span: 12, offset: 0 }}>
            <VoIPServiceSettingsForm
              data={serviceData.data}
              onSubmit={(data: any) => {
                saveService(data);
              }}
              goBack={goBack}
            />
          </Col>
        );
      case PAGE_MODE.limits:
        return (
          <Col md={{ span: 12, offset: 0 }}>
            <UpdateLimitsForm
              data={serviceData.data}
              onSubmit={(data: any) => {
                console.log(data);
                //saveDevice(data);
              }}
            />
          </Col>
        );
      default:
        return <>Unknown pagemode</>;
    }
  };

  let callChargesThisMonthString = ''
  if(serviceData.data.retailCurrentMonthCallChargesDate != null && serviceData.data.retailCurrentMonthCallChargesPounds!=null){
    callChargesThisMonthString='£'+serviceData.data.retailCurrentMonthCallChargesPounds.toFixed(2)
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="content">
        <PageHead
          resellerID={resellerID}
          title={serviceData.title}
          parents={[
            {
              title: serviceData.data.id ? serviceData.data.name : "",
              url:
                "/voip/portal/reseller/" +
                resellerID +
                "/service/" +
                serviceData.data.id +
                "/" +
                PAGE_MODE.summary,
            },
          ]}
        >
          <>
            {!error && (
              <VipBurgerMenu options={burgerOpts} />
            )}
          </>
        </PageHead>

        {error ? (
          <Error />
        ) : (
          <>
            {" "}
            <PageMode mode={serviceData.pageMode} />
            {resellerID && <ResellerNotes resellerID={resellerID} />}
          </>
        )}
      </div>
    </>
  );
};

export default ServicePage;

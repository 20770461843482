
import * as React from 'react';
import { Button, Dropdown, Table } from 'react-bootstrap';

import { Form, Field } from 'react-final-form';



import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import FormGroupAdapter from '../../components/FormGroupAdaptor';
import Papa from 'papaparse';

export interface IRequestUserUploadPopupProps {
    data: any;
    onSubmit: any;

}

export interface IRequestUserUploadPopupState {
    data: any;
    visible: boolean;
}

// ({ data, onSubmit }: any)
export default class UserUploadPopup extends React.Component<IRequestUserUploadPopupProps, IRequestUserUploadPopupState> {
    public state: any = {
        data: { users: [] },
        visible: false,
    };

    public setVisible = (bool: boolean, data: any) => {
        this.setState({ visible: bool, data: data });
    }

    constructor(props: any) {
        super(props);
        console.log("notusless", props);
    }

    public submit = (data: any) => {
        this.setState({ visible: false });
        this.props.onSubmit('uploadedUsers', data)
    }

    public render() {
        console.log(this.state.data)
        if (this.state.visible === false) {
            return <></>
        } else {
            return <div className="overlay">

                <div className="overlay-content">

                    <Form
                        onSubmit={this.submit}
                        initialValues={{ ...this.props.data, users: [] }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (

                            <form onSubmit={handleSubmit}>


                                <div className="popupContent text-left test-new-customer-start WHITEN">

                                    <fieldset>


                                        <legend>CSV Template</legend>
                                        <a href="/voip/src/templates/NumberPortTemplate.docx" download>
                                            <Button variant="outline-primary" className="mb-2">
                                                Download Number Port Form< FaIcon
                                                    icon="file-download"
                                                    color="primary"
                                                    className="ml-2"
                                                />
                                            </Button>
                                        </a>
                                        <div className="form-group">
                                            <p>Upload :</p>
                                            <Field
                                                name="newFile"
                                                component={FormGroupAdapter}
                                                inputOnChange={(sdata: any) => {
                                                    //  console.log("on change", sdata);

                                                    const reader = new FileReader();
                                                    const file = sdata.target.files[0];

                                                    reader.readAsDataURL(file);
                                                    reader.addEventListener("load", () => {
                                                        console.log("onload", reader.result);
                                                        if (typeof reader.result === "string") {

                                                            Papa.parse(file, {
                                                                header: true,
                                                                complete: (results) => {
                                                                    console.log("Finished:", results.data);
                                                                    let array: any[] = [];
                                                                    for (let index = 0; index < results.data.length; index++) {
                                                                        let element: any = results.data[index];

                                                                        element.siteIndex = 0;
                                                                        element.permissionIndex = 0;

                                                                        if(element.portalAccess === 'yes'){
                                                                            element.portalAccess = true;
                                                                        } else {
                                                                            element.portalAccess = false;
                                                                        }


                                                                        if(element.firstName && element.lastName){
                                                                            array.push(element)
                                                                        }
                                                                        
                                                                    }
                                                                    form.change('users', array)
                                                                },
                                                            });
                                                        }
                                                    });
                                                }}
                                                placeholder="Upload file"
                                            />
                                        </div>
                                        {JSON.stringify(values)}
                                        <Table responsive="sm">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Site</th>
                                                    <th>Phone Number</th>
                                                    <th>User Type</th>
                                                    <th>Portal Access</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {values.users !== undefined && <>{values.users.map((data: any, index: number) => {


                                                    //   console.log(values.sites[data.siteIndex])
                                                    return <tr key={index}>
                                                        <td className="userName">{data.salutation} {data.firstName} {data.lastName}</td>
                                                        <td className="userSite">{data.siteIndex === 'notSite' ? (<>Not at Site</>) : (<></>)}{this.state.data.sites[data.siteIndex].description}</td>
                                                        <td className="userPhone">{data.telephone}</td>
                                                        <td className="userType">{this.state.data.permissions[data.permissionIndex].name}</td>
                                                        <td className="userPortalAccess">{data.portalAccess}</td>
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="link" className="button__options" id="dropdown-basic">

                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item as='button' onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        console.log(data);
                                                                        let update: any[] = [...values.users];
                                                                        update.splice(index, 1);
                                                                        form.change('users', update)
                                                                    }}>Remove</Dropdown.Item>

                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>

                                                    </tr>
                                                })}</>}

                                            </tbody>
                                        </Table>

                                    </fieldset>

                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={submitting || pristine}>Add Users</button>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => this.setState({ visible: false })}
                                    >Cancel</button>



                                </div>
                            </form>
                        )} />

                </div></div>

        }

    }
}




import React, { useState, useEffect } from "react";
import { INotification } from "../interfaces/notification";
import { NOTIFICATION_TYPE } from "../enum/notification-type";

import Loading from "./loading";
import Button from "react-bootstrap/Button";
import MySettings from "../static/settings";
import App from "../App";
import ScrollToTop from "./misc/ScrollToTop";

const set = new MySettings();

const PasswordResetButton = (props : any) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const callMfaReset = async () => {
      if (isLoading) {
        try {
          await set.resellerCustomerContactResetPassword(
            props.id
          );
          const notification: INotification = {
            title: "Success",
            data: "Password Reset Success! The user should receive an email allowing them to set another password.",
            variant: NOTIFICATION_TYPE.success,
            srcComponent: "PersonForm",
            srcData: "success",
            srcFunction: "resellerContactResetMFAForCustomerContact",
          };

          App.newNotification(notification);
        } catch (error) {
          const notification: INotification = {
            title: "Failed",
            data: "Password Reset Went Wrong!",
            variant: NOTIFICATION_TYPE.warning,
            srcComponent: "PersonForm",
            srcData: "failed",
            srcFunction: "resellerContactResetMFAForCustomerContact",
          };

          App.newNotification(notification);
        }
      }
      setIsLoading(false);
    };
    callMfaReset();
  }, [isLoading, props]);

  const handleClick = () => {
    if (!isLoading) setIsLoading(true);
    else return null;
  };

  return isLoading ? (<div><ScrollToTop></ScrollToTop><Loading /></div>) : (<Button
      variant="primary"
      type="reset"
      onClick={handleClick}
      className="mt-3"
    >
      Send Password Reset
    </Button>)
};

export default PasswordResetButton;

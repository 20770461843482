import { Box, Theme, Typography, makeStyles } from "@material-ui/core";

export const useTabStyles = makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      width: "auto",
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  }));
  
  
  
  export interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
  export const a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <Box
      component={'div'} 
        style={{ width: "100%"}}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box component={'div'}  style={{ paddingLeft: 15, width:"auto" }}>
            <Typography component={'div'} >{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }


  export default TabPanel;
import React, { useEffect, useState } from "react";
import { IBranding } from "../interfaces/branding";
import MySettings from "../static/settings";

const BrandedLogo = () => {
  const [branding, setBranding] = useState<IBranding>({
    brandPortalDomain: "",
    emailAddress: "default",
    logo: "",
    name: "default",
    navigationBarLogo: "",
    privacyPolicyURL: "./privacy",
    telephoneNumber: "",
  });
  useEffect(() => {
    //this is here because the branding info doesn't seem to come through from the useMain context correctly
    async function getBranding() {
      try {
        const set = new MySettings();
        const newBranding = await set.brandingGet(window.location.hostname);
        setBranding(newBranding);
      } catch (err) {}
    }
    getBranding();
  }, []);

  return (
    <>
      {window.location.hostname === "portal.vipvoip.co.uk" ||
      window.location.hostname === "localhost" ||
      window.location.hostname === "apitesting.vipvoip.co.uk" ? (
        <>
          <img
            src="../../../voip/portal/svg/logo-blue.svg"
            alt="Logo"
            height="56"
          />
          <img
            src="../../../voip/portal/svg/VIP-VOIP.svg"
            alt="Logo"
            height="40"
            className="mt-2 ml-1"
          />
        </>
      ) : (
        <>
          <img
            src={"/voip/uploadFile?file=" + branding.logo}
            alt={branding.name}
          />
        </>
      )}
    </>
  );
};

export default BrandedLogo;

import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import { Card, CardContent, Grid, Button, Typography } from "@material-ui/core";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import Error from "../../components/error";
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";
import { closeModal, openModal } from "../../components/MuiComponents/Popup";
import MuiDropdownButton, {
  IDropdownButtonProps,
} from "../../components/MuiComponents/MuiDropdownButton";

import "../../sass/numbers.scss";
import EditIcon from '@material-ui/icons/Edit';
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import {
  INumber,
  INumbersHttpResponse,
} from "../../interfaces/number";
import { IconButton } from "@material-ui/core";
import MuiModal from "../../components/modal/MuiModal";
import NumbersBatchUpdateModal from "../../components/modal/NumbersBatchUpdateModal";

enum PageState {
  ShowTable,
  Loading,
  NetworkError,
}

interface INumbersPage extends RouteComponentProps<any>, IPageProps {}

const set = new MySettings();
let myRowsSelected:number[] = []
const Numbers: React.FC<INumbersPage> = (props) => {
  const [data, setData] = useState<INumber[]>([]);
  const [pageState, setPageState] = useState(PageState.Loading);
  //const [myRowsSelected,setRowsSelected] = useState<number[]>([])
  const [batchUpdateModalOpen,setBatchUpdateModalOpen] = useState(false)
  const getData = async () => {
    setPageState(PageState.Loading)
    try {
      const result: INumbersHttpResponse = await set.numberSearch("", "",props.resellerID);

      setData(result.numbers);

      setPageState(PageState.ShowTable);
    } catch (error) {
      setPageState(PageState.NetworkError);
    }
  }
  useEffect(() => {
    getData();
    //PBB 2024-02-23 do not put getData in the depency array or the page goes in to an infinite loop
  }, [props.resellerID]);

  const deactivateNumbers = async () => {
    try {
      setPageState(PageState.Loading);
      closeModal();

      await set.numbersDeactivate(props.customer.customer.id);

      const result: INumbersHttpResponse = await set.numberSearch("", "");

      setData(result.numbers);
      setPageState(PageState.ShowTable);
    } catch (error) {
      // console.log(error);
      setPageState(PageState.NetworkError);
    }
  }

  const onBatchUpdateClick = () => {
    if(myRowsSelected.length===0){
      alert('You must select at least one number.')
    }else{
      setBatchUpdateModalOpen(true)
    }
  }


  const col: any[] = [
    {
      name: "name",
      label: "Number",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  col.push({
    name:'providerName',
    label:'Provider'
  })
  
  if (
    props.resellerID == null
  ) {
    //customer
    col.push(
      // {
      //   name: "customersName",
      //   label: "Customers Name",
      //   options: {
      //     filter: true,
      //     sort: true,
      //   },
      // },
      {
        name: "ownerName",
        label: "Customer",
        options: {
          filter: true,
          sort: true,
        },
      },{
        name:'notes',
        label:'Notes',
        options:{filter:true,sort:true}
      },
      {
        name: "forwarding",
        label: "Forwarding",
        options: {
          filter: true,
          sort: true,
        },
      }
    );
    
    col.push({
      name: "faxToEmail",
      label: "Fax To Email",
      options: {
        filter: true,
        sort: true,
      },
    });

    col.push({
      name: "deactivated",
      label: "Active",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => {
          const element = data[dataIndex];



          return element.deactivated === null || element.deactivated === "" ? (
            <span className="activeTable">Active</span>
          ) : (
            <span className="inactiveTable">Inactive</span>
          );
        },
      },
    });
  } else {
    //reseller with selected customer
    col.push({
      name:'notes',
      label:'Notes',
      options:{filter:true,sort:true}
    })
    col.push({
      name: "forwarding",
      label: "Forwarding",
      options: {
        filter: true,
        sort: true,
      },
    });
    col.push({
      name: "faxToEmail",
      label: "Fax To Email",
      options: {
        filter: true,
        sort: true,
      },
    });

    col.push({
      name: "deactivated",
      label: "Active",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => {
          const element = data[dataIndex];
          return element.deactivated === null || element.deactivated === ""
            ? "Active"
            : "Deactivated";
        },
      },
    });
  }

  col.push({
    name: "",

    options: {
      filter: false,
      sort: false,
      display: true,
      download: false,
      
      setCellProps: () => ({ align:"right" }),
      customBodyRenderLite: (dataIndex: number) => {
        const element = data[dataIndex];
        const link: string = "./number/" + element.id + "/0";

        return <IconButton href={link}><EditIcon /></IconButton>

      },
    },
  });

  const DeactivateNumbersModal = () => {
    return (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Deactivate All Numbers
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: "16px" }}>
              <Typography variant="subtitle2" align="center">
                Are you sure you want to deactivate all numbers for this
                customer?
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={deactivateNumbers}
                fullWidth
              >
                Delete
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button color="secondary" fullWidth onClick={closeModal}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const dropdownOptions: IDropdownButtonProps["options"] = [
    { label: "Allocate Numbers", link: "./allocateNumbers", target:'' },
    { label: "Number Trackers", link: "./numberTrackers", target:'' }
  ];

  /*if(props.session.userType==='resellersContacts'){
    dropdownOptions.push()
  }*/
  
  if (
    props.session.userType === "resellersContacts" &&
    props.resellerID !== undefined
  ) {
    dropdownOptions.push(
      {
        label: "Deactivate All Numbers",target:'',
        onClick: () => openModal({ content: <DeactivateNumbersModal /> }),
      },
      //{ label: "Allocate Numbers", link: "./allocateNumbers" }
    );
  }

  const RenderSwitch = () => {
    switch (pageState) {
      case PageState.ShowTable:
        return (
          <MuiTableWrapper
            title=""
            data={data}
            columns={col}
            options={{
              filterType: "checkbox",
              selectableRows: props.resellerID ? "multiple" : "none",
              responsive: "standard",
              selectToolbarPlacement: "none",
              onRowSelectionChange(currentRowsSelected, allRowsSelected, rowsSelected) {
                  //setRowsSelected(rowsSelected ? rowsSelected : [])
                  //PBB 2024-02-23 we can't use anything that affects the state of the page or the entire
                  //table rerenders and breaks all the sorting and selection
                  //we use a global variable as a weird hack
                  myRowsSelected = rowsSelected ? rowsSelected : [];
              },
              //rowsSelected:myRowsSelected, //we need this or the selected rows clear after each page rendering
              elevation: 0,
              print: false,
              download: false,
              search: true,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "name",
                direction: "asc",
              },
            }}
          />
        );

      case PageState.Loading:
        return <Loading />;

      case PageState.NetworkError:
        return <Error />;
      default:
        return <></>;
    }
  };

  const selectedNumbers:INumber[]=[]
  myRowsSelected.forEach((index)=>{
    selectedNumbers.push(data[index])
  })
  return (
    <Grid container spacing={3} alignContent="flex-start">
      <Grid item xs={12}>
        <PageHead
          resellerID={props.resellerID}
          title="Numbers"
          parents={[]}
          subpages={[]}
        >
          <MuiDropdownButton label="More Options" options={dropdownOptions} />
        </PageHead>
      </Grid>

      {props.resellerID && (<Grid item xs={12}>
        <Button variant="outlined" type="button" onClick={onBatchUpdateClick}>
          Batch Update Selected Numbers
        </Button>
        <MuiModal open={batchUpdateModalOpen} title="Batch Update" onClose={() => {  setBatchUpdateModalOpen(false) }}>
            <NumbersBatchUpdateModal customersId={props.resellerID ? props.resellerID : ''} selectedNumbers={selectedNumbers} setModalOpen={(open)=>{
              getData()
              setBatchUpdateModalOpen(open)
              }
            } />
          </MuiModal>
      </Grid>)}

      <Grid item xs={12}>
        <RenderSwitch />
      </Grid>
    </Grid>
  );
};

export default Numbers;


import MySettings from "../../static/settings";
import "react-table/react-table.css";
import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import { RouteComponentProps } from "react-router-dom";
import PageHead from "../../components/PageHead";
import {
  IProvisioningRecord,
  IProvisioningGetHttpResponse,
  IProvisioningGetHttpRequest,
} from "../../interfaces/provisioning";
import Error from "../../components/error";
import { RESELLER_ROUTE_PROVISIONING_VIEW_URLS } from "../ResellerHome";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { useEffect, useRef, useState } from "react";
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";
import MuiDropdownButton, { IDropdownButtonProps } from "../../components/MuiComponents/MuiDropdownButton";
import { Grid, IconButton, ButtonGroup } from "@material-ui/core";
import App from "../../App";
import React from "react";
import ImportDevicesForm from "./ImportDevicesForm";


enum PageState {
  ShowTable,
  Loading,
  NetworkError,
  NoResults,
  ImportingDevices
}
export interface IDevicesState {
  // newPerson: IPerson;
  // error: boolean;
  // loading: boolean;
  devices: IProvisioningRecord[];
  search: {};
}

const set = new MySettings();

interface IDevicesPage extends RouteComponentProps<any>, IPageProps { }

const Devices: React.FC<IDevicesPage> = (props) => {
  const [pageState, setPageState] = useState(PageState.Loading);
  const [data, setData] = useState<IDevicesState>({
    devices: [],
    search: {},
  });


  const isMounted = useRef(true);

  const getServices = async (request: IProvisioningGetHttpRequest) => {

    if (props.session.userType === "resellersContacts") {
      if (props.resellerID !== undefined) {
        request.customersId = props.resellerID;
      }
    }

    try{
      const result: IProvisioningGetHttpResponse = await set.provisioningGet(
        request
      );
      setData({ ...data, devices: result.items });
      setPageState(PageState.ShowTable);
    }catch(err){
      let message='There was a problem loading the devices.'
      if(err && err.data){
        message=message+' '+err.data
      }
      App.newNotificationError(message)
    }
  }

  useEffect(() => {
    if (isMounted.current && !data.devices.length) {
      try {
        getServices({});
      } catch (error) {
        setPageState(PageState.NetworkError);
      }
    }

    return () => {
      isMounted.current = false;
    };
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, pageState, props.resellerID, props.session.userType]);

  const onImportDevicesClick = () => {
    setPageState(PageState.ImportingDevices)
  }


  const columns = [
    {
      name: "uuid",
      label: "UUID",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name:'userAgent',
      label:'Device',
      options:{
        filter:true,
        sort:true,
        customBodyRenderLite:(dataIndex:number)=>{
          let userAgent=''
          const element = data.devices[dataIndex]
          if(element.userAgent){
            userAgent=element.userAgent.replace(element.uuid,'')
            userAgent=userAgent.replace('.build.by.SVN','').trim()
          }
          return userAgent
        }
      }
    },
    {
      name: "extensionCallerid",
      label: "Extension",
      options: {
        filter: true,
        sort: true,
      },
    },



    {
      name: "uuid",
      label: " ",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ align: "right" }),
        customBodyRenderLite: (dataIndex: number) => {
          const element = data.devices[dataIndex];

          let link: string = "./device/" + element.uuid;
          if (props.session.userType === "resellersContacts") {
            if (props.resellerID !== undefined) {
              link += "?resellerID=" + props.resellerID;
            }
          }
          //return (
          // <div>
          //   <Link to={link} className="btn btn-info">
          //     <FaIcon fixedWidth className="whiteIcon" icon="edit" />
          //   </Link>


          return <ButtonGroup>
            <IconButton color="primary" href={link}>
              <EditIcon />
            </IconButton>

            <IconButton color="secondary" type="button" onClick={(ev) => {
              ev.preventDefault();
              deleteDevice(element.uuid)
            }}>
              <DeleteIcon />
            </IconButton>
          </ButtonGroup>
        }
      },
    },
  ]
  const dropdownOptions: IDropdownButtonProps["options"] = [];

  let linkN: string = "./device/new";
  dropdownOptions.push({ label: "Add Device", link: linkN, target: '' });
  if (
    props.session.userType === "resellersContacts" &&
    props.resellerID === undefined
  ) {
    let linkRP: string = "./resellerProvisioning";
    dropdownOptions.push({
      label: "Reseller Provisioning Settings",
      link: linkRP,
      target: "",
    });



    columns.unshift({
      name: "customersName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: true,

      },
    })
  }
  if (props.session.userType === "resellersContacts") {
    let linkRP: string = RESELLER_ROUTE_PROVISIONING_VIEW_URLS;
    if (props.resellerID) {
      linkRP =
        "/reseller/" + props.resellerID + "/viewprovisioningurls";
    }
    dropdownOptions.push({
      label: "View Provisioning URLS",
      link: linkRP,
      target: "",
    });
  }
  if(props.resellerID!=null){
    dropdownOptions.push({
      label:'Import Devices',
      target:'',
      onClick:onImportDevicesClick
    })
  }


  const deleteDevice = (uuid: any) => {
    if (
      window.confirm(
        "Are you sure you want to remove this device?"
      )
    ) {
      setPageState(PageState.Loading);

      set.provisioningRemove(uuid).then(
        () => {
          const notification: INotification = {
            title: "Success",
            data: "Device removed.",
            variant: NOTIFICATION_TYPE.success,
            srcComponent: "Devices",
            srcData: "success",
            srcFunction: "",
          };
          App.newNotification(notification);
        },
        () => {
          App.newNotificationError(
            "There was a problem removing the device."
          );
        }
      );
   
      setTimeout(function () {
        getServices({});
      }, 500);
    }
  }

  const RenderSwitch = () => {
    switch (pageState) {
      case PageState.Loading:
        return <Loading />;

      case PageState.ShowTable:
        return (
          <MuiTableWrapper
            title=""
            data={data.devices}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: false,
              search: true,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "customersName",
                direction: "asc",
              },
            }}
          />
        );

      case PageState.ImportingDevices:
        return <ImportDevicesForm customersId={props.resellerID ? props.resellerID : ''} onDoneClick={()=>{getServices({})}}></ImportDevicesForm>
      case PageState.NetworkError:
        return <Error />;
      default:
        return <></>;
    }
  };
  return <Grid container spacing={3} alignContent="flex-start">
    <Grid item xs={12}>
      <PageHead
        resellerID={props.resellerID}
        title="Provisioning - Devices"
        parents={[]}
        subpages={[]}
      >
        <MuiDropdownButton label="More Options" options={dropdownOptions} />
      </PageHead>
    </Grid>


    <Grid item xs={12}>
      <RenderSwitch />
    </Grid>
  </Grid>

}



export default Devices

/*
  constructor(props: any) {
    super(props);

    this.state = {
      error: false,
      loading: true,
      devices: [],
      search: { uuid: "" },
    };
    // this.clickNew = this.clickNew.bind(this);
  }

  public render() {
    const { loading, devices, error, search } = this.state;

    // console.log("state of devices", this.state);

    if (loading) {
      return <Loading />;
    } else {
      const col = [
        {
          Header: "UUID",
          accessor: "uuid",
          sortable: true,
        },
        {
          Header: "Extension",
          accessor: "extensionCallerid",
          sortable: true,
        },
        {
          Header: "",
          accessor: "uuid", // String-based value accessors!
          Cell: (props: any) => {
            let link: string = "./device/" + props.value;
            if (this.props.session.userType === "resellersContacts") {
              if (this.props.resellerID !== undefined) {
                link += "?resellerID=" + this.props.resellerID;
              }
            }
            return (
              <div>
                <Link to={link} className="btn btn-info">
                  <FaIcon fixedWidth className="whiteIcon" icon="edit" />
                </Link>
                <Button
                  variant="danger"
                  type="button"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to remove this device?"
                      )
                    ) {
                      this.setState({ loading: true });
                      set.provisioningRemove(props.value).then(
                        () => {
                          const notification: INotification = {
                            title: "Success",
                            data: "Device removed.",
                            variant: NOTIFICATION_TYPE.success,
                            srcComponent: "Devices",
                            srcData: "success",
                            srcFunction: "",
                          };
                          App.newNotification(notification);
                        },
                        () => {
                          App.newNotificationError(
                            "There was a problem removing the device."
                          );
                        }
                      );
                      var self = this;
                      setTimeout(function () {
                        self.get({});
                      }, 500);
                    }
                  }}
                >
                  <FaIcon fixedWidth className="whiteIcon" icon="trash-alt" />
                </Button>
              </div>
            );
          },
        },
      ];
      let linkN: string = "./device/new";
      let burgerOpts: IBurgerOption[] = [];
      burgerOpts.push({ title: "Add Device", link: linkN, isExternal: false });
      if (
        this.props.session.userType === "resellersContacts" &&
        this.props.resellerID === undefined
      ) {
        let linkRP: string = "./resellerProvisioning";
        burgerOpts.push({
          title: "Reseller Provisioning Settings",
          link: linkRP,
          isExternal: false,
        });
        col.unshift({
          Header: "Customer",
          accessor: "customersName",
          sortable: true,
        });
      }
      if (this.props.session.userType === "resellersContacts") {
        let linkRP: string = RESELLER_ROUTE_PROVISIONING_VIEW_URLS;
        if (this.props.resellerID) {
          linkRP =
            "/reseller/" + this.props.resellerID + "/viewprovisioningurls";
        }
        burgerOpts.push({
          title: "View Provisioning URLS",
          link: linkRP,
          isExternal: false,
        });
      }

      return (
        <>
          <div className="content">
            <PageHead
              resellerID={this.props.resellerID}
              title="Provisioning - Devices"
            >
              <DevicesSearchForm data={search} onSubmit={this.get}>
                <VipBurgerMenu options={burgerOpts} />
              </DevicesSearchForm>
            </PageHead>
            {error ? (
              <Error />
            ) : (
              <>
                <VoipTable
                  title="Devices"
                  data={devices}
                  sortByStart="uuid"
                  col={col}
                  multiSelect={false}
                />
              </>
            )}
          </div>
        </>
      );
    }
  }

  public resolveData = (data: any) => data.map((row: any) => row);

  public componentDidMount = () => {
    // trigger db call here
    this.get({});
  };

  // get phonebooks
  public get = (payload: any) => {
    // console.log("get", payload);
    this.setState({ loading: true });

    if (this.props.session.userType === "resellersContacts") {
      if (this.props.resellerID !== undefined) {
        payload.customersId = this.props.resellerID;
      }
    }
    set
      .provisioningGet(payload)
      .then((data: IProvisioningGetHttpResponse) => {
        // console.log("provisioningGet", data);
        const stateUpdate: any = {};
        stateUpdate.loading = false;
        stateUpdate.search = payload;
        if (data.items) {
          stateUpdate.devices = data.items;
        }
        this.setState(stateUpdate);
        //     resolve(true);
      })
      .catch((exception) => {
        App.newNotificationError('There was a problem fetching the devices.')
      });
  };
}
*/
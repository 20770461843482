import * as React from "react";
import { Link } from "react-router-dom";

import customerwizard from "../NewCustomerWizard/images/customer-wizard-img@2x.png";

// ({ data, onSubmit }: any)
const FinalizeForm = (
  values: any,
  push: any,
  pop: any,
  form: any,
  thisX: any
) => {
  const ID = values.customerId;
  const serviceLink = "./reseller/" + ID + "/newService/" + ID;
  const customerLink = "./reseller/" + ID;
  return (
    <>
      <div className="wizard__offer-post-box">
        {values.mainError !== true ? (
          <>
            <p className="wizard__offer-post-box-heading">
              Your new customer is set up and ready to go
            </p>
            <img
              className="wizard__offer-post-img"
              src={customerwizard}
              alt={"welcome"}
              width="424px"
              height="317px"
            />
            {values.errors.length > 0 && (
              <>
                {values.errors.map((errr: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="col-12 alert alert-danger"
                      role="alert"
                    >
                      <p>
                        {errr.error} {errr.e}
                      </p>
                    </div>
                  );
                })}
              </>
            )}
            <Link to={serviceLink} className="btn btn-primary">
              Add A Service
            </Link>
            <Link to={customerLink} className="btn button-secondary">
              Go To Customer
            </Link>
          </>
        ) : (
          <div className="alert alert-danger" role="alert">
            <p>{values.error}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default FinalizeForm;

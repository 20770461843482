import { Button } from "@material-ui/core"
import React from "react"
import MySettings from "../../static/settings"

interface IImportDevicesFormProps {
    customersId:string
    onDoneClick:()=>void
}
const settings=new MySettings()
const ImportDevicesForm:React.FunctionComponent<IImportDevicesFormProps> = (props:IImportDevicesFormProps) => {

    return <>
        First download the current list of devices to use as a template using the button below.
        <a href={settings.getDownloadDevicesUrl(props.customersId)} target="_blank">
            <Button fullWidth color="primary" style={{ marginTop: 10 }} variant="contained">
                Download Devices CSV
            </Button>
        </a>
        <br /><br />
        Now add any new devices to the bottom of the template with the correct Server and Device. For example<br />
        00:00:00:00:00:00,voipserverXXXX.vipvoipuk.net,200phone
        <br /><br />
        Then upload the CSV of devices using the choose file button below.
        <form method="POST" action={settings.getDownloadDevicesUrl(props.customersId)} encType="multipart/form-data" target="_blank">
            <input type="hidden" name="customersId" value={props.customersId} />
            <br />
            Insert colons in to MAC addresses? <input type="checkbox" name="addColons" value="addColons" /> (use if your list of MAC addresses do not have colons in them)
            <br /><br />
            <input type="file" name="file" style={{display:'block'}}/>
            <br />
            Finally click Import Devices to begin the import. You will receive a CSV file with the results of the import.
            <br />
            <input type="submit" value="Import Devices" />
        </form>
        <br />
        Once you are happy with the results of the import please click Done.
        <Button fullWidth color="primary" style={{ marginTop: 10 }} variant="contained" onClick={props.onDoneClick}>Done</Button>
    </>
}

export default ImportDevicesForm
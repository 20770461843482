import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import { Button, Card, Form as Forms } from "react-bootstrap";
import App from "../../App";
import Loading from "../../components/loading";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";

const set = new MySettings();

const WallboardNameForm: React.FC<any> = (props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>(props.name);

  // console.log("state of parent", widgets);

  const updateName = async () => {
    setLoading(true);
    try {
      await set.wallboardLayoutUpdate(props.id, name);
      const notification: INotification = {
        title: "Wallboard Updated",
        data: "Wallboard has been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "WallboardWidgetMessageForm",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification);
    } catch (err) {
      App.newNotificationError("Failed to update the widget " + err.data);
    }
    setLoading(false);
  };

  useEffect(() => { }, []);

  return (
    <Card style={{ textAlign: "left" }}>
      <Card.Header as="h6">Wallboard Name</Card.Header>
      {loading ? (
        <div style={{ margin: "60px" }}>
          <Loading />
        </div>
      ) : (
        <form>
          <div
            style={{
              marginBottom: "30px",
              marginLeft: "20px",
              marginTop: "20px",
            }}
            className=" justify-content-start"
          >
            <label className="mr-2 my-auto">
              Please use the box below to set the name of the wallboard.
            </label>
            <div className="d-flex justify-content-start customHeight">
              <Forms.Control
                type="text"
                value={name}
                placeholder="Wallboard Name"
                onChange={(e) => setName(e.target.value)}
              ></Forms.Control>
            </div>
          </div>
          <Card.Footer className="text-center">
            <Button className="noleft" variant="primary" onClick={updateName}>
              Save
            </Button>
          </Card.Footer>
        </form>
      )}
    </Card>
  );
};
export default WallboardNameForm;

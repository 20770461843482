import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "react-table/react-table.css";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";

import { Link, RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import App from "../../App";
import Error from "../../components/error";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import { INumber } from "../../interfaces/number";
import { IPeopleGetHttpResponse, IPerson } from "../../interfaces/people";
import "./index.css";
library.add(faWindowClose, faEdit);

export interface INumberState {
  // newPerson: IPerson;
  loading: boolean;
  customers: INumber[];
  error: boolean;
}

const set = new MySettings();
interface ICustomerPage extends RouteComponentProps<any>, IPageProps { }
interface ParamTypes {
  id: string;
}

const CustomerListPage: React.FC<ICustomerPage> = (props) => {
  let { id } = useParams<ParamTypes>();
  const [data, setData] = useState<IPerson[]>([]);
  const [person, setPerson] = useState<any>({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getNumber = async () => {
    try {
      const response = await set.numberGet(id);
      const people: IPeopleGetHttpResponse = await set.peopleGet(id);
      setPerson(response);
      setData(people.items);
      setLoading(false);
    } catch (error) {
      setError(true);

      const notification: INotification = {
        title: "Error",
        data: "API Error Occurred. please retry if problem persists please contact support",
        variant: NOTIFICATION_TYPE.danger,
        srcFunction: "handleNumberDelete",
        srcData: window.location.pathname,
        srcComponent: "People",
      };

      App.newNotification(notification);

    }
  }

  useEffect(() => {
    getNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const col = [
    {
      Header: "Name",
      accessor: "name",
      sortable: true,
    },
    {
      Header: "Email",
      accessor: "emailAddress",
      sortable: true,
    },
    {
      Header: "Phone",
      accessor: "telephone",
      sortable: true,
    },
    {
      Header: "",
      accessor: "id", // String-based value accessors!
      Cell: (props: any) => {
        // console.log(props);
        const link: string = "/vrmessageadd/" + id + "/" + props.obj.id;
        return (
          <Link
            to={link}
            className="btn btn-primary"
            onClick={(e) => e.stopPropagation()}
          >
            Add Message
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        {loading ? (
          <>
            <div style={{ height: "150px" }}></div>
            <Loading />
          </>
        ) : error ? (
          <Error />
        ) : (
          <>
            <PageHead
              title={
                person !== undefined && person.item.ownerName !== undefined
                  ? `${person.item.ownerName} / ${person.item.vrAnswerAs}`
                  : ""
              }
              parents={[{ title: "Home", url: "../virtualreceptionisthome" }]}
            ></PageHead>
            <div className="bgBox">
              <table style={{ width: "500px" }}>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>
                      <span className="tValue">{person.item.ownerName}</span>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Answer As</th>
                    <td>
                      <span className="tValue">{person.item.vrAnswerAs}</span>{" "}
                    </td>
                  </tr>
                  <tr>
                    <th>Special Instructions</th>
                    <td>
                      <span className="tValue">
                        {person.item.vrSpecialInstructions}
                      </span>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ height: "50px" }}></div>
            <VoipTable
              title="VR Receptionist"
              data={data}
              col={col}
              sortByStart="name"
              multiSelect={false}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CustomerListPage;

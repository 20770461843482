
import { Field, Form } from "react-final-form";
import { ICallRecordingsSearchFormProps } from "../../interfaces/search-form";
import DateFnsUtils from "@date-io/date-fns";
import GetExtensionOptionsForCallRecordingSearch from "./GetExtensionOptionsForCallRecordingSearch";
import { Button, Grid, ButtonGroup } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MuiSelect from "../../components/MuiComponents/MuiSelect";
import MuiTextInput from "../../components/MuiComponents/MuiTextInput";

const CallRecordingSearchForm: React.FunctionComponent<
  ICallRecordingsSearchFormProps
> = ({ services, onSubmit, data, resellerId }) => {
 




  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ handleSubmit, form, submitting, pristine, values }) => (<MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form onSubmit={handleSubmit}>

          <Grid
            container
            spacing={2}
          >

            <Grid item xs={3} >
            </Grid>

            <Grid item xs={3} style={{ textAlign: 'center' }}>From</Grid>

            <Grid item xs={3} style={{ textAlign: 'center' }}>To</Grid>

            <Grid item xs={3} >
            </Grid>
            <Grid item xs={3} >
              {(services || onSubmit || data || resellerId) !== undefined &&
                services.length > 1 && (



                  <MuiSelect
                    name="customersSitesServicesId"
                    label="Site"
                    options={services!.map((service) => {
                      return { value: service.id, label: service.siteName };
                    })}
                  />
                )}
            </Grid>
            <Grid item xs={3} >

              <Field name="datetime.$gte">
                {({ input: { onChange, value } }) => (
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={value}
                    onChange={onChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                )}
              </Field>

            </Grid>

            <Grid item xs={3} >
              <Field name="datetime.$lte">
                {({ input: { onChange, value } }) => (

                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={value}
                    onChange={onChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                )}
              </Field>

            </Grid>



            {/* 






              <Grid item xs={12} sm={4} md={3} >
                <Field
                  name="datetime.$gte"
                  validate={requiredValidator}
                  dateFormat="dd/MM/yyyy"
                  component={DatePickerAdapter}
                  placeholder="From Date"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Field
                  name="datetime.$lte"
                  dateFormat="dd/MM/yyyy"
                  validate={requiredValidator}
                  component={DatePickerAdapter}
                  placeholder="To Date"
                />
              </Grid> */}
            <Grid item xs={3}>
              <MuiTextInput outlinedLabel="Source" name="source" />

            </Grid>
            <Grid item xs={3}>
              <MuiTextInput outlinedLabel="Destination" name="destination" />

            </Grid>
            <Grid item xs={3}>
              <MuiTextInput outlinedLabel="DDI" name="ddi" />

            </Grid>
            <Grid item xs={3}
              style={{ maxWidth: "346px", marginBottom: "16px" }}>

              <GetExtensionOptionsForCallRecordingSearch name="extensionName" resellerId={resellerId}
                customersSitesServicesId={values.customersSitesServicesId}
                label="Agent" />

            </Grid>

            <Grid item xs={3}>
              <ButtonGroup fullWidth>
                <Button

                  id="CallLogSearchBtn"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Search
                </Button>


                {resellerId && (
                  <Button href={`/voip/portal/reseller/${resellerId}/service/${values.customersSitesServicesId}/callrec`} variant="contained"
                    color="secondary">
                    Settings
                  </Button>
                )}</ButtonGroup>
            </Grid>


          </Grid>


          {/* {JSON.stringify(values)} */}

        </form> </MuiPickersUtilsProvider>
      )}
    />
  );
};

export default CallRecordingSearchForm;

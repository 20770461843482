import React, { useEffect, useState } from "react";
import "react-table/react-table.css";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";

import { Link, RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";
import App from "../../App";
import Error from "../../components/error";
import PageHead from "../../components/PageHead";
import VipBurgerMenu from "../../components/VipBurgerMenu";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import { IPeopleGetHttpResponse, IPerson } from "../../interfaces/people";
import AssociateButton from "../../components/associateUserButton";
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";
import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
export interface IPeopleState {
  // newPerson: IPerson;
  loading: boolean;
  people: IPerson[];
  error: boolean;
}

const set = new MySettings();
interface IPeoplePage extends RouteComponentProps<any>, IPageProps { }

const People: React.FC<IPeoplePage> = (props) => {
  console.log('PBB', props)
  const [people, setPeople] = useState<IPerson[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // console.log("STATE", resellerPeople);

  useEffect(() => {
    let isMounted = true;

    const getPeople = async () => {
      try {
        const peopleGetResponse: IPeopleGetHttpResponse = await set.peopleGet(
          '', props.resellerID
        );
        // console.log("DATA", peopleGetResponse);

        if (isMounted) {
          setPeople(peopleGetResponse.items);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);

        const notification: INotification = {
          title: "Error",
          data: "API Error Occurred. please retry if problem persists please contact support",
          variant: NOTIFICATION_TYPE.danger,
          srcFunction: "handleNumberDelete",
          srcData: window.location.pathname,
          srcComponent: "People",
        };

        App.newNotification(notification);

        setError(true);

      }
    };

    getPeople();

    return () => {
      isMounted = false;
    };
  }, [props.resellerID]);

  const handlePersonDelete = async (id: string) => {
    if (
      window.confirm(
        "Are you sure you want to remove this person?"
      )
    ) {
      try {
        setLoading(true);

        await set.customerPersonRemove(id);

        setPeople(people.filter((person) => person.id !== id));

        const notification: INotification = {
          title: "Success",
          data: "Person removed.",
          variant: NOTIFICATION_TYPE.success,
          srcFunction: "handleNumberDelete",
          srcData: window.location.pathname,
          srcComponent: "PhoneBook",
        };

        App.newNotification(notification);
      } catch (error) {
        console.log(error);
        const notification: INotification = {
          title: "Problem Removing Person",
          data: error.data,
          variant: NOTIFICATION_TYPE.warning,
          srcFunction: "handleNumberDelete",
          srcData: window.location.pathname,
          srcComponent: "PhoneBook",
        };

        App.newNotification(notification);
      }
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "emailAddress",
      label: "Email Address",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "associatedExtension",
      label: "Extension",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "id",
      label: " ",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ align: "right" }),
        customBodyRenderLite: (dataIndex: number) => {
          const element = people[dataIndex];

          let numberEditLink =
            "./person/" + element.id;

          return <>
            <Tooltip title="Edit Person">
              <IconButton component={Link} type="button" to={numberEditLink}>
                <EditIcon color="primary" /></IconButton>
            </Tooltip>

            <Tooltip title="Delete Person">
              <IconButton color="secondary" type="button" onClick={(ev) => {
                ev.preventDefault();
                handlePersonDelete(element.id);
              }}>
                <DeleteIcon color="secondary" />
              </IconButton>
            </Tooltip>
            {props.resellerID && (<AssociateButton id={element.id} buttonLabel="Impersonate" openInNewTab={false}></AssociateButton>)}
          </>
        }
      },
    },
  ];
  // const col = [
  //   {
  //     Header: "First Name",
  //     accessor: "firstName",
  //     sortable: true,
  //   },
  //   {
  //     Header: "Last Name",
  //     accessor: "lastName",
  //     sortable: true,
  //   },
  //   {
  //     Header: "Email Address",
  //     accessor: "emailAddress",
  //     sortable: true,
  //   }, {
  //     Header: "Extension",
  //     accessor: "associatedExtension",
  //     sortable: true
  //   },
  //   {
  //     Header: "",
  //     accessor: "id", // String-based value accessors!
  //     Cell: (cellProps: any) => {
  //       const link: string = "./person/" + cellProps.value;

  //       return (
  //         <>
  //           <Link to={link} className="btn btn-outline-primary">
  //             Edit <FaIcon fixedWidth icon="edit" />
  //           </Link>
  //           <Button
  //             onClick={() => {
  //               handlePersonDelete(cellProps.value);
  //             }}
  //             variant="danger"
  //           >
  //             Remove <FaIcon icon="user-minus" color="white" />
  //           </Button>
  //           {props.resellerID && (<AssociateButton id={cellProps.value} buttonLabel="Impersonate" openInNewTab={false}></AssociateButton>)}
  //         </>
  //       );
  //     },
  //   },
  // ];

  return (
    <>
      <div className="content">
        <PageHead resellerID={props.resellerID} title="People" parents={[]}>
          <VipBurgerMenu
            options={[
              {
                title: "New Person",
                link: "./person/new",
                isExternal: false,
              },
            ]}
          />
        </PageHead>

        {loading ? (
          <Loading />
        ) : error ? (
          <Error />
        ) : (
          <>
            {/* <VoipTable
              title="People"
              data={people}
              col={col}
              sortByStart="lastName"
              multiSelect={false}
            /> */}



            <MuiTableWrapper
              title="People"
              data={people}
              columns={columns}
              options={{
                filterType: "checkbox",
                selectableRows: "none",
                responsive: "standard",
                selectToolbarPlacement: "none",

                elevation: 0,
                print: false,
                download: false,
                search: true,
                filter: false,
                viewColumns: false,
                sortOrder: {
                  name: "lastName",
                  direction: "asc",
                },
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default People;

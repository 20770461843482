import React, { useEffect, useState } from "react";

import { Button, Card, Form } from "react-bootstrap";
import { useParams } from "react-router";
import App from "../../App";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import { IPhonebookContact } from "../../interfaces/phonebook-number";
import MySettings from "../../static/settings";

const set = new MySettings();

export interface PhoneBookUploadProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent) => Promise<void>;
  contact: IPhonebookContact;
}

const PhoneBookUpload: React.FC<PhoneBookUploadProps> = () => {
  const [uploading, setUploading] = React.useState<boolean>(false)
  const [selectedFile, setSelectedFile] = React.useState<any>();
  let { phoneBookId, rid } = useParams<any>();
  const [phoneBook, setPhoneBook] = useState<any>();

  useEffect(() => {
    const f = async () => {
      const book = await set.phonebooksGet(rid);
      if (book.phoneBooks) {
        const ind = book.phoneBooks.findIndex((d) => d.id === phoneBookId)
        setPhoneBook(book.phoneBooks[ind])
      }
      console.log("book", book)
    }
    f();
  }, [rid, phoneBookId])
  const handleFileSelect = (event: any) => {
    let name = event.target.files[0].name.split(".");
    if (name[1] !== "csv") {
      const notification: INotification = {
        title: "Error",
        data: "Please select a CSV file",
        variant: NOTIFICATION_TYPE.warning,
        srcFunction: "handleNumberDelete",
        srcData: window.location.pathname,
        srcComponent: "PhoneBook",
      };

      App.newNotification(notification);
      return;
    }
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (window.location.search.substring(1).includes("uploaded")) {
      showSuccessNotification()
    }
  }, []);

  const showSuccessNotification = () => {
    const notification: INotification = {
      title: "Success",
      data: "Phonebook Entry Uploaded",
      variant: NOTIFICATION_TYPE.success,
      srcFunction: "handleNumberDelete",
      srcData: window.location.pathname,
      srcComponent: "PhoneBook",
    };

    App.newNotification(notification);
  }

  const submit = async () => {
    console.log("dfg", phoneBookId)
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("id", phoneBookId)
    try {
      const res = await fetch(set.phoneBookUploadEntriesURL(), {
        method: "POST",
        body: formData,
      });
      if (res.status === 200) {
        showSuccessNotification()
      } else {
        throw res
      }
    } catch (err3) {
      let err = err3 as any
      let message = 'There was a problem saving the phone book entries.'
      try {
        message = await err.text()
      } catch (err2) {

      }
      App.newNotificationError(message)
    }
    setUploading(false)
  }

  //we have to do this because regular form posting does not work
  const onSubmit = (e: any) => {
    e.preventDefault();
    setUploading(true);
    submit()
    return false;
  }
  const pageName = (phoneBook && phoneBook.name ? phoneBook.name : 'Phonebook')
  return (
    <div className="content">
      <PageHead
        resellerID=""
        title="Upload CSV"
        parents={[
          { title: "Phone Books", url: "../PhoneBooks" },
          { title: pageName, url: "../phonebook/" + phoneBookId },
        ]}
      ></PageHead>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Form
          action={set.phoneBookUploadEntriesURL()}
          method="POST"
          encType="multipart/form-data"
          onSubmit={onSubmit}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              marginBlock: "20px",
              fontWeight: 550,
            }}
          >
            <div> Valid Files</div>
            <div>- Yealink phonebook exported to CSV format</div>
            <div>
              - Download and edit this{" "}
              <a
                href="/voip/src/templates/phonebookExample.csv"
                target="_blank"
              >
                CSV example
              </a>{" "}
            </div>
          </div>
          <Card style={{ width: "500px" }}>
            <Card.Header>
              <h2>Select File</h2>
            </Card.Header>

            <Card.Body className="form-card-body">
              <Form.Group controlId="file">
                <Form.Label>
                  Please click here to choose the CSV with your phone book
                  entries
                </Form.Label>
                {!selectedFile ? (
                  <div>No file selected</div>
                ) : (
                  <div>{selectedFile?.name}</div>
                )}
                <Form.Control
                  type="file"
                  placeholder="Click to select File"
                  value=""
                  onChange={handleFileSelect}
                  name="file"
                />
                <Form.Control
                  style={{ display: "none" }}
                  type="text"
                  placeholder="id"
                  value={phoneBookId}
                  name="id"
                />
              </Form.Group>
            </Card.Body>

            <Card.Footer className="text-center">
              {uploading ? (
                <div>Saving...</div>
              ) : (
                <Button variant="primary" type="submit">Save</Button>
              )}
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </div>
  );
};

export default PhoneBookUpload;

import React, { useEffect, useState } from "react";
import Loading from "../../components/loading";
import MuiSelect from "../../components/MuiComponents/MuiSelect";
import MySettings from "../../static/settings";

const set = new MySettings();

enum PageState {
  ShowContent,
  Loading,
  NetworkError,
  NoResults,
}

interface INumberGetSiteOptionsProps {
  customerId:string
  setNoSites: (noSites:boolean) => void;
}

const NumberGetSiteOptions:React.FC<INumberGetSiteOptionsProps> = (props:INumberGetSiteOptionsProps) => {
  const [siteOptions, setSiteOptions] = useState<any[]>([]);
  const [pageState, setPageState] = useState(PageState.Loading);

  useEffect(() => {
    const getSiteOptions = async () => {
      try {
        const result = await set.sitesGet(props.customerId, true, false);

        setSiteOptions(result.items);

        if (result.items.length > 0) {
          props.setNoSites(false)
          setPageState(PageState.ShowContent);
        } else {
          props.setNoSites(true)
          setPageState(PageState.NoResults);
        }
      } catch (err) {
        setPageState(PageState.NetworkError);
      }
    };

    getSiteOptions();
  }, [props]);

  switch (pageState) {
    case PageState.Loading:
      return <Loading size="100px" />;

    case PageState.ShowContent:
      return (
        <MuiSelect
          name="customersSitesId"
          label="Site"
          emptyFirstOption
          options={siteOptions.map((site) => {
            return { value: site.id, label: site.description };
          })}
        />
      );

    case PageState.NoResults:
      return <p>This customer is not currently associated with any sites.</p>;

    case PageState.NetworkError:
      return <p>Something went wrong. Please try again later.</p>;
    default:
      return <></>;
  }
};

export default NumberGetSiteOptions;

import React, { useEffect, useState } from "react";
import "react-table/react-table.css";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";

import { Link, RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Error from "../../components/error";
import PageHead from "../../components/PageHead";
import { INumber, INumbersHttpResponse } from "../../interfaces/number";
library.add(faWindowClose, faEdit);

export interface INumberState {
  // newPerson: IPerson;
  loading: boolean;
  number: INumber[];
  error: boolean;
}

const set = new MySettings();
interface INumberPage extends RouteComponentProps<any>, IPageProps { }

const HomePage: React.FC<INumberPage> = (props) => {
  const [data, setData] = useState<INumber[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);



  useEffect(() => {
    const getNumber = async () => {
      try {
        const NumberGetResponse: INumbersHttpResponse = props.resellerID
          ? await set.numberSearch("", "", props.resellerID)
          : await set.numberSearch("", "", "");
        setData(NumberGetResponse.numbers);
        setLoading(false);
      } catch (error) {
        setError(true);
      }
    };
    getNumber();

  }, [props]);

  const col = [
    {
      Header: "DDI",
      accessor: "name",
      sortable: true,
      Cell: (props: any) => {
        const link: string = props.resellerID
          ? `/reseller/${props.resellerID}/virtualReceptionistSummary/${props.obj.id}`
          : "/virtualreceptionistsummary/" + props.obj.id;

        return (
          <Link
            style={{ textDecoration: "underline", float: "left" }}
            to={link}
          >
            {props.value}
          </Link>
        );
      },
    },
    {
      Header: "Answer As",
      accessor: "vrAnswerAs",
      sortable: true,
    },
  ];

  return (
    <>
      <div className="content">
        <PageHead
          resellerID={props.resellerID}
          title="VIP Receptionist"
          parents={[]}
        ></PageHead>

        {loading ? (
          <Loading />
        ) : error ? (
          <Error />
        ) : (
          <>
            <VoipTable
              title="Receptionist"
              data={data}
              col={col}
              sortByStart="name"
              multiSelect={false}
            />
          </>
        )}
      </div>
    </>
  );
};

export default HomePage;


import { ISelectOption } from '../interfaces/interfaces';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { memo, useState } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
// table object properties
export interface IVipProvisioningFieldProps {
  title: string;
  data: any;
  type: "string" | "number" | "boolean" | "CustomStatuses" | "select" | "checkBoxList";
  dataField: string;
  payload?: any;
  selectOptions?: ISelectOption[]
  updateField: (field: any, payload: any) => void;
}

//
export interface IVipProvisioningFieldState {
  enabled: boolean;
  id: string;
  status: string;
  message: string;
  checkboxValue: boolean
  stringValue: string
  selectOptions: ISelectOption[]
}

const uuidv4 = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
interface iVipProvisioningFieldTextMuiProp {
  dataField: string;
  type: 'text' | 'number';
  value: string;
  setValue: (newValue: string) => void;
}
const VipProvisioningFieldTextMui = ({ value, dataField, setValue, type }: iVipProvisioningFieldTextMuiProp) => {
  const [textValue, setTextValue] = useState(() => {
    if(value ===undefined){
      return ''
    }
    return value;
  })

  const handleTextChange = (event: any) => {
    setTextValue(event.target.value)
    setValue(event.target.value)
  }

  return <TextField
    fullWidth
    id={dataField}
    value={textValue}
    type={type}
    onChange={handleTextChange}
    variant="outlined"
    size="small"
  />
}

interface iVipProvisioningFieldCheckboxMuiProp {
  dataField: string;
  type: 'text' | 'number';
  value: any|undefined;
  setValue: (newValue: string) => void;
}
const VipProvisioningFieldCheckboxMui = ({ value, dataField, setValue }: iVipProvisioningFieldCheckboxMuiProp) => {
  const [data, setData] = useState<boolean>(() => {
    if(value ===undefined){
      return false
    }
    if(typeof value ==='boolean'){
      return value as boolean;
    }
    return false
  })

  const handleChange = (event: any) => {
    setData(!value)
    setValue(!value)
  }

  return <FormGroup row>
  <FormControlLabel
    control={
      <Checkbox
        id={dataField}
        checked={data}
        onChange={handleChange}
        name="checkedB"
        color="primary"
      />
    }
    label={data === true ? 'Enabled' : 'Disabled'}
  />
</FormGroup>
}



const VipProvisioningFieldMui2 = ({ title, payload, data, type, dataField, selectOptions, updateField }: IVipProvisioningFieldProps) => {

  const [payArray, setPayArray] = useState<any[]>(() => {
    let pay: any[] = [];
    if (payload !== undefined) {
      pay = payload;
      if (type === 'checkBoxList') {
        const dataValue = data;
        if (dataValue) {
          for (let i = 0; i < payload.length; i++) {
            if (dataValue.indexOf(payload[i].id) >= 0) {
              payload[i].enabled = true;
            }

          }
        }
      }
    }
    return pay;
  })


  const [state, setState] = useState<any>(() => {
    let ena = false;
    let value: any;

    value = data;
    if (value === undefined) { } else {
      ena = true;
    }


    console.log("title", dataField, data)

    return {
      enabled: ena,
      id: '',
      status: 'Available',
      message: '',
      checkboxValue: data,
      stringValue: data,
      selectOptions: selectOptions ? selectOptions : []
    }
  });




    const toggleIncomingCheckbox = (event: React.SyntheticEvent) => {

      if (state.enabled === true) {
        setState({ ...state, enabled: false });
      } else {
        setState({ ...state, enabled: true });
        if (state.selectOptions.length > 0) {
          updateField(dataField, state.selectOptions[0].value);
        }
      }
    }
    const disableField = () => {
      if (state) {
        setState({ ...state, enabled: false });
        updateField(dataField, undefined);
      }
    }
    const handleLocalChange = (event: any) => {
      console.log(event.target.id, event.target.value);

      if (state) {
        const up: any = { ...state }
        up[event.target.id.toString()] = event.target.value

        setState(up);
      }


      //this.setState(updateState(event.target.id.toString(), event.target.value));

    }
    const handleChangeCustomStatusSelect = (event: any) => {
      //update local UI
      console.log(event, event.target.value)
      if (state) {
        setState({ ...state, status: event.target.value });
        //update form so that when we submit the data is correct
        //   updateField(event.target.id, event.target.value);
      }
    }
    const handleTextChange = (value: string) => {
      //update local UI
      console.log(value)
      //if (state) {

      updateField(dataField, value);
      // }
    }

    const handleSelectChange = (event: any) => {
      //update local UI
    //  console.log("handleSelectChange", event, dataField, event.target.value)
      // if (state) {
      //   setState({ ...state, stringValue: event.target.value });
      
      // }  
      updateField(dataField, event.target.value);
    }
   
    const updateCheckBoxList = (change: any) => {
      console.log(change);
      const tmpArray = [...payArray];

      const index = tmpArray.findIndex(dd => dd.id === change.id);
      // console.log(tmpArray[index])

      if (tmpArray[index].enabled === undefined) {
        tmpArray[index].enabled = true
      } else {
        tmpArray[index].enabled = !tmpArray[index].enabled
      }

      const dataUpdate = tmpArray.map(d => {
        if (d.enabled && d.enabled === true) {
          return d.id
        } else {
          return null
        }
      }).filter((el) => {
        return el != null;
      });
      setPayArray(tmpArray)
      updateField(dataField, dataUpdate);
    }

    const updateCustomStatus = () => {
      if (state) {
        let status: any = { message: '', status: '' };
        console.log(status, state);
        let masterValue: any = data;
        let objValue;
        if (masterValue === undefined) {
          objValue = {
            statuses: []
          };
        } else {
          objValue = JSON.parse(masterValue);
          if (objValue == null || objValue.statuses == null) {
            objValue = {
              statuses: []
            };
          }
        }


        status.status = state.status;
        status.message = state.message;
        setState({ ...state, status: '', message: '' })
        status.id = uuidv4();
        objValue.statuses.push(status);
        const textValue = JSON.stringify(objValue);
        updateField(dataField, textValue);
      }
    }


    const deleteCustomStatus = (uuid: string) => {
      if (state) {
        let masterValue: any = data;
        let objValue: any;
        if (masterValue === undefined) {
          objValue = {
            statuses: []
          };
        } else {
          objValue = JSON.parse(masterValue);
          if (objValue == null || objValue.statuses == null) {
            objValue = {
              statuses: []
            };
          }
        }

        let xx = objValue.statuses.findIndex((dd: any) => dd.id = uuid);
        //   console.log(xx);
        objValue.statuses.splice(xx, 1);
        const textValue = JSON.stringify(objValue);
        updateField(dataField, textValue);
      }
    }


    let MakeItem = (selectOption: ISelectOption) => {

      return <MenuItem key={selectOption.value} value={selectOption.value}>{selectOption.label}</MenuItem>

    };

    let masterValue = data;







    //custom statuss
    let aArray;
    const { message, status } = state;
    if (type === 'CustomStatuses') {
      aArray = {
        statuses: []
      };
      if (masterValue === undefined) {

      } else {
         aArray = JSON.parse(masterValue);
      }
      if (aArray == null) {
        aArray = {
          statuses: []
        }
      }
    }

    return <Box>{console.log("render", title)}
      <Typography variant="h6" style={{ position: 'relative', marginBottom: 10, marginTop: 10 }}>
        {title}
        <FormControlLabel style={{ position: 'absolute', right: 0, top: 0 }} control={<Switch color="primary" checked={state.enabled} onChange={(ev) => {
          console.log("toggle", state)
          if (state.enabled === false) {
            toggleIncomingCheckbox(ev)
          } else {
            disableField(ev)
          }
        }} defaultChecked />} label={(state.enabled === true ? "Enabled" : "Disabled")} />





      </Typography>

      {state.enabled && <>




        {type === 'number' && <VipProvisioningFieldTextMui type='number' dataField={dataField} value={data} setValue={(value) => {
          handleTextChange(value)
        }} />}


        {type === 'string' && <VipProvisioningFieldTextMui type='text' dataField={dataField} value={data} setValue={(value) => {
          handleTextChange(value)
        }} />}



        {type === 'boolean' && <VipProvisioningFieldCheckboxMui dataField={dataField} value={data} setValue={(value) => {
          handleTextChange(value)
        }} />} 


        {type === 'select' && <Select
          variant='outlined'
          fullWidth
          id={dataField}
          value={data}
          onChange={handleSelectChange}
        >
          {state.selectOptions.map(MakeItem)}
        </Select>}


        {type === 'checkBoxList' && <FormGroup row>

          {payArray.map(pp => {
            let checked = false;
            if (pp.enabled && pp.enabled === true) {
              checked = pp.enabled;
            }
            return (<FormControlLabel
              key={dataField + pp.id}
              control={
                <Checkbox
                  id={dataField + pp.id}
                  color='primary'
                  checked={checked}
                  onChange={(event: any) => {
                    event.preventDefault();
                    updateCheckBoxList(pp);
                  }}
                />
              }
              label={`${pp.name} - ${checked === true ? "Enabled" : "Disabled"}`}
            />
            );
          })}
        </FormGroup>}




        {type === "CustomStatuses" && <Box>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell align="left">Message</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {aArray?.statuses?.map((aa: any) => {
                  return <TableRow key={aa.id}>
                    <TableCell align="left">{aa.status}</TableCell>
                    <TableCell align="left">{aa.message}</TableCell>
                    <TableCell align="right">

                      <IconButton
                        color='secondary'
                        onClick={(event) => {
                          event.preventDefault();
                          deleteCustomStatus(aa.id);
                        }}>
                        <DeleteIcon />
                      </IconButton>

                    </TableCell>

                  </TableRow>;
                })}
              </TableBody>
            </Table>
          </TableContainer>


          <Box display="flex" style={{ marginTop: 4 }}>
            <Box width="40%" style={{ paddingRight: 3 }}>
              <Select
                fullWidth
                variant='outlined'
                value={status} id="status" onChange={handleChangeCustomStatusSelect}
              >
                <MenuItem value="Available">Available</MenuItem>
                <MenuItem value="Away">Away</MenuItem>
                <MenuItem value="Extended Away">Extended Away</MenuItem>
                <MenuItem value="DND">DND</MenuItem>
                <MenuItem value="Unavailable">Unavailable</MenuItem>
              </Select>
            </Box>
            <Box width="40%" style={{ paddingRight: 3 }}>
              <TextField
                fullWidth
                type="text"
                placeholder={title}
                value={message}
                id="message"
                onChange={handleLocalChange}
                variant="outlined"
                size="small"
              />
            </Box>
            <Box flexGrow={1}>
              <Button
                color='primary'

                fullWidth
                onClick={(event: any) => {
                  event.preventDefault();
                  updateCustomStatus();
                }} variant="outlined">
                Add
              </Button>
            </Box>

          </Box>
        </Box>}
      </>}
    </Box>

  }
const VipProvisioningFieldMui = memo(VipProvisioningFieldMui2, (a, b) => {
    return (a.data === b.data)
  })

  export default VipProvisioningFieldMui
export interface IMFAContext {
    label:string
    secret:string
    mfaAttemptToken:string
    loggedIn:boolean
}

const mfaContext:IMFAContext = {
    label:'',
    secret:'',
    mfaAttemptToken:'',
    loggedIn:false
};
export default mfaContext;
import React from "react";
import { Dropdown } from "react-bootstrap";

import { Link } from "react-router-dom";

export interface IBurgerOption {
  title: string;
  link?: string;
  isExternal: boolean;
  isDivider?: boolean;
  onClick?: any;
}
type TVipBurgerMenu = {
  options: IBurgerOption[];
};

const VipBurgerMenu: React.FC<TVipBurgerMenu> = ({ options }) => {
  return (
    <Dropdown alignRight>
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        <span className="mr-1">More options</span>
      </Dropdown.Toggle>
      {options.length >= 1 && (
        <Dropdown.Menu>
          {options.map((option, oIndex) => {
            if (option.isDivider) {
              return <Dropdown.Divider key={oIndex} />;
            } else if (option.isExternal) {
              //external link
              return (
                <Dropdown.Item href={option.link} target="_new" as="a">
                  {option.title}
                </Dropdown.Item>
              );
            } else if (option.link === undefined) {
              return (
                <Dropdown.Item
                  //   className="ml-n3"
                  key={option.title}
                  onClick={(event: any) => {
                    event.preventDefault();
                    if (option.onClick !== undefined) {
                      option.onClick();
                    }
                  }}
                >
                  {option.title}
                </Dropdown.Item>
              );
            } else {
              return (
                <Dropdown.Item key={option.link} to={option.link} as={Link}>
                  {option.title}
                </Dropdown.Item>
              );
            }
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};
export default VipBurgerMenu;

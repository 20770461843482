
import * as React from 'react';
import { Form } from 'react-final-form';
import { ButtonGroup} from 'react-bootstrap';
import arrayMutators from 'final-form-arrays'
import VipTextFinalField from '../../components/misc/form/VipTextFinalField';


const UpdateLimitsForm = ({ data, onSubmit }: any) => {

  return <Form
    onSubmit={onSubmit}
    initialValues={data}
    mutators={{
      ...arrayMutators
    }}
    render={({ handleSubmit, form, submitting, pristine, values }) => (

      <form onSubmit={handleSubmit}>

        <div className="text-left test-service-Call-Recording">
          <h2>Update Limits</h2>

          <div className="form-group">
            <label>Low Level Alert</label>
            <VipTextFinalField fieldName="lowLevelAlert" placeholder="£" required={true} type="number" />
          </div>


          <div className="form-group">
            <label>Cut of level</label>
            <VipTextFinalField fieldName="cutOfLevel" placeholder="" required={true} type="number" />
          </div>

          <ButtonGroup>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={submitting || pristine}>Submit</button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={form.reset}
              disabled={submitting || pristine}>Reset</button>
          </ButtonGroup>





        </div>
      </form>
    )} />
}

export default UpdateLimitsForm;



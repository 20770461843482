import React from "react";

const FormGroupAdapter = ({ input, inputOnChange, placeholder, meta }: any) => {
  const inputProps = {
    ...input,
    onChange: (e: any) => {
      input.onChange(e);
      inputOnChange && inputOnChange(e);
    },
  };

  return (
    <>
      <label htmlFor="file-upload" className="custom-file-upload">
        Upload Form
      </label>

      <input
        {...inputProps}
        type="file"
        placeholder={placeholder}
        id="file-upload"
        // className="btn btn-outline-primary form-control-file"
      />
      {meta.error && meta.touched && (
        <span className="invalid">{meta.error}</span>
      )}
      {meta.valid && meta.dirty && <span className="valid">Great!</span>}
    </>
  );
};

export default FormGroupAdapter;

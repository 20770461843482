import React from "react";

import { TextField, Typography } from "@material-ui/core";
import { Field, FormSpy } from "react-final-form";
import requiredValidator from "../misc/form/requiredValidator";

interface ITextInputProps {
  name: string;
  type?: string;
  label?: string;
  outlinedLabel?: string;
  required?: boolean;
  style?:any;
  [x: string]: any;

}

const MuiTextInput: React.FC<ITextInputProps> = ({
  name,
  type = "text",
  label,
  outlinedLabel,
  required,
  style = {},
  ...rest
}) => {

  const addFieldParams:any = { };

  if(required){
    addFieldParams.validate = requiredValidator;
  }
  return (
    <FormSpy
      subscription={{
        dirtySinceLastSubmit: true,
        submitting: true,
      }}
    >
      {({ dirtySinceLastSubmit, submitting }) => (
        <>
          {label && <Typography variant="subtitle1">{label}</Typography>}
          <Field name={name} {...addFieldParams}>
            {({ input, meta }) => (
              <TextField
                {...input}
                name={name}
                fullWidth
                type={type}
                variant="outlined"
                label={outlinedLabel}
                size="small"
                style={style}
                {...rest}
                error={
                  (meta.error && meta.touched) ||
                  (!!meta.submitError && !dirtySinceLastSubmit && !submitting)
                }
                helperText={
                  meta.error && meta.touched
                    ? meta.error
                    : !!meta.submitError && !dirtySinceLastSubmit && !submitting
                    ? meta.submitError
                    : ""
                }
              />
            )}
          </Field>
        </>
      )}
    </FormSpy>
  );
};
export default MuiTextInput;

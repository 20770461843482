
import * as React from 'react';

import { Field, Form } from 'react-final-form';
import makeId from '../../components/misc/form/makeId';

import VipTextFinalField from '../../components/misc/form/VipTextFinalField';
import { IAccessGroup } from '../../interfaces/access-group';
// ({ data, onSubmit }: any)




export interface IPermissionPopupFormProps {
    data: any;
    onSubmit: any;
    default: IAccessGroup;
}

export interface IPermissionPopupFormState {
    data: any;

    visible: boolean;
}

export default class PermissionPopupForm extends React.Component<IPermissionPopupFormProps, IPermissionPopupFormState> {
    public state: any = {
        data: {},
        visible: false,
    };

    public setVisible = (bool: boolean, value?: any) => {

        let stateUp: any = {}
        stateUp.visible = bool;
        stateUp.data = {};
        if (value !== undefined) {
            stateUp.data = value;
        } else {
            stateUp.data = { ...this.props.default }
        }
        this.setState(stateUp);
    }

    constructor(props: any) {
        super(props);
        console.log("notusless");
    }

    public submit = (data: any) => {
        this.setState({ visible: false });
        this.props.onSubmit('newPermission', data)
    }
    public render() {
        console.log(this.props)
        if (this.state.visible === false) {
            return <></>
        } else {
            return <div className="overlay">

                <div className="overlay-content">

                    <Form
                        onSubmit={this.submit}
                        initialValues={this.state.data}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (

                            <form onSubmit={handleSubmit}>

                                <div className="popupContent text-left test-new-customer-start WHITEN">

                                    <fieldset>
                                        <legend>Group Details</legend>

                                        <div className="form-group">
                                            <label>Name</label>
                                            <VipTextFinalField fieldName="name" placeholder="" required={true} />

                                        </div>

                                        <div className="form-group">
                                            <label>Description</label>

                                            <VipTextFinalField fieldName="description" placeholder="" required={true} />


                                        </div>
                                        {Object.keys(values.permissions).map((perm: string, pIndex: number) => {
                                            return <div key={pIndex} className="form-group col">
                                                <label>{perm}</label>

                                                <div className="row">


                                                    {Object.keys(values.permissions[perm]).map((thing: string, tIndex: number) => {
                                                        const nameCapitalized = thing.charAt(0).toUpperCase() + thing.slice(1)

                                                        const nnn: string = `permissions[` + perm + `][` + thing + `]`;
                                                        return <div key={tIndex} className="form-group form-check col-3">
                                                            <Field name={nnn} component="input" type="checkbox">
                                                                {({ input, meta }) => (
                                                                    <div>
                                                                        <input {...input} id={input.name.replace('.', '_')} className="form-check-input" autoComplete={"autocomplete_off_hack_" + makeId(5)} />
                                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                    </div>
                                                                )}
                                                            </Field>
                                                            <label className="form-check-label">{nameCapitalized}</label>
                                                        </div>

                                                    })}
                                                </div>

                                            </div>

                                        })}


                                    </fieldset>

                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={submitting || pristine}>{values.index ? (<>Save Group</>):(<>Save Group</>)}</button>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => this.setState({ visible: false })}
                                    >Cancel</button>



                                </div>
                            </form>
                        )} />

                </div></div>

        }

    }
}




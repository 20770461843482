
import * as React from 'react';


const PrepayTransactions = ({ data, onSubmit }: any) => {
//console.log(data);

  return <>{JSON.stringify(data)}</>
}

export default PrepayTransactions;



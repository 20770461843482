import React from "react";

import { Form } from "react-final-form";
import VipTextFinalField from "../misc/form/VipTextFinalField";
import { ICustomer, ICustomerUpdateRequest } from "../../interfaces/customer";

interface ISidebarLimitsProps {
  data:ICustomer,
  onSubmit:(update:ICustomerUpdateRequest)=>void
}

const SidebarLimits = (props:ISidebarLimitsProps) => {
  const onSubmit = (data:any) => {
    if(typeof data.monthlyAlertLimit==='string'){
      data.monthlyAlertLimit=parseFloat(data.monthlyAlertLimit)
    }
    if(isNaN(data.monthlyAlertLimit)){
      data.monthlyAlertLimit=0
    }
    props.onSubmit(data)
  }

  const values={
    id:props.data.id,
    dailyCallSpendLimit:props.data.dailyCallSpendLimit,
    monthlyAlertLimit:props.data.monthlyAlertLimit,
    dailyCallHardSpendLimit:props.data.dailyCallHardSpendLimit
  }
  return (
    <div className="sidebarDiv">
      <Form
        onSubmit={onSubmit}
        initialValues={values}
        render={({ handleSubmit, submitting, pristine}) => (
          <form onSubmit={handleSubmit}>
            <div className="sidebarLimitsDivider">
              <div className="sidebarGreyText">
                Amount the customer can spend on calls in a 24 hour period
                before you are alerted. Maximum &pound;200.
              </div>

              <VipTextFinalField
                fieldName="dailyCallSpendLimit"
                type="number"
                placeholder="100.00"
                required={true}
              />
            </div>

            <div className="sidebarLimitsDivider">
              <div className="sidebarGreyText">
                Amount the customer can spend on calls in a month before you are alerted.
              </div>

              <VipTextFinalField
                fieldName="monthlyAlertLimit"
                type="number"
                placeholder="300.00"
                required={false}
              />
            </div>


            <div className="sidebarGreyText">
              Amount the customer can spend on calls in a 24 hour period before
              they are cut off. Maximum &pound;200.
            </div>

            <VipTextFinalField
              fieldName="dailyCallHardSpendLimit"
              type="number"
              placeholder="200.00"
              required={true}
            />

            <button
              className="btn btn-outline-primary sidebarSubButton"
              type="submit"
              disabled={submitting || pristine}
            >
              Change Customer Limit
            </button>
          </form>
        )}
      />
    </div>
  );
};

export default SidebarLimits;

import { IBranding } from "./branding";
import { ITopMenuEntry } from "./menu-option";
import { IProvisioningSettings } from "./provisioning";

export interface ISelectOption {
    label:string
    value:string
  }


export enum CallRecordingsUnits {
    'Gigabytes' = 'gigabytes',
    'Months' = 'months'
}

export interface IVoipServiceLogins {
    datetime:string,
    ip:string,
    email:string
}

export interface IBundleMinutes {
    [key:string]:{
        available:number,
        used:number
    }
}

export interface IVoipService {
    id:string;
    siteName:string
    name:string;
    status:string;
    hostname:string
    vipHostname:string
    sipPort:string
    iaxPort:string
    tlsPort:string
    useCallerIdAsLabel:boolean
    provisioningSettings:IProvisioningSettings
    defaultSearchInDays:number
    measureSizeBy:CallRecordingsUnits
    excludedExtensions:string[]
    eurosoftCallRecordingFTPServerAddress:string
    eurosoftCallRecordingFTPServerPort:number
    eurosoftCallRecordingFTPServerUsername:string
    eurosoftCallRecordingFTPServerPassword:string
    eurosoftCallRecordingFTPServerPath:string
    eurosoftCallRecordingFTPServerUseFTPS:boolean
    callRecordingWebHookAddress:string
    callRecordingWebHookToken:string
    vaultGrowsIndefinitely:boolean
    vaultSize:number
    prepay:boolean
    prepayCutOffLimit:number;
    lowBalanceAlertThreshold:number;
    callRecordingTranscriptionEnabled?:boolean;
    grafanaHourlyEnabled?:boolean;
    extensionTypesToShowOnCallRecordingSearch?:string
    retailCurrentMonthCallChargesDate?:string
    retailCurrentMonthCallChargesPounds?:number
    logins:IVoipServiceLogins[]
    matchCraftEnabled:boolean
    matchCraftLogs:string[]
    callRecordingsAPILog:string[]
    pausedDateTime?:string
    pausedBy?:string
    canUnpauseDate?:string
    unpausedDateTime?:string
    unpausedBy?:string
    retailCurrentMonthBundleMinutes?:IBundleMinutes
}

export interface IVoipServicesRequest {
    services:IVoipService[];
}

export interface IVoipSession{
    activeCustomerID?:string;
    activeCustomerName?:string;
    loggedIn:boolean;
    userName:string;
    branding?:IBranding;
    emailAddress: string;
    userType: string;
    usesMFA:boolean;
    oldPortalLink:string;
    topMenu:ITopMenuEntry[]
    canSeeCustomerProfile:boolean;
}

export interface IFileUpload {
    base64:string
    filename:string
}

export interface IBuyPhonesLine {
    rentalPeriod:string
    psusRequired:boolean
    product:string //the product id
    quantity:number
}
import React from "react";
import image from "../compiledSVG/undraw_people_search_wctu.svg";

const pStyle = {
  fontSize: "16px",
  color: "#5b6688",
  marginTop: "1rem",
};

const imgStyle = {
  marginTop: "4rem",
  marginBottom: "2rem",
};

const NotFound: React.FC = () => {
  return (
    <div className="text-center">
      <img
        style={imgStyle}
        src={image}
        className="img-fluid"
        alt="No Results Found"
      />

      <h2>Page Not Found</h2>
      <p style={pStyle}>
        The page you were looking for could not be found. Please click the back
        button in your browser.
      </p>
    </div>
  );
};

export default NotFound;

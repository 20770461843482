import React from "react";

import { Field } from "react-final-form";
import { FormControlLabel, Checkbox, Box } from "@material-ui/core";

interface ICheckboxListEntry {
  label: string;
  value: string;
}

interface ICheckboxListProps {
  data: ICheckboxListEntry[];
  fieldName: string;
  required?: boolean;
}

const MuiCheckboxList = ({
  data,
  fieldName,
  required = false,
}: ICheckboxListProps) => {
  return (
    <Box display="flex" flexDirection="column">
      {data.map((entry: ICheckboxListEntry) => (
        <Field name={fieldName} value={entry.value} type="checkbox" key={entry.value}>
          {({ input }) => (
            <FormControlLabel
              control={
                <Checkbox {...input} color="primary" required={required} />
              }
              label={entry.label}
            />
          )}
        </Field>
      ))}
    </Box>
  );
};

export default MuiCheckboxList;

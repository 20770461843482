import React from "react";

import { Button, Dropdown, Table } from "react-bootstrap";

import { Field } from "react-final-form";
import FormGroupAdapter from "../../components/FormGroupAdaptor";
import { IFileUpload } from "../../interfaces/interfaces";
import { FieldArray } from "react-final-form-arrays";
import VipTextFinalField from "../../components/misc/form/VipTextFinalField";

export interface NewNumberPortPopupProps {}

const NewNumberPopup: React.FC<any> = ({ form, push, values, setVisible }) => {
  return (
    <div className="overlay">
      <div className="overlay-content">
        <div className="popupContent text-left test-new-customer-start WHITEN">
          <fieldset className="mainContent">
            <legend>Request Number Port</legend>
            <p className="mb-2">Please upload a completed number port form.</p>
            <div className="mb-2">
              <a href="/voip/src/templates/NumberPortTemplate.docx" download>
                Download Template
              </a>
            </div>
            <div className="form-group">
              <Field
                name="newFile"
                component={FormGroupAdapter}
                inputOnChange={(sdata: any) => {
                  const reader = new FileReader();
                  const file = sdata.target.files[0];

                  reader.readAsDataURL(file);
                  reader.addEventListener("load", function () {
                    if (typeof reader.result === "string") {
                      const numberPortTemplate: IFileUpload = {
                        base64: reader.result,
                        filename: file.name,
                      };
                      form.change("numberPortTemplate", [numberPortTemplate]);
                    }
                  });
                }}
                placeholder="Upload file"
              />
              {values.numberPortNumbers && (
                <Table className="mt-2">
                  <thead>
                    <tr>
                      <th className="asLabel">Number</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name="numberPortNumbers">
                      {({ fields }) =>
                        fields.map((number, index) => (
                          <tr key={index + number}>
                            <td>{fields.value[index]}</td>
                            <td>
                              <Dropdown className="float-right">
                                <Dropdown.Toggle
                                  variant="link"
                                  className="button__options"
                                  id="dropdown-basic"
                                ></Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => fields.remove(index)}
                                  >
                                    Remove
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))
                      }
                    </FieldArray>
                  </tbody>
                </Table>
              )}
              <br />
              <label className="my-2">
                Please list the number your customer wants to port.
              </label>

              <VipTextFinalField
                fieldName="numberPortNumber"
                placeholder=""
                required={false}
              />
            </div>
            <div className="form-group">
              <Field name="miltiLineNumber" component="input" type="checkbox" />
              <label className="ml-2">Is this a multi line number?</label>
              <p>
                A 'Multi Line' number is a phone number that is associated with
                more than one physical line. A single line number is only
                associated with one physical line.
              </p>
            </div>
          </fieldset>
          <div className="bottomButtons">
            <Button
              type="button"
              onClick={() => {
                if(values.numberPortNumber && values.numberPortNumber.length>0){
                  let newNumber=values.numberPortNumber.replace(/[^0-9]/g,'')
                  if(newNumber.length>0){
                    form.change("numberPortNumber", undefined);
                    push("numberPortNumbers", values.numberPortNumber);
                  }else{
                    alert('Please enter a valid number.')
                  }
                }else{
                  alert('Please enter a valid number.')
                }
              }}
            >
              {values.numberPortNumbers && values.numberPortNumbers.length > 0
                ? "Add More Numbers"
                : "Add Number"}
            </Button>

            <Button
              type="button"
              variant="outline-primary"
              className="button__back"
              onClick={() => setVisible()}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewNumberPopup;

import React, { useEffect, useRef, useState } from "react";

import { Col, ButtonGroup } from "react-bootstrap";

import PageHead from "../../components/PageHead";
import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";
import MySettings from "../../static/settings";
import { Link, RouteComponentProps } from "react-router-dom";

import {
  IInvoicesGetResponse,
  IResellerInvoice,
} from "../../interfaces/reseller-invoice";
import ResellerInvoicesAndCreditNotesSearchForm, {
  IInvoiceSearchForm,
} from "./ResellerInvoicesAndCreditNotesSearchForm";
import Error from "../../components/error";
import { addMonths, format } from "date-fns";

const set = new MySettings();

export interface IResellerInvoicesPageState {
  loading: boolean;
  error: boolean;
  displayEditForm: boolean;
  results: IResellerInvoice[];
  search: IInvoiceSearchForm;
}

interface IResellerInvoicesPage extends RouteComponentProps<any>, IPageProps {}

const ResellerInvoicesPage: React.FC<IResellerInvoicesPage> = (props) => {
  const [invoices, setInvoices] = useState<IResellerInvoice[]>([]);
  const initialSearch = useRef({
    fromDate: addMonths(new Date(), -1),
    toDate: new Date(),
    number: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    handleSearch(initialSearch.current);
  }, []);

  const handleSearch = async (searchParams: IInvoiceSearchForm) => {
    try {
      setLoading(true);

      const response: IInvoicesGetResponse = await set.invoicesGet(
        format(searchParams.fromDate, "yyyy-MM-dd"),
        format(searchParams.toDate, "yyyy-MM-dd")+'T24',
        searchParams.number
      );

      setInvoices(response.invoices);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const col = [
    {
      Header: "Number",
      accessor: "displayNumber",
      sortable: true,
    },
    {
      sortable: true,
      Header: "Date",
      cssClass: "text-left",
      accessor: "date", // String-based value accessors!
      Cell: (props: any) => {
        //   console.log(props.value);
        let explode2 = props.value.split("T")[0];
        let explode1 = explode2.split("-");

        return (
          <>
            {explode1[2]}/{explode1[1]}/{explode1[0]}{" "}
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      sortable: true,
    },
    {
      Header: "Gross",
      accessor: "totalValue",
      sortable: true,
      decimalPad: true,
    },
    {
      Header: "Net",
      accessor: "netValue",
      sortable: true,
      decimalPad: true,
    },
    {
      Header: "Outstanding",
      accessor: "outstanding",
      sortable: true,
      decimalPad: true,
    },
    {
      Header: "Payment",
      accessor: "paymentType",
      sortable: true,
    },
    {
      Header: "",
      accessor: "id", // String-based value accessors!
      Cell: (props: any) => {
        //     console.log(props);
        const invoice = "/invoice/" + props.value;

        return (
          <ButtonGroup aria-label="Basic example">
            <Link to={invoice} className="btn btn-info">
              View Invoice
            </Link>
          </ButtonGroup>
        );
      },
    },
  ];

  return (
    <div className="content">
      <Col md="12" className="text-left">
        <PageHead
          resellerID={props.resellerID}
          isHome={false}
          customersMode={false}
          title="Invoices"
        ></PageHead>

        {error ? (
          <Error />
        ) : (
          <>
            <ResellerInvoicesAndCreditNotesSearchForm
              data={initialSearch.current}
              onSubmit={handleSearch}
            />
            {loading ? (
              <Loading />
            ) : (
              <VoipTable
                title="Invoices"
                data={invoices}
                col={col}
                multiSelect={false}
              />
            )}
          </>
        )}
      </Col>
    </div>
  );
};

export default ResellerInvoicesPage;

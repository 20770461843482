import React, { useState } from "react";

import { Form } from "react-final-form";

import {
  IProvisioningSettingsCustomField, ProvisioningSettingsCustomFieldDevice, ProvisioningSettingsCustomFieldType
} from "../interfaces/provisioning";
import VipProvisioningCustomField from "./VipProvisioningCustomField";
import MuiTextInput from "./MuiComponents/MuiTextInput";
import MuiSelect from "./MuiComponents/MuiSelect";
import { Box, Grid, Typography, Button } from "@material-ui/core";
export interface IVipProvisioningCustomFieldsProps {
  value: IProvisioningSettingsCustomField[];
  updateField: (field: any, payload: any) => void;
}

export interface IVipProvisioningCustomFieldsState {
  newField?: IProvisioningSettingsCustomField;
  fields: IProvisioningSettingsCustomField[];
}

const NewFieldForm = ({ data, onSubmit, cancelField }: any) => {
  let title: string;
  if (data.isNew === true) {
    title = "New Field";
  } else {
    title = data.name;
  }
  const initalValues: IProvisioningSettingsCustomField = { ...data };
  const types = Object.values(ProvisioningSettingsCustomFieldType);
  const devices = Object.values(ProvisioningSettingsCustomFieldDevice);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initalValues}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography variant="h6" style={{ position: 'relative', marginBottom: 10, marginTop: 10 }}>
              {title}
            </Typography>


            <Grid container>
              <Grid item sm={2} style={{ paddingRight: 5 }}>
                {initalValues.isNew === true && <>
                  <label>Name</label>

                  <MuiTextInput
                    name="name"
                    placeholder="Name"
                    required={true}
                  />
                </>}

              </Grid>

              <Grid item sm={2} style={{ paddingRight: 5 }}>
                <label>Label</label>

                <MuiTextInput
                  name="label"
                  placeholder="Label"
                  required={true}
                />
              </Grid>

              <Grid item sm={2} style={{ paddingRight: 5 }}>
                <label>Data Type</label>
                <MuiSelect name="type" options={types.map((ddd, i) => {
                  return { value: ddd, label: ddd }
                })} />

              </Grid>

              <Grid item sm={2} style={{ paddingRight: 5 }}>
                <label>Value</label>

                <MuiTextInput
                  name="value"
                  placeholder="Value"
                  required={false}
                />
              </Grid>


              <Grid item sm={2} style={{ paddingRight: 5 }}>
                <label>Device Type</label>
                <MuiSelect name="device" options={devices.map((ddd, i) => {
                  return { value: ddd, label: ddd }
                })} />

              </Grid>



              <Grid item sm={2} style={{ paddingRight: 5, textAlign: 'right', paddingTop: 30 }}>

                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  {initalValues.isNew === true ? "Add Field" : "Update Field"}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  type="button"
                  onClick={(ev) => {
                    ev.preventDefault();
                    cancelField();
                  }}
                >
                  Cancel
                </Button>
              </Grid>

            </Grid>
          </Box>
        </form>
      )}
    />
  );
};



const VipProvisioningCustomFields = ({ value, updateField }: IVipProvisioningCustomFieldsProps) => {
  const [state, setState] = useState<IVipProvisioningCustomFieldsState>(() => {
    return {
      newField: undefined,
      fields: value,
    }
  })
  const updateFieldInternal = (
    fieldName: any,
    original: IProvisioningSettingsCustomField,
    payload: any,
    f: any
  ) => {
    // console.log(
    //   "_______________________________________________________-updateField",
    //   fieldName,
    //   payload,
    //   original
    // );
    let clone = { ...original };
    clone.value = payload;
    let fields: any[] = [...state.fields];
    const index = fields.findIndex((f) => f.name === original.name);
    fields[index] = clone;
    setState({ ...state, fields: fields });
    updateField("customFields", fields);
  };


  const startNewField = () => {
    setState({
      ...state,
      newField: {
        overridden: true,
        isNew: true,
        name: "",
        label: "",
        type: ProvisioningSettingsCustomFieldType.STRING,
        value: "",
        device: ProvisioningSettingsCustomFieldDevice.YEALINK,
      },
    });
  };

  const deleteField = (src: IProvisioningSettingsCustomField) => {
    console.log(
      "_______________________________________________________-deleteField",
      src
    );

    let fieldss = state.fields;
    for (var i = 0; i < fieldss.length; i++) {
      if (fieldss[i].name === src.name) {
        fieldss.splice(i, 1);
        break;
      }
    }
    setState({ ...state, fields: fieldss });
  };

  const makeNewField = (payload: IProvisioningSettingsCustomField) => {
    let fieldss: any = state.fields;
    if (fieldss == null || fieldss.map == null) {
      fieldss = [];
    }

    const index = fieldss.findIndex((df: any) => df.name === payload.name);
    if (index >= 0) {
      fieldss[index] = payload;
    } else {
      fieldss.push(payload);
    }
    setState({ ...state, fields: fieldss });
    updateField("customFields", fieldss);
  };

  const cancelField = () => {
    console.log("cancel");
    setState({ ...state, newField: undefined });
  };



  let { newField, fields } = state;
  if (fields == null || fields.map == null) {
    fields = [];
  }
  return <>
    {fields.map((value, i) => {
      //PBB 2024-7-05 we need a unique key but that key must not
      //get reused when we delete an entry so use the index plus the label
      const key=i+value.name
      let title = value.label
      if(value.name!==title){
        title = value.name + ' - ' + value.label
      }
      return (
        <VipProvisioningCustomField
          key={key}
          updateField={(fieldname: string, newValue: any) => {
            updateFieldInternal(fieldname, value, newValue, key);
          }}
          data={value}
          overridden={value.overridden}
          dataField={value.name}
          title={title}
          type={value.type}
          deleteField={deleteField}
        />
      );
    })}

    {newField !== undefined && (
      <NewFieldForm
        data={newField}
        onSubmit={makeNewField}
        cancelField={cancelField}
      />
    )}
    {newField === undefined && (
      <Button
        style={{ marginTop: 20 }}
        variant="outlined"
        color="primary"
        onClick={(ev: any) => {
          ev.preventDefault();
          startNewField();
        }}>
        New Field
      </Button>
    )}
  </>
}


export default VipProvisioningCustomFields
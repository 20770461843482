import React, { useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import MySettings from "../../static/settings";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import App from "../../App";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import {
  ICustomer,
  ICustomerUpdateRequest,
  IServiceWithSiteName,
} from "../../interfaces/customer";
import { INotification } from "../../interfaces/notification";
import Loading from "../loading";
import VipSwitch from "../VipSwitch";
import SidebarDeliveryAddress from "./SidebarDeliveryAddress";
import SidebarGeneralContact from "./SidebarGeneralContact";
import SidebarInvoiceDetails from "./SidebarInvoiceDetails";
import SidebarLimits from "./SidebarLimits";
import EditIcon from '@material-ui/icons/Edit';
import "../../sass/sideBar.scss";
import { IconButton } from "@material-ui/core";
import MuiModal from "../modal/MuiModal";
import VipSidebarCustomerNameForm from "./VipSideBarCustomerNameForm";
import VipSidebarArchiveCustomerForm from "./VipSideBarArchiveCustomerForm";

library.add(faChevronDown, faChevronUp);

const set = new MySettings();

type TVipSidebar = {
  resellerID: string;
  initialCustomer: any;
};

const VipSidebar: React.FC<TVipSidebar> = ({ resellerID, initialCustomer }) => {
  const [customer, setCustomer] = useState<any>(initialCustomer || undefined);
  const [accordionKey, setAccordionKey] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [titleModalOpen, setTitleModalOpen] = useState<boolean>(false);
  const [archiveCustomerModalOpen, setArchiveCustomerModalOpen] = useState<boolean>(false);

  const setAccordionTab = (tabID: string) => {
    if (accordionKey === tabID) {
      setAccordionKey("");
    } else {
      setAccordionKey(tabID);
    }
  };

  const onArchiveCustomerClick = () => {
    setArchiveCustomerModalOpen(true);
  }

  const onCancelArchiveCustomerClick = async () => {
    if (window.confirm("Are you sure you no longer want to archive this customer?")) {
      setIsLoading(true);
      try {
        let response = await set.resellerCustomerUpdate({id:customer.customer.id,archiveOnDate:''});
        setCustomer({customer:response,services:customer.services})
        window.scrollTo(0, 0);
        App.newNotificationSuccess('The customer will no longer be archived.')
      } catch (e) {
        let message='There was a problem cancelling the archiving.'
        if(e && e.data){
          message=message+' '+e.data
        }
        App.newNotificationError(message)
      }
      setIsLoading(false);
    }
  }

  const updateCustomerName = async (name: string) => {
    setLoading(true);

    try {
      let result = await set.resellerCustomerUpdate({ id: customer.customer.id, name });
      setCustomer({ customer: result, services: customer.services });
      setTitleModalOpen(false)

      const notification: INotification = {
        title: "Success",
        data: "Customer name updated.",
        variant: NOTIFICATION_TYPE.success,
        srcFunction: "componentDidUpdate",
        srcData: window.location.pathname,
        srcComponent: "App",
      };
      App.newNotification(notification);
    } catch (e:any) {
      let message='There was a problem updating the name of the customer.'
      if(e && e.data && e.data.length>0){
        message=' '+e.data
      }
      App.newNotificationError(message)
    }
    setLoading(false);
  };

  const updateCustomerData = async (data: ICustomerUpdateRequest) => {
    setLoading(true);
    let data2:any = {...data}
    if(data2.sendAllToCloudManage && data2.sendAllToCloudManage.length>0){
      data2.sendAllToCloudManage=true
    }else{
      data2.sendAllToCloudManage=false
    }
    try {
      let result = await set.resellerCustomerUpdate(data2);
      setCustomer({ customer: result, services: customer.services });
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };

  const togglePortalAccess = (value: boolean) => {
    const update: any = { id: customer.customer.id, portalAccess: value };
    updateCustomerData(update);
  };
  // const toggleNewPortal = (value: boolean) => {
  //   const update: any = { id: customer.customer.id, useNewPortal: value };
  //   updateCustomerData(update);
  // };

  
  //const toggleEnabled = (value: boolean) => {
  //  const update: any = { id: customer.customer.id, inactive: !value };
  //  updateCustomerData(update);
  //};


  const addServiceLink =
    "/reseller/" + resellerID + "/newService/" + resellerID;
  const tariff = "/customer/" + resellerID + "/tariff";
  // const customerHome = "/reseller/" + resellerID;
  return (
    <>
      {customer === undefined || loading ? (
        <Loading />
      ) : (
        <>
          <div className="sidebarBody">
            <div className="sidebarHead">
              
              {customer.customer.archivedDateTime ? (<div>
                <h4 className="sidebarHeading">{customer.customer.name}</h4>
                  Archived on {customer.customer.archivedDateTime.substring(0,10)}
                </div>) : (<>
                  <h4 className="sidebarHeading">{customer.customer.name}
                      <IconButton size="small" style={{ left: 0, bottom: 5, position: 'relative' }} onClick={() => {
                      setTitleModalOpen(true)
                    }}><EditIcon /></IconButton></h4>
                  <MuiModal open={titleModalOpen} title="Change Customer Name" onClose={() => {  setTitleModalOpen(false) }}>
                    <VipSidebarCustomerNameForm name={customer.customer.name} update={(name:string)=>{
                      setTitleModalOpen(false)
                      updateCustomerName(name)
                    }} />
                  </MuiModal>

                  <Link to={addServiceLink} className="btn btn-outline-primary">
                    Add Service
                  </Link>
                </>)}
            </div>

            <Accordion activeKey={accordionKey} className="full-width sidebar">
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="0"
                  onClick={() => setAccordionTab("0")}
                >
                  <>
                    {accordionKey === "0" ? (
                      <span className="sidebarDown" />
                    ) : (
                      <span className="sidebarUp" />
                    )}
                    Services
                  </>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                  <div className="sidebarDiv">
                    {customer.services.length > 0 ? (
                      <>
                        {customer.services
                          .sort(function (
                            a: IServiceWithSiteName,
                            b: IServiceWithSiteName
                          ) {
                            if (
                              a.name === "VoIP Service" &&
                              b.name !== "VoIP Service"
                            ) {
                              return -1;
                            } else if (
                              a.name === "VoIP Service" &&
                              b.name === "VoIP Service"
                            ) {
                              return a.dateCreated.localeCompare(b.dateCreated);
                            }
                            return 1;
                          })
                          .map((service: IServiceWithSiteName) => {
                            let link = "";
                            if (service.name === "VoIP Service") {
                              link =
                                "/reseller/" +
                                resellerID +
                                "/service/" +
                                service.id +
                                "/summary";
                            } else if (
                              service.name === "Internet Service Enquiry"
                            ) {
                              link =
                                "/reseller/" +
                                resellerID +
                                "/internetserviceenquiry/" +
                                service.id +
                                "/summary";
                            } else if (
                              service.name === "Teams Call Recording Service"
                            ) {
                              link =
                                "/reseller/" +
                                resellerID +
                                "/teamscallrecordingservice/" +
                                service.id +
                                "/summary";
                            }
                            // console.log("link", link);
                            // console.log("service", service);

                            return (
                              <div
                                key={service.id}
                                className="sidebarDivServices"
                              >
                                <div className="d-flex flex-rowd-flex justify-content-between align-text-bottom">
                                  <div className="sidebarServiceName">
                                    {service.name}
                                  </div>
                                  <div className="sidebarServiceStatus">
                                    {service.status}
                                  </div>
                                </div>
                                <p className="sidebarTitle">Site</p>
                                <p className="sidebarText">
                                  {service.siteName}
                                </p>
                                {service.name === "VoIP Service" && (
                                  <>
                                    <p className="sidebarTitle">Server</p>
                                    <p className="sidebarText" style={{whiteSpace:'nowrap'}}>
                                      {service.hostname}
                                    </p>
                                  </>
                                )}
                                <p className="sidebarTitle">Order Date</p>
                                <p className="sidebarText">
                                  {service.dateCreated.split("T")[0]}
                                </p>

                                {service.status !== "Completed" &&
                                  (service.name ===
                                    "Internet Service Enquiry" ? (
                                    <Link
                                      to={{
                                        pathname: link,
                                        state: service,
                                      }}
                                      className="btn btn-outline-primary sidebarSubButton"
                                    >
                                      Edit Service Details
                                    </Link>
                                  ) : service.name ===
                                    "Teams Call Recording Service" ? (
                                    <Link
                                      to={{
                                        pathname: link,
                                        state: service,
                                      }}
                                      className="btn btn-outline-primary sidebarSubButton"
                                    >
                                      Edit Service Details
                                    </Link>
                                  ) : link.length > 0 && (
                                    <Link
                                      to={link}
                                      className="btn btn-outline-primary sidebarSubButton"
                                    >
                                      Edit Service Details
                                    </Link>
                                  ))}
                              </div>
                            );
                          })}
                      </>
                    ) : (
                      <p>No services found</p>
                    )}
                  </div>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="1"
                  onClick={() => setAccordionTab("1")}
                >
                  <>
                    {accordionKey === "1" ? (
                      <span className="sidebarDown" />
                    ) : (
                      <span className="sidebarUp" />
                    )}
                    General Contact
                  </>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="1">
                  <SidebarGeneralContact
                    data={customer.customer}
                    onSubmit={updateCustomerData}
                  />
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="2"
                  onClick={() => setAccordionTab("2")}
                >
                  <>
                    {accordionKey === "2" ? (
                      <span className="sidebarDown" />
                    ) : (
                      <span className="sidebarUp" />
                    )}
                    Delivery Address
                  </>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="2">
                  <SidebarDeliveryAddress
                    data={customer.customer}
                    onSubmit={updateCustomerData}
                  />
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="3"
                  onClick={() => setAccordionTab("3")}
                >
                  <>
                    {accordionKey === "3" ? (
                      <span className="sidebarDown" />
                    ) : (
                      <span className="sidebarUp" />
                    )}
                    Invoice Details
                  </>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="3">
                  <SidebarInvoiceDetails
                    data={customer.customer}
                    onSubmit={updateCustomerData}
                  />
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="6"
                  onClick={(event) => setAccordionTab("6")}
                >
                  <>
                    {accordionKey === "6" ? (
                      <span className="sidebarDown" />
                    ) : (
                      <span className="sidebarUp" />
                    )}
                    Portal Access
                  </>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="6">
                  <>
                    <div className="sidebarSwitchDiv">
                      <label>Portal Access</label>
                      <VipSwitch
                        isOn={customer.customer.portalAccess}
                        handleToggle={() =>
                          togglePortalAccess(!customer.customer.portalAccess)
                        }
                      />
                    </div>

                    {/*PBB 2023-12-08 this seems redundant
                    customer.customer.portalAccess === true && (
                      <div className="sidebarSwitchDiv">
                        <label>Use new portal</label>
                        <VipSwitch
                          isOn={customer.customer.useNewPortal}
                          handleToggle={() =>
                            toggleNewPortal(!customer.customer.useNewPortal)
                          }
                        />
                      </div>
                        )*/}
                  </>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="7"
                  onClick={() => setAccordionTab("7")}
                >
                  <>
                    {accordionKey === "7" ? (
                      <span className="sidebarDown" />
                    ) : (
                      <span className="sidebarUp" />
                    )}
                    Limits
                  </>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                  <SidebarLimits
                    data={customer.customer}
                    onSubmit={updateCustomerData}
                  />
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="8"
                  onClick={() => setAccordionTab("8")}
                >
                  <>
                    {accordionKey === "8" ? (
                      <span className="sidebarDown" />
                    ) : (
                      <span className="sidebarUp" />
                    )}
                    Tariff
                  </>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8">
                  <div className="sidebarDiv">
                    <Link
                      to={tariff}
                      className="btn btn-outline-primary sidebarSubButton"
                    >
                      Change Tariff
                    </Link>
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            {/* 
            <div className="sidebarSwitchDiv">
              <label>Active customer</label>
              <VipSwitch
                isOn={!customer.customer.inactive}
                handleToggle={() => toggleEnabled(customer.customer.inactive)}
              />
            </div>
            */}
            {isLoading ? (
              <Loading />
            ) : (
              <>
              {!customer.customer.archivedDateTime && (<>{customer.customer.archiveOnDate ? (
                <>
                Archive scheduled for {customer.customer.archiveOnDate}
                <br />by {customer.customer.archivedBy}
                <br />on {customer.customer.archivedSetDateTime.replace('T',' ')}.
                <Button style={{ marginTop: "20px" }}
                variant="outline-danger"
                type="button"
                onClick={onCancelArchiveCustomerClick}
              >
                Cancel Archiving
              </Button>
              </>
              ) : (
                <Button
                  style={{ marginTop: "20px" }}
                  variant="outline-danger"
                  type="button"
                  onClick={onArchiveCustomerClick}
                >
                  Archive Customer
                </Button>
              )}</>)}
              
              </>
            )}

            <MuiModal open={archiveCustomerModalOpen} title="Archive Customer" onClose={() => {  setArchiveCustomerModalOpen(false) }}>
              <VipSidebarArchiveCustomerForm customersId={customer.customer.id} update={(result:ICustomer)=>{
                  setCustomer({customer:result,services:customer.services})
              }} setArchiveCustomerModalOpen={setArchiveCustomerModalOpen} />
            </MuiModal>
          </div>
        </>
      )}
    </>
  );
};

export default VipSidebar;

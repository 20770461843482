import * as React from "react";
import { Card } from "react-bootstrap";
import { Form } from "react-final-form";

import VipTextFinalField from "../../components/misc/form/VipTextFinalField";

const ProfileUserPasswordForm = ({ data, onSubmit }: any) => {
  // console.log(data);
  //   const initalValues: any = {
  //     currentPassword: "",
  //     newPassword: "",
  //     newPassword2: "",
  //   };

  return (
    <Form
      onSubmit={onSubmit}
      //   initialValues={initalValues}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Card.Body>
            <label>Please enter your current password</label>

            <VipTextFinalField
              fieldName="currentPassword"
              placeholder="Current Password"
              type="password"
              required={true}
            />

            <label>Now enter your new password</label>

            <VipTextFinalField
              fieldName="newPassword"
              placeholder="New Password"
              type="password"
              required={true}
            />

            <label>Please enter your new password again</label>

            <VipTextFinalField
              fieldName="newPassword2"
              placeholder="Re-Enter Password"
              type="password"
              required={true}
            />
          </Card.Body>

          <Card.Footer className="form-buttons">
            <button
              className="btn btn-primary noleft"
              type="submit"
              disabled={submitting || pristine}
            >
              Submit
            </button>
          </Card.Footer>
        </form>
      )}
    />
  );
};

export default ProfileUserPasswordForm;

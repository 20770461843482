import React, { useState } from "react";
import Collapse from '@material-ui/core/Collapse';
interface ReviewProps {
  header: string;
  content: JSX.Element | string;
  initalOpen?: boolean;
}

const ReviewCollapse: React.FC<ReviewProps> = ({ header, content, initalOpen = true }) => {
  const [open, setOpen] = useState(initalOpen);

  const style = {
    border: "0px",
    outline: "0px",
    backgroundColor: "#ffffff",
  };

  return (
    <div>
      <button
        style={style}
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
        aria-controls="collapse-text"
        aria-expanded={open}
      >
        <div className="wizard8__permissions">
          <div className="wizard8__right-heading-div">
            <p className={open ? "wizard8__right-p" : "wizard8__right-p2"}>
              {header}
            </p>
          </div>
        </div>
      </button>
      <Collapse in={open}>
        <div id="collapse-text">{content}</div>
      </Collapse>
    </div>
  );
};

export default ReviewCollapse;

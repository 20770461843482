
import React from "react";
import {  FormSpy } from "react-final-form";


import {Paper,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

import MuiTextInput from "../../../components/MuiComponents/MuiTextInput";

import { FieldArray } from "react-final-form-arrays";

const EhaForm = () => {



  return <>


    <FormSpy
      subscription={{
        // dirtySinceLastSubmit: true,
        // submitting: true,

        values: true
      }}
    >
      {({ values, form }) => (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Number</TableCell>
                  <TableCell>Contact Info</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <FieldArray name="toEHA">
                  {({ fields }) =>
                    fields.map((number, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{values.toEHA[index] && values.toEHA[index].number && values.toEHA[index].number.name}</TableCell>
                        <TableCell>
                          <MuiTextInput
                            style={{ paddingBottom: 10 }}
                            name={`${number}.number.ehaName`}
                            outlinedLabel="Name"
                            required={true}
                          />

                          <br />
                          <MuiTextInput
                            style={{ paddingBottom: 10 }}
                            name={`${number}.number.ehaPremises`}
                            outlinedLabel="Building name or number"
                            required={true}
                          />
                          <br />
                          <MuiTextInput

                            style={{ paddingBottom: 10 }}
                            name={`${number}.number.ehaThoroughfare`}
                            outlinedLabel="Street"
                            required={true}
                          />
                          <br />
                          <MuiTextInput
                            style={{ paddingBottom: 10 }}
                            name={`${number}.number.ehaLocality`}
                            outlinedLabel="Town or city"
                            required={true}
                          />
                          <br />
                          <MuiTextInput
                            style={{ paddingBottom: 10 }}
                            name={`${number}.number.ehaPostcode`}
                            outlinedLabel="Postcode"
                            required={true}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </FieldArray>
              </TableBody>
            </Table>
          </TableContainer>


        </>)}
    </FormSpy>

  </>
}

export default EhaForm;
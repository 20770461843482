  import { Box, Button, Checkbox, IconButton, Menu, MenuItem } from "@material-ui/core";

import { IExtension } from "../interfaces/extension";
import { useState } from "react";
import { INumber } from "../interfaces/number";
import DeleteIcon from '@material-ui/icons/Delete';

const ITEM_HEIGHT = 48;
interface VipExtensionSelectorProps {
  extensions: IExtension[] | INumber[];
  field: string;
  buttonText: string;
  value: string[];

  updateField: (field: any, payload: any) => void;
}

const VipExtensionSelector = ({ buttonText, extensions, field, value, updateField}: VipExtensionSelectorProps) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [array, setArray] = useState<string[]>(() => {
    if (value) {
      return value;
    } else {
      return [];
    }
  });
  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeFromArray = (index: number) => {
    const clone = [...array];
    clone.splice(index, 1)

    setArray(clone);
    updateField(field, clone)
  }
  const handleCheck = (exten: IExtension | INumber) => {
    const cloneArray = [...array];


    const index = cloneArray.indexOf(exten.name)
    console.log(index)
    if (index >= 0) {
      // remove from array
      cloneArray.splice(index, 1);
    } else {
      cloneArray.push(exten.name)
    }
    setArray(cloneArray)
    updateField(field, cloneArray);

  }

  return <>


    <Box style={{ textAlign: 'center' }}>
      <Button variant="outlined" color="primary" onClick={handleClick}>
        {buttonText}

      </Button>



      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6.5,
            //width: '25ch',
          },
        }}
      >
          <Box sx={{mr:2, ml:2}}>
            <Button variant="outlined" fullWidth sx={{}} color="primary" onClick={handleClose}>
              Save Changes            
            </Button>
          </Box>
             {extensions.sort((a, b) => { return a.name.localeCompare(b.name) }).map((d, index) => {
          const checked = (array && array.indexOf(d.name) >= 0 ? true : false);

          return <MenuItem key={index} onClick={() => {
            handleCheck(d)
          }}>
            <Checkbox
              checked={checked}
              onChange={(e) => {
                e.preventDefault();
                handleCheck(d)

              }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />

            {d.smdrName && d.smdrName} {d.name}</MenuItem>
        })}

      </Menu>
    </Box>
    {array.map((name, index) => {


      const found = extensions.find(d => d.name === name)

      return <Box component={'div'} key={index} display="flex">
        <Box p={1} flexGrow={1}>
          {found && found.smdrName && found.smdrName} {name}
        </Box>
        <Box component={'div'} p={0}>
          <IconButton size="small" color="secondary" onClick={(ev) => {
            ev.preventDefault();
            removeFromArray(index)
          }}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    })}
  </>
}


export default VipExtensionSelector;

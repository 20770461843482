
import * as React from 'react';

import { Field, Form } from 'react-final-form';
import makeId from '../../components/misc/form/makeId';

import VipTextFinalField from '../../components/misc/form/VipTextFinalField';



export interface IAddUserPopupProps {
    data: any;
    onSubmit: any;

}

export interface IAddUserPopupState {
    data: any;
    loading: boolean;
    supportData: any;
    visible: boolean;
}

export default class AddUserPopup extends React.Component<IAddUserPopupProps, IAddUserPopupState> {
    public state: any = {
        supportData: {},
        data: {},
        loading: false,
        visible: false,
    };

    public setVisible = (bool: boolean, stuff: any, value?: any) => {

        let stateUp: any = {}
        stateUp.visible = bool;
        stateUp.supportData = stuff;
        stateUp.data = {};
        if (value !== undefined) {
            stateUp.data = value;
        }
        this.setState(stateUp);
    }

    // eslint-disable-next-line 
    constructor(props: any) {
        super(props);
    }

    public submit = (data: any) => {
        this.setState({ visible: false });
        this.props.onSubmit('newUser', data)
    }
    public render() {
        console.log(this.props)
        if (this.state.visible === false) {
            return <></>
        } else {
            return <div className="overlay">

                <div className="overlay-content">

                    <Form
                        onSubmit={this.submit}
                        initialValues={this.state.data}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (

                            <form onSubmit={handleSubmit}>


                                <div className="popupContent text-left test-new-customer-start WHITEN">

                                    <fieldset>
                                        <legend>User</legend>


                                        <div className="form-group">
                                            <label>Salutation Or Title (Mr/Mrs)</label>

                                            <VipTextFinalField fieldName="salutation" placeholder="" required={false} />

                                        </div>
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <VipTextFinalField fieldName="firstName" placeholder="" required={true} />
                                        </div>

                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <VipTextFinalField fieldName="lastName" placeholder="" required={true} />
                                        </div>

                                        <div className="form-group">
                                            <label>User Type</label>
                                            <Field component="select" name="permissionIndex">
                                                {({ input, meta }) => (
                                                    <div>
                                                        <select {...input} id={input.name.replace('.', '_')} autoComplete={"autocomplete_off_hack_" + makeId(5)} className="form-control">
                                                            <option></option>
                                                            {this.state.supportData.permissions.map((map: any, index: number) => {
                                                                return <option key={index} value={index}>{map.name}</option>
                                                            })}
                                                        </select>
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>

                                        <div className="form-group">
                                            <label>Email Address</label>

                                            <VipTextFinalField fieldName="emailAddress" placeholder="" required={true} />


                                        </div>


                                        <div className="form-group">
                                            <label>Phone number</label>

                                            <VipTextFinalField fieldName="telephone" placeholder="" required={true} />


                                        </div>


                                        <div className="form-group">
                                            <label>Site</label>
                                            <Field component="select" name="siteIndex">
                                                {({ input, meta }) => (
                                                    <div>
                                                        <select {...input} id={input.name.replace('.', '_')} autoComplete={"autocomplete_off_hack_" + makeId(5)} className="form-control">
                                                            {this.state.supportData.sites.map((map: any, index: number) => {
                                                                return <option key={index} value={index}>{map.description}</option>
                                                            })}
                                                            <option value="notSite">Not At Site</option>
                                                        </select>
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>




                                        {/*values.siteIndex === 'notSite' && <>
                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <label className="form-label">Postcode</label>

                                                    <VipTextFinalField fieldName="postCode" placeholder="" required={true}></VipTextFinalField>
                                                </div>
                                                <button onClick={(e) => {
                                                    e.preventDefault();
                                                    console.log(values)
                                                    if (!values.postCode || values.postCode === '') {
                                                        alert("Please enter a post code")
                                                    } else {
                                                        form.change('looking_lookup', true);
                                                        setTimeout(() => {
                                                            set.postCodeLookUp(values.postCode).then(res => {
                                                                console.log(res);
                                                                form.change('looking_lookup', false);
                                                                form.change('lookup', res)
                                                            }).catch(err => {
                                                                form.change('manualAddress', true)
                                                                form.change('looking_lookup', false);
                                                                alert("Lookup Failed, please enter manually");
                                                            })
                                                        }, 2000)
                                                    }


                                                }} className="find-address" type="button">
                                                    {values.looking_lookup === true ? (<LoadingSpin
                                                        duration="2s"
                                                        width="2px"
                                                        timingFunction="ease-in-out"
                                                        direction="alternate"
                                                        size={"20px"}
                                                        primaryColor="#fff"
                                                        secondaryColor="#1D57D0"
                                                    />) : ('Find Address')}</button>
                                            </div>
                                            {values.lookup !== undefined && <>

                                                <div className="form-row">
                                                    <div className="form-group col">
                                                        <label className="form-label">Choose Address</label>
                                                        <Field component="select" name="lookupIndex">
                                                            {({ input, meta, inputOnChange }) => (
                                                                <>
                                                                    <select {...input} id={input.name.replace('.', '_')} className="form-control" autoComplete={"autocomplete_off_hack_" + makeId(5)} onChange={(evt: any) => {
                                                                        const val = evt.currentTarget.value;

                                                                        const chosen: any = values.lookup.items[val];


                                                                        console.log("selAddress", val, chosen);

                                                                        input.onChange(evt);
                                                                        inputOnChange && inputOnChange(evt);


                                                                        if (chosen === undefined) {
                                                                            form.change('line1', '')
                                                                            form.change('line2', '')
                                                                            //  form.change('postCode', '')
                                                                            form.change('county', '')
                                                                            form.change('city', '')
                                                                        } else {
                                                                            form.change('line1', chosen.line1)
                                                                            form.change('line2', chosen.line2)
                                                                            //    form.change('postCode', chosen.postCode)
                                                                            form.change('county', chosen.county)
                                                                            form.change('city', chosen.city)
                                                                        }
                                                                    }}>
                                                                        <option></option>
                                                                        {values.lookup.items.map((map: any, index: number) => {
                                                                            return <option key={index} value={index}>{map.line1}, {map.line2}, {map.city}</option>
                                                                        })}
                                                                    </select>
                                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                </>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                                    </>}
                                            {values.manualAddress !== true && <>
                                                <div className="form-row">
                                                    <p onClick={(e) => {
                                                        e.preventDefault();
                                                        form.change('manualAddress', !values.manualAddress)
                                                    }} className="enter-address-manual">Enter address manually</p>
                                                </div>
                                            </>}




                                            {values.manualAddress === true && <>




                                                <div className="form-row">
                                                    <div className="form-group col">
                                                        <label className="form-label">
                                                            Address line 1</label>

                                                        <VipTextFinalField fieldName="line1" placeholder="" required={false}></VipTextFinalField>
                                                    </div>
                                                </div>



                                                <div className="form-row">
                                                    <div className="form-group col">
                                                        <label className="form-label">Address line 2</label>
                                                        <VipTextFinalField fieldName="line2" placeholder="" required={false} />

                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col">
                                                        <label className="form-label">City</label>
                                                        <VipTextFinalField fieldName="city" placeholder="" required={false} />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col">
                                                        <label className="form-label">County</label>
                                                        <VipTextFinalField fieldName="county" placeholder="" required={false} />
                                                    </div>
                                                </div>

                                            </>}



                                            </>*/}
                                        <div className="form-group">
                                            <label>Portal Access</label>
                                            <Field name="portalAccess" component="input" type="checkbox" >
                                                {({ input, meta }) => (
                                                    <input type="checkbox" autoComplete={"autocomplete_off_hack_" + makeId(5)} {...input} id={input.name.replace('.', '_')} className="form-check-label ml-1"></input>

                                                )}
                                            </Field>

                                        </div>

                                        <div className="form-group">
                                            <label>Notify User</label>
                                            <Field name="multilineNumber" component="input" type="checkbox" >
                                                {({ input, meta }) => (
                                                    <input type="checkbox" autoComplete={"autocomplete_off_hack_" + makeId(5)} {...input} id={input.name.replace('.', '_')} className="form-check-label ml-1"></input>

                                                )}
                                            </Field>

                                        </div>

                                    </fieldset>

                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={submitting || pristine}>{values.index ? (<>Save User</>) : (<>Add User</>)}</button>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => this.setState({ visible: false })}
                                    >Cancel</button>





                                </div>
                            </form>
                        )} />

                </div></div>

        }

    }
}




import React, { ChangeEvent, useEffect, useState } from "react";
import MySettings from "../../static/settings";
import { INotification } from "../../interfaces/notification";
import App from "../../App";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import Loading from "../loading";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";


const set = new MySettings();
const GetSiteOptionsMui = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const [siteOptions, setSiteOptions] = useState<any[]>([]);
  const [customerID, setCustomerID] = useState<string>("");

  useEffect(() => {
    let associatedOnly = false;
    if (props.associatedOnly !== undefined) {
      if (
        props.userType !== undefined &&
        props.userType === "customersContacts"
      ) {
        associatedOnly = props.associatedOnly;
      }
    }
    let singleCustomerMode = false;

    if (props.singleCustomerMode !== undefined) {
      singleCustomerMode = props.singleCustomerMode;
    }

    if (singleCustomerMode) {
      if (
        props.userType !== undefined &&
        props.userType !== "customersContacts"
      ) {
        singleCustomerMode = false;
      }
    }

    const getSiteOptions = async () => {

      if (
        (!isLoading &&
          props.customerID !== "" &&
          // eslint-disable-next-line
          props.customerID !== undefined &&
          props.customerID !== customerID) ||
        // eslint-disable-next-line
        ((singleCustomerMode || associatedOnly) && customerID !== customerID)
      ) {
        try {
          console.log("go", props.customerID);
          setLoading(true);
          setCustomerID(props.customerID);

          let actID = props.customerID;
          if (actID === "###") {
            actID = "";
          }
          let servicesOnly = false
          if (props.servicesOnly === true) {
            servicesOnly = true
          }
          const result = await set.sitesGet(actID, servicesOnly, associatedOnly);
          result.items.sort((a, b) => { return a.description.localeCompare(b.description) })
          setSiteOptions(result.items);

          // console.log("hook fired", result);
          if (props.form && result.items.length === 1) {
            // i can update form

            props.form.change(props.input.name, result.items[0].id);
          }
          setLoading(false);
          if (result.items.length === 0 && props.onEmpty != null) {
            props.onEmpty()
          }
        } catch (err) {
          console.log(err);
          const alert: INotification = {
            title: "Error",
            data: "Error loading sites.",
            variant: NOTIFICATION_TYPE.warning,
            srcComponent: "GetSiteOptions",
            srcData: "warning",
            srcFunction: "getSiteOptions",
          };
          App.newNotification(alert);
          setLoading(false);
        }
      }
    };

    getSiteOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerID,
    isLoading,
    props.associatedOnly,
    props.customerID,
    props.form,
    props.input.name,
    props.singleCustomerMode,
    props.userType,
  ]);

  return isLoading ? (
    <Loading size="100px" />
  ) : (
    <>
      {siteOptions.length > 0 ? (
        <FormControl fullWidth error={props.meta.error && props.meta.touched} variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Site</InputLabel>
          <Select
            fullWidth
            label="Site"
            {...props.input}
            id={props.input.name.replace(".", "_")}

            onChange={(event: ChangeEvent<{ value: unknown }>) => {
              props.input.onChange(event.target.value as string);


              props.inputOnChange && props.inputOnChange(event.target.value as string);
              if (props.onChange) {
                const val = event.target.value as string;
                props.onChange(val);
              }
            }}
          >
            {props.addBlank && <MenuItem></MenuItem>}
            {siteOptions.map((ss: any) => (
              <MenuItem key={ss.id} value={ss.id}>
                {ss.description}
              </MenuItem>
            ))}
          </Select>
          {props.meta.error && props.meta.touched && (
            <FormHelperText>{props.meta.error}</FormHelperText>
          )}
        </FormControl>
      ) : !props.meta.touched ? (
        <><p>This customer is not currently associated with any sites.</p></>
      ) : (
        <p>This customer is not currently associated with any sites.</p>
      )}
    </>
  );
};

export default GetSiteOptionsMui;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "react-table/react-table.css";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";

import { RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Error from "../../components/error";
import PageHead from "../../components/PageHead";
import { IVirtualReceptionistMessage } from "../../interfaces/virtualreceptionist";
import "./index.css";
library.add(faWindowClose, faEdit);

export interface IChatState {
  loading: boolean;
  data: IVirtualReceptionistMessage;
  error: boolean;
}

const set = new MySettings();
interface IMessagePage extends RouteComponentProps<any>, IPageProps { }
interface ParamTypes {
  id: string;
}
const Message: React.FC<IMessagePage> = (props) => {
  let { id } = useParams<ParamTypes>();
  const [data, setData] = useState<IVirtualReceptionistMessage>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // console.log("STATE", resellerPeople);

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      try {
        const message = await set.virtualReceptionistMessageGet(id);
        // console.log("DATA", message);

        if (isMounted) {
          setData(message);
          setLoading(false);
          console.log(message);
        }
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [id]);

  return (
    <>
      <div className="content">
        <PageHead
          resellerID={props.resellerID}
          title="View Message"
          parents={[
            {
              title: "VIP Receptionist",
              url: "../virtualreceptionist/homepage",
            },
          ]}
        ></PageHead>

        {loading ? (
          <Loading />
        ) : error ? (
          <Error />
        ) : (
          data && (
            <>
              <div className="box">
                <table>
                  <tbody>
                    <tr>
                      <td className="head">Message:</td>
                      <td>{data.message}</td>
                    </tr>
                    <tr>
                      <td className="head">Company:</td>
                      <td>{data.company}</td>
                    </tr>
                    <tr>
                      <td className="head">Callers Name:</td>
                      <td>{data.callersName}</td>
                    </tr>
                    <tr>
                      <td className="head">Telephone:</td>
                      <td>{data.telephone}</td>
                    </tr>
                    <tr>
                      <td className="head">Job Title:</td>
                      <td>{data.jobTitle}</td>
                    </tr>
                    <tr>
                      <td className="head">Fax:</td>
                      <td>{data.fax}</td>
                    </tr>
                    <tr>
                      <td className="head">Address 1:</td>
                      <td>{data.address1}</td>
                    </tr>
                    <tr>
                      <td className="head">Address 2:</td>
                      <td>{data.address2}</td>
                    </tr>
                    <tr>
                      <td className="head">Address 3:</td>
                      <td>{data.address3}</td>
                    </tr>
                    <tr>
                      <td className="head">Address 4:</td>
                      <td>{data.address4}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )
        )}
      </div>
    </>
  );
};

export default Message;

import * as React from "react";
import MySettings, { IOffice365AuthedResponse } from "../static/settings";
import Loading from "../components/loading";
import Row from "react-bootstrap/Row";
import { IPageProps } from "../interfaces/page-props";
import { RouteComponentProps } from "react-router-dom";
import { Card } from "react-bootstrap";
import App from "../App";

interface IOffice365PageProps extends RouteComponentProps<any>, IPageProps {}
export interface IOffice365PageState {
  loading: boolean;
  authURL: string;
  authed: boolean;
}

export default class Office365Page extends React.Component<
  IOffice365PageProps,
  IOffice365PageState
> {
  public state: IOffice365PageState = {
    loading: true,
    authURL: "",
    authed: false,
  };

  /*constructor(props: any) {
    super(props);
  }
*/
  componentDidMount() {
    this.checkAuthed();
  }

  componentWillUnmount() {}

  public checkAuthed = () => {
    // Actually send request

    const success = (response: IOffice365AuthedResponse) => {
      this.setState({
        loading: false,
        authURL: response.authURL,
        authed: response.authed,
      });
    };

    const fail = () => {
      App.newNotificationError('There was a problem checking your Microsoft 365 state. Please log out, then log in and then try again.')
      this.setState({ loading: false }); // so we can resubmit
    };
    //start off loading and fire off a request to see if we are authed
    this.setState({ loading: true });
    const set = new MySettings();
    set.office365IsAuthed().then(success).catch(fail);
    // we need to use an endpoint to detect if we are authorised, if not display URL for providing auth
  };

  public render() {
    return (
      <div className="content test-Login">
        {this.state.loading === true ? (
          <Loading />
        ) : (
          <>
          <Row>
            &nbsp;
          </Row>
          <Row>
              <Card className="mb-3">
                <Card.Header as="h3">Microsoft 365 Contact Syncing</Card.Header>
                <Card.Body className="text-center">
                  {this.state.authed ? (
                    <div>
                      Your 365 account is paired. The Microsoft-365-Org and other phone books should appear in the next 20 minutes.
                      <br />
                      If you need to pair again click the link below
                    </div>
                  ) : (
                    <div>
                      Click the link below to enable your Microsoft 365 contacts to be imported in to the VoIP platform.
                    </div>
                  )}
                </Card.Body>
                <Card.Footer style={{ textAlign: "center", color: "white" }}>
                  <a className="btn btn-primary" href={this.state.authURL}>
                    Click Here To Pair
                  </a>
                </Card.Footer>
              </Card>
          </Row>
          </>
        )}
      </div>
    );
  }
}

import React, { MouseEvent, useState } from "react";

import AddIcon from '@material-ui/icons/Add';
import {
  EmailReportRange,
  EmailReportType,
  IEmailReport,
  IEmailSubReport,
} from "../../interfaces/email-reports";
import { IExtension } from "../../interfaces/extension";
import { IVoipService } from "../../interfaces/interfaces";
import { INumber } from "../../interfaces/number";
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiVipDatePicker } from "../VipDateField";

import FormEmailSubReport from "./form-email-subreport";
import { CircularProgress, FormHelperText, IconButton } from "@material-ui/core";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, InputLabel, OutlinedInput, Paper, Select, Typography, Menu, MenuItem } from "@material-ui/core";

import MuiTitle from "../misc/form/MuiTitle";
import VipTimeFieldMui from "../VipTimeFieldMui";
import MuiToggleBoolControlled from "../misc/form/MuiToggleBoolControlled";
import TabPanel, { a11yProps, useTabStyles } from "../misc/tabs";
import TextFieldWithState from "../misc/form/MuiTextFieldWithState";



const validateEmail = (email: any) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


export interface IEmailReportProps {
  data: {
    report: IEmailReport;
    services: IVoipService[];
    extensions: IExtension[];
    hotDesks: IExtension[];
    ddis: INumber[];
  };
  reportUpdateHandler: (field: string, value: any) => void;
  handleTimeChange: (field: string, value: any) => void;
  handleStartDayChange: (day: any) => void;
  handleEndDayChange: (day: any) => void;
  handleSubmit: () => void;
  handleTest: (report: any) => void;
  removeEmail: (email: string) => void;
  addEmail: (email: string) => void;
  newStartTime: string;
  newStartDay: string;
  newEndTime: string;
  newEndDay: string;
  isReseller: boolean;
  showHTML: boolean;
  reportLoading: boolean;
  handleShowHTMLChange: (showHTML: boolean) => void;
}




const headingStyle: any = { position: "relative", marginTop: 10, marginBottom: 10 }

const FormEmailReport: React.FC<IEmailReportProps> = ({
  data: {
    report: {
      lastSentEmailAddresses,
      dontSendHTML,
      attachCSV,
      attachJSON,
      attachPDF,
      customersSitesServicesId,
      emailAddresses,
      id,
      Monday,
      Tuesday,
      Wednesday,
      Thursday,
      Friday,
      Saturday,
      Sunday,
      range,
      name,
      reportTime,
      subReports,
      fromEmailAddress,
    },
    services,
    extensions,
    ddis,
    hotDesks,
  },
  reportUpdateHandler,
  handleTimeChange,
  handleStartDayChange,
  handleEndDayChange,
  handleSubmit,
  handleTest,
  removeEmail,
  addEmail,
  newStartTime,
  newStartDay,
  newEndTime,
  newEndDay,
  isReseller,
  showHTML,
  handleShowHTMLChange,
  reportLoading,
}) => {

  const [activeKey, setActiveKey] = useState(0);
  const classes = useTabStyles();
  let subReportRangeEnum: any = { ...EmailReportRange };
  const ranges = Object.keys(subReportRangeEnum).map((key: any) => ({
    id: key,
    name: subReportRangeEnum[key],
  }));

  let subReportTypeEnum: any = { ...EmailReportType };
  const subReportTypes = Object.keys(EmailReportType).map((key: any) => ({
    id: key,
    name: subReportTypeEnum[key],
  }));

  const addSubReport = (subReportType: string) => {
    const subReps = [...subReports];
    let subReportNaming: any = subReportTypes.find(
      (subReport) => subReport.id === subReportType
    );
    if (subReportNaming === undefined) {
      subReportNaming = { name: "" };
    }
    const subReport = {
      reportID: subReportType + "_" + subReps.length,
      reportType: subReportType,
      reportTitle: subReportNaming.name,
    };
    subReps.push(subReport);
    reportUpdateHandler("subReports", subReps);
    setActiveKey(subReps.length - 1);
  };

  const updateSubReport = (report: any, field: string, data: any) => {
    if (report.lostCallsAgentHourlyCallThreshold != null) {
      report.lostCallsAgentHourlyCallThreshold =
        +report.lostCallsAgentHourlyCallThreshold;
    }

    console.log(report, field, data);

    const subReps = [...subReports];
    report[field] = data;
    const index = subReps.findIndex(
      (ii: IEmailSubReport) => ii.reportID === report.reportID
    );
    subReps[index] = report;
    reportUpdateHandler("subReports", subReps);
  };

  const deleteSubReport = (index: number) => {
    const subReps = [...subReports];
    subReps.splice(index, 1);

    reportUpdateHandler("subReports", subReps);
    setActiveKey(0);
  };

  const setField = (field: string, value: any) => {
    console.log(field, value)
    reportUpdateHandler(field, value);
  };



  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleAddClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAddClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveKey(newValue);
  };



  return (
    <Box style={{
      borderRadius: "4px",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.08)",
      padding: " 24px 12px",
    }}>
      <Paper component={"form"} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MuiTitle title="Report Name" />

            {/* <TextField fullWidth variant="outlined" id="name" value={name}
              onChange={handleChange} /> */}
            <TextFieldWithState required={true} field="name" data={name} setField={setField} type="text" placeholder="Report name" />
          </Grid>

          {isReseller && <Grid item xs={6}>
            <MuiTitle title="Email From Address" />
            <TextFieldWithState required={false} field="fromEmailAddress" data={fromEmailAddress} setField={setField} type="email" placeholder="Leave empty for default" />

          </Grid>}
          <Grid item xs={6}>
            <MuiTitle title="Report Time" />
            <VipTimeFieldMui
              field="reportTime"
              timeArray={[...Array(60).keys()]}
              value={reportTime}
              updateField={setField}
            />
          </Grid>

          <Grid item xs={6}>
            <MuiTitle title="Range" />
            <Select
              variant='outlined'
              fullWidth
              value={range}
              onChange={(ev: any) => {
                setField("range", ev.target.value)
              }}
            >
              {ranges.map((day: any) => {
                return (
                  <MenuItem key={day.id} value={day.id}>
                    {day.name}
                  </MenuItem>
                );
              })}

            </Select>
          </Grid>


          {services.length > 1 && <Grid item xs={6}>
            <MuiTitle title="Site" />
            <Select
              variant='outlined'
              fullWidth
              value={customersSitesServicesId}
              onChange={(ev: any) => {
                setField("customersSitesServicesId", ev.target.value)
              }}
            >
              {services.map((site: IVoipService) => {
                return (
                  <MenuItem key={site.id} value={site.id}>
                    {site.siteName}
                  </MenuItem>
                );
              })}

            </Select>
          </Grid>}



          <Grid item sm={12}>
            <Typography component={'span'} variant="h5" style={headingStyle} >Attachments:</Typography>
          </Grid>
          <Grid item xs={3}>
            <MuiTitle title="Attach CSV" />
            <MuiToggleBoolControlled
              value={attachCSV}

              setValue={(ev) => {
                setField('attachCSV', !attachCSV);
              }} />
          </Grid>
          <Grid item xs={3}>
            <MuiTitle title="Attach JSON" />
            <MuiToggleBoolControlled
              value={attachJSON}

              setValue={(ev) => {
                setField('attachJSON', !attachJSON);
              }} />
          </Grid>

          <Grid item xs={3}>
            <MuiTitle title="Attach PDF" />
            <MuiToggleBoolControlled
              value={attachPDF}

              setValue={(ev) => {
                setField('attachPDF', !attachPDF);
              }} />
          </Grid>

          <Grid item xs={3}>
            <MuiTitle title="Just Send Attachments" />
            <MuiToggleBoolControlled
              value={dontSendHTML}

              setValue={(ev) => {
                setField('dontSendHTML', !dontSendHTML);
              }} />
          </Grid>

          <Grid item sm={12}>
            <Typography variant="h5" style={headingStyle} >Days to run the report:</Typography>
          </Grid>
          <Grid item sm={12}>
            <FormGroup row style={{ display: "flex", justifyContent: "space-between" }}>
              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  id="Monday"
                  checked={Monday}
                  onChange={(e: any) => {
                    setField("Monday", !Monday)
                  }}

                  name="Monday" />}
                label="Monday"
              />


              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  id="Tuesday"
                  checked={Tuesday}
                  onChange={(e: any) => {
                    setField("Tuesday", !Tuesday)
                  }}

                  name="Tuesday" />}
                label="Tuesday"
              />

              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  id="Wednesday"
                  checked={Wednesday}
                  onChange={(e: any) => {
                    setField("Wednesday", !Wednesday)
                  }}

                  name="Wednesday" />}
                label="Wednesday"
              />


              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  id="Thursday"
                  checked={Thursday}
                  onChange={(e: any) => {
                    setField("Thursday", !Thursday)
                  }}

                  name="Thursday" />}
                label="Thursday"
              />



              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  id="Friday"
                  checked={Friday}
                  onChange={(e: any) => {
                    setField("Friday", !Friday)
                  }}

                  name="Friday" />}
                label="Friday"
              />

              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  id="Saturday"
                  checked={Saturday}
                  onChange={(e: any) => {
                    setField("Saturday", !Saturday)
                  }}

                  name="Saturday" />}
                label="Saturday"
              />


              <FormControlLabel
                control={<Checkbox
                  color="primary"
                  id="Sunday"
                  checked={Sunday}
                  onChange={(e: any) => {
                    setField("Sunday", !Sunday)
                  }}

                  name="Sunday" />}
                label="Sunday"
              />

            </FormGroup>
          </Grid>
        </Grid>


        <Grid item sm={12}>

          <Typography variant="h5" style={{ ...headingStyle }}>Email addresses to send the report to:</Typography>
          {emailAddresses.map((email: any) => {
            return <Box key={email} display="flex">
              <Box p={1} flexGrow={1}
                key={email}
                className="d-flex flex-rowd-flex justify-content-between"
              >
                {email}</Box>
              <Box className="text-right">

                <IconButton size="small" color="secondary" onClick={(ev) => {
                  ev.preventDefault();
                  removeEmail(email);
                }}>
                  <DeleteIcon />
                </IconButton>

              </Box>
            </Box>
          })}
          <AddEmailField addEmail={addEmail} />
        </Grid>

        <Grid item sm={12}>
          <Typography variant="h5" style={headingStyle} >
            What To Report
            <Button
              style={{ position: "absolute", right: 0 }}
              id="add-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              variant="outlined"
              color="primary"
              size="small"
              endIcon={<ExpandMoreIcon />}
              aria-expanded={open ? 'true' : undefined}
              onClick={handleAddClick}
            >
              Add To Report
            </Button>
            <Menu
              id="add-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleAddClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {subReportTypes.map((reportType: any) => {
                return (
                  <MenuItem key={reportType.id} onClick={() => {
                    handleAddClose()
                    addSubReport(reportType.id);
                  }}>{reportType.name}</MenuItem>

                );
              })}

            </Menu>
          </Typography>

        </Grid>


        {(subReports == null || subReports.length === 0) && (
          <label>
            Add as many sub-reports as you like using the `Add To Report`
            button to the right.
          </label>
        )}




        <div className={classes.root}>



          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={activeKey}
            onChange={handleTabChange}
            orientation="vertical"
            aria-label="simple tabs example">
            {subReports.map((report: IEmailSubReport, index: number) => {
              return <Tab style={{ marginRight: '1em' }} key={index} label={report.reportTitle} {...a11yProps(index)} />
            })}
          </Tabs>

          {subReports.map((report: IEmailSubReport, index: number) => {
            return (<TabPanel key={index} value={activeKey} index={index}>
              <FormEmailSubReport
                dataIndex={index}
                ddis={ddis}
                extensions={extensions}
                hotDesks={hotDesks}
                data={report}
                deleteSubReport={deleteSubReport}
                submitFunction={(field: string, data: any) => {
                  updateSubReport(report, field, data);
                }}
              />


            </TabPanel>)
          })}
        </div>






        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography component={'span'} >Test Report</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ margin: 0 }}>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MuiTitle title="Start" />
                <VipTimeFieldMui
                  field="startTime"
                  timeArray={[...Array(60).keys()]}
                  value={newStartTime}
                  updateField={handleTimeChange}
                />
                <Box style={{ marginTop: 10 }}>
                  <MuiVipDatePicker
                    placeholder={newStartDay}
                    updateField={handleStartDayChange}
                  />
                </Box>

              </Grid>
              <Grid item xs={6}>
                <MuiTitle title="End" />
                <VipTimeFieldMui

                  field="endTime"
                  timeArray={[...Array(60).keys()]}
                  value={newEndTime}
                  updateField={handleTimeChange}

                />
                <Box style={{ marginTop: 10 }}>
                  <MuiVipDatePicker placeholder={newEndDay}
                    updateField={handleEndDayChange}
                  /></Box>

              </Grid>

              <Grid item xs={6}>
                <MuiTitle title="Show in browser?" />
                <MuiToggleBoolControlled
                  value={showHTML}

                  setValue={(ev) => {
                    handleShowHTMLChange(!showHTML);
                  }} />

              </Grid>
              <Grid item xs={12}>
                {reportLoading === true ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    className="noleft my-3"
                    disabled
                  >
                    <CircularProgress />
                    Loading...
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleTest}
                    className="noleft my-3"
                  >
                    Submit Test Report
                  </Button>
                )}
              </Grid>
            </Grid>

          </AccordionDetails>
        </Accordion>



        <h4>Report Log</h4>
        {(lastSentEmailAddresses != null && lastSentEmailAddresses.length > 0) ? (<>
          {lastSentEmailAddresses.map((item, index) => {
            return <div key={index}>{item.datetime.replace('T', ' ') + ' ' + item.emailAddress}</div>
          })}
        </>) : (<>
          Report not sent.
        </>)}


        <Button variant="outlined" fullWidth color="primary" type="submit">
          Save Report
        </Button>

      </Paper>
    </Box >
  );
};

export default FormEmailReport;





interface oAddEmailFieldProps {
  addEmail: (email: string) => void;
}

const AddEmailField = ({ addEmail }: oAddEmailFieldProps) => {
  const [newEmail, setNewEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  return <FormControl variant="outlined" fullWidth style={{ marginTop: 10 }}>
    <InputLabel htmlFor="outlined-adornment-password">Email address to add</InputLabel>
    <OutlinedInput
      id="outlined-adornment-password"
      type="email"
      value={newEmail}
      error={error}
      label="Email address to add"
      onChange={(event: any) => {
        setNewEmail(event.target.value);
      }}
      endAdornment={
        <InputAdornment position="end">
          <Button
            endIcon={<AddIcon />}
            aria-label="Add Email"
            onClick={() => {
              if (validateEmail(newEmail)) {
                addEmail(newEmail);
                setNewEmail("");
                setError(false)
              } else {
                setError(true)
              }

            }}

          >
            Add Email
          </Button>
        </InputAdornment>
      }
      labelWidth={70}
    />
    {error === true && <FormHelperText style={{ color: "red" }} id="outlined-weight-helper-text">Valid Email Address Required</FormHelperText>}
  </FormControl>

}
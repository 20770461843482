import React, { useEffect, useState } from "react";
import { IPageProps } from "../../interfaces/page-props";
import MySettings from "../../static/settings";

import { Button, Card } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { IExtension } from "../../interfaces/extension";
import { CallRecordingsUnits, IVoipService } from "../../interfaces/interfaces";
import { INotification } from "../../interfaces/notification";

import App from "../../App";
import Error from "../../components/error";
import { IPrepayTransaction } from "../../interfaces/prepay";
import ResellerNotes from "../ResellerNotes";

const set = new MySettings();
export interface IProvisionState {
  data: IVoipService;
  transactions: IPrepayTransaction[];
  displayBase: boolean;
  displayCallRecordingForm: boolean;
  displayAPIKeys: boolean;
  displayUpdateLimits: boolean;
  displayTramsactons: boolean;
  displayAddCredit: boolean;
  title: string;
  extensions: IExtension[];
  apiKeys: string[];
  pageMode: PAGE_MODE;
}

const blankServ: IVoipService = {
  prepay: false,
  prepayCutOffLimit: 0,
  lowBalanceAlertThreshold: 0,
  status: "",
  eurosoftCallRecordingFTPServerUseFTPS: false,
  callRecordingWebHookAddress: "",
  callRecordingWebHookToken: "",
  vaultGrowsIndefinitely: false,
  vaultSize: 0,
  eurosoftCallRecordingFTPServerPort: 0,
  eurosoftCallRecordingFTPServerUsername: "",
  eurosoftCallRecordingFTPServerPassword: "",
  eurosoftCallRecordingFTPServerPath: "",
  defaultSearchInDays: 0,
  measureSizeBy: CallRecordingsUnits.Gigabytes,
  excludedExtensions: [],
  eurosoftCallRecordingFTPServerAddress: "",
  name: "",
  id: "",
  siteName: "",
  useCallerIdAsLabel: false,
  provisioningSettings: { customFields: [], extraAccounts: [] },
  hostname: "",
  vipHostname: "",
  sipPort: "",
  iaxPort: "",
  tlsPort: "",
};

enum PAGE_MODE {
  summary = "summary",
  callrec = "callrec",
  apiKeys = "apiKeys",
  limits = "limits",
  addCredit = "addCredit",
  transactions = "transactions",
}

interface IServicePage extends RouteComponentProps<any>, IPageProps { }

const ServicePage: React.FC<IServicePage> = ({
  resellerID,
  match,
  history,
}) => {
  // const [serviceData, setServiceData] = useState({
  //   title: "",
  //   data: blankServ,
  //   pageMode: match.params.pageMode ? match.params.pageMode : PAGE_MODE.summary,
  // });
  const serviceData = {
    title: "",
    data: blankServ,
    pageMode: match.params.pageMode ? match.params.pageMode : PAGE_MODE.summary,
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [services, setServices] = useState<any>({});
  // console.log("STATE", serviceData);
  // console.log("PROPS", resellerID);

  useEffect(() => {
    window.scrollTo(0, 0);
    let isMounted = true;

    const getService = async () => {
      try {
        const services = await set.serviceGet(match.params.id);
        console.log("services", services);
        if (isMounted) {
          setServices(services);

          setLoading(false);
        }

      } catch (error) {
        console.log(error);
        setLoading(false);
        setError(true);
      }
    };

    getService();

    return () => {
      isMounted = false;
    };
  }, [match.params.id, serviceData.pageMode]);

  const cancelService = async () => {
    if (
      window.confirm(
        "Are you sure you want to cancel Teams Call Recording for this customer? No more call recordings from Teams will be collected."
      )
    ) {
      setLoading(true);
      try {
        const result = await set.resellerTeamsCallRecordingCancel(
          match.params.id
        );
        console.log(result);
        setLoading(false);
        const notification: INotification = {
          title: "Success",
          data: "Service Cancelled",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
        history.push(`/reseller/${match.params.customersid}`);
      } catch (err) {
        setLoading(false);
        const notification: INotification = {
          title: "Error",
          data: "Unable to cancel service",
          variant: NOTIFICATION_TYPE.danger,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      }
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="content">
        <PageHead resellerID={resellerID} title={serviceData.title}></PageHead>

        {error ? (
          <Error />
        ) : (
          <>
            <div
              className="d-flex flex-rowd-flex justify-content-between"
              style={{ marginBottom: "20px" }}
            >
              {services.status === "Active" && (
                <Button
                  variant="outline-danger"
                  type="button"
                  onClick={() => cancelService()}
                >
                  Cancel Service
                </Button>
              )}
            </div>
            <Card>
              <Card.Body>
                <div className="d-flex flex-rowd-flex justify-content-between">
                  <div>Status:</div>
                  <div>{services.status}</div>
                </div>

                <div className="d-flex flex-rowd-flex justify-content-between">
                  <div>Site Name: </div>
                  <div>{services.siteName}</div>
                </div>
              </Card.Body>
            </Card>
            {resellerID && <ResellerNotes resellerID={resellerID} />}
          </>
        )}
      </div>
    </>
  );
};

export default ServicePage;

import axios from "axios";

import { IAreaCodeRoutingsRoute } from "../components/AreaCodeRoutingTableRow";
import {
  IAccessGroup,
  IAccessGroupHttpResponse,
  IAccessGroupWithPermissionsTreeHttpResponse
} from "../interfaces/access-group";
import { IBranding } from "../interfaces/branding";
import {
  ICallRecording, ICallRecordingHttpRequest,
  ICallRecordingHttpResponse
} from "../interfaces/call-recording";
import {
  ICallLogsHttpRequest,
  ICallLogsHttpResponse
} from "../interfaces/calllog";
import {
  ICustomer,
  ICustomerAddRequest, ICustomerAllowDialCode, ICustomerDetailsResponse, ICustomersSearchItems, ICustomerTariffUpdateRequest, ICustomerUpdateRequest, IPSACustomer,
  IService,
  IServiceWithSiteName, IStandardFullTariffGetResponse, IStandardRetailTariffGetResponse,
  ITariff,
  ITariffGetResponse, ITariffsGetResponse
} from "../interfaces/customer";
import {
  IEmailReport,
  IEmailReportHttpResponse, IEmailReportsHttpRequest, IEmailReportsHttpResponse, IEmailReportTest
} from "../interfaces/email-reports";
import {
  IDDIsHttpResponse, IExtensionsHttpResponse
} from "../interfaces/extension";
import {
  IIncomingCallStatsHttpRequest,
  IIncomingCallStatsHttpResponse
} from "../interfaces/incoming-call-stats";
import {
  CallRecordingsUnits,
  IBuyPhonesLine, IVoipService, IVoipServicesRequest
} from "../interfaces/interfaces";
import {
  IMeetingRoom, IMeetingRoomGetResponse,
  IMeetingRoomsGetResponse
} from "../interfaces/meeting-rooms";
import { ITours } from "../interfaces/menu-option";
import {
  INewServiceVoipServiceRequest,
  IResellerInternetServiceEnquiry
} from "../interfaces/new-service";
import {
  INewServiceNumberPortNumbersRequest, INumber, INumberAllocationRequest, INumberEHAUpdateRequest,
  INumberFaxToEmailRequest, INumberForwardRequest, INumberHttpResponse, INumbersAlistResponse,
  INumbersAllocateResponse, INumbersHttpResponse, INumberTracker,
  INumberTrackersGetHttpResponse
} from "../interfaces/number";
import { IPeopleGetHttpResponse, IPerson } from "../interfaces/people";
import {
  IPhonebook,
  IPhonebookSettings,
  IPhonebooksHttpResponse
} from "../interfaces/phonebook";
import {
  IPhonebookContact,
  IPhonebookNumbersHttpResponse
} from "../interfaces/phonebook-number";
import {
  IPrepayTransactionAddRequest, IPrepayTransactionsGetResponse
} from "../interfaces/prepay";
import {
  IAvailableProductsResponse,
  IPSAProduct,
  IPSAReconciliation,
  IPSAReconciliationGetResponse,
  IResellerPSAProductMapping
} from "../interfaces/products";
import {
  IProvisionedDevice,
  IProvisionedDeviceResponse, IProvisioningGetHttpRequest,
  IProvisioningGetHttpResponse, IProvisioningIDResponse, IProvisioningRecord,
  IProvisioningSettings
} from "../interfaces/provisioning";
import {
  ICreditNoteGetResponse, ICreditNotesGetResponse, IInvoiceGetResponse, IInvoicesGetResponse, IPSAAgreementType,
  IPSABillingCycle, IResellerPSAConfiguration
} from "../interfaces/reseller-invoice";
import {
  ISite, ISitesGetHttpResponse, ISiteWithName
} from "../interfaces/site";
import { IVirtualReceptionistMessage } from "../interfaces/virtualreceptionist";
import {
  IVoiceRouting,
  IVoiceRoutingHttpResponse,
  IVoiceRoutingsHttpResponse
} from "../interfaces/voiceroutings";
import {
  IWallboardLayout,
  IWallboardLayoutHttpResponse,
  IWallboardLayoutsHttpResponse,
  IWallboardWidgetSetting
} from "../interfaces/wallboards";

export interface IAppLoginAttempt {
  datetime:string //YYYY-MM-DDTHH:MM:SS
  success:boolean //did they manage to log in
  appVersion:string //what tried to log in
  username:string //usually an email address
  ipAddress:string //the ip address of the where the login came from
  voipServerHostname:string //the server we redirected them to
  deviceName:string //the device we associated them with
}

export interface IAppLoginAttemptsResponse {
  items:IAppLoginAttempt[]
}

export interface IDrogonLoginResponse {
  url: string;
}

// Contact numbers http response

export interface IAreaCodeRoutingsGetRequest {
  records: IAreaCodeRoutingsRoute[];
}

export interface ISuccessAPIResponse {
  success: boolean;
  message: string;
}

export interface IMessageAPIResponse {
  message: string;
}

export interface IMFAAttemptRequest {
  code: string;
  mfaAttemptToken: string;
}

export interface IMFAAttemptResponse {
  success: boolean;
  type: string;
  message?: string;
  redirectTo?:string
}

export interface IMFAResetResponse {
  secret: string;
  token: string;
  label: string;
}

export interface IConnectwiseContactsAuthedResponse {
  connectwiseContactsURL:string
  connectwiseContactsEnabled: boolean
  connectwiseContactsCompany: string
  connectwiseContactsPublicKey: string
  connectwiseContactsPrivateKey: string
  connectwiseContactsValidated:boolean
  connectwiseContactsLastSync:string
}

export interface IOffice365AuthedResponse {
  authURL: string;
  authed: boolean;
  actionStepCreateFileNotes: boolean;
  actionStepCreateTimeEntries: boolean;
}

export interface IVoipServiceProvisioningUpdateRequest {
  id: string;
  provisioningSettings: IProvisioningSettings;
  useCallerIdAsLabel: boolean;
}

export interface IVoipServiceCallRecordingEmailAddress {
  ddiFilter: string;
  emailAddress: string;
}

export interface IVoipServiceCallRecordingsUpdateRequest {
  id: string;
  defaultVaultSearchLengthDays: number;
  vaultSize: number;
  useAPIRecordingsCollection: boolean;
  vaultGrowsIndefinitely: boolean;
  excludedExtensions: string[];
  recordInternal: boolean;
  measureSizeBy: CallRecordingsUnits;
  eurosoftCallRecordingFTPServerAddress: string;
  eurosoftCallRecordingFTPServerPort: string;
  eurosoftCallRecordingFTPServerUsername: string;
  eurosoftCallRecordingFTPServerPassword: string;
  eurosoftCallRecordingFTPServerPath: string;
  eurosoftCallRecordingFTPServerUseFTPS: boolean;
  callRecordingWebHookAddress: string;
  callRecordingWebHookToken: string;
  callRecordingEmailAddresses: IVoipServiceCallRecordingEmailAddress[];
  teamsCallRecordingLicenseLimit: number;
  callRecordingTranscriptionEnabled: boolean;
  grafanaHourlyEnabled?: boolean;
}

export interface IVoipServiceSettingsUpdateRequest {
  id: string;
  grafanaHourlyEnabled: boolean;
  generateCDRFiles:boolean;
}

export interface ITeamsCallRecordingUser {
  id: string;
  userPrincipalName: string;
  displayName: string;
  licensed: boolean;
}

export interface ITeamsCallRecordingUsersResponse {
  teamsCallRecordingLicenseLimit: number;
  usedLicenses: number;
  users: Record<string, ITeamsCallRecordingUser>;
}

export interface ILoginRequest {
  username: string;
  password: string;
}

export interface IChromePluginAuthCodesResponse {
  userid: string;
  secret: string;
}

export interface IVoipServiceAPIKeysResponse {
  keys: string[];
}

export interface IAreaCode {
  id: string;
  areaCode: string;
  city: string;
}

export interface IAreaCodesResponse {
  areaCodes: IAreaCode[];
}

export interface IIOSAccountAddURLResponse {
  url: string;
}

export interface IResellerCab9WallboardWebhook {
  url:string,
  token:string,
  password:string
}

export interface IResellerCab9CallPoppingWebHook {
  url:string
  token:string
}

export interface IResellerCab9WallboardGetResponse {
  passengerConnectHostname:string
  passengerConnectCallerId:string
  passengerConnectEnabled:boolean
  apiToken:string
  exists:boolean
  queues:string[]
  webhooks:IResellerCab9WallboardWebhook[]
  callPoppingWebHooks:IResellerCab9CallPoppingWebHook[]
  callRecordingWebHooks:IResellerCab9CallPoppingWebHook[]
  wallboardLastLogDateTime:string
  wallboardLastLogCmd:string
  wallboardLastLogResponse:string
  legacyCallRecordingsLogLines:string[]
}

export default class MySettings {
  public static serverBase: string = "/";
  //
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js

  //

  // public static serverBase:string = 'https://portal.vipvoip.co.uk/voip/'
  // public static serverBase:string = 'http://127.0.0.1:8080/voip/'
  // public static serverBase:string = 'http://apitesting.vipvoip.co.uk:8080/voip/'
  // public static serverBase:string = 'http://192.168.0.4:8080/voip/'

  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js
  // DONT CHANGE THESE SETTINGS TO ALTER THE URL OF THE API SERVER - FOR LOCAL MODE SEE src/setupProxy.js

  public getBase = () => {
    return MySettings.serverBase;
  };

  private server: string = MySettings.serverBase + "voip/react/api/v1";
  //private server:string = 'http://127.0.0.1:8080/voip/react/api/v1';
  // private server:string = 'http://172.16.0.44:8080/voip/react/api/v1'
  private login: string = "/customer/contact/login";
  private menu: string = "/customer/contact/menuoptions";
  private resetpwd: string = "/customer/contact/resetpassword";
  private changePassword: string = "/customer/contact/changepassword";
  private numbers: string = "/customer/contact/numbers";
  private number: string = "/customer/contact/number";
  private logout: string = "/customer/contact/logout";
  private areaCodeRouting: string = "/customer/contact/areacoderouting";
  private areaCodeRoutingsGet: string =
    "/customer/contact/areacoderoutings/get";
  private areaCodeRoutingsAdd: string =
    "/customer/contact/areacoderoutings/add";
  private areaCodeRoutingsDelete: string =
    "/customer/contact/areacoderoutings/delete";
  private voipServices: string = "/customer/contact/voipservices";
  private phoneBooksGet: string = "/customer/contact/phonebooks";
  private phoneBooksAdd: string = "/customer/contact/phonebooks/add";
  private phoneBookRecordsGet: string = "/customer/contact/phonebookrecords";
  private phoneBookRecordsAdd: string =
    "/customer/contact/phonebooks/records/add";
  public getCallLogDownloadHref = () => {
    return "/../callLogs";
  };
  public areaCodeRouteDelete = (id: string): Promise<boolean> => {
    const promise = new Promise<boolean>((resolve, reject) => {
      this.makeAPIRequest(this.areaCodeRoutingsDelete, { id })
        .then((data: any) => {
          resolve(data.success);
        })
        .catch((error) => {
          resolve(false);
        });
    });
    return promise;
  };

  public getLoginUrl(): string {
    return this.server + this.login;
  }

  public getDownloadDevicesUrl(customersId:string):string {
    return this.server+'/reseller/contact/devices/csv?customersId='+customersId
  }
  
  /*
    POST
    response=
    {
        name:string, name of the person logged in
        menuOptions:[{
            title:string, title of the menu option to be displayed
            path:string, url to use for routing
            permissions:{
                add:boolean,
                edit:boolean,
                view:boolean,
                remove:boolean
            }
        }]
    }
    */
  public getMenu(): string {
    return this.server + this.menu;
  }

  public getResetPassword(): string {
    return this.server + this.resetpwd;
  }

  public getChangePassword(): string {
    return this.server + this.changePassword;
  }
  public getNumbers(): string {
    return this.server + this.numbers;
  }
  public getNumberUrl(): string {
    return this.server + this.number;
  }
  public getLogout(): string {
    return this.server + this.logout;
  }
  public getAreaCodeRouting(): string {
    return this.server + this.areaCodeRouting;
  }
  public phoneBookUploadEntriesURL(): string {
    return this.server + "/customer/contact/phonebooks/records/upload";
  }

  public numberGet(id: string): Promise<INumberHttpResponse> {
    return this.doAPIRequest<INumberHttpResponse>(this.server + this.number, {
      id,
    });
  }

  public getChromePluginAuthCodes(
    request: ILoginRequest
  ): Promise<IChromePluginAuthCodesResponse> {
    return this.doAPIRequest<IChromePluginAuthCodesResponse>(
      this.server + "/customers/contacts/chromeplugin/authcodes/get",
      request
    );
  }

  public postCodeLookUp(postCode: string): Promise<ISitesGetHttpResponse> {
    return this.doAPIRequest<ISitesGetHttpResponse>(
      this.server + "/customer/contact/postcodelookup",
      {
        postCode,
      }
    );
  }

  /*
  set
        .doAPIRequest<INumberHttpResponse>(url, numberRequest)
  
        .then((data: INumberHttpResponse) => {
          console.log(data);
          let update = this.parseResponse(data);
          update.loading = false;
          //  update.activeKey = mode;
          this.setState(update);
        })
        .catch((exception) => {
          catchME(this, exception, true);
        });
  
  */

  public async getNumber(id: string): Promise<INumber> {

    const request = await this.doAPIRequest<INumberHttpResponse>(this.server + this.getNumberUrl(),
      {
        id,
      }
    );
    return request.item
  }
  /*
    POST
    {
        customersSitesServicesId:string
    }

    response=
    {
        records:[{
            id:string,
            prefix:string,
            destination:string
        }]
    }
    */
  /*public getAreaCodeRoutingsGet():string{
        return this.server+this.areaCodeRoutingsGet;
    }*/

  public getVoipServicesCall(
    includeAPIRecordingsLog:boolean,
    customerID?: string
  ): Promise<IVoipServicesRequest> {
    let payload: any = {includeAPIRecordingsLog};
    if (customerID !== undefined) {
      payload.customersId = customerID;
    }
    const promise = new Promise<IVoipServicesRequest>((resolve, reject) => {
      this.makeAPIRequest(this.server + this.voipServices, payload)
        .then((data: any) => {
          //  let response = data as IVoipServicesRequest
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  public voipServiceGet(id: string): Promise<IVoipService> {
    return this.doAPIRequest<IVoipService>(
      this.server + "/customer/contact/voipservice/get",
      { id }
    );
  }

  public resellerPauseServer(id: string): Promise<IVoipService> {
    return this.doAPIRequest<IVoipService>(
      this.server + "/reseller/contact/voipservice/pause",
      { id }
    );
  }

  public serviceGet(id: string): Promise<IServiceWithSiteName> {
    return this.doAPIRequest<IServiceWithSiteName>(
      this.server + "/customer/contact/service/get",
      { id }
    );
  }

  public getAreaCodeRoutingsGet(
    customersSitesServicesId: string
  ): Promise<IAreaCodeRoutingsRoute[]> {
    const promise = new Promise<IAreaCodeRoutingsRoute[]>((resolve, reject) => {
      this.makeAPIRequest(this.areaCodeRoutingsGet, {
        customersSitesServicesId,
      })
        .then((data: any) => {
          let response = data as IAreaCodeRoutingsGetRequest;
          resolve(response.records);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  /*
    POST
    {
        customersSitesServicesId:string,
        destination:string,
        prefix:string
    }

    response=
    {
        success:boolean
    }
    */
  public getAreaCodeRoutingsAdd(): string {
    return this.server + this.areaCodeRoutingsAdd;
  }

  public getVoipServices(): string {
    return this.server + this.voipServices;
  }

  /*
    POST
    response=
    {
        phoneBooks:[
            {
                id:string,
                customersId:string,
                name:string,
                safeCustomersName:string
            }
        ]
    }
    */
  public getPhoneBooks(): string {
    return this.server + this.phoneBooksGet;
  }

  /*
    POST
    {
        name:string
    }
    
    response=
    {
        record:{
            id:string,
            name:string
        }
    }
    */

  public getPhoneBooksAdd(): string {
    return this.server + this.phoneBooksAdd;
  }

  /*
    POST
    {
        phoneBooksId:string id of the phone book
    }

    response=
    {
        name:string,
        records:[{
            id:string,
            phoneBooksId:string,
            name:string,
        givenName:string,
        sn:string,
        number1:string,
        number2:string,
        number3:string
        }]
    }
    */

  /*
    POST
    {
        phoneBookId:string id of the phone book to add this record to
    name:string name of the contact
    givenName:string
    sn:string surname
    number1:string number1
    number2:string number2
    number3:string number3
    }

    response=
    {
        record:{
            id:string unique id for this record
            phoneBookId:string id of the phone book to add this record to
            name:string name of the contact
            givenName:string
            sn:string surname
            number1:string number1
            number2:string number2
            number3:string number3
        }
    }
    */

  //Phonebook Entry stuff

  //get phonebook

  public getPhoneBook(
    phoneBookID: string
  ): Promise<IPhonebookNumbersHttpResponse> {
    const payload = {
      phoneBooksId: phoneBookID,
    };

    return new Promise<IPhonebookNumbersHttpResponse>((resolve, reject) => {
      this.doAPIRequest<IPhonebookNumbersHttpResponse>(
        this.server + this.phoneBookRecordsGet,
        payload
      )
        .then((newNumber) => {
          resolve(newNumber);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //

  public addPhoneBookEntry(
    entry: IPhonebookContact
  ): Promise<IPhonebookContact> {
    return new Promise<IPhonebookContact>((resolve, reject) => {
      this.doAPIRequest<IPhonebookContact>(
        this.server + this.phoneBookRecordsAdd,
        entry
      )
        .then((newNumber) => {
          resolve(newNumber);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updatePhoneBookEntry(
    entry: IPhonebookContact
  ): Promise<IPhonebookContact> {
    return new Promise<IPhonebookContact>((resolve, reject) => {
      this.doAPIRequest<IPhonebookContact>(
        this.server + "/customer/contact/phonebookrecords/update",
        entry
      )
        .then((updatedEntry) => {
          resolve(updatedEntry);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public deletePhoneBookRecordEntry(id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.doAPIRequest<any>(
        this.server + "/customer/contact/phonebookrecords/remove",
        {
          id,
        }
      )
        .then((response) => {
          resolve(response.success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  
  public deletePhoneBookRecord(id: string): Promise<boolean> {
    return this.doAPIRequest<any>(this.server + "/customer/contact/phonebooks/remove",{id})
  }

  // branding
  public brandingGet(hostname: string): Promise<IBranding> {
    return this.doAPIRequest<IBranding>(
      this.server + "/customer/contact/branding",
      { hostname }
    );
  }

  public drogonLogin(id: string, user: string): Promise<IDrogonLoginResponse> {
    return this.doAPIRequest<IDrogonLoginResponse>(
      this.server + "/customer/contact/drogon/login",
      { id, user }
    );
  }

  public passwordResetCheck(id:string):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/customer/contact/passwordreset',{id,cmd:'checkid'})
  }

  public passwordSetPassword(id:string,password:string,password2:string):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/customer/contact/passwordreset',{id,password,password2,cmd:'setpassword'})
  }

  public resellerBrandingUpdate(
    brandName: string,
    brandPortalDomain: string,
    brandEmailAddress: string,
    removeLogo: boolean,
    newLogo: string,
    newLogoFileName: string,
    removeNavigationBarLogo: boolean,
    newNavigationBarLogo: string,
    newNavigationBarFileName: string,
    bundleAlertEmailAddress:string,
    bundleAlertPercentage:number,
    overrideNavigationBarColour:boolean,
    navigationBarColour:string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/branding/update",
      {
        brandName,
        brandPortalDomain,
        brandEmailAddress,
        removeLogo,
        newLogo,
        newLogoFileName,
        removeNavigationBarLogo,
        newNavigationBarLogo,
        newNavigationBarFileName,
        bundleAlertEmailAddress,
        bundleAlertPercentage,
        overrideNavigationBarColour,
        navigationBarColour
      }
    );
  }

  public resellerNumberChangeOwner(
    numbersId: string,
    customersSitesId: string,
    extensionNumber:string
  ): Promise<INumber> {
    return this.doAPIRequest<INumber>(
      this.server + "/reseller/contact/number/changeowner",
      {
        numbersId,
        customersSitesId,
        extensionNumber
      }
    );
  }

  public resellerNumberChangeOutgoingBillingSite(
    numbersId: string,
    customersSitesId: string
  ): Promise<INumber> {
    return this.doAPIRequest<INumber>(
      this.server + "/reseller/contact/number/changeoutgoingbillingsite",
      {
        numbersId,
        customersSitesId,
      }
    );
  }

  public resellerCustomerContactResetPassword(
    id: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/customer/contact/resetpassword",
      { id }
    );
  }

  public resellerNumberPortAdd(
    request: INewServiceNumberPortNumbersRequest
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/numberport/add",
      {
        notes: request.notes,
        customersSitesId: request.customersSitesId,
        numberPortNumbers: request.numberPortNumbers,
        multiLineNumber: request.multiLineNumber,
        numberPortTemplate: request.numberPortTemplate,
      }
    );
  }

  // number
  public numberSearch(
    query: string,
    customersName: string,
    customersId?: string
  ): Promise<INumbersHttpResponse> {
    let payload: any = { name: query, customersName };
    if (customersId !== undefined) {
      payload.customersId = customersId;
    }

    return this.doAPIRequest<INumbersHttpResponse>(
      this.server + "/customer/contact/numbers",
      payload
    );
  }

  //deactivates all the numbers for a customer. Leave customersId blank to deactivate for the currently logged in customer
  public numbersDeactivate(customersId: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/numbers/deactivate",
      { customersId }
    );
  }

  // ddis
  public ddisGet(customersSitesServicesId: string): Promise<IDDIsHttpResponse> {
    return this.doAPIRequest<IDDIsHttpResponse>(
      this.server + "/customer/contact/ddis",
      { customersSitesServicesId }
    );
  }

  // extensions
  public extensionsGet(
    customersSitesServicesId: string,
    types: string[] //leave empty to get the default set of extensions
  ): Promise<IExtensionsHttpResponse> {
    return this.doAPIRequest<IExtensionsHttpResponse>(
      this.server + "/customer/contact/extensions",
      { customersSitesServicesId, types }
    );
  }

  public extensionsGetForCustomer(
    customersId: string,
    types: string[], //leave empty to get the default set of extensions
    customersSitesServicesId: string
  ): Promise<IExtensionsHttpResponse> {
    return this.doAPIRequest<IExtensionsHttpResponse>(
      this.server + "/customer/contact/extensions",
      { customersId, types, customersSitesServicesId }
    );
  }

  public extensionsGetForCustomerSite(
    customersSitesId: string
  ): Promise<IExtensionsHttpResponse> {
    return this.doAPIRequest<IExtensionsHttpResponse>(
      this.server + "/customer/contact/extensions",
      { customersSitesId }
    );
  }

  public provisionableDevicesGetForCustomerSite(
    customersSitesId: string,
    associatedOnly: boolean
  ): Promise<IExtensionsHttpResponse> {
    return this.doAPIRequest<IExtensionsHttpResponse>(
      this.server + "/customer/contact/provisionabledevices",
      {
        customersSitesId,
        associatedOnly,
      }
    );
  }

  // mfa
  public mfaAttempt(attempt: IMFAAttemptRequest): Promise<IMFAAttemptResponse> {
    return this.doAPIRequest<IMFAAttemptResponse>(
      this.server + "/customer/contact/mfa/attempt",
      attempt
    );
  }

  public mfaReset(): Promise<IMFAResetResponse> {
    return this.doAPIRequest<IMFAResetResponse>(
      this.server + "/customer/contact/mfa/reset",
      {}
    );
  }

  public mfaUpdate(useMFA: boolean): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/mfa/update",
      { useMFA }
    );
  }

  public toursUpdate(tours: ITours): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/tours/update",
      tours
    );
  }

  // Number stuff
  public numberCancelForward(id: string): Promise<INumber> {
    return this.doAPIRequest<INumber>(
      this.server + "/customer/contact/number/forward/cancel",
      { id }
    );
  }

  public numberCancelFaxToEmail(id: string): Promise<INumber> {
    return this.doAPIRequest<INumber>(
      this.server + "/customer/contact/number/faxtoemail/cancel",
      { id }
    );
  }

  public numberNotesUpdate(number: INumber): Promise<INumber> {
    return this.doAPIRequest<INumber>(
      this.server + "/customer/contact/number/notes/update",
      number
    );
  }

  public numberDeactivate(id: string): Promise<INumber> {
    return this.doAPIRequest<INumber>(
      this.server + "/customer/contact/number/deactivate",
      {
        id,
      }
    );
  }

  public numberReactivate(id: string): Promise<INumber> {
    return this.doAPIRequest<INumber>(
      this.server + "/customer/contact/number/reactivate",
      {
        id,
      }
    );
  }

  public numberForward(request: INumberForwardRequest): Promise<INumber> {
    return this.doAPIRequest<INumber>(
      this.server + "/customer/contact/number/forward",
      request
    );
  }

  public customerAllowDialCodesGet(
    customersId: string
  ): Promise<ICustomerAllowDialCode[]> {
    return this.doAPIRequest<ICustomerAllowDialCode[]>(
      this.server + "/customer/contact/allowdialcodes/get",
      { customersId }
    );
  }

  public customerAllowDialCodesAddMain(
    customersId: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/allowdialcodes/addmain",
      { customersId }
    );
  }

  public customerAllowDialCodesRemoveMain(
    customersId: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/allowdialcodes/removemain",
      { customersId }
    );
  }

  public customerAllowDialCodesRemove(
    id: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/allowdialcodes/remove",
      { id }
    );
  }

  public customerAllowDialCodesAdd(
    dialCode: ICustomerAllowDialCode
  ): Promise<ICustomerAllowDialCode> {
    return this.doAPIRequest<ICustomerAllowDialCode>(
      this.server + "/customer/contact/allowdialcodes/add",
      dialCode
    );
  }

  // call logs stuff
  public callLogsGet(
    request: ICallLogsHttpRequest
  ): Promise<ICallLogsHttpResponse> {
    return this.doAPIRequest<ICallLogsHttpResponse>(
      this.server + "/customer/contact/calllogs/get",
      request
    );
  }

  // Call recording stuff
  public callRecordingsEmail(
    customersSitesServicesId:string,
    ids: string[],
    emailAddresses: string[]
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/callrecordings/email",
      {
        customersSitesServicesId,
        ids,
        emailAddresses,
      }
    );
  }

  public callRecordingSetProtected(
    customersSitesServicesId:string,
    id: string,
    isProtected: boolean
  ): Promise<ICallRecording> {
    return this.doAPIRequest<ICallRecording>(
      this.server + "/customer/contact/callrecordings/setprotected",
      {
        customersSitesServicesId,
        id,
        isProtected,
      }
    );
  }

  public callRecordingRemove(customersSitesServicesId:string,id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.doAPIRequest<ISuccessAPIResponse>(
        this.server + "/customer/contact/callrecordings/remove",
        { customersSitesServicesId,id }
      )
        .then((result) => {
          resolve(result.success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public callRecordingsRemove(customersSitesServicesId:string,ids: string[]): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/callrecordings/remove",
      { customersSitesServicesId,ids }
    );
  }

  public getCallRecordings(
    request: ICallRecordingHttpRequest
  ): Promise<ICallRecordingHttpResponse> {
    return this.doAPIRequest<ICallRecordingHttpResponse>(
      this.server + "/customer/contact/callrecordings/get",
      request
    );
  }

  //email reports
  public emailReportGet(id: string): Promise<IEmailReportHttpResponse> {
    return this.doAPIRequest<IEmailReportHttpResponse>(
      this.server + "/customer/contact/emailreport/get",
      { id }
    );
  }

  public voiceRoutingGet(id: string): Promise<IVoiceRoutingHttpResponse> {
    return this.doAPIRequest<IVoiceRoutingHttpResponse>(
      this.server + "/customer/contact/voicerouting/get",
      { id }
    );
  }

  public emailReportsGet(
    request: IEmailReportsHttpRequest
  ): Promise<IEmailReportsHttpResponse> {
    return this.doAPIRequest<IEmailReportsHttpResponse>(
      this.server + "/customer/contact/emailreports/get",
      request
    );
  }

  public voiceRoutingsGet(
    customersSitesServicesId: string
  ): Promise<IVoiceRoutingsHttpResponse> {
    return this.doAPIRequest<IVoiceRoutingsHttpResponse>(
      this.server + "/customer/contact/voiceroutings/get",
      { customersSitesServicesId }
    );
  }

  public emailReportsAdd(report: IEmailReport): Promise<IEmailReport> {
    return this.doAPIRequest<IEmailReport>(
      this.server + "/customer/contact/emailreports/add",
      report
    );
  }

  public voiceRoutingsAdd(record: IVoiceRouting): Promise<IVoiceRouting> {
    return this.doAPIRequest<IVoiceRouting>(
      this.server + "/customer/contact/voiceroutings/add",
      record
    );
  }

  public voiceRoutingsUpdate(record: IVoiceRouting): Promise<IVoiceRouting> {
    return this.doAPIRequest<IVoiceRouting>(
      this.server + "/customer/contact/voiceroutings/update",
      record
    );
  }

  public emailReportsUpdate(report: IEmailReport): Promise<IEmailReport> {
    return this.doAPIRequest<IEmailReport>(
      this.server + "/customer/contact/emailreports/update",
      report
    );
  }

  public emailReportsRemove(id: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/emailreports/remove",
      { id }
    );
  }

  public voiceRoutingsRemove(id: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/voiceroutings/remove",
      { id }
    );
  }

  public emailReportsRun(
    report: IEmailReportTest
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/emailreports/run",
      report
    );
  }

  // leave customersContactsId to do the currently logged in user
  public customersContactIOSAccountAddURL(
    customersContactsId: string
  ): Promise<IIOSAccountAddURLResponse> {
    return this.doAPIRequest<IIOSAccountAddURLResponse>(
      this.server + "/customer/contact/iosaddaccounturl",
      {
        customersContactsId,
      }
    );
  }

  public meetingRoomGet(
    meetingRoomId: string
  ): Promise<IMeetingRoomGetResponse> {
    return this.doAPIRequest<IMeetingRoomGetResponse>(
      this.server + "/customer/contact/meetingroom/get",
      { id: meetingRoomId }
    );
  }

  public meetingRoomsGet(
    customersId: string
  ): Promise<IMeetingRoomsGetResponse> {
    return this.doAPIRequest<IMeetingRoomsGetResponse>(
      this.server + "/customer/contact/meetingrooms/get",
      { customersId }
    );
  }

  public meetingRoomsAdd(room: IMeetingRoom): Promise<IMeetingRoom> {
    return this.doAPIRequest<IMeetingRoom>(
      this.server + "/customer/contact/meetingroom/add",
      room
    );
  }

  public meetingRoomsRemove(id: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/meetingroom/remove",
      { id }
    );
  }

  // phonebooks
  public phonebooksGet(customersId?: string): Promise<IPhonebooksHttpResponse> {
    return this.doAPIRequest<IPhonebooksHttpResponse>(this.getPhoneBooks(), {
      customersId,
    });
  }

  public phonebookSettingsGet(id: string): Promise<IPhonebookSettings> {
    return this.doAPIRequest<IPhonebookSettings>(
      this.server + "/customer/contact/phonebooksettings/get",
      { id }
    );
  }

  public phonebookSettingsUpdatePassword(
    id: string,
    password: string
  ): Promise<IPhonebookSettings> {
    return this.doAPIRequest<IPhonebookSettings>(
      this.server + "/customer/contact/phonebooksettings/updatepassword",
      { id, password }
    );
  }

  public phonebookSettingsUpdate(
    id: string,
    syncWith365: boolean
  ): Promise<IPhonebook> {
    return this.doAPIRequest<IPhonebook>(
      this.server + "/customer/contact/phonebooksettings/update",
      { id, syncWith365 }
    );
  }

  public peopleGet(
    numbersId: string,
    customersId?: string
  ): Promise<IPeopleGetHttpResponse> {
    return this.doAPIRequest<IPeopleGetHttpResponse>(
      this.server + "/customer/contact/people/get",
      { customersId, numbersId }
    );
  }

  public resellerPeopleGet(): Promise<IPeopleGetHttpResponse> {
    return this.doAPIRequest<IPeopleGetHttpResponse>(
      this.server + "/reseller/contact/people/get",
      {}
    );
  }

  public resellerPersonGet(id: string): Promise<IPerson> {
    return this.doAPIRequest<IPerson>(
      this.server + "/reseller/contact/person/get",
      { id }
    );
  }

  public resellerPersonAppLoginAttemptsGet(id:string):Promise<IAppLoginAttemptsResponse>{
    return this.doAPIRequest<IAppLoginAttemptsResponse>(this.server+'/reseller/contact/person/apploginattempts/get',{id})
  }

  public resellerPersonRemove(id: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/person/remove",
      { id }
    );
  }

  public customerPersonRemove(id: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/person/remove",
      { id }
    );
  }

  public personGet(id: string,includeSpeedDials:boolean): Promise<IPerson> {
    return this.doAPIRequest<IPerson>(
      this.server + "/customer/contact/person/get",
      { id,includeSpeedDials }
    );
  }

  // Access groups
  public portalAccessGroupsAdd(newGroup: IAccessGroup): Promise<IAccessGroup> {
    return this.doAPIRequest<IAccessGroup>(
      this.server + "/customer/contact/portalaccessgroups/add",
      newGroup
    );
  }

  public resellerPortalAccessGroupsAdd(
    newGroup: IAccessGroup
  ): Promise<IAccessGroup> {
    return this.doAPIRequest<IAccessGroup>(
      this.server + "/reseller/contact/portalaccessgroups/add",
      newGroup
    );
  }

  public resellerCustomerDefaultPortalAccessGroupsAdd(
    newGroup: IAccessGroup
  ): Promise<IAccessGroup> {
    return this.doAPIRequest<IAccessGroup>(
      this.server + "/reseller/contact/customer/default/portalaccessgroups/add",
      newGroup
    );
  }

  public getAccessGroups(customersId?: string): Promise<IAccessGroup[]> {
    let payload: any = {};
    if (customersId !== undefined) {
      payload.customersId = customersId;
    }
    //console.log(payload);
    return new Promise<IAccessGroup[]>((resolve, reject) => {
      this.doAPIRequest<IAccessGroupHttpResponse>(
        this.server + "/customer/contact/portalaccessgroups/get",
        payload
      )
        .then((response) => {
          resolve(response.items);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public wallboardsSynchronise(customersId:string,resetStats:boolean):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/customer/contact/wallboards/synchronise',{customersId,resetStats})
  }
  
  public wallboardsGet(
    customersId: string
  ): Promise<IWallboardLayoutsHttpResponse> {
    return this.doAPIRequest<IWallboardLayoutsHttpResponse>(
      this.server + "/customer/contact/wallboards/get",
      { customersId }
    );
  }

  public wallboardGet(id: string): Promise<IWallboardLayoutHttpResponse> {
    return this.doAPIRequest<IWallboardLayoutHttpResponse>(
      this.server + "/customer/contact/wallboard/get",
      { id }
    );
  }

  public wallboardLayoutUpdate(
    id: string,
    name: string
  ): Promise<IWallboardLayout> {
    return this.doAPIRequest<IWallboardLayout>(
      this.server + "/customer/contact/wallboard/update",
      { id, name }
    );
  }

  public wallboardLayoutRemove(id:string):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+"/customer/contact/wallboard/remove",{id})
  }

  public wallboardWidgetUpdate(
    id: string,
    widget: { [id: string]: any }
  ): Promise<IWallboardWidgetSetting> {
    widget.id = id;
    return this.doAPIRequest<IWallboardWidgetSetting>(
      this.server + "/customer/contact/wallboardwidget/update",
      widget
    );
  }

  public logOutHotDesk(
    id: string,
    hotDeskNameToLogOut: string
  ): Promise<IWallboardWidgetSetting> {
    return this.doAPIRequest<IWallboardWidgetSetting>(
      this.server + "/customer/contact/wallboardwidget/update",{id,hotDeskNameToLogOut});
  }

  public logInHotDesk(
    id: string,
    hotDeskNameToLogIn: string,
    extensionNameToLogIn:string
  ): Promise<IWallboardWidgetSetting> {
    return this.doAPIRequest<IWallboardWidgetSetting>(
      this.server + "/customer/contact/wallboardwidget/update",{id,hotDeskNameToLogIn,extensionNameToLogIn});
  }

  public wallboardWidgetAddResetEvent(id:string,resetTimeToAdd:string):Promise<IWallboardWidgetSetting> {
    return this.doAPIRequest<IWallboardWidgetSetting>(this.server+'/customer/contact/wallboardwidget/update',{id,resetTimeToAdd})
  }

  public wallboardWidgetDeleteResetEvent(id:string,resetEventToRemoveId:string):Promise<IWallboardWidgetSetting> {
    return this.doAPIRequest<IWallboardWidgetSetting>(this.server+'/customer/contact/wallboardwidget/update',{id,resetEventToRemoveId})
  }

  //resellersContactsId can be an empty string to get the groups for the currently logged in reseller
  public resellerPortalAccessGroupsGet(): Promise<IAccessGroupHttpResponse> {
    return this.doAPIRequest<IAccessGroupHttpResponse>(
      this.server + "/reseller/contact/portalaccessgroups/get",
      {}
    );
  }

  public resellerUpdateCustomerBlockList(
    id: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/customers/blocklist/update",
      { id }
    );
  }

  public resellerDefaultCustomerPortalAccessGroupsGet(): Promise<IAccessGroupWithPermissionsTreeHttpResponse> {
    return this.doAPIRequest<IAccessGroupWithPermissionsTreeHttpResponse>(
      this.server + "/reseller/contact/customer/defaultgroups/get",
      {}
    );
  }

  public portalAccessGroupsUpdate(update: IAccessGroup): Promise<IAccessGroup> {
    return this.doAPIRequest<IAccessGroup>(
      this.server + "/customer/contact/portalaccessgroups/update",
      update
    );
  }

  public resellerPortalAccessGroupsUpdate(
    update: IAccessGroup
  ): Promise<IAccessGroup> {
    return this.doAPIRequest<IAccessGroup>(
      this.server + "/reseller/contact/portalaccessgroups/update",
      update
    );
  }

  public resellerCustomerDefaultPortalAccessGroupsUpdate(
    update: IAccessGroup
  ): Promise<IAccessGroup> {
    return this.doAPIRequest<IAccessGroup>(
      this.server + "/reseller/contact/customer/default/portalaccessgroups/update",
      update
    );
  }

  public portalAccessGroupsRemove(id: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/portalaccessgroups/remove",
      { id }
    );
  }

  public virtualReceptionistContactsGet(numbersId: string): Promise<IPerson[]> {
    return this.doAPIRequest<IPerson[]>(
      this.server + "/customer/contact/virtualreceptionist/contacts/get",
      { numbersId }
    );
  }

  public virtualReceptionistMessagesGet(
    numbersId: string
  ): Promise<IVirtualReceptionistMessage[]> {
    return this.doAPIRequest<IVirtualReceptionistMessage[]>(
      this.server + "/customer/contact/virtualreceptionist/messages/get",
      { numbersId }
    );
  }

  public virtualReceptionistMessageGet(
    id: string
  ): Promise<IVirtualReceptionistMessage> {
    return this.doAPIRequest<IVirtualReceptionistMessage>(
      this.server + "/customer/contact/virtualreceptionist/message/get",
      { id }
    );
  }

  public resellerPortalAccessGroupsRemove(
    id: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/portalaccessgroups/remove",
      { id }
    );
  }

  public resellerCustomerDefaultPortalAccessGroupsRemove(
    id: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/customer/default/portalaccessgroups/remove",
      { id }
    );
  }

  public updatePerson(request: IPerson): Promise<IPerson> {
    return this.doAPIRequest<IPerson>(
      this.server + "/customer/contact/person/update",
      request
    );
  }

  public addPerson(request: IPerson): Promise<IPerson> {
    return this.doAPIRequest<IPerson>(
      this.server + "/customer/contact/person/add",
      request
    );
  }

  public resellerPersonAdd(request: IPerson): Promise<IPerson> {
    return this.doAPIRequest<IPerson>(
      this.server + "/reseller/contact/person/add",
      request
    );
  }

  public resellerPersonUpdate(request: IPerson): Promise<IPerson> {
    return this.doAPIRequest<IPerson>(
      this.server + "/reseller/contact/person/update",
      request
    );
  }

  // incoming call stats
  public incomingCallStatsGet(
    request: IIncomingCallStatsHttpRequest
  ): Promise<IIncomingCallStatsHttpResponse> {
    return new Promise<IIncomingCallStatsHttpResponse>((resolve, reject) => {
      this.doAPIRequest<IIncomingCallStatsHttpResponse>(
        this.server + "/customer/contact/incomingcallstats",
        request
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // number

  public resellerNumbersAlist(
    amount: number,
    areaCode: string,
    consecutive: boolean
  ): Promise<INumbersAlistResponse> {
    return this.doAPIRequest<INumbersAlistResponse>(
      this.server + "/reseller/contact/numbers/alist",
      { amount, areaCode, consecutive }
    );
  }

  public resellerNumbersAllocation(
    numbers: INumberAllocationRequest[],
    consecutive: boolean
  ): Promise<INumbersAllocateResponse> {
    return this.doAPIRequest<INumbersAllocateResponse>(
      this.server + "/reseller/contact/numbers/allocate",
      { numbers, consecutive }
    );
  }
  /*
    public resellerNumberPortAdd(customersSitesId: string, numberPort: string[], multiLineNumber: boolean, numberPortTemplate: IFileUpload[]): Promise<ISuccessAPIResponse> {
        return this.doAPIRequest<ISuccessAPIResponse>(this.server + '/reseller/contact/numberport/add', {
            customersSitesId,
            numberPort,
            multiLineNumber,
            numberPortTemplate
        })
    }
*/
  public resellerInternetServiceEnquiryAdd(
    enquiry: IResellerInternetServiceEnquiry
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/internetserviceenquiry/add",
      enquiry
    );
  }

  public resellerInternetServiceEnquiryAccept(
    id: string,
    quoteAccepted: string,
    quoteRejectedNotes: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/internetserviceenquiry/accept",
      { id, quoteAccepted, quoteRejectedNotes }
    );
  }

  public resellerVoipIntegrationAdd(
    customersSitesId: string,
    softwareName: string,
    integrationLevel: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/voipintegration/add",
      {
        customersSitesId,
        softwareName,
        integrationLevel,
      }
    );
  }

  public resellerVirtualReceptionistAdd(
    customersSitesId: string,
    notes: string,
    //vrSpecialInstructions: string,
    userIds: string[]
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/virtualreceptionist/add",
      {
        customersSitesId,
        notes,
        //vrSpecialInstructions,
        userIds,
      }
    );
  }

  public resellerWallboardAdd(
    customersSitesId: string,
    notes: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/wallboard/add",
      {
        customersSitesId,
        notes
      }
    );
  }

  public resellerTeamsCallRecordingAdd(
    customersSitesId: string,
    notes: string,
    teamsCallRecordingLicenseLimit: number,
    userIds: string[]
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/teamscallrecording/add",
      {
        customersSitesId,
        notes,
        teamsCallRecordingLicenseLimit,
        userIds,
      }
    );
  }

  public resellerTeamsCallRecordingCancel(id: string): Promise<IService> {
    return this.doAPIRequest<IService>(
      this.server + "/reseller/contact/teamscallrecording/cancel",
      {
        id,
      }
    );
  }

  public resellerVoipServiceAdd(
    request: INewServiceVoipServiceRequest,
    ignoreAlreadyAdded: boolean
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/voipservice/add",
      {
        customersSitesId: request.customersSitesId,
        requestedDomainName: request.requestedDomainName,
        city: request.city,
        estimatedNumberOfExtensions: request.estimatedNumberOfExtensions,
        requiresSoftwareIntegration: request.requiresSoftwareIntegration,
        notes: request.notes,
        ignoreAlreadyAdded,
        type:request.type
      }
    );
  }

  // delivery address should be the information from the site presented as a string with line breaks.
  public resellerBuyPhones(
    customersSitesId: string,
    deliveryAddress: string,
    lines: IBuyPhonesLine[]
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/buyphones",
      {
        customersSitesId,
        deliveryAddress,
        lines,
      }
    );
  }

  public numberEHAUpdate(number: INumber): Promise<INumber> {
    return new Promise<INumber>((resolve, reject) => {
      if (
        number.ehaTitle !== undefined &&
        number.ehaThoroughfare !== undefined &&
        number.ehaPremises !== undefined &&
        number.ehaPostcode !== undefined &&
        number.ehaName !== undefined &&
        number.ehaLocality !== undefined &&
        number.ehaHonours !== undefined &&
        number.ehaForename !== undefined &&
        number.ehaBussuffix !== undefined
      ) {
        const eha: INumberEHAUpdateRequest = {
          id: number.id,
          TITLE: number.ehaTitle,
          THOROUGHFARE: number.ehaThoroughfare,
          PREMISES: number.ehaPremises,
          POSTCODE: number.ehaPostcode,
          NAME: number.ehaName,
          LOCALITY: number.ehaLocality,
          HONOURS: number.ehaHonours,
          FORENAME: number.ehaForename,
          BUSSUFFIX: number.ehaBussuffix,
        };

        this.doAPIRequest<INumber>(
          this.server + "/customer/contact/number/eha/update",
          eha
        )
          .then((number) => {
            resolve(number);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(false);
      }
    });
  }

  public numberFaxToEmail(request: INumberFaxToEmailRequest): Promise<INumber> {
    return new Promise<INumber>((resolve, reject) => {
      this.doAPIRequest<INumber>(
        this.server + "/customer/contact/number/faxtoemail",
        request
      )
        .then((number) => {
          resolve(number);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // number trackers

  // /customer/contact/numbertrackers/get​

  public numberTrackerAdd(request: INumberTracker): Promise<INumberTracker> {
    return new Promise<INumberTracker>((resolve, reject) => {
      this.doAPIRequest<INumberTracker>(
        this.server + "/customer/contact/numbertracker/add",
        request
      )
        .then((tracker) => {
          resolve(tracker);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public numberTrackersGet(
    owner: string,
    area: string,
    notes: string,
    customersId?: string
  ): Promise<INumberTrackersGetHttpResponse> {
    let data: any = { owner: owner, area: area, notes: notes };

    if (customersId !== undefined) {
      data.customersId = customersId;
    }

    return this.doAPIRequest<INumberTrackersGetHttpResponse>(
      this.server + "/customer/contact/numbertrackers/get",
      data
    );
  }

  public numberTrackerGet(id: string): Promise<INumberTracker> {
    return this.doAPIRequest<INumberTracker>(
      this.server + "/customer/contact/numbertracker/get",
      { id }
    );
  }

  public numberTrackerRemove(id: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/numbertracker/remove",
      { id }
    );
  }

  public numberTrackerUpdate(request: INumberTracker): Promise<INumberTracker> {
    return this.doAPIRequest<INumberTracker>(
      this.server + "/customer/contact/numbertracker/update",
      request
    );
  }

  public connectwiseContactsIsAuthed(): Promise<IConnectwiseContactsAuthedResponse> {
    return this.doAPIRequest<IConnectwiseContactsAuthedResponse>(
      this.server + "/customer/contact/connectwise/contacts/authed",
      {}
    );
  }

  // office365
  public office365IsAuthed(): Promise<IOffice365AuthedResponse> {
    return this.doAPIRequest<IOffice365AuthedResponse>(
      this.server + "/customer/contact/office365/authed",
      {}
    );
  }

  public actionStepIsAuthed(): Promise<IOffice365AuthedResponse> {
    return this.doAPIRequest<IOffice365AuthedResponse>(
      this.server + "/customer/contact/actionstep/authed",
      {}
    );
  }

  public teamsCallRecordingIsAuthed(
    state: string
  ): Promise<IOffice365AuthedResponse> {
    return this.doAPIRequest<IOffice365AuthedResponse>(
      this.server + "/customer/contact/teamscallrecoding/authed",
      { state }
    );
  }

  public teamsCallRecordingUsers(
    state: string
  ): Promise<ITeamsCallRecordingUsersResponse> {
    return this.doAPIRequest<ITeamsCallRecordingUsersResponse>(
      this.server + "/customer/contact/teamscallrecoding/users",
      { state }
    );
  }

  public teamsCallRecordingUsersUpdate(
    state: string,
    ids: string[]
  ): Promise<ITeamsCallRecordingUsersResponse> {
    return this.doAPIRequest<ITeamsCallRecordingUsersResponse>(
      this.server + "/customer/contact/teamscallrecoding/users/update",
      { state, ids }
    );
  }

  public actionStepUpdateSettings(
    newsettings: IOffice365AuthedResponse
  ): Promise<IOffice365AuthedResponse> {
    return this.doAPIRequest<IOffice365AuthedResponse>(
      this.server + "/customer/contact/actionstep/authed",
      newsettings
    );
  }

  public teamsCallRecordingUpdateSettings(
    newsettings: IOffice365AuthedResponse
  ): Promise<IOffice365AuthedResponse> {
    return this.doAPIRequest<IOffice365AuthedResponse>(
      this.server + "/customer/contact/teamscallrecording/authed",
      newsettings
    );
  }

  public portalHelpRequest(enquiry: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/help/request",
      { enquiry }
    );
  }

  //provisioning
  public provisioningGet(
    request: IProvisioningGetHttpRequest
  ): Promise<IProvisioningGetHttpResponse> {
    return this.doAPIRequest<IProvisioningGetHttpResponse>(
      this.server + "/customer/contact/provisioning/get",
      request
    );
  }

  public provisioningUpdate(
    request: IProvisioningRecord
  ): Promise<IProvisionedDevice> {
    return this.doAPIRequest<IProvisionedDevice>(
      this.server + "/customer/contact/provisioning/update",
      request
    );
  }

  public provisioningRemove(uuid: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/customer/contact/provisioning/device/remove",
      { uuid }
    );
  }

  public resellerProvisioningSettingsGet(): Promise<IProvisioningSettings> {
    return this.doAPIRequest<IProvisioningSettings>(
      this.server + "/reseller/contact/provisioning/get",
      {}
    );
  }

  public resellerProvisioningUpdate(
    request: IProvisioningSettings
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/provisioning/update",
      request
    );
  }

  public voipServiceAPIKeysGet(
    id: string
  ): Promise<IVoipServiceAPIKeysResponse> {
    return this.doAPIRequest<IVoipServiceAPIKeysResponse>(
      this.server + "/reseller/contact/voipservices/apikeys/get",
      { id }
    );
  }

  public voipServiceCallRecordingsUpdate(
    request: IVoipServiceCallRecordingsUpdateRequest
  ): Promise<IVoipService> {
    return this.doAPIRequest<IVoipService>(
      this.server + "/reseller/contact/voipservices/callrecordings/update",
      request
    );
  }

  public voipServiceSettingsUpdate(
    request: IVoipServiceSettingsUpdateRequest
  ): Promise<IVoipService> {
    return this.doAPIRequest<IVoipService>(
      this.server + "/reseller/contact/voipservices/callrecordings/update",
      request
    );
  }

  public voipServiceProvisioningUpdate(
    request: IVoipServiceProvisioningUpdateRequest
  ): Promise<IVoipService> {
    return this.doAPIRequest<IVoipService>(
      this.server + "/customer/contact/voipservices/provisioning/update",
      request
    );
  }

  public voipServicePrepayToggle(id: string): Promise<IVoipService> {
    return this.doAPIRequest<IVoipService>(
      this.server + "/reseller/contact/voipservice/prepay/toggle",
      { id }
    );
  }

  public getPrepayTransactions(
    id: string
  ): Promise<IPrepayTransactionsGetResponse> {
    return this.doAPIRequest<IPrepayTransactionsGetResponse>(
      this.server +
      "/reseller/contact/customer/service/prepay/transactions/get",
      { id }
    );
  }

  public prepayTransactionsAdd(
    transaction: IPrepayTransactionAddRequest
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server +
      "/reseller/contact/customer/service/prepay/transactions/add",
      transaction
    );
  }

  public areaCodesGet(): Promise<IAreaCodesResponse> {
    return this.doAPIRequest<IAreaCodesResponse>(
      this.server + "/reseller/contact/areacodes/get",
      {}
    );
  }

  public resellerContactResetMFAForCustomerContact(
    id: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/customer/contact/mfa/reset",
      { id }
    );
  }

  public creditNoteGet(id: string): Promise<ICreditNoteGetResponse> {
    return this.doAPIRequest<ICreditNoteGetResponse>(
      this.server + "/reseller/contact/creditnote/get",
      { id }
    );
  }

  public creditNotesGet(
    from: string,
    to: string,
    displayNumber: string
  ): Promise<ICreditNotesGetResponse> {
    return this.doAPIRequest<ICreditNotesGetResponse>(
      this.server + "/reseller/contact/creditnotes/get",
      {
        from,
        to,
        displayNumber,
      }
    );
  }

  public invoicesGet(
    from: string,
    to: string,
    displayNumber: string
  ): Promise<IInvoicesGetResponse> {
    return this.doAPIRequest<IInvoicesGetResponse>(
      this.server + "/reseller/contact/invoices/get",
      { from, to, number: displayNumber }
    );
  }

  public invoiceGet(id: string): Promise<IInvoiceGetResponse> {
    return this.doAPIRequest<IInvoiceGetResponse>(
      this.server + "/reseller/contact/invoice/get",
      { id }
    );
  }

  // reports
  public reportsGetLink(): string {
    return this.server + "/customer/contact/reports/link/get";
  }

  // sites
  public siteGet(id: string): Promise<ISite> {
    return new Promise<ISite>((resolve, reject) => {
      this.doAPIRequest<ISite>(this.server + "/customer/contact/site/get", {
        id,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public sitesGet(
    customersId: string,
    servicesOnly: boolean,
    associatedOnly: boolean
  ): Promise<ISitesGetHttpResponse> {
    return this.doAPIRequest<ISitesGetHttpResponse>(
      this.server + "/customer/contact/sites/get",
      {
        customersId,
        servicesOnly,
        associatedOnly,
      }
    );
  }

  public sitesAdd(site: ISite): Promise<ISite> {
    return new Promise<ISite>((resolve, reject) => {
      this.doAPIRequest<ISite>(
        this.server + "/customer/contact/sites/add",
        site
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public sitesUpdate(site: ISite): Promise<ISite> {
    return new Promise<ISite>((resolve, reject) => {
      this.doAPIRequest<ISite>(
        this.server + "/customer/contact/sites/update",
        site
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public sitesRemove(id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.doAPIRequest<ISuccessAPIResponse>(
        this.server + "/customer/contact/sites/remove",
        { id }
      )
        .then((result) => {
          resolve(result.success);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // reseller
  public resellerCustomerAdd(
    addCustomerRequest: ICustomerAddRequest
  ): Promise<ICustomer> {
    return this.doAPIRequest<ICustomer>(
      this.server + "/reseller/contact/customers/add",
      addCustomerRequest
    );
  }

  // reseller tariff
  public resellerCustomerTariffUpdate(
    tariff: ICustomerTariffUpdateRequest
  ): Promise<ICustomer> {
    return this.doAPIRequest<ICustomer>(
      this.server + "/reseller/contact/customers/updateTariff",
      tariff
    );
  }

  public resellerCustomerRemove(id: string): Promise<IMessageAPIResponse> {
    return this.doAPIRequest<IMessageAPIResponse>(
      this.server + "/reseller/contact/customer/remove",
      { id }
    );
  }

  public resellerCustomersGetNamePartial(name: string): Promise<ICustomer[]> {
    return this.doAPIRequest<ICustomer[]>(
      this.server + "/reseller/contact/customers/get",
      { name }
    );
  }

  public resellerCustomersAndSitesGetNamePartial(
    name: string
  ): Promise<ISiteWithName[]> {
    return this.doAPIRequest<ISiteWithName[]>(
      this.server + "/reseller/contact/customersandsites/get",
      { name }
    );
  }

  public resellerPSAProductsGetNamePartial(
    name: string
  ): Promise<IResellerPSAProductMapping[]> {
    return this.doAPIRequest<IResellerPSAProductMapping[]>(
      this.server + "/reseller/contact/resellerpsaproducts/get",
      { name }
    );
  }

  public resellerPSACustomerMappingUpdate(
    id: string,
    connectwiseManageCompaniesId: string,
    connectwiseManageAgreementsId: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/psa/customers/mapping/update",
      { id, connectwiseManageCompaniesId, connectwiseManageAgreementsId }
    );
  }

  public resellerPSAProductMappingUpdate(
    productsId: string,
    connectwiseManageProductId: string
  ): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/psa/products/mapping/update",
      { productsId, connectwiseManageProductId }
    );
  }

  public resellerPSACustomersGet(): Promise<IPSACustomer[]> {
    return this.doAPIRequest<IPSACustomer[]>(
      this.server + "/reseller/contact/psa/customers/get",
      {}
    );
  }

  public resellerPSAAgreementsGet(companyId: string): Promise<IPSACustomer[]> {
    return this.doAPIRequest<IPSACustomer[]>(
      this.server + "/reseller/contact/psa/agreements/get",
      { companyId }
    );
  }

  public resellerPSAAgreementTypesGet(): Promise<IPSAAgreementType[]> {
    return this.doAPIRequest<IPSAAgreementType[]>(
      this.server + "/reseller/contact/psa/agreementtypes/get",
      {}
    );
  }

  public resellerPSABillingCyclesGet(): Promise<IPSABillingCycle[]> {
    return this.doAPIRequest<IPSABillingCycle[]>(
      this.server + "/reseller/contact/psa/billingcycles/get",
      {}
    );
  }

  public resellerPSAProductsGet(): Promise<IPSAProduct[]> {
    return this.doAPIRequest<IPSAProduct[]>(
      this.server + "/reseller/contact/psa/products/get",
      {}
    );
  }

  public resellerPSAReconciliationsGet(): Promise<IPSAReconciliationGetResponse> {
    return this.doAPIRequest<IPSAReconciliationGetResponse>(
      this.server + "/reseller/contact/psa/reconciliations/get",
      {}
    );
  }

  public resellerPSAReconciliationsUpdate(
    reconciliations: IPSAReconciliation[]
  ): Promise<IPSAReconciliation[]> {
    return this.doAPIRequest<IPSAReconciliation[]>(
      this.server + "/reseller/contact/psa/reconciliations/update",
      { reconciliations }
    );
  }

  public resellerCustomerTariffs(customerId:string,isDomestic:boolean): Promise<ITariffsGetResponse> {
    return this.doAPIRequest<ITariffsGetResponse>(
      this.server + "/reseller/contact/tariffs/get",
      {customerId,isDomestic}
    );
  }

  public resellerTariffBundles(customerId:string,isDomestic:boolean): Promise<ITariffsGetResponse> {
    return this.doAPIRequest<ITariffsGetResponse>(
      this.server + "/reseller/contact/tariffs/bundles/get",
      {customerId,isDomestic}
    );
  }

  public resellerStandardRetailTariff(): Promise<IStandardRetailTariffGetResponse> {
    return this.doAPIRequest<IStandardRetailTariffGetResponse>(
      this.server + "/reseller/contact/tariffs/standardretail/get",
      {}
    );
  }

  public resellerFullRetailTariff(): Promise<IStandardFullTariffGetResponse> {
    return this.doAPIRequest<IStandardFullTariffGetResponse>(
      this.server + "/reseller/contact/tariffs/standardfull/get",
      {}
    );
  }

  public resellerTariffAdd(tariff: ITariff): Promise<ITariff> {
    return this.doAPIRequest<ITariff>(
      this.server + "/reseller/contact/tariffs/add",
      tariff
    );
  }

  public resellerTariffUpdate(tariff: ITariff): Promise<ITariff> {
    return this.doAPIRequest<ITariff>(
      this.server + "/reseller/contact/tariff/update",
      tariff
    );
  }

  public resellerTariffGet(id: string): Promise<ITariffGetResponse> {
    return this.doAPIRequest<ITariffGetResponse>(
      this.server + "/reseller/contact/tariff/get",
      { id }
    );
  }

  public resellerCustomerDetailsGet(
    id: string
  ): Promise<ICustomerDetailsResponse> {
    return this.doAPIRequest<ICustomerDetailsResponse>(
      this.server + "/reseller/contact/customers/detail",
      { id }
    );
  }

  // also used by customers
  public resellerDeviceAdd(
    uuid: string,
    customersId: string,
    customersSitesId: string,
    asteriskServerExtensionsId: string,
    customersSitesId2: string,
    asteriskServerExtensionsId2: string,
    deskPhoneId: string,
    provisioningSettings: IProvisioningSettings
  ): Promise<IProvisionedDeviceResponse> {
    return this.doAPIRequest<IProvisionedDeviceResponse>(
      this.server + "/reseller/contact/device/add",
      {
        uuid,
        asteriskServerExtensionsId,
        asteriskServerExtensionsId2,
        customersId,
        deskPhoneId,
        customersSitesId,
        customersSitesId2,
        provisioningSettings,
      }
    );
  }

  public resellerCustomerSearch(query: string,showArchived:boolean): Promise<ICustomersSearchItems> {
    return this.doAPIRequest<ICustomersSearchItems>(
      this.server + "/reseller/contact/customers/search",
      { query,showArchived }
    );
  }

  public resellerCustomersList(): Promise<ICustomer[]> {
    return this.doAPIRequest<ICustomer[]>(
      this.server + "/reseller/contact/customers/search",
      { }
    );
  }

  public resellerCustomerUpdate(
    update: ICustomerUpdateRequest
  ): Promise<ICustomer> {
    return this.doAPIRequest<ICustomer>(
      this.server + "/reseller/contact/customers/update",
      update
    );
  }

  public resellerAvailableProductsGet(): Promise<IAvailableProductsResponse> {
    return this.doAPIRequest<IAvailableProductsResponse>(
      this.server + "/reseller/contact/products/get",
      {}
    );
  }

  public resellerPSAConfigureGet(): Promise<IResellerPSAConfiguration> {
    return this.doAPIRequest<IResellerPSAConfiguration>(
      this.server + "/reseller/contact/psa/configuration/get",
      {}
    );
  }

  public resellerPSAConfigureUpdate(
    configuration: IResellerPSAConfiguration
  ): Promise<IResellerPSAConfiguration> {
    return this.doAPIRequest<IResellerPSAConfiguration>(
      this.server + "/reseller/contact/psa/configuration/update",
      configuration
    );
  }

  public connectwiseContactsConfigureUpdate(
    configuration: IConnectwiseContactsAuthedResponse
  ): Promise<IConnectwiseContactsAuthedResponse> {
    return this.doAPIRequest<IConnectwiseContactsAuthedResponse>(
      this.server + "/customer/contact/connectwise/contacts/configuration/update",
      configuration
    );
  }

  public resellerBrandingGet(): Promise<IBranding> {
    return this.doAPIRequest<IBranding>(
      this.server + "/reseller/contact/branding/get",
      {}
    );
  }

  public resellerAssociate(id: string): Promise<ISuccessAPIResponse> {
    return this.doAPIRequest<ISuccessAPIResponse>(
      this.server + "/reseller/contact/associate",
      { id }
    );
  }

  public resellerCab9WallboardCreate(serviceId:string):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/reseller/contact/cab9/wallboard/create',{id:serviceId})
  }

  public resellerCab9WallboardGet(serviceId:string):Promise<IResellerCab9WallboardGetResponse>{
    return this.doAPIRequest<IResellerCab9WallboardGetResponse>(this.server+'/reseller/contact/cab9/wallboard/get',{id:serviceId})
  }

  public resellerPassengerConnectTest(serviceId:string,caller:string,destination:string,callerId:string):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/reseller/contact/ringbacktest',{id:serviceId,caller,destination,callerId})
  }

  public resellerRingbackTest(serviceId:string,extension:string,destination:string,callerId:string):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/reseller/contact/ringbacktest',{id:serviceId,extension,destination,callerId})
  }

  public resellerDeleteCallRecordingWebHook(serviceId:string,webhook:IResellerCab9CallPoppingWebHook):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/reseller/contact/ringbacktest',{id:serviceId,cmd:'deleteCallRecordingWebHook',url:webhook.url,customerToken:webhook.token})
  }

  public resellerDeleteWallboardWebHook(serviceId:string,webhook:IResellerCab9WallboardWebhook):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/reseller/contact/ringbacktest',{id:serviceId,cmd:'deleteWallboardWebHook',url:webhook.url,customerToken:webhook.token,password:webhook.password})
  }

  public resellerDeleteCallPoppingWebHook(serviceId:string,webhook:IResellerCab9CallPoppingWebHook):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/reseller/contact/ringbacktest',{id:serviceId,cmd:'deleteCallPoppingWebHook',url:webhook.url,customerToken:webhook.token})
  }

  public resellerWallboardWebHookAdd(serviceId:string,url:string,customerToken:string,password:string):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/reseller/contact/ringbacktest',{id:serviceId,cmd:'addWallboardWebHook',url,customerToken,password})
  }

  public resellerCallRecordingWebHookAdd(serviceId:string,url:string,customerToken:string):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/reseller/contact/ringbacktest',{id:serviceId,cmd:'addCallRecordingWebHook',url,customerToken})
  }

  public resellerCallPoppingWebHookAdd(serviceId:string,url:string,customerToken:string):Promise<ISuccessAPIResponse>{
    return this.doAPIRequest<ISuccessAPIResponse>(this.server+'/reseller/contact/ringbacktest',{id:serviceId,cmd:'addCallPoppingWebHook',url,customerToken})
  }


  // wallboards
  public wallboardsGetLink(): string {
    return this.server + "/customer/contact/wallboards/link/get";
  }

  public provisioningIdGet(
    customersId: string
  ): Promise<IProvisioningIDResponse> {
    return this.doAPIRequest<IProvisioningIDResponse>(
      this.server + "/customer/contact/provisioningid/get",
      { customersId }
    );
  }

  public vrCustomersGet(name: string): Promise<INumber[]> {
    return this.doAPIRequest<INumber[]>(this.server + "/vr/customers/get", {
      name,
    });
  }

  public vrMessageAdd(
    message: IVirtualReceptionistMessage
  ): Promise<IVirtualReceptionistMessage> {
    return this.doAPIRequest<IVirtualReceptionistMessage>(
      this.server + "/vr/message/add",
      message
    );
  }

  public vrNumberRedirect(number: string): Promise<INumber> {
    return this.doAPIRequest<INumber>(this.server + "/vr/number/redirect", {
      number,
    });
  }

  // General
  public doAPIRequest = <T>(url: string, body: any): Promise<T> => {
    //console.log(url);
    return new Promise<T>((resolve, reject) => {
      axios
        .post(url, body, { withCredentials: true })
        .then((response) => {
          //console.log(response);
          return response.data;
        })
        .then((data: T) => {
          //    console.log(data);
          resolve(data);
        })
        .catch((error) => {
          console.log("fetchError", error.response);
          reject(error.response);
        });
    });
  };
  private makeAPIRequest = (url: string, body: any): Promise<any> => {
    //    console.log(url)
    return this.doAPIRequest<any>(url, body);
  };
}

import React from "react";

import { MenuItem, IconButton, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../../theme";
import { Link } from "react-router-dom";

interface IDropdownProps {
  options: { label: string; link?: string; onClick?: () => void }[];
  label?: string;
  required?: boolean;
}

const MuiDropdownDots: React.FC<IDropdownProps> = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <ThemeProvider theme={theme}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            // maxHeight: 48 * 4.5,
            // width: "20ch",
            border: "1px solid #C7C8CB",
          },
        }}
      >
        {options.map((option) =>
          option.link ? (
            <MenuItem
              key={option.link + option.label}
              value=""
              // @ts-ignore
              component={Link}
              onClick={handleClose}
              to={option.link}
              style={{ color: "inherit" }}
            >
              {option.label}
            </MenuItem>
          ) : (
            <MenuItem
              key={option.label}
              value=""
              onClick={() => {
                option.onClick!();
                handleClose();
              }}
              style={{ color: "inherit" }}
            >
              {option.label}
            </MenuItem>
          )
        )}
      </Menu>
    </ThemeProvider>
  );
};
export default MuiDropdownDots;

import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import { Button } from "react-bootstrap";

import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import PageHead from "../../components/PageHead";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { Link, RouteComponentProps } from "react-router-dom";
import VipBurgerMenu from "../../components/VipBurgerMenu";
import App from "../../App";
import image from "../../compiledSVG/undraw_people_search_wctu.svg";
import { ICustomerAllowDialCode } from "../../interfaces/customer";
import { IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";


const set = new MySettings();

interface RouteParams {}

const CustomerAllowDialCodes: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = ({ resellerID }) => {
  const [numbers, setNumbers] = useState<ICustomerAllowDialCode[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const getNumbers = async () => {
      try {
        const data = await set.customerAllowDialCodesGet(
          resellerID ? resellerID : ""
        );
        if (isMounted) {
          setNumbers(data);
          setLoading(false);
        }
      } catch (error) {
        App.newNotificationError(error.data);
      }
    };

    getNumbers();

    return () => {
      isMounted = false;
    };
  }, [resellerID]);

  const handleNumberDelete = async (id: string) => {
    if (window.confirm("Are you sure you want to remove this dial code?")) {
      try {
        setLoading(true);

        let success = await set.customerAllowDialCodesRemove(id);

        if (success.success) {
          setNumbers(numbers.filter((number) => number.id !== id));

          const notification: INotification = {
            title: "Success",
            data: "Dial code deleted.",
            variant: NOTIFICATION_TYPE.success,
            srcFunction: "handleNumberDelete",
            srcData: window.location.pathname,
            srcComponent: "CustomerBlockLists",
          };
          App.newNotification(notification);
        } else {
          throw new Error("Failed to remove the dial code.");
        }
      } catch (error) {
        App.newNotificationError(error.data);
      }
      setLoading(false);
    }
  };

  const onAddMainCountriesToDialCodeClick = async () => {
    setLoading(true);
    try {
      await set.customerAllowDialCodesAddMain(resellerID ? resellerID : "");
      const data = await set.customerAllowDialCodesGet(
        resellerID ? resellerID : ""
      );
      setNumbers(data);
    } catch (error) {
      App.newNotificationError("Problem adding dial codes:" + error.data);
    }
    setLoading(false);
  };

  const customerAllowDialCodesRemoveMain = async () => {
    setLoading(true);
    try {
      await set.customerAllowDialCodesRemoveMain(resellerID ? resellerID : "");
      const data = await set.customerAllowDialCodesGet(
        resellerID ? resellerID : ""
      );
      setNumbers(data);
    } catch (error) {
      App.newNotificationError("Problem adding dial codes:" + error.data);
    }
    setLoading(false);
  };

  const columns = [
    {
      name: "dialCode",
      label: "Dial Code",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "id",
      label: " ",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ align:"right" }),
        customBodyRenderLite: (dataIndex: number) => {
          const element = numbers[dataIndex];
          return <IconButton  color="secondary"   onClick={(ev)=>{
            ev.preventDefault();
            handleNumberDelete(element.id);
          }}>
            <DeleteIcon /></IconButton>
        }
      },
    },
  ]


  // const table = [
  //   {
  //     Header: "Dial Code",
  //     accessor: "dialCode",
  //     sortable: true,
  //   },
  //   {
  //     Header: "",
  //     accessor: "id", // String-based value accessors!
  //     Cell: (props: any) => {
  //       return (
  //         <ButtonGroup aria-label="Basic example">
  //           <Button
  //             onClick={() => {
  //               handleNumberDelete(props.value);
  //             }}
  //             variant="danger"
  //           >
  //             Delete <FaIcon icon="user-minus" color="white" />
  //           </Button>
  //         </ButtonGroup>
  //       );
  //     },
  //   },
  // ];
  let newurl = "/allowdialcodesnew";
  if (resellerID && resellerID.length > 0) {
    newurl = "/reseller/" + resellerID + newurl;
  }
  return (
    <div className="content">
      <PageHead resellerID={resellerID} title="Allowed Dial Codes" parents={[]}>
        <VipBurgerMenu
          options={[
            {
              title: "New Allowed Dial Code",
              link: newurl,
              isExternal: false,
            },
            {
              title: "Add Main Countries To Allowed Dial Codes",
              onClick: () => {
                if (
                  window.confirm(
                    "Are you sure you want to update the allowed dial codes?"
                  )
                ) {
                  onAddMainCountriesToDialCodeClick();
                }
              },
              isExternal: false,
            },
            {
              title: "Remove Main Countries From Allowed Dial Codes",
              onClick: () => {
                if (
                  window.confirm(
                    "Are you sure you want to update the allowed dial codes?"
                  )
                ) {
                  customerAllowDialCodesRemoveMain();
                }
              },
              isExternal: false,
            },
          ]}
        />
      </PageHead>

      {loading ? (
        <Loading />
      ) : numbers.length > 0 ? (
        <MuiTableWrapper
            title=""
            data={numbers}
            columns={columns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: false,
              search: true,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "datetime",
                direction: "asc",
              },
            }}
          />
        
      ) : (
        <div className="text-center">
          <img src={image} className="img-fluid" alt="No Results Found" />
          <br />
          <br />
          <h2>No allowed dial codes found.</h2>
          <p>This customer has no white listed dial codes.</p>
          <Link to={newurl}>
            <Button variant="outline-primary" className="mt-4">
              <FaIcon icon="user-plus" color="primary" className="mr-2" />
              New Allowed Dial Code
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};
export default CustomerAllowDialCodes;

import * as React from "react";
import { InputGroup, Form } from "react-bootstrap";

// Simple footer component

// table object properties
export interface IVipTimeFieldProps {
  field: string;
  value: string;
  timeArray: number[];
  updateField: (field: any, payload: any) => void;
}

//
export interface IVipTimeFieldState {
  currentHr: Number;
  currentMin: Number;
}

export default class VipTimeField extends React.Component<
  IVipTimeFieldProps,
  IVipTimeFieldState
> {
  static defaultProps: { timeArray: number[] };

  constructor(props: any) {
    super(props);
    let hr = 0;
    let min = 0;

    if (this.props.value) {
      let s = this.props.value.split(":");
      hr = parseInt(s[0]);
      min = parseInt(s[1]);
    }

    this.state = { currentHr: hr, currentMin: min };
  }

  handelHourChange = (event: any) => {
    event.preventDefault();
   // console.log(event.target.value);
    this.setState({currentHr:parseInt(event.target.value)}, ()=>{
      this.updateFieldWithValue();
    });
  };

  handelMinChange = (event: any) => {
    event.preventDefault();
  //  console.log(event.target.value);
    this.setState({currentMin:parseInt(event.target.value)}, ()=>{
       this.updateFieldWithValue();
    });
  };
  updateFieldWithValue = () => {
    let x = this.state.currentHr.toString().padStart(2, "0");
    let y = this.state.currentMin.toString().padStart(2, "0");
    let time = x + ":" + y + ":00";
    // console.log(time);
    this.props.updateField(this.props.field, time);
  };
  public render() {
    const { currentHr, currentMin } = this.state;
    return (
      <InputGroup>
        <Form.Control
          as="select"
          value={currentHr.toString()}
          id={this.props.field + "-Hr"}
          onChange={this.handelHourChange}
        >
          {[...Array(24).keys()].map((val) => {
            return (
              <option key={val} value={val}>
                {val.toString().padStart(2, "0")}
              </option>
            );
          })}
        </Form.Control>
        <Form.Control
          as="select"
          value={currentMin.toString()}
          id={this.props.field + "-Min"}
          onChange={this.handelMinChange}
        >
          {this.props.timeArray.map((val) => {
            return (
              <option key={val} value={val}>
                {val.toString().padStart(2, "0")}
              </option>
            );
          })}
        </Form.Control>
      </InputGroup>
    );
  }
}

VipTimeField.defaultProps = {
  timeArray: [0, 10, 20, 30, 40, 50],
};

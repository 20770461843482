import * as React from "react";
import Button from "react-bootstrap/Button";
import { INotification } from "../interfaces/notification";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

export interface INotificationState {
  notifications: INotification[];
}

export default class Notifications extends React.Component<
  any,
  INotificationState
> {
  public state: any = {
    notifications: [],
  };

  public render() {
    const { notifications } = this.state;
    return (
      <>
        {this.state.notifications.length > 0 && (
          <div className="notificationBox">
            <div className="notificationsHolder">
              <Row>
                <Col md="12">
                  {notifications.map((note: any) => {
                    const handleDeleteClick = () => {
                      this.removeNotification(note);
                    };
                    return (
                      <div className="note" key={note.id}>
                        <Alert variant={note.variant}>
                          <Alert.Heading>{note.title}</Alert.Heading>
                          <p>{note.data}</p>
                          <hr />
                          <div className="d-flex justify-content-end">
                            <Button
                              id={"close" + note.id}
                              className="closeNotification mt-3"
                              onClick={handleDeleteClick}
                              variant="success"
                            >
                              Close
                            </Button>
                          </div>
                        </Alert>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </div>
          </div>
        )}
      </>
    );
  }

  public createNotification(note: INotification): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (!note.id) {
        note.id = Date.now().toString();
      }
      const arr = this.state.notifications;

      if (
        this.state.notifications.findIndex(
          (ddd: INotification) => ddd.data === note.data
        ) === -1
      ) {

        // check if weve just had a loged out warning

        if (
          this.state.notifications.findIndex(
            (ddd: INotification) => ddd.srcComponent === "LOGGEDOUT"
          ) === -1
        ) {
          arr.push(note);
          this.setState({ notifications: arr }, () => {
            setTimeout(() => {
              this.removeNotification(note);
            }, 7500);
            resolve(true);
          });
        } else {
          resolve(true);
        }
      }
    });
  }

  public removeNotification(note: INotification) {
    // remove alert in this controll
    const newArray: any[] = this.state.notifications;
    const index: number = newArray.findIndex((obj) => obj.id === note.id);
    newArray.splice(index, 1);
    this.setState({ notifications: newArray });
  }
}

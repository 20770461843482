import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import App from "../App";
import AccordionHeader from "../components/accordionHeader";
import Loading from "../components/loading";
import { NOTIFICATION_TYPE } from "../enum/notification-type";
import { INotification } from "../interfaces/notification";
import "../sass/sideBar.scss";
import MySettings from "../static/settings";

const set = new MySettings();

interface ResellerNotesProps {
  resellerID: string;
}

const ResellerNotes: React.FC<ResellerNotesProps> = ({ resellerID }) => {
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    let isMounted = true;

    const getNotes = async () => {
      try {
        const result = await set.resellerCustomerDetailsGet(resellerID);
        console.log("result", result);

        if (isMounted) {
          setLoading(false);
          setNotes(result.customer.notes);
        }
      } catch (error) {
        App.newNotificationError("Error loading notes.");
      }
    };

    getNotes();

    return () => {
      isMounted = false;
    };
  }, [resellerID]);

  const onSubmit = async (formData: { notes: string }) => {
    setLoading(true);
    try {
      await set.resellerCustomerUpdate({
        id: resellerID,
        notes: formData.notes,
      });
      const notification: INotification = {
        title: "Notes Updated",
        data: "The notes have been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "ResellerNotes",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification);
    } catch (err) {
      App.newNotificationError("Failed to submit notes " + err.data);
    }
    setNotes(formData.notes);
    setLoading(false);
  };

  return (
    <div className="mb-3">
      {loading ? (
        <Loading size={"100px"} />
      ) : (
        <Accordion className="full-width sidebar">
          <Card>
            <AccordionHeader
              eventKey="0"
              as={Card.Header}
              variant="link"
              style={{ backgroundColor: "#f4f7fd", cursor: "pointer" }}
            >
              <span className="sidebarUp" />
              Customer Notes
            </AccordionHeader>

            <Accordion.Collapse eventKey="0">
              <Form
                onSubmit={onSubmit}
                initialValues={{ notes: notes }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Card>
                      <Card.Body>
                        <label>Notes:</label>
                        <Field name="notes" component="textarea">
                          {({ input, meta }) => (
                            <>
                              <fieldset className="mainContent">
                                <textarea
                                  {...input}
                                  id={input.name.replace(".", "_")}
                                  className="form-control"
                                  rows={3}
                                />
                              </fieldset>
                              {meta.error && meta.touched && (
                                <span>{meta.error}</span>
                              )}
                            </>
                          )}
                        </Field>

                        <div className="float-right mt-3">
                          <Button
                            variant="primary"
                            className="noleft"
                            type="submit"
                            disabled={submitting || pristine}
                          >
                            Submit
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </form>
                )}
              />
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
    </div>
  );
};
export default ResellerNotes;


import * as React from 'react';

import { Table } from 'react-bootstrap';



const ReviewForm = (values: any) => {
  return <>


    <div className="wizard8__right-heading-div">
      <p className="wizard8__right-heading">Review Customer</p>

    </div>

    <div className="wizard8__permissions">
      <div className="wizard8__right-heading-div">
        <p className="wizard8__right-p">Numbers</p>
      </div>
    </div>

    <div>
      <Table responsive="sm">
        <thead>
          <tr>
            <th>Number</th>
            <th>Type</th>
            <th>Site</th>
          </tr>
        </thead>
        <tbody>
          {values.numbers.map((dd: any, index: number) => {

            return <tr key={index}>
              <td>{dd.number}</td>
              <td>{dd.numberType}</td>
              <td>{values.sites[parseInt(dd.siteIndex)].description}</td>
            </tr>
          })}




          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>

    <div className="wizard8__permissions">

      <p className="text-permissions">Check the information below is correct before creating this customer.</p>

      <div className="wizard8__right-heading-div">
        <p className="wizard8__right-p">Company Details</p>
      </div>

    </div>

    <div>
      <Table responsive="sm">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Phone Number</th>
            <th>Website</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <td >{values.companyName}</td>
            <td >{values.companyPhone}</td>
            <td >{values.companyWebsite}</td>

          </tr>

          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </Table>


      <div className="wizard8__right-heading-div">
        <p className="wizard8__right-p">Permissions</p>
      </div>


      <div>
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th></th>

            </tr>
          </thead>
          <tbody>

            {values.permissions.map((dd: any, index: number) => {
              return <tr key={index}>
                <td >{dd.name}</td>
                <td className="userDescription">{dd.description}</td>
                <td></td>

              </tr>
            })}


            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>

      </div>


      <div className="wizard8__right-heading-div">
        <p className="wizard8__right-p">Tariff</p>
      </div>


      <div>
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Type</th>
              <th>Tariff</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

            {values.tariffType === 'PPM' ? (<tr>
              <td >PPM</td>
              <td >{values.tariffName}</td>
              <td ></td>
            </tr>
            ) : (<tr>
              <td >Call Bundles</td>
              <td >

                <p>Bundle Size : {values.tariffName}</p>
                <p>Number of bundles : {values.numExtraBundlesStr ? values.numExtraBundlesStr : '1'}</p>
                <p>Bundle override : {values.bundleNumberOverride ? values.bundleNumberOverride : '0'}</p>
              </td>
              <td ></td>
            </tr>
              )}

            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>

      </div>

      <div className="wizard8__right-heading-div">
        <p className="wizard8__right-p">Sites</p>
      </div>


      <div>
        <Table responsive="sm">
          <thead>
            <tr>
              {values.sites.map((site: any, index: number) => {
                return <th key={index}>{site.name}</th>
              })}
            </tr>
          </thead>
          <tbody>


            <tr>
              {values.sites.map((site: any, index: number) => {
                return <td  key={index}>
                  {site.line1}<br></br>
                  {site.line1}<br></br>
                  {site.city}<br></br>
                  {site.county}<br></br>
                  {site.postCode}</td>
              })}
            </tr>

            <tr>
              {values.sites.map((site: any, index: number) => {
                return <td key={index}></td>
              })}
            </tr>
          </tbody>
        </Table>
      </div>


      <div className="wizard8__permissions">
        <div className="wizard8__right-heading-div">
          <p className="wizard8__right-p">Users</p>
        </div>
      </div>

      <div>
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>User Type</th>
              <th>Portal Access</th>

            </tr>
          </thead>
          <tbody>
            {values.users.map((user: any, index: number) => {
              let permissionsName='';
              if(values.permissions[user.permissionIndex]){
                permissionsName=values.permissions[user.permissionIndex].name;
              }
              
              return <tr key={index}>

                <td className="userName">{user.firstName} {user.lastName}</td>
                <td className="userPhone">{user.telephone}</td>
                <td className="userType">{permissionsName}</td>
                <td className="userPortalAccess">{user.userPortalAccess}</td>

              </tr>
            })}




            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>


          </tbody>

        </Table>


      </div>

    </div>






  </>
}


export default ReviewForm;
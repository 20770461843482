
import { IEmailSubReportCallLogs } from "../../interfaces/email-reports";

import { useState } from "react";
import MuiToggleBoolControlled from "../misc/form/MuiToggleBoolControlled";
import MuiTitle from "../misc/form/MuiTitle";
import { Grid, MenuItem, Select } from "@material-ui/core";
import VipTimeFieldMui from "../VipTimeFieldMui";
import MuiFreeTypeStringList from "../MuiComponents/MuiFreeTypeStringList";
import TextFieldWithState from "../misc/form/MuiTextFieldWithState";

export interface iEmailReportCallLogsProp {
  data: IEmailSubReportCallLogs;
  submitFunction: (field: string, data: any) => void;
}

const EmailReportCallLogs = ({ submitFunction, data }: iEmailReportCallLogsProp) => {

  const [state, setState] = useState<IEmailSubReportCallLogs>(() => {
    //  console.log(hotDesks)
    const xState = { ...data };
    if (!xState.startTime) {
      xState.startTime = "08:00:00"
      submitFunction("startTime", "08:00:00")
    }
    if (!xState.endTime) {
      xState.endTime = "18:00:00"
      submitFunction("endTime", "18:00:00")
    }
    if (!xState.callLogsShowPhoneBookNames) {
      xState.callLogsShowPhoneBookNames = false;
      submitFunction("callLogsShowPhoneBookNames", false)
    }
    if (!xState.callLogsShowTotalsByNumber) {
      xState.callLogsShowTotalsByNumber = false;
      submitFunction("callLogsShowTotalsByNumber", false)
    }
    if (!xState.callLogsJustShowFrequentCallers) {
      xState.callLogsJustShowFrequentCallers = false;
      submitFunction("callLogsJustShowFrequentCallers", false)
    }
    if (!xState.callLogsSortBy) {
      xState.callLogsSortBy = "";
    }
    if (!xState.callLogsDirection) {
      xState.callLogsDirection = "";
    }
    if (!xState.callLogsStatuses) {
      xState.callLogsStatuses = "";
    }
    return xState;

  });
  const setField = (field: string, value: any) => {
    console.log("subform", field, value);
    const stateUp: any = { ...state };
    stateUp[field] = value;
    setState(stateUp);
    submitFunction(field, value);
  }

  return (<>
    <Grid container spacing={2}>

      <Grid item xs={6}>
        <MuiTitle title="Start Time" />
        <VipTimeFieldMui
          field="startTime"
          value={state.startTime ? state.startTime : "08:00:00"}
          updateField={setField}
        />

      </Grid>
      <Grid item xs={6}>
        <MuiTitle title="End Time" />
        <VipTimeFieldMui
          field="endTime"
          value={state.startTime ? state.endTime : "18:00:00"}
          updateField={setField}
        />
      </Grid>


      <Grid item xs={6}>
        <MuiTitle title="Show Phone Book Names?" />
        <MuiToggleBoolControlled value={state.callLogsShowPhoneBookNames} setValue={(ev) => {
          setField("callLogsShowPhoneBookNames", !state.callLogsShowPhoneBookNames);
        }} />

      </Grid>
      <Grid item xs={6}>
        <MuiTitle title="Show Totals By Number?" />
        <MuiToggleBoolControlled value={state.callLogsShowTotalsByNumber} setValue={(ev) => {
          setField("callLogsShowTotalsByNumber", !state.callLogsShowTotalsByNumber);
        }} />

      </Grid>

      <Grid item xs={6}>
        <MuiTitle title="The minimum number of seconds for the time to answer." />
        <TextFieldWithState required={false} field="fromEmailAddress" data={state.callLogsTTASeconds} setField={(f, value) => {
          setField("callLogsTTASeconds", parseInt(value))
        }} type="number" placeholder="Seconds" />


      </Grid>

      <Grid item xs={6}>
        <MuiTitle title="Statuses" />
        {/* <Form.Label>Statuses</Form.Label>
          <Form.Control
            as="select"
            value={data.callLogsStatuses}
            onChange={handleChange}
          >
            <option value="">All</option>
            <option value="ANSWERED">Answered</option>
            <option value="NO ANSWER">No Answer</option> */}

        <Select
          variant='outlined'
          fullWidth

          value={state.callLogsStatuses}
          onChange={(d, v) => {
            //console.log(d.target.value)
            setField('callLogsStatuses', d.target.value)
          }}
        >

          <MenuItem value=''>
            All
          </MenuItem>

          <MenuItem value='ANSWERED'>
            Answered
          </MenuItem>

          <MenuItem value='NO ANSWER'>
            No Answer
          </MenuItem>


        </Select>

      </Grid>



      <Grid item xs={6}>
        <MuiTitle title="Direction" />
        {/* 
              as="select"
            value={data.callLogsDirection}
            onChange={handleChange}
          >
            <option value="">All</option>
            <option value="Incoming">Incoming</option>
            <option value="Outgoing">Outgoing</option>
        
        
        */}

        <Select
          variant='outlined'
          fullWidth

          value={state.callLogsDirection}
          onChange={(d, v) => {
            //console.log(d.target.value)
            setField('callLogsDirection', d.target.value)
          }}
        >

          <MenuItem value=''>
            All
          </MenuItem>

          <MenuItem value='Incoming'>
            Incoming
          </MenuItem>

          <MenuItem value='Outgoing'>
            Outgoing
          </MenuItem>


        </Select>

      </Grid>


      <Grid item xs={6}>
        <MuiTitle title="Sort By" />

        <Select
          variant='outlined'
          fullWidth

          value={state.callLogsSortBy}
          onChange={(d, v) => {
            //console.log(d.target.value)
            setField('callLogsSortBy', d.target.value)
          }}
        >

          <MenuItem value=''>
            Date
          </MenuItem>

          <MenuItem value='Destination'>
            Destination
          </MenuItem>


        </Select>

      </Grid>


      <Grid item xs={6}>
        <MuiTitle title="CallerID Allow List" />

        <MuiFreeTypeStringList
          field="callLogsCallerIDsAllowed"
          value={state.callLogsCallerIDsAllowed}
          updateField={submitFunction}
          text="CallerIDs"
          placeholder="CallerIDs"

        />

      </Grid>
      <Grid item xs={6}>
        <MuiTitle title="DDIs To Include (leave empty to include all)" />

        <MuiFreeTypeStringList
          field="callLogsDDIsToInclude"
          value={state.callLogsDDIsToInclude}
          updateField={submitFunction}
          text="DDIs"
          

        />
      </Grid>

      <Grid item xs={3}>
        <MuiTitle title="Just show frequent callers?" />
        <MuiToggleBoolControlled value={state.callLogsJustShowFrequentCallers} setValue={(ev) => {
          setField("callLogsJustShowFrequentCallers", !state.callLogsJustShowFrequentCallers);
        }} />

      </Grid>


      <Grid item xs={4}>
        <MuiTitle title="The number of days to look for frequent callers." />

        <TextFieldWithState required={false} field="callLogsFrequentCallersTimePeriodDays" data={state.callLogsFrequentCallersTimePeriodDays} setField={(f, value) => {
          setField("callLogsFrequentCallersTimePeriodDays", parseInt(value))
        }} type="number" placeholder="Calls" />


      </Grid>

      <Grid item xs={5}>
        <MuiTitle title="The minimum number of calls to be a frequent caller." />
        <TextFieldWithState required={false} field="callLogsFrequentCallersMinimumCalls" data={state.callLogsFrequentCallersMinimumCalls} setField={(f, value) => {
          setField("callLogsFrequentCallersMinimumCalls", parseInt(value))
        }} type="number" placeholder="Days" />

      </Grid>
    </Grid>

  </>
  );
};

export default EmailReportCallLogs;

import React from "react";
import Select from "react-select";

const styles = {
  container: (provided: any) => ({
    ...provided,
    flex: 1,
  }),
  control: (provided: any) => ({
    ...provided,
    borderColor: "rgba(114, 117, 126, 0.25)",
    maxHeight: "38px",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    maxHeight: "38px",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: "1rem",
    color: "#6C7592",
  }),
};

const SelectAdapter = ({ input, ...rest }: any) => {
  return <Select {...input} {...rest} searchable styles={styles} />;
};

export default SelectAdapter;

import React, { useEffect, useState } from "react";
import {
  IProvisioningSettingsCustomField, IYealinkFirmwares
} from "../interfaces/provisioning";
import DeleteIcon from '@material-ui/icons/Delete';

import VipProvisioningYealinkField from "./VipProvisioningYealinkField";

import { Box, Button, IconButton, Typography } from "@material-ui/core";
export interface IVipProvisioningYealinkProps {
  value: IYealinkFirmwares[];
  updateField: (field: any, payload: any) => void;
}

export interface IVipProvisioningYealinksState {
  newField?: IProvisioningSettingsCustomField;
  fields: IYealinkFirmwares[];
}
const uuidv4 = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const VipProvisioningYealink = ({ value, updateField }: IVipProvisioningYealinkProps) => {
  const [thisFields, setThisFields] = useState<IYealinkFirmwares[]>();


  useEffect(() => {
    setThisFields(value)
  }, [value]);



  const updateFieldLocal = (
    fieldName: any,
    original: IYealinkFirmwares,
    payload: any,
    f: any
  ) => {
    if (thisFields) {
      let clone: any = { ...original };
      clone[fieldName] = payload;
      let fields: any[] = [...thisFields];
      const index = fields.findIndex((f) => f.uuid === original.uuid);
      fields[index] = clone;
      setThisFields(fields);
      updateField("yealinkFirmwares", fields);
    }
  };
  const deleteFirmware = (uuid: string) => {
    if (thisFields) {
      let fieldss = thisFields;
      for (var i = 0; i < fieldss.length; i++) {
        if (fieldss[i].uuid === uuid) {
          fieldss.splice(i, 1);
          break;
        }
      }
      setThisFields(fieldss);
      updateField("yealinkFirmwares", fieldss);
    }
  };

  const newFirmware = () => {

    let fieldss: any = thisFields;
    if (fieldss === null || fieldss == null || fieldss.map == null) {
      fieldss = [];
    }

      fieldss.push({
        uuid: uuidv4(),
        userAgentPrefix: '',
        url: ''
      });

      setThisFields(fieldss);
      updateField("yealinkFirmwares", fieldss);

    setThisFields(fieldss);
    updateField("yealinkFirmwares", fieldss);
  };
  return <>
    <div style={{color:'red',textAlign:'center'}}>Use extreme caution with these settings. Please consult VIP VoIP before using these settings as you can easily break all the phones at a site.</div>
    {thisFields && thisFields.map((value, i) => {
      i++;
      return (<Box
        key={i}>
        <Typography style={{ position: 'relative' }} variant="h6">
          Firmware #{i}
          <IconButton
            style={{ position: 'absolute', right: 0, top: 0 }}

            color="secondary"
            onClick={() => {
              deleteFirmware(value.uuid)
            }}>
            <DeleteIcon />      </IconButton>
        </Typography>


        <VipProvisioningYealinkField

          updateField={(fieldname: string, newValue: any) => {
            updateFieldLocal(fieldname, value, newValue, i);
          }}
          data={value}
          overridden={true}
          dataField={"userAgentPrefix"}
          title={'User Agent Prefix (For example "SIP-T46G" to provision this firmware to all T46G devices. Or "SIP-T46G 28.83.0.50" to provision to only phones with that exact firmware.)'}
        >

        </VipProvisioningYealinkField>
        <VipProvisioningYealinkField

          updateField={(fieldname: string, newValue: any) => {
            updateFieldLocal(fieldname, value, newValue, i);
          }}
          data={value}
          dataField={"url"}
          title={'Firmware URL (For example "https://your.com/firmware.bin".)'}
        >

        </VipProvisioningYealinkField>

      </Box >
      );
    })}



    <Box style={{ textAlign: "center" }}>
      <Button
        style={{ marginTop: 5 }}
        variant="outlined"
        color="primary"
        onClick={(ev: any) => {
          ev.preventDefault();
          newFirmware();
        }}
      >
        New Yealink Firmware
      </Button>
    </Box>


  </>
}



export default VipProvisioningYealink;
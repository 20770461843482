import * as React from "react";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router";
import MySettings from "../../static/settings";

import VipTextFinalField from "../../components/misc/form/VipTextFinalField";
import App from "../../App";
const set = new MySettings();

const CustomerEditTariffForm = ({ data, customer, onSubmit, tariffs }: any) => {
  const [bundles, setBundles] = useState<any>([]);
  data = { ...data, ...customer };
  if (data.bundleSize !== undefined) {
    data.bundleSizeStr = data.bundleSize.toString();
  }

  data.CustomerID = customer.id;
  if (data.tariff === undefined) {
    data.tariff = "";
  }

  if (data.numExtraBundles !== undefined) {
    data.numExtraBundlesStr = data.numExtraBundles.toString();
  } else {
    data.numExtraBundlesStr = "0";
  }

  data.bundleNumberOverrideStr = '0'
  if (data.bundleNumberOverride) {
    data.bundleNumberOverrideStr = data.bundleNumberOverride+''
  }

  if (data.bundleNumberOverride !== undefined) {
    data.bundleNumberOverrideStr = data.bundleNumberOverride.toString();
  } else {
    data.bundleNumberOverrideStr = "0";
  }

  const submitMe = (payload: any) => {
    if(payload.bundleNumberOverrideStr){
      payload.bundleNumberOverride=parseInt(payload.bundleNumberOverrideStr)
      if(isNaN(payload.bundleNumberOverride)){
        payload.bundleNumberOverride=0
      }
    }

    if (payload.numExtraBundlesStr !== undefined) {
      payload.numExtraBundles = parseInt(payload.numExtraBundlesStr);
    }

    if (payload.bundleNumberOverrideStr !== undefined) {
      payload.bundleNumberOverride = parseInt(payload.bundleNumberOverrideStr);
    }

    if (payload.tariffType === "bundle") {
      // set tariff off
      payload.tariff = "";
    } else {
      payload.numExtraBundles = 0;
      payload.bundleNumberOverride = 0;
    }
    onSubmit(payload);
    console.log(payload);
  };
  // console.log(data, customer);

  const history = useHistory();
  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    let isMounted = true;

    const getBundles = async () => {
      try {
        if (isMounted) {
          const bundles = await set.resellerTariffBundles(customer.id,false);
          setBundles(bundles.items);
        }
      } catch (error) {
        App.newNotificationError('Failed to get the tariffs. '+error)
      }
    };

    getBundles();

    return () => {
      isMounted = false;
    };
  }, [customer.id]);

  return (
    <Form
      onSubmit={submitMe}
      initialValues={data}
      render={({ handleSubmit, form, submitting, pristine, values }) => { 
        let currentNumberOfBundles=values.bundleNumberOverrideStr + ' (overridden)'
        if(values.bundleNumberOverrideStr==='' || values.bundleNumberOverrideStr==='0' || values.bundleNumberOverrideStr==null){
          if(!values.numExtraBundlesStr){
            values.numExtraBundlesStr='0'
          }
          currentNumberOfBundles = data.extensionsForCustomer + ' (extensions) + ' + values.numExtraBundlesStr + ' (extra) = '+(data.extensionsForCustomer+parseInt(values.numExtraBundlesStr))
        }
        return (
        <form onSubmit={handleSubmit}>
          <div className="text-left test-edit-customer-tariff content mt-5">
            <Card>
              <Card.Header>
                <h2 className="text-center my-auto">Edit Customer Tariff</h2>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Col>
                    <p className="mb-2">
                      Please choose the tariff type that you would like to apply
                      to this customer.
                    </p>
                    <p>
                      <b>PPM</b> means Pay Per Minute. We charge you at standard
                      wholesale rates for each call destination and you choose
                      the retail tariff to be applied to the customer (i.e. the
                      rates witch you want to charge the customer)
                    </p>
                    <p>
                      <b>Call Bundles</b> provide a monthly allowance for calls
                      to UK landlines and mobiles for a fixed price. We charge
                      you the wholesale price for each bundle. You invoice your
                      customer whatever you like for the same bundle. We
                      automatically apply a minimum of one call bundle per user
                      for the customer. The total call allowance is shared
                      between all users, You can choose to add additional
                      bundles to suit the customer's expected call demand.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>Customer Type</Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <label>
                        <Field
                          name="voipServiceType"
                          component="input"
                          type="radio"
                          value="Business"
                        />{" "}
                        Business
                      </label>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label>
                        <Field
                          name="voipServiceType"
                          component="input"
                          type="radio"
                          value="Domestic"
                        />{" "}
                        Domestic
                      </label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <label>
                        <Field
                          name="tariffType"
                          component="input"
                          type="radio"
                          value="ppm"
                        />{" "}
                        PPM
                      </label>
                    </div>
                    <div className="form-group">
                      <label>PPM Tariff</label>

                      <Field name="tariff" component="select">
                        {({ input, meta }) => (
                          <>
                            <select
                              {...input}
                              id={input.name.replace(".", "_")}
                              className="form-control"
                            >
                              <option></option>
                              {tariffs.map((t: any) => {
                                return (
                                  <option key={t.id} value={t.id}>
                                    {t.name}
                                  </option>
                                );
                              })}
                            </select>
                            {meta.error && meta.touched && (
                              <span>{meta.error}</span>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label>
                        <Field
                          name="tariffType"
                          component="input"
                          type="radio"
                          value="bundle"
                        />{" "}
                        Bundles
                      </label>
                    </div>

                    <div className="form-group">
                      <label>Current number of bundles</label>
                      <br />
                      {currentNumberOfBundles}
                    </div>
                    <div className="form-group">
                      <label>Extra bundles</label>
                      <VipTextFinalField
                        fieldName="numExtraBundlesStr"
                        placeholder=""
                        type="number"
                        required={false}
                      />
                    </div>
                    <div className="form-group">
                      <label>Bundle number override (leave at 0 to automatically calculate number of bundles)</label>
                      <VipTextFinalField
                        fieldName="bundleNumberOverrideStr"
                        placeholder=""
                        type="number"
                        required={false}
                      />
                    </div>
                    {/* <div className="form-group">
                      <label>Bundle number override</label>
                      <VipTextFinalField
                        fieldName="bundleNumberOverride"
                        placeholder=""
                        type="number"
                        required={false}
                      />
                    </div> */}
                    <>
                      <div>Bundle Size</div>
                      {bundles.map((bundle: any) => (
                        <div key={bundle.id}>
                          <Field
                            name="bundleSizeId"
                            component="input"
                            type="radio"
                            value={bundle.id}
                          >
                            {({ input, meta }) => {
                              return (
                                <input
                                  {...input}
                                  onChange={(e) => {
                                    input.onChange(e.target.value); //final-form's onChange
                                  }}
                                />
                              );
                            }}
                          </Field>
                          <label title="" className="tarif_label">
                            {" "}
                            {bundle.name}
                          </label>
                        </div>
                      ))}
                    </>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="d-flex flex-rowd-flex justify-content-between">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={back}
                >
                  Back
                </button>
                <button
                  className="btn btn-primary noleft"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  Save
                </button>
              </Card.Footer>
            </Card>
          </div>
        </form>
      )}
    }
    />
  );
};

export default CustomerEditTariffForm;

import * as React from "react";
import { Button, Card } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import VipTextFinalField from "../../components/misc/form/VipTextFinalField";
import { SketchPicker } from 'react-color'
import MuiCheckbox from "../../components/MuiComponents/MuiCheckbox";

const ResellerProfileForm = ({ data, onSubmit }: any) => {
  const FormGroupAdapter = ({
    input,
    inputOnChange,
    placeholder,
    meta,
  }: any) => {
    const inputProps = {
      ...input,
      onChange: (e: any) => {
        input.onChange(e);
        inputOnChange && inputOnChange(e);
      },
    };

    return (
      <>
        <input
          {...inputProps}
          type="file"
          placeholder={placeholder}
          className="form-control-file"
          style={{ display: 'block' }}
        />
        {meta.error && meta.touched && (
          <span className="invalid">{meta.error}</span>
        )}
        {meta.valid && meta.dirty && <span className="valid">Great!</span>}
      </>
    );
  };
  let current = "/voip/uploadFile?file=" + data.logo;
  return (
    <Card>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          let sub = (sdata: any) => {
            var reader = new FileReader();
            var file = sdata.target.files[0];
            reader.addEventListener("load", function () {
              form.change("newLogo", this.result);
              form.change("newLogoFileName", file.name);
            });
            reader.readAsDataURL(file);
          };

          let sub2 = (sdata: any) => {
            var reader = new FileReader();
            var file = sdata.target.files[0];
            reader.addEventListener("load", function () {
              form.change("newNavigationBarLogo", this.result);
              form.change("newNavigationBarLogoFileName", file.name);
            });
            reader.readAsDataURL(file);
          };

          return (
            <form onSubmit={handleSubmit}>
              <div className="test-new-customer-start">
                <Card.Body>
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group">
                        <label>Brand Name</label>
                        <VipTextFinalField
                          fieldName="name"
                          placeholder="Brand Name"
                          required={false}
                        />
                      </div>

                      <div className="form-group">
                        <label>Brand Email</label>
                        <VipTextFinalField
                          fieldName="emailAddress"
                          placeholder="Brand Email"
                          required={false}
                        />
                      </div>

                      <div className="form-group">
                        <label>Brand Domain</label>
                        <VipTextFinalField
                          fieldName="brandPortalDomain"
                          placeholder="Domain"
                          required={false}
                        />
                      </div>

                      <div className="form-group">
                        <label>Default Access Groups</label>
                        <br />
                        <Link to="customerdefaultgroups" className="btn btn-primary noleft">Manage Default Access Groups</Link>
                      </div>

                      <div className="form-group">
                        <label>Bundle Alert Email Address</label>
                        <VipTextFinalField
                          fieldName="bundleAlertEmailAddress"
                          placeholder="The address to send alerts to when customers are nearing their amount of bundle minutes for the month."
                          required={false}
                        />
                      </div>

                      <div className="form-group">
                        <label>Bundle Alert Percentage</label>
                        <VipTextFinalField
                          fieldName="bundleAlertPercentage"
                          placeholder="The percentage of minutes used to send an alert at. We suggest 90%."
                          required={false}
                        />
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-6">
                      {values.logo !== "" && (
                        <div className="form-group">
                          <label>Current Login Logo</label>
                          <br />
                          <img src={current} alt="" />
                          {values.removeLogo !== true && (
                            <>
                              <br />
                              <Button
                                className="noleft"
                                onClick={(ev: any) => {
                                  ev.preventDefault();
                                  form.change("removeLogo", true);
                                  form.change("newLogo", undefined);
                                  form.change("newLogoFileName", undefined);
                                }}
                              >
                                Remove Image
                              </Button>
                            </>
                          )}
                          <div className="hidden">
                            <Field
                              name="removeLogo"
                              component="input"
                              placeholder="Upload image file"
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group">
                        <label>New Login Logo</label>
                        {values.newLogo !== undefined && (
                          <img src={values.newLogo} alt="" />
                        )}
                        <Field
                          name="newFile"
                          component={FormGroupAdapter}
                          inputOnChange={sub}
                          placeholder="Upload image file"
                        />
                      </div>

                      {values.navigationBarLogo !== "" && (
                        <div className="form-group">
                          <label>Current Navigation Bar Logo</label>
                          <br />
                          <img src={"/voip/uploadFile?file=" + data.navigationBarLogo} alt="" />
                          {values.removeNavigationBarLogo !== true && (
                            <>
                              <br />
                              <Button
                                className="noleft"
                                onClick={(ev: any) => {
                                  ev.preventDefault();
                                  form.change("removeNavigationBarLogo", true);
                                  form.change("newNavigationBarLogo", undefined);
                                  form.change("newNavigationBarLogoFileName", undefined);
                                }}
                              >
                                Remove Image
                              </Button>
                            </>
                          )}
                          <div className="hidden">
                            <Field
                              name="removeNavigationBarLogo"
                              component="input"
                              placeholder="Upload image file"
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group">
                        <label>New Navigation Bar Logo</label>
                        {values.newNavigationBarLogo !== undefined && (
                          <img src={values.newNavigationBarLogo} alt="" />
                        )}
                        <Field
                          name="newNavigationBarFile"
                          component={FormGroupAdapter}
                          inputOnChange={sub2}
                          placeholder="Upload image file"
                        />
                      </div>


                        <div className="form-group">
                          <label>Navigation Bar Colour</label>
                          <br />
                          <MuiCheckbox
                            name="overrideNavigationBarColour"
                            label="Override Navigation Bar Colour?"
                          />
                          {values.overrideNavigationBarColour && (<SketchPicker color={values.navigationBarColour} onChangeComplete={(color)=>{
                            form.change('navigationBarColour',color.hex)
                            }}></SketchPicker>)}
                        </div>
                    </div>
                  </div>


                </Card.Body>
                <Card.Footer>
                  <div className="" style={{ textAlign: 'right' }}>
                    <button
                      className="btn btn-primary noleft"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </button>
                  </div>
                </Card.Footer>
              </div>
            </form>
          );
        }}
      />
    </Card>
  );
};

export default ResellerProfileForm;

import { Typography, Paper } from "@material-ui/core"
import React from "react"
import VoipTable from "../../components/VoipTable"
import { IPhonebookContact } from "../../interfaces/phonebook-number";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
interface ISpeedDialsProps {
    speedDials:IPhonebookContact[]
}
const SpeedDials: React.FC<ISpeedDialsProps> = (props) => {
  const col = [
    {
      Header: "Name",
      accessor: "name",
      sortable: true
    },{
      Header:'Number 1',
      accessor:'number1',
      sortable:true
    },{
      Header:'Number 2',
      accessor:'number2',
      sortable:true
    },{
      Header:'Number 3',
      accessor:'number3',
      sortable:true
    },{
      Header:'Dashboard',
      accessor:'windowsDisplayOnDashboard',
      sortable:true,
      Cell: (props: any) => {
        if (props.value === true) {
          return (
            <div className="text-center">
              <FontAwesomeIcon icon={faCheck} />
            </div>
          );
        } else {
          return (
            <div className="text-center">
              <FontAwesomeIcon icon={faTimes} />
            </div>
          );
        }
      }
    }
  ]

  return <Paper style={{marginTop:'1em',padding:'1em'}}>
    <Typography variant="h6">Speed Dials</Typography>
      <VoipTable
        showResultsCount={false}
        sortByStart="name"
        sortReverse={false}
        title="Speed Dials"
        data={props.speedDials}
        col={col}
        multiSelect={false}
      />
    </Paper>
}

export default SpeedDials
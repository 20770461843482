import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import MySettings from "../static/settings";

import { Button, Card } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import App from "../App";
import Loading from "../components/loading";
import VoipTable from "../components/VoipTable";
import { NOTIFICATION_TYPE } from "../enum/notification-type";
import { INotification } from "../interfaces/notification";

const set = new MySettings();

const TeamsCallRecordingLicenseAllocator = ({ state }: any) => {
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [data, setData] = useState<any>([]);
  const [filterData, setFilter] = useState<any>([]);
  const [update, setUpdate] = useState<string>("");

  const table = useRef<any>(null);

  const selectLicencedRecord = useCallback(() => {
    table.current.checkLicensedRole();
  }, []);

  const selectAllrecord = () => {
    table.current.checkMultipleRow();
  };

  useEffect(() => {
    let isMounted = true;

    const getTeams = async () => {
      try {
        const data = await set.teamsCallRecordingUsers(state);
        if (isMounted) {
          let tempData = Object.values(data.users);
          setData(tempData);
          setFilter(tempData);
          setLoading(false);
          selectLicencedRecord();
        }
      } catch (error) {
        setLoading(false);
        App.newNotificationError(
          "Failed to load Teams call recording configuration " + error.data
        );
      }
    };

    getTeams();

    return () => {
      isMounted = false;
    };
  }, [selectLicencedRecord, state]);

  const onSubmit = async (idList: any[]) => {
    let ids: string[] = [];

    for (let i = 0; i < idList.length; i++) {
      ids.push(idList[i].id);
    }

    setLoading(true);

    try {
      await set.teamsCallRecordingUsersUpdate(
        state,
        ids
      );
      const data = await set.teamsCallRecordingUsers(state);
      let tempData = Object.values(data.users);
      setData(tempData);
      setLoading(false);
      selectLicencedRecord();

      setLoading(false);
      const notification: INotification = {
        title: "Configuration Updated",
        data: "User licenses have been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "TeamsCallRecordingPage",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification);
    } catch (err) {
      App.newNotificationError(
        "Failed to update the Teams call recording configuration " + err.data
      );
    }
    setLoading(false);
  };
  const search = (value: string) => {
    setSearchString(value);
    setData(
      filterData.filter((single: any) =>
        single.displayName.toUpperCase().includes(value.toUpperCase())
      )
    );
    setUpdate((Math.random() + 1).toString(36).substring(7));
  };

  const col: any[] = [
    {
      Header: "Name",
      accessor: "displayName",
      sortable: true,
    },

    {
      Header: "Principal Name",
      accessor: "userPrincipalName",
      sortable: true,
    },
  ];

  return (
    <div className="content">
      {loading === true ? (
        <Loading />
      ) : (
        <Row>
          <br />
          <Col md={{ span: 12 }}>
            <br />
            <Card>
              <Card.Header>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Select Licensed Users</span>{" "}
                  <div>
                    <input
                      style={{
                        outline: "none",
                        borderRadius: "5px",
                        padding: "0 10px",
                      }}
                      placeholder="search name"
                      onChange={(e) => search(e.target.value)}
                      value={searchString}
                    />
                  </div>
                </div>{" "}
              </Card.Header>
              <Card.Body>
                {data.length > 0 && (
                  <VoipTable
                    key={update}
                    ref={table}
                    title="Users"
                    sortByStart="displayName"
                    data={data}
                    col={col}
                    multiSelect={true}
                    multiSelectActions={[
                      {
                        title: "Select All",
                        f: (ddd) => {
                          selectAllrecord();
                        },
                      },
                      {
                        title: "Save Selected",
                        f: (ddd) => {
                          onSubmit(ddd);
                        },
                      },
                    ]}
                  />
                )}
                <Button
                  style={{
                    float: "right",
                    marginTop: "30px",
                    marginRight: "10px",
                  }}
                  onClick={() => onSubmit(table.current.selectedRows())}
                >
                  Save
                </Button>
              </Card.Body>
              <Card.Footer
                style={{ textAlign: "center", color: "white" }}
              ></Card.Footer>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default TeamsCallRecordingLicenseAllocator;

import React from "react";

import { createTheme, ThemeProvider } from "@material-ui/core";

import MUIDataTable, { MUIDataTableProps } from "mui-datatables";

const tableTheme = createTheme({
  overrides: {
    MuiPaper: {
      elevation0: {
        border: "none !important",
        boxShadow: "0 2px 24px 0 rgba(0,0,0,0.08)",
      },
    },
    MUIDataTable: {
      paper: {
        border: "1px solid #DFE3EB",
        borderRadius: "4px",
        paddingLeft: "24px",
        paddingRight: "24px",
      },
      responsiveScroll: {
        maxHeight: "auto",
      },
      responsiveScrollFullHeight: {
        zIndex: 1,
      },
    },
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 10,
        },
        "&:nth-child(2)": {
          width: 10,
        },
        "&:nth-child(3)": {
          width: 10,
        },
        "&:nth-child(4)": {
          width: 10,
        },
        "&:nth-child(5)": {
          width: 10,
        },
        "&:nth-child(6)": {
          width: 10,
        },
        "&:nth-child(7)": {
          width: 10,
        },
        "&:nth-child(8)": {
          width: 10,
        },
        "&:nth-child(9)": {
          width: 10,
        },
        "&:first-child": {
          paddingLeft: "0",
        },
        "&:last-child": {
          paddingRight: "0",
        },

        paddingTop: "0",
        paddingBottom: "0",
      },
      data: {
        fontSize: 14,
        fontWeight: 400,
        color: "#5B6688",
      },
    },
    MUIDataTableBodyCell: {
      root: {
        "&:first-child": {
          paddingLeft: 0,
        },
        "&:last-child": {
          paddingRight: 0,
        },
      },
    },

    MUIDataTablePagination: {
      tableCellContainer: {
        borderBottom: "none",
      },
    },

    MUIDataTableToolbar: {
      root: {
        padding: 0,
        borderBottom: "1px solid #E0E0E0",
      },
    },

    MuiMenuItem: {
      root: {
        fontSize: "14px",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "13px",
      },
    },
  },
  typography: {
    fontSize: 14,

    h6: {
      fontFamily: "Montserrat",
      fontSize: 22,
      fontWeight: 500,
    },
  },
} as any);

const MuiTableWrapper = (props:MUIDataTableProps) => {
  return (
    <ThemeProvider theme={tableTheme}>
      <MUIDataTable
       {...props}
      />
    </ThemeProvider>
  );
};
export default MuiTableWrapper;

import * as React from "react";
import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";
import { INumber } from "../../interfaces/number";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
library.add(faCheckSquare);

const updateState =
  <T extends string>(key: keyof INumber, value: T) =>
    (prevState: INumber): INumber => ({
      ...prevState,
      [key]: value,
    });

export interface IFormNumberProp {
  data: INumber;
  submitFunction: (data: INumber) => void;
}

export default class FormNumberAnswer extends React.Component<
  IFormNumberProp,
  INumber
> {
  constructor(props: any) {
    super(props);
    this.state = props.data;
  }

  public handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    this.props.submitFunction(this.state);
  };
  public handleChange = (event: any) => {
    this.setState(updateState(event.target.id.toString(), event.target.value));
  };
  componentDidMount() {
  }
  public render() {
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col>
              <Form.Group controlId="vrAnswerAs">
                <Form.Label>VIP Receptionist Greeting</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Greeting the receptionist should use when answering this number."
                  rows={3}
                  value={this.state.vrAnswerAs}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="vrSpecialInstructions">
                <Form.Label>VIP Receptionist Special Instructions</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Any special instructions for the receptionist when they have answered a call."
                  rows={3}
                  value={this.state.vrSpecialInstructions}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className="text-right">
              <Button variant="primary" type="submit">
                Save&nbsp;
                <FaIcon fixedWidth className="whiteIcon" icon="check-square" />
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

import React, {  useRef } from "react";
import { Field, Form } from "react-final-form";
import DateFnsUtils from "@date-io/date-fns";


import { Button, Grid } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import MuiSelect from "../../components/MuiComponents/MuiSelect";
import MuiCheckbox from "../../components/MuiComponents/MuiCheckbox";
import MuiTextInput from "../../components/MuiComponents/MuiTextInput";

import { IBundleMinutes, IVoipService } from "../../interfaces/interfaces";
import { ICallLogsFormState } from "../../interfaces/calllog";

const getBundlesDom = (bundles:IBundleMinutes) => {
  const bundleDoms = []
  for(const description in bundles){
    const minutes=bundles[description]
    const domItem=<div>{description} used {minutes.used} minutes of {minutes.available}</div>
    bundleDoms.push(domItem)
  }
  return <Grid item xs={6}>{bundleDoms}</Grid>
}


interface ICalLogFormProps {
  currentSearch:any;
  onSubmit: (values: ICallLogsFormState) => Promise<void>;
  services: IVoipService[];
}

const CallLogSearchForm:React.FunctionComponent<ICalLogFormProps> = ({
  onSubmit,
  services,
  currentSearch
}:ICalLogFormProps) => {
  let renderServicePicker = false;

  if (services !== undefined && services.length > 1) {
    renderServicePicker = true;
  }

  const fromRef = useRef<HTMLAnchorElement>();

  // useEffect(() => {
  //   const getInital = async () => {
  //     await delay(1000);
  //     fromRef&& fromRef.current && fromRef.current.click();
  //   }
  //   getInital();
  // }, []);


  return (
    <Form
      onSubmit={onSubmit}
      initialValues={currentSearch}
      render={({ handleSubmit, values }) => {
        let callChargesThisMonthWidth = 12
        let callChargesThisMonthString = ''
        let bundlesDom = null
        if (services != null) {
          for (let i = 0; i < services.length; i++) {
            const service = services[i]
            if (service.id === values.customersSitesServicesId && service.retailCurrentMonthCallChargesDate != null && service.retailCurrentMonthCallChargesPounds != null) {
              callChargesThisMonthString = 'Call charges so far this month £' + service.retailCurrentMonthCallChargesPounds.toFixed(2) + ' (excluding today).'
              if(service.retailCurrentMonthBundleMinutes){
                callChargesThisMonthWidth = 6
                bundlesDom=getBundlesDom(service.retailCurrentMonthBundleMinutes)
              }
            }
          }
        }

        return (
          <form onSubmit={handleSubmit}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3}>
                {callChargesThisMonthString.length > 0 && (<Grid item xs={callChargesThisMonthWidth}>{callChargesThisMonthString}</Grid>)}
                {bundlesDom && bundlesDom}
                {renderServicePicker && (
                  <Grid item xs={2}>


                    <MuiSelect
                      name="customersSitesServicesId"
                      label="Site"
                      options={services!.map((service) => {
                        return { value: service.id, label: service.siteName };
                      })}
                    />
                  </Grid>
                )}

                <Grid item xs={2}>
                  <MuiSelect
                    name="incoming"
                    label="Incoming"
                    options={[
                      { value: true, label: "Incoming" },
                      { value: false, label: "Outgoing" },
                    ]}
                  />
                </Grid>

                <Grid item xs={3}>
                  <MuiTextInput outlinedLabel="Extension" name="extension" />
                </Grid>

                <Grid item xs={3}>
                  <MuiTextInput outlinedLabel={(values.incoming === true ? "Caller Number" : "Destination Number")} name="number" />
                </Grid>

                <Grid item xs={2}>
                  <MuiCheckbox name="missedOnly" label="Missed calls only?" />
                </Grid>

                {!renderServicePicker && <Grid item xs={2} />}


                <Grid item xs={4} style={{ textAlign: "center", paddingBottom: 0, paddingTop: 0 }}>From</Grid>



                <Grid item xs={1} style={{ paddingBottom: 0, paddingTop: 0 }}></Grid>

                <Grid item xs={4} style={{ textAlign: "center", paddingBottom: 0, paddingTop: 0 }}>To</Grid>


                <Grid item xs={2} style={{ paddingBottom: 0, paddingTop: 0 }}></Grid>

                <Grid item xs={2}>
                  <Field name="startDate">
                    {({ input: { onChange, value } }) => (
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={value}
                        onChange={onChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={2}>
                  <Field name="startTime">
                    {({ input: { onChange, value } }) => (
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        value={value}
                        onChange={onChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={1} />

                <Grid item xs={2}>
                  <Field name="endDate">
                    {({ input: { onChange, value } }) => (
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={value}
                        onChange={onChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={2}>
                  <Field name="endTime">
                    {({ input: { onChange, value } }) => (
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        value={value}
                        onChange={onChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={1} />

                <Grid item xs={2}>
                  <Button
                    ref={fromRef}
                    id="CallLogSearchBtn"
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>

            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </form>
        )
      }}
    />
  );
};

export default CallLogSearchForm;

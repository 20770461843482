import { FormControlLabel, Switch } from "@material-ui/core";

interface iMuiToggleBoolControlledProp {
    value: boolean;
    setValue:(event:any)=> void
}


const MuiToggleBoolControlled = ({ value, setValue }: iMuiToggleBoolControlledProp) => {


    return <FormControlLabel control={<Switch color="primary" checked={value} onChange={(ev) => {
        setValue(ev)
  
    }} />} label={(value === true ? "Enabled" : "Disabled")} />
}


export default MuiToggleBoolControlled;
import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Form as Forms } from "react-final-form";
import App from "../../App";
import Loading from "../../components/loading";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import MySettings from "../../static/settings";
import { IWallboardWidgetFormProps } from "./WallboardPage";

import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import VipTextFinalField from "../../components/misc/form/VipTextFinalField";
import { IWallboardWidgetSettingAgents } from "../../interfaces/wallboards";

const set = new MySettings();

const WallboardWidgetCallsTakenForm: React.FC<IWallboardWidgetFormProps> = (
  props
) => {
  const [loading, setLoading] = useState(false);
  const data: any = {
    queueNames: props.widget.queueNames,
  };
  const minimumWaitTimeSeconds: number = props.widget.minimumWaitTimeSeconds;

  // tidy up 2022 Richard King
  // const [minimumWaitTimeSeconds, setMinimumWaitTimeSeconds] = useState<number>(
  //   props.widget.minimumWaitTimeSeconds
  // );

  // const onMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const aNumber = parseInt(event.currentTarget.value);
  //   if (isNaN(aNumber)) {
  //   } else {
  //     setMinimumWaitTimeSeconds(aNumber);
  //   }
  // };

  const realSubmit = async (data: IWallboardWidgetSettingAgents) => {
    data.timeOnEachAgentPageSeconds = +minimumWaitTimeSeconds;
    console.log("formData", data);
    console.log(props.widget.id);
    setLoading(true);
    try {
      await set.wallboardWidgetUpdate(props.widget.id, {
        minimumWaitTimeSeconds: minimumWaitTimeSeconds,
        queueNames: data.queueNames,
      });
      window.scrollTo(0, 0);

      const notification: INotification = {
        title: "Widget Updated",
        data: "The widget has been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "WallboardWidgetMessageForm",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification);
    } catch (err) {
      App.newNotificationError("Failed to update the widget " + err.data);
    }
    setLoading(false);
  };

  return (
    <Card style={{ textAlign: "left", marginTop: "20px" }}>
      <Card.Header as="h6">Calls Taken ({props?.widget?.id})</Card.Header>
      <Forms
        onSubmit={realSubmit}
        initialValues={data}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          form: {
            mutators: { push, pop },
          },
          pristine,
          form,
          submitting,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Card.Body>
              <div className="mb-3">
                <Button
                  variant="outline-primary"
                  className="noleft mb-2"
                  onClick={() => push("queueNames", undefined)}
                >
                  <FaIcon icon="user-plus" color="primary" className="mr-1" />
                  Add Queue Name
                </Button>
                <FieldArray name="queueNames">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div
                        key={name}
                        className="d-flex justify-content-start customHeight"
                      >
                        <label className="mr-2 my-auto">
                          Queue Name #{index + 1}
                        </label>

                        <VipTextFinalField
                          fieldName={name}
                          placeholder="Queue Name"
                          required={true}
                          type="text"
                        />

                        <Button
                          onClick={() => fields.remove(index)}
                          variant="outline-danger"
                        >
                          Delete
                        </Button>
                      </div>
                    ))
                  }
                </FieldArray>
              </div>

              {/* <div>
                <Col xs={4}>
                  <label
                    style={{
                      width: "500px",
                      paddingTop: "20px",
                      paddingBottom: "4px",
                    }}
                  >
                    Minimum wait time in seconds for a call to be classified as
                    missed.
                  </label>
                  <Form.Control
                    type="text"
                    value={minimumWaitTimeSeconds}
                    onChange={onMessageChange}
                  ></Form.Control>
                </Col>
                <div style={{ height: "30px" }}></div>
              </div> */}
            </Card.Body>

            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

            <Card.Footer className="text-center">
              {loading ? (
                <Loading />
              ) : (
                <Button variant="primary" className="noleft" type="submit">
                  Save
                </Button>
              )}
            </Card.Footer>
          </Form>
        )}
      />
    </Card>
  );
};
export default WallboardWidgetCallsTakenForm;

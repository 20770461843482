import React, { useState } from "react";
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import GetDeskPhoneOptions from "../../components/getDeskPhoneOptions";
import GetExtensionOptions from "../../components/getExtensionOptions";
import GetSiteOptions from "../../components/getOptions";
import VipTextFinalField from "../../components/misc/form/VipTextFinalField";
import FormProvisioning from "../../components/oldForms/form-provisioning";
import Wizard from "../../components/Wizard";
import { IProvisioningRecord } from "../../interfaces/provisioning";
import ExtraAccounts from "./ExtraAcounts";
import MuiModal from "../../components/modal/MuiModal";
import { ICustomer } from "../../interfaces/customer";

type IDeviceForm = {
  data: IProvisioningRecord;
  onSubmit: any;
  customers: ICustomer[];
  singleCustomerMode: boolean;
  newUrlMode: boolean;
  userType: string;
};

const DeviceForm: React.FunctionComponent<IDeviceForm> = ({
  data,
  customers,
  onSubmit,
  singleCustomerMode,
  newUrlMode,
  userType,
}) => {
  const [show, setShow] = useState(false);
  //   console.log("data", data);
  const onClose = () => {
    setShow(!show);
  };
  const generateMarkup = (string: string) => {
    if(string==null){
      string = '';
    }
    return { __html: string.replace(/\n/g, "<br />") };
  };
  customers.sort((a,b)=>{
    return a.name.localeCompare(b.name)
  })
  return (
    <Wizard
      initialValues={data}
      onSubmit={onSubmit}
      menuBottom
      pageMenu={data.id ? true : false}
      startIndex={newUrlMode ? 1 : 0}
    >
      <Wizard.Page
        n="DETAILS"
        nt="Details"
        t={1}
        validate={(values: any) => {
          const errors: any = {};
          //   if (!values.purchaced) {
          //      errors.purchaced = 'Required'
          //  }
          return errors;
        }}
        render={(values: any, push: any, pop: any, form: any) => (
          <>
            <fieldset>
              {values.id === "" && <legend>Details</legend>}
              <p>
                Please enter the UUID (or MAC address) of the device you want to
                provision
              </p>
              <div className="form-group">
                <label>UUID</label>
                <VipTextFinalField
                  fieldName="uuid"
                  placeholder="00:11:22:33:44:55"
                  required={true}
                />
              </div>
            </fieldset>
            <div>User Agent: {values.userAgent ? values.userAgent : ''}</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "360px",
              }}
            >
              <div>Last Delivered Date & Time :</div>
              <div>
                {new Date(
                  values.lastTemplateDeliveredDateTime+'Z' //we plus Z to make it do the time zone correction
                ).toLocaleString()}
              </div>
            </div>
            <div style={{ marginTop: "20px", marginLeft: "-20px" }}>
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={() => onClose()}
              >
                View last template delivered
              </button>
            </div>
            <MuiModal
              open={show}
              title="Last Template Delivered"
              onClose={() => onClose()}
            >
              <div
                dangerouslySetInnerHTML={generateMarkup(
                  values.lastTemplateDelivered
                )}
              ></div>
            </MuiModal>
          </>
        )}
        onSubmit={(values: any, next: any) => {
          // console.log(values);
          next(values);
        }}
      ></Wizard.Page>

      <Wizard.Page
        n="CUSTOMER"
        nt="Customer"
        validate={(values: any) => {
          const errors: any = {};
          /*if (!values.purchaced) {
                errors.purchaced = 'Required'
            }*/
          return errors;
        }}
        render={(values: any, push: any, pop: any, form: any) => {
          return (
            <>
              <fieldset>
                {values.id === "" && <legend>Customer</legend>}

                {!singleCustomerMode && (
                  <div className="form-group">
                    <label>Customer</label>
                    <Field component="select" name="customersId">
                      {({ input, meta }) => (
                        <div>
                          <select
                            {...input}
                            id={input.name.replace(".", "_")}
                            className="form-control"
                          >
                            <option></option>
                            {customers.map((map) => {
                              return (
                                <option key={map.id} value={map.id}>
                                  {map.name}
                                </option>
                              );
                            })}
                          </select>
                          {meta.error && meta.touched && (
                            <span>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                )}

                {(singleCustomerMode || values.customersId !== "") && (
                  <div className="form-group">
                    <label>Site</label>

                    <Field component="select" name="customersSitesId">
                      {({ input, meta, inputOnChange }) => (
                        <>
                          {GetSiteOptions({
                            form: form,
                            addBlank: true,
                            input: input,
                            meta: meta,
                            inputOnChange: inputOnChange,
                            userType: userType,
                            customerID: values.customersId,
                            singleCustomerMode: singleCustomerMode,
                            associatedOnly: newUrlMode,
                          })}
                        </>
                      )}
                    </Field>
                  </div>
                )}

                <OnChange name={`asteriskServerExtensionsId`}>
                  {(value, previous) => {
                    // do something
                    //   console.log("V", value, "p", previous)
                    if (value === "") {
                      form.change("asteriskServerExtensionsId", "");
                      form.change("extensionCallerid", "");
                      form.change("extensionName", "");
                    }
                  }}
                </OnChange>
                {values.customersSitesId && (
                  <>
                    <div className="form-group">
                      <label>Extension</label>

                      <Field
                        name="asteriskServerExtensionsId"
                        component={GetExtensionOptions}
                        customersSitesId={values.customersSitesId}
                        defaultV={values.asteriskServerExtensionsId}
                        placeholder="Extension"
                      />
                    </div>

                    <div className="form-group">
                      <label>Desk Phone</label>

                      <Field
                        name="deskPhoneId"
                        component={GetDeskPhoneOptions}
                        customersSitesId={values.customersSitesId}
                        associatedOnly={newUrlMode}
                        defaultV={values.deskPhoneId}
                      />
                    </div>
                  </>
                )}

                {(singleCustomerMode || values.customersId !== "") && (
                  <>
                    <p>
                      If your phone supports two accounts you can choose a
                      second extension below:
                    </p> 

                    <div className="form-group">
                      <label>Site 2</label>

                      <Field component="select" name="customersSitesId2">
                        {({ input, meta, inputOnChange }) => (
                          <>
                            {GetSiteOptions({
                              addBlank: true,
                              input: input,
                              meta: meta,
                              inputOnChange: inputOnChange,
                              userType: userType,
                              customerID: values.customersId,
                              singleCustomerMode: singleCustomerMode,
                              associatedOnly: newUrlMode,
                            })}
                          </>
                        )}
                      </Field>
                    </div>
                  </>
                )}
                {values.customersSitesId2 && (
                  <div className="form-group">
                    <label>Extension 2</label>

                    <Field
                      name="asteriskServerExtensionsId2"
                      component={GetExtensionOptions}
                      customersSitesId={values.customersSitesId}
                      defaultV={values.asteriskServerExtensionsId2}
                      placeholder="Extension"
                    />
                  </div>
                )}

                <ExtraAccounts
                  singleCustomerMode={singleCustomerMode}
                  customers={customers}
                  form={form}
                  userType={userType}
                  newUrlMode={newUrlMode}
                  values={values}
                />
              </fieldset>
            </>
          );
        }}
        onSubmit={(values: any, next: any) => {
          console.log(values)
          // skip provisioning settings if they are being sent here using uuidForPairing
          if (newUrlMode) {
            next(values, "SUBMIT_NOW");
          } else {
            next(values);
          }
        }}
      ></Wizard.Page>
      <Wizard.Page
        n="PROVISIONING"
        nt="Provisioning"
        validate={(values: any) => {
          const errors: any = {};
          /*if (!values.purchaced) {
                errors.purchaced = 'Required'
            }*/
          return errors;
        }}
        render={(values: any, push: any, pop: any, form: any) => {
          let dData: any = data.provisioningSettings;
          if (data.provisioningSettings === undefined) {
            dData = {
              customFields: [],
            };
          }

          return (
            <fieldset>
             
              {values.id === "" && <legend>Provisioning</legend>}
              <FormProvisioning
                resellerMode={false}
                showYeaLinkFirmware={false}
                data={dData}
                updateFieldOverride={(fn: string, data: any) => {
                  console.log(fn, data)
                  form.change("provisioningSettings." + fn, data);
                }}
                submitFunction={(payload: any) => {
                  console.log(payload);
                }}
              />
            </fieldset>
          );
        }}
        onSubmit={(values: any, next: any) => {
          next(values);
        }}
      ></Wizard.Page>
    </Wizard>
  );
};

export default DeviceForm;

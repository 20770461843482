import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import { IPhonebookSettings } from "../../interfaces/phonebook";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import PageHead from "../../components/PageHead";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import ResellerRedirect from "../../components/ResellerRedirect";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";

const set = new MySettings();

interface RouteParams {
  id: string;
}

const PhoneBookLDAPSettings: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = ({ match, resellerID, session }) => {
  const [settings, setSettings] = useState<IPhonebookSettings>({
    customersId: "",
    safeCustomerName: "",
    password: "",
    hostname:''
  });
  const [newpassword, setNewPassword] = useState<string>("");
  const [newsync, setNewSync] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [phoneBookName, setPhoneBookName] = useState<string>('Phone Book')

  useEffect(() => {
    let isMounted = true;

    const getSettings = async () => {
      try {
        const data = await set.phonebookSettingsGet(match.params.id);
        const phoneBook = await set.getPhoneBook(match.params.id);
        if (isMounted) {
          setSettings(data);
          setNewSync(phoneBook.phonebook.syncWith365);
          setLoading(false);
          setPhoneBookName(phoneBook.phonebook.name)
        }
      } catch (error) {
        App.newNotificationError('There was a problem fetching the phone book settings.')   
      }
    }

    getSettings();

    return () => {
      isMounted = false;
    };
  }, [match.params.id]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const newsettings = await set.phonebookSettingsUpdatePassword(
        match.params.id,
        newpassword
      );
      setSettings(newsettings);
      setNewPassword("");
      const notification: INotification = {
        title: "Password Updated",
        data: "The password for this phone book has been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "PhoneBookLDAPSettings",
        srcData: "success",
        srcFunction: "phonebookSettingsUpdatePassword",
      };
      App.newNotification(notification);
    } catch (err) {
      const notification: INotification = {
        title: "Failed",
        data: "There was a problem updating the password. " + err.statusText,
        variant: NOTIFICATION_TYPE.warning,
        srcComponent: "PhoneBookLDAPSettings",
        srcData: "failed",
        srcFunction: "phonebookSettingsUpdatePassword",
      };
      App.newNotification(notification);
    }
    setLoading(false);
  };

  const onSyncSubmit = async () => {
    setLoading(true);
    try {
      const phonebook = await set.phonebookSettingsUpdate(
        match.params.id,
        newsync
      );
      setNewSync(phonebook.syncWith365);
      const notification: INotification = {
        title: "Updated",
        data: "This phone book has been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "PhoneBookLDAPSettings",
        srcData: "success",
        srcFunction: "phonebookSettingsUpdateSync",
      };
      App.newNotification(notification);
    } catch (err) {
      const notification: INotification = {
        title: "Failed",
        data: "There was a problem updating the phone book. " + err.statusText,
        variant: NOTIFICATION_TYPE.warning,
        srcComponent: "PhoneBookLDAPSettings",
        srcData: "failed",
        srcFunction: "phonebookSettingsUpdatePassword",
      };
      App.newNotification(notification);
    }
    setLoading(false);
  };

  const onNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.currentTarget.value);
  };

  const onNewSyncChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSync(event.currentTarget.checked);
  };

  let customerUsername =
    "uid=search,dc=" + settings.safeCustomerName + ",dc=vipvoipuk,dc=net";
  let baseNode = "dc=" + settings.safeCustomerName + ",dc=vipvoipuk,dc=net";

  return (
    <div className="content">
      <PageHead
        resellerID={resellerID}
        title="Phone Book Settings"
        parents={[
          { title: "Phone Books", url: "../PhoneBooks" },
          { title: phoneBookName, url: "../phonebook/" + match.params.id },
        ]}
      ></PageHead>

      {loading ? (
        <Loading />
      ) : (
        <>
          {resellerID === undefined &&
          session.userType === "resellersContacts" ? (
            <ResellerRedirect />
          ) : (
            <Row className="whiteBox">
              <Col md="12" lg="12">
                <Card>
                  <Card.Header>
                    <h2>LDAP Settings</h2>
                  </Card.Header>
                  <Card.Body className="form-card-body">
                    <p>If you need to configure LDAP for your phone manually then please use these settings below.</p>
                    <p>LDAP Name Filter: (|(cn=%)(sn=%))</p>
                    <p>
                      LDAP Number Filter:
                      (|(telephoneNumber=%)(Mobile=%)(ipPhone=%))
                    </p>
                    <p>Server Address: {settings.hostname}</p>
                    <p>Server Port: 389</p>
                    <p>Base: {baseNode}</p>
                    <p>Username: {customerUsername}</p>
                    <p>Password: {settings.password}</p>
                    <p>Max Hits: 50</p>
                    <p>LDAP Name Attributes: cn sn</p>
                    <p>
                      LDAP Number Attributes: Mobile telephoneNumber ipPhone
                    </p>
                    <p>LDAP Display Name: %cn</p>
                    <p>Protocol Version: 3</p>
                    <p>LDAP Lookup for Incoming Calls: Enabled</p>
                    <p>LDAP Sorting Results: Enabled</p>
                  </Card.Body>
                </Card>
                <br />
                <Card>
                  <Form onSubmit={onSubmit}>
                    <Card.Header>
                      <h2>Change Password</h2>
                    </Card.Header>
                    <Card.Body className="form-card-body">
                      <Form.Group controlId="password">
                        <Form.Label>
                          If you want to set a new password please enter a
                          password at least 8 characters length. You can use
                          numbers, letters and hyphens.:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter new password."
                          value={newpassword}
                          onChange={onNewPasswordChange}
                        />
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Button variant="primary" type="submit">
                        Change Password
                      </Button>
                    </Card.Footer>
                  </Form>
                </Card>
                <br />
                <Card>
                  <Form onSubmit={onSyncSubmit}>
                    <Card.Header>
                      <h2>Microsoft 365 Sync</h2>
                    </Card.Header>
                    <Card.Body className="form-card-body">
                      <Form.Group controlId="syncWith365">
                        <Form.Label>
                          Enabling this will push this phone book in to your Microsoft 365 account so that you can access the contacts in Outlook. They will appear as a new folder in your Outlook contacts area.
                        </Form.Label>
                        <Form.Control
                          type="checkbox"
                          checked={newsync}
                          onChange={onNewSyncChange}
                          value=""
                        />
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Button variant="primary" type="submit">
                        Update
                      </Button>
                    </Card.Footer>
                  </Form>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};
export default PhoneBookLDAPSettings;

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";

import PageHead from "../../components/PageHead";
import { IProvisioningSettings } from "../../interfaces/provisioning";

import FormProvisioning from "../../components/oldForms/form-provisioning";
import Error from "../../components/error";


import MySettings from "../../static/settings";
import { RouteComponentProps } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import App from "../../App";
import React from "react";
const set = new MySettings();

export interface IProvisionState {
  loading: boolean;
  data: IProvisioningSettings;
  displayVaultForm: boolean;
  error: boolean;
}

interface IResellerProvisioningSettingsPage
  extends RouteComponentProps<any>,
  IPageProps { }

export enum ResellerProvisioningSettingsgPageState {
  ShowTable,
  Loading,
  NetworkError,
 // NoResults,
}



const ResellerProvisioningSettings = ({resellerID, history}: IResellerProvisioningSettingsPage) => {
  const [state, setState] = useState(() => {
    return {
      displayVaultForm: false,
      data: { customFields: [], extraAccounts: [],  yealinkFirmwares:[]},
    }
  })

  const [pageState, setPageState] = useState(ResellerProvisioningSettingsgPageState.Loading);
  const isMounted = useRef(false);


  useEffect(() => {

    const Go = async () => {
      try {
        const data = await set.resellerProvisioningSettingsGet();

        const stateUpdate: any = {};
        stateUpdate.loading = false;

        stateUpdate.data = data;

        setState((s) => { return { ...s, ...stateUpdate } });


        setPageState(ResellerProvisioningSettingsgPageState.ShowTable)

      } catch (ex) {
        setPageState(ResellerProvisioningSettingsgPageState.NetworkError);
      }

    }
    if (isMounted.current === false) {
      isMounted.current = true;
      Go();

    }
  }, [])


  const saveDevice = async (device: IProvisioningSettings) => {
    //  console.log("saveSettings", device)

   try{
    setPageState(ResellerProvisioningSettingsgPageState.Loading)

      await set.resellerProvisioningUpdate(device)
     
        //this.setState({  loading: false });
        //this.getDevice();
        if (history) {
          history.push(`../provisioning`);
        }
        setPageState(ResellerProvisioningSettingsgPageState.ShowTable)
        const alert: INotification = {
          title: "Success",
          data: "Settings updated.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "ResellerProvisioningSettings",
          srcData: "success",
          srcFunction: "handleSubmit",
        };
        App.newNotification(alert);

    } catch (error:any) {
        App.newNotificationError(error.data);

        setPageState(ResellerProvisioningSettingsgPageState.NetworkError);
      }

  };

  return <Grid container spacing={3} alignContent="flex-start">
    <Grid item xs={12}>
      <PageHead
        resellerID={resellerID}
        title={"Reseller Provisioning Settings"}
        parents={[
          { title: "Provisioning - Devices", url: "../provisioning" },
        ]}
      ></PageHead>



      <Box style={{
        borderRadius: "4px",
        backgroundColor: "#ffffff",
        boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.08)",
        padding: " 24px 12px",
      }}>

        {pageState === ResellerProvisioningSettingsgPageState.Loading && <Loading />}
        {pageState === ResellerProvisioningSettingsgPageState.NetworkError && <Error />}
        {pageState === ResellerProvisioningSettingsgPageState.ShowTable && <>
          <FormProvisioning
                  resellerMode={true}
                  showYeaLinkFirmware={true}
                  data={state.data}
                  submitFunction={saveDevice}
                />
        </>}
      </Box>
    </Grid>
  </Grid>
}
export default ResellerProvisioningSettings;
// }
// export default class ResellerProvisioningSettings extends Component<
//   IResellerProvisioningSettingsPage,
//   IProvisionState
// > {
//   public formNumber: FormNumber = new FormNumber({});
//   constructor(props: any) {
//     super(props);

//     this.getDevice.bind(this);

//     //  this.clickEhaCancel.bind(this);
//     //  this.clickEhaEdit.bind(this);

//     //  this.clickForwardEdit.bind(this);
//     //  this.clickForwardCancel.bind(this);

//     this.state = {
//       displayVaultForm: false,
//       loading: true,
//       error: false,
//       data: { customFields: [], extraAccounts: [] },
//     };
//   }

//   public render() {
//     const { loading, data, error } = this.state;
//     let dData = data;
//     if (dData === undefined) {
//       dData = {
//         customFields: [], extraAccounts: []
//       };
//     }
//     let title: string;
//     title = "Reseller Provisioning Settings";

//     return (
//       <>
//         <div className="content">


//           {error ? (
//             <Error />
//           ) : loading ? (
//             <Loading />
//           ) : (
//             <Row>
//               <Col md="12">
          
//               </Col>
//             </Row>
//           )}
//         </div>
//       </>
//     );
//   }

//   public saveDevice = (device: IProvisioningSettings) => {
//     //  console.log("saveSettings", device)

//     // console.log(device)
//     this.setState({ loading: true });
//     set
//       .resellerProvisioningUpdate(device)
//       .then((resp) => {
//         // console.log(resp);
//         //this.setState({  loading: false });
//         //this.getDevice();
//         if (this.props.history) {
//           this.props.history.push(`../provisioning`);
//         }
//       })
//       .catch((exception) => {
//         catchME(this, exception, true);
//       });
//   };

//   public componentDidMount = () => {
//     // const requestedID = this.props.match.params.id;
//     this.getDevice();
//     // console.log(requestedID);
//   };

//   // get person
//   public getDevice = () => {
//     let query;

//     query = set.resellerProvisioningSettingsGet();
//     query
//       .then((data: IProvisioningSettings) => {
//         //  console.log(data);
//         const stateUpdate: any = {};
//         stateUpdate.loading = false;

//         stateUpdate.data = data;

//         this.setState(stateUpdate);
//       })
//       .catch((exception) => {
//         catchME(this, exception, true);
//       });
//   };
// }

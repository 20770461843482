import queryString from "query-string";
import * as React from "react";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { RouteComponentProps } from "react-router-dom";
import App from "../App";
import Loading from "../components/loading";
import PageHead from "../components/PageHead";
import { IPageProps } from "../interfaces/page-props";
import MySettings, { IOffice365AuthedResponse } from "../static/settings";
import TeamsCallRecordingLicenseAllocator from "./TeamsCallRecordingLicenseAllocator";
const set = new MySettings();
interface RouteParams { }
const TeamsCallRecordingPage: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = () => {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState("");
  const [newsettings, setSettings] = useState<IOffice365AuthedResponse>({
    authURL: "",
    authed: false,
    actionStepCreateFileNotes: false,
    actionStepCreateTimeEntries: false,
  });

  useEffect(() => {
    let isMounted = true;

    const getSettings = async () => {
      let state = "";
      const parsed = queryString.parse(window.location.search);
      if (parsed.state) {
        state = parsed.state.toString();
        setState(parsed.state.toString());
        console.log(state);
      }
      try {
        const data = await set.teamsCallRecordingIsAuthed(state);
        if (isMounted) {
          setSettings(data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);

        App.newNotificationError(
          "Failed to load Teams call recording configuration " + error.data
        );
      }
    };
    console.log(state);

    getSettings();

    return () => {
      isMounted = false;
    };
  }, [state]);

  // const onSubmit = async (e: React.FormEvent) => {
  //   setLoading(true);
  //   e.preventDefault();
  //   try {
  //     const updatedsettings = await set.teamsCallRecordingUpdateSettings(
  //       newsettings
  //     );
  //     setSettings(updatedsettings);
  //     const notification: INotification = {
  //       title: "Configuration Updated",
  //       data: "The Teams call recording configuration has been updated.",
  //       variant: NOTIFICATION_TYPE.success,
  //       srcComponent: "TeamsCallRecordingPage",
  //       srcData: "success",
  //       srcFunction: "onSubmit",
  //     };
  //     App.newNotification(notification);
  //   } catch (err) {
  //     App.newNotificationError(
  //       "Failed to update the Teams call recording configuration " + err.data
  //     );
  //   }
  //   setLoading(false);
  // };
  /*
  const onCreateFileNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...newsettings,
      actionStepCreateFileNotes: event.currentTarget.checked,
    })
  }

  const onCreateTimeEntriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...newsettings,
      actionStepCreateTimeEntries: event.currentTarget.checked,
    });
  }
*/
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PageHead
        resellerID={""}
        title={"Teams Call Recording Settings"}
        parents={[]}
      ></PageHead>

      <div className="content">
        {loading === true ? (
          <Loading />
        ) : (
          <Row>
            <br />
            <Col md={{ span: 12 }}>
              <br />
              <Card>
                <Card.Header>Teams Call Recording</Card.Header>
                <Card.Body>
                  {newsettings.authed ? (
                    <>
                      <div style={{ textAlign: "center" }}>
                        Your 365 tenancy is now paired with our call recording
                        service. If you need to unpair or have paired the wrong
                        tenancy by mistake please use the unpair button below.
                        <br />
                        <br />
                        <div style={{ textAlign: "center" }}>
                          <a
                            className="btn btn-primary"
                            href="/voip/api/teamscallrecording/redirected?code=unpair"
                          >
                            Click Here To Unpair
                          </a>
                        </div>
                        <br />
                        <br />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        To complete the set up please make sure your 365 tenancy
                        is configured correctly by following our guide.
                        <br />
                        <br />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <a
                          className="btn btn-primary"
                          href="/voip/uploadFile?download=true&file=teamsCallRecordingSetUp.pdf&label=teamsCallRecordingSetUp.pdf"
                          target="_blank"
                        >
                          Download Setup Guide
                        </a>
                      </div>
                      {/*
                    TODO Mcbobby - add empty component here
                    Actually it would be better to handle the license allocation on this page using a component
                    <div style={{textAlign:'center'}}>
                      <br />
                      <br />
                      Once the guide has been followed you will also need to allocate the call recording licenses to your users.
                      <br />
                      <br />
                    </div>
                    <div style={{textAlign:'center'}}>
                      <a className="btn btn-primary" href="/voip/portal/teamscallrecordingallocatelicenses">Allocate Licenses</a>
                </div>*/}
                    </>
                  ) : (
                    <div>
                      Click the link below to enable your Teams call recording.
                    </div>
                  )}
                </Card.Body>
                <Card.Footer style={{ textAlign: "center", color: "white" }}>
                  {newsettings.authed ? (
                    <></>
                  ) : (
                    <a className="btn btn-primary" href={newsettings.authURL}>
                      Click Here To Pair
                    </a>
                  )}
                </Card.Footer>
              </Card>
              {/*
            <br />
            <Form onSubmit={onSubmit}>
              <Card>
                <Card.Header>Action Step Settings</Card.Header>
                <Card.Body>
                  <Form.Group controlId="actionStepCreateFileNotes">
                    <Form.Label>Create file notes:</Form.Label>
                    <Form.Control
                      type="checkbox"
                      checked={newsettings.actionStepCreateFileNotes}
                      onChange={onCreateFileNotesChange}
                      name="actionStepCreateFileNotes"
                    />
                  </Form.Group>
                  <Form.Group controlId="actionStepCreateTimeEntries">
                    <Form.Label>Create time entries:</Form.Label>
                    <Form.Control
                      type="checkbox"
                      checked={newsettings.actionStepCreateTimeEntries}
                      onChange={onCreateTimeEntriesChange}
                      name="actionStepCreateTimeEntries"
                    />
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Button variant="primary" type="submit">
                    Update Configuration
                  </Button>
                </Card.Footer>
              </Card>
            </Form> 
            */}
            </Col>
          </Row>
        )}
      </div>
      {state && <TeamsCallRecordingLicenseAllocator state={state} />}
    </div>
  );
};
export default TeamsCallRecordingPage;

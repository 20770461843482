import React from "react";

import { Form } from "react-final-form";
import VipTextFinalField from "../../components/misc/form/VipTextFinalField";
import { ISearchFormProps } from "../../interfaces/search-form";

const ReceptionistSearchForm: React.FunctionComponent<ISearchFormProps> = (
  props
) => {
  /*
    let arrayOfNumbers: any[] = [];

    arrayOfNumbers = PROP.array.map((dd: any) => {
        const thing = { value: dd.name, label: dd.name };
        return thing;
    });
*/

  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={props.data}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-rowd-flex justify-content-between">
            <div className="input-group">
              <VipTextFinalField
                fieldName="query"
                placeholder="Name"
                required={false}
              />
            </div>
            <div>
              <button
                className="btn btn-outline-primary"
                type="submit"
                disabled={submitting}
              >
                Search
              </button>
            </div>
            <div>{props.children}</div>
          </div>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </form>
      )}
    />
  );
};

export default ReceptionistSearchForm;

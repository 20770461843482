import React, { useState } from "react";

import { ButtonGroup } from "react-bootstrap";

import { Form } from "react-final-form";
import VipTextFinalField from "../misc/form/VipTextFinalField";

const SidebarDeliveryAddress = ({ data, onSubmit }: any) => {
  const [formEna, setFormEna] = useState<boolean>(false);

  /*
  deliveryAddressLine1?:string
  deliveryAddressLine2?:string
  deliverycity?:string
  deliverycounty?:string
  deliverypostCode?:string

 
 
  */
  const updateData = {
    id: data.id,
    deliveryAddressLine1: data.deliveryAddressLine1,
    deliveryAddressLine2: data.deliveryAddressLine2,
    deliverycity: data.deliverycity,
    deliverycounty: data.deliverycounty,
    deliverypostCode: data.deliverypostCode,
  };

  const hideSubmit = (payload: any) => {
    setFormEna(false);
    return onSubmit(payload);
  };
  if (formEna === false) {
    //Not displaying form
    return (
      <div className="sidebarDiv">
        <div className="sidebarTitle">Address Line 1</div>
        <div className="sidebarText">{data.deliveryAddressLine1}</div>
        <div className="sidebarTitle">Address Line 2</div>
        <div className="sidebarText">{data.deliveryAddressLine2}</div>
        <div className="sidebarTitle">City</div>
        <div className="sidebarText">{data.deliverycity}</div>
        <div className="sidebarTitle">County</div>
        <div className="sidebarText">{data.deliverycounty}</div>
        <div className="sidebarTitle">Post Code</div>
        <div className="sidebarText">{data.deliverypostCode}</div>

        <button
          className="btn btn-outline-primary sidebarSubButton"
          onClick={() => setFormEna(true)}
        >
          Edit general contact
        </button>
      </div>
    );
  } else {
    //form
    return (
      <div className="sidebarDiv">
        <Form
          onSubmit={hideSubmit}
          initialValues={updateData}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="sidebarTitle">Address Line 1</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="deliveryAddressLine1"
                  placeholder="Address Line 1"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">Address Line 2</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="deliveryAddressLine2"
                  placeholder="Address Line 2"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">City</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="deliverycity"
                  placeholder="City"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">County</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="deliverycounty"
                  placeholder="County"
                  required={true}
                />
              </div>
              <div className="sidebarTitle">Post Code</div>
              <div className="sidebarText">
                <VipTextFinalField
                  fieldName="deliverypostCode"
                  placeholder="County"
                  required={true}
                />
              </div>

              <div className="side-button">
                <ButtonGroup>
                  <button
                    className="btn btn-primary sidebarFormButton"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setFormEna(false)}
                    className="btn btn-outline-primary sidebarFormButton"
                  >
                    Cancel
                  </button>
                </ButtonGroup>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
};

export default SidebarDeliveryAddress;

import * as React from "react";
import {
  Button, ButtonGroup, ButtonToolbar, Dropdown, DropdownButton, FormControl, InputGroup, Pagination, Table
} from "react-bootstrap";
import image from "../compiledSVG/undraw_people_search_wctu.svg";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowDown,
  faArrowUp, faCheckSquare, faSearch,
  faSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import "../sass/voiptable.scss";

library.add(faArrowDown, faArrowUp, faSearch, faSquare, faCheckSquare);

export interface ITableMultiAction {
  title: string;
  f: (ids: any[]) => void;
}
export interface ITableState {
  cacheData: any[];
  perPage: number;
  activePage: number;
  aroundPage: number;
  perPageOptions: number[];
  sortBy: string;
  sortByStart: boolean;
  sortReverse: boolean;
  multiSelect: boolean;
  multiSelectActions: ITableMultiAction[];
  enableSearch: boolean;
  showResultsCount: boolean;
  holderClass: string;
  selectAll: boolean;
}

export interface ITableProps {
  title: string;
  titleButtons?: any;
  data: any[];
  col: any[];
  multiSelect?: boolean;
  multiSelectActions?: ITableMultiAction[];
  sortByStart?: string;
  sortReverse?: boolean;
  showResultsCount?: boolean;
  holderClass?: string;
  rowClickLink?: string;
  history?: any;
  onRender?: (state: ITableState) => void;
}

export default class VoipTable extends React.Component<
  ITableProps,
  ITableState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      cacheData: [...props.data],
      perPage: 25,
      activePage: 1,
      aroundPage: 2,
      perPageOptions: [10, 25, 50, 100],
      sortBy: props.sortByStart ? props.sortByStart : "",
      sortReverse: props.sortReverse ? props.sortReverse : false,
      multiSelect: props.multiSelect ? true : false,
      multiSelectActions: props.multiSelectActions
        ? props.multiSelectActions
        : [],
      enableSearch: false,
      showResultsCount:
        props.showResultsCount != null ? props.showResultsCount : true,
      holderClass: props.holderClass ? props.holderClass : "whiteBox",
      sortByStart: props.sortByStart ? props.sortByStart : false,
      selectAll: false,
    };
  }

  public selectPerPage = (e: any) => {
    const x = parseInt(e);
    if (x !== this.state.perPage) {
      this.setState({
        perPage: x,
        activePage: 1,
      });
    }
  };

  public selectedRowsCount = (): number => {
    const found = this.state.cacheData.filter(
      (i) => i.___checkboxChecked === true
    );
    return found.length;
  };

  public selectedRows = (): any => {
    const found: any = this.state.cacheData.filter(
      (i) => i.___checkboxChecked === true
    );
    return found;
  };

  public onAction = (e: any) => {
    const found = this.state.cacheData.filter(
      (i) => i.___checkboxChecked === true
    );
    const func = this.state.multiSelectActions.filter((i) => i.title === e)[0];

    if (func) {
      func.f(found);
    }
  };

  public compare = (a: any, b: any) => {
    // Use toUpperCase() to ignore character casing
    let A = a[this.state.sortBy];
    let B = b[this.state.sortBy];
    if (typeof A === "string") {
      A = A.toUpperCase();
    }
    if (typeof B === "string") {
      B = B.toUpperCase();
    }

    let comparison = 0;
    if (A > B) {
      comparison = 1;
    } else if (A < B) {
      comparison = -1;
    }

    if (this.state.sortReverse) {
      return comparison * -1;
    } else {
      return comparison;
    }
  };

  public checkAll = (checked: boolean) => {
    const tmpData = [...this.state.cacheData];
    for (let i = 0; i < tmpData.length; i++) {
      tmpData[i].___checkboxChecked = checked;
    }
    this.setState({ cacheData: tmpData });
  };

  public checkRow = (id: string) => {
    const tmpData = [...this.state.cacheData];

    const foundIndex = tmpData.findIndex((obj) => obj.id === id);
    const thing: any = { ...tmpData[foundIndex] };
    if (thing.___checkboxChecked) {
      delete thing.___checkboxChecked;
    } else {
      thing.___checkboxChecked = true;
    }

    tmpData[foundIndex] = thing;
    this.setState({ cacheData: tmpData });
  };

  public checkMultipleRow = () => {
    const newSelectAll = !this.state.selectAll;
    this.checkAll(newSelectAll);
    this.setState({
      selectAll: newSelectAll,
    });
  };
  public checkLicensedRole = () => {
    const tmpData = [...this.state.cacheData];
    console.log(tmpData);

    for (let i = 0; i < tmpData.length; i++) {
      tmpData[i].___checkboxChecked = tmpData[i].licensed;
    }
    this.setState({ cacheData: tmpData });
    console.log(tmpData);
  };

  public setSortBy = (key: string) => {
    if (this.state.sortBy === "") {
      this.setState({
        sortBy: key,
      });
    } else {
      if (this.state.sortBy === key) {
        this.setState({
          sortReverse: !this.state.sortReverse,
        });
      } else {
        this.setState({
          sortBy: key,
          sortReverse: false,
        });
      }
    }
  };

  public render() {
    let actualData: any[];
    if (this.state.sortBy === "") {
      actualData = [...this.state.cacheData];
    } else {
      actualData = [...this.state.cacheData].sort(this.compare);
    }
    if (this.props.onRender) {
      this.props.onRender(this.state);
    }
    const pages = Math.ceil(this.state.cacheData.length / this.state.perPage);
    const startIndex = (this.state.activePage - 1) * this.state.perPage;

    const activeData: any[] = actualData.splice(startIndex, this.state.perPage);
    const headCols = [];
    let headCount = 0;
    if (this.state.multiSelect) {
      // if were having like select all or select and do xyz..
      headCols.push(
        <th key={headCount} className="multiselect">
          MultiSelect
        </th>
      );
      headCount++;
    }
    // lets generate the header
    for (const col of this.props.col) {
      if (col.sortable) {
        // Sortable ....
        const sortClick = (e: any) => {
          this.setSortBy(col.accessor);
        };

        if (col.accessor === this.state.sortBy) {
          if (this.state.sortReverse) {
            headCols.push(
              <th key={headCount}>
                <Button variant="link" onClick={sortClick} className="nomargin">
                  {col.Header}
                </Button>
                <FaIcon icon="arrow-up" className="fa-sm" />
              </th>
            );
          } else {
            headCols.push(
              <th key={headCount}>
                <Button variant="link" onClick={sortClick} className="nomargin">
                  {col.Header}
                </Button>
                <FaIcon icon="arrow-down" className="fa-sm" />
              </th>
            );
          }
        } else {
          headCols.push(
            <th key={headCount}>
              <Button variant="link" onClick={sortClick} className="nomargin">
                {col.Header}
              </Button>
            </th>
          );
        }
      } else {
        // NOT sortable just display header
        headCols.push(<th key={headCount}>{col.Header}</th>);
      }
      headCount++;
    }

    // lets generate the data rows
    const dataRows = [];
    let rowCount = 0;

    for (const row of activeData) {
      let isSelected = false;
      rowCount++;
      const ffsRow = [];
      let rC = 0;
      // add selected data
      if (this.state.multiSelect) {
        if (row.___checkboxChecked) {
          isSelected = true;
        }

        // let ico;
        // if (!isSelected) {
        //   ico = (
        //     <FaIcon icon="square" className="fa-lg tableCheckboxUnChecked" />
        //   );
        // } else {
        //   ico = <FaIcon icon="check-square" className="fa-lg" />;
        // }
        ffsRow.push(
          <td key={rC}>
            <input
              type="checkbox"
              onClick={() => {
                if (this.state.selectAll) {
                  //unset select all since not all selected anymore
                  this.setState({ selectAll: false });
                  this.checkAll(true);
                }
                this.checkRow(row.id);
              }}
              className="tableCheckbox"
              style={{ cursor: "pointer" }}
              checked={row.___checkboxChecked}
            ></input>
          </td>
        );
        rC++;
      }
      for (const col of this.props.col) {
        let payLoad, classN;
        if (col.Cell) {
          classN = "text-right";
          if (col.cssClass) {
            classN = col.cssClass;
          }
          payLoad = col.Cell({ obj: row, value: row[col.accessor] });
          ffsRow.push(
            <td className={classN} key={rC}>
              {payLoad}
            </td>
          );
        } else {
          if (col.cssClass) {
            classN = col.cssClass;
          }
          payLoad = row[col.accessor];
          if (col.decimalPad) {
            try {
              payLoad = payLoad.toFixed(2);
            } catch (ex) { }
          }
          ffsRow.push(
            <td className={classN} key={rC}>
              {payLoad}
            </td>
          );
        }

        rC++;
      }

      const rowLinks: {
        [key: string]: string;
      } = {
        customers: "/reseller/" + row.id,
        numbers: "./number/" + row.id + "/0",
      };

      let rowLink: string = "";
      if (this.props.rowClickLink) rowLink = rowLinks[this.props.rowClickLink];

      if (isSelected) {
        dataRows.push(
          this.props.rowClickLink ? (
            <tr
              key={rowCount}
              className="test_resultRow activeTR cursorPointer"
              onClick={() => this.props.history.push(rowLink)}
            >
              {ffsRow}
            </tr>
          ) : (
            <tr key={rowCount} className="test_resultRow activeTR">
              {ffsRow}
            </tr>
          )
        );
      } else {
        dataRows.push(
          this.props.rowClickLink ? (
            <tr
              key={rowCount}
              className="test_resultRow cursorPointer"
              onClick={() => this.props.history.push(rowLink)}
            >
              {ffsRow}
            </tr>
          ) : (
            <tr key={rowCount} className="test_resultRow">
              {ffsRow}
            </tr>
          )
        );
      }
    }

    // PAGE NUMBERING
    const pageLinks = [];

    let startPage = this.state.activePage - this.state.aroundPage;
    if (startPage <= 1) {
      startPage = 1;
    }

    let endPage = this.state.activePage + this.state.aroundPage;
    if (endPage >= pages) {
      endPage = pages;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageLinks.push(
        <Pagination.Item
          key={i}
          active={i === this.state.activePage}
          onClick={() => this.setState({ activePage: i })}
        >
          {i}
        </Pagination.Item>
      );
    }

    let MultiSelect = <></>;
    if (this.state.multiSelect) {
      const actionsDropdown = [];
      if (this.state.multiSelectActions) {
        for (const act of this.state.multiSelectActions) {
          actionsDropdown.push(
            <Dropdown.Item key={act.title} eventKey={act.title}>
              {act.title}
            </Dropdown.Item>
          );
        }
      }
      let actTitle = "Actions";
      const selected = this.selectedRowsCount();
      if (selected > 0) {
        actTitle = selected + " Selected";
      }
      MultiSelect = (
        <DropdownButton
          size="sm"
          as={ButtonGroup}
          title={actTitle}
          id="bg-nested-dropdown"
          onSelect={this.onAction}
          style={{
            position: "absolute",
            bottom: "-40px",
            left: "10px",
          }}
        >
          {actionsDropdown}
        </DropdownButton>
      );
    }

    //  const noresultssvg = process.env.REACT_APP_PATH+'svg/undraw_people_search_wctu.svg';
    return this.state.cacheData.length >= 1 ? (
      <>
        <div className={this.state.holderClass}>
          {(this.state.showResultsCount ||
            this.state.multiSelect ||
            this.state.enableSearch) && (
              <ButtonToolbar
                className="justify-content-between"
                aria-label="Toolbar with Button groups"
              >
                {this.state.showResultsCount && (
                  <ButtonGroup>
                    <h4 className="tableH4">
                      {this.state.cacheData.length} Results
                    </h4>
                  </ButtonGroup>
                )}
                <InputGroup>
                  {this.state.enableSearch && (
                    <>
                      <FormControl
                        type="text"
                        placeholder="Input group example"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon2"
                      />
                      <InputGroup.Prepend>
                        <InputGroup.Text id="btnGroupAddon2">
                          <FaIcon icon="search" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </>
                  )}
                  <div
                    style={{
                      width: "100px",
                      position: "relative",
                    }}
                  >
                    {MultiSelect}
                  </div>
                </InputGroup>
              </ButtonToolbar>
            )}

          <Table className="vipTable" hover responsive>
            <thead>
              <tr>{headCols}</tr>
            </thead>
            <tbody>{dataRows}</tbody>
          </Table>

          <ButtonToolbar
            className="justify-content-between"
            aria-label="Toolbar with Button groups"
          >
            {actualData.length >= this.state.perPage && (
              <div>
                <Pagination size="sm">
                  {this.state.activePage !== 1 && (
                    <>
                      <Pagination.First
                        onClick={() => this.setState({ activePage: 1 })}
                        className="first"
                        key="f"
                      >
                        First
                      </Pagination.First>

                      <Pagination.Prev
                        onClick={() =>
                          this.setState({
                            activePage: this.state.activePage - 1,
                          })
                        }
                        className="prev"
                      >
                        Prev
                      </Pagination.Prev>
                      {pages >= 5 && (
                        <>
                          <Pagination.Ellipsis />
                        </>
                      )}
                    </>
                  )}

                  {pageLinks}

                  {this.state.activePage !== pages && (
                    <>
                      {pages >= 5 && (
                        <>
                          <Pagination.Ellipsis />
                        </>
                      )}

                      <Pagination.Next
                        onClick={() =>
                          this.setState({
                            activePage: this.state.activePage + 1,
                          })
                        }
                        className="next"
                      >
                        Next
                      </Pagination.Next>
                      <Pagination.Last
                        onClick={() =>
                          this.setState({
                            activePage: pages,
                          })
                        }
                        className="last"
                        key="l"
                      >
                        Last
                      </Pagination.Last>
                    </>
                  )}
                </Pagination>
              </div>
            )}

            <div className="divRows">
              <p className="pRows">Rows per page</p>
              <DropdownButton
                size="sm"
                as={ButtonGroup}
                title={this.state.perPage}
                id="bg-nested-dropdown"
                onSelect={this.selectPerPage}
                variant="outline-primary"
                className="perPageButton"
              >
                {this.state.perPageOptions.map((value) => {
                  return (
                    <Dropdown.Item key={value} eventKey={value.toString()}>
                      {value}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </div>
          </ButtonToolbar>
        </div>
      </>
    ) : (
      <>
        <div className="table-centered">
          <img
            src={image}
            className="img-fluid img-table"
            alt="No Results Found"
          />

          <h3 className="table1">
            No {this.props.title.toLowerCase()} matching the current search.
          </h3>
        </div>
      </>
    );
  }
}

import * as React from "react";

import { Field } from "react-final-form";
import requiredValidator from "./requiredValidator";
import minLength from "./minLength";
import composeValidators from "./composeValidators";
import ukNumberValidator from "./ukNumberValidator";
import makeId from "./makeId";

type TTextField = {
  fieldName: string;
  placeholder: string;
  required: boolean;
  type?: string;
  disabled?: boolean;
  step?: string;
  minLength?: number;
};

const VipTextFinalField: React.FunctionComponent<TTextField> = ({
  fieldName,
  placeholder,
  required,
  type,
  disabled,
  step,
}) => {
  let validate: any = false;
  //console.log(placeholder);
  if (type === undefined) {
    type = "text";
  }

  if (disabled === undefined) {
    disabled = false;
  }

  if (type === "ukPhone") {
    type = "text";
    validate = ukNumberValidator;
  }

  if (required === true) {
    if (type === "password") {
      validate = composeValidators(requiredValidator, minLength(8));
    } else {
      validate = requiredValidator;
    }
  }
  return (
    <Field
      name={fieldName}
      component="input"
      type={type}
      placeholder={placeholder}
      validate={validate}
    >
      {({ input, meta }) => (
        <div>
          {step !== undefined ? (
            <input
              {...input}
              id={input.name.replace(".", "_")}
              autoComplete={"autocomplete_off_hack_" + makeId(5)}
              className="form-control"
              placeholder={placeholder}
              disabled={disabled}
              min={0}
              step={step}
            />
          ) : (
            <input
              {...input}
              id={input.name.replace(".", "_")}
              className="form-control"
              placeholder={placeholder}
              disabled={disabled}
            />
          )}
          {meta.error && meta.touched && (
            <span style={{ color: "red" }}>{meta.error}</span>
          )}
        </div>
      )}
    </Field>
  );
};

export default VipTextFinalField;

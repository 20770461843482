import { IEmailSubReportNumberTrackers } from "../../interfaces/email-reports";
import { Grid } from "@material-ui/core";
import MuiTitle from "../misc/form/MuiTitle";
import { IExtension } from "../../interfaces/extension";

export interface iEmailReportNumberTrackersProp {
  extensions: IExtension[];
  data: IEmailSubReportNumberTrackers;
  submitFunction: (field: string, data: any) => void;
}


const EmailReportNumberTrackers = () =>{

  return <Grid container spacing={2}>

    <Grid item xs={12}>
      <MuiTitle title="There are no options for this report." />
    </Grid>
  </Grid>
}
export default EmailReportNumberTrackers;

import React, { useState } from "react";
import { Link } from "react-router-dom";

import { MenuItem, Menu, Button } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

export interface IDropdownButtonProps {
  options: { target:string; label: string; link?: string; onClick?: () => void }[];
  label: string;
  required?: boolean;
}

const MuiDropdownButton: React.FC<IDropdownButtonProps> = ({
  options,
  label,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        onClick={(event: any) => {
          setAnchorEl(event.currentTarget);
        }}
        variant="outlined"
        color="primary"
      >
        {label}
        <ArrowDropDown
          style={{ marginLeft: "4px", marginRight: "-8px", fill: "#2B2B36" }}
        />
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            // maxHeight: 48 * 4.5,
            // width: "20ch",
            border: "1px solid #C7C8CB",
          },
        }}
      >
        {options.map((option) =>
          option.link ? (
            <MenuItem
              key={option.link + option.label}
              value=""
              // @ts-ignore
              component={Link}
              onClick={handleClose}
              to={option.link}
              style={{ color: "inherit" }}
              target={option.target}
            >
              {option.label}
            </MenuItem>
          ) : (
            <MenuItem
              key={option.label}
              value=""
              onClick={() => {
                option.onClick!();
                handleClose();
              }}
              style={{ color: "inherit" }}
            >
              {option.label}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
};
export default MuiDropdownButton;


import * as React from 'react';

import Pin from '../NewCustomerWizard/images/map-pin.png';

// ({ data, onSubmit }: any)
const SitesForm = (values: any, push: any, pop: any, form: any, thisX: any) => {
  const deleteItem = (index: number) => {
    let update: any[] = [...values.sites];
    update.splice(index, 1);
    form.change('users', update)
  }


  return <>

    <div className="wizard3__right-heading-div">
      <p className="wizard3__right-heading">Sites</p>
    </div>

    <div className="wizard3__permissions">
      <p className="">Sites represent the different locations that the company works from. Each VoIP Service that you add will require it’s own site.</p>
    </div>

    <div className="wizard5-main">

      <div className="row">
        {values.sites.map((site: any, index: number) => {
          return <div key={index} className="wizard5-info">
            <p className="wizard5__info-heading">{site.description}</p>
            <div className="wizard5__info-data">
              <p>{site.line1}</p>
              <p>{site.line2}</p>
              <p>{site.city}</p>
              <p>{site.county}</p>
              <p>{site.postcode}</p>
            </div>
              <button style={{margin:'0.25em'}} className="button__skip button__sites" onClick={(e: any) => {
                e.preventDefault();
                thisX.site.setVisible(true, values, { ...site, index: index });
              }}>Edit</button>
              {values.sites.length > 1 && <button style={{margin:'0.25em'}} className="button__skip button__sites" onClick={(e: any) => {
                e.preventDefault();
                deleteItem(index);
              }}>Remove</button>}
          </div>

        })}
        <div className="wizard5-info wizard5-info">
          <div style={{width:'100%',textAlign:'center'}}>
          <img className=""
            src={Pin}
            alt={"pin"}
            width="47px"
            height="90px" />
          </div>
          <br />
          <button onClick={(e) => {
            e.preventDefault();
            thisX.site.setVisible(true);
          }} className="button__skip button__sites">Add Another Site</button>
        </div>
      </div>
    </div>
  </>
}


export default SitesForm
import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../compiledSVG/undraw_people_search_wctu.svg';

const Error = () => {
   
    return <Row>
        <Col>
            <div className="text-center"><img src={image} className="img-fluid" alt="No Results Found"/><br /><br />
            <h2>Ooops something went wrong.</h2><p> Try again or contact support.</p></div>

        </Col>
    </Row>



};



export default Error;

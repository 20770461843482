import * as React from 'react';
import PageHead from '../components/PageHead';

export default class Privacy extends React.Component <any, any> {
  public render(){
    return (
        <>
          <PageHead resellerID={this.props.resellerID}  title="Privacy Policy" parents={[]}></PageHead>
     
          <p> todo</p>
        </>
    );
  }
}

import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";

import Loading from "../../components/loading";

import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";



import PageHead from "../../components/PageHead";
import Error from "../../components/error";

import {
  ITariff,
  IWholesaleTariffBand,
  ICustomer,
} from "../../interfaces/customer";
import TariffForm from "./TariffForm";
import App from "../../App";
import { RouteComponentProps } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

const set = new MySettings();

export interface ITaiffState {
  loading: boolean;
  tariff: ITariff;
  error: boolean;
  customers: ICustomer[];
  bands: IWholesaleTariffBand[];
}
const blankTariff: ITariff = {
  id: "",
  description: "",
  billingType: "",
  name: "",
  resellersId: "",
  overrideIncomingDefault: false,
  incomingSalesPrice: 0,
  incomingSalesPriceMobile: 0,
  freeMinutes: 0,
  bands: [],
};

interface ITariffPage extends RouteComponentProps<any>, IPageProps { }
const Tariff = ({  match, history }: ITariffPage) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tariff, setTariff] = useState<ITariff>()
  const [customers, setCustomers] = useState<ICustomer[]>();
  const [bands, setBands] = useState<IWholesaleTariffBand[]>();
  const mounted = useRef(false);


  useEffect(() => {
    const Go = async () => {
      try {
        const requestedID = match.params.id;
        // console.log(this.state);
        if (requestedID === "new") {

          const response = await set.resellerFullRetailTariff()
          setBands(response.items)
          setCustomers([])
          setTariff(blankTariff)
        } else {
          const data = await set.resellerTariffGet(requestedID)

          const response = await set.resellerFullRetailTariff()


          setTariff(data.item)
          setCustomers(data.customers)
          setBands((await response).items)
          setLoading(false)
        }

      } catch (exception) {
        setError(true)
        //     catchME(this, exception, true);
      }
    }


    if (mounted.current === false) {
      mounted.current = true;
      Go();
    }
  }, [match])

  const handlePost = async (data: ITariff) => {
    setLoading(true)
    if (data.id === "") {

      const res = await set
        .resellerTariffAdd(data)

      const notification: INotification = {
        title: "Success",
        data: "Tariff " + res.name + " created.",
        variant: NOTIFICATION_TYPE.info,
        srcFunction: "componentDidUpdate",
        srcData: window.location.pathname,
        srcComponent: "App",
      };
      App.newNotification(notification, true);
      setLoading(false)
      setTariff(res)
      if (history) {
        history.push(`../tariffs`);
      }

    } else {

      const res = await set
        .resellerTariffUpdate(data)

      //  this._isMounted && this.setState({ loading: false, tariff: res });
      setLoading(false)
      setTariff(res)
      const notification: INotification = {
        title: "Success",
        data: "Tariff " + res.name + " Updated",
        variant: NOTIFICATION_TYPE.info,
        srcFunction: "componentDidUpdate",
        srcData: window.location.pathname,
        srcComponent: "App",
      };
      App.newNotification(notification, true);
      if (history) {
        history.push(`../tariffs`);
      }

    }
  }

  const name = (tariff ? tariff.name : '')


  return <div className="content">
    <PageHead
      title={name}
      parents={[{ title: "Tariffs", url: "../tariffs" }]}
    ></PageHead>

    {loading === true ? (
      <Loading />
    ) : (
      <>
        {error === true ? (
          <Error />
        ) : (
          <>
            {customers && tariff && bands && <TariffForm
              customers={customers}
              data={tariff}
              bands={bands}
              onSubmit={handlePost}
            />}
          </>
        )}
      </>
    )}
  </div>
}


export default Tariff
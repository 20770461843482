import React, { useEffect } from "react";

import { RouteComponentProps } from "react-router-dom";
import Loading from "../components/loading";
import mfaContext from "../context/mfa";
import { IPageProps } from "../interfaces/page-props";
import MySettings from "../static/settings";
interface ILogoutProps extends RouteComponentProps<any>, IPageProps {
  reloadMenu(): Promise<boolean>;
}

const set = new MySettings();
let done = false;
const Logout: React.FC<ILogoutProps> = (props) => {
  useEffect(() => {
    const postLogout = async () => {
      let redirect=''
      try {
        const url = set.getLogout();
        const response=await set.doAPIRequest<any>(url, {});
        if(response && response.redirect){
          redirect=response.redirect
        }
      } catch (error) {

      }
      try {
        await props.reloadMenu(); //  trigger reload in Navigation
      } catch (err) {

      }
      mfaContext.loggedIn = false;
      setTimeout(() => {
        done = false;
        if(redirect){
          window.location.href=redirect
        }else{
          props.history.push("/login");
        }
      }, 250)
    };

    if (done === true) {

    } else {
      done = true;
      postLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default Logout;

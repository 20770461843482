import * as React from "react";
import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";

import { Row, Col, ButtonGroup } from "react-bootstrap";
import { IAccessGroup } from "../../interfaces/access-group";

const updateState =
  <T extends string>(key: keyof IAccessGroup, value: T) =>
  (prevState: IAccessGroup): IAccessGroup => ({
    ...prevState,
    [key]: value,
  });

export interface IPermissisonFormProp {
  data: IAccessGroup;
  submitFunction: (data: IAccessGroup) => void;
}

export default class PermissisonForm extends React.Component<
  IPermissisonFormProp,
  IAccessGroup
> {
  constructor(props: any) {
    super(props);

    this.state = props.data;
    //  this.state.enabled = true;
  }

  public handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    this.props.submitFunction(this.state);
    // return this.postLogin();
  };
  public handleChange = (event: any) => {
    this.setState(updateState(event.target.id.toString(), event.target.value));
  };
  public getData = (): IAccessGroup => {
    return this.state;
  };
  public render() {
    const {
      //   id,
      name,
    } = this.state;
    // console.log(notifications);
    return (
      <Form onSubmit={this.handleSubmit} className="searchForm">
        <Row>
          <Col>
            <Form.Group controlId="name">
              <Form.Control
                type="text"
                placeholder="Access group name"
                value={name}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Col>

          <Col className="text-right">
            <ButtonGroup aria-label="Buttons">
              <Button variant="outline-primary" type="submit">
                Create Access Group
              </Button>
              {this.props.children}
            </ButtonGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

import * as React from "react";

import VipTextFinalField from "../../components/misc/form/VipTextFinalField";
import MySettings from "../../static/settings";
import { Field } from "react-final-form";
import makeId from "../../components/misc/form/makeId";

import { Button, CircularProgress } from "@material-ui/core";
const validateMe: boolean = true;

const set = new MySettings();


interface IProp {
  name: any;
  debounce: number;
  validate: any;
}

class DebouncingValidatingField extends React.Component<IProp, any> {
  clearTimeout: any;

  validate = (...arg: any) =>
    new Promise((resolve: any) => {
      if (arg.length > 0 && (arg[0] == null || arg[0].length === 0)) {
        resolve('Please enter a company name.')
        return
      }
      if (this.clearTimeout) {
        this.clearTimeout();
      }
      const timerId = setTimeout(() => {
        resolve(this.props.validate(...arg));
      }, this.props.debounce);
      this.clearTimeout = () => {
        clearTimeout(timerId);
        resolve();
      };
    });
  render() {
    return (
      <Field type="text" {...this.props} validate={this.validate}>
        {({ input, meta }) => (
          <div>
            <input
              {...input}
              id={input.name.replace(".", "_")}
              className="form-control"
            />
            {meta.error && meta.touched && <span style={{ color: "red" }}>{meta.error}</span>}
          </div>
        )}
      </Field>
    );
  }
}

const usernameAvailable = async (value: string) => {
  if (!value || value.length === 0) {
    return "Please enter a company name.";
  }

  const lowerCaseNames = await (
    await set.resellerCustomerSearch(value,false)
  ).items.map((ccc: any) => {
    return ccc.item.name.toLowerCase();
  });

  if (lowerCaseNames.indexOf(value.toLowerCase()) >= 0) {
    return "Customer name already exists.";
  }
};

const CompanyDetailsForm = (values: any, push: any, pop: any, form: any) => {
  return (
    <>
      <div className="wizard2__right-heading-div">
        <p className="wizard2__right-heading">Company Details</p>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">Company Name - If this is a residential customer please use their full name.</label>
          <DebouncingValidatingField
            debounce={500}
            name="companyName"
            validate={usernameAvailable}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">Salutation or Title (Mr/Miss etc)</label>
          <VipTextFinalField
            fieldName="title"
            placeholder=""
            required={false}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">First Name</label>
          <VipTextFinalField
            fieldName="firstName"
            placeholder=""
            required={validateMe}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">Last Name</label>
          <VipTextFinalField
            fieldName="lastName"
            placeholder=""
            required={validateMe}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">
            Site Name (E.g. Main or Second office)
          </label>
          <VipTextFinalField
            fieldName="description"
            placeholder=""
            required={validateMe}
          ></VipTextFinalField>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">Postcode</label>

          <VipTextFinalField
            fieldName="postCode"
            placeholder=""
            required={validateMe}
          ></VipTextFinalField>
        </div>
        {values.looking_lookup === true ? (<CircularProgress style={{marginTop:'1.75em'}} color="primary" />) : (<button
          onClick={(e) => {
            e.preventDefault();
            console.log(values);
            if (!values.postCode || values.postCode === "") {
              alert("Please enter a post code.");
            } else {
              form.change("looking_lookup", true);
              setTimeout(() => {
                set
                  .postCodeLookUp(values.postCode)
                  .then((res) => {
                    console.log(res);
                    form.change("looking_lookup", false);
                    form.change("lookup", res);
                  })
                  .catch((err) => {
                    form.change("manualAddress", true);
                    form.change("looking_lookup", false);
                    alert("Post code look up failed. Please enter the address manually.");
                  });
              }, 2000);
            }
          }}
          className="find-address"
          type="button"
        >
          Find Address
        </button>)}
      </div>

      {values.lookup !== undefined && (
        <>
          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">Choose Address</label>
              <Field component="select" name="lookupIndex">
                {({ input, meta, inputOnChange }) => (
                  <>
                    <select
                      {...input}
                      id={input.name.replace(".", "_")}
                      className="form-control"
                      autoComplete={"autocomplete_off_hack_" + makeId(5)}
                      onChange={(evt: any) => {
                        const val = evt.currentTarget.value;

                        const chosen: any = values.lookup.items[val];

                        console.log("selAddress", val, chosen);

                        input.onChange(evt);
                        inputOnChange && inputOnChange(evt);
                        if (chosen === undefined) {
                          form.change("line1", "");
                          form.change("line2", "");
                          form.change("county", "");
                          form.change("city", "");
                        } else {
                          form.change("line1", chosen.line1);
                          form.change("line2", chosen.line2);
                          form.change("county", chosen.county);
                          form.change("city", chosen.city);
                        }
                      }}
                    >
                      <option></option>
                      {values.lookup.items.map((map: any, index: number) => {
                        return (
                          <option key={index} value={index}>
                            {map.line1}, {map.line2}, {map.city}
                          </option>
                        );
                      })}
                    </select>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </>
                )}
              </Field>
            </div>
          </div>
        </>
      )}

      {values.manualAddress !== true && (
        <>
          <div style={{marginLeft:'auto',marginRight:'auto'}}>
              <Button variant="outlined" onClick={(e) => {
                e.preventDefault();
                form.change("manualAddress", !values.manualAddress);
              }}>Enter Address Manually</Button>
          </div>
        </>
      )}

      {values.manualAddress === true && (
        <>
          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">Address line 1</label>

              <VipTextFinalField
                fieldName="line1"
                placeholder=""
                required={validateMe}
              ></VipTextFinalField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">Address line 2</label>
              <VipTextFinalField
                fieldName="line2"
                placeholder=""
                required={false}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">City</label>
              <VipTextFinalField
                fieldName="city"
                placeholder=""
                required={validateMe}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label className="form-label">County</label>
              <VipTextFinalField
                fieldName="county"
                placeholder=""
                required={false}
              />
            </div>
          </div>
        </>
      )}

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">Phone Number</label>

          <VipTextFinalField
            fieldName="companyPhone"
            placeholder=""
            required={validateMe}
          ></VipTextFinalField>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">Email Address</label>

          <VipTextFinalField
            fieldName="companyEmail"
            placeholder=""
            required={validateMe}
          ></VipTextFinalField>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <label className="form-label">Website</label>

          <VipTextFinalField
            fieldName="companyWebsite"
            placeholder=""
            required={false}
          ></VipTextFinalField>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailsForm;

import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col, Card } from "react-bootstrap";
import { IExtension } from "../../interfaces/extension";
import { IVoiceRouting } from "../../interfaces/voiceroutings";

export interface IVoiceRoutingProps {
  data: {
    report: IVoiceRouting;
    extensions: IExtension[];
  };
  handleSubmit: () => void;
  reportUpdateHandler: (field: string, value: any) => void;
}

const FormVoiceRouting: React.FC<IVoiceRoutingProps> = (props) => {
  const handleChange = (event: any) => {
    props.reportUpdateHandler(event.target.id.toString(), event.target.value);
  };

  return (
    <Form onSubmit={props.handleSubmit}>
      <Card>
        <Card.Header>
          <h3 className="my-auto">Routing Information</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={12}>
              <Form.Group controlId="text">
                <Form.Label>Text</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Text"
                  value={props.data.report.text}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="destination">
                <Form.Label>Destination</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Destination"
                  value={props.data.report.destination}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="text-center">
          <Button variant="primary" type="submit">
            Save Routing
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default FormVoiceRouting;

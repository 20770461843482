import { TextField } from "@material-ui/core";
import { memo, useState } from "react";

interface iTextFieldWithState {
    type: 'text' | 'email' | 'number'
    data: any;
    required:boolean
    field: string;
    placeholder?:string
    setField: (field: string, value: any) => void;
  }
  const TextFieldWithStateBase = ({ type,required,  data, field, setField, placeholder='' }: iTextFieldWithState) => {
    const [text, setText] = useState(() => { return data })
  
    const handleChange = (event: any) => {
      setText(event.target.value)
      setField(field, event.target.value)
    }
  
    return <TextField
      required={required}
      fullWidth
      type={type}
      variant="outlined"
      id={field}
      value={text}
      placeholder={placeholder}
      onChange={handleChange} />
  }
  const TextFieldWithState = memo(TextFieldWithStateBase);
  export default TextFieldWithState;
import React, { useState, useEffect } from "react";
import Select from "react-select";
import MySettings from "../static/settings";

const set = new MySettings();

type option = {
  value: {
    id: string;
  };
  label: string;
};

const GetDeskPhoneOptions = ({
  input,
  customersSitesId,
  associatedOnly,
  defaultV,
  ...rest
}: any) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<option[]>([]);
  const [selectedOption, setSelectedOption] = useState<option | null>(null);

  // console.log("state GetExtensionOptions", data);
  // console.log("props GetExtensionOptions", input);
  // console.log("selectedOption", selectedOption);

  useEffect(() => {
    const getExtensions = async () => {
      try {
        const result = await set.provisionableDevicesGetForCustomerSite(
          customersSitesId,
          associatedOnly ? associatedOnly : false
        );
        // console.log("data GetDeskPhoneOptions", result);
        const reshapedResult = result.items.map(
          (item: { id: string; name: string }) => ({
            value: {
              id: item.id,
            },
            label: item.name,
          })
        );

        reshapedResult.unshift({
          value: {
            id: "",
          },
          label: "",
        });

        reshapedResult.sort(compare);

        setData(reshapedResult);

        const selected = reshapedResult.find(
          (item: { value: { id: string } }) => item.value.id === defaultV
        );
        if (selected) {
          setSelectedOption(selected);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getExtensions();
  }, [associatedOnly, customersSitesId, defaultV]);

  const compare = (a: option, b: option) => {
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
  };

  const handleChange = (selectedOption: option) => {
    input.onChange(selectedOption.value.id);
    setSelectedOption(selectedOption);
  };

  return (
    <Select
      options={data}
      {...input}
      {...rest}
      searchable
      value={selectedOption}
      onChange={handleChange}
      isLoading={loading}
      styles={{ option: (styles) => ({ minHeight: 40, ...styles }) }}
    />
  );
};

export default GetDeskPhoneOptions;

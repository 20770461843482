import * as React from "react";

import { IEmailSubReportPerDDIPerHour } from "../../interfaces/email-reports";
import { Grid } from "@material-ui/core";
import MuiTitle from "../misc/form/MuiTitle";
import MuiFreeTypeStringList from "../MuiComponents/MuiFreeTypeStringList";


export interface IEmailSubReportPerDDIPerHourProps {
  data: IEmailSubReportPerDDIPerHour;
  submitFunction: (field: string, data: any) => void;
}

const EmailReportPerDDIPerHour:React.FunctionComponent<IEmailSubReportPerDDIPerHourProps> = (props:IEmailSubReportPerDDIPerHourProps) => {

  return <Grid container>
    <Grid item xs={6}>
        <MuiTitle title="DDIs To Include (leave empty to include all)" />

        <MuiFreeTypeStringList
          field="perDDIPerHourDDIsToInclude"
          value={props.data.perDDIPerHourDDIsToInclude}
          updateField={props.submitFunction}
          text="DDIs"
          placeholder=""
        />
      </Grid>
  </Grid>
}
export default EmailReportPerDDIPerHour

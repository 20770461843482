import * as React from 'react';
import Form from 'react-bootstrap/Form'
import Loading from './loading'
import { Button, Col, Row } from 'react-bootstrap'
import MySettings from '../static/settings'

export interface IAddAreaCodeRouteResponse{
    success:boolean
}

export interface IAddAreaCodeRouteProps{
    customersSitesServicesId:string,
    onAdded:()=>void
}
export interface IAddAreaCodeRouteState{
    prefix:string,
    destination:string,
    adding:boolean
}

  export class AddAreaCodeRoute extends React.Component<IAddAreaCodeRouteProps,IAddAreaCodeRouteState> {
    constructor(props:any){
      super(props)
      this.state={
        prefix:'',
        destination:'',
        adding:false
      }
      this.handleChange=this.handleChange.bind(this)
      this.handleSubmit=this.handleSubmit.bind(this)
    }
  
    public render(){
      return (
        <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col>
         
            <Form.Group controlId="prefix">
              <Form.Control type="text" placeholder="Area Code" name="prefix" onChange={this.handleChange} />
            </Form.Group>
            </Col>

          <Col>
         
            <Form.Group controlId="destination">
              <Form.Control type="text" placeholder="Destination" name="destination" onChange={this.handleChange} />
            </Form.Group>

            </Col>

          <Col className="text-right">
         
            {this.state.adding ? (
              <Loading />
            ):
            (<Button variant="primary" type="submit">
              Save New Route
            </Button>)}
         
          </Col>
        </Row>

        </Form>
        )
    }
  
    private handleChange(event: any){
      let newState : {[key: string]: string} = {}
      newState[event.target.name]=event.target.value
      this.setState(newState as unknown as IAddAreaCodeRouteState)
    }
  
    private handleSubmit(event:React.FormEvent){
      event.preventDefault()
      this.setState({
        adding:true
      })
      // send off our new route
      const settings=new MySettings()
      const url = settings.getAreaCodeRoutingsAdd()

      settings.doAPIRequest<IAddAreaCodeRouteResponse>(url, {
        customersSitesServicesId:this.props.customersSitesServicesId,
        destination:this.state.destination,
        prefix:this.state.prefix
    })
     
        .then((data: IAddAreaCodeRouteResponse) => {
          if(data.success){
            this.props.onAdded()
          }
          this.setState({
              adding:false
          })
        })
        .catch((error) => {
          console.log("fetchError", error);
          //  this.setState({loggedIn:false});
          this.setState({
              adding:false
          })
        });

    }
  }
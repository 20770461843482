import React from "react";

import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Field } from "react-final-form";

interface ICheckboxProps {
  name: string;
  label?: string;
  required?: boolean;
  style?: any;
}

const MuiCheckbox: React.FC<ICheckboxProps> = ({
  name,
  label,
  required = false,
  style = {},
}) => {
  return (
    <Field name={name} type="checkbox">
      {({ input }) => (
        <FormControlLabel
          style={style}
          control={<Checkbox {...input} color="primary" required={required} />}
          label={label}
        />
      )}
    </Field>
  );
};
export default MuiCheckbox;

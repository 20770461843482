
import * as React from 'react';

import { Table, Dropdown } from 'react-bootstrap';
// ({ data, onSubmit }: any)
import Emty from '../NewCustomerWizard/images/empty-state.png';

const csvOn: boolean = false;

const UsersForm = (values: any, push: any, pop: any, form: any, thisX: any) => {

  const deleteItem = (index: number) => {
    let update: any[] = [...values.users];
    update.splice(index, 1);
    form.change('users', update)
  }

  

  return <>

    <div className="wizard3__right-heading-div">
      <p className="wizard3__right-heading">Users</p>

    </div>

    <div className="wizard3__permissions">

      <p className="">Create accounts for each of the customer's users. {csvOn && <> If you have lots of users to add you can save time by uploading them as a CSV. Download Template</>}</p>

    </div>
    {values.users.length === 0 ? (<>
      <div className="wizard5-main">

        <div className="wizard6__offer-post">

          <img className="wizard6__offer-post-img" src={Emty} alt={"welcome"} width="224px" height="212px" />

        </div>

      </div></>) : (<div>
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Site</th>
              <th>Email Address</th>
              <th>User Type</th>
              <th>Portal Access</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {values.users.map((data: any, index: number) => {
              let permissionsName='';
              if(values.permissions[data.permissionIndex]){
                permissionsName=values.permissions[data.permissionIndex].name;
              }
              let siteDescription='Not at Site'
              if(values.sites.length>data.siteIndex && data.siteIndex>=0){
                siteDescription=values.sites[data.siteIndex].description
              }
              return <tr key={index}>
                <td className="userName">{data.salutation} {data.firstName} {data.lastName}</td>
                <td className="userSite">{siteDescription}</td>
                <td className="userPhone">{data.emailAddress}</td>
                <td className="userType">{permissionsName}</td>
                <td className="userPortalAccess">{data.portalAccess === true ? (<>Yes</>) : (<>No</>)}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" className="button__options" id="dropdown-basic">

                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {values.users.length > 1 &&
                        <Dropdown.Item as='button' onClick={(e: any) => {
                          e.preventDefault();
                          console.log(data);
                          deleteItem(index);
                        }}>Remove</Dropdown.Item>}
                      <Dropdown.Item as='button' onClick={(e: any) => {
                        e.preventDefault();
                        console.log(data);
                        thisX.addUser.setVisible(true, values, { ...data, index: index });
                      }}>Edit</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>

              </tr>
            })}
          </tbody>
        </Table>


      </div>

      )}

    <div className="text-center">


      <div className="wizard6__offer-post-box">
        <button onClick={(e) => {
          e.preventDefault();
          thisX.addUser.setVisible(true, values)
        }} className="button-primary"><span className="wizard6__plus-icon">&nbsp;&nbsp;&nbsp;&nbsp;</span>Add New User</button>

        {csvOn && <>

          <p className="wizard6-box-middle" ></p>
          <button onClick={(e) => {
            e.preventDefault();
            thisX.userUpload.setVisible(true, values)
          }} className="button-primary">CSV Upload</button></>}

      </div>

    </div>

  </>
}


export default UsersForm
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, TextField, Typography } from '@material-ui/core';
import { memo, useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete';

// Simple footer component

// table object properties
export interface IVipProvisioningCustomFieldProps {
  title: string;
  data: any;
  type: "string" | "number" | "boolean";
  dataField: string;
  payload?: any;
  isCustomField: boolean;
  overridden: boolean;
  updateField: (field: any, payload: any) => void;
  toggleOverride: (field: string) => void;
  deleteField: (field: any) => void;
  editField: (field: any) => void;
}

//
export interface IVipProvisioningFieldState {
  something?: string;
  enabeled: boolean;
  object?: any;
  id: string;
  status: string;
  message: string;
}



const VipProvisioningCustomField = ({ type, dataField, title, data, updateField, deleteField }: IVipProvisioningCustomFieldProps) => {
  const [masterValue, setMasterValue] = useState(() => {

    return data.value;
  })

  const handleChange = (event: any) => {
    //console.log(event.target.id, event.target.value);
    setMasterValue(event.target.value)
    updateField(event.target.id, event.target.value);
  }

  const handleChangeToggle = (event: any) => {
    // console.log(event.target.id, event.target.value);
    let value: any = masterValue;
    // console.log(value);

    let setVaue;

    if (value === undefined) {
      setVaue = true;
    } else {
      if (value === true) {
        setVaue = false;
      } else {
        setVaue = true;
      }
    }
    setMasterValue(setVaue)
    updateField(event.target.id, setVaue);
  }
  // 

  return <Box style={{marginTop:'2em'}}>
    <Typography variant="h6" style={{ position: 'relative', marginBottom: 10, marginTop: 10 }}>
      {title}
      <Box style={{ position: 'absolute', right: 0, top: 0 }}>



        <IconButton
          size="small"
          color='secondary'
          onClick={() => {
            deleteField(data)
          }}>
          <DeleteIcon />
        </IconButton>



      </Box>
    </Typography>


    {type === 'string' && <>


      <TextField
        fullWidth
        type="text" placeholder={title} value={masterValue} onChange={handleChange}
        variant="outlined"
        size="small"
        label={title}
      />
    </>}



    {type === 'number' &&
      <TextField
        fullWidth
        type="number" placeholder={title} value={masterValue} onChange={handleChange}
        variant="outlined"
        size="small"
        label={title}
      />}




    {type === 'boolean' && <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            id={dataField} checked={masterValue} onChange={handleChangeToggle}
            name="checkedB"
            color="primary"
          />
        }
        label={masterValue === true ? 'Enabled' : 'Disabled'}
      />
    </FormGroup>}




  </Box>
}
export default memo(VipProvisioningCustomField);

// export default class VipProvisioningCustomField extends React.Component<IVipProvisioningCustomFieldProps, IVipProvisioningFieldState> {


//   constructor(props: any) {
//     super(props);

//     this.toggleIncomingCheckbox.bind(this);
//     this.disableField.bind(this);
//     this.handleLocalChange.bind(this);
//     this.handleChange.bind(this);
//     this.handleChangeToggle.bind(this);
//     this.updateCustomStatus.bind(this);
//     this.updateCustomStatus.bind(this);



//     let ena = false;
//     let value: any;

//     if (this.props.isCustomField === true) {
//       //console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSs", this.props)
//       ena = true;
//       value = this.props.data.value;
//     } else {
//       value = this.props.data[this.props.dataField];
//       if (value === undefined) { } else {
//         ena = true;
//       }
//     }





//     this.state = {
//       enabeled: ena,
//       id: '',
//       status: '',
//       message: ''
//     }
//   }

//   private uuidv4 = () => {
//     return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
//   }

//   private toggleIncomingCheckbox = (event: React.SyntheticEvent) => {
//     if (this.state.enabeled === true) {
//       this.setState({ enabeled: false });
//     } else {
//       this.setState({ enabeled: true });
//     }
//   }

//   public handleLocalChange = (event: any) => {
//     //  console.log(event.target.id, event.target.value);
//     this.setState(updateState(event.target.id.toString(), event.target.value));

//   }
//   public handleChange = (event: any) => {
//     //console.log(event.target.id, event.target.value);
//     this.props.updateField(event.target.id, event.target.value);
//   }
//   public handleChangeToggle = (event: any) => {
//     // console.log(event.target.id, event.target.value);
//     let value: any = this.props.data[this.props.dataField];
//     // console.log(value);

//     let setVaue;

//     if (value === undefined) {
//       setVaue = true;
//     } else {
//       if (value === true) {
//         setVaue = false;
//       } else {
//         setVaue = true;
//       }
//     }
//     this.props.updateField(event.target.id, setVaue);
//   }

//   public updateCheckBoxList = (dd: any) => {
//     //  console.log(dd);
//   }
//   public updateCustomStatus = () => {

//     let status: any = { message: '', status: '' };
//     // console.log(status, this.state);
//     let masterValue: any = this.props.data[this.props.dataField];
//     let objValue;
//     if (masterValue === undefined) {
//       objValue = [];
//     } else {
//       objValue = JSON.parse(masterValue);
//     }

//     status.status = this.state.status;
//     status.message = this.state.message;

//     this.setState({ status: '', message: '', id: '' })
//     if (status.id) {
//       //update code
//     } else {
//       status.id = this.uuidv4();
//       objValue.push(status);
//     }



//     const textValue = JSON.stringify(objValue);
//     this.props.updateField(this.props.dataField, textValue);
//   }


//   public deleteCustomStatus = (uuid: string) => {
//     // console.log(uuid);
//     let masterValue: any = this.props.data[this.props.dataField];
//     let objValue: any[];
//     if (masterValue === undefined) {
//       objValue = [];
//     } else {
//       objValue = JSON.parse(masterValue);
//     }

//     let xx = objValue.findIndex(dd => dd.id = uuid);
//     //   console.log(xx);
//     objValue.splice(xx, 1);
//     const textValue = JSON.stringify(objValue);
//     this.props.updateField(this.props.dataField, textValue);
//   }

//   public render() {

//     let masterValue: any;
//     if (this.props.isCustomField === true) {
//       masterValue = this.props.data.value;
//     } else {
//       masterValue = this.props.data[this.props.dataField];
//     }





//     if (this.props.overridden === false) {
//       return (<Form.Group as={Row} controlId={this.props.dataField}>
//         <Form.Label column sm={2}>{this.props.title}</Form.Label>
//         <Col sm={10} className="text-right">
//           <ButtonGroup>

//             {this.props.children}
//             <Button onClick={this.disableField} variant="outline-primary">Enable Setting</Button>
//           </ButtonGroup>
//         </Col>
//       </Form.Group>)
//     } else {
//       // datavalue not unset display thing  
//       //  console.log(this.props.type);


//       const grp = (<InputGroup.Append>
//         {this.props.children}
//         <Button onClick={(event: any) => {
//           event.preventDefault();
//           this.disableField()
//         }} variant="outline-warning">Disable</Button>
//       </InputGroup.Append>)


//       switch (this.props.type) {


//         case 'string':

//           return (<Form.Group as={Row} controlId={this.props.dataField}>
//             <Form.Label column sm={2}>{this.props.title}</Form.Label>
//             <Col sm={10}>
//               <InputGroup>

//                 <Form.Control type="text" placeholder={this.props.title} value={masterValue} onChange={this.handleChange} />
//                 {grp}
//               </InputGroup>
//             </Col>

//           </Form.Group>);
//         case 'number':

//           return (<Form.Group as={Row} controlId={this.props.dataField}>
//             <Form.Label column sm={2}>{this.props.title}</Form.Label>
//             <Col sm={10}>
//               <InputGroup>

//                 <Form.Control type="number" placeholder={this.props.title} value={masterValue} onChange={this.handleChange} />
//                 {grp}
//               </InputGroup>
//             </Col>

//           </Form.Group>);
//         case 'boolean':

//           return (<Form.Group as={Row} controlId={this.props.dataField}>
//             <Form.Label column sm={2}>{this.props.title}</Form.Label>

//             <Col sm={10}>
//               <InputGroup>
//                 <InputGroup.Prepend className="flex-grow-1">
//                   <InputGroup.Checkbox id={this.props.dataField} checked={masterValue} onChange={this.handleChangeToggle} />
//                   <InputGroup.Text className="flex-grow-1">
//                     {masterValue === true ? (<>Enabled</>) : (<>Disabled</>)}
//                   </InputGroup.Text>
//                 </InputGroup.Prepend>
//                 {grp}
//               </InputGroup>
//             </Col>
//           </Form.Group>);



//         default:
//           return (<h1>Unspecified data type, please contact support</h1>);

//       }
//     }



//   }

// }


import * as React from "react";
import PageHead from "../../components/PageHead";
import { Col } from "react-bootstrap";
import MySettings from "../../static/settings";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";

import { IAccessGroup } from "../../interfaces/access-group";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import {
  INumber,
  INumberTracker,
  INumbersHttpResponse,
} from "../../interfaces/number";
import catchME from "../../catchME";
import FormNumberTracker from "../../components/oldForms/form-number-tracker";
import App from "../../App";
library.add(faChevronDown, faChevronUp);
const set = new MySettings();

export interface INumberTrackerPageState {
  loading: boolean;
  tracker: INumberTracker;
  accordian: string;
  group?: IAccessGroup;
  customers: string[];
  numbers: string[];
  presetCustomer: string;
  numberObjects: INumber[];
}

export default class NumberTrackerPage extends React.Component<
  IPageProps & any,
  INumberTrackerPageState
> {
  _isMounted: boolean = true;

  constructor(props: any) {
    super(props);

    this.state = {
      tracker: {
        owner: "",
        area: "",
        notes: "",
        numbersName: "",
        uid: "",
        yhc: "",
        numbersId: "",
      },
      loading: true,
      accordian: "",
      customers: [],
      numberObjects: [],
      numbers: [],
      presetCustomer: "",
    };

    // this.subSet.bind(this);
    this.save.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  public componentDidMount = () => {
    this._isMounted = true;
    const requestedID = this.props.match.params.id;

    this.getNumberTrackers(requestedID);
  };

  // get numbers list
  public getNumberTrackers = (id: string): void => {
    console.log(id);
    this._isMounted && this.setState({ loading: true });

    this._isMounted &&
      set
        .numberSearch("", "", this.props.resellerID)
        .then((numberD: INumbersHttpResponse) => {
          let customerList: string[] = [];
          let numbersList: string[] = [];
          let numberObjectList: INumber[] = [];
          numberD.numbers.map((tracker: INumber) => {
            if (numbersList.indexOf(tracker.name) === -1) {
              numbersList.push(tracker.name);
            }
            numberObjectList.push(tracker);
            //  return tracker.owner;

            if (customerList.indexOf(tracker.ownerId) === -1) {
              customerList.push(tracker.ownerId);
            }
            //      numberObjectList.push(tracker);

            return false;
          });
          //  const presetCustomer ='';
          console.log(this.props);

          if (id === "new") {
            let tracker = {
              owner: "",
              area: "",
              notes: "",
              numbersName: "",
              uid: "",
              yhc: "",
              numbersId: "",
            };

            if (
              this.props.session.userType === "resellersContacts" &&
              this.props.resellerID !== undefined
            ) {
              tracker.owner = this.props.resellerID;
            }

            this._isMounted &&
              this.setState({
                loading: false,
                tracker: tracker,
                customers: customerList,
                numbers: numbersList,
                numberObjects: numberObjectList,
              });
          } else {
            this._isMounted &&
              set
                .numberTrackerGet(id)
                .then((tracker: INumberTracker) => {
                  this._isMounted &&
                    this.setState({
                      loading: false,
                      tracker: tracker,
                      customers: customerList,
                      numbers: numbersList,
                      numberObjects: numberObjectList,
                    });
                })
                .catch((exception) => {
                  catchME(this, exception, false);
                });
          }
        })
        .catch((exception) => {
          console.log("numbers error");
          catchME(this, exception, false);
        });
  };

  public save = (data: INumberTracker) => {
    //save
    // console.log(data);

    let numberIndex = this.state.numberObjects.findIndex(
      (ee) => ee.name === data.numbersName
    );
    const numberID = this.state.numberObjects[numberIndex].id;

    data.numbersId = numberID;

    let customersArr = [...this.state.customers];
    const customerArrayIndex = this.state.customers.indexOf(data.owner);
    if (customerArrayIndex >= 0) {
      customersArr.push(data.owner);
      this.setState({ loading: true, customers: customersArr });
    } else {
      this.setState({ loading: true });
    }

    if (data.id) {
      this._isMounted &&
        set
          .numberTrackerUpdate(data)
          .then((resp) => {
            const notification: INotification = {
              title: "Success",
              data: "Number tracking updated",
              variant: NOTIFICATION_TYPE.info,
              srcFunction: "componentDidUpdate",
              srcData: window.location.pathname,
              srcComponent: "App",
            };
            App.newNotification(notification, true);
            if (this.props.history) {
              this.props.history.push(`../numberTrackers`);
            }
          })
          .catch((exception) => {
            catchME(this, exception, true);
          });
    } else {
      this._isMounted &&
        set
          .numberTrackerAdd(data)
          .then((resp) => {
            const notification: INotification = {
              title: "Success",
              data: "Number tracker created",
              variant: NOTIFICATION_TYPE.info,
              srcFunction: "componentDidUpdate",
              srcData: window.location.pathname,
              srcComponent: "App",
            };
            App.newNotification(notification, true);
            //  console.log(this.props)
            if (this.props.history) {
              this.props.history.push(`../numberTrackers`);
            }
          })
          .catch((exception) => {
            catchME(this, exception, true);
          });
    }
  };

  public render() {
    const { loading, tracker, numbers, customers } = this.state;

    if (loading === true) {
      return <Loading />;
    } else {
      const title = "Number Tracker";
      return (
        <div className="content">
          <Col md="12" className="text-left">
            <PageHead
              resellerID={this.props.resellerID}
              title={title}
              parents={[{ title: "Number Trackers", url: "../numberTrackers" }]}
            />

            <FormNumberTracker
              session={this.props.session}
              resellerID={this.props.resellerID}
              data={tracker}
              submitFunction={this.save}
              numbers={numbers}
              customers={customers}
            />
          </Col>
        </div>
      );
    }
  }
}

import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { ICustomerAllowDialCode } from "../../interfaces/customer";

export interface ICustomerAllowDialCodeFormProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent) => Promise<void>;
  dialCode: ICustomerAllowDialCode;
}

const CustomerAllowDialCodeForm: React.FC<ICustomerAllowDialCodeFormProps> = ({
  dialCode,
  handleChange,
  onSubmit,
}) => {
  // console.log("FORM PROPS", props);
  return (
    <Form onSubmit={onSubmit}>
      <Card>
        <Card.Header>
          <h2 className="my-auto">Allow Dial Code</h2>
        </Card.Header>

        <Card.Body className="form-card-body">
          <div>
            Dial code without leading 00 or +, for example 380 will unblock all
            Ukrainian numbers.
          </div>

          <Form.Group controlId="dialCode">
            <Form.Label>Dial Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Dial code"
              value={dialCode.dialCode}
              onChange={handleChange}
            />
          </Form.Group>
        </Card.Body>
        <Card.Footer className="text-center">
          <Button variant="primary" type="submit" className="noleft">
            Save
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default CustomerAllowDialCodeForm;

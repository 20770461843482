import * as React from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { IChromePluginAuthCodesResponse } from "../static/settings";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import Loading from "../components/loading";
import { IPageProps } from "../interfaces/page-props";

const updateState =
  <T extends string>(key: keyof IChromePluginAuthPageState, value: T) =>
  (prevState: IChromePluginAuthPageState): IChromePluginAuthPageState => ({
    ...prevState,
    [key]: value,
  });

interface IChromePluginAuthPageProps
  extends RouteComponentProps<any>,
    IPageProps {
  /* other props for ChildComponent */
  // reloadMenu() :Promise<boolean> ;
  // menuOptions: IMenuOption[];
}
export interface IChromePluginAuthPageState {
  loading: boolean;
  username: string;
  password: string;
  showAuthCodes: boolean;
  authCodes: IChromePluginAuthCodesResponse;
}

export default class ChromePluginAuthPage extends React.Component<
  IChromePluginAuthPageProps,
  IChromePluginAuthPageState
> {
  public state: IChromePluginAuthPageState = {
    loading: false,
    username: "",
    password: "",
    showAuthCodes: false,
    authCodes: {
      userid: "",
      secret: "",
    },
  };

  constructor(props: any) {
    super(props);
    // this.state.alerts = [];
    this.state = { ...props };
  }

  public handleChange = (event: any) => {
    this.setState(updateState(event.target.id.toString(), event.target.value));
  };

  // PBB this should disable the button and display some kind of progress spinner
  // RK; I also need to make this do some form validation because
  // i just tested empty user empty pass and yeh need to stop that.
  public handleSubmit = (event: React.FormEvent) => {
    this.setState({ loading: true });
    event.preventDefault();
    this.postLogin();
  };

  public render() {
    return (
      <>
      <Helmet>
      <title>{this.props && this.props.branding && this.props.branding.name} Login</title>
      </Helmet>
      <div className="content test-Login">


        {this.state.loading === true ? (
          <Loading />
        ) : (
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              {this.state.showAuthCodes ? (
                <fieldset>
                  <legend>
                    Your auth codes are below. Please copy them in to the
                    options screen.
                  </legend>
                  <Form.Group>
                    <Form.Label>Auth User ID</Form.Label>
                    <div>{this.state.authCodes.userid}</div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Auth User Secret</Form.Label>
                    <div>{this.state.authCodes.secret}</div>
                  </Form.Group>
                </fieldset>
              ) : (
                <fieldset>
                  <legend>
                    Enter login details below to get your auth codes.
                  </legend>
                  {/*<input type="text" id="username" value={username} onChange={this.handleChange}/>*/}
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="username">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        placeholder="Enter Username"
                        value={this.state.username}
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Button id="submit" type="submit">
                            Submit
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </fieldset>
              )}
            </Col>
          </Row>
        )}
      </div>
    </>);
  }

  public postLogin = () => {
    // Actually send request

    const success = (response: IChromePluginAuthCodesResponse) => {
      this.setState({
        loading: false,
        showAuthCodes: true,
        authCodes: response,
      });
    };

    const fail = () => {
      this.setState({ loading: false }); // so we can resubmit
      alert("Login failed. Please try again.");
    };
    this.setState({ loading: true });
    const loginRequest = {
      username: this.state.username,
      password: this.state.password,
    };
    const set = new MySettings();
    set.getChromePluginAuthCodes(loginRequest).then(success).catch(fail);
  };
}

import { IEmailSubReportAutoAttendantOptions } from "../../interfaces/email-reports";
import { Grid } from "@material-ui/core";
import MuiTitle from "../misc/form/MuiTitle";
import VipTimeFieldMui from "../VipTimeFieldMui";
import { useState } from "react";
import MuiToggleBoolControlled from "../misc/form/MuiToggleBoolControlled";

export interface iEmailReportAttendantOptionsProps {
  data: IEmailSubReportAutoAttendantOptions;
  submitFunction: (field: string, data: any) => void;
}

const EmailReportAttendantOptions = ({ data, submitFunction }: iEmailReportAttendantOptionsProps) => {
  const [state, setState] = useState<IEmailSubReportAutoAttendantOptions>(() => {
    //  console.log(hotDesks)
    const xState = { ...data };
    if (!xState.startTime) {
      xState.startTime = "08:00:00"
      submitFunction("startTime", "08:00:00")
    
    }
    if (!xState.endTime) {
      xState.endTime = "18:00:00"
      submitFunction("endTime", "18:00:00")
    }



    return xState;
  });

  const setField = (field: string, value: any) => {
    console.log("subform", field, value);
    const stateUp: any = { ...state };
    stateUp[field] = value;
    setState(stateUp);
    submitFunction(field, value);
  }

  return <Grid container spacing={2}>
    {/* <Grid item xs={12}>
    {JSON.stringify(data)}

  </Grid> */}
    <Grid item xs={6}>
      <MuiTitle title="Start Time" />
      <VipTimeFieldMui
        field="startTime"
        value={state.startTime ? state.startTime : "08:00:00"}
        updateField={setField}
      />

    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="End Time" />
      <VipTimeFieldMui
        field="endTime"
        value={state.startTime ? state.endTime : "18:00:00"}
        updateField={setField}
      />
    </Grid>
    <Grid item xs={3}>
      <MuiTitle title="Show Individual Calls?" />
      <MuiToggleBoolControlled value={state.aaShowIndividualCalls} setValue={(ev) => {
        setField("aaShowIndividualCalls", !state.aaShowIndividualCalls);
      }} />

    </Grid>

  </Grid>
}


export default EmailReportAttendantOptions;

/*



export default class EmailReportAttendantOptions extends React.Component<
  SomeProps,
  IEmailSubReportAutoAttendantOptions
> {
  constructor(props: any) {
    super(props);
    let joy = { ...props.data };
    this.state = joy;
    this.setField = this.setField.bind(this);

    if (!joy.startTime) {
      this.setField("startTime", "08:00:00");
    }
    if (!joy.endTime) {
      this.setField("endTime", "18:00:00");
      //joy.endTime = '18:00:00';
    }
  }

  public setField(field: string, value: any) {
    console.log("subform", field, value);
    //this.setState(updateState(field, value));
    this.props.submitFunction(field, value);
  }

  public handleChange = (event: any) => {
    this.setField(event.target.id.toString(), event.target.value);
  };

  public render() {
    const { startTime, endTime } = this.props.data;
    // console.log(notifications);

    //const ranges = Object.keys(EmailReportRange).map((key:any) => ({ id: key, name: key }))

    return (
      <>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Start Time</Form.Label>
              <VipTimeField
                field="startTime"
                value={startTime}
                updateField={this.setField}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>End Time</Form.Label>
              <VipTimeField
                field="endTime"
                value={endTime}
                updateField={this.setField}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Checkbox
                  checked={this.props.data.aaShowIndividualCalls}
                  onChange={() =>
                    this.props.submitFunction(
                      "aaShowIndividualCalls",
                      !this.props.data.aaShowIndividualCalls
                    )
                  }
                />
                <InputGroup.Text id="basic-addon1">
                  Show Individual Calls?
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
          </Col>
        </Row>
      </>
    );
  }
}

*/ 
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CircularProgress } from "@material-ui/core";

interface ILoadingProps {
  size?: string;
}

const Loading: React.FC<ILoadingProps> = ({ size = "200px" }) => {
  return (
    <Row>
      <Col className="loaderSpinner">
        <CircularProgress color="primary" />
      </Col>
    </Row>
  );
};
export default Loading;

import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import PageHead from "../../components/PageHead";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";
import {
  IPSAAgreementType,
  IPSABillingCycle,
  IResellerPSAConfiguration,
} from "../../interfaces/reseller-invoice";

const set = new MySettings();

interface RouteParams {}

const ResellerPSAConfigurePage: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = () => {
  const [newsettings, setSettings] = useState<IResellerPSAConfiguration>({
    psaEnabled: false,
    psaConnectwiseCompany: "",
    psaConnectwisePublicKey: "",
    psaConnectwisePrivateKey: "",
    psaConnectwiseCredentialsValidated: false,
    psaConnectwiseNewAgreementsName: "",
    psaConnectwiseNewAgreementsBillingCycleId: "",
    psaConnectwiseNewAgreementsTypeId: "",
  });

  const [loading, setLoading] = useState(true);
  const [psaAgreementTypes, setAgreementTypes] = useState<IPSAAgreementType[]>(
    []
  );
  const [psaBillingCycles, setBillingCycles] = useState<IPSABillingCycle[]>([]);

  useEffect(() => {
    let isMounted = true;

    const getSettings = async () => {
      try {
        const data = await set.resellerPSAConfigureGet();
        let types: IPSAAgreementType[] = [];
        let cycles: IPSABillingCycle[] = [];
        try {
          types = await set.resellerPSAAgreementTypesGet();
          cycles = await set.resellerPSABillingCyclesGet();
        } catch (err) {}
        if (isMounted) {
          setAgreementTypes(types);
          setBillingCycles(cycles);
          setSettings(data);
          setLoading(false);
        }
      } catch (error) {
        App.newNotificationError(
          "Failed to load PSA configuration " + error.data
        );
      }
    };

    getSettings();

    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    try {
      const updatedsettings = await set.resellerPSAConfigureUpdate(newsettings);
      setSettings(updatedsettings);
      const notification: INotification = {
        title: "Configuration Updated",
        data: "The PSA configuration has been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "ResellerPSAConfigurePage",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification);
    } catch (err) {
      App.newNotificationError(
        "Failed to update the PSA configuration " + err.data
      );
    }
    setLoading(false);
  }

  const onEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...newsettings,
      psaEnabled: event.currentTarget.checked,
    });
  };

  const onStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.currentTarget.name;
    let settings: { [index: string]: any } = { ...newsettings };
    settings[fieldName] = event.currentTarget.value;
    let settings2: any = { ...settings };
    setSettings(settings2);
  };

  const billingCycleToOption = function (billingCycle: IPSABillingCycle) {
    return (
      <option key={billingCycle.id} value={billingCycle.id}>
        {billingCycle.name}
      </option>
    );
  };

  const agreementTypeToOption = function (billingCycle: IPSAAgreementType) {
    return (
      <option key={billingCycle.id} value={billingCycle.id}>
        {billingCycle.name}
      </option>
    );
  };

  return (
    <div className="content">
      <PageHead title="PSA Configuration" parents={[]}></PageHead>

      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={onSubmit}>
          <Row className="whiteBox">
            <Col md="12" lg="12">
              <Card>
                <Card.Header>
                  <h2>PSA Configuration</h2>
                </Card.Header>
                <Card.Body className="form-card-body">
                  {newsettings.psaConnectwiseCredentialsValidated ? (
                    <div>Validated.</div>
                  ) : (
                    <div>Not validated.</div>
                  )}
                  <Form.Group controlId="psaEnabled">
                    <Form.Label>Enable PSA:</Form.Label>
                    <Form.Control
                      type="checkbox"
                      checked={newsettings.psaEnabled}
                      onChange={onEnabledChange}
                      name="psaEnabled"
                    />
                  </Form.Group>
                  <Form.Group controlId="psaConnectwiseCompany">
                    <Form.Label>Connectwise Company:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Connectwise company."
                      value={newsettings.psaConnectwiseCompany}
                      onChange={onStringChange}
                      name="psaConnectwiseCompany"
                    />
                  </Form.Group>
                  <Form.Group controlId="psaConnectwisePublicKey">
                    <Form.Label>Connectwise Public Key:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Connectwise public key."
                      value={newsettings.psaConnectwisePublicKey}
                      onChange={onStringChange}
                      name="psaConnectwisePublicKey"
                    />
                  </Form.Group>
                  <Form.Group controlId="psaConnectwisePrivateKey">
                    <Form.Label>Connectwise Private Key:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Connectwise private key."
                      value={newsettings.psaConnectwisePrivateKey}
                      onChange={onStringChange}
                      name="psaConnectwisePrivateKey"
                    />
                  </Form.Group>
                  <Form.Group controlId="psaConnectwisePrivateKey">
                    <Form.Label>Name for new agreements:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name for new agreements"
                      value={newsettings.psaConnectwiseNewAgreementsName}
                      onChange={onStringChange}
                      name="psaConnectwiseNewAgreementsName"
                    />
                  </Form.Group>
                  <Form.Group controlId="psaConnectwiseNewAgreementsBillingCycleId">
                    <Form.Label>Billing cycle for new agreements:</Form.Label>
                    <Form.Control
                      name="psaConnectwiseNewAgreementsBillingCycleId"
                      as="select"
                      value={
                        newsettings.psaConnectwiseNewAgreementsBillingCycleId
                      }
                      onChange={onStringChange}
                    >
                      {psaBillingCycles.map(billingCycleToOption)}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="psaConnectwiseNewAgreementsTypeId">
                    <Form.Label>Type for new agreements:</Form.Label>
                    <Form.Control
                      name="psaConnectwiseNewAgreementsTypeId"
                      as="select"
                      value={newsettings.psaConnectwiseNewAgreementsTypeId}
                      onChange={onStringChange}
                    >
                      {psaAgreementTypes.map(agreementTypeToOption)}
                    </Form.Control>
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Button variant="primary" type="submit">
                    Update Configuration
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};
export default ResellerPSAConfigurePage;

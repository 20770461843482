import React from "react";

import { Snackbar, SnackbarContent, Typography } from "@material-ui/core";

import { CheckCircle, Error } from "@material-ui/icons";

let openSnackbarFn;

class Notifier extends React.Component {
  state = {
    open: false,
    message: "",
    type: "",
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  openSnackbar = ({ message, type }) => {
    this.setState({
      open: true,
      message,
      type,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: "",
      type: "",
    });
  };

  render() {
    let color;

    if (this.state.type === "success") {
      color = "green";
    } else if (this.state.type === "error") {
      color = "red";
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={this.state.open}
        autoHideDuration={4000}
        onClose={this.handleSnackbarClose}
      >
        <SnackbarContent
          aria-describedby="Notifications"
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              {this.state.type === "success" && <CheckCircle />}
              {this.state.type === "error" && <Error />}
              <Typography
                variant="body1"
                style={{ color: "white", marginLeft: "15px" }}
              >
                {this.state.message}
              </Typography>
            </span>
          }
          style={{ backgroundColor: color }}
        />
      </Snackbar>
    );
  }
}

export function openSnackbar({ message, type }) {
  openSnackbarFn({ message, type });
}

export default Notifier;

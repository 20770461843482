import {
  AgentSummaryReportExtensionType, IEmailSubReportAgentSummary
} from "../../interfaces/email-reports";
import { IExtension } from "../../interfaces/extension";
import { INumber } from "../../interfaces/number";

import {  useState } from "react";
import {  Grid, MenuItem, Select,  TextField } from "@material-ui/core";
import MuiTitle from "../misc/form/MuiTitle";
import VipTimeFieldMui from "../VipTimeFieldMui";
import VipExtensionSelector from "../VipExtensionSelector";
import MuiToggleBoolControlled from "../misc/form/MuiToggleBoolControlled";


export interface iEmailReportAgentSummaryProps {
  ddis: INumber[]
  extensions: IExtension[]
  data: IEmailSubReportAgentSummary
  hotDesks: IExtension[]
  submitFunction: (field: string, data: any) => void
}
const something: any = { ...AgentSummaryReportExtensionType };
const extTypes = Object.keys(something).map((key: any) => ({
  id: key,
  name: something[key],
}));
export const EmailReportAgentSummary = ({ data, ddis, extensions, hotDesks, submitFunction }: iEmailReportAgentSummaryProps) => {
  const [state, setState] = useState<IEmailSubReportAgentSummary>(() => {
  //  console.log(hotDesks)
    const xState = { ...data };
    if (!xState.startTime) {
      xState.startTime = "08:00:00"
      submitFunction("startTime",  "08:00:00");
    }
    if (!xState.endTime) {
      xState.endTime = "18:00:00"
      submitFunction("endTime",  "18:00:00");
    }

    if (!xState.extensionType) {
      xState.extensionType = "EX"
      submitFunction("extensionType",  "EX");
    }

    if (!xState.agentSummarySortBy) {
      xState.agentSummarySortBy = ""
     // submitFunction("agentSummarySortBy",  "08:00:00");
    }

    if (!xState.agentSummaryHideAgentsThatAnsweredNoCalls) {
      xState.agentSummaryHideAgentsThatAnsweredNoCalls = false
      submitFunction("startTime",  "08:00:00");
    }

    if (!xState.minimumWaitTime) {
      xState.minimumWaitTime = 5

      submitFunction("minimumWaitTime",  5);
    }

    if (!xState.hourly) {
      xState.hourly = false
      submitFunction("hourly",  false);
    }
    return xState;
  })

  const setField = (field: string, value: any) => {
    console.log("subform", field, value);
    const stateUp:any = {...state};
    stateUp[field] = value;
    setState(stateUp);
    submitFunction(field, value);
  }


  let extensionsToExclueOptions = extensions
  switch (state.extensionType) {
    case "HD":
      extensionsToExclueOptions = hotDesks
      break;
    default:
      extensionsToExclueOptions = extensions
      break;
  }


  return <Grid container spacing={2}>
    {/* <Grid item xs={12}>
      {JSON.stringify(data)}

    </Grid> */}
    <Grid item xs={6}>
      <MuiTitle title="Start Time" />
      <VipTimeFieldMui
        field="startTime"
        value={state.startTime ? state.startTime : "08:00:00"}
        updateField={setField}
      />

    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="End Time" />
      <VipTimeFieldMui
        field="endTime"
        value={state.startTime ? state.endTime : "18:00:00"}
        updateField={setField}
      />


    </Grid>
    <Grid item xs={3}>
      <MuiTitle title="Hourly" />
      <MuiToggleBoolControlled value={state.hourly} setValue={(ev) => {
        setField("hourly", !state.hourly);
      }} />
      
    </Grid>


    <Grid item xs={3}>
      <MuiTitle title="Group stats by" />

      <Select
        variant='outlined'
        fullWidth
        id="extensionType"
        value={state.extensionType}
        onChange={(d, v) => {
          //console.log(d.target.value)
          setField('extensionType', d.target.value)
        }}
      >
        {extTypes.map((pay: any) => {
          let opt = (
            <MenuItem key={pay.id} value={pay.id}>
              {pay.name}
            </MenuItem>
          );

          return opt;
        })}

      </Select>
    </Grid>


    <Grid item xs={3}>
      <MuiTitle title="Sort agents by" />

      <Select
        variant='outlined'
        fullWidth

        value={state.agentSummarySortBy}
        onChange={(d, v) => {
          //console.log(d.target.value)
          setField('agentSummarySortBy', d.target.value)
        }}
      >

        <MenuItem value='none'>
          Number
        </MenuItem>

        <MenuItem value='mostcalls'>
          Most Calls
        </MenuItem>

        <MenuItem value='totaltalktime'>
          Total Talk Time
        </MenuItem>


      </Select>
    </Grid>


    <Grid item xs={3}>
      <MuiTitle title="Hide agents who answered no calls?" />
      <MuiToggleBoolControlled value={state.agentSummaryHideAgentsThatAnsweredNoCalls
        ? state.agentSummaryHideAgentsThatAnsweredNoCalls
        : false} setValue={(ev) => {
          setField("agentSummaryHideAgentsThatAnsweredNoCalls", !state.agentSummaryHideAgentsThatAnsweredNoCalls);
      }} />
      
    </Grid>

    <Grid item xs={6}>
      <MuiTitle title="Extensions To Include (leave empty for all)" />


      <VipExtensionSelector
        updateField={submitFunction}
        buttonText="Extensions"
        field="agentSummaryExtensionsToInclude"
        value={state.agentSummaryExtensionsToInclude}
        extensions={extensionsToExclueOptions} />

    </Grid>

    <Grid item xs={6}>
      <MuiTitle title="Extensions To Exclude" />


      <VipExtensionSelector
        updateField={submitFunction}
        buttonText="Extensions"
        field="extensionsToExclude"
        value={state.extensionsToExclude}
        extensions={extensionsToExclueOptions} />

    </Grid>

    <Grid item xs={12}>
      <MuiTitle title="DDIs To Exclude" />


      <VipExtensionSelector
        updateField={submitFunction}
        buttonText="DDI's"
        field="ddisToExclude"
        value={state.ddisToExclude}
        extensions={ddis} />

    </Grid>



    <Grid item xs={2}>
      <MuiTitle title="Include Missed" />


      <MuiToggleBoolControlled value={state.includeMissed
        ? state.includeMissed
        : false} setValue={(ev) => {
          setField("includeMissed", !state.includeMissed);
        }} />
      

    </Grid>


    <Grid item xs={2}>
      <MuiTitle title="Only Show Totals" />


      <MuiToggleBoolControlled value={state.onlyShowTotals
        ? state.onlyShowTotals
        : false} setValue={(ev) => {
          setField("onlyShowTotals", !state.onlyShowTotals);
        }} />
      

    </Grid>


    <Grid item xs={2}>
      <MuiTitle title="Use Queue Logs" />
      <MuiToggleBoolControlled value={state.useQueueLogs
        ? state.useQueueLogs
        : false} setValue={(ev) => {
          setField("useQueueLogs", !state.useQueueLogs);
        }} />
    </Grid>


    <Grid item xs={2}>
      <MuiTitle title="Use SMDR" />
      <MuiToggleBoolControlled value={state.useSMDR
        ? state.useSMDR
        : false} setValue={(ev) => {
          setField("useSMDR", !state.useSMDR);
        }} />
    </Grid>


    <Grid item xs={2}>
      <MuiTitle title="Only Show Internal Calls" />


      <MuiToggleBoolControlled value={state.agentSummaryOnlyShowInternalCalls
        ? state.agentSummaryOnlyShowInternalCalls
        : false} setValue={(ev) => {
          setField("agentSummaryOnlyShowInternalCalls", !state.agentSummaryOnlyShowInternalCalls);
        }} />
      

    </Grid>

    <Grid item xs={2}>
      <MuiTitle title="Add Individual Queue Stats" />


      <MuiToggleBoolControlled value={state.agentSummaryAddQueueStats
        ? state.agentSummaryAddQueueStats
        : false} setValue={(ev) => {
          setField("agentSummaryAddQueueStats", !state.agentSummaryAddQueueStats);
        }} />
      

    </Grid>

    <Grid item xs={2}>
      <MuiTitle title="Minimum Wait Time" />

        <TextField type="number" variant="outlined" value={state.minimumWaitTime} onChange={(ev)=>{
          setField("minimumWaitTime", parseInt(ev.target.value))
        }} />

    </Grid>
  </Grid>
}
export default EmailReportAgentSummary;


import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";
import { Card } from "react-bootstrap";

const set = new MySettings();

interface RouteParams {}

const ViewProvisioningURLs: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = ({ resellerID, session }) => {
  const [provisioningId, setProvisioningId] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const getSettings = async () => {
      try {
        const response = await set.provisioningIdGet(
          resellerID ? resellerID : ""
        );
        if (isMounted) {
          setProvisioningId(response.id);
          setLoading(false);
        }
      } catch (error) {
        App.newNotificationError("Problem loading provisioning URLs.");
      }
    };

    getSettings();

    return () => {
      isMounted = false;
    };
  }, [resellerID]);

  return (
    <div className="content">
      <PageHead
        resellerID={resellerID}
        title="Provisioning URLs"
        parents={[
          {
            title: "Provisioning - Devices",
            url: `../${resellerID}/provisioning`,
          },
        ]}
      ></PageHead>

      {loading ? (
        <Loading />
      ) : (
        <Card>
          <Card.Header>
            <h2 className="my-auto">Provisioning URLs</h2>
          </Card.Header>
          <Card.Body className="form-card-body">
            <Card.Text>
              Jitsi URL: https://provisioning1.vipvoipuk.net/
              {provisioningId}/$&#123;uuid&#125;
            </Card.Text>
            <Card.Text>
              Yealink URL: http://provisioning1.vipvoipuk.net/
              {provisioningId}
            </Card.Text>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};
export default ViewProvisioningURLs;

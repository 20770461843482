import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import "react-table/react-table.css";

import { IPageProps } from "../../interfaces/page-props";

import Loading from "../../components/loading";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  IPhonebook,
  IPhonebooksHttpResponse,
} from "../../interfaces/phonebook";

import { Row, Col, Button } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import PageHead from "../../components/PageHead";
import ResellerRedirect from "../../components/ResellerRedirect";
import Error from "../../components/error";
import image from "../../compiledSVG/undraw_people_search_wctu.svg";
import App from "../../App";
import VipBurgerMenu from "../../components/VipBurgerMenu";
library.add(faWindowClose);

const set = new MySettings();

const Phonebooks: React.FC<RouteComponentProps & IPageProps> = ({
  resellerID,
  session: { userType },
}) => {
  const [phoneBooks, setPhoneBooks] = useState<IPhonebook[]>([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const getPhonebooks = async () => {
      try {
        const data: IPhonebooksHttpResponse = await set.phonebooksGet(
          resellerID
        );
        if (isMounted) {
          if (data.phoneBooks) {
            setPhoneBooks(data.phoneBooks);
          }
          setLoading(false);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
        App.newNotificationError(error.data);
      }
    };
    if (userType === "resellersContacts") {
      if (resellerID !== undefined) {
        getPhonebooks();
      }
    } else {
      getPhonebooks();
    }
    return () => {
      isMounted = false;
    };
  }, [resellerID, userType]);

  const phoneBookHandleDelete = async (phoneBookID: string) => {
    const dialogResp = window.confirm(
      "Are you sure you want to remove this phone book and the all the numbers inside it?"
    );
    if (dialogResp) {
      setLoading(true);
      try{
        await set.deletePhoneBookRecord(phoneBookID);
        setPhoneBooks(
          phoneBooks.filter((phonebook) => phonebook.id !== phoneBookID)
        )
        setLoading(false);
        App.newNotificationSuccess('Phone book removed.')
      }catch(err){
        App.newNotificationError('There was a problem removing the phone book. '+err)
      }
    }
  }

  let newurl = "/phonebooksnew";
  if (resellerID && resellerID.length > 0) {
    newurl = "/reseller/" + resellerID + "/phonebooksnew";
  }
  return (
    <div className="content">
      <PageHead resellerID={resellerID} title="Phone Books" parents={[]}>
        <VipBurgerMenu
          options={[
            {
              title: "New Phone Book",
              link: newurl,
              isExternal: false,
            },
          ]}
        />
      </PageHead>

      {resellerID === undefined && userType === "resellersContacts" ? (
        <ResellerRedirect />
      ) : loading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : phoneBooks.length > 0 ? (
        <>
          <Row>
            {[...phoneBooks].reverse().map((book: IPhonebook) => (
              <Col key={book.id} md="6" lg="3">
                <div className="dashboard_item whiteBox m-2">
                  <Link to={"./phonebook/" + book.id}>
                    <img
                      src="/voip/portal/svg/phonebook-img.svg"
                      className="img-fluid"
                      alt="Phone Books"
                    />
                    <h4 className="mt-2 mb-4">{book.name}</h4>
                    {/* <Button variant="outline-primary" className="mb-2">
                        <FaIcon
                          icon="user-plus"
                          color="primary"
                          className="mr-1"
                        />
                      </Button> */}
                  </Link>
                  <Button
                    type="button"
                    variant="outline-danger"
                    className="mr-3"
                    onClick={() => {
                      phoneBookHandleDelete(book.id ? book.id : "");
                    }}
                  >
                    <FaIcon icon="user-minus" color="red" />
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <div className="text-center">
          <img src={image} className="img-fluid" alt="No Results Found" />
          <br />
          <br />
          <h2>No phone books found</h2>
          <p>
            You currently have no phone books. Would you like to create one?
          </p>
          <Link to={newurl}>
            <Button variant="outline-primary" className="mt-4 ">
              <FaIcon icon="user-plus" color="primary" className="mr-2" />
              New Phone Book
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Phonebooks;

import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";
import { ButtonGroup, Button } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserMinus,
  faPencilAlt,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import PageHead from "../../components/PageHead";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import ResellerRedirect from "../../components/ResellerRedirect";
import { Link, RouteComponentProps } from "react-router-dom";
import VipBurgerMenu from "../../components/VipBurgerMenu";
import Error from "../../components/error";
import App from "../../App";
import image from "../../compiledSVG/undraw_people_search_wctu.svg";
import {
  IVoiceRouting,
  IVoiceRoutingsHttpResponse,
} from "../../interfaces/voiceroutings";

library.add(faUserMinus, faPencilAlt, faUserPlus);

const set = new MySettings();

interface RouteParams {
  customersSitesServicesId: string;
}

const VoiceRoutings: React.FC<RouteComponentProps<RouteParams> & IPageProps> =
  ({ match, resellerID, session }) => {
    const [numbers, setNumbers] = useState<IVoiceRouting[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // console.log("STATE", phoneBook);
    // console.log("PROPS", props);

    useEffect(() => {
      let isMounted = true;

      const getNumbers = async () => {
        try {
          const data: IVoiceRoutingsHttpResponse = await set.voiceRoutingsGet(
            match.params.customersSitesServicesId
          );
          if (isMounted) {
            setNumbers(data.items);
            setLoading(false);
          }
        } catch (error) {
          setError(true);
        }
      };

      getNumbers();

      return () => {
        isMounted = false;
      };
    }, [match.params.customersSitesServicesId]);

    const handleNumberDelete = async (phoneBookNumberID: string) => {
      if (window.confirm("Are you sure you want to remove this route?")) {
        try {
          setLoading(true);

          await set.voiceRoutingsRemove(phoneBookNumberID);

          setNumbers(
            numbers.filter((number) => number.id !== phoneBookNumberID)
          );

          const notification: INotification = {
            title: "Success",
            data: "Routing Deleted",
            variant: NOTIFICATION_TYPE.success,
            srcFunction: "handleNumberDelete",
            srcData: window.location.pathname,
            srcComponent: "VoiceRoutings",
          };

          App.newNotification(notification);
        } catch (error) {
          const notification: INotification = {
            title: "Error",
            data: error,
            variant: NOTIFICATION_TYPE.warning,
            srcFunction: "handleNumberDelete",
            srcData: window.location.pathname,
            srcComponent: "VoiceRoutings",
          };

          App.newNotification(notification);
        }
        setLoading(false);
      }
    };

    const table = [
      {
        Header: "Text",
        accessor: "text",
        sortable: true,
      },
      {
        Header: "Destination",
        accessor: "destination",
        sortable: true,
      },
      {
        Header: "",
        accessor: "id", // String-based value accessors!
        Cell: (props: any) => {
          let numberEditLink = "./voicerouting/" + props.value;

          return (
            <ButtonGroup aria-label="Basic example">
              <Link to={numberEditLink} className="btn btn-outline-primary">
                Edit <FaIcon icon="pencil-alt" color="blue" />
              </Link>
              <Button
                onClick={() => {
                  handleNumberDelete(props.value);
                }}
                variant="outline-danger"
              >
                Delete <FaIcon icon="user-minus" color="red" />
              </Button>
            </ButtonGroup>
          );
        },
      },
    ];

    return (
      <div className="content">
        <PageHead resellerID={resellerID} title={"Voice Routings"} parents={[]}>
          <VipBurgerMenu
            options={[
              {
                title: "New Route",
                link: "./voicerouting/new",
                isExternal: false,
              },
            ]}
          />
        </PageHead>

        {loading ? (
          <Loading />
        ) : error ? (
          <Error />
        ) : numbers.length > 0 ? (
          <>
            {resellerID === undefined &&
            session.userType === "resellersContacts" ? (
              <ResellerRedirect />
            ) : (
              <VoipTable
                title="Voice Routes"
                data={numbers}
                col={table}
                sortByStart="text"
                multiSelect={false}
              />
            )}
          </>
        ) : (
          <div className="text-center">
            <img src={image} className="img-fluid" alt="No Results Found" />
            <br />
            <br />
            <h2>No routes found</h2>
            <p>This service has no voice routes. Would you like to add one?</p>
            <Link to={"./voicerouting/new"}>
              <Button variant="outline-primary" className="mt-3">
                <FaIcon icon="user-plus" color="primary" className="mr-2" />
                New Route
              </Button>
            </Link>
          </div>
        )}
      </div>
    );
  };
export default VoiceRoutings;

import React from "react";

const Status: React.FC<any> = ({ array, page, setPage }) => {
  return array.map((item: string, index: number) => {
    return (
      <li key={item}>
        <button
          className="status__item buttonLink"
          onClick={(e) => {
            e.preventDefault();
            if (page > index + 1) setPage(index + 1);
          }}
        >
          <span
            className={
              page === index + 1
                ? "menu-active"
                : page > index + 1
                ? "menu-done"
                : ""
            }
          >
            {index + 1}
          </span>
          {item}
        </button>
      </li>
    );
  });
};
export default Status;

import * as React from "react";
import { useMain } from "../../context/main";
import { Card } from "react-bootstrap";
import { Form } from "react-final-form";
import VipTextFinalField from "../../components/misc/form/VipTextFinalField";

const ProfileMFAForm = ({ data, onSubmit }: any) => {
  const mainContext = useMain();
  let app="portal.vipvoip.co.uk"
  if(mainContext.data.branding?.brandPortalDomain!=null){
    app=mainContext.data.branding?.brandPortalDomain
  }
  const qrURL =
    "https://mfa1.hatsoffsoftware.net/generateqrv1.php?app="+app+"&label=" +
    data.label +
    "&secret=" +
    data.secret;

  const initalValues = { ...data, code: "" };
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initalValues}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Card.Body>
            <div className="text-center">
              {data.secret.length > 0 && (
                <img src={qrURL} alt="" className="mb-4" />
              )}

              <Card.Title>
                Use your authenticator app to scan the QR code above.<br />Or if you can not scan QR codes enter the code {data.secret} in to your authenticator app.
              </Card.Title>
            </div>
            <Card.Text>
              On iOS this can be done by opening your authenticator app and clicking
              the 2FA tab in the bottom right corner. Then click Scan in the top
              right corner.
            </Card.Text>
            <Card.Text>
              On Android this can be done by opening your authenticator app. Then
              click the burger menu in the top right. Then click Two-Factor
              Authentication (2FA). Then click the camera button.
            </Card.Text>
            <Card.Text>
              Once you have scanned the QR code please enter the six digit shown
              in the app. This will activate 2FA for your portal account.
            </Card.Text>

            <label>Type in the 2FA code from your phone here</label>

            <VipTextFinalField
              fieldName="code"
              placeholder="Token generated by your 2FA Application"
              required={true}
            />
          </Card.Body>

          <Card.Footer className="form-buttons">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={submitting || pristine}
            >
              Submit
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={form.reset}
              disabled={submitting || pristine}
            >
              Reset
            </button>
          </Card.Footer>
        </form>
      )}
    />
  );
};

export default ProfileMFAForm;

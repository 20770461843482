import React, { useState } from "react";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";

import PageHead from "../../components/PageHead";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";

import App from "../../App";
import { RouteComponentProps } from "react-router-dom";
import { ICustomerAllowDialCode } from "../../interfaces/customer";
import CustomerAllowDialCodeForm from "./CustomerAllowDialCodeForm";

const set = new MySettings();

interface IPhoneBookNumberPage extends RouteComponentProps<any>, IPageProps {}

const CustomerAllowDialCodesNew: React.FC<IPhoneBookNumberPage> = (props) => {
  const [dialCode, setDialCode] = useState<ICustomerAllowDialCode>({
    customersId: props.resellerID ? props.resellerID : "",
    dialCode: "",
    id: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      await set.customerAllowDialCodesAdd(dialCode);
      // console.log("data", data);
      const notification: INotification = {
        title: "Success",
        data: "New dial code saved.",
        variant: NOTIFICATION_TYPE.success,
        srcFunction: "handleSubmit",
        srcData: window.location.pathname,
        srcComponent: "CustomerAllowDialCode",
      };
      App.newNotification(notification);
      let url = "/allowdialcodes";
      if (props.match.params.rid && props.match.params.rid.length > 0) {
        url = "/reseller/" + props.match.params.rid + url;
      }
      props.history.push(url);
    } catch (error) {
      App.newNotificationError(error.data);
    }
    setLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDialCode({ ...dialCode, [event.target.id]: event.target.value });
  };

  return (
    <div className="content">
      <PageHead
        resellerID={props.resellerID}
        title="New Allow Dial Code"
        parents={[]}
      ></PageHead>

      {loading === true ? (
        <Loading />
      ) : (
        <CustomerAllowDialCodeForm
          dialCode={dialCode}
          handleChange={handleChange}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default CustomerAllowDialCodesNew;

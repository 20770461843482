import React from "react";
import { addMonths } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import { Form, Field } from "react-final-form";
import { Button, Grid } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MuiAutocompleteSelect from "../../components/MuiComponents/MuiAutocompleteSelect";
import MuiTextInput from "../../components/MuiComponents/MuiTextInput";

import { ISearchFormProps } from "../../interfaces/search-form";

const dateMemo = new Date();
const dateMemoMinusOneMonth = addMonths(dateMemo, -1);

const IncomingCallStatsSearchForm: React.FunctionComponent<
  ISearchFormProps
> = ({ onSubmit, numbers, customers, areas }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        startTime: dateMemoMinusOneMonth,
        endTime: dateMemo,
        destination: "All Numbers",
        area: "All Areas",
        owner: "All Customers",
      }}
      render={({ handleSubmit, values, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>


              <Grid item xs={3} style={{ textAlign: "center", paddingBottom: 0, paddingTop: 0 }}>
                From
              </Grid>

              <Grid item xs={3} style={{ textAlign: "center", paddingBottom: 0, paddingTop: 0 }}>
                To
              </Grid>

              <Grid item xs={3} style={{ textAlign: "center", paddingBottom: 0, paddingTop: 0 }}>
              </Grid>

              <Grid item xs={3} style={{ textAlign: "center", paddingBottom: 0, paddingTop: 0 }}>
              </Grid>

              <Grid item xs={3}>

                <Field name="startTime">
                  {({ input: { onChange, value } }) => (
                    <KeyboardDatePicker
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={value}
                      onChange={onChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={3}>

                <Field name="endTime">
                  {({ input: { onChange, value } }) => (
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={value}
                      onChange={onChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  )}
                </Field>
              </Grid>
            </MuiPickersUtilsProvider>

            <Grid item xs={3}>
              <MuiTextInput outlinedLabel="Notes" name="notes" />
            </Grid>

            <Grid item xs={3} />

            <Grid item xs={3}>
              <MuiAutocompleteSelect
                name="destination"
                options={numbers!}
                label="Number"
              />
            </Grid>

            <Grid item xs={3}>
              <MuiAutocompleteSelect
                name="owner"
                options={customers!}
                label="Customer"
              />
            </Grid>
            <Grid item xs={3}>
              <MuiAutocompleteSelect
                name="area"
                options={areas!}
                label="Area"
              />
            </Grid>

            <Grid item xs={1} />

            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={submitting}
              >
                Search
              </Button>
            </Grid>
          </Grid>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </form>
      )}
    />
  );
};

export default IncomingCallStatsSearchForm;

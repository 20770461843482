import React, { useState, useEffect } from "react";
import { INotification } from "../interfaces/notification";
import { NOTIFICATION_TYPE } from "../enum/notification-type";

import Loading from "./loading";
import Button from "react-bootstrap/Button";
import MySettings from "../static/settings";
import App from "../App";

const set = new MySettings();

const MfaResetButton = (props : any) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const callMfaReset = async () => {
      if (isLoading) {
        try {
          const result = await set.resellerContactResetMFAForCustomerContact(
            props.id
          );
          console.log("hook fired", result);

          const notification: INotification = {
            title: "Success",
            data: "2FA Reset Success!",
            variant: NOTIFICATION_TYPE.success,
            srcComponent: "PersonForm",
            srcData: "success",
            srcFunction: "resellerContactResetMFAForCustomerContact",
          };

          App.newNotification(notification);
        } catch (error) {
          console.log(error);

          const notification: INotification = {
            title: "Failed",
            data: "2FA Reset Went Wrong!",
            variant: NOTIFICATION_TYPE.warning,
            srcComponent: "PersonForm",
            srcData: "failed",
            srcFunction: "resellerContactResetMFAForCustomerContact",
          };

          App.newNotification(notification);
        }
      }
      setIsLoading(false);
    };
    callMfaReset();
  }, [isLoading, props]);

  const handleClick = () => {
    if (!isLoading) setIsLoading(true);
    else return null;
  };

  return isLoading ? (<Loading />) : (<Button
      variant="primary"
      type="reset"
      onClick={handleClick}
      className="mt-3"
    >
      2FA Reset
    </Button>)
};

export default MfaResetButton;

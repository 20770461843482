
import * as React from 'react';

import { Form, Field } from 'react-final-form';
import makeId from '../../components/misc/form/makeId';

import VipTextFinalField from '../../components/misc/form/VipTextFinalField';
import MySettings from '../../static/settings';
// ({ data, onSubmit }: any)

import { CircularProgress } from '@material-ui/core';


const set = new MySettings();
export interface IPermissionPopupFormProps {
    data: any;
    onSubmit: any;

}

export interface IPermissionPopupFormState {
    data: any;
    visible: boolean;
}

export default class SitePopupForm extends React.Component<IPermissionPopupFormProps, IPermissionPopupFormState> {
    public state: any = {
        data: [],
        visible: false,
    };

    public setVisible = (bool: boolean) => {
        this.setState({ visible: bool });
    }

    constructor(props: any) {
        super(props);
        console.log("notusless");
    }

    public submit = (data: any) => {
        this.setState({ visible: false });
        this.props.onSubmit('newSite', data)
    }
    public render() {
        console.log(this.props)
        if (this.state.visible === false) {
            return <></>
        } else {
            return <div className="overlay">

                <div className="overlay-content">

                    <Form
                        onSubmit={this.submit}
                        initialValues={this.props.data}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (

                            <form onSubmit={handleSubmit}>


                                <div className="popupContent text-left test-new-customer-start WHITEN">

                                    <fieldset>
                                        <legend>Site</legend>


                                        <div className="form-group">
                                            <label>Description</label>

                                            <VipTextFinalField fieldName="description" placeholder="Description" required={true} />


                                        </div>



                                        <div className="form-row">
                                            <div className="form-group col">
                                                <label className="form-label">Postcode</label>

                                                <VipTextFinalField fieldName="postCode" placeholder="" required={true}></VipTextFinalField>
                                            </div>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                console.log(values)
                                                if (!values.postCode || values.postCode === '') {
                                                    alert("Please enter a post code.")
                                                } else {
                                                    form.change('looking_lookup', true);
                                                    setTimeout(() => {
                                                        set.postCodeLookUp(values.postCode).then(res => {
                                                            console.log(res);
                                                            form.change('looking_lookup', false);
                                                            form.change('lookup', res)
                                                        }).catch(err => {
                                                            form.change('manualAddress', true)
                                                            form.change('looking_lookup', false);
                                                            alert("Lookup Failed, please enter manually");
                                                        })
                                                    }, 2000)
                                                }


                                            }} className="find-address" type="button">
                                                {values.looking_lookup === true ? (<CircularProgress color="primary" />) : ('Find Address')}</button>
                                        </div>

                                        {values.lookup !== undefined && <>

                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <label className="form-label">Choose Address</label>
                                                    <Field component="select" name="lookupIndex">
                                                        {({ input, meta, inputOnChange }) => (
                                                            <>
                                                                <select {...input} id={input.name.replace('.', '_')} autoComplete={"autocomplete_off_hack_" + makeId(5)} className="form-control" onChange={(evt: any) => {
                                                                    const val = evt.currentTarget.value;

                                                                    const chosen: any = values.lookup.items[val];


                                                                    console.log("selAddress", val, chosen);

                                                                    input.onChange(evt);
                                                                    inputOnChange && inputOnChange(evt);
                                                                    if (chosen === undefined) {
                                                                        form.change('line1', '')
                                                                        form.change('line2', '')
                                                                        //  form.change('postCode', '')
                                                                        form.change('county', '')
                                                                        form.change('city', '')
                                                                    } else {
                                                                        form.change('line1', chosen.line1)
                                                                        form.change('line2', chosen.line2)
                                                                        //    form.change('postCode', chosen.postCode)
                                                                        form.change('county', chosen.county)
                                                                        form.change('city', chosen.city)
                                                                    }
                                                                }}>
                                                                    <option></option>
                                                                    {values.lookup.items.map((map: any, index: number) => {
                                                                        return <option key={index} value={index}>{map.line1}, {map.line2}, {map.city}</option>
                                                                    })}
                                                                </select>
                                                                {meta.error && meta.touched && <span>{meta.error}</span>}

                                                            </>

                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </>}

                                        <div className="form-group">
                                            <label>Address line 1</label>
                                            <VipTextFinalField fieldName="line1" placeholder="Line 1" required={true} />

                                        </div>
                                        <div className="form-group">
                                            <label>Address line 2</label>
                                            <VipTextFinalField fieldName="line2" placeholder="Line 2" required={false} />

                                        </div>
                                        <div className="form-group">
                                            <label>City</label>
                                            <VipTextFinalField fieldName="city" placeholder="City" required={true} />

                                        </div>

                                        <div className="form-group">
                                            <label>County</label>
                                            <VipTextFinalField fieldName="county" placeholder="County" required={true} />

                                        </div>

                                    </fieldset>

                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={submitting || pristine}>{values.index ? (<>Save Site</>) : (<>Add Site</>)}</button>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => this.setState({ visible: false })}
                                    >Cancel</button>




                                </div>
                            </form>
                        )} />

                </div></div>

        }

    }
}




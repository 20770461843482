import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";
import { TextField } from '@material-ui/core';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPencilAlt, faUserMinus, faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { Redirect, RouteComponentProps } from "react-router-dom";
import App from "../../App";
import image from "../../compiledSVG/undraw_people_search_wctu.svg";
import Error from "../../components/error";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import ResellerRedirect from "../../components/ResellerRedirect";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import { IPageProps } from "../../interfaces/page-props";
import {
  IWallboardLayout,
  IWallboardWidgetSetting,
  IWallboardWidgetSettingAgents
} from "../../interfaces/wallboards";
import WallboardCallsTakenForm from "./WallboardCallsTakenForm";
import WallboardNameForm from "./wallboardNameForm";
import WallboardWidgetIncomingCallsForm from "./WallboardWidgetIncomingCallsForm";

import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import VipTextFinalField from "../../components/misc/form/VipTextFinalField";
import WallboardWidgetMessageForm, {
  WallboardWidgetMissedCallsForm
} from "./WallboardAgentForm";
import WallboardWidgetResetEventsForm from "./WallboardResetEventsForm";

library.add(faUserMinus, faPencilAlt, faUserPlus);

const set = new MySettings();

export interface IWallboardWidgetFormProps {
  widget: IWallboardWidgetSetting;
}

interface IWallboardWidgetFormAgentsProps {
  widget: IWallboardWidgetSettingAgents
}

const WallboardWidgetAgentForm: React.FC<any> = (props:IWallboardWidgetFormAgentsProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IWallboardWidgetSettingAgents>({...props.widget});
  const [hotDeskNameToLogIn,setHotDeskNameToLogIn] = useState('')
  const [extensionNameToLogIn,setExtensionNameToLogIn] = useState('')

  /*useEffect(() => {
    console.log(typeof data.timeOnEachAgentPageSeconds);
  }, [data.timeOnEachAgentPageSeconds]);*/

  const realSubmit = async (formData: IWallboardWidgetSettingAgents) => {
    formData.timeOnEachAgentPageSeconds = +formData.timeOnEachAgentPageSeconds;

    setLoading(true);
    try {
      const newData = await set.wallboardWidgetUpdate(
        props.widget.id,
        formData
      );
      setData({...data,...newData});
      const notification: INotification = {
        title: "Widget Updated",
        data: "The widget has been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "WallboardWidgetMessageForm",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification);
    } catch (err) {
      App.newNotificationError("Failed to update the widget " + err.data);
    }
    setLoading(false);
  };

  const onClickLogIn = async () => {
    setLoading(true)
    try {
      const newData = await set.logInHotDesk(props.widget.id,hotDeskNameToLogIn,extensionNameToLogIn)
      setData({...data,...newData});
      setExtensionNameToLogIn('')
      setHotDeskNameToLogIn('')
      const notification: INotification = {
        title: "Logged In",
        data: "The agent has been logged in.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "WallboardWidgetMessageForm",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification)
    } catch (err) {
      App.newNotificationError("Failed to log in the agent " + err.data);
    }
    setLoading(false)
  }

  const onClickLogOut = async (number:string) => {
    setLoading(true)
    try {
      const newData = await set.logOutHotDesk(props.widget.id,number)
      setData({...data,...newData});
      const notification: INotification = {
        title: "Logged Out",
        data: "The agent has been logged out.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "WallboardWidgetMessageForm",
        srcData: "success",
        srcFunction: "onSubmit",
      };
      App.newNotification(notification)
    } catch (err) {
      App.newNotificationError("Failed to log out the agent " + err.data);
    }
    setLoading(false)
  }

  if(loading){
    return <Loading />
  }

  return (<>
  <Card style={{ textAlign: "left" }}>
    <Card.Header as="h6">Agent Settings ({props.widget.id})</Card.Header>
        <>
          <Form
            onSubmit={realSubmit}
            initialValues={data}
            mutators={{
              ...arrayMutators,
            }}
            render={({
              handleSubmit,
              form: {
                mutators: { push, pop },
              },
              pristine,
              form,
              submitting,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Card.Body>
                  <div className="mb-3">
                    <Button
                      variant="outline-primary"
                      className="noleft mb-2"
                      onClick={() => push("groupNames", undefined)}
                    >
                      <FaIcon
                        icon="user-plus"
                        color="primary"
                        className="mr-1"
                      />
                      Add Group Name
                    </Button>
                    <FieldArray name="groupNames">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <div
                            key={name}
                            className="d-flex justify-content-start customHeight"
                          >
                            <label className="mr-2 my-auto">
                              Group Name #{index + 1}
                            </label>

                            <VipTextFinalField
                              fieldName={name}
                              placeholder="Group Name"
                              required={true}
                              type="text"
                            />

                            <Button
                              onClick={() => fields.remove(index)}
                              variant="outline-danger"
                            >
                              Delete
                            </Button>
                          </div>
                        ))
                      }
                    </FieldArray>
                  </div>

                  <div className="mb-3">
                    <label>Number of seconds to show each page of agents</label>
                    <VipTextFinalField
                      fieldName="timeOnEachAgentPageSeconds"
                      placeholder=""
                      required={false}
                      type="number"
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-check-label">
                      <Field
                        name="includeDynamicAgents"
                        component="input"
                        type="checkbox"
                      />{" "}
                      Include dynamic agents?
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="form-check-label">
                      <Field
                        name="hideUnavailableAgents"
                        component="input"
                        type="checkbox"
                      />{" "}
                      Hide unavailable agents?
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="form-check-label">
                      <Field
                        name="unavailableIfInNoQueues"
                        component="input"
                        type="checkbox"
                      />{" "}
                      Mark agents as unavailable if they are in no queues?
                    </label>
                  </div>
                </Card.Body>

                <Card.Footer className="text-center">
                  <Button
                    variant="primary"
                    className="noleft"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Save
                  </Button>
                </Card.Footer>
              </form>
            )}
          />{" "}
        </>
    </Card>
    <br />
    <Card>
      <Card.Header as="h6">Agents</Card.Header>
      <Card.Body>
        <table>
          <thead>
            <tr>
              <th>Number</th>
              <th>Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Updated</th>
              <th>End Of Last Call</th>
              <th>Linked Extension</th>
            </tr>
          </thead>
          <tbody>
          {data.extensions.sort((a,b)=>{
            let aNumber=parseInt(a.name)
            let bNumber=parseInt(b.name)
            return aNumber-bNumber
          }).map((extension)=>{
            let linkedDiv = <></>
            if(extension.linkedExtensionName){
              linkedDiv = <Button onClick={()=>{
                onClickLogOut(extension.name)
              }}>Log Out {extension.linkedExtensionName}</Button>
            }else if(extension.name===hotDeskNameToLogIn){
              linkedDiv = (<>
                <TextField defaultValue={extensionNameToLogIn} id="outlined-basic" label="Extension Number" variant="outlined" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setExtensionNameToLogIn(event.target.value);
                }} /> 
                <Button onClick={onClickLogIn}>Log In</Button>
              </>)
            }else if(extension.type==='HD'){
              linkedDiv = <Button onClick={()=>{
                setHotDeskNameToLogIn(extension.name)
              }}>Log In To?</Button>
            }
            return <tr>
              <td>{extension.name}</td>
              <td>{extension.callerid}</td>
              <td>{extension.type}</td>
              <td>{extension.status}</td>
              <td>{extension.statusUpdatedDateTime?.replace('T',' ')}</td>
              <td>{extension.endOfLastCallDateTime?.replace('T',' ')}</td>
              <td>{linkedDiv}</td>
            </tr>
          })}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  </>);
};

interface RouteParams {
  id: string;
}

const WallboardPage: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = ({ match, resellerID, session }) => {
  const [wallboard, setWallboard] = useState<IWallboardLayout>({
    customersId: "",
    id: "",
    name: "",
    canEdit: false,
  });
  const [widgets, setWidgets] = useState<IWallboardWidgetSetting[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [name, setName] = useState("");
  const [redirect, setRedirect] = useState(false);
  // console.log("state of parent", widgets);

  useEffect(() => {
    let isMounted = true;

    const getWallboards = async () => {
      try {
        const data = await set.wallboardGet(match.params.id);
        const widgetsCopy: IWallboardWidgetSetting[] = [];
        setName(data.layout.name);
        data.widgets.forEach((widget: any) => {
          if (Array.isArray(widget.groupNames)) {
            widgetsCopy.push(widget);
          } else if (widget.groupNames == null) {
            widgetsCopy.push({ ...widget, groupNames: [] });
          } else {
            widgetsCopy.push({
              ...widget,
              groupNames: widget.groupNames.value,
            });
          }
        });

        if (isMounted) {
          const sortedWidgets=widgetsCopy.sort((a,b)=>{
            if(a.sortOrder==null){
              a.sortOrder=999;
            }
            if(b.sortOrder==null){
              b.sortOrder=999;
            }
            return a.sortOrder-b.sortOrder;
          })
          setWidgets(sortedWidgets);
          setWallboard(data.layout);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    };

    getWallboards();

    return () => {
      isMounted = false;
    };
  }, [match.params.id]);


  const deleteMe = async () => {
    const confirm = window.confirm("Are you sure you want to remove this wallboard?");
    if (confirm) {
      setLoading(true)
      try {
        const res = await set.wallboardLayoutRemove(wallboard.id);
        if (res.success) {
          const notification: INotification = {
            title: "Wallboard Removed",
            data: "The wallboard has been removed.",
            variant: NOTIFICATION_TYPE.success,
            srcComponent: "WallboardRemove",
            srcData: "success",
            srcFunction: "onSubmit",
          };
          App.newNotification(notification);
          setLoading(false);
          setRedirect(true);
        }
      } catch (err) {
        App.newNotificationError("Failed to remove the wallboard " + err.data);
        setLoading(false);
      }
    }
  }

const getWidgetForm = (name:string,widget:IWallboardWidgetSetting) => {
  switch (widget.widgetType) {
    case "Agents":
      return (
        <WallboardWidgetAgentForm
          key={widget.id}
          widget={widget as IWallboardWidgetSettingAgents}
          name={name}
        />
      );
    case "Message":
      return (
        <WallboardWidgetMessageForm
          key={widget.id}
          widget={widget}
        />
      );
    case "MissedCalls":
      return (
        <>
          <WallboardWidgetMissedCallsForm
            key={widget.id}
            widget={widget as IWallboardWidgetSettingAgents}
          />
        </>
      );
    case "CallsTaken":
      return (
        <>
          <WallboardCallsTakenForm
            key={widget.id}
            widget={widget as IWallboardWidgetSettingAgents}
          />
        </>
      );
    case "IncomingCalls":
      return (
        <>
          <WallboardWidgetIncomingCallsForm
            key={widget.id}
            widget={widget as IWallboardWidgetSettingAgents}
          />
        </>
      );
  }
}


  return (
    <div className="content">
      {redirect && <Redirect to="../" />}
      <PageHead
        resellerID={resellerID}
        title={
          wallboard !== undefined && wallboard.name !== undefined
            ? wallboard.name
            : ""
        }
        parents={[{ title: "Wallboards", url: "../wallboards" }]}
      >
        <button type="button" className="btn btn-danger" onClick={deleteMe}>    <FaIcon
          fixedWidth
          className="whiteIcon"
          icon="trash-alt"
        /> Delete</button></PageHead>

      {loading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : widgets.length > 0 ? (
        <>
          <WallboardNameForm name={name} id={match.params.id} />
          <div style={{ height: "30px" }}></div>
          {resellerID === undefined &&
            session.userType === "resellersContacts" ? (
            <ResellerRedirect />
          ) : (
            <>
              {widgets.map((widget) => {
                const widgetForm=getWidgetForm(name,widget)
                const resetForm=<WallboardWidgetResetEventsForm widgetId={widget.id} resetEvents={widget.resetEvents}></WallboardWidgetResetEventsForm>
                return <>{widgetForm}<br />{resetForm}<br /></>;
              })}
            </>
          )}
        </>
      ) : (
        <div className="text-center">
          <img src={image} className="img-fluid" alt="No Results Found" />
          <br />
          <br />
          <h2>No widgets found.</h2>
        </div>
      )}
    </div>
  );
};
export default WallboardPage;

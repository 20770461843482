import React, { useEffect, useState } from "react";

import { NOTIFICATION_TYPE } from "../enum/notification-type";
import { INotification } from "../interfaces/notification";

import MySettings from "../static/settings";

import { Form as BootstrapForm } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Form } from "react-final-form";
import App from "../App";
import Loading from "../components/loading";
import VipTextFinalField from "../components/misc/form/VipTextFinalField";

import Helmet from "react-helmet";
import image from "../compiledSVG/undraw_people_search_wctu.svg";
import BrandedLogo from "../components/BrandedLogo";
import "../sass/login.scss";

const set = new MySettings();

enum PageState {
  Loading,
  ShowForm,
  ShowNetworkError,
  ShowUserError
}

const PasswordReset: React.FC<any> = ({
  match: {
    params: { id },
  },
  history,
  branding
}) => {
  const [pageState, setPageState] = useState(PageState.Loading);
  
  useEffect(() => {
    const checkUserId = async () => {
      try {
        const result = await set.passwordResetCheck(id);

        if (result.success) {
          setPageState(PageState.ShowForm);
        }else{
          setPageState(PageState.ShowUserError)
        }
      } catch (error) {
        setPageState(PageState.ShowNetworkError);
        const notification: INotification = {
          title: "Network Error",
          data: "Please try again later.",
          variant: NOTIFICATION_TYPE.warning,
          srcComponent: "PasswordReset",
          srcData: "failed",
          srcFunction: "checkUserId",
        };

        App.newNotification(notification);

        console.log(error);
      }
    };
    checkUserId();
  }, [id]);

  const UserError = () => {
    return <div className="text-center">
      <img
        src={image}
        className="img-fluid mt-4 mb-4"
        alt="No Results Found"
      />

      <h2>Reset link no longer valid.</h2>
    </div>
  }

  const Error = () => {
    return <div className="text-center">
      <img
        src={image}
        className="img-fluid mt-4 mb-4"
        alt="Something went wrong"
      />

      <h2>Something went wrong.</h2>
    </div>
  };

  const renderSwitch = () => {
    switch(pageState){
      case PageState.Loading:
        return <Loading />
      case PageState.ShowForm:
        return <>
        <h6 className="text-center mb-5">
          Please enter your new password below.<br />
          Passwords must be at least 8 characters long with at least one number, one lower case character and one uppercase character.
        </h6>

        <BootstrapForm>
          <BootstrapForm.Group>
            <BootstrapForm.Label>Password</BootstrapForm.Label>
            <VipTextFinalField
              fieldName="password1"
              placeholder="Password"
              required={true}
              type="password"
            />
          </BootstrapForm.Group>
        </BootstrapForm>

        <BootstrapForm>
          <BootstrapForm.Group>
            <BootstrapForm.Label>
              Repeat Password
            </BootstrapForm.Label>
            <VipTextFinalField
              fieldName="password2"
              placeholder="Repeat Password"
              required={true}
              type="password"
            />
          </BootstrapForm.Group>
        </BootstrapForm>

        <Button
          id="submit"
          type="submit"
          block
          size="lg"
          className="mt-4 noleft"
        >
          Reset Password
        </Button>
      </>
      case PageState.ShowUserError:
        return UserError()
      case PageState.ShowNetworkError:
        return Error()
    }
  }

  return (
    <div className="content">
    <Helmet>
     <title>{branding && branding.name} Password Reset</title>
    </Helmet>

      <Form
        onSubmit={async ({ password1, password2 }) => {
          try {
            setPageState(PageState.Loading);

            const result = await set.passwordSetPassword(
              id,
              password1,
              password2
            );

            if (result.success) {
              const notification: INotification = {
                title: "Success",
                data: "Password reset successful. Please log in with your new password.",
                variant: NOTIFICATION_TYPE.success,
                srcComponent: "PasswordReset",
                srcData: "success",
                srcFunction: "startResetPassword",
              };

              App.newNotification(notification);

              history.push("/login");
            } else {
              setPageState(PageState.ShowForm)
              const notification: INotification = {
                title: "Failed",
                data: result.message,
                variant: NOTIFICATION_TYPE.warning,
                srcComponent: "PasswordReset",
                srcData: "failed",
                srcFunction: "startResetPassword",
              };

              App.newNotification(notification);
            }
          } catch (error) {
            
            const notification: INotification = {
              title: "Network Error",
              data: "Please try again later.",
              variant: NOTIFICATION_TYPE.warning,
              srcComponent: "passwordSetPassword",
              srcData: "failed",
              srcFunction: "checkUserId",
            };

            App.newNotification(notification);
            setPageState(PageState.ShowForm)
            console.log(error);
          }
        }}
        initialValues={{}}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={{ span: 4, offset: 4 }} className="mt-5 mb-5">
                <div className="d-flex justify-content-center mt-5 mb-5">
                  <BrandedLogo />
                </div>
                <h3 className="text-center">Password Reset</h3>
                {renderSwitch()}
              </Col>
            </Row>
          </form>
        )}
      />
    </div>
  );
};

export default PasswordReset;

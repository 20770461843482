import * as React from "react";

import Logout from "./Logout";
import Number from "./Number/Number";
import Numbers from "./Number/Numbers";
import Profile from "./Profile/Profile";
import ResellerHome from "./ResellerHome";

import PhonebookForm from "../components/oldForms/form-phonebook";
import AreaCodeRouting from "./AreaCodeRouting";
import PhoneBooks from "./PhoneBook/PhoneBooks";
import Privacy from "./Privacy";

import People from "./People/People";
import Person from "./People/Person";
import PhoneBook from "./PhoneBook/PhoneBook";
import ChatSummary from "./Receptionist/chat";
import CustomerDeatailPage from "./Receptionist/customerPage";
import Receptionist from "./Receptionist/homePage";
import ReceptionistMessage from "./Receptionist/message";
import ReceptionistHome from "./Receptionist/virtualReceptionistHome";
import NewMessage from "./Receptionist/VirtualReceptionistNewMessage";

import CallRecording from "./CallRecording/CallRecording";

import CallLog from "./CallLog/CallLog";
import Device from "./Devices/Device";
import Devices from "./Devices/Devices";

import EmailReport from "./EmailReport/EmailReport";
import EmailReports from "./EmailReport/EmailReports";

import Reseller from "./Customer/Customers";
import SitesPage from "./Sites/Sites";

import CustomerPage from "./Customer/Customer";

import SitePage from "./Sites/Site";

import MFAAttemptPage from "./MFAAttemptPage";

import ChromePluginAuthPage from "./ChromePluginAuthPage";
import Office365Page from "./Office365Page";
import PermissionPage from "./Permissions/Permission";
import PermissionsPage from "./Permissions/Permissions";
import PhoneBookNumber from "./PhoneBook/PhoneBookNumber";
import ResellerInvoicePage from "./ResellerInvoices/ResellerInvoicePage";
import ResellerInvoicesPage from "./ResellerInvoices/ResellerInvoicesPage";
import ResellerProfile from "./ResellerProfile/ResellerProfile";
import NewServicePage from "./Service/NewService";
import ServicePage from "./Service/Service";
import VoipServiceProvisioningPage from "./VoipServiceProvisioningPage";

import { IBranding } from "../interfaces/branding";
import ActionStepPage from "./ActionStepPage";
import ConnectwiseContactsPage from "./ConnectwiseContactsPage";
import CustomerAllowDialCodes from "./Customer/CustomerAllowDialCodes";
import CustomerAllowDialCodesNew from "./Customer/CustomerAllowDialCodesNew";
import CustomerDefaultGroupPage from "./CustomerDefaultGroups/CustomerDefaultGroup";
import CustomerDefaultGroupsPage from "./CustomerDefaultGroups/CustomerDefaultGroups";
import DemoPage from "./Demo/Demo";
import ResellerProvisioningSettings from "./Devices/ResellerProvisioningSettings";
import ViewProvisioningURLs from "./Devices/ViewProvisioningURLs";
import HelpPage from "./Help";
import Home from "./Home";
import IncomingCallRedirect from "./IncomingCallStats/incomingCallRedirect";
import IncomingCallStats from "./IncomingCallStats/IncomingCallStats";
import InternetServiceEnquiry from "./InternetServiceEnquiry";
import Login from "./Login";
import LoginPasswordReset from "./LoginPasswordReset";
import VoipServiceMeetingRoom from "./Meeting Rooms/VoipServiceMeetingRoom";
import VoipServiceMeetingRooms from "./Meeting Rooms/VoipServiceMeetingRooms";
import VoipServiceMeetingRoomsForm from "./Meeting Rooms/VoipServiceMeetingRoomsForm";
import NumberTrackerPage from "./Number/NumberTrackerPage";
import NumberTrackersPage from "./Number/NumberTrackersPage";
import ResellerAllocateNumbers from "./Number/ResellerAllocateNumbers";
import PasswordReset from "./PasswordReset";
import ResellerPeople from "./People/ResellerPeople";
import ResellerPerson from "./People/ResellerPerson";
import PhoneBookLDAPSettings from "./PhoneBook/PhoneBookLDAPSettings";
import PhoneBookUplaod from "./PhoneBook/phoneBookUpload";
import ResellerPSAConfigurePage from "./psa/ResellerPSAConfigurePage";
import ResellerPSACustomerMatchingPage from "./psa/ResellerPSACustomerMatchingPage";
import ResellerPSAProductMatchingPage from "./psa/ResellerPSAProductMatchingPage";
import ResellerPSAReconciliationPage from "./psa/ResellerPSAReconciliationPage";
import ResellerCreditNotePage from "./ResellerInvoices/ResellerCreditNotePage";
import ResellerCreditNotesPage from "./ResellerInvoices/ResellerCreditNotesPage";
import ServiceDetails from "./Service/TeamsCallRecordingService";
import StandardTariff from "./Tariff/StandardTariff";
import Tariff from "./Tariff/Tariff";
import Tariffs from "./Tariff/Tariffs";
import TeamsCallRecordingPage from "./TeamsCallRecordingPage";
import VoiceRouting from "./VoiceRoutings/VoiceRouting";
import VoiceRoutings from "./VoiceRoutings/VoiceRoutings";
import WallboardPage from "./wallboards/WallboardPage";
import WallboardsListPage from "./wallboards/WallboardsListPage";
import PBXAuditTrailPage from "./PBXAuditTrailPage";

export class PageDeclares {
  public _this: any;
  public _session: any;
  public _menuOptions: any;
  public _branding: any;
  public _resellerID: any;
  public _menuReloadListener: any;
  public _customer: any;
  public _brandingReloadListener: any;
  constructor(
    _this: any,
    session: any,
    menuOptions: any,
    branding: IBranding,
    menuReloadListener: any,
    brandingReloadListener: any,
    resellerID?: string,
    customer?: any
  ) {
    this._this = this;
    this._session = session;
    this._menuOptions = menuOptions;
    this._branding = branding;
    this._menuReloadListener = menuReloadListener;
    this._brandingReloadListener = brandingReloadListener;

    if (resellerID !== undefined) {
      this._resellerID = resellerID;
      this._customer = customer;
    }

    // console.log("og", this)
  }

  homePage = (props: any) => {
    return (
      <Home
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        menuOptions={this._menuOptions}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  demoPage = (props: any) => {
    return (
      <DemoPage
        {...props}
        newNotification={this._this.notificationListener}
        reloadMenu={this._menuReloadListener}
        menuOptions={this._menuOptions}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  resellerHome = (props: any) => {
    return (
      <ResellerHome
        {...props}
        reloadMenu={this._menuReloadListener}
        menuOptions={this._menuOptions}
        newNotification={this._this.notificationListener}
        session={this._session}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
        reloadBranding={this._brandingReloadListener}
      />
    );
  };
  pbxAuditTrailPage = (props:any) =>{return (<PBXAuditTrailPage {...props} />)}
  phoneBookUpload = (props: any) => {
    return (
      <PhoneBookUplaod
        {...props}
        reloadMenu={this._menuReloadListener}
        menuOptions={this._menuOptions}
        newNotification={this._this.notificationListener}
        session={this._session}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
        reloadBranding={this._brandingReloadListener}
      />
    );
  };

  loginPage = (props: any) => {
    return (
      <Login
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        redirectTo={this._this.redirectTo}
        branding={this._branding}
      />
    );
  };

  passwordReset = (props: any) => {
    return (
      <PasswordReset
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        redirectTo={this._this.redirectTo}
        branding={this._branding}
      />
    );
  };

  loginPasswordReset = (props: any) => {
    return (
      <LoginPasswordReset
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        redirectTo={this._this.redirectTo}
        branding={this._branding}
      />
    );
  };

  logoutPage = (props: any) => {
    return (
      <Logout
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  chromePluginPage = (props: any) => {
    return <ChromePluginAuthPage {...props}  branding={this._branding}/>;
  };

  mfaPage = (props: any) => {
    return (
      <MFAAttemptPage
        {...props}
        reloadMenu={this._this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  profilePage = (props: any) => {
    return (
      <Profile
        {...props}
        reloadMenu={this._this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  numbersPage = (props: any) => {
    return (
      <Numbers
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  tariffsPage = (props: any) => {
    return (
      <Tariffs
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  tariffPage = (props: any) => {
    return (
      <Tariff
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  standardTariffPage = (props: any) => {
    return (
      <StandardTariff
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  callRecordingPage = (props: any) => {
    return (
      <CallRecording
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  callLogPage = (props: any) => {
    return (
      <CallLog
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  numberPage = (props: any) => {
    return (
      <Number
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  voipServiceProvisioningPage = (props: any) => {
    return (
      <VoipServiceProvisioningPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  voipServiceMeetingRooms = (props: any) => {
    return (
      <VoipServiceMeetingRooms
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  voipServiceMeetingRoom = (props: any) => {
    return (
      <VoipServiceMeetingRoom
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  voipServiceMeetingRoomsForm = (props: any) => {
    return (
      <VoipServiceMeetingRoomsForm
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  numberTrackersPage = (props: any) => {
    return (
      <NumberTrackersPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  numberTrackerPage = (props: any) => {
    return (
      <NumberTrackerPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  areaCodeRoutingPage = (props: any) => {
    return (
      <AreaCodeRouting
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        resellerID={this._resellerID}
        session={this._session}
        resellerCheck={this._this.resellerCheck}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  actionStepPage = (props: any) => {
    return (
      <ActionStepPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  teamsCallRecordingPage = (props: any) => {
    return (
      <TeamsCallRecordingPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  office365Page = (props: any) => {
    return (
      <Office365Page
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  connectwiseContactsPage = (props: any) => {
    return (
      <ConnectwiseContactsPage
        {...props}
      />
    );
  };
  phonebooksPage = (props: any) => {
    return (
      <PhoneBooks
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  phonebooksFormPage = (props: any) => {
    return (
      <PhonebookForm
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  peoplePage = (props: any) => {
    return (
      <People
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  receptionistPage = (props: any) => {
    return (
      <Receptionist
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  receptionistHomePage = (props: any) => {
    return (
      <ReceptionistHome
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  customerDetailPage = (props: any) => {
    return (
      <CustomerDeatailPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  addMessagePage = (props: any) => {
    return (
      <NewMessage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  chatPage = (props: any) => {
    return (
      <ChatSummary
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  chatMessagePage = (props: any) => {
    return (
      <ReceptionistMessage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  incomingCallRedirect = (props: any) => {
    return (
      <IncomingCallRedirect
        {...props}
        newNotification={this._this.notificationListener}
        session={this._session}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  resellerPeoplePage = (props: any) => {
    return (
      <ResellerPeople
        {...props}
        newNotification={this._this.notificationListener}
        session={this._session}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  resellerPersonPage = (props: any) => {
    return (
      <ResellerPerson
        {...props}
        newNotification={this._this.notificationListener}
        session={this._session}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  personPage = (props: any) => {
    return (
      <Person
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  phonebookPage = (props: any) => {
    return (
      <PhoneBook
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  wallboardPage = (props: any) => {
    return (
      <WallboardPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  wallboardsListPage = (props: any) => {
    return (
      <WallboardsListPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  phonebookLDAPSettingsPage = (props: any) => {
    return (
      <PhoneBookLDAPSettings
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  provisioningViewURLsPage = (props: any) => {
    return (
      <ViewProvisioningURLs
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  customerAllowDialCodesPage = (props: any) => {
    return (
      <CustomerAllowDialCodes
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  customerAllowDialCodesNewPage = (props: any) => {
    return (
      <CustomerAllowDialCodesNew
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  psaConfigurationPage = (props: any) => {
    return (
      <ResellerPSAConfigurePage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  psaCustomerMatchingPage = (props: any) => {
    return (
      <ResellerPSACustomerMatchingPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  psaProductMatchingPage = (props: any) => {
    return (
      <ResellerPSAProductMatchingPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  psaReconciliationPage = (props: any) => {
    return (
      <ResellerPSAReconciliationPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  phonebookNumberPage = (props: any) => {
    return (
      <PhoneBookNumber
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  privacyPage = (props: any) => {
    return <Privacy {...props} />;
  };
  devicesPage = (props: any) => {
    return (
      <Devices
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  resellerProvisioningSettingsPage = (props: any) => {
    return (
      <ResellerProvisioningSettings
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  devicePage = (props: any) => {
    return (
      <Device
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
        branding={this._branding}
      />
    );
  };
  emailReportsPage = (props: any) => {
    return (
      <EmailReports
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  voiceRoutingPage = (props: any) => {
    return <VoiceRouting {...props} />;
  };
  voiceRoutingsPage = (props: any) => {
    return (
      <VoiceRoutings
        {...props}
        session={this._session}
        resellerID={this._resellerID}
      />
    );
  };
  emailReportPage = (props: any) => {
    return (
      <EmailReport
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  incomingCallStatsPage = (props: any) => {
    return (
      <IncomingCallStats
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  resellerPage = (props: any) => {
    return (
      <Reseller
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  customerPage = (props: any) => {
    return (
      <CustomerPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  permssionPage = (props: any) => {
    return (
      <PermissionPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  permssionsPage = (props: any) => {
    return (
      <PermissionsPage
        {...props}
        also
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  customerDefaultGroupPage = (props: any) => {
    return (
      <CustomerDefaultGroupPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  customerDefaultGroupsPage = (props: any) => {
    return (
      <CustomerDefaultGroupsPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  sitesPage = (props: any) => {
    return (
      <SitesPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  sitePage = (props: any) => {
    return (
      <SitePage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  servicePage = (props: any) => {
    return (
      <ServicePage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  newServicePage = (props: any) => {
    return (
      <NewServicePage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        customer={this._customer}
        resellerID={this._resellerID}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  resellerCreditNotePage = (props: any) => {
    return (
      <ResellerCreditNotePage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  resellerCreditNotesPage = (props: any) => {
    return (
      <ResellerCreditNotesPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        redirectTo={this._this.redirectTo}
      />
    );
  };

  resellerInvoicesPage = (props: any) => {
    return (
      <ResellerInvoicesPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  resellerInvoicePage = (props: any) => {
    return (
      <ResellerInvoicePage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  resellerProfilePage = (props: any) => {
    return (
      <ResellerProfile
        {...props}
        reloadBranding={this._brandingReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  resellerAllocateNumbers = (props: any) => {
    return (
      <ResellerAllocateNumbers
        {...props}
        resellerID={this._resellerID}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  helpPage = (props: any) => {
    return (
      <HelpPage
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  internetServiceEnquiry = (props: any) => {
    return (
      <InternetServiceEnquiry
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        redirectTo={this._this.redirectTo}
      />
    );
  };
  teamscallrecordingservice = (props: any) => {
    return (
      <ServiceDetails
        {...props}
        reloadMenu={this._menuReloadListener}
        newNotification={this._this.notificationListener}
        session={this._session}
        redirectTo={this._this.redirectTo}
      />
    );
  };
}

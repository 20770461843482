
import { Box, TextField, Typography } from '@material-ui/core';
import * as React from 'react';

// Simple footer component


export interface IVipProvisioningYealinkProps {
  title: string;
  data: any;
  dataField: string;
  payload?: any;
  updateField: (field: any, payload: any) => void;
}



const VipProvisioningYealinkField = ({ dataField, title, data, updateField }: IVipProvisioningYealinkProps) => {

  let masterValue: any;

  masterValue = data[dataField];
  const handleChange = (event: any) => {
    console.log(event.target.id, event.target.value);
    updateField(event.target.id, event.target.value);
  }

  return <Box>
    <Typography variant="subtitle1">{title}</Typography>
    <TextField
      fullWidth
      id={dataField}
      value={masterValue}
      onChange={handleChange}
      variant="outlined"
      size="small"
    />


  </Box>
}

export default VipProvisioningYealinkField;
import * as React from "react";
import { useMain } from "../context/main";
import "../sass/footer.scss";

const Footer = () => {
  var d = new Date();
  const year = d.getFullYear();
  const mainContext = useMain();
  var privacyPolicyURL = mainContext.data.branding?.privacyPolicyURL;
  if (privacyPolicyURL == null) {
    privacyPolicyURL = "./privacy";
  }
  return (
    <div className="footer">
      <p>
        © {year} {mainContext.data.branding?.name}, Inc. All Rights Reserved.
      </p>
      <a target="_new" href={privacyPolicyURL} className="nav-link">
        Privacy Policy.
      </a>
    </div>
  );
};

export default Footer;

import { Box, Button, TextField } from "@material-ui/core";
import { useEffect, useState } from "react"


interface iVipSidebarCustomerNameFormProps {
    name: string;

    update: (bool: string) => void;
}


const VipSidebarCustomerNameForm = ({ name, update }: iVipSidebarCustomerNameFormProps) => {
   // const [working, setWorking] = useState(false);
    const [title, setTitle] = useState("");
    useEffect(() => {
        setTitle(name)
    }, [name]);

    const updateName = async () => {
    //    setWorking(true)
        update(title)
    }
    return <>
        <Box style={{width:"100%",padding:10}}>

            <TextField
                value={title}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(event.target.value);
                }}
                name={"name"}
                fullWidth

                variant="outlined"
                label={""}
                size="small"
            />



            <Button fullWidth color="primary" style={{ marginTop: 10 }} disabled={(title.length===0)} variant="contained" onClick={() => {
                updateName();
            }}>Update Customer Name</Button>
        </Box>
    </>;
}


export default VipSidebarCustomerNameForm
import React from "react";
import { Card, CardContent, Grid, Button, Typography } from "@material-ui/core";
import { closeModal } from "./Popup";

interface IConfirmationModalProps {
  header: string;
  message: string;
  buttonText: string;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  header,
  message,
  buttonText,
  onConfirm,
}) => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {header}
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: "16px" }}>
            <Typography variant="subtitle2" align="center">
              {message}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onConfirm}
              fullWidth
            >
              {buttonText}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button color="secondary" fullWidth onClick={closeModal}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ConfirmationModal;

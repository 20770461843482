import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { ButtonGroup, IconButton } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DeleteIcon from '@material-ui/icons/Delete';
import NotesIcon from '@material-ui/icons/Notes';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import React, { useState } from 'react';
import MuiModal from '../../components/modal/MuiModal';

interface iCallRecordingButtons {
    recording: any;
    playerHref: string;
    downloadHref: string;
    activePlayer: string;
    setActivePlayer: (id: string) => void;
    setProtection: (id: string, v: boolean) => void;
    deleteRecord: (id: string) => void;
    setRecordingModal: (d: boolean) => void;
    setRecordings: (d: any[]) => void;
}

interface ITranscriptionState {
    sentence?: any[];
    message?: string;
  }
  
const CallRecordingButtons = ({ activePlayer, setActivePlayer, recording, downloadHref, playerHref, setProtection, deleteRecord, setRecordingModal, setRecordings }: iCallRecordingButtons) => {
    const [transcriptionModal, setTranscriptionModal] = useState(false);
    const [transcription, setTranscription] = useState<ITranscriptionState>({ sentence: [], message: '' });
    return <>
        {activePlayer === recording.id && <>
            <AudioPlayer
                autoPlay
                src={playerHref}
                onPlay={e => console.log("onPlay")}
            // other props here
            />
        </>}
        <MuiModal
      open={transcriptionModal}

      title="Transcription"

      onClose={() => setTranscriptionModal(false)}

    >
      <>
        {transcription.sentence && <>
          {transcription.sentence.map((sentence)=>{
            return <div style={{margin:'0.5em'}}>{sentence.words}</div>
          })}
        </>}
        <div style={{ marginTop: "20px",padding:'0.5em' }}>
          <h5>Summary</h5>
          {transcription.message}
        </div>
      </>
    </MuiModal>
        <ButtonGroup>

            {/* Lock */}
            {activePlayer === recording.id ? <IconButton title="Stop Recording" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setActivePlayer("");
            }}>
                <StopIcon />
            </IconButton> : <IconButton title="Play Recording" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setActivePlayer(recording.id);
            }}>
                <PlayArrowIcon />
            </IconButton>}
            {!recording.isProtected ? <IconButton title="Lock Recording" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setProtection(recording.id, true);
            }}>
                <LockIcon />
            </IconButton> : <IconButton title="Un-Lock Recording" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setProtection(recording.id, false);
            }}>
                <LockOpenIcon />
            </IconButton>}
            <IconButton title="Delete" color="primary" style={{ color: "red" }} onClick={(ev) => {
                ev.preventDefault();
                if (
                    window.confirm(
                        "Are you sure you want to delete this recording?"
                    )
                ) {
                    deleteRecord(recording.id);
                }
            }}>
                <DeleteIcon />
            </IconButton>

            {recording.transcription && recording.transcription.length > 0 && <IconButton title="View Transcription" color="primary" onClick={(ev) => {
                ev.preventDefault();
                const sentences=[]
                if(recording.transcriptionSentences){
                    for(const sentence of recording.transcriptionSentences){
                        if(typeof sentence==='string'){
                            sentences.push({words:sentence})
                        }else{
                            sentences.push(sentence)
                        }
                    }
                }
                setTranscription({
                    message: recording.transcription,
                    sentence: sentences,
                });
                setTranscriptionModal(true)
            }}>
                <NotesIcon />
            </IconButton>}


            <IconButton title="Email Recording" color="primary" onClick={(ev) => {
                ev.preventDefault();
                setRecordings([recording.id])
                setRecordingModal(true)
            }}>
                <EmailIcon />
            </IconButton>






            <IconButton title="Download Recording" color="primary" target="_new" href={downloadHref}>
                <GetAppIcon />
            </IconButton>



        </ButtonGroup>


    </>
}

export default CallRecordingButtons
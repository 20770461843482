import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";
import Error from "../../components/error";
import Loading from "../../components/loading";
import PersonForm from "../../components/oldForms/form-person";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import {
  IAccessGroup,
  IAccessGroupHttpResponse
} from "../../interfaces/access-group";
import { INotification } from "../../interfaces/notification";
import { IPageProps } from "../../interfaces/page-props";
import { IPerson } from "../../interfaces/people";
import { IWallboardLayout } from "../../interfaces/wallboards";
import MySettings from "../../static/settings";

const set = new MySettings();

export interface IPersonState {
  data: IPerson;
  accessGroups: IAccessGroup[];
  wallboards: IWallboardLayout[];
}

interface IResellerPersonPage extends RouteComponentProps<any>, IPageProps { }

const ResellerPerson: React.FC<IResellerPersonPage> = (props) => {
  const [resellerPersonData, setResellerPersonData] = useState<IPersonState>({
    accessGroups: [], //potential access groups
    data: {
      id: "new",
      lastName: "",
      title: "",
      name: "",
      emailAddress: "",
      firstName: "",
      telephone: "",
      salutation: "",
      line1: "",
      line2: "",
      line3: "",
      town: "",
      county: "",
      postCode: "",
      portalAccess: false,
      portalAccessGroups: [], //groups the user is currently in
      faxNumber: "",
      wallboards: [],
      customersId: props.resellerID ? props.resellerID : "",
      usesMFA: false,
      callRecordingsAllowedExtensions: []
    },
    wallboards: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // console.log("PROPS", props);
  // console.log("STATE", resellerPersonData);

  useEffect(() => {
    let isMounted = true;

    const getResellerPerson = async () => {
      try {
        const dataAccessGroups: IAccessGroupHttpResponse =
          await set.resellerPortalAccessGroupsGet();
        if (props.match.params.id !== "new" && isMounted) {
          const dataResellerPersonGet = await set.resellerPersonGet(
            props.match.params.id
          );
          if (dataResellerPersonGet.portalAccessGroups == null) {
            dataResellerPersonGet.portalAccessGroups = []
          }
          // console.log("dataPersonGet", dataPersonGet);
          if (isMounted) {
            setResellerPersonData((prevState) => {
              return {
                ...prevState,
                data: dataResellerPersonGet,
                accessGroups: dataAccessGroups.items,
              };
            });
          }
        } else if (isMounted) {
          setResellerPersonData((prevState) => {
            return {
              ...prevState,
              accessGroups: dataAccessGroups.items,
            };
          });
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };

    getResellerPerson();

    return () => {
      isMounted = false;
    };
  }, [props.match.params.id]);

  const handleSubmit = async (values: IPerson) => {
    console.log("VALUES", values);
    try {
      setLoading(true);
      if (values.id !== "new") {
        await set.resellerPersonUpdate(values)
        const notification: INotification = {
          title: "Success",
          data: "Person saved.",
          variant: NOTIFICATION_TYPE.success,
          srcFunction: "submitForm",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification);
      } else {
        await set.resellerPersonAdd(values)
        const notification: INotification = {
          title: "Success",
          data: "Person created.",
          variant: NOTIFICATION_TYPE.success,
          srcFunction: "submitForm",
          srcData: window.location.pathname,
          srcComponent: "Person",
        };
        App.newNotification(notification);
      }
      props.history.push("../../resellerpeople");
    } catch (error) {
      console.log("error", error);
      const notification: INotification = {
        title: "Warning",
        data: error.data,
        variant: NOTIFICATION_TYPE.warning,
        srcFunction: "submitForm",
        srcData: window.location.pathname,
        srcComponent: "Person",
      };
      App.newNotification(notification);
      setLoading(false)
    }
  };

  return (
    <div className="content">
      <PageHead
        resellerID={props.resellerID}
        title={
          resellerPersonData.data.name
            ? resellerPersonData.data.name
            : "New Person"
        }
        parents={[{ title: "People", url: "/voip/portal/resellerpeople" }]}
      ></PageHead>

      {error ? (
        <Error />
      ) : loading ? (
        <Loading />
      ) : (
        <Row>
          <Col md="12">
            <PersonForm
              loginAttempts={[]}
              data={resellerPersonData.data}
              accessGroups={resellerPersonData.accessGroups}
              wallboards={resellerPersonData.wallboards}
              sites={[]}
              submitFunction={handleSubmit}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ResellerPerson;

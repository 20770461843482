import React from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import GetExtensionOptions from "../../components/getExtensionOptions";
import GetSiteOptions from "../../components/getOptions";

interface IExtraAccounts {
    singleCustomerMode: boolean,
    customers: any[],
    form: any,
    userType: string,
    newUrlMode: boolean,
    values: any
}
const ExtraAccounts: React.FunctionComponent<IExtraAccounts> = ({ singleCustomerMode, customers, form, userType, newUrlMode, values }) => {
    return <FieldArray name="provisioningSettings.extraAccounts">
        {({ fields }) => {


            return <>
                {fields.map((name, index) => {
                    const someValue = fields.value[index];


                    return <div key={index}>
                        <label className="d-flex justify-content-between"><span>Extra Account</span> <button className="btn btn-danger" type="button" onClick={ev => {
                            ev.preventDefault();
                            fields.remove(index);
                        }}>X</button></label>
                        {!singleCustomerMode && <div className="form-group">
                            <label>Customer</label>
                            <Field component="select" name={`${name}.customersId`}>
                                {({ input, meta }) => (
                                    <div>
                                        <select
                                            {...input}
                                            id={input.name.replace(".", "_")}
                                            className="form-control"
                                        >
                                            <option></option>
                                            {customers.map((map) => {
                                                return (
                                                    <option key={map.id} value={map.id}>
                                                        {map.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        {meta.error && meta.touched && (
                                            <span>{meta.error}</span>
                                        )}
                                    </div>
                                )}
                            </Field>
                        </div>}

                        {(singleCustomerMode || someValue.customersId) && <div className="form-group">
                            <label>Site</label>

                            <Field component="select" name={`${name}.customersSitesId`}>
                                {({ input, meta, inputOnChange }) => {
                                    const reallyMeta = { ...meta }
                                    reallyMeta.touched = true;
                                    return <>
                                        {GetSiteOptions({
                                            form: form,
                                            addBlank: true,
                                            input: input,
                                            meta: reallyMeta,
                                            inputOnChange: inputOnChange,
                                            userType: userType,
                                            customerID: someValue.customersId,
                                            singleCustomerMode: singleCustomerMode,
                                            associatedOnly: newUrlMode,
                                        })}
                                    </>
                                }}
                            </Field>
                        </div>}
                        <OnChange name={`${name}.asteriskServerExtensionsId`}>
                            {(value, previous) => {
                                // do something
                                //   console.log("V", value, "p", previous)
                                if (value === '') {
                                    console.log(someValue)
                                    const additionalClone: any = { ...someValue }
                                    additionalClone.asteriskServerExtensionsId = '';
                                    additionalClone.extensionCallerid = '';
                                    additionalClone.extensionName = '';


                                    const extra = [...values.provisioningSettings.extraAccounts]
                                    extra[index] = someValue;
                                    form.change('provisioningSettings.extraAccounts', extra);
                                }
                            }}
                        </OnChange>
                        {someValue.customersSitesId && <div className="form-group">
                            <label>Extension</label>

                            <Field
                                name={`${name}.asteriskServerExtensionsId`}
                                component={GetExtensionOptions}
                                customersSitesId={someValue.customersSitesId}
                                defaultV={someValue.asteriskServerExtensionsId}
                                placeholder="Extension" />
                        </div>}


                    </div>;
                })}

                <div className="form-group text-center">
                    <button className=" w-75 btn btn-outline-primary" type="button" onClick={ev => {
                        ev.preventDefault();
                        console.log(values)
                        if (!singleCustomerMode) {
                            fields.push({ customersId: '', asteriskServerExtensionsId: '', customersSitesId: '', });
                        } else {
                            fields.push({ customersId: values.customersId, asteriskServerExtensionsId: '', customersSitesId: '', });
                        }

                    }}>Add Another Account</button>
                </div>
            </>;


        }}
    </FieldArray>;
}

export default ExtraAccounts
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import { useState } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

interface iMuiFreeTypeStringListProp {
    field: string;
    value: string[];
    placeholder: string;
    text: string;
    updateField: (field: any, payload: any) => void;
}


const MuiFreeTypeStringList = ({ text, field, value, placeholder, updateField }: iMuiFreeTypeStringListProp) => {
    const [newField, setNewField] = useState("");

    const [array, setArray] = useState<string[]>(() => {
        if (value) {
            return value
        } else {
            return []
        }
    })

    const addToArray = () => {
        if (newField !== '') {
            const clone = [...array];
            if (clone.indexOf(newField) >= 0) {
                // exists
            } else {
                clone.push(newField)
            }
            setNewField("")
            setArray(clone);
            updateField(field, clone)
        }
    }

    const removeFromArray = (index: number) => {
        const clone = [...array];
        clone.splice(index, 1)

        setArray(clone);
        updateField(field, clone)
    }

    return <Box component={'div'} >
        {array.map((v, index) => {
            return <Box  component={'div'}  key={index} display="flex">
                <Box p={1} flexGrow={1}>
                    {v}
                </Box>
                <Box component={'div'} p={0}>
                    <IconButton size="small" color="secondary" onClick={(ev) => {
                        ev.preventDefault();
                        removeFromArray(index)
                    }}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Box>
        })}


        <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">{text}</InputLabel>
            <OutlinedInput
                label={text}
                type={'text'}
                placeholder={placeholder}
                value={newField}
                onChange={(ev: any) => {
                    setNewField(ev.target.value)
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            color="primary"
                            aria-label="Add"
                            onClick={addToArray}
                            edge="end"
                        >
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </InputAdornment>
                }

            />
        </FormControl>



    </Box>
}


export default MuiFreeTypeStringList;


import * as React from 'react';
import { Table, Dropdown } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

// ({ data, onSubmit }: any)
import Emty from '../NewCustomerWizard/images/empty-state.png';
import makeId from '../../components/misc/form/makeId';



const NumbersForm = (values: any, push: any, pop: any, form: any, thisX: any) => {
  const deleteItem = (index: number) => {
    let update: any[] = [...values.numbers];
    update.splice(index, 1);
    form.change('numbers', update)
  }


  return <>
    <div className="wizard3__right-heading-div">
      <p className="wizard3__right-heading">Allocate Numbers</p>
       
    </div>

    <div className="wizard3__permissions">

      <p className="">Add the required numbers for this customer. You can add both new or previously ported numbers, or request a number port.</p>

    </div>

    {values.numbers.length === 0 ? (<>




      <div className="wizard5-main">

        <div className="wizard6__offer-post">

          <img className="wizard6__offer-post-img" src={Emty} alt={"welcome"} width="224px" height="212px" />



        </div>

      </div>
    </>) : (<>
      <div>
        <Table responsive="sm">
          <thead>

            <tr>

              <th>#'</th>
              <th>Name</th>
              <th>Type</th>
              <th>Site</th>
              <th></th>

            </tr>
          </thead>
          <tbody>

            <FieldArray name="numbers">
              {({ fields }) =>
                fields.map((number, index) => (
                  <tr key={index}>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      {values.numbers[index].number}
                    </td>
                    <td>
                      {values.numbers[index].numberType}
                    </td>
                    <td>

                      <Field component="select" name={`${number}.siteIndex`}>
                        {({ input, meta }) => (
                          <div>
                            <select {...input} id={input.name.replace('.', '_')} className="form-control" autoComplete={"autocomplete_off_hack_" + makeId(5)} >
                              {values.sites.map((map: any, index: number) => {
                                return <option key={index} value={index}>{map.description}</option>
                              })}
                            </select>
                            {meta.error && meta.touched && <span>{meta.error}</span>}
                          </div>
                        )}
                      </Field>
                    </td>
                    <td>
                    {values.numbers.length > 1 && 
                      <Dropdown>
                        <Dropdown.Toggle variant="link" className="button__options" id="dropdown-basic">

                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item as='button' onClick={(e: any) => {
                            e.preventDefault();
                            //  console.log(data);
                            deleteItem(index);
                          }}>Remove</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>}
                    </td>

                  </tr>
                ))}
            </FieldArray>
          </tbody>
        </Table>


      </div>

    </>)}
    <div className="text-center">


      <div className="wizard6__offer-post-box">
        <button className="button-primary" onClick={(e) => {
          e.preventDefault();
          thisX.addNumber.setVisible(true)
        }}><span className="wizard6__plus-icon">&nbsp;&nbsp;&nbsp;&nbsp;</span>Add New Number</button>
        <p className="wizard6-box-middle" ></p>
        <button className="button-primary" onClick={(e) => {
          e.preventDefault();
          thisX.numberPort.setVisible(true)
        }}>Request Number Port</button>

      </div>
    </div>



  </>
}


export default NumbersForm
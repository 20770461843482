
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons';
import { Button, Row } from 'react-bootstrap';

export interface IVipCheckboxProps {
  field: string;
  value: boolean;
  text:string;
  updateField: (field: any, payload: any) => void;
  vertical? : boolean
}

//
export interface IVipCheckboxState {
  bool: boolean;
}

export default class VipCheckbox extends React.Component<IVipCheckboxProps, IVipCheckboxState> {


  constructor(props: any) {
    super(props);

    this.state = { bool: this.props.value };
    this.toggle.bind(this);

  }

  updateFieldWithValue = () => {
    const { bool } = this.state;
    this.props.updateField(this.props.field, bool)
  }
  public toggle=()=> {
    const { bool } = this.state;
    if (bool === true) {
      this.setState({ bool: false }, () => {
        this.updateFieldWithValue();
      });
    } else {
      this.setState({ bool: true }, () => {
        this.updateFieldWithValue();
      });
    }
  }
  public render() {
    const { bool } = this.state;
    return (
      <>
        {this.props.vertical ? (
          <>{bool === true ?
            (<Button variant="link" className="text-black" onClick={this.toggle}><Row><FontAwesomeIcon icon={faCheckCircle} style={{marginLeft:'auto',marginRight:'auto'}} /></Row><Row>{this.props.text}</Row></Button>) :
            (<Button variant="link" className="text-black" onClick={this.toggle}><Row><FontAwesomeIcon icon={faCircle} style={{marginLeft:'auto',marginRight:'auto'}} /></Row><Row>{this.props.text}</Row></Button>)}</>
        ) : (
          <>
            {bool === true ?
            (<Button variant="link" className="text-black" onClick={this.toggle}><FontAwesomeIcon icon={faCheckCircle} /> {this.props.text}</Button>) :
            (<Button variant="link" className="text-black" onClick={this.toggle}><FontAwesomeIcon icon={faCircle} /> {this.props.text}</Button>)}
          </>
        )}
      </>
    );
  }

}


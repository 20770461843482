import * as React from 'react';
import { Route } from 'react-router';

import { Redirect } from 'react-router-dom';
import App from './App';
import { NOTIFICATION_TYPE } from './enum/notification-type';
import { INotification } from './interfaces/notification';
import { IProtectedRouteProps } from './interfaces/protected-route';
import queryString from 'query-string';

export class ProtectedRoute extends Route<IProtectedRouteProps> {


    public render = () => {

        if (!this.props.isAuthenticated(window.location.pathname)) {
            const parsed2 = queryString.parse(window.location.search);
            if (parsed2.loop) {
                delete parsed2.loop
            }
          //  const stringifiedp = queryString.stringify(parsed2);
            let path = encodeURI(window.location.pathname.replace('/voip/portal/', ''));
            //prevent trying to redirect to nothing
            const parsed = queryString.parse(window.location.search);

            if (path === '/') {
                path = '';
            }
            let loopCount: number = 0;
            if (parsed.loop) {
                loopCount = parseInt(parsed.loop.toString());
            }
            loopCount++;
            parsed.loop = loopCount.toString();
            delete parsed2.loop;

            parsed.redirect = path;
            const stringified = queryString.stringify(parsed);

            let redirectTo = '/login?' + stringified;

            if (loopCount === 1) {
                const notification: INotification = { title: "Error", data: "Access to page denied", variant: NOTIFICATION_TYPE.danger, srcFunction: "componentDidUpdate", srcData: window.location.pathname, srcComponent: 'App' };
                App.newNotification(notification)

            }
            return <Redirect to={redirectTo} />
        } else {
            return <Route {...this.props} />;
        }
    }

} 
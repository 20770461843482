import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";
import { ButtonGroup } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserMinus,
  faPencilAlt,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import PageHead from "../../components/PageHead";
import ResellerRedirect from "../../components/ResellerRedirect";
import { Link, RouteComponentProps } from "react-router-dom";
import Error from "../../components/error";
import image from "../../compiledSVG/undraw_people_search_wctu.svg";
import { IWallboardLayout } from "../../interfaces/wallboards";
import MuiDropdownButton, { IDropdownButtonProps } from "../../components/MuiComponents/MuiDropdownButton";
import App from "../../App";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";

library.add(faUserMinus, faPencilAlt, faUserPlus);

const set = new MySettings();

const WallboardsListPage: React.FC<
  RouteComponentProps<{
    rid: string;
  }> &
    IPageProps
> = ({ resellerID, session, match }) => {
  const [syncingWallboards,setSyncingWallboards] = useState(false);
  const [wallboards, setWallboards] = useState<IWallboardLayout[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // console.log("STATE", wallboards);
  // console.log("PROPS", match);

  useEffect(() => {
    let isMounted = true;

    const getWallboards = async () => {
      try {
        const data: IWallboardLayout[] = (
          await set.wallboardsGet(resellerID ? match.params.rid : "")
        ).items;
        if (isMounted) {
          setWallboards(data);
          setLoading(false);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };

    getWallboards();

    return () => {
      isMounted = false;
    };
  }, [match.params.rid, resellerID]);

  const table = [
    {
      Header: "Name",
      accessor: "name",
      sortable: true,
    },
    {
      Header: "",
      accessor: "id", // String-based value accessors!
      Cell: (props: any) => {
        let numberEditLink = "../wallboard/" + props.value;
        let wallboardViewLink = "/voip/wallboard.jsp"
        if(resellerID){
          numberEditLink = './wallboard/'+props.value
          wallboardViewLink = wallboardViewLink + "?customersId=" + match.params.rid
        }
        wallboardViewLink = wallboardViewLink + "#" + props.value;
        return (
          <ButtonGroup aria-label="Basic example">
            {(wallboards.length>0 && wallboards[0].canEdit) && 
              <Link to={numberEditLink} className="btn btn-success">
                Edit <FaIcon icon="pencil-alt" color="white" />
              </Link>}
            <a
              onClick={()=>{document.cookie="wallboard=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/voip;"}}
              target="_blank"
              rel="noopener noreferrer"
              href={wallboardViewLink}
              className="btn btn-primary"
            >
              View
            </a>
          </ButtonGroup>
        );
      },
    },
  ];
  const dropdownOptions: IDropdownButtonProps["options"] = [
    { target:'', label: "Synchronise Wallboards", onClick:async ()=>{
      setSyncingWallboards(true)
      try{
        await set.wallboardsSynchronise(resellerID ? match.params.rid : "",false)
        const notification: INotification = {
          title: "Wallboard Synchronise",
          data: "The wallboards have been synchronised. The data should update in the next five minutes.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "",
          srcData: "success",
          srcFunction: "",
        };
        App.newNotification(notification)
      }catch(err){
        App.newNotificationError('There was a problem synchronising the wallboards.')
      }
      setSyncingWallboards(false)
    } },{ target:'', label: "Synchronise Wallboards And Reset Stats", onClick:async ()=>{
      setSyncingWallboards(true)
      try{
        await set.wallboardsSynchronise(resellerID ? match.params.rid : "",true)
        const notification: INotification = {
          title: "Wallboard Synchronise",
          data: "The wallboards have been synchronised. The data should update in the next five minutes.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "",
          srcData: "success",
          srcFunction: "",
        };
        App.newNotification(notification)
      }catch(err){
        App.newNotificationError('There was a problem synchronising the wallboards.')
      }
      setSyncingWallboards(false)
    } }
  ];
  return (
    <div className="content">
      <PageHead
        resellerID={resellerID}
        title="Wallboards"
        parents={[]}
      >
        {syncingWallboards ? (
          <Loading />
        ) : (
          <MuiDropdownButton label="More Options" options={dropdownOptions} />
        )}
      </PageHead>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : wallboards.length > 0 ? (
        <>
          {resellerID === undefined &&
          session.userType === "resellersContacts" ? (
            <ResellerRedirect />
          ) : (
            <VoipTable
              title="wallboards"
              data={wallboards}
              col={table}
              sortByStart="name"
              multiSelect={false}
            />
          )}
        </>
      ) : (
        <div className="text-center">
          <img src={image} className="img-fluid" alt="No Results Found" />
          <br />
          <br />
          <h2>No wallboards found.</h2>
        </div>
      )}
    </div>
  );
};
export default WallboardsListPage;

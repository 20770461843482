
import MySettings from "../../static/settings";
import { RouteComponentProps } from "react-router-dom";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";

import PageHead from "../../components/PageHead";
import Error from "../../components/error";
import { ITariff } from "../../interfaces/customer";
import EditIcon from '@material-ui/icons/Edit';
import VipBurgerMenu from "../../components/VipBurgerMenu";
import { useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";

export interface ITariffState {
  currentSearch: any;
  loading: boolean;
  tariffs: ITariff[];
  error: boolean;
}

interface ITariffs extends RouteComponentProps<any>, IPageProps { }
const set = new MySettings();



const Tariffs = ({ resellerID }: ITariffs) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tariffs, setTariffs] = useState<ITariff[]>()
  const col: any[] = [
    {
      Header: "Tariff Name",
      accessor: "name",
      sortable: true,
    },
  ];

  col.push({
    Header: "",
    accessor: "id", // String-based value accessors!
    Cell: (props: any) => {
      let link: string = "./tariff/" + props.value;

      return (
        <Button variant="outlined" href={link} color="primary" endIcon={<EditIcon />}>
          Edit Tariff
        </Button>
      );
    },
  });

  const mounted = useRef(false)


  useEffect(() => {
    const Go = async () => {
      try {
        const result = await set.resellerCustomerTariffs('', false)
        setTariffs(result.items)
        setLoading(false)
      } catch (ex) {
        setError(true)
      }
    }
    if (mounted.current === false) {
      mounted.current = true;
      Go();
    }
  }, []);
  const linkN = "./tariff/new";
  const linkST = "./standardTariff";
  return <div className="content">
    <PageHead
      resellerID={resellerID}
      title="Tariffs"
      parents={[]}
      subpages={[]}
    >
      <VipBurgerMenu
        options={[
          { title: "Add Tariff", link: linkN, isExternal: false },
          { title: "Standard Tariff", link: linkST, isExternal: false },
        ]}
      />
    </PageHead>
    {error ? (
      <Error />
    ) : loading ? (
      <Loading />
    ) : (
      <>
        {tariffs && <VoipTable
          title="tariffs"
          sortByStart="name"
          data={tariffs}
          col={col}
          multiSelect={false}
        />}
      </>
    )}
  </div>
}

export default Tariffs
import React, { useEffect, useState } from "react";

import { Card, Col, Row } from "react-bootstrap";

import PageHead from "../../components/PageHead";
import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import MySettings from "../../static/settings";

import Error from "../../components/error";

import { RouteComponentProps } from "react-router-dom";
import VipBurgerMenu, { IBurgerOption } from "../../components/VipBurgerMenu";
import VoipTable from "../../components/VoipTable";
import {
  ICreditNoteGetResponse,
  IResellerCreditNote,
} from "../../interfaces/reseller-invoice";
import { format, parseISO } from "date-fns";

const set = new MySettings();

interface IResellerCreditNotesPage
  extends RouteComponentProps<any>,
    IPageProps {}

const ResellerCreditNotePage: React.FC<IResellerCreditNotesPage> = ({
  match,
  resellerID,
}) => {
  const [creditNote, setCreditNote] = useState<IResellerCreditNote>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const getCreditNote = async () => {
      try {
        const noteGetResponse: ICreditNoteGetResponse = await set.creditNoteGet(
          match.params.id
        );

        if (isMounted) {
          setCreditNote(noteGetResponse.item);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };

    getCreditNote();

    return () => {
      isMounted = false;
    };
  }, [match.params.id]);

  let burgerOpts: IBurgerOption[] = [];
  burgerOpts.push({
    title: "PDF Download",
    link:
      creditNote &&
      "/voip/pdfs?id=" + creditNote.id + "&collection=creditNotes",
    isExternal: true,
  });

  const generateMarkup = (string: string) => {
    return { __html: string.replace(/\n/g, "<br />") };
  };

  const col = [
    {
      Header: "Qty",
      accessor: "qty",
      sortable: true,
    },
    {
      Header: "Name",
      accessor: "name",
      sortable: true,
    },
    {
      Header: "Unit Value",
      accessor: "value",
      sortable: true,
    },
    {
      Header: "Sub Value",
      accessor: "subValue",
      sortable: true,
    },
    {
      Header: "VAT",
      accessor: "vatValue",
      sortable: true,
    },
    {
      Header: "Total",
      accessor: "totalValue",
      sortable: true,
    },
  ];

  return (
    <div className="content">
      <Col md="12" className="text-left">
        <PageHead
          resellerID={resellerID}
          isHome={false}
          customersMode={true}
          title={creditNote ? creditNote.displayNumber : "Credit Notes"}
          parents={[{ title: "Credit Notes", url: "../creditnotes" }]}
        >
          <VipBurgerMenu options={burgerOpts} />
        </PageHead>

        {loading ? (
          <Loading />
        ) : error ? (
          <Error />
        ) : (
          creditNote && (
            <Card>
              <Card.Header>
                <p>Number: {creditNote.displayNumber}</p>
                <p>Date: {format(parseISO(creditNote.date), "dd/MM/yyyy")}</p>
                <p>Status: {creditNote.status}</p>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <p>Credit Note</p>
                    <p
                      dangerouslySetInnerHTML={generateMarkup(
                        creditNote.invoiceAddress
                      )}
                    ></p>
                  </Col>
                  <Col className="text-center">
                    <p>Delivery</p>
                    <p
                      dangerouslySetInnerHTML={generateMarkup(
                        creditNote.deliveryAddress
                      )}
                    ></p>
                  </Col>
                  <Col className="text-right">
                    <p>Sender</p>
                    <p
                      dangerouslySetInnerHTML={generateMarkup(
                        creditNote.senderAddress
                      )}
                    ></p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2>Credit Note Items</h2>
                    <VoipTable
                      title="Credit Note Items"
                      data={creditNote.lines}
                      col={col}
                      multiSelect={false}
                      showResultsCount={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ offset: 0, span: 10 }}
                    className="paddedTop text-center"
                  >
                    {creditNote.paymentTerms}
                  </Col>
                  <Col md={{ offset: 0, span: 2 }}>
                    <div className="d-flex flex-rowd-flex justify-content-between">
                      <div>
                        <b>Sub-Total</b>
                      </div>
                      <div> £{creditNote.subValue.toFixed(2)}</div>
                    </div>

                    <div className="d-flex flex-rowd-flex justify-content-between">
                      <div>
                        <b> VAT @ 20%</b>
                      </div>
                      <div> £{creditNote.vatValue.toFixed(2)}</div>
                    </div>

                    <div className="d-flex flex-rowd-flex justify-content-between">
                      <div>
                        <b>Total</b>
                      </div>
                      <div> £{creditNote.totalValue.toFixed(2)}</div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )
        )}
      </Col>
    </div>
  );
};
export default ResellerCreditNotePage;

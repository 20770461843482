import React, { useEffect, useState } from "react";
import MySettings, { ISuccessAPIResponse } from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import PageHead from "../../components/PageHead";
import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";
import {
  IPSAProduct,
  IResellerPSAProductMapping,
} from "../../interfaces/products";

const set = new MySettings();

interface RouteParams {}

interface IResellerPSAProductMatchingFormProps {
  product: IResellerPSAProductMapping;
  psaProducts: IPSAProduct[];
}

const ResellerPSAProductMatchingForm: React.FC<IResellerPSAProductMatchingFormProps> =
  (props) => {
    const [loading, setLoading] = useState(false);
    const [psaProductId, setPSAProductId] = useState<string>(
      props.product.connectwiseManageProductId
    );
    const onPSAProductChange = (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      setPSAProductId(event.currentTarget.value);
    };

    const productToOption = function (product: IPSAProduct) {
      return (
        <option key={product.id} value={product.id}>
          {product.identifier}
        </option>
      );
    };

    const onSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      try {
        await set.resellerPSAProductMappingUpdate(
          props.product.productsId,
          psaProductId
        );
        const notification: INotification = {
          title: "Product Mapping Updated",
          data: "The PSA product has been mapped.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "ResellerPSAProductMatchingPage",
          srcData: "success",
          srcFunction: "onSubmit",
        };
        App.newNotification(notification);
      } catch (err) {
        App.newNotificationError(
          "Failed to update the PSA mapping " + err.data
        );
      }
      setLoading(false);
    };

    return (
      <Form onSubmit={onSubmit}>
        <Row>
          <Col xs={4}>{props.product.name}</Col>
          <Col xs={4}>
            <Form.Control
              as="select"
              value={psaProductId}
              onChange={onPSAProductChange}
            >
              {props.psaProducts.map(productToOption)}
            </Form.Control>
          </Col>
          <Col>
            {loading ? (
              <Loading />
            ) : (
              <Button variant="primary" type="submit">
                Update Configuration
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    );
  };

const ResellerPSAProductMatchingPage: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = () => {
  const [vipvoipProducts, setVipVoipProducts] = useState<
    IResellerPSAProductMapping[]
  >([]);
  const [psaProducts, setPSAProducts] = useState<IPSAProduct[]>([]);
  const [loading, setLoading] = useState(true);

  const getSettings = async (isMounted: ISuccessAPIResponse) => {
    setLoading(true);
    try {
      const data = await set.resellerPSAProductsGetNamePartial("");
      const psaproducts = await set.resellerPSAProductsGet();
      psaproducts.sort((a, b) => {
        return a.identifier.localeCompare(b.identifier);
      });
      psaproducts.splice(0, 0, { id: "", identifier: "Not matched" });
      if (isMounted.success) {
        setVipVoipProducts(data);
        setPSAProducts(psaproducts);
      }
    } catch (error) {
      App.newNotificationError("Failed to load PSA products " + error.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    let isMounted: ISuccessAPIResponse = {
      success: true,
      message:''
    };

    getSettings(isMounted);

    return () => {
      isMounted.success = false;
    };
  }, []);

  const onRefreshClick = () => {
    getSettings({ success: true,message:'' });
  };

  return (
    <div className="content">
      <PageHead title="PSA Product Matching" parents={[]}></PageHead>

      {loading ? (
        <Loading />
      ) : (
        <Row className="whiteBox">
          <Col md="12" lg="12">
            <Card>
              <Card.Header>
                <h2>PSA Product Matching</h2>
              </Card.Header>
              <Card.Body className="form-card-body">
                {vipvoipProducts.map((product) => (
                  <ResellerPSAProductMatchingForm
                    key={product.id}
                    product={product}
                    psaProducts={psaProducts}
                  />
                ))}
              </Card.Body>
              <Card.Footer>
                <Button onClick={onRefreshClick}>Refresh</Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ResellerPSAProductMatchingPage;

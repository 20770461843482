
import MySettings from "../../static/settings";
import { useState } from "react";
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";
import { Box, ButtonGroup, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import App from "../../App";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import VipStringList from "../../components/VipStringList";
import MuiModal from "../../components/modal/MuiModal";
import CallRecordingButtons from "./CallRecordingButton";
import { CallRecPageState } from "./CallRecording";

interface iCallRecordingTable {
  customersSitesServicesId: string
  results: any[];
  setPageState: (state: CallRecPageState) => void
  setResults: (state: any[]) => void
  reload: () => Promise<any>;
}
const set = new MySettings();
const CallRecordingTable = ({ customersSitesServicesId, results, setPageState, setResults, reload }: iCallRecordingTable) => {

  const [recordings_emails, setRecordings_emails] = useState<any[]>([]);
  const [activePlayer, setActivePlayer] = useState('');
  const [recordingModal, setRecordingModal] = useState(false);
  const [page, setPage] = useState(0);

  const [recordings, setRecordings] = useState<any[]>([]);



  const updateRecordingEmail = (field: string, payload: any) => {
    console.log("updateRecordingEmail", field, payload);
    //   setData({ ...data, recordings_emails: payload });
    setRecordings_emails(payload)
  };


  const setProtection = async (id: string, isProtected: boolean) => {
    try {
      if (results) {


        setPageState(CallRecPageState.Loading);
        const response = await set
          .callRecordingSetProtected(customersSitesServicesId, id, isProtected)

        //this.setState({ loading: false });
        const copy = [...results];
        const index = results.findIndex((xs) => xs.id === id);
        copy[index] = response;


        setResults(copy)
        setPageState(CallRecPageState.ShowTable)
      }
    } catch {
      setPageState(CallRecPageState.NetworkError)
    }

    //   this.setState({ results: copy });
  }
  const deleteRecord = async (id: string) => {
    try {
      if (results) {


        setPageState(CallRecPageState.Loading);
        await set.callRecordingRemove(customersSitesServicesId, id)
        const copy = [...results];
        const index = results.findIndex((xs) => xs.id === id);
        copy.splice(index, 1);
        setResults(copy);
        setPageState(CallRecPageState.ShowTable)
        const notification: INotification = {
          title: "Success",
          data: "Recording has been deleted.",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      }

    } catch {
      setPageState(CallRecPageState.NetworkError)
    }

    //   this.setState({ results: copy });
  }




  const deleteMultipleRecords = async (ids: string[]) => {
    try {

      if (ids.length === 0) {
        const notification: INotification = {
          title: "Failed",
          data: "No recording have been selected.",
          variant: NOTIFICATION_TYPE.danger,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      } else {
        setPageState(CallRecPageState.Loading);
        const resp = await set.callRecordingsRemove(customersSitesServicesId, ids)

        if (resp.success === true) {
          const notification: INotification = {
            title: "Success",
            data: "Recording's have been deleted.",
            variant: NOTIFICATION_TYPE.info,
            srcFunction: "componentDidUpdate",
            srcData: window.location.pathname,
            srcComponent: "App",
          };
          App.newNotification(notification, true);
          await reload()
        } else {
          const notification: INotification = {
            title: "Failed",
            data: "Recording's have failed to delete.",
            variant: NOTIFICATION_TYPE.danger,
            srcFunction: "componentDidUpdate",
            srcData: window.location.pathname,
            srcComponent: "App",
          };
          App.newNotification(notification, true);
        }

      }

    } catch (exc) {
      console.log(exc)
      setPageState(CallRecPageState.NetworkError)
    }
  };

  const downloadMulti = (ids: string[]) => {
    if (ids.length === 0) {
      const notification: INotification = {
        title: "Failed",
        data: "No recording have been selected.",
        variant: NOTIFICATION_TYPE.danger,
        srcFunction: "componentDidUpdate",
        srcData: window.location.pathname,
        srcComponent: "App",
      };
      App.newNotification(notification, true);
    } else {
      window.open(`/voip/callRecordings?ids=${JSON.stringify(ids)}`, "_blank");
    }
  };

  const emailCallRecordings = async (emails: string[]) => {
    try {
      if (recordings) {




        const dd = await set.callRecordingsEmail(customersSitesServicesId, recordings, emails)

        console.log("callRecordingEmail", dd);
        setRecordings([]);
        setRecordings_emails([])
        const notification: INotification = {
          title: "Success",
          data: "Recording has been emailed.",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "emailRecording",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      }

    } catch (ex: any) {
      setRecordings([]);
      setRecordings_emails([])
      const notification: INotification = {
        title: "Error",
        data: "Failed to email recording: " + ex.data,
        variant: NOTIFICATION_TYPE.danger,
        srcFunction: "emailRecording",
        srcData: window.location.pathname,
        srcComponent: "App",
      };
      App.newNotification(notification, true);
    }

    //   this.setState({ results: copy });
  }


  const columns = [
    // {
    //     name: "id",
    //     label: "Date",
    //     options: {
    //         filter: true,
    //         sort: true,

    //     },
    // },
    {
      name: "datetime",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => {
          return value.replace("T", " ")
        }
      },
    },
    {
      name: "source",
      label: "Source",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "destination",
      label: "Destination",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ddi",
      label: "DDI",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "extensionName",
      label: "Agent",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "id",
      label: " ",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
          console.log(rowIndex, dataIndex)
          if (results) {
            const recording = results[dataIndex];
            const downloadHref =
              MySettings.serverBase +
              "voip/callRecordings?customersSitesServicesId=" + recording.customersSitesServicesId + "&id=" +
              recording.id +
              "&html5=1";
            const playerHref =
              MySettings.serverBase +
              "voip/callRecordings?customersSitesServicesId=" + recording.customersSitesServicesId + "&id=" +
              recording.id +
              "&html5=1&mobile=1";
            return <CallRecordingButtons activePlayer={activePlayer} setActivePlayer={setActivePlayer} downloadHref={downloadHref} playerHref={playerHref} recording={recording} setProtection={setProtection} deleteRecord={deleteRecord} setRecordingModal={setRecordingModal} setRecordings={setRecordings} />


          } else {
            return <>


            </>
          }
        }
      },
    },
  ];

  return <>
    <MuiTableWrapper
      title=""
      data={results}
      columns={columns}
      options={{
        page,
        filterType: "checkbox",
        selectableRows: "multiple",
        responsive: "standard",
        selectToolbarPlacement: "replace",
        onChangePage: (page: number) => { setPage(page) },
        customToolbarSelect: (selectedRows) => {
          //    console.log(selectedRows.data)
          return <ButtonGroup>
            <IconButton title="Download" onClick={(ev) => {
              ev.preventDefault();

              if (results) {
                const ids: string[] = selectedRows.data.map(i => {
                  const index = i.dataIndex;
                  return results[index].id
                })

                downloadMulti(ids)
              }
            }}>
              <GetAppIcon />
            </IconButton>
            <IconButton title="Delete" onClick={(ev) => {
              ev.preventDefault();
              const ids: string[] = selectedRows.data.map(i => {
                const index = i.dataIndex;
                return results[index].id
              })

              deleteMultipleRecords(ids)
            }}>
              <DeleteIcon />
            </IconButton>
          </ButtonGroup>
        },
        elevation: 0,
        print: false,
        download: false,
        search: true,
        filter: false,
        viewColumns: false,
        sortOrder: {
          name: "datetime",
          direction: "asc",
        },
      }}
    />

    <MuiModal
      open={recordingModal}
      title="Email Recording"
      onClose={() => setRecordingModal(false)}

    >

      <Box style={{ width: "100%", padding: 10 }}>

        <div style={{ marginTop: "1em" }}>
          {(recordings_emails && recordings_emails.length===0) ? (<div style={{ textAlign: "center" }}>
            No email addresses chosen yet.<br />Please add an email address using the box below.
          </div>) : (<div></div>)}
          
          {recordings_emails && <VipStringList
            field="recordings_emails"
            value={recordings_emails}
            updateField={updateRecordingEmail}
            text="Email"
            placeholder="email@sommewhere.com"
            additionalButtonVariant="primary"
            additionalButtonText="Send"
            additionalButtonId="recordings_email_button"
            additionalButtonFunction={emailCallRecordings}
          />}
        </div>

      </Box>
    </MuiModal></>
}



export default CallRecordingTable;
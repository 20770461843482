import React from "react";
import { useParams } from "react-router";
import { IAreaCode } from "../../static/settings";
import { INumberAllocationRequest } from "../../interfaces/number";
import MySettings from "../../static/settings";

import MuiWizard from "../../components/MuiComponents/MuiWizard";
import EhaForm from "./allocateNumberSubforms/ehaForm";
import FindNumbers1 from "./allocateNumberSubforms/findNumbers1";
import FindNumbers2 from "./allocateNumberSubforms/findNumbers2";
import FinishedScreen from "./allocateNumberSubforms/finishedScreen";




const set = new MySettings();
interface AllocateNumberFormProps {
  data: any;
  onSubmit: any;
  areaCodes: IAreaCode[];
  customers: any[];
}

const AllocateNumberForm: React.FunctionComponent<AllocateNumberFormProps> = ({
  data,
  areaCodes,
  customers,
  onSubmit,
}) => {
  let params: any = useParams();

  let arrayOfCodes: any[] = [];

  arrayOfCodes = areaCodes.map((dd: any) => {
    const thing = { value: dd.id, label: dd.city + "(" + dd.id + ")" };
    return thing;
  });


  return (<>

    <MuiWizard

      finishScreen={(data) => {
        return <FinishedScreen data={data} />
      }}

      steps={[
        {
          // visible: (data: any) => {
          //   if (data.ported === 'yes') {
          //     return false
          //   } else if (data.ported === 'no') {
          //     return true
          //   } else {
          //     return false;
          //   }
          // },
          id: 'find1',
          title: "Find",
          formContents: <><FindNumbers1 arrayOfCodes={arrayOfCodes} /></>,
          onSubmit: async (values: any) => {
            console.log(values);
            try {
              const list = await set.resellerNumbersAlist(
                values.numbersAmt,
                values.areaCode.value,
                values.consecutiveNumbers
              )


              values.numbers = [];
              delete values.exception;
              let customersSitesId = ''
              if (params.rid && params.rid.length > 0) {
                try {
                  const result = await set.sitesGet(params.rid, true, false);

                  result.items.sort((a, b) => { return a.description.localeCompare(b.description) })
                  //values.sites = result.items;
                  //first search for main
                  for (let i = 0; i < result.items.length; i++) {
                    if (result.items[i].description === 'Main') {
                      customersSitesId = result.items[i].id
                    } else if (i === 0 && customersSitesId.length === 0) {
                      customersSitesId = result.items[i].id;
                    }
                  }
                } catch (err) {

                }
              }

              for (let index = 0; index < list.items.length; index++) {
                const num: INumberAllocationRequest = {
                  number: list.items[index],
                  customersId: params.rid ? params.rid : '',
                  customersSitesId: customersSitesId,
                };
                values.numbers.push(num);
              }
              return values;
            }
            catch (exception: any) {
             
              values.exception = exception;
              delete values.numbers;
              throw exception.data;
            };
          }
        },
        {
          // visible: (data: any) => {
          //   if (data.ported === 'yes') {
          //     return false
          //   } else if (data.ported === 'no') {
          //     return true
          //   } else {
          //     return false;
          //   }
          // },
          id: 'find2',
          title: "Allocate",
          formContents: <FindNumbers2 customers={customers} />,
          onSubmit: async (values) => {
            try {
              const array: INumberAllocationRequest[] = [];
              for (let index = 0; index < values.numbers.length; index++) {
                const element = values.numbers[index];
                let request: INumberAllocationRequest = {
                  customersId: element.customersId,
                  customersSitesId: element.customersSitesId,
                  number: element.number,
                };

                array.push(request);
              }




              let res = await set.resellerNumbersAllocation(array, values.consecutiveNumbers)
              values.toEHA = [];


              for (let index = 0; index < res.items.length; index++) {
                const element = res.items[index];



                const selectedCustomer = customers.filter(
                  customer => customer.id === values.numbers[index].customersId
                );
                    console.log(element)
                try {
                  element.number.ehaPremises = element.site?.line1;
                  element.number.ehaThoroughfare = element.site?.line2;
                  element.number.ehaLocality = element.site?.city;
                  element.number.ehaPostcode =element.site?.postCode;

                  element.number.ehaName = selectedCustomer[0].name;
                } catch (err) {
                  //PBB 2023-07-25 this seems broken but I don't have time to fix it now
                }

                values.toEHA.push(element);
              }
              return values;
            } catch (exception: any) {
             
              throw exception.data;
            }
          }
        },
        {
          id: 'eha',
          title: 'EHA',
          formContents: <><EhaForm /></>,
          onSubmit: async (values) => {
            const returnD: any[] = [];
            for (const value of values.toEHA) {

              try {
                console.log(value);
                const number = await set.numberEHAUpdate(value.number)
                console.log(number);
                value.NUMBER = number;
              } catch (exception: any) {
                value.ERROR = exception;
              }


              returnD.push(value)
            }
            values.return = returnD
            return values;
          }
        },


      ]} />
  </>);
};

export default AllocateNumberForm;
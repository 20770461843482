import React, { useEffect, useState } from "react";
import Joyride, { STATUS } from "react-joyride";

import create from "zustand";
import App from "../../App";
import { IMenuHttpResponse } from "../../interfaces/menu-option";
import MySettings from "../../static/settings";
import Loading from "../loading";

const set = new MySettings();

interface State {
  opened: boolean;
  remoteControl: boolean;
  open: () => void;
  close: () => void;
  remoteOn: () => void;
  remoteOff: () => void;
}

export const useStore = create<State>((set) => ({
  opened: false,
  remoteControl: false,
  open: () => set(() => ({ opened: true })),
  close: () => set(() => ({ opened: false })),
  remoteOn: () => set(() => ({ remoteControl: true })),
  remoteOff: () => set(() => ({ remoteControl: false })),
}));

const TwoFaJoyride = () => {
  const openDropdown = useStore((state) => state.open);
  const closeDropdown = useStore((state) => state.close);
  const remoteOn = useStore((state) => state.remoteOn);
  const remoteOff = useStore((state) => state.remoteOff);
  const [showJoyride, setShowJoyride] = useState(false);
  const [index, setIndex] = useState();
  const [loading, setLoading] = useState(true);
  const [steps] = useState([
    {
      target: "body",
      locale: { skip: <strong aria-label="skip">SKIP</strong> },
      placement: "center" as const,
      disableOverlayClose: true,
      content:
        "Thank you for signing in to the portal. We'll quickly show you some tips to help you get started.",
    },
    {
      target: "a.profileLink",
      placement: "left" as const,
      disableOverlayClose: true,
      content:
        "To update your password or enable 2FA please click the profile button.",
    },
  ]);

  const handleJoyrideCallback = async (data: any) => {
    const { index, status } = data;

    setIndex(index);

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      remoteOff();
      closeDropdown();
      await set.toursUpdate({ twoFA: true });
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getMenuOpts = async () => {
      try {
        const result = await set.doAPIRequest<IMenuHttpResponse>(
          set.getMenu(),
          {}
        );

        if (isMounted) {
          if (!result.tours.twoFA) {
            remoteOn();
            openDropdown();
            setShowJoyride(true);
          }
          setLoading(false);
        }
      } catch (error) {
        App.newNotificationError("Problem loading tour.");
      }
    };

    getMenuOpts();

    return () => {
      isMounted = false;
      remoteOff();
      closeDropdown();
    };
  }, [closeDropdown, openDropdown, remoteOff, remoteOn]);

  return loading ? (
    <Loading />
  ) : showJoyride ? (
    <Joyride
      callback={handleJoyrideCallback}
      steps={steps}
      continuous
      locale={{ last: "Finish" }}
      showSkipButton
      showProgress={index === 0 ? true : false}
      styles={{
        options: {
          primaryColor: "#1D57D0",
          zIndex: 1000,
        },
      }}
    />
  ) : null;
};

export default TwoFaJoyride;

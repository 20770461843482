import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { ProgressBar } from "react-bootstrap";
import { ITariff } from "../interfaces/customer";
import Loading from "./loading";

interface IWizardNewUserProps {

  initialValues: any;
  onSubmit(data: any): void;
  menuBottom?: boolean;
  pageMenu?: boolean;
  startIndex?: number;
  tariffs: ITariff[];
}
interface IWizardNewUserState {
  loading: boolean;
  values: any;
  page: number;
  pageMenu: boolean;
  menuBottom: boolean;
  startIndex: number;
}
class WizardNewUser extends React.Component<IWizardNewUserProps, IWizardNewUserState> {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }: any) => children;

  constructor(props: any) {
    super(props);

    let menuBottom: boolean = true;
    if (props.menuBottom !== undefined) {
      menuBottom = props.menuBottom;
    }
    let pageMenu: boolean = false;
    if (props.pageMenu !== undefined) {
      pageMenu = props.pageMenu;
    }
    let startIndex: number = 0;
    if (props.startIndex !== undefined) {
      startIndex = props.startIndex;
    }
    this.state = {
      loading: false,
      page: startIndex,
      pageMenu: pageMenu,
      menuBottom: menuBottom,
      startIndex: startIndex,
      values: props.initialValues || {},
    };
  }
  public updateValues = (thing: string, something: any) => {
    console.log("updateValues", thing, something)

  }
  next = (values: any, goto?: string) => {
    if (goto === 'SUBMIT_NOW') {
      this.props.onSubmit(values);
    } else {
      const children = React.Children.toArray(this.props.children);
      console.log("Wizard:next", values, goto, children);
      if (children !== undefined && children !== null) {
        if (goto !== undefined) {
          const ind = children.findIndex((something: any) => {
            return something.props.n === goto;
          });
          this.setState((state: any) => ({
            page: ind,
            values,
          }));
        } else {
          this.setState((state: any) => ({
            page: Math.min(state.page + 1, children.length - 1),
            values,
          }));
        }
      }
    }

  };

  gotoPage = (goto: string) => {
    const children = React.Children.toArray(this.props.children);
    const ind = children.findIndex((something: any) => {
      return something.props.n === goto;
    });
    this.setState((state: any) => ({
      page: ind,
    }));
  }

  previous = () => {
    this.setState((state: any) => ({
      page: Math.max(state.page - 1, 0),
    }));
  }

  validate = (values: any) => {
    const activePage: any = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    if (activePage !== undefined && activePage !== null) {
      return activePage.props.validate ? activePage.props.validate(values) : {};
    }
  };

  handleSubmit = (values: any) => {
    //  console.log("Wizard:handelSubmit", values.SUBMITNOW);
    const activePage: any = React.Children.toArray(this.props.children)[
      this.state.page
    ];

    if (activePage !== undefined && activePage !== null) {
      // if theres a custom on submit
      if (activePage.props.onSubmit !== undefined) {
        activePage.props.onSubmit(values, (valuesS: any, goto?: string) => {
          values = valuesS;
          const { children, onSubmit } = this.props;
          const { page } = this.state;
          let isLastPage = page === React.Children.count(children) - 1;

          if (isLastPage) {
            return onSubmit(values);
          } else {
            this.next(values, goto);
          }
        });
      } else {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        let isLastPage = page === React.Children.count(children) - 1;

        if (isLastPage) {
          return onSubmit(values);
        } else {
          this.next(values);
        }
      }
    }
  };

  render() {
    const { children } = this.props;

    const { page, values } = this.state;
    const activePage: any = React.Children.toArray(children)[page];
    //  console.log(activePage);
    let r: any = null;
    if (activePage.props.render) {
      r = activePage.props.render;
    }

    let isLastPage = page === React.Children.count(children) - 1;

    const complete = (page / (React.Children.count(children) - 1)) * 100
    return (<>
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({
          handleSubmit,
          form: {
            mutators: { push, pop },
          }, // injected from final-form-arrays above
          pristine,
          form,
          submitting,
          values,
        }) => {
          React.Children.toArray(this.props.children).map((u: any) => {
            //  console.log(u);
            let n: any = {};
            if (u !== null && u !== undefined && u.props !== undefined) {
              if (u.props.n !== undefined) {
                n.n = u.props.n
              }
              if (u.props.nt !== undefined) {
                n.nt = u.props.nt
              }
            }

            return n;
          })
          //console.log(page)




          return (<>
            {this.state.loading === true ? (<Loading />) : (<>
              <form onSubmit={handleSubmit}>
                <section className="wizard2__body  container-wizard">

                  <div className="wizard1">

                    <div className="status">

                      <div className="status__top">

                        <ul>
                          <p className="status__heading">Customer Setup</p>

                          <li>
                            <button className="status__item buttonLink" onClick={(e) => {
                              e.preventDefault();
                              if (page > 1) this.setState({ page: 1 });
                            }}>
                              <span className={`${page === 1 ? "menu-active" : `${page > 1 ? "menu-done" : ``}`}`}>1</span>Company Details</button>
                          </li>
                          <li>
                            <button className="status__item buttonLink" onClick={(e) => {
                              e.preventDefault();
                              if (page > 2) this.setState({ page: 2 });
                            }}>
                              <span className={`${page === 2 ? "menu-active" : `${page > 2 ? "menu-done" : ``}`}`}>2</span>Invoice Details</button>
                          </li>
                          <li>
                            <button className="status__item buttonLink" onClick={(e) => {
                              e.preventDefault();
                              console.log("fdsfd", page)
                              if (page > 3) this.setState({ page: 3 });
                            }}>
                              <span className={`${page === 3 ? "menu-active" : `${page > 3 ? "menu-done" : ``}`}`}>3</span>Permissions</button>
                          </li>
                          <li>
                            <button className="status__item buttonLink" onClick={(e) => {
                              e.preventDefault();
                              if (page > 4) this.setState({ page: 4 });
                            }}>
                              <span className={`${page === 4 ? "menu-active" : `${page > 4 ? "menu-done" : ``}`}`}>4</span>Tariff</button>
                          </li>
                          <li>
                            <button className="status__item buttonLink" onClick={(e) => {
                              e.preventDefault();
                              if (page > 5) this.setState({ page: 5 });
                            }}>
                              <span className={`${page === 5 ? "menu-active" : `${page > 5 ? "menu-done" : ``}`}`}>5</span>Sites</button>
                          </li>
                          <li>
                            <button className="status__item buttonLink" onClick={(e) => {
                              e.preventDefault();
                              if (page > 6) this.setState({ page: 6 });
                            }}>
                              <span className={`${page === 6 ? "menu-active" : `${page > 6 ? "menu-done" : ``}`}`}>6</span>Allocate Numbers</button>
                          </li>
                          <li>
                            <button className="status__item buttonLink" onClick={(e) => {
                              e.preventDefault();
                              if (page > 7) this.setState({ page: 7 });
                            }}>
                              <span className={`${page === 7 ? "menu-active" : `${page > 7 ? "menu-done" : ``}`}`}>7</span>Users</button>
                          </li>
                          <li>
                            <button className="status__item buttonLink" onClick={(e) => {
                              e.preventDefault();
                              if (page > 8) this.setState({ page: 8 });
                            }}>
                              <span className={`${page === 8 ? "menu-active" : `${page > 8 ? "menu-done" : ``}`}`}>8</span>Review</button>
                          </li>
                        </ul>
                      </div>
                      <div className="status__bottom">
                        <div>
                          <div className="status__progress">
                            <p>Progress</p>
                            <p>{complete.toFixed(0)}%</p>
                          </div>

                          <div>
                            <ProgressBar now={complete} />
                          </div>


                        </div>
                      </div>

                    </div>

                    <div className="wizard1__main">

                      <div className="wizard1__main-post">

                        {r !== null ? <>{r(values, push, pop, form)}</> : <>{activePage}</>}


                        
                        <div className="wizard2__right-buttons">

                          {page > 0 ? (
                            <button
                              type="button"
                              className="button__back"
                              onClick={this.previous}
                            >
                              Previous
                            </button>
                          ) : (
                              <div></div>
                            )}
                          {page > 0 && <>{!isLastPage ? (
                            <button className="button__next" type="submit"   disabled={submitting}>
                              Next
                            </button>
                          ) : (
                              <></>
                            )}</>}

                        </div>

                      </div>
                    </div>
                  </div>

                </section>
              </form>
            </>)}</>
          )
        }}
      </Form>
    </>
    );
  }
}

export default WizardNewUser;

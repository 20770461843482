import React, { useEffect, useState } from "react";
import MySettings, { ISuccessAPIResponse } from "../../static/settings";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { IPSAAgreement, IPSACustomer } from "../../interfaces/customer";
import { INotification } from "../../interfaces/notification";
import { IPageProps } from "../../interfaces/page-props";
import { ISiteWithName } from "../../interfaces/site";

const set = new MySettings();

interface RouteParams { }

interface IResellerPSACustomerMatchingFormProps {
  customer: ISiteWithName;
  psaCustomers: IPSACustomer[];
}

const ResellerPSACustomerMatchingForm: React.FC<IResellerPSACustomerMatchingFormProps> =
  (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingAgreements, setLoadingAgreements] = useState(false);
    const [psaCustomerId, setPSACustomerId] = useState<string>(
      props.customer.connectwiseManageCompaniesId
    );
    const [psaAgreementId, setPSAAgreementId] = useState<string>(
      props.customer.connectwiseManageAgreementsId
    );
    const [psaAgreements, setPSAAgreements] = useState<IPSAAgreement[]>([]);

    const onPSACustomerChange = (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      setPSACustomerId(event.currentTarget.value);
      getPSAAgreements(event.currentTarget.value);
    };

    const onPSAAgreementChange = (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      setPSAAgreementId(event.currentTarget.value);
    };

    const customerToOption = function (customer: IPSACustomer) {
      return (
        <option key={customer.id} value={customer.id}>
          {customer.name}
        </option>
      );
    };

    const agreementToOption = function (customer: IPSAAgreement) {
      return (
        <option key={customer.id} value={customer.id}>
          {customer.name}
        </option>
      );
    };

    const getPSAAgreements = async (psaCustomerId: string) => {
      setLoadingAgreements(true);
      try {
        let agreements: IPSAAgreement[] = [];
        if (psaCustomerId != null && psaCustomerId.length > 0) {
          agreements = await set.resellerPSAAgreementsGet(psaCustomerId);
        }
        agreements.splice(0, 0, { id: "", name: "Not matched" });
        setPSAAgreements(agreements);
      } catch (err) {
        App.newNotificationError("Failed to get agreements " + err.data);
      }
      setLoadingAgreements(false);
    };

    const realSubmit = async () => {
      setLoading(true);
      try {
        await set.resellerPSACustomerMappingUpdate(
          props.customer.id,
          psaCustomerId,
          psaAgreementId
        );
        const notification: INotification = {
          title: "Customer Mapping Updated",
          data: "The PSA customer has been mapped.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "ResellerPSACustomerMatchingPage",
          srcData: "success",
          srcFunction: "onSubmit",
        };
        App.newNotification(notification);
      } catch (err) {
        App.newNotificationError(
          "Failed to update the PSA mapping " + err.data
        );
      }
      setLoading(false);
    };

    const onSubmit = (e: React.FormEvent): boolean => {
      e.preventDefault();
      realSubmit();
      return false;
    };

    useEffect(() => {
      getPSAAgreements(props.customer.connectwiseManageCompaniesId);
    }, [props.customer.connectwiseManageCompaniesId]);

    return (
      <Form onSubmit={onSubmit}>
        <Row>
          <Col xs={4}>{props.customer.name}</Col>
          <Col xs={4}>
            <Form.Control
              as="select"
              value={psaCustomerId}
              onChange={onPSACustomerChange}
            >
              {props.psaCustomers.map(customerToOption)}
            </Form.Control>
          </Col>
          <Col>
            {loadingAgreements ? (
              <Loading />
            ) : (
              <Form.Control
                as="select"
                value={psaAgreementId}
                onChange={onPSAAgreementChange}
              >
                {psaAgreements.map(agreementToOption)}
              </Form.Control>
            )}
          </Col>
          <Col>
            {loading ? (
              <Loading />
            ) : (
              <Button variant="primary" type="submit">
                Update Configuration
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    );
  };

const ResellerPSACustomerMatchingPage: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = () => {
  const [vipvoipCustomers, setVipVoipCustomers] = useState<ISiteWithName[]>([]);
  const [psaCustomers, setPSACustomers] = useState<IPSACustomer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [matchedFilter, setMatchedFilter] = useState<string>("notmatched");
  const nameFilter: string = "";

  useEffect(() => {
    //we have to turn isMounted in to an object so that when it is updated in the callback that gets passed correctly through to getSettings
    let isMounted: ISuccessAPIResponse = {
      success: true,
      message:''
    };
    getSettings(isMounted, matchedFilter, nameFilter);
    return () => {
      isMounted.success = false;
    };
  }, [matchedFilter, nameFilter]);

  //we have to pass in the matched filter as using the one from our state doesnt seem to work
  const getSettings = async (
    isMounted: ISuccessAPIResponse,
    matchedFilter: string,
    nameFilter: string
  ) => {
    if (isMounted.success) {
      setLoading(true);
    }
    try {
      const data = await set.resellerCustomersAndSitesGetNamePartial(
        nameFilter
      );
      let newvipCustomers: ISiteWithName[] = [];
      for (var i = 0; i < data.length; i++) {
        if (
          matchedFilter.length === 0 ||
          (matchedFilter === "matched" &&
            data[i].connectwiseManageCompaniesId != null &&
            data[i].connectwiseManageCompaniesId.length > 0) ||
          (matchedFilter === "notmatched" &&
            (data[i].connectwiseManageCompaniesId == null ||
              data[i].connectwiseManageCompaniesId.length === 0))
        ) {
          newvipCustomers.push({ ...data[i] });
        }
      }

      const psacustomers = await set.resellerPSACustomersGet();
      psacustomers.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      psacustomers.splice(0, 0, { id: "", name: "Not matched" });
      if (isMounted.success) {
        setVipVoipCustomers(newvipCustomers);
        setPSACustomers(psacustomers);
      }
    } catch (error) {
      App.newNotificationError(
        "Failed to load PSA configuration " + error.data
      );
    }
    if (isMounted.success) {
      setLoading(false);
    }
  };

  const onMatchFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMatchedFilter(event.target.value);
    getSettings({ success: true,message:'' }, event.target.value, nameFilter);
  };

  // const onNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setNameFilter(event.target.value);
  //   getSettings({ success: true }, matchedFilter, event.target.value);
  // };

  return (
    <div className="content">
      <PageHead title="PSA Customer Matching" parents={[]}></PageHead>

      {loading ? (
        <Loading />
      ) : (
        <Row className="whiteBox">
          <Col md="12" lg="12">
            <Card>
              <Card.Header>
                <h2>PSA Customer Matching</h2>
              </Card.Header>
              <Card.Body className="form-card-body">
                <Row>
                  <Col>
                    {/*<input type="text" value={nameFilter} onChange={onNameFilterChange} />*/}
                  </Col>
                  <Col>
                    <select
                      className="form-control"
                      value={matchedFilter}
                      onChange={onMatchFilterChange}
                    >
                      <option value="">All</option>
                      <option value="matched">Matched</option>
                      <option value="notmatched">Not Matched</option>
                    </select>
                  </Col>
                  <Col></Col>
                </Row>
                {vipvoipCustomers.map((customer) => (
                  <ResellerPSACustomerMatchingForm
                    key={customer.id}
                    customer={customer}
                    psaCustomers={psaCustomers}
                  />
                ))}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ResellerPSACustomerMatchingPage;

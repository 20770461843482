import React, { useState } from "react";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import App from "../App";
import MySettings from "../static/settings";
import Loading from "./loading";

const set = new MySettings();

interface IUrlButtonProps {
  customersContactsId:string
}

const UrlButton: React.FC<IUrlButtonProps> = (props:IUrlButtonProps) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const generateUrl = async () => {
    try {
      setLoading(true);
      const result = await set.customersContactIOSAccountAddURL(props.customersContactsId);
      if(result){
        if(result.url){
          setUrl(result.url);
          setLoading(false);
        }else if(result.error){
          App.newNotificationError(result.error)
        }
      }else{
        App.newNotificationError('There was a problem generating the URL. Please try again later.')
      }
    } catch (error) {
      let message='There was a problem generating the URL. Please try again later.'
      if(error && error.data){
        message=message+' '+error.data
      }
      App.newNotificationError(message)
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Your URL</Popover.Title>
      <Popover.Content>
        {loading ? <Loading size="75px" /> : <strong style={{whiteSpace:'nowrap'}}>{url}</strong>}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="auto" overlay={popover}>
      <Button onClick={generateUrl} disabled={url ? true : false}>
        Generate URL
      </Button>
    </OverlayTrigger>
  );
};

export default UrlButton;

import React from "react";

import { Field, Form } from "react-final-form";
import VipTextFinalField from "../../components/misc/form/VipTextFinalField";
import { ISearchFormProps } from "../../interfaces/search-form";

const CustomersSearchForm: React.FunctionComponent<ISearchFormProps> = (
  props
) => {
  /*
    let arrayOfNumbers: any[] = [];

    arrayOfNumbers = PROP.array.map((dd: any) => {
        const thing = { value: dd.name, label: dd.name };
        return thing;
    });
*/

  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={props.data}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-rowd-flex justify-content-between">
            <div className="input-group">
              <VipTextFinalField
                fieldName="query"
                placeholder="Name"
                required={false}
              />
            </div>
            {props.showArchived && (
              <div className="input-group" style={{padding:0}}>
                <table style={{padding:0,margin:0,position:'relative',top:'-0.7em'}}>
                  <tbody>
                    <tr>
                      <td style={{padding:0,margin:0}}>Show archived?</td>
                      <td style={{padding:0,margin:0}}>
                        <Field type="checkbox" name="showArchived" value="showArchived">
                        {({ input }) => (
                              <input type="checkbox" {...input} />
                        )}
                        </Field>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
            )}
            
            <div>
              <button
                className="btn btn-outline-primary"
                type="submit"
                disabled={submitting}
              >
                Search
              </button>
            </div>
            <div>{props.children}</div>
          </div>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </form>
      )}
    />
  );
};

export default CustomersSearchForm;

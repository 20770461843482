import * as React from "react";
import MySettings from "../../static/settings";
import { Link, RouteComponentProps } from "react-router-dom";

import { IPageProps } from "../../interfaces/page-props";

import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";
import {
  INumber,
  INumbersHttpResponse,
  INumberTrackersGetHttpResponse,
  INumberTracker,
  INumberTrackersGetHttpRequest,
} from "../../interfaces/number";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserMinus,
  faPencilAlt,
  faUserPlus,
  faEdit,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";

import { INotification } from "../../interfaces/notification";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { Button, ButtonGroup } from "react-bootstrap";
import PageHead from "../../components/PageHead";

import Error from "../../components/error";
import catchME from "../../catchME";
import NumberTrackersSearchForm from "./NumberTrackersSearchForm";
import App from "../../App";

library.add(faUserMinus, faPencilAlt, faUserPlus, faEdit, faEye);

export interface INumberTrackersState {
  // currentSearch:INumbersGetHttpRequest
  loading: boolean;
  error: boolean;
  trackers: INumberTracker[];
  activeTracker: INumberTracker;
  showTrackerEdit: boolean;
  customers: any[];
  numbers: string[];
  numberObjects: INumber[];
  trackerSearch: INumberTrackersGetHttpRequest;
}
interface INumberTrackersPage extends RouteComponentProps<any>, IPageProps {}

const set = new MySettings();
export default class NumberTrackersPage extends React.Component<
  INumberTrackersPage,
  INumberTrackersState
> {
  _isMounted: boolean = false;
  constructor(props: any) {
    super(props);

    this.state = {
      error: false,
      trackerSearch: { notes: "", owner: "", area: "" },
      activeTracker: {
        notes: "",
        owner: "",
        area: "",
        uid: "",
        yhc: "",
        numbersId: "",
        numbersName: "",
      },
      showTrackerEdit: false,
      loading: true,
      trackers: [],
      customers: [],
      numbers: [],
      numberObjects: [],
    };
  }

  // take data from form and posts it
  public handleSearch = (data: INumberTrackersGetHttpRequest): void => {
    //this.searchName = data.name
    this.setState({ trackerSearch: data });
    this.getNumberTrackers(data.owner, data.area, data.notes);
  };

  public render() {
    const { loading, trackers, customers, numbers, trackerSearch, error } =
      this.state;

    const col: any[] = [
      {
        Header: "Number",
        accessor: "numbersName",
        sortable: true,
      },
      {
        Header: "Area",
        accessor: "area",
        sortable: true,
      },
    ];

    if (
      this.props.resellerID === undefined &&
      this.props.session.userType !== "customersContacts"
    ) {
      col.push({
        Header: "Customer",
        accessor: "owner",
        sortable: true,
      });
    }
    col.push({
      Header: "Notes",
      accessor: "notes",
      sortable: true,
    });

    col.push({
      Header: "",
      accessor: "id", // String-based value accessors!
      Cell: (props: any) => {
        // console.log(props);

        const numberLink: string = "./number/" + props.obj.numbersId + "/0";
        const trackerLink: string = "./numberTracker/" + props.obj.id;
        return (
          <ButtonGroup aria-label="Basic example">
            <Link to={numberLink} className="btn btn-outline-primary">
              Edit Number
            </Link>

            <Link to={trackerLink} className="btn btn-outline-primary">
              Edit Tracker
            </Link>

            <Button
              variant="outline-danger"
              onClick={(ev: any) => {
                ev.preventDefault();
                this.deleteNumberTracker(props.obj.id);
              }}
            >
              Delete <FaIcon fixedWidth className="redIcon" icon="trash" />
            </Button>
          </ButtonGroup>
        );
      },
    });

    const newTrackerLink = "./numberTracker/new";

    return (
      <div className="content">
        <PageHead
          resellerID={this.props.resellerID}
          title="Number Trackers"
          parents={[{ title: "Numbers", url: "./numbers" }]}
        >
          <Link to={newTrackerLink} className="btn btn-outline-primary">
            New Number Tracker
          </Link>
        </PageHead>
        {error ? (
          <Error />
        ) : loading ? (
          <Loading />
        ) : (
          <>
            <NumberTrackersSearchForm
              data={trackerSearch}
              onSubmit={this.handleSearch}
              numbers={numbers}
              customers={customers}
            />

            <div className="text-left">
              <VoipTable
                title=""
                data={trackers}
                col={col}
                multiSelect={false}
              />
            </div>
          </>
        )}
      </div>
    );
  }

  public resolveData = (data: any) => data.map((row: any) => row);
  componentWillUnmount() {
    this._isMounted = false;
  }
  public componentDidMount = () => {
    this._isMounted = true;

    if (this.props.session.userType === "resellersContacts") {
      if (this.props.resellerID !== undefined) {
        this.getNumberTrackers("", "", "");
      } else {
        this.getNumberTrackers("", "", "");
      }
    } else {
      this.getNumberTrackers("", "", "");
    }
  };

  // get numbers list
  public getNumberTrackers = (
    owner: string,
    area: string,
    notes: string
  ): void => {
    this._isMounted && this.setState({ loading: true });

    this._isMounted &&
      set
        .numberSearch("", "", this.props.resellerID)
        .then((numberD: INumbersHttpResponse) => {
          let customersList: string[] = [];
          let numbersList: string[] = [];
          let numberObjectList: INumber[] = [];
          let customerObjectList: any[] = [];

          numberD.numbers.map((tracker: INumber) => {
            if (numbersList.indexOf(tracker.name) === -1) {
              numbersList.push(tracker.name);
              numberObjectList.push(tracker);
            }

            //  return tracker.owner;

            if (customersList.indexOf(tracker.ownerId) === -1) {
              customersList.push(tracker.ownerId);
              customerObjectList.push({
                id: tracker.ownerId,
                name: tracker.ownerName,
              });
            }
            return false;
          });
          this._isMounted &&
            set
              .numberTrackersGet("", area, notes, this.props.resellerID)
              .then((data: INumberTrackersGetHttpResponse) => {
                //  let list: string[] = [];
                console.log("numberTrackersGet", data);

                this._isMounted &&
                  this.setState({
                    loading: false,
                    trackers: data.items,
                    customers: customerObjectList,
                    numbers: numbersList,
                    numberObjects: numberObjectList,
                  });
              })
              .catch((exception) => {
                catchME(this, exception, true);
              });
        })
        .catch((exception) => {
          catchME(this, exception, true);
        });
  };

  public deleteNumberTracker = (id: string) => {
    // console.log(id);
    const conf = window.confirm("Confirm Delete Tracker?");

    if (conf === true) {
      this._isMounted && this.setState({ loading: true });
      set
        .numberTrackerRemove(id)
        .then((resp) => {
          //console.log(resp);
          let newTrackers = [...this.state.trackers];
          const index = newTrackers.findIndex((e) => e.id === id);
          if (index >= 0) {
            newTrackers.splice(index, 1);
          }
          //  console.log(id, index, newTrackers);
          this.setState({
            loading: false,
            trackers: newTrackers,
          });
          const notification: INotification = {
            title: "Success",
            data: "Number forwarding deleted",
            variant: NOTIFICATION_TYPE.info,
            srcFunction: "componentDidUpdate",
            srcData: window.location.pathname,
            srcComponent: "App",
          };
          App.newNotification(notification, true);
        })
        .catch((exception) => {
          catchME(this, exception, true);
        });
    }
  };
}


import MySettings, { IVoipServiceProvisioningUpdateRequest } from '../static/settings';

import { IPageProps } from '../interfaces/page-props';

import Loading from '../components/loading';

import PageHead from '../components/PageHead';
import Error from "../components/error";

import { RouteComponentProps } from 'react-router-dom';
import FormProvisioning from '../components/oldForms/form-provisioning';
import VipCheckbox from '../components/VipCheckbox';
import { CallRecordingsUnits, IVoipService } from '../interfaces/interfaces';
import { IProvisioningSettings } from '../interfaces/provisioning';
import { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { INotification } from '../interfaces/notification';
import { NOTIFICATION_TYPE } from '../enum/notification-type';
import App from '../App';

const set = new MySettings();
// cos one place is better than 2

const blankServ: IVoipService = {
	lowBalanceAlertThreshold: 0, prepayCutOffLimit: 0, prepay: false, status: '', eurosoftCallRecordingFTPServerUseFTPS: false, callRecordingWebHookAddress: '', callRecordingWebHookToken: '', vaultGrowsIndefinitely: false, vaultSize: 0,
	eurosoftCallRecordingFTPServerPort: 0, eurosoftCallRecordingFTPServerUsername: '', eurosoftCallRecordingFTPServerPassword: '', eurosoftCallRecordingFTPServerPath: '',
	defaultSearchInDays: 0, measureSizeBy: CallRecordingsUnits.Gigabytes, excludedExtensions: [], eurosoftCallRecordingFTPServerAddress: '',
	name: '', id: '', siteName: '', useCallerIdAsLabel: false, provisioningSettings: { yealinkFirmwares: [], customFields: [], extraAccounts: [] }, hostname: '', vipHostname: '', sipPort: '', iaxPort: '', tlsPort: ''
};


export interface IVoipServiceProvisioningState {
	data: IVoipService;
	loading: boolean;
	settings: IProvisioningSettings;
}

interface IVoipServiceProvisioningPage extends RouteComponentProps<any>, IPageProps {
	/* other props for ChildComponent  
	reloadMenu() :Promise<boolean>;
	menuOptions: IMenuOption[];
	branding: IBranding;*/
}
export enum VoipServiceProvisioningPageState {
	ShowTable,
	Loading,
	NetworkError,
	//NoResults,
  }

const VoipServiceProvisioningPage = (props:IVoipServiceProvisioningPage) => {
	
	
	let resellerID  = props.resellerID ? props.resellerID : '' 
		if (resellerID.length === 0 && props.match.params.rid != null) {
			resellerID = props.match.params.rid
		}

	const [pageState, setPageState] = useState(VoipServiceProvisioningPageState.Loading);
	const isMounted = useRef(false);
	const [state, setState]=useState(()=>{
		return {
			settings: { customFields: [], extraAccounts: [] },
			data: blankServ
		}
	})



	useEffect(()=>{

		const Go = async ()=>{
			try{
				const service: IVoipService = await set.voipServiceGet(props.match.params.id)
				if (service.provisioningSettings === undefined) {
					service.provisioningSettings = { customFields: [], extraAccounts: [], yealinkFirmwares:[] };
				}

				if (service.provisioningSettings.customFields === undefined) {
					service.provisioningSettings.customFields = [];
				}
				// console.log(service.provisioningSettings)
				setState((s)=> {return { ...s, data: service, settings: service.provisioningSettings} });
				setPageState(VoipServiceProvisioningPageState.ShowTable);
			} catch(err:any){
				let message='There was a problem loading the provisioning settings.'
				if(err && err.data){
					message=message+' '+err.data
				}
				App.newNotificationError(message)
				setPageState(VoipServiceProvisioningPageState.NetworkError)
			}
			
		
		}
		if(isMounted.current===false){
			isMounted.current = true;
			Go();

		}
	}, [props.match.params.id])


	const onSubmitForm = async (values: IProvisioningSettings) => {
		setPageState(VoipServiceProvisioningPageState.Loading);
		// TODO build update request from form/state#]
		try{
			let request: IVoipServiceProvisioningUpdateRequest = {
				id: props.match.params.id,
				useCallerIdAsLabel: state.data.useCallerIdAsLabel, // there needs to be another checkbox on the form for this
				provisioningSettings: values
			};

			const response = await set.voipServiceProvisioningUpdate(request)
			
			setState({ ...state, data: response, settings: response.provisioningSettings });
			setPageState(VoipServiceProvisioningPageState.ShowTable);
			const alert: INotification = {
				title: "Success",
				data: "Settings updated.",
				variant: NOTIFICATION_TYPE.success,
				srcComponent: "EmailReport",
				srcData: "success",
				srcFunction: "handleSubmit",
			  };
			  App.newNotification(alert);

		} catch(error:any){
			let message='There was a problem saving the settings.'
			if(error && error.data){
				message=message+' '+error.data
			}
			App.newNotificationError(message)
			setPageState(VoipServiceProvisioningPageState.NetworkError);
		}

	}

	const setField = (field: string, value: boolean) => {
		//	console.log('setField', field, value, this.state);
		
		setState({...state,  data: { ...state.data, useCallerIdAsLabel: value } });
	}

	



/*
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
    padding: 24px 12px;
}

*/



	return <Grid container spacing={3} alignContent="flex-start">
    <Grid item xs={12}>
	<PageHead resellerID={resellerID} title={"Provisioning"} parents={[{ title: 'Provisioning - Service', url: '../../provisioning' }]}>

	</PageHead>



	  <Box style={{    
		borderRadius: "4px",
		backgroundColor: "#ffffff",
		boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.08)",
		padding:" 24px 12px",
		}}>

		{pageState === VoipServiceProvisioningPageState.Loading && <Loading />}
		{pageState === VoipServiceProvisioningPageState.NetworkError && <Error />}
		{pageState === VoipServiceProvisioningPageState.ShowTable&& <>
			<VipCheckbox field="useCallerIdAsLabel" value={state.data.useCallerIdAsLabel} updateField={setField} text="Use caller ID as label CSV" />
			<FormProvisioning showYeaLinkFirmware={true} resellerMode={false} data={state.settings} submitFunction={onSubmitForm} />

			</>}
	  </Box>
    </Grid>
  </Grid>
}
export default VoipServiceProvisioningPage;

// export default class VoipServiceProvisioningPage extends Component<IVoipServiceProvisioningPage, IVoipServiceProvisioningState> {
// 	id: string = ''

// 	constructor(props: any) {
// 		super(props);

// 		this.state = {
// 			settings: { customFields: [], extraAccounts: [] },
// 			loading: true,
// 			data: blankServ
// 		};

// 		this.setField.bind(this);
// 	}

// 	public componentDidMount() {
// 		// console.log('componentDidMNount')
// 		this.id = this.props.match.params.id;

// 		set.voipServiceGet(this.id).then((service: IVoipService) => {
// 			if (service.provisioningSettings === undefined) {
// 				service.provisioningSettings = { customFields: [], extraAccounts: [] };
// 			}

// 			if (service.provisioningSettings.customFields === undefined) {
// 				service.provisioningSettings.customFields = [];
// 			}
// 			// console.log(service.provisioningSettings)
// 			this.setState({ loading: false, data: service, settings: service.provisioningSettings });
// 		});
// 	}

// 	public onSubmitForm = (values: IProvisioningSettings) => {
// 		this.setState({ loading: true });
// 		// console.log(values);
// 		// TODO build update request from form/state#]


// 		let request: IVoipServiceProvisioningUpdateRequest = {
// 			id: this.id,
// 			useCallerIdAsLabel: this.state.data.useCallerIdAsLabel, // there needs to be another checkbox on the form for this
// 			provisioningSettings: values
// 		};
// 		// console.log(request);

// 		set.voipServiceProvisioningUpdate(request).then((service) => {
// 			// TODO display success notification
// 			// console.log(service.provisioningSettings);

// 			this.setState({ loading: false, data: service, settings: service.provisioningSettings });
// 		})
// 		//delete values;


// 	}

// 	public setField = (field: string, value: boolean) => {
// 		//	console.log('setField', field, value, this.state);

// 		this.setState({ data: { ...this.state.data, useCallerIdAsLabel: value } });
// 	}

// 	public render() {
// 		const { data, settings, loading } = this.state;
// 		// useCallerIdAsLabelVisible={true}

// 		const title = 'Provisioning';// TODO render provisioning settings form here along with checkbox for useCallerIdAsLabel
// 		//	console.log(data);
// 		return (
			
// 		);
// 	}
// }


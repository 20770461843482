import React, { useState } from "react";

import { Form } from "react-final-form";
import { Button, Grid, TextField } from "@material-ui/core";
import MuiSelect from "../../components/MuiComponents/MuiSelect";
import NumberGetSiteOptions from "./NumberGetSiteOptions";
import { ICustomersSearchItem } from "../../interfaces/customer";

export interface INumberSitePickerSubmit {
  customersId:string
  customersSitesId:string
  extensionNumber:string
}

export interface IFormNumberProp {
  customers:ICustomersSearchItem[]
  customersId: string;
  customersSitesId: string;
  userType: string;
  submitFunction: (data: INumberSitePickerSubmit) => void;
  emptyFirstOption:boolean
  enableExtensionBox:boolean
}
const NumberSitePicker: React.FC<IFormNumberProp> = (props: IFormNumberProp) => {
  const [noSites,setNoSites] = useState<boolean>(true)
  return (
    <Form
      onSubmit={props.submitFunction}
      initialValues={{
        customersId: props.customersId,
        customersSitesId: props.customersSitesId,
        extensionNumber:''
      }}
      mutators={{setExtensionNumber:(args,state,utils)=>{
        utils.changeValue(state,'extensionNumber',()=>args[0])
      }}}
      render={({ form, handleSubmit, submitting, pristine, values,  }) => {
        let submitDisabled=true
        if(!submitting){
          if(!pristine){
            if(!noSites || props.emptyFirstOption){
              if((values.customersId && values.customersSitesId) || (!values.customersId && props.emptyFirstOption)){
                submitDisabled=false
              }
            }
          }
        }
        if(!values.customersId){
          values.customersSitesId=''
        }
        
        return (<form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {props.userType === "resellersContacts" && (
                <MuiSelect
                  name="customersId"
                  label="Customer"
                  emptyFirstOption={props.emptyFirstOption}
                  options={props.customers.map((customer) => {
                    return { value: customer.item.id, label: customer.item.name };
                  })}
                />
              )}
            </Grid>
            <Grid item xs={8} />

            <Grid item xs={4}>
              {values?.customersId && (
                <NumberGetSiteOptions customerId={values.customersId} setNoSites={setNoSites} />
              )}
            </Grid>
            <Grid item xs={8} />

            {props.enableExtensionBox && (
              <Grid item xs={4}>
                <TextField name="extensionNumber" label="Extension Number (leave blank for default)" value={values.extensionNumber} onChange={(evt)=>{form.mutators.setExtensionNumber(evt.target.value)}} ></TextField>
              </Grid>
            )}

            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>)
      }}
    />
  );
};

export default NumberSitePicker;

import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";
import Error from "../../components/error";
import Loading from "../../components/loading";
import { useParams, useHistory } from "react-router";

const set = new MySettings();

const IncomingCallRedirect: React.FunctionComponent<any> = (props) => {
  let { id } = useParams<any>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getNumber = async () => {
      try {
        const response = await set.vrNumberRedirect(id);
        setLoading(false);
        history.push(`/vrcustomer/${response.id}`);
        // console.log(`vrcustomer/${response.id}`);

        console.log(response);
      } catch (error) {
        setError(true);
        setLoading(false);
        console.log(error);
      }
    };
    getNumber();
  }, [history, id]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading && <Loading />}{" "}
      {error && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Error />
          <div style={{ textAlign: "center" }}>Number not recognised</div>
        </div>
      )}
    </div>
  );
};

export default IncomingCallRedirect;

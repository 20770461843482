import * as React from "react";
import MySettings from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";

import { Button, Col, Row } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import App from "../../App";
import catchME from "../../catchME";
import PageHead from "../../components/PageHead";
import VipBurgerMenu from "../../components/VipBurgerMenu";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import {
  IAccessGroup,
  IAccessGroupHttpResponse
} from "../../interfaces/access-group";
import { INotification } from "../../interfaces/notification";
import PermissisonForm from "./form-phonebook";
library.add(faWindowClose);

export interface ICustomerDefaultGroupsState {
  object: IAccessGroup;
  loading: boolean;
  displayNewForm: boolean;
  list: IAccessGroup[];
}
const set = new MySettings();
interface ICustomerDefaultGroupsPage extends RouteComponentProps<any>, IPageProps { }
export default class CustomerDefaultGroupsPage extends React.Component<
  ICustomerDefaultGroupsPage,
  ICustomerDefaultGroupsState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      object: { id: "", name: "", permissions: {} },

      loading: true,
      displayNewForm: false,
      list: [],
    };
    this.clickDeletePhonebook = this.clickDeletePhonebook.bind(this);
    this.clickNew = this.clickNew.bind(this);
  }

  public render() {
    const { loading, list, displayNewForm, object } = this.state;
    let svgSrc = "./svg/phonebook-img.svg";
    if (this.props.resellerID !== undefined) {
      svgSrc = "../../svg/phonebook-img.svg";
    }
    list.reverse();
    const phoneBookElements: any[] = [];
    list.map((book: IAccessGroup) => {
      let id: string = "";
      if (book.id !== undefined) id = book.id;

      const link: string = "./customerdefaultgroup/" + book.id;

      phoneBookElements.push(
        <Col key={book.id} md="6" lg="3">
          <div className="dashboard_item whiteBox m-2">
            <Link to={link}>
              <img src={svgSrc} className="img-fluid" alt="Permission" />
              <h4>{book.name}</h4>
            </Link>
            <br />
            <Button
              type="button"
              variant="outline-danger"
              className="noleft"
              onClick={(event: any) => {
                this.clickDeletePhonebook(event, id);
              }}
            >
              <FaIcon icon="user-minus" color="red" />
            </Button>
          </div>
        </Col>
      );

      return true;
    });

    phoneBookElements.reverse();

    if(phoneBookElements.length===0){
      phoneBookElements.push(<div key="a" style={{textAlign:'center',width:'100%'}}>You currently have no default permissions for your customers. Please click more options and then new group.</div>)
    }

    return (
      <div className="content">
        <PageHead
          resellerID={this.props.resellerID}
          title="Customer Default Groups"
          parents={[{ title: "Permissions", url: "./permissions" }]}
        >
          {displayNewForm === false && (
            <VipBurgerMenu
              options={[
                {
                  title: "New Group",
                  onClick: this.clickNew,
                  isExternal: false,
                },
              ]}
            />
          )}
        </PageHead>

        {displayNewForm === true ? (
          <PermissisonForm data={object} submitFunction={this.handleSubmit}>
            <Button
              variant="outline-danger"
              type="button"
              onClick={this.clickCancelNew}
            >
              Cancel
            </Button>
          </PermissisonForm>
        ) : (
          <>
            {loading === true ? (
              <Loading />
            ) : (
              <>
                <Row>{phoneBookElements}</Row>
              </>
            )}
          </>
        )}

        
      </div>
    );
  }

  // opens new Phonebook form
  public clickDeletePhonebook = (e: any, phoneBookID: string) => {
    e.preventDefault();
    //console.log(phoneBookID)
    const dialogResp = window.confirm("Are you sure you want to remove this ?");
    if (dialogResp === true) {
      const AccessGroupRemoved = () => {
        const newArr = [...this.state.list];
        const index = newArr.findIndex((x) => x.id === phoneBookID);
        // console.log(index);
        if (index >= 0) {
          newArr.splice(index, 1);
        }
        this.setState({ loading: false, list: newArr });
        const notification: INotification = {
          title: "Success",
          data: "Access Group Deleted",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
      };

      this.setState({ loading: true });


      set
        .resellerCustomerDefaultPortalAccessGroupsRemove(phoneBookID)
        .then((res) => {
          AccessGroupRemoved();
        })
        .catch((exception) => {
          console.log(exception);
          catchME(this, exception, false);
        });

    }
  };

  // opens new Phonebook form
  public clickNew = (e: any) => {
    // e.preventDefault();
    this.setState({ displayNewForm: true });
  };
  // closes new Phonebook form
  public clickCancelNew = (e: any) => {
    e.preventDefault();
    this.setState({ displayNewForm: false });
  };
  // take data from form and posts it
  public handleSubmit = (data: IAccessGroup) => {
    //  console.log(data);
    this.setState({ loading: true, displayNewForm: false });
    this.postNew(data);
  };

  // THE actual post
  public postNew = (srcData: IAccessGroup) => {
    this.setState({ loading: true });

    let payload: any = {
      name: srcData.name,
    };
    if (this.props.resellerID) {
      payload.customersId = this.props.resellerID;
    }

    const InsertNewAccessGroup = (dd: IAccessGroup) => {
      const something: IAccessGroup[] = [...this.state.list];
      something.push(dd);

      this.setState({ loading: false, list: something });
      const notification: INotification = {
        title: "Success",
        data: "Default access group created.",
        variant: NOTIFICATION_TYPE.info,
        srcFunction: "componentDidUpdate",
        srcData: window.location.pathname,
        srcComponent: "App",
      };
      App.newNotification(notification, true);
    };

    set
      .resellerCustomerDefaultPortalAccessGroupsAdd(payload)
      .then((dd: IAccessGroup) => {
        InsertNewAccessGroup(dd);
      })
      .catch((exception) => {
        console.log(exception);
        catchME(this, exception, false);
      });


  };

  public resolveData = (data: any) => data.map((row: any) => row);

  public componentDidMount = () => {
    this.get();
  };

  // get phonebooks
  public get = () => {
    set
      .resellerDefaultCustomerPortalAccessGroupsGet()
      .then((data: IAccessGroupHttpResponse) => {
        console.log(data);
        const stateUpdate: any = {};
        stateUpdate.loading = false;
        if (data) {
          stateUpdate.list = data.items;
        }
        this.setState(stateUpdate);
      })
      .catch((exception) => {
        console.log(exception);
        catchME(this, exception, false);
      });


    //this.props.resellerID === undefined &&
  };
}


// Simple footer component

import { Grid, MenuItem, Select } from "@material-ui/core";
import { useState } from "react";

// table object properties
export interface IVipTimeFieldProps {
  field: string;
  value: string;
  timeArray: number[];
  updateField: (field: any, payload: any) => void;
}

//
export interface IVipTimeFieldState {
  currentHr: Number;
  currentMin: Number;
}



const VipTimeFieldMui = ({ field, value,  updateField, timeArray= [0, 10, 20, 30, 40, 50]}: IVipTimeFieldProps) => {
  const [currentHr, setCurrentHr] = useState(() => {
    let hr = 0;
    if (value) {
      let s = value.split(":");
      hr = parseInt(s[0]);

    }
    return hr
  })

  const [currentMin, setCurrentMin] = useState(() => {
    let min = 0;
    if (value) {
      let s = value.split(":");
      min = parseInt(s[1]);

    }
    return min
  })



  const handelHourChange = (value: any) => {
  //  event.preventDefault();
    // console.log(event.target.value);
    setCurrentHr(parseInt(value))
    updateFieldWithValue(parseInt(value), currentMin);

  };

  const handelMinChange = (value: any) => {
   // event.preventDefault();
    //  console.log(event.target.value);
    setCurrentMin(parseInt(value));
    updateFieldWithValue(currentHr,parseInt(value));

  };

  const updateFieldWithValue = (hr?: number, min?:number) => {
    let x = currentHr.toString().padStart(2, "0");
    if(hr !== undefined){
      x = hr.toString().padStart(2, "0");;
    }
    let y = currentMin.toString().padStart(2, "0");
    if(min !== undefined){
      y = min.toString().padStart(2, "0");;
    }
    let time = x + ":" + y + ":00";
     console.log(time);
    updateField(field, time);
  };

  return <Grid container>

    <Grid item sm={6}>
      <Select
        variant='outlined'
        fullWidth
        value={currentHr}
        onChange={(value: any) => {
          handelHourChange(value.target.value);

        }}
      >
        {[...Array(24).keys()].map((val) => {
          return (
            <MenuItem key={val} value={val}>
              {val.toString().padStart(2, "0")}
            </MenuItem>
          );
        })}
      </Select>

    </Grid>

    <Grid item sm={6}>

      <Select
        variant='outlined'
        fullWidth
        value={currentMin}
        onChange={(value: any) => {
          handelMinChange(value.target.value);

        }}
      >
        {timeArray.map((val) => {
          return (
            <MenuItem key={val} value={val}>
              {val.toString().padStart(2, "0")}
            </MenuItem>
          );
        })}

      </Select>
    </Grid>
  </Grid>
}

export default VipTimeFieldMui;
import React from "react";

import arrayMutators from "final-form-arrays";
import { Button, Card } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { IVoipService } from "../../interfaces/interfaces";
interface IVoipServiceSettingsFormProps {
  data: IVoipService;
  onSubmit: (data: IVoipService) => void;
  goBack: () => void;
}

const VoIPServiceSettingsForm: React.FC<IVoipServiceSettingsFormProps> = ({
  data,
  onSubmit,
  goBack,
}: any) => {
  console.log(data);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        submitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <Card.Header>
              <h2 className="my-auto">Edit VoIP Service Settings</h2>
            </Card.Header>

            <Card.Body>
              

              <div className="mb-3">
                <label className="form-check-label">
                  Generate daily CDR files for FTP?&nbsp;
                  <Field
                    name="generateCDRFiles"
                    component="input"
                    type="checkbox"
                  />
                </label>
              </div>

              <div className="mb-3">
                <label className="form-check-label">
                  Use cost price in FTP CDRs instead of retail price?&nbsp;
                  <Field
                    name="generateCDRFilesWholesale"
                    component="input"
                    type="checkbox"
                  />
                </label>
              </div>
            </Card.Body>
            <Card.Footer>
              <Button
                className="float-right"
                variant="primary"
                type="button"
                disabled={submitting}
                onClick={handleSubmit}
              >
                Save
              </Button>

              <Button
                className="float-left noleft"
                variant="primary"
                onClick={goBack}
                disabled={submitting}
              >
                Back
              </Button>
            </Card.Footer>
          </Card>
        </form>
      )}
    />
  );
};

export default VoIPServiceSettingsForm;

import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import SelectAdapter from "../../components/misc/form/SelectAdapter";
import VipTextFinalField from "../../components/misc/form/VipTextFinalField";
import { IProduct } from "../../interfaces/products";
import { IAreaCode } from "../../static/settings";

import "../../sass/style.scss";
import WizardClean from "./WizClean";
import { WizardCleanPage } from "./WizCleanPage";

import App from "../../App";
import Error from "../../components/error";
import VoipTable from "../../components/VoipTable";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import MySettings from "../../static/settings";
import Empty from "../NewCustomerWizard/images/empty-state.png";
import NewNumberPortPopup from "./NewNumberPortPopup";
import ReviewCollapse from "./ReviewCollapse";
const set = new MySettings();

const required = (value: any) => (value ? undefined : "Required");

type NewServiceFormProps = {
  data: any;
  areaCodes: IAreaCode[];
  products: IProduct[];
  onSubmit: any;
  loading: boolean;
  site: any;
  resellerID: string;
  customersId: string;
};

const NewServiceForm2: React.FC<NewServiceFormProps> = ({
  data,
  areaCodes,
  products,
  onSubmit,
  loading,
  site,
  resellerID,
  customersId,
}) => {
  const [showNumberPortPopup, setShowNumberPortPopup] = useState(false);
  const [personData, setPersonData] = useState<any>([]);
  const [selected, setSelected] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  let arrayOfCodes: any[] = [];
  // useEffect(() => {
  // }, []);

  arrayOfCodes = areaCodes.map((dd: any) => {
    const array = { value: dd.id, label: dd.city + "(" + dd.id + ")" };
    return array;
  });

  const table = useRef<any>(null);

  const selectAllrecord = (data: any) => {
    table.current.checkMultipleRow();
  };

  const col = [
    {
      Header: "First Name",
      accessor: "firstName",
      sortable: true,
      cssClass: "none",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      sortable: true,
    },
  ];
  const teamCol = [
    {
      Header: "First Name",
      accessor: "firstName",
      sortable: true,
      cssClass: "none",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      sortable: true,
    },
    {
      Header: "Email",
      accessor: "emailAddress",
      sortable: true,
    },
  ];

  useEffect(() => {
    let isMounted = true;

    const getPerson = async () => {
      try {
        if (customersId !== "new" && isMounted) {
          const dataPersonGet = await set.peopleGet("", customersId);

          setPersonData(dataPersonGet.items);
        } else if (isMounted) {
          setPersonData([]);
        }
        // setLoading(false);
      } catch (error) {
        console.log(error);

        const notification: INotification = {
          title: "Error",
          data: "API Error Occurred. please retry if problem persists please contact support",
          variant: NOTIFICATION_TYPE.danger,
          srcFunction: "handleNumberDelete",
          srcData: window.location.pathname,
          srcComponent: "People",
        };

        App.newNotification(notification);

        setError(true);
      }
    };
    getPerson();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>{error ? <Error /> : <WizardClean
      // initialValues={data}
      onSubmit={onSubmit}
      loading={loading}
      status={{
        voipService: ["Service Details", "Notes", "Review"],
        numberPort: ["Number Details", "Notes", "Review"],
        voipIntergration: ["VoIP Integration Details", "Notes", "Review"],
        buyPhones: ["Phone Details", "Notes", "Review"],
        internetServiceEnquiry: ["Enquiry Details", "Notes", "Review"],
        virtualReceptionistAdd: ["Notes", "Review"],
        resellerTeamsCallRecordingAdd: ["Notes", "Review"],
        resellerWallboardAdd:['Notes','Review']
      }}
    >
      <WizardCleanPage>
        <legend className="underlined">
          What service would you like to add to this site?
        </legend>

        <fieldset className="mainContent">
          <div>
            <Field
              name="service"
              value="voipService"
              component="input"
              type="radio"
              required={true}
            />
            <label>VoIP Service</label>
          </div>

          <div>
            <Field
              name="service"
              value="numberPort"
              component="input"
              type="radio"
            />
            <label>Number Port</label>
          </div>

          <div>
            <Field
              name="service"
              value="voipIntergration"
              component="input"
              type="radio"
            />
            <label>VoIP Integration</label>
          </div>

          <div>
            <Field
              name="service"
              value="buyPhones"
              component="input"
              type="radio"
            />
            <label>Buy Phones</label>
          </div>

          <div>
            <Field
              name="service"
              value="internetServiceEnquiry"
              component="input"
              type="radio"
            />
            <label>Internet Service Enquiry (Broadband and line enquiry)</label>
          </div>

          {/*<div>
            <Field
              name="service"
              value="virtualReceptionistAdd"
              component="input"
              type="radio"
            />
            <label>VIP Receptionist</label>
          </div>*/}
          <div>
            <Field
              name="service"
              value="resellerTeamsCallRecordingAdd"
              component="input"
              type="radio"
            />
            <label>Teams Call Recording</label>
          </div>

          <div>
            <Field
              name="service"
              value="resellerWallboardAdd"
              component="input"
              type="radio"
            />
            <label>Wallboard</label>
          </div>

        </fieldset>
      </WizardCleanPage>
      <WizardCleanPage
        render={(values: any, push: any, pop: any, form: any) => (
          <>
            {values.service === "voipService" && (
              <fieldset className="mainContent">
                <legend className="underlined">VoIP Service Details</legend>
                <label>Would you like to use your own server address?</label>

                <div>
                  <Field
                    name="ownServerAddress"
                    value="yes"
                    component="input"
                    type="radio"
                  />
                  <label>Yes</label>
                </div>

                <div className="mb-2">
                  <Field
                    name="ownServerAddress"
                    value="no"
                    component="input"
                    type="radio"
                  />
                  <label>No</label>
                </div>

                <div className="form-group">
                  <label>How many extensions do you require ?</label>

                  <VipTextFinalField
                    fieldName="estimatedNumberOfExtensions"
                    placeholder=""
                    required={true}
                    type="number"
                  />
                </div>

                <label>
                  Would you like to allocate a new number for the VoIP Service?
                </label>

                <div>
                  <Field
                    name="allocateNewNumber"
                    value="yes"
                    component="input"
                    type="radio"
                  />
                  <label>Yes</label>
                </div>

                <div className="mb-2">
                  <Field
                    name="allocateNewNumber"
                    value="no"
                    component="input"
                    type="radio"
                  />
                  <label>No</label>
                </div>

                {values.allocateNewNumber === "yes" && (
                  <>
                    <div className="selector">
                      <label>New Number Area code / City</label>
                      <Field
                        name="city"
                        options={arrayOfCodes}
                        component={SelectAdapter}
                        placeholder="Type area code or city name"
                      />
                    </div>
                  </>
                )}
                <div className="form-group">
                  <label>Required software integrations</label>
                  <VipTextFinalField
                    fieldName="requiresSoftwareIntegration"
                    placeholder=""
                    required={false}
                    type="text"
                  />
                </div>

                <label>Is this customer a business or domestic user?</label>
                <div>
                  <Field
                    name="type"
                    value="Business"
                    component="input"
                    type="radio"
                  />
                  <label>Business</label>
                </div>
                <div className="mb-2">
                  <Field
                    name="type"
                    value="Domestic"
                    component="input"
                    type="radio"
                  />
                  <label>Domestic</label>
                </div>
              </fieldset>
            )}

            {values.service === "numberPort" && (
              <>
                {showNumberPortPopup && (
                  <NewNumberPortPopup
                    form={form}
                    push={push}
                    values={values}
                    setVisible={() => setShowNumberPortPopup(false)}
                  />
                )}

                <>
                  <legend className="underlined">Number Port Details</legend>

                  <div className="wizard3__permissions">
                    <p className="">You can request a number port here.</p>
                  </div>

                  {!values.numberPortNumbers ? (
                    <>
                      <div className="wizard5-main">
                        <div className="wizard6__offer-post">
                          <img
                            className="wizard6__offer-post-img"
                            src={Empty}
                            alt={"welcome"}
                            width="224px"
                            height="212px"
                          />
                          <h6 className="mt-3 mb-5">
                            Click below to add numbers
                          </h6>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <Table responsive="sm" className="my-4">
                          <thead>
                            <tr>
                              <label>Number</label>

                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <FieldArray name="numberPortNumbers">
                              {({ fields }) =>
                                fields.map((number, index) => (
                                  <tr key={index}>
                                    <td>{values.numberPortNumbers[index]}</td>

                                    <td>
                                      <Dropdown className="float-right">
                                        <Dropdown.Toggle
                                          variant="link"
                                          className="button__options"
                                          id="dropdown-basic"
                                        ></Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            as="button"
                                            onClick={() => fields.remove(index)}
                                          >
                                            Remove
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                ))
                              }
                            </FieldArray>
                          </tbody>
                        </Table>
                      </div>
                    </>
                  )}
                  <div className="text-center">
                    <div className="wizard6__offer-post-box">
                      <Button
                        className="button-primary"
                        onClick={() => setShowNumberPortPopup(true)}
                      >
                        {!values.numberPortNumbers
                          ? "Request Number Port"
                          : "Port More Numbers"}
                      </Button>
                    </div>
                  </div>
                </>
              </>
            )}

            {values.service === "voipIntergration" && (
              <fieldset className="mainContent">
                <legend className="underlined">VoIP Integration Details</legend>
                <div className="form-group">
                  <label>Name of the software for integration</label>
                  <VipTextFinalField
                    fieldName="voipIntergrationName"
                    placeholder=""
                    required={true}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label>Level of the software integration required</label>
                  <VipTextFinalField
                    fieldName="voipIntergrationLevel"
                    placeholder=""
                    required={true}
                    type="text"
                  />
                </div>
              </fieldset>
            )}

            {values.service === "virtualReceptionistAdd" && (
              // <fieldset className="mainContent">
              //   <label>
              //     Please enter any special instructions for the virtual
              //     receptionists.
              //   </label>
              //   <Field name="specialInstructions" component="textarea">
              //     {({ input, meta }) => (
              //       <>
              //         <fieldset className="mainContent">
              //           <textarea
              //             {...input}
              //             id={input.name.replace(".", "_")}
              //             className="form-control"
              //             rows={3}
              //           />
              //         </fieldset>
              //         {meta.error && meta.touched && <span>{meta.error}</span>}
              //       </>
              //     )}
              //   </Field>
              // </fieldset>
              <>
                <div>
                  Please choose the contacts that will become VIP Receptionist
                  Managers. These contacts will be able to set up the
                  instructions for the VIP Receptionists.
                </div>
                <div>
                  <VoipTable
                    onRender={() => {
                      if (table && table.current) {
                        values.customers = table.current.selectedRows();
                      }
                    }}
                    title="Select Customers"
                    data={personData}
                    col={col}
                    multiSelect={true}
                    ref={table}
                    multiSelectActions={[
                      {
                        title: selected ? "Unselect All" : "Select All",
                        f: (ddd) => {
                          selectAllrecord(ddd);
                          values.customers = table.current.selectedRows();
                          setSelected(!selected);
                        },
                      },
                    ]}
                  />
                </div>
              </>
            )}
            {values.service === "resellerTeamsCallRecordingAdd" && (
              <>
                <div>
                  Please choose the contacts that will become the Teams call
                  recording managers.
                  <br />
                  These contacts will be sent instructions to enable the Teams
                  call recording and will need admin privileges on their 365
                  tenancy.
                </div>
                <div>
                  <VoipTable
                    onRender={() => {
                      if (table && table.current) {
                        values.customers = table.current.selectedRows();
                        console.log(personData);
                      }
                    }}
                    title="Select Customers"
                    data={personData}
                    col={teamCol}
                    multiSelect={true}
                    ref={table}
                    multiSelectActions={[
                      {
                        title: selected ? "Unselect All" : "Select All",
                        f: (ddd) => {
                          selectAllrecord(ddd);
                          values.customers = table.current.selectedRows();
                          setSelected(!selected);
                        },
                      },
                    ]}
                  />
                </div>
                <div style={{ marginTop: "30px" }}>
                  <label>
                    What is the maximum number of teams call recording licenses
                    available to this customer?
                  </label>
                  <VipTextFinalField
                    fieldName="teamsCallRecordingLicenseLimit"
                    placeholder=""
                    required={true}
                    type="number"
                  />
                </div>
              </>
            )}

            {values.service === "buyPhones" && (
              <>
                {
                  (values.buyPhoneAdress = values.buyPhoneAdress
                    ? values.buyPhoneAdress
                    : `${site.description} \n ${site.line1} \n ${site.line2} \n ${site.city} \n ${site.postCode}`)
                }
                <fieldset className="mainContent">
                  <legend className="underlined">Buy Phones</legend>
                  <div className="wizard3__permissions">
                    <p className="">You can buy phones here.</p>
                    <p className="">Please enter the delivery address below.</p>

                    <Field name="buyPhoneAdress" component="textarea">
                      {({ input, meta }) => (
                        <>
                          <fieldset className="mainContent">
                            <textarea
                              {...input}
                              id={input.name.replace(".", "_")}
                              className="form-control"
                              rows={4}
                            />
                          </fieldset>
                          {meta.error && meta.touched && (
                            <span>{meta.error}</span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  {!values.buyPhonesArray ? (
                    <>
                      <div className="wizard5-main">
                        <div className="wizard6__offer-post">
                          <img
                            className="wizard6__offer-post-img"
                            src={Empty}
                            alt={"welcome"}
                            width="224px"
                            height="212px"
                          />
                          <h6 className="mt-3 mb-5">
                            Click below to add phones
                          </h6>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Table>
                      <thead>
                        <tr>
                          <th className="asLabel">Product</th>
                          <th className="asLabel">Qty</th>
                          <th className="asLabel">PSUs Required</th>
                          <th className="asLabel">Rental Period</th>
                          <th className="asLabel"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="buyPhonesArray">
                          {({ fields }) =>
                            fields.map((product, index) => (
                              <tr key={product}>
                                <td>
                                  <Field
                                    component="select"
                                    name={`${product}.product`}
                                    validate={required}
                                  >
                                    {({ input, meta }) => (
                                      <div>
                                        <select
                                          {...input}
                                          id={input.name.replace(".", "_")}
                                          className="form-control"
                                        >
                                          <option></option>
                                          {products.map((mapp) => {
                                            return (
                                              <option
                                                key={mapp.id}
                                                value={mapp.id}
                                              >
                                                {mapp.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                        {meta.error && meta.touched && (
                                          <span>{meta.error}</span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </td>
                                <td className="narrow">
                                  <VipTextFinalField
                                    fieldName={`${product}.qty`}
                                    placeholder=""
                                    required={true}
                                    type="number"
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`${product}.psuRequired`}
                                    component="input"
                                    type="checkbox"
                                  >
                                    {({ input, meta }) => (
                                      <div className="form-check">
                                        <input
                                          type="checkbox"
                                          {...input}
                                          id={input.name.replace(".", "_")}
                                          className="form-check-input"
                                        ></input>
                                        <label className="form-check-label">
                                          &nbsp;
                                        </label>
                                      </div>
                                    )}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    component="select"
                                    name={`${product}.rentalPeriod`}
                                    validate={required}
                                  >
                                    {({ input, meta }) => (
                                      <div>
                                        <select
                                          {...input}
                                          id={input.name.replace(".", "_")}
                                          className="form-control"
                                        >
                                          <option></option>
                                          <option value="None">None</option>
                                          <option value="24">24</option>
                                          <option value="36">36</option>
                                        </select>
                                        {meta.error && meta.touched && (
                                          <span>{meta.error}</span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </td>
                                <td>
                                  <Dropdown className="float-right">
                                    <Dropdown.Toggle
                                      variant="link"
                                      className="button__options"
                                      id="dropdown-basic"
                                    ></Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        as="button"
                                        onClick={() => fields.remove(index)}
                                      >
                                        Remove
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          }
                        </FieldArray>
                      </tbody>
                    </Table>
                  )}

                  <div className="text-center">
                    <div className="wizard6__offer-post-box">
                      <Button
                        className="button-primary"
                        onClick={() => {
                          push("buyPhonesArray", undefined);
                        }}
                      >
                        {!values.buyPhonesArray
                          ? "Add Phones To Buy"
                          : "Add More Phones"}
                      </Button>
                    </div>
                  </div>
                </fieldset>
              </>
            )}

            {values.service === "internetServiceEnquiry" && (
              <fieldset className="mainContent">
                <legend className="underlined">Internet Service Details</legend>
                <div className="form-group">
                  <label>
                    Please give details of the Internet service required. E.g
                    How many people will be using the service? What will the
                    service mainly be used for?
                  </label>
                  <VipTextFinalField
                    fieldName="internetServiceEnquirySpeedRequired"
                    placeholder=""
                    required={true}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label>
                    New Or Existing Line. If existing line please give the
                    number of the existing line.
                  </label>
                  <VipTextFinalField
                    fieldName="internetServiceEnquiryNewOrExisting"
                    placeholder=""
                    required={true}
                    type="text"
                  />
                </div>
              </fieldset>
            )}

{values.service === "resellerWallboardAdd" && (
              <fieldset className="mainContent">
                Thank you for requesting a wallboard. Please provide details about what is needed on the wallboard by clicking next.
              </fieldset>
            )}
          </>
        )}
      ></WizardCleanPage>
      <WizardCleanPage>
        <legend className="underlined">Notes</legend>
        <label>You can leave any additional notes here.</label>
        <Field name="notes" component="textarea">
          {({ input, meta }) => (
            <>
              <fieldset className="mainContent">
                <textarea
                  {...input}
                  id={input.name.replace(".", "_")}
                  className="form-control"
                  rows={3}
                />
              </fieldset>
              {meta.error && meta.touched && <span>{meta.error}</span>}
            </>
          )}
        </Field>
      </WizardCleanPage>

      <WizardCleanPage
        render={(values: any, push: any, pop: any, form: any) => (
          <>
            <div className="wizard8__right-heading-div">
              <legend className="underlined">Review Service</legend>
            </div>
            <p className="mb-4">
              Check the information below is correct before submitting.
            </p>

            <div className="wizard5-main">
              {values.service === "voipService" && (
                <ReviewCollapse
                  header="VoIP Service Details"
                  content={
                    <Table responsive="sm" className="tableMargin">
                      <thead>
                        <tr>
                          <th className="asLabel">Your own server address?</th>
                          <th className="asLabel">
                            Number of required extensions
                          </th>
                          <th className="asLabel">
                            Allocate a new number for the VoIP Service?
                          </th>
                          <th className="asLabel">
                            New Number Area code / City
                          </th>
                          <th className="asLabel">
                            Required software integrations
                          </th>
                          <th className="asLabel">
                            Business or Domestic
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {values.ownServerAddress
                              ? values.ownServerAddress
                              : ""}
                          </td>
                          <td>
                            {values.estimatedNumberOfExtensions
                              ? values.estimatedNumberOfExtensions
                              : ""}
                          </td>
                          <td>
                            {values.allocateNewNumber
                              ? values.allocateNewNumber
                              : ""}
                          </td>
                          <td>
                            {values.allocateNewNumber &&
                              values.city &&
                              values.city.label
                              ? values.city.label
                              : ""}
                          </td>
                          <td>
                            {values.requiresSoftwareIntegration
                              ? values.requiresSoftwareIntegration
                              : ""}
                          </td>
                          <td>
                            {values.type ? values.type : ''}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  }
                />
              )}
              {values.service === "numberPort" && (
                <ReviewCollapse
                  header="Number Port Details"
                  content={
                    <Table responsive="sm" className="tableMargin">
                      <thead>
                        <tr>
                          <th className="asLabel">Numbers to port</th>
                          <th className="asLabel">Multiline number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {values.numberPortNumbers
                              ? values.numberPortNumbers.map(
                                (number: string, i: number, arr: []) => {
                                  if (arr.length - 1 === i) {
                                    return `${number}`;
                                  } else {
                                    return `${number}, `;
                                  }
                                }
                              )
                              : ""}
                          </td>
                          <td>{values.miltiLineNumber ? "Yes" : ""}</td>
                        </tr>
                      </tbody>
                    </Table>
                  }
                />
              )}

              {values.service === "resellerTeamsCallRecordingAdd" && (
                <>
                  <ReviewCollapse
                    header="Teams Call Recording Managers"
                    content={
                      <>
                        {values.customers && values.customers.length > 0 ? (
                          <Table responsive="sm" className="tableMargin">
                            <thead>
                              <tr>
                                <th className="asLabel">First Name</th>
                                <th className="asLabel">Last Name</th>
                                <th className="asLabel">Email</th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.customers &&
                                values.customers.map((customer: any) => (
                                  <tr key={customer.id}>
                                    <td>{customer.firstName}</td>
                                    <td>{customer.lastName}</td>
                                    <td>{customer.emailAddress}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        ) : (
                          <>
                            <div style={{ marginLeft: "20px" }}>
                              No record specified.
                            </div>
                          </>
                        )}
                      </>
                    }
                  />
                  <ReviewCollapse
                    header="Teams Call Recording License Limit"
                    content={
                      <>
                        <div style={{ marginLeft: "20px" }}>
                          {values.teamsCallRecordingLicenseLimit}
                        </div>
                      </>
                    }
                  />
                </>
              )}
              {values.service === "virtualReceptionistAdd" && (
                <ReviewCollapse
                  header="VIP Receptionist Managers"
                  content={
                    <>
                      {values.customers && values.customers.length > 0 ? (
                        <Table responsive="sm" className="tableMargin">
                          <thead>
                            <tr>
                              <th className="asLabel">First Name</th>
                              <th className="asLabel">Last Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.customers &&
                              values.customers.map((customer: any) => (
                                <tr key={customer.id}>
                                  <td>{customer.firstName}</td>
                                  <td>{customer.lastName}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : (
                        <>
                          <div style={{ marginLeft: "20px" }}>
                            No managers specified.
                          </div>
                        </>
                      )}
                    </>
                  }
                />
              )}
              {values.service === "voipIntergration" && (
                <ReviewCollapse
                  header="VoIP Integration Details"
                  content={
                    <Table responsive="sm" className="tableMargin">
                      <thead>
                        <tr>
                          <th className="asLabel">
                            Name of the software for integration
                          </th>
                          <th className="asLabel">
                            Level of the software integration
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {values.voipIntergrationName
                              ? values.voipIntergrationName
                              : ""}
                          </td>
                          <td>
                            {values.voipIntergrationLevel
                              ? values.voipIntergrationLevel
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  }
                />
              )}
              {values.service === "internetServiceEnquiry" && (
                <ReviewCollapse
                  header="Enquiry Details"
                  content={
                    <Table responsive="sm" className="tableMargin">
                      <thead>
                        <tr>
                          <th className="asLabel">Details</th>
                          <th className="asLabel">New Or Existing Line</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{values.internetServiceEnquirySpeedRequired}</td>
                          <td>{values.internetServiceEnquiryNewOrExisting}</td>
                        </tr>
                      </tbody>
                    </Table>
                  }
                />
              )}
              {values.service === "buyPhones" && (
                <>
                  <ReviewCollapse
                    header="Buy Phones Details"
                    content={
                      <Table responsive="sm" className="tableMargin">
                        <thead>
                          <tr>
                            <th className="asLabel">Product</th>
                            <th className="asLabel">Qty</th>
                            <th className="asLabel">PSUs Required</th>
                            <th className="asLabel">Rental Period</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.buyPhonesArray &&
                            values.buyPhonesArray.map((phone: any) => (
                              <tr
                                key={
                                  phone.product + phone.qty + phone.psuRequired
                                }
                              >
                                <td>
                                  {phone.product
                                    ? products.find(
                                      (item) => item.id === phone.product
                                    )!.name
                                    : ""}
                                </td>
                                <td>{phone.qty ? phone.qty : ""}</td>
                                <td>{phone.psuRequired ? "Yes" : "No"}</td>
                                <td>{phone.rentalPeriod ? phone.rentalPeriod : ""}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    }
                  />
                  <ReviewCollapse
                    header="Buy Phones Adress"
                    content={
                      <div
                        style={{ marginLeft: "22px" }}
                        dangerouslySetInnerHTML={{
                          __html: values.buyPhoneAdress.replace(
                            /\n/g,
                            "<br />"
                          ),
                        }}
                      >
                        {/* {values.buyPhoneAdress ? values.buyPhoneAdress : ""} */}
                      </div>
                    }
                  />
                </>
              )}
              {values.notes && (
                <ReviewCollapse
                  header="Notes"
                  content={<div className="divMargin">{values.notes}</div>}
                />
              )}
            </div>
          </>
        )}
      ></WizardCleanPage>
    </WizardClean>}</>

  );
};

export default NewServiceForm2;

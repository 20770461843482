import React, { useState, useEffect } from "react";
import { INotification } from "../interfaces/notification";
import { NOTIFICATION_TYPE } from "../enum/notification-type";

import Loading from "./loading";
import Button from "react-bootstrap/Button";
import MySettings from "../static/settings";
import App from "../App";
import ScrollToTop from "./misc/ScrollToTop";
import { useHistory } from "react-router";

const set = new MySettings();

interface AssociateButtonProps {
  id:string
  buttonLabel:string
  openInNewTab:boolean
}

const AssociateButton = (props: AssociateButtonProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const resellerAssociate = async () => {
      if (isLoading) {
        try {
          await set.resellerAssociate(props.id);
          const notification: INotification = {
            title: "Success",
            data: "You have successfully impersonated this user.",
            variant: NOTIFICATION_TYPE.success,
            srcComponent: "PersonForm",
            srcData: "success",
            srcFunction: "resellerContactResetMFAForCustomerContact",
          };
          if(props.openInNewTab){
            window.open('/voip/portal')
          }else{
            window.location.replace("/voip/portal");
          }
          App.newNotification(notification);
        } catch (error) {
          const notification: INotification = {
            title: "Failed",
            data: "Error please try again",
            variant: NOTIFICATION_TYPE.warning,
            srcComponent: "PersonForm",
            srcData: "failed",
            srcFunction: "resellerContactResetMFAForCustomerContact",
          };

          App.newNotification(notification);
        }
      }
      setIsLoading(false);
    };
    resellerAssociate();
  }, [history, isLoading, props]);

  const handleClick = () => {
    if (!isLoading) setIsLoading(true);
    else return null;
  };

  return isLoading ? (
    <div>
      <ScrollToTop></ScrollToTop>
      <Loading />
    </div>
  ) : (
    <Button
      variant="primary"
      type="reset"
      onClick={handleClick}
    >
      {props.buttonLabel}
    </Button>
  );
};

export default AssociateButton;

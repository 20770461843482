import {
  format,
  getHours,
  getMinutes,
  parse,
  setHours,
  setMinutes
} from "date-fns";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";
import Loading from "../../components/loading";
import FormEmailReport from "../../components/oldForms/form-email-report";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import {
  EmailReportRange, IEmailReport, IEmailReportHttpResponse,
  IEmailReportTest
} from "../../interfaces/email-reports";
import { IExtension } from "../../interfaces/extension";
import {
  IVoipService, IVoipServicesRequest
} from "../../interfaces/interfaces";
import { INotification } from "../../interfaces/notification";
import { INumber } from "../../interfaces/number";
import { IPageProps } from "../../interfaces/page-props";
import MySettings from "../../static/settings";

const settings = new MySettings();

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> { }

export interface IEmailReportState {
  report: IEmailReport;
  services: IVoipService[];
  extensions: IExtension[];
  hotDesks: IExtension[];
  ddis: INumber[];
}

const newEmailReport: IEmailReport = {
  lastSentEmailAddresses:[],
  fromEmailAddress: "",
  attachCSV: false,
  attachJSON: false,
  attachPDF: false,
  customersSitesServicesId: "",
  emailAddresses: [],
  id: "", // unique id, generated by the server
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
  range: EmailReportRange.Day, // should a picker of Day, Week, Month, default Day
  name: "", // free text, ends up in the subject of the email, default "New Report"
  reportTime: "09:00:00", // when the email is sent, format is HH:MM:SS, default 09:00:00
  subReports: [],
};

const EmailReport: React.FC<IPageProps & Props> = (props) => {
  const [data, setData] = useState<IEmailReportState>({
    report: newEmailReport,
    services: [],
    extensions: [],
    ddis: [],
    hotDesks: []
  });

  const [reportLoading, setReportLoading] = useState<boolean>(false);



  const [newStartDay, setNewStartDay] = useState<string>(
    format(new Date(), "dd/MM/yyyy")
  );
  const [newStartTime, setNewStartTime] = useState<string>("00:00:00");
  const [newEndDay, setNewEndDay] = useState<string>(
    format(new Date(), "dd/MM/yyyy")
  );
  const [newEndTime, setNewEndTime] = useState<string>("23:59:00");
  const [showHTML, setShowHTML] = useState<boolean>(false)

  const [loading, setLoading] = useState(true);

  // console.log("PROPS OF EMAIL REPORT", props);
  // console.log("STATE OF EMAIL REPORT", data);
  // console.log("newStartDay", newStartDay);
  // console.log("newStartTime", typeof newStartTime, newStartTime);
  // console.log("newEndDay", newEndDay);
  // console.log("newEndTime", newEndTime);

  useEffect(() => {
    let isMounted = true;
    const getReport = async (reportId: string) => {
      try {
        const resultOfVoipServicesCall: IVoipServicesRequest =
          await settings.getVoipServicesCall(false,props.resellerID);

        if (reportId !== "new") {
          const resultOfEmailReportGet: IEmailReportHttpResponse =
            await settings.emailReportGet(reportId);

          const resultOfExtensionsGet = await settings.extensionsGet(
            resultOfEmailReportGet.item.customersSitesServicesId, []
          );
          const resultOfHotDesksGet = await settings.extensionsGet(resultOfEmailReportGet.item.customersSitesServicesId, ['HD'])

          const ddis = await settings.ddisGet(
            resultOfEmailReportGet.item.customersSitesServicesId
          );

          if (isMounted) {
            setData((currentState) => ({
              ...currentState,
              report: resultOfEmailReportGet.item,
              services: resultOfVoipServicesCall.services,
              extensions: resultOfExtensionsGet.items,
              ddis: ddis.items,
              hotDesks: resultOfHotDesksGet.items
            }));
          }
        } else {
          const firstService = resultOfVoipServicesCall.services[0].id;

          const resultOfExtensionsGet = await settings.extensionsGet(
            firstService, []
          );
          const resultOfHotDesksGet = await settings.extensionsGet(
            firstService, ['HD']
          );

          const ddis = await settings.ddisGet(firstService);

          if (isMounted) {
            setData((currentState) => ({
              ...currentState,
              ddis: ddis.items,
              extensions: resultOfExtensionsGet.items,
              hotDesks: resultOfHotDesksGet.items,
              services: resultOfVoipServicesCall.services,
              report: {
                ...newEmailReport,
                customersSitesServicesId: firstService,
                emailAddresses: [props.session.emailAddress],
              },
            }));
          }
        }
      } catch (error) {
        App.newNotificationError("An error has occured");
      }
      setLoading(false);
    };

    getReport(props.match.params.id);

    return () => {
      isMounted = false;
    };
  }, [props.match.params.id, props.resellerID, props.session.emailAddress]);

  const makeNotification = (note: INotification) => {
    return App.newNotification(note);
  };

  const handleStartDayChange = (day: any) => {
    setNewStartDay(format(day, "dd/MM/yyyy"));
  };

  const handleEndDayChange = (day: any) => {
    setNewEndDay(format(day, "dd/MM/yyyy"));
  };

  const handleTimeChange = (field: string, value: any) => {
    field === "startTime" ? setNewStartTime(value) : setNewEndTime(value);
  };

  const handleTest = async (d: any): Promise<void> => {
    //  setLoading(true);
    console.log("handleTest", d)
    setReportLoading(true);
    let parsedStartDay = parse(newStartDay, "dd/MM/yyyy", new Date());
    let parsedStartTime = parse(newStartTime, "HH:mm:ss", new Date());

    let startDayWithUpdatedHours = setHours(
      parsedStartDay,
      getHours(parsedStartTime)
    );

    let completeStartDate = setMinutes(
      startDayWithUpdatedHours,
      getMinutes(parsedStartTime)
    ).toISOString();

    let parsedEndDay = parse(newEndDay, "dd/MM/yyyy", new Date());
    let parsedEndTime = parse(newEndTime, "HH:mm:ss", new Date());

    let endDayWithUpdatedHours = setHours(
      parsedEndDay,
      getHours(parsedEndTime)
    );
    let completeEndDate = setMinutes(
      endDayWithUpdatedHours,
      getMinutes(parsedEndTime)
    ).toISOString();

    const emailReportTest: IEmailReportTest = {
      ...data.report,
      showHTML,
      manualStartDateTime: completeStartDate,
      manualEndDateTime: completeEndDate,
      manualDateTimeZoneMinutesOffset: new Date().getTimezoneOffset()
    };

    try {
      // console.log("emailReportsRun", emailReportTest);
      const response = await settings.emailReportsRun(emailReportTest);
      if (typeof response === 'string') {
        const win: Window | null = window.open("", "_blank");
        if (win != null) {
          win.document.body.innerHTML = response;
        } else {
          const alert: INotification = {
            title: "Warning",
            data: "Please disable your popup blocker on this site.",
            variant: NOTIFICATION_TYPE.warning,
            srcComponent: "EmailReport",
            srcData: "success",
            srcFunction: "handleTest",
          };
          makeNotification(alert);
        }

        setReportLoading(false);
      } else {
        const alert: INotification = {
          title: "Success",
          data: "Report sent.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "EmailReport",
          srcData: "success",
          srcFunction: "handleTest",
        };
        makeNotification(alert);
        setReportLoading(false);
      }

    } catch (error) {
      // setLoading(false);
      App.newNotificationError("There was a problem generating the report. Please contact your VoIP provider.");
      setReportLoading(false);
    }

    //setLoading(false);
  };

  const handleSubmit = async (): Promise<void> => {
    let emailReportsUrl = "../emailreports";

    setLoading(true);
    if (data.report.id === "") {
      // console.log("add call", data);
      try {
        const resultOfEmailReportsAdd = await settings.emailReportsAdd(
          data.report
        );

        const alert: INotification = {
          title: "Success",
          data: "Report created.",
          variant: NOTIFICATION_TYPE.success,
          srcComponent: "EmailReport",
          srcData: "success",
          srcFunction: "handleSubmit",
        };
        makeNotification(alert);

        setData((currentState) => ({
          ...currentState,
          data: resultOfEmailReportsAdd,
        }));

        props.history.push(emailReportsUrl);
      } catch (error) {
        setLoading(false);
        App.newNotificationError(error.data);
      }
    } else {
      // console.log("update call", data);
      try {
        const resultOfEmailReportsUpdate = await settings.emailReportsUpdate(
          data.report
        );
        {
          const alert: INotification = {
            title: "Success",
            data: "Report saved.",
            variant: NOTIFICATION_TYPE.success,
            srcComponent: "EmailReport",
            srcData: "success",
            srcFunction: "handleSubmit",
          };
          makeNotification(alert);

          setData((currentState) => ({
            ...currentState,
            data: resultOfEmailReportsUpdate,
          }));

          props.history.push(emailReportsUrl);
        }
      } catch (error) {
        App.newNotificationError(error.data);
      }

      setLoading(false);
    }
  };

  const addEmail = (email: string): void => {
    const emails = [...data.report.emailAddresses];
    if (!emails.includes(email)) {
      emails.push(email);
      const copy = { ...data.report, emailAddresses: emails };
      setData((currentState) => ({
        ...currentState,
        report: copy,
      }));
    }
  };

  const removeEmail = (email: string): void => {
    const emails = data.report.emailAddresses.filter((item) => item !== email);
    const copy = { ...data.report, emailAddresses: emails };
    setData((currentState) => ({
      ...currentState,
      report: copy,
    }));
  };

  const reportUpdateHandler = (field: string, value: any): void => {
    // console.log("HANDLER", field, value);
    const copy = { ...data.report, [field]: value };
    setData((currentState) => ({
      ...currentState,
      report: copy,
    }));
  };

  let title: string;
  if (data.report.id) {
    title = data.report.name;
  } else {
    title = "New Report";
  }

  if (data.report.subReports == null) {
    data.report.subReports = [];
  }

  let isReseller = false;
  if (props.resellerID && props.resellerID.length > 0) {
    isReseller = true;
  }
  return (
    <>
      <div className="content">
        <PageHead
          resellerID={props.resellerID}
          title={title}
          parents={[{ title: "Email Reports", url: "../emailreports" }]}
        ></PageHead>
        {loading ? (
          <Loading />
        ) : (
          <Row>
            <Col md="12">
              <FormEmailReport
                data={data}
                handleSubmit={handleSubmit}
                handleTest={handleTest}
                handleStartDayChange={handleStartDayChange}
                handleEndDayChange={handleEndDayChange}
                handleTimeChange={handleTimeChange}
                newStartTime={newStartTime}
                newStartDay={newStartDay}
                newEndTime={newEndTime}
                newEndDay={newEndDay}
                addEmail={addEmail}
                removeEmail={removeEmail}
                reportUpdateHandler={reportUpdateHandler}
                isReseller={isReseller}
                showHTML={showHTML}
                handleShowHTMLChange={setShowHTML}
                reportLoading={reportLoading}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default EmailReport;

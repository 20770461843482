import React, { useEffect, useState } from "react";
import MySettings from "../../static/settings";

import { ChangeEvent } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import { IPageProps } from "../../interfaces/page-props";
import {
  IPSAReconciliation,
  IPSAReconciliationGetResponse
} from "../../interfaces/products";

const set = new MySettings();

interface RouteParams { }

interface IResellerPSAReconciliationFormProps {
  reconciliation: IPSAReconciliation;
  onReconcileChange(event: ChangeEvent<HTMLInputElement>): void;
}

const ResellerPSAReconciliationForm: React.FC<IResellerPSAReconciliationFormProps> =
  (props) => {
    const centerStyle = { textAlign: "center" as const };
    return (
      <tr>
        <td>{props.reconciliation.name}</td>
        <td>{props.reconciliation.psaCompanyName}</td>
        <td>{props.reconciliation.productsName}</td>
        <td>{props.reconciliation.psaProductName}</td>
        <td style={centerStyle}>{props.reconciliation.vipQuantity}</td>
        <td style={centerStyle}>{props.reconciliation.psaQuantity}</td>
        <td style={centerStyle}>
          <input
            value={props.reconciliation.id}
            name="reconcile[]"
            type="checkbox"
            checked={props.reconciliation.reconcile}
            onChange={props.onReconcileChange}
          />
        </td>
      </tr>
    );
  };

const ResellerPSAReconciliationPage: React.FC<
  RouteComponentProps<RouteParams> & IPageProps
> = () => {
  const [response, setReconciliations] =
    useState<IPSAReconciliationGetResponse>({
      invoiceDate: "",
      items: [],
    });
  const [loading, setLoading] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const onReconcileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    //we always have to create a new array and copy everything
    //or when something else changes the state it breaks everything in weird ways
    let newRecs: IPSAReconciliation[] = [];
    response.items.forEach((reconciliation) => {
      let newRec = { ...reconciliation };
      if (newRec.id === event.target.value) {
        newRec.reconcile = event.target.checked;
      }
      newRecs.push(newRec);
    });
    setReconciliations({ invoiceDate: response.invoiceDate, items: newRecs });
  };

  const onSelectAllChange = (event: ChangeEvent<HTMLInputElement>): void => {
    //we always have to create a new array and copy everything
    //or when something else changes the state it breaks everything in weird ways
    let newRecs: IPSAReconciliation[] = [];
    response.items.forEach((reconciliation) => {
      let newRec = { ...reconciliation };
      newRec.reconcile = event.target.checked;
      newRecs.push(newRec);
    });
    setReconciliations({ invoiceDate: response.invoiceDate, items: newRecs });
    setSelectAll(event.target.checked);
  };

  const onRefreshClick = () => {
    getSettings();
  }

  const onSubmitClick = async () => {
    setLoading(true);
    try {
      const newRecon = await set.resellerPSAReconciliationsUpdate(
        response.items
      );
      setReconciliations({
        invoiceDate: response.invoiceDate,
        items: newRecon,
      });
      const notification: INotification = {
        title: "Reconciliations Updated",
        data: "The reconciliations have been updated.",
        variant: NOTIFICATION_TYPE.success,
        srcComponent: "ResellerPSAReconciliationsPage",
        srcData: "success",
        srcFunction: "onSubmitClick",
      };
      App.newNotification(notification);
    } catch (error) {
      App.newNotificationError("Failed to reconcile " + error.data);
    }
    setLoading(false);
  };

  const getSettings = async () => {
    setLoading(true);
    try {
      const reconciliations = await set.resellerPSAReconciliationsGet();
      setReconciliations(reconciliations);
    } catch (error) {
      App.newNotificationError("Failed to load reconciliations " + error.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getSettings();
  }, []);
  const centerStyle = {
    paddingLeft: "1em" as const,
    textAlign: "center" as const,
  };
  return (
    <div className="content">
      <PageHead title="PSA Reconciliation" parents={[]}></PageHead>

      {loading ? (
        <Loading />
      ) : (
        <Row className="whiteBox">
          <Col md="12" lg="12">
            <Card>
              <Card.Header>
                <h2>
                  PSA Reconciliation - {response.invoiceDate.substring(0, 10)}
                </h2>
              </Card.Header>
              <Card.Body className="form-card-body">
                {response.items.length === 0 ? (
                  <div>Everything is reconciled.</div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th style={centerStyle}>Customer</th>
                        <th style={centerStyle}>PSA Customer</th>
                        <th style={centerStyle}>Product</th>
                        <th style={centerStyle}>PSA Product</th>
                        <th style={centerStyle}>VIP Qty</th>
                        <th style={centerStyle}>PSA Qty</th>
                        <th style={centerStyle}>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={onSelectAllChange}
                            value="selectAll"
                            name="selectAll"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {response.items.map((reconciliation) => (
                        <ResellerPSAReconciliationForm
                          key={reconciliation.id}
                          reconciliation={reconciliation}
                          onReconcileChange={onReconcileChange}
                        />
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={centerStyle}>
                          <Button onClick={onSubmitClick}>
                            Reconcile Selected
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </Card.Body>
              <Card.Footer>
                <Button onClick={onRefreshClick}>Refresh</Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ResellerPSAReconciliationPage;

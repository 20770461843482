import React from "react";

import { TextField, Typography } from "@material-ui/core";
import { Field, FormSpy } from "react-final-form";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

interface IMuiTextInputMultilineProps {
  name: string;
  type?: string;
  label?: string;
  outlinedLabel?: string;
  required?: boolean;
  [x: string]: any;
}

const theme = createTheme({});

const MuiTextInputMultiline: React.FC<IMuiTextInputMultilineProps> = ({
  name,
  type = "text",
  label,
  outlinedLabel,
  required,
  ...rest
}) => {
  return (
    <FormSpy
      subscription={{
        dirtySinceLastSubmit: true,
        submitting: true,
      }}
    >
      {({ dirtySinceLastSubmit, submitting }) => (
        <>
          {label && <Typography variant="subtitle1">{label}</Typography>}
          <Field name={name}>
            {({ input, meta }) => (
              <ThemeProvider theme={theme}>
                <TextField
                  {...input}
                  name={name}
                  fullWidth
                  type={type}
                  variant="outlined"
                  label={outlinedLabel}
                  size="small"
                  required={required}
                  {...rest}
                  error={
                    (meta.error && meta.touched) ||
                    (!!meta.submitError && !dirtySinceLastSubmit && !submitting)
                  }
                  helperText={
                    meta.error && meta.touched
                      ? meta.error
                      : !!meta.submitError &&
                        !dirtySinceLastSubmit &&
                        !submitting
                      ? meta.submitError
                      : ""
                  }
                />
              </ThemeProvider>
            )}
          </Field>
        </>
      )}
    </FormSpy>
  );
};
export default MuiTextInputMultiline;

import { Box, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react"
import Loading from "../loading";
import MySettings from "../../static/settings";
import { INumber } from "../../interfaces/number";
import NumberSitePicker, { INumberSitePickerSubmit } from "../../pages/Number/NumberSitePicker";
import { ICustomersSearchItem } from "../../interfaces/customer";

interface INumberBatchUpdateModalProps {
    setModalOpen:(open:boolean)=>void;
    selectedNumbers:INumber[]
    customersId:string
}

enum PageState {
    OK,
    LOADING,
    SUCCESS
}

enum NumberState {
    OK,
    UPDATING,
    SUCCESS,
    FAILED
}

const set=new MySettings()
const getNumberStatus = (state?:NumberState) => {
    switch(state){
        case NumberState.UPDATING:
            return <span>Updating...</span>
        case NumberState.SUCCESS:
            return <span style={{color:'green'}}>Updated.</span>
        case NumberState.FAILED:
            return <span style={{color:'red'}}>Failed.</span>
    }
    return <span></span>
}
const NumbersBatchUpdateModal = (props:INumberBatchUpdateModalProps) => {
    const [customers, setCustomers] = useState<ICustomersSearchItem[]>([]);
    const [pageState,setPageState] = useState(PageState.OK)
    const [errorMessage,setErrorMessage] = useState('')
    const [ourNumbersUpdated,setOurNumbersUpdated] = useState<{[key:string]:NumberState}>({})
    const [updating,setUpdating] = useState(false)

    useEffect(() => {
        setPageState(PageState.LOADING)
        try {
          const getCustomers = async () => {
            const response = await set.resellerCustomerSearch("",false);
            response.items.sort((a,b)=>{
              return a.item.name.localeCompare(b.item.name)
            })
            setCustomers(response.items);
          };
          getCustomers();
        } catch (error) {
          setErrorMessage('There was a problem loading the customers.')
        }
        setPageState(PageState.OK)
      }, []);

    const onUpdateClick = async (data:INumberSitePickerSubmit) => {
        setErrorMessage('')
        setUpdating(true)
        const numbersUpdating:{[key:string]:NumberState} = {}
        for(let i=0;i<props.selectedNumbers.length;i++){
            const number=props.selectedNumbers[i]
            numbersUpdating[number.id]=NumberState.UPDATING
            setOurNumbersUpdated(numbersUpdating)
            try{
                await set.resellerNumberChangeOwner(number.id,data.customersSitesId,data.extensionNumber)
                numbersUpdating[number.id]=NumberState.SUCCESS
            }catch(err){
                numbersUpdating[number.id]=NumberState.FAILED
            }
            setOurNumbersUpdated({...numbersUpdating})
        }
        setPageState(PageState.SUCCESS)
    }

    const renderLoading = () => {
        return <Loading />
    }
    
    const renderOK = () => {
        return <>
                <Box style={{width:"100%",padding:10}}>
                    {errorMessage.length>0 && (<div style={{textAlign:'center',color:'red'}}>{errorMessage}<br /><br /></div>)}
                    <div>Please choose the customer and site you wish to point the selected numbers at.</div>
                    <br />
                    {props.selectedNumbers.map((number)=>{
                        return <div key={number.name}>
                            {number.name} {getNumberStatus(ourNumbersUpdated[number.id])}
                        </div>
                    })}
                    <br />
                    {updating ? (
                        <Loading />
                    ) : (
                        <NumberSitePicker
                        emptyFirstOption={true}
                        customers={customers}
                        userType="resellersContacts"
                        customersId={props.customersId}
                        customersSitesId={''}
                        submitFunction={onUpdateClick}
                        enableExtensionBox={true}
                        />
                    )}
                </Box>
            </>
    }

    const renderSuccess = () => {
        return <>
            <Box style={{width:"100%",padding:10}}>
                The numbers have been updated.
                {props.selectedNumbers.map((number)=>{
                    return <div key={number.name}>
                        {number.name} {getNumberStatus(ourNumbersUpdated[number.id])}
                    </div>
                })}
                <br />
                <Button fullWidth color="primary" style={{ marginTop: 10 }} variant="contained" onClick={() => {
                    props.setModalOpen(false)
                }}>Done</Button>
            </Box>
        </>
    }

    switch(pageState){
        case PageState.OK:
            return renderOK()
        case PageState.LOADING:
            return renderLoading()
        case PageState.SUCCESS:
            return renderSuccess()
    }

    return <></>
}

export default NumbersBatchUpdateModal
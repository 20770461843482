import Loading from "../../components/loading";
import MySettings, { IResellerCab9CallPoppingWebHook, IResellerCab9WallboardGetResponse, IResellerCab9WallboardWebhook } from "../../static/settings";
import App from "../../App";
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from '@material-ui/icons/Delete';


interface IProps {
    serviceId: string
}

enum PAGE_STATE {
    loading,
    loaded
}


const settings = new MySettings()
const Cab9Wallboard = (props: IProps) => {
    const isMounted = useRef(false);
    const [data, setData] = useState<IResellerCab9WallboardGetResponse>({
        apiToken:'',
        exists: false,
        queues: [],
        webhooks: [],
        passengerConnectEnabled:false,
        passengerConnectCallerId:'',
        passengerConnectHostname:'',
        callPoppingWebHooks:[],
        callRecordingWebHooks:[],
        legacyCallRecordingsLogLines:[],
        wallboardLastLogCmd:'',
        wallboardLastLogDateTime:'',
        wallboardLastLogResponse:''
    })
    const [pageState, setPageState] = useState(PAGE_STATE.loading)
    const [ringbackTestExtension, setRingbackTestExtension] = useState('')
    const [ringbackTestDestination, setRingbackTestDestination] = useState('')
    const [ringbackTestCallerID, setRingbackTestCallerID] = useState('')
    
    const [passengerConnectTestCaller, setPassengerConnectTestCaller] = useState('')
    const [passengerConnectTestDestination, setPassengerConnectTestDestination] = useState('')
    const [passengerConnectTestCallerID, setPassengerConnectTestCallerID] = useState('')

    const [callRecordingWebHookURL,setCallRecordingWebHookURL] = useState('')
    const [callRecordingWebHookCustomerToken,setCallRecordingWebHookCustomerToken] = useState('')

    const [callPoppingWebHookURL,setCallPoppingWebHookURL] = useState('')
    const [callPoppingWebHookCustomerToken,setCallPoppingWebHookCustomerToken] = useState('')

    const [wallboardWebHookURL,setWallboardWebHookURL] = useState('')
    const [wallboardWebHookCustomerToken,setWallboardWebHookCustomerToken] = useState('')
    const [wallboardWebHookPassword,setWallboardWebHookPassword] = useState('')

    const handleCreateWallboard = async () => {
        setPageState(PAGE_STATE.loading)
        try {
            let response = await settings.resellerCab9WallboardCreate(props.serviceId)
            if (response.success) {
                onload()
            } else {
                throw new Error({ data: 'Wallboard could not be created.' })
            }
        } catch (err: any) {
            setPageState(PAGE_STATE.loaded)
            App.newNotificationError('Problem creating Cab9 wallboard. ' + err.data)
        }
    }

    const handleTestPassengerConnect = async () => {
        setPageState(PAGE_STATE.loading)
        try{
            let response=await settings.resellerPassengerConnectTest(props.serviceId,passengerConnectTestCaller,passengerConnectTestDestination,passengerConnectTestCallerID)
            if(response.success){
                App.newNotificationSuccess('Passenger connect test started.')
            }else{
                App.newNotificationError('Passenger connect test could not be started.')
            }
        }catch(err){
            let message='Passenger connect test could not be started.'
            if(err && err.data){
                message=message+' '+err.data
            }
            App.newNotificationError(message)
        }
        setPageState(PAGE_STATE.loaded)
    }

    const handleTestRingback = async () => {
        setPageState(PAGE_STATE.loading)
        try{
            let response=await settings.resellerRingbackTest(props.serviceId,ringbackTestExtension,ringbackTestDestination,ringbackTestCallerID)
            if(response.success){
                App.newNotificationSuccess('Ringback test started.')
            }else{
                App.newNotificationError('Ringback test could not be started.')
            }
        }catch(err){
            let message='Ringback test could not be started.'
            if(err && err.data){
                message=message+' '+err.data
            }
            App.newNotificationError(message)
        }
        setPageState(PAGE_STATE.loaded)
    }

    const onAddCallRecordingWebHookClicked = async () => {
        setPageState(PAGE_STATE.loading)
        try{
            let response=await settings.resellerCallRecordingWebHookAdd(props.serviceId,callRecordingWebHookURL,callRecordingWebHookCustomerToken)
            if(response.success){
                onload()
                App.newNotificationSuccess('Web hook added.')
            }else{
                App.newNotificationError('Web hook could not be added.')
            }
        }catch(err){
            let message='Web hook could not be added.'
            if(err && err.data){
                message=message+' '+err.data
            }
            App.newNotificationError(message)
        }
        setPageState(PAGE_STATE.loaded)
    }

    const onAddWallboardWebHookClicked = async () => {
        setPageState(PAGE_STATE.loading)
        try{
            let response=await settings.resellerWallboardWebHookAdd(props.serviceId,wallboardWebHookURL,wallboardWebHookCustomerToken,wallboardWebHookPassword)
            if(response.success){
                onload()
                App.newNotificationSuccess('Web hook added.')
            }else{
                App.newNotificationError('Web hook could not be added.')
            }
        }catch(err){
            let message='Web hook could not be added.'
            if(err && err.data){
                message=message+' '+err.data
            }
            App.newNotificationError(message)
        }
        setPageState(PAGE_STATE.loaded)
    }

    const onAddCallPoppingWebHookClicked = async () => {
        setPageState(PAGE_STATE.loading)
        try{
            let response=await settings.resellerCallPoppingWebHookAdd(props.serviceId,callPoppingWebHookURL,callPoppingWebHookCustomerToken)
            if(response.success){
                onload()
                App.newNotificationSuccess('Web hook added.')
            }else{
                App.newNotificationError('Web hook could not be added.')
            }
        }catch(err){
            let message='Web hook could not be added.'
            if(err && err.data){
                message=message+' '+err.data
            }
            App.newNotificationError(message)
        }
        setPageState(PAGE_STATE.loaded)
    }

    const onload = async () => {
        //when we load we need to trigger fetching the cab9 wallboard details
        try {
            let response = await settings.resellerCab9WallboardGet(props.serviceId)
            setData(response)
            setPageState(PAGE_STATE.loaded)
        } catch (err: any) {
            App.newNotificationError('There was a problem loading the Cab9 wallboard details. ' + err.data)
        }
    }

    const onDeleteCallPoppingWebHookClicked = async (webhook:IResellerCab9CallPoppingWebHook) => {
        const confirmed=window.confirm('Are you sure you want to remove this call popping web hook?')
        if(confirmed){
            setPageState(PAGE_STATE.loading)
            try{
                let response = await settings.resellerDeleteCallPoppingWebHook(props.serviceId,webhook)
                if(response.success){
                    onload()
                }else{
                    throw {'data':'Could not delete the web hook.'}
                }
            }catch(err){
                let message='Problem deleting the web hook.'
                if(err && err.data){
                    message=message+' '+err.data
                }
                App.newNotificationError(message)
                setPageState(PAGE_STATE.loaded)
            }
        }
    }

    const onDeleteCallRecordingWebHookClicked = async (webhook:IResellerCab9CallPoppingWebHook) => {
        const confirmed=window.confirm('Are you sure you want to remove this call recording web hook?')
        if(confirmed){
            setPageState(PAGE_STATE.loading)
            try{
                let response = await settings.resellerDeleteCallRecordingWebHook(props.serviceId,webhook)
                if(response.success){
                    onload()
                }else{
                    throw {'data':'Could not delete the web hook.'}
                }
            }catch(err){
                let message='Problem deleting the web hook.'
                if(err && err.data){
                    message=message+' '+err.data
                }
                App.newNotificationError(message)
                setPageState(PAGE_STATE.loaded)
            }
        }
    }

    const onDeleteWallboardWebHookClicked = async (webhook:IResellerCab9WallboardWebhook) => {
        const confirmed=window.confirm('Are you sure you want to remove this web hook?')
        if(confirmed){
            setPageState(PAGE_STATE.loading)
            try{
                let response = await settings.resellerDeleteWallboardWebHook(props.serviceId,webhook)
                if(response.success){
                    onload()
                }else{
                    throw {'data':'Could not delete the web hook.'}
                }
            }catch(err){
                let message='Problem deleting the web hook.'
                if(err && err.data){
                    message=message+' '+err.data
                }
                App.newNotificationError(message)
                setPageState(PAGE_STATE.loaded)
            }
        }
    }

    useEffect(() => {
        if (isMounted.current === false) {
            isMounted.current = true;
            onload()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    switch (pageState) {
        case PAGE_STATE.loaded:
            return (<>
                <div>
                    <a href={"https://"+data.passengerConnectHostname+"/statuspagev1"} target="_blank" rel="noreferrer">Click here to check current status of incoming and outgoing calls, passenger connect and ringback.</a>
                    <div className="d-flex flex-rowd-flex justify-content-between align-text-bottom">
                        <div className="sidebarServiceName">
                            Call Popping
                        </div>
                        <div className="sidebarServiceStatus">
                            {data.callPoppingWebHooks.length>0 ? 'Enabled' : 'Disabled'}
                        </div>
                    </div>
                </div>
                {data.apiToken.length===0 ? (
                    <span style={{color:'red'}}>No API token. Please contact VIP VoIP to generate one.</span>
                ) : (
                    <div>
                        Example API usage to send to the integration partner:<br />
                        <pre>
                            curl -k --data 'token={data.apiToken}&amp;cmd=get' https://{data.passengerConnectHostname}/api/extensionStatusv1.php <br />
                            curl -k --data 'token={data.apiToken}&amp;cmd=add&amp;url=https://some.com&amp;customerToken=12345' https://{data.passengerConnectHostname}/api/extensionStatusv1.php <br />
                            curl -k --data 'token={data.apiToken}&amp;cmd=remove&amp;url=https://some.com&amp;customerToken=12345' https://{data.passengerConnectHostname}/api/extensionStatusv1.php
                        </pre>
                        Add a call popping web hook using the form below.
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField label="URL" value={callPoppingWebHookURL} onChange={(ev:any)=>{setCallPoppingWebHookURL(ev.target.value)}}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField label="Customer Token" value={callPoppingWebHookCustomerToken} onChange={(ev:any)=>{setCallPoppingWebHookCustomerToken(ev.target.value)}}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                            <Button variant="outlined" color="primary" onClick={onAddCallPoppingWebHookClicked}>Add Web Hook</Button>
                            </Grid>
                        </Grid>
                        <br />
                    </div>
                )}
                {data.callPoppingWebHooks.length===0 ? (
                    <div style={{color:'red'}}>Call popping web hooks are not set up. Please send the integration partner the above API examples and ask them to set them up.</div>
                ) : (
                    <div>
                        <div style={{color:'green'}}>Web hooks are set up. If the integration partner reports a problem please send these web hook details to them to confirm they are correct.</div>
                        {data.callPoppingWebHooks.map((webhook,index)=>{
                            return (<div key={index}>{webhook.url} {webhook.token}
                            <IconButton title="Delete" color="primary" style={{ color: "red" }} onClick={()=>{
                                    onDeleteCallPoppingWebHookClicked(webhook)
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>)
                        })}
                    </div>
                )}
                <br />
                <div>
                    <div className="d-flex flex-rowd-flex justify-content-between align-text-bottom">
                        <div className="sidebarServiceName">
                            Call Recording
                        </div>
                        <div className="sidebarServiceStatus">
                            {data.callRecordingWebHooks.length>0 ? 'Enabled' : 'Disabled'}
                        </div>
                    </div>
                </div>
                {data.apiToken.length===0 ? (
                    <span style={{color:'red'}}>No API token. Please contact VIP VoIP to generate one.</span>
                ) : (
                    <div>
                        Example API usage to send to integration partner:<br />
                        <pre>
                            curl -k --data 'token={data.apiToken}&amp;cmd=get' https://{data.passengerConnectHostname}/api/callRecordingWebHooksV1.php <br />
                            curl -k --data 'token={data.apiToken}&amp;cmd=add&amp;url=https://some.com&amp;customerToken=12345' https://{data.passengerConnectHostname}/api/callRecordingWebHooksV1.php <br />
                            curl -k --data 'token={data.apiToken}&amp;cmd=remove&amp;url=https://some.com&amp;customerToken=12345' https://{data.passengerConnectHostname}/api/callRecordingWebHooksV1.php
                        </pre>
                        Add a call recording web hook using the form below.
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField label="URL" value={callRecordingWebHookURL} onChange={(ev:any)=>{setCallRecordingWebHookURL(ev.target.value)}}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField label="Customer Token" value={callRecordingWebHookCustomerToken} onChange={(ev:any)=>{setCallRecordingWebHookCustomerToken(ev.target.value)}}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                            <Button variant="outlined" color="primary" onClick={onAddCallRecordingWebHookClicked}>Add Web Hook</Button>
                            </Grid>
                        </Grid>
                        <br />
                    </div>
                )}
                {data.callRecordingWebHooks.length===0 ? (
                    <div style={{color:'red'}}>Call recording web hooks are not set up. Please send the integration partner the above API examples and ask them to set them up.</div>
                ) : (
                    <div>
                        <span style={{color:'green'}}>Web hooks are set up. If problems are reported please send these web hook details to the integration partner to confirm they are correct.</span>
                        {data.callRecordingWebHooks.map((webhook,index)=>{
                            return (<div key={index}>
                                {webhook.url} {webhook.token} 
                                <IconButton title="Delete" color="primary" style={{ color: "red" }} onClick={()=>{
                                    onDeleteCallRecordingWebHookClicked(webhook)
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>)
                        })}
                    </div>
                )}
                <br />
                <div>
                    <div className="d-flex flex-rowd-flex justify-content-between align-text-bottom">
                        <div className="sidebarServiceName">
                            Passenger Connect
                        </div>
                        <div className="sidebarServiceStatus">
                            {data.passengerConnectEnabled ? 'Enabled' : 'Disabled'}
                        </div>
                    </div>
                </div>
                {(data.passengerConnectEnabled && data.apiToken.length>0) ? 
                    (<div>
                        <div style={{color:'green'}}>Passenger connect is enabled. Please send the below API example to the integration partner if they are having problems.</div>
                        <pre>
                        curl -k --data &quot;token={data.apiToken}&amp;caller=0784555XXXX&amp;destination=0114299XXXX&amp;callerId={data.passengerConnectCallerId}&quot; https://{data.passengerConnectHostname}/api/v1/passengerConnectV2.php
                        </pre>
                        To test passenger connect put your mobile number in as the driver number then put another mobile number or a test number pointed at a message player in as the passenger number.
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField label="Driver Number" value={passengerConnectTestCaller} onChange={(ev:any)=>{setPassengerConnectTestCaller(ev.target.value)}}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField label="Passenger Number" value={passengerConnectTestDestination} onChange={(ev:any)=>{setPassengerConnectTestDestination(ev.target.value)}}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField label="Caller ID" value={passengerConnectTestCallerID} onChange={(ev:any)=>{setPassengerConnectTestCallerID(ev.target.value)}}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                            <Button variant="outlined" color="primary" onClick={handleTestPassengerConnect}>Test Passenger Connect</Button>
                            </Grid>
                        </Grid>
                    </div>) : (
                        <div style={{color:'red'}}>Passenger connect is disabled. Please contact VIP VoIP to enable the passenger connect.</div>
                    )
                }
                <br />

                <div>
                    <div className="d-flex flex-rowd-flex justify-content-between align-text-bottom">
                        <div className="sidebarServiceName">
                            Ringback
                        </div>
                        <div className="sidebarServiceStatus">
                            {data.passengerConnectEnabled ? 'Enabled' : 'Disabled'}
                        </div>
                    </div>
                </div>
                {(data.passengerConnectEnabled && data.apiToken.length>0) ? 
                    (<div>
                        <div style={{color:'green'}}>Ringback is enabled. Please send the below API example to the integration partner if they are having problems.</div>
                        <pre>
                        curl -k --data &quot;token={data.apiToken}&amp;extension=XXX&amp;destination=0784555XXXX&amp;callerId={data.passengerConnectCallerId}&amp;waittime=7&quot; https://{data.passengerConnectHostname}/api/makeCallV2.php
                        </pre>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField label="Ringback Message Player Number" value={ringbackTestExtension} onChange={(ev:any)=>{setRingbackTestExtension(ev.target.value)}}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField label="Destination" value={ringbackTestDestination} onChange={(ev:any)=>{setRingbackTestDestination(ev.target.value)}}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField label="Caller ID" value={ringbackTestCallerID} onChange={(ev:any)=>{setRingbackTestCallerID(ev.target.value)}}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                            <Button variant="outlined" color="primary" onClick={handleTestRingback}>Test Ringback</Button>
                            </Grid>
                        </Grid>
                    </div>) : (
                        <div style={{color:'red'}}>Ringback is disabled. Please contact VIP VoIP to enable the ringback API.</div>
                    )
                }
                <br />

                <div>
                    <div className="d-flex flex-rowd-flex justify-content-between align-text-bottom">
                        <div className="sidebarServiceName">
                            Wallboard
                        </div>
                        <div className="sidebarServiceStatus">
                            {data.exists ? 'Enabled' : 'Disabled'}
                        </div>
                    </div>
                </div>

                {data.apiToken.length===0 && (
                    <span style={{color:'red'}}>No API token. Please contact VIP VoIP to generate one.</span>
                )}



                {data.exists && <>
                    <div className="sidebarServiceName">Queues Tracked By The Wallboard</div>
                    {data.queues.length === 0 ? <p style={{color:'red'}}>No queues. This is a problem. Please create some queues on the server and then recreate this wallboard.</p> : 
                        <div>
                            {data.queues.map((queue) => {
                                return <span key={queue}>{queue} </span>
                            })}
                        </div>}

                            <br />
                    <div className="sidebarServiceName">Wallboard Webhooks</div>
                    Add a wallboard web hook using the form below.
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField label="URL" value={wallboardWebHookURL} onChange={(ev:any)=>{setWallboardWebHookURL(ev.target.value)}}></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField label="Customer Token" value={wallboardWebHookCustomerToken} onChange={(ev:any)=>{setWallboardWebHookCustomerToken(ev.target.value)}}></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField label="Password" value={wallboardWebHookPassword} onChange={(ev:any)=>{setWallboardWebHookPassword(ev.target.value)}}></TextField>
                        </Grid>
                        <Grid item xs={3}>
                        <Button variant="outlined" color="primary" onClick={onAddWallboardWebHookClicked}>Add Web Hook</Button>
                        </Grid>
                    </Grid>
                    <br />
                    {data.webhooks.length === 0 ? (
                        <p style={{color:'red'}}>
                            No web hooks set. This is a problem. Please get your integration partner to create the web hooks.<br />
                            The API key to send them is {data.apiToken}
                        </p>
                        
                    ) : (
                        <div>
                            <div style={{color:'green'}}>Web hooks are set up. If a problem is reported please send these web hook details to the integration partner to confirm they are correct.</div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>URL</th>
                                        <th>Customer Token</th>
                                        <th>Password</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data.webhooks.map((webhook,index)=>{
                                    return (<tr key={index}>
                                        <td>{webhook.url}</td>
                                        <td>{webhook.token}</td>
                                        <td>{webhook.password}</td>
                                        <td><IconButton title="Delete" color="primary" style={{ color: "red" }} onClick={()=>{
                                        onDeleteWallboardWebHookClicked(webhook)
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                    </td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                            
                            <br />
                            <div className="sidebarServiceName">Wallboard Webhook Logs</div>
                            <div>Last wallboard update sent at {data.wallboardLastLogDateTime.replace('T',' ')}.</div>
                            <br />
                            <div>Last wallboard update sent:</div>
                            <pre>
                                {data.wallboardLastLogCmd}
                            </pre>
                            <div>Last wallboard update response:</div>
                            <pre>
                                {data.wallboardLastLogResponse}
                            </pre>
                        </div>
                    )}
                </>}
                <Button style={{ marginTop: 10 }} variant="outlined" color="primary" fullWidth onClick={handleCreateWallboard}> {data.exists !== true ? 'Create Wallboard' : 'Re-Create Wallboard'}</Button>
                <br />
                <br />
                <br />
                <div className="sidebarServiceName">Legacy Call Recording Logs</div>
                    {data.legacyCallRecordingsLogLines.length>0 ? (<pre>
                        {data.legacyCallRecordingsLogLines.map((line)=>{
                            return line+"\n"
                        })}
                    </pre>) : (<div>
                        No legacy log lines. This may not be a problem if the integration is using web hooks.
                    </div>)}
            </>)
        case PAGE_STATE.loading:
            return (<Loading />)
        default:
            return (<>Cab9</>)
    }
}
export default Cab9Wallboard
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Accordion, Button, Card, Col } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { Redirect, RouteComponentProps } from "react-router-dom";
import App from "../../App";
import catchME from "../../catchME";
import Loading from "../../components/loading";
import PageHead from "../../components/PageHead";
import VipCheckbox from "../../components/VipCheckbox";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { IAccessGroup } from "../../interfaces/access-group";
import { INotification } from "../../interfaces/notification";
import { IPageProps } from "../../interfaces/page-props";
import MySettings from "../../static/settings";
library.add(faChevronDown, faChevronUp);
const set = new MySettings();

function sortPermissions(permissions:any[]){
  permissions.sort((a,b)=>{
    return a.N.localeCompare(b.N)
  })
  permissions.sort((a,b)=>{
    const aType=typeof a.V==='object'
    const bType=typeof b.V==='object'
    if(aType===bType){
      return 0
    }else if(aType && !bType){
      return -1
    }else{
      return 1
    }
  })
  for(let i=0;i<permissions.length;i++){
    if(permissions[i].D!=null){
      permissions[i].D=sortPermissions(permissions[i].D)
    }
    if(permissions[i].id!=null){
      permissions[i].id=i
    }else if(permissions[i].id2!=null){
      permissions[i].id2=i
    }
  }

  return permissions
}

function CustomToggle({ children, eventKey, data }: any) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    //     console.log('totally custom!', data);
    //  setAccoridanKey(eventKey);
  });

  //console.log(data);
  return (
    <Card.Header>
      <div className="d-flex flex-rowd-flex justify-content-between">
        <div onClick={decoratedOnClick}>
          {"" === eventKey ? (
            <FaIcon fixedWidth icon="chevron-up" />
          ) : (
            <FaIcon fixedWidth icon="chevron-down" />
          )}
          {data.N}
        </div>
        <div> {children}</div>
      </div>
    </Card.Header>
  );
}

export interface IPermssionState {
  loading: boolean;
  results: any[];
  accordian: string;
  group?: IAccessGroup;
  redirect: boolean;
}

interface ICustomerDefaultGroupPage extends RouteComponentProps<any>, IPageProps { }
export default class CustomerDefaultGroupPage extends React.Component<
  ICustomerDefaultGroupPage,
  IPermssionState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      results: [],
      loading: true,
      accordian: "",
      redirect: false,
    };

    this.subSet.bind(this);
    this.save.bind(this);
  }

  public componentDidMount = () => {
    this.getCustomerDefaultGroups();
  };
  // THE actual post
  public getCustomerDefaultGroups = () => {
    const requestedID = this.props.match.params.id;

    if (this.props.session.userType === "resellersContacts") {
      if (this.props.resellerID) {

      } else {
        set
          .resellerDefaultCustomerPortalAccessGroupsGet()
          .then((resp) => {
            const groups = resp.items;

            const index = groups.findIndex((ddd) => ddd.id === requestedID);

            const payload: any = { ...groups[index].permissions };
            let arrayOfPermissions: any[] = [];

            if (Object.getOwnPropertyNames(payload).length > 0) {
              Object.getOwnPropertyNames(payload).map((dd, id) => {
                let tempObject: any = { id: id, N: dd, D: [], VV: false };

                //   console.log(typeof payload[dd]);
                if (typeof payload[dd] !== "boolean") {
                  const propNames = Object.getOwnPropertyNames(payload[dd]);
                  const totalProps = propNames.length;
                  let trueProps = 0;
                  propNames.map((dds, id2) => {
                    const value = payload[dd][dds];
                    if (value === true) {
                      trueProps++;
                    }
                    tempObject.D.push({ id2: id2, N: dds, V: value });
                    return null;
                  });

                  if (trueProps === totalProps) {
                    tempObject.VV = true;
                  }
                } else {
                  delete tempObject.D;
                  tempObject.VV = payload[dd];
                }
                arrayOfPermissions.push(tempObject);
                return null;
              });
            } else {
              const ballake: any = { ...resp.permissionsTree.data }
              //load template
              Object.getOwnPropertyNames(ballake).map((dd, id) => {
                let tempObject: any = { id: id, N: dd, D: [], VV: false };

                //   console.log(typeof payload[dd]);
                if (typeof payload[dd] !== "boolean") {
                  const propNames = Object.getOwnPropertyNames(ballake[dd]);
                  const totalProps = propNames.length;
                  let trueProps = 0;
                  propNames.map((dds, id2) => {
                    const value = ballake[dd][dds];
                    if (value === true) {
                      trueProps++;
                    }
                    tempObject.D.push({ id2: id2, N: dds, V: value });
                    return null;
                  });

                  if (trueProps === totalProps) {
                    tempObject.VV = true;
                  }
                } else {
                  delete tempObject.D;
                  tempObject.V = ballake[dd];
                }
                arrayOfPermissions.push(tempObject);
                return null;
              });
            }

            arrayOfPermissions=sortPermissions(arrayOfPermissions)
            this.setState({
              group: { ...groups[index] },
              loading: false,
              results: arrayOfPermissions,
            });
          })
          .catch((exception) => {
            console.log(exception);
            catchME(this, exception, false);
          });
      }
    } else {

    }
  };

  public subSet = (value: boolean, element: any, subElement?: any) => {
    console.log('PBB',value, element, subElement);

    const { results } = this.state;
    let newResults = [...results];
    this.setState({ results: [] }, () => {
      const index = results.findIndex((ddd) => ddd.id === element.id);
      //  console.log(index);
      let parentNode = { ...results[index] };
      //   const c = results[index];
      if (subElement === undefined) {
        parentNode.VV = value;
        if (parentNode.D !== undefined) {
          let newD = parentNode.D.map((d: any) => {
            d.V = value;
            return d;
          });
          delete parentNode.D;
          parentNode.D = newD;
        }
        console.log('PBB',parentNode)
      } else {
        //  console.log(parentNode);
        const index2 = parentNode.D.findIndex(
          (ddd: any) => ddd.id2 === subElement.id2
        );

        let childNode = { ...parentNode.D[index2] };
        childNode.V = value;

        parentNode.D[index2] = childNode;
      }
      newResults[index] = parentNode;
      this.setState({ results: newResults });
    });
  };

  public save() {
    //convert

    if (this.state.group !== undefined) {
      let update = this.state.group;
      const data: any[] = [...this.state.results];
      let json: any = {};
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let obj: any = {};

        if (element.D !== undefined && element.D.length>0) {
          for (let index2 = 0; index2 < element.D.length; index2++) {
            const element2 = element.D[index2];
            obj[element2.N] = element2.V;
          }
        } else {
          obj = element.VV;
        }
        json[element.N] = obj;
      }
      update.permissions = json;
      console.log('PBB',update)

      set.resellerCustomerDefaultPortalAccessGroupsUpdate(update).then((updated) => {
        //   console.log(updated);
        const notification: INotification = {
          title: "Success",
          data: "Default access group saved.",
          variant: NOTIFICATION_TYPE.info,
          srcFunction: "componentDidUpdate",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification, true);
        this.setState({ redirect: true })
      });

    }
  }

  public render() {
    const { loading, results, group, redirect } = this.state;

    let title = "Customer Default Group ";

    if (group !== undefined) {
      title = group.name;
    }

    return (
      <div className="content">
        {redirect === true && <Redirect to="/customerdefaultgroups" />}
        <Col md="12" className="text-left">
          <PageHead
            resellerID={this.props.resellerID}
            title={title}
            parents={[
              { title: "Permissions", url: "../permissions" },
              { title: "Customer Default Groups", url: "../customerdefaultgroups" }]}
          ></PageHead>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Accordion>
                {results.map((element, dIndex) => {
                  const indexD = dIndex.toString();
                  return (
                    <Card key={indexD}>
                      <CustomToggle eventKey={indexD} data={element}>
                        <VipCheckbox
                          field="V"
                          value={element.VV}
                          updateField={(ddd, bool) => {
                            //  console.log('sss', ddd);
                            this.subSet(bool, element);
                          }}
                          text=""
                        />
                      </CustomToggle>
                      <Accordion.Collapse eventKey={indexD}>
                        <Card.Body>
                          {element.D !== undefined && (
                            <>
                              {element.D.map(
                                (subDDD: any, subIndex: number) => {
                                  return (
                                    <div
                                      key={subIndex.toString()}
                                      className="d-flex flex-rowd-flex justify-content-between"
                                    >
                                      <div>{subDDD.N}</div>
                                      <div>
                                        <VipCheckbox
                                          field="V"
                                          value={subDDD.V}
                                          updateField={(ddd, bool) => {
                                            //  console.log('sss', ddd);
                                            this.subSet(bool, element, subDDD);
                                          }}
                                          text=""
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>

              <Button
                onClick={() => {
                  this.save();
                }}
                className="noleft mt-3"
              >
                Save
              </Button>
            </>
          )}
        </Col>
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";

import MySettings, { IAreaCode } from "../../static/settings";

import { IPageProps } from "../../interfaces/page-props";

import { ButtonGroup, Col, Row } from "react-bootstrap";
import Error from "../../components/error";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INewServiceBase } from "../../interfaces/new-service";
import { IProduct } from "../../interfaces/products";
import { ISite, ISitesGetHttpResponse } from "../../interfaces/site";
import SiteSelect from "./SiteSelect";

import { RouteComponentProps } from "react-router-dom";
import App from "../../App";
import { INotification } from "../../interfaces/notification";
import NewServiceForm2 from "./NewServiceV2";

const set = new MySettings();

export interface IProvisionState {
  sites: ISite[];
  areaCodes: IAreaCode[];
  products: IProduct[];
  data: INewServiceBase;
  selectedSite: ISite;
}
interface INewServicePage extends RouteComponentProps<any>, IPageProps { }

const NewServicePage: React.FC<INewServicePage> = (props) => {
  const [data, setData] = useState<IProvisionState>({
    sites: [],
    areaCodes: [],
    products: [],
    data: { customersSitesId: "", service: "", notes: "" },
    selectedSite: {
      connectwiseManageAgreementsId: "",
      connectwiseManageCompaniesId: "",
      county: "",
      id: "",
      line1: "",
      line2: "",
      customersId: "",
      description: "",
      city: "",
      postCode: "",
    },
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const getSites = async () => {
      try {
        const getSitesResponse: ISitesGetHttpResponse = await set.sitesGet(
          props.match.params.id,
          false,
          false
        );

        if (isMounted) {
          setData((prevState: IProvisionState) => {
            return {
              ...prevState,
              sites: getSitesResponse.items,
            };
          });

          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };

    getSites();

    return () => {
      isMounted = false;
    };
  }, [props.match.params.id]);

  let title: string = "New Service";

  const submit = async (payload: any) => {
    setLoading(true);
    console.log(payload);
    let IdStrings: any = [];

    payload.customersSitesId = data.selectedSite.id;
    switch (payload.service) {
      case "voipService":
        try {
          payload.customersSitesId = data.selectedSite.id;
          payload.estimatedNumberOfExtensions = parseInt(
            payload.estimatedNumberOfExtensions
          );
          let city = "";
          if (payload.city && payload.city.value) {
            city = payload.city.value;
          }

          await set.resellerVoipServiceAdd({ ...payload, city: city }, false);
          setLoading(false);
          const notification: INotification = {
            title: "Success",
            data: "VOIP Service Added Successfully",
            variant: NOTIFICATION_TYPE.info,
            srcFunction: "saveService",
            srcData: window.location.pathname,
            srcComponent: "NewServicePage",
          };
          App.newNotification(notification, true);

          props.history.push(`../`);
        } catch (error) {
          if (error.status === 500) {
            const notification: INotification = {
              title: "Error",
              data: "Site already has a VOIP service.",
              variant: NOTIFICATION_TYPE.info,
              srcFunction: "submit",
              srcData: window.location.pathname,
              srcComponent: "NewServicePage",
            };
            App.newNotification(notification, true);
          }
          setLoading(false);
          setError(true);
        }

        break;
      case "numberPort":
        try {
          if (
            !payload.numberPortNumbers ||
            payload.numberPortNumbers.length === 0
          ) {
            // eslint-disable-next-line
            throw "You must provide at least one number for porting."
          }
          if (!payload.newFile) {
            // eslint-disable-next-line
            throw "You must upload a completed number port form.";
          }
          const resellerNumberPortAddResult = await set.resellerNumberPortAdd(
            payload
          );
          setLoading(false);
          if (resellerNumberPortAddResult.success === true) {
            const notification: INotification = {
              title: "Success",
              data: "Number port request submitted.",
              variant: NOTIFICATION_TYPE.info,
              srcFunction: "savePortNumbers",
              srcData: window.location.pathname,
              srcComponent: "NewServicePage",
            };
            App.newNotification(notification, true);
            props.history.push(`../`);
          } else {
            const notification: INotification = {
              title: "Failed",
              data: "Failed to submit the number port request.",
              variant: NOTIFICATION_TYPE.danger,
              srcFunction: "savePortNumbers",
              srcData: window.location.pathname,
              srcComponent: "NewServicePage",
            };
            App.newNotification(notification, true);
            props.history.push(`../`);
          }
        } catch (error) {

          setLoading(false);
          App.newNotificationError(error);
          props.history.push(`../`);

        }

        break;

      case "voipIntergration":
        try {
          await set.resellerVoipIntegrationAdd(
            data.selectedSite.id,
            payload.voipIntergrationName,
            payload.voipIntergrationLevel
          );
          setLoading(false);
          const notification: INotification = {
            title: "Success",
            data: "Integration Order Successful",
            variant: NOTIFICATION_TYPE.info,
            srcFunction: "savePortNumbers",
            srcData: window.location.pathname,
            srcComponent: "NewServicePage",
          };
          App.newNotification(notification, true);

          props.history.push(`../`);
        } catch (error) {
          setLoading(false);
          setError(true);
        }

        break;
      case "buyPhones":
        for (let i = 0; i < payload.buyPhonesArray.length; i++) {
          payload.buyPhonesArray[i].qty = +payload.buyPhonesArray[i].qty;
        }
        try {
          await set.resellerBuyPhones(
            data.selectedSite.id,
            payload.buyPhoneAdress,
            payload.buyPhonesArray
          );
          console.log(payload.buyPhoneAdress);

          setLoading(false);

          const notification: INotification = {
            title: "Success",
            data: "Buy Phones Order Successful",
            variant: NOTIFICATION_TYPE.info,
            srcFunction: "savePortNumbers",
            srcData: window.location.pathname,
            srcComponent: "NewServicePage",
          };
          App.newNotification(notification, true);

          props.history.push(`../`);
        } catch (error) {
          setLoading(false);
          setError(true);
        }

        break;

      case "internetServiceEnquiry":
        try {
          await set.resellerInternetServiceEnquiryAdd(payload);
          setLoading(false);
          const notification: INotification = {
            title: "Success",
            data: "Your Internet service enquiry has been received and will be processed in the next few days.",
            variant: NOTIFICATION_TYPE.info,
            srcFunction: "",
            srcData: window.location.pathname,
            srcComponent: "",
          };
          App.newNotification(notification, true);
          props.history.push(`../`);
        } catch (error) {
          console.log(error);
          setLoading(false);
          setError(true);
        }
        break;

      case "resellerTeamsCallRecordingAdd":
        if (payload.customers.length > 0) {
          for (let i = 0; i < payload.customers.length; i++) {
            IdStrings.push(payload.customers[i].id);
          }
        }

        try {
          await set.resellerTeamsCallRecordingAdd(
            data.selectedSite.id,
            payload.notes,
            +payload.teamsCallRecordingLicenseLimit,
            IdStrings
          );

          setLoading(false);
          const notification: INotification = {
            title: "Success",
            data: "The Teams call recording is now active. Please advise the customer to set up the instructions for the recording.",
            variant: NOTIFICATION_TYPE.info,
            srcFunction: "",
            srcData: window.location.pathname,
            srcComponent: "",
          };
          App.newNotification(notification, true);
          props.history.push(`../`);
        } catch (error) {
          setLoading(false);
          let message = "There was a problem adding the service.";
          if (error && error.data) {
            message = error.data;
          }
          App.newNotificationError(message);
        }
        break;

      case "virtualReceptionistAdd":
        if (payload.customers.length > 0) {
          for (let i = 0; i < payload.customers.length; i++) {
            IdStrings.push(payload.customers[i].id);
          }
        }

        try {
          await set.resellerVirtualReceptionistAdd(
            data.selectedSite.id,
            payload.notes,
            // payload.specialInstructions,
            IdStrings
          );

          setLoading(false);
          const notification: INotification = {
            title: "Success",
            data: "The VIP Receptionist service is now active. Please advise the customer to set up the instructions for the receptionists.",
            variant: NOTIFICATION_TYPE.info,
            srcFunction: "",
            srcData: window.location.pathname,
            srcComponent: "",
          };
          App.newNotification(notification, true);
          props.history.push(`../`);
        } catch (error) {
          setLoading(false);
          let message = "There was a problem adding the service.";
          if (error && error.data) {
            message = error.data;
          }
          App.newNotificationError(message);
        }
        break;

      case 'resellerWallboardAdd':
        try{
          await set.resellerWallboardAdd(data.selectedSite.id,payload.notes)
          App.newNotificationSuccess('The wallboard has been requested and you should receive a response in 48 hours.')
          props.history.push(`../`);
        }catch(error){
          let message = "There was a problem adding the service.";
          if (error && error.data) {
            message = error.data;
          }
          App.newNotificationError(message);
        }
        setLoading(false)
        break;
      default:
        alert("Api Required");
        setLoading(false);
        setError(true);
        break;
    }
  };

  const setSite = async (site: ISite) => {
    setData((prevState: IProvisionState) => {
      return {
        ...prevState,
        data: { customersSitesId: site.id, service: "", notes: "" },
        selectedSite: site,
      };
    });
    setLoading(true);

    const areaCodesGetResult = await set.areaCodesGet();
    const resellerAvailableProductsGetResult =
      await set.resellerAvailableProductsGet();

    setData((prevState: IProvisionState) => {
      return {
        ...prevState,
        areaCodes: areaCodesGetResult.areaCodes,
        products: resellerAvailableProductsGetResult.items,
      };
    });
    setLoading(false);
  };

  return (
    <>
      <div className="content">
        <PageHead title={title} parents={[]}>
          <ButtonGroup></ButtonGroup>
        </PageHead>

        {error ? (
          <Row>
            <Col md="12">
              There was a problem loading the sites for this customer. Please
              try again later and check your permissions.
            </Col>
          </Row>
        ) : (
          <Row>
            {!data.selectedSite.id ? (
              <Col md="12">
                Please choose a site for the service or enquiry. If the site is
                not there then please add a new site.
                <SiteSelect
                  customersId={props.match.params.id}
                  allowNew={true}
                  sites={data.sites}
                  onSelect={(site: ISite) => {
                    setSite(site);
                  }}
                />
              </Col>
            ) : (
              <></>
            )}
            {data.selectedSite.id && (
              <Col md="12">
                {error ? (
                  <Error />
                ) : (
                  <NewServiceForm2
                    loading={loading}
                    products={data.products}
                    data={data.data}
                    site={data.selectedSite}
                    areaCodes={data.areaCodes}
                    resellerID={props.resellerID ? props.resellerID : ""}
                    customersId={props.match.params.id}
                    onSubmit={(data: any) => {
                      submit(data);
                    }}
                  />
                )}
              </Col>
            )}
          </Row>
        )}
      </div>
    </>
  );
};

export default NewServicePage;

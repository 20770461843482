
import * as React from 'react';
import { useState } from 'react';
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import App from '../../App';
import Loading from '../../components/loading';
import { NOTIFICATION_TYPE } from '../../enum/notification-type';
import { INotification } from '../../interfaces/notification';
import { ISite } from '../../interfaces/site';
import MySettings from '../../static/settings';
import SiteForm from '../Sites/SiteForm';
const set = new MySettings();
interface ISiteSelectProps {
    allowNew:boolean
    sites:ISite[]
    onSelect: (site:ISite) => void
    customersId:string
}
const SiteSelect: React.FunctionComponent<ISiteSelectProps> = (props) => {
    const site:ISite={
        connectwiseManageAgreementsId: "",
        connectwiseManageCompaniesId: "",
        county: "",
        id: "new",
        line1: "",
        line2: "",
        customersId:props.customersId,
        description: "",
        city: "",
        postCode: ""
      }
    const [addingNew, setAddingNew] = useState(false)
    const [savingNew, setSavingNew] = useState(false)
    const [sites, setSites] = useState(props.sites)

    //needed because useState does not work with arrays very well
    React.useEffect(()=>{
        setSites(props.sites)
    },[props])

    const submitForm = async (data: ISite) => {
        setSavingNew(true);
        try {
            let site = await set.sitesAdd(data);
            const notification: INotification = {
              title: "Success",
              data: "Site created.",
              variant: NOTIFICATION_TYPE.info,
              srcFunction: "submitForm",
              srcData: window.location.pathname,
              srcComponent: "App",
            };
            App.newNotification(notification, true);
            setAddingNew(false)
            let newSites=sites
            newSites.push(site)
            setSites(newSites)
        } catch (error) {
            const notification: INotification = {
                title: "Failed",
                data: error+'',
                variant: NOTIFICATION_TYPE.danger,
                srcFunction: "submitForm",
                srcData: window.location.pathname,
                srcComponent: "App",
            }
            App.newNotification(notification, true);
        }
        setSavingNew(false);
    }

    const deleteSite = () => {}

    const onNewSiteBack = () => {
        setAddingNew(false)
    }

    return (
        <>
            {addingNew ? (
                <Row>
                    <Col>
                    {savingNew ? (
                        <Loading />
                    ) : (
                        <SiteForm onBack={onNewSiteBack} data={site} onSubmit={submitForm} onDelete={deleteSite} />
                    )}
                    </Col>
                </Row>
            ) : (
                <Row>
                    {sites.map((site: ISite) => {
                        const siteLink = '/reseller/'+site.customersId+ '/site/' + site.id;
                        return <Col key={site.id} md={4}><br />
                            <p>{site.description}</p>
                            <p>{site.line1}</p>
                            <p>{site.line2}</p>
                            <p>{site.city}</p>
                            <p>{site.county}</p>
                            <p>{site.postCode}</p>
                            <ButtonGroup>
                                <Button onClick={(ev: any) => {
                                    ev.preventDefault();
                                    props.onSelect(site);
                                }}>Choose Site</Button>

                                <Link className="btn btn-primary" to={siteLink}>Edit Site</Link>
                            </ButtonGroup>
                        </Col>
                        })
                    }
                    {props.allowNew && (<Col md={4}>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <ButtonGroup>
                            <Button onClick={(ev: any) => {
                                ev.preventDefault();
                                setAddingNew(true)
                            }}>Add Another Site</Button>
                        </ButtonGroup>
                    </Col>)}
                </Row>
            )}
        </>
    )
}

export default SiteSelect;

/*


        



                
*/
import React, { useState } from "react"
import { IWallboardWidgetResetEvent } from "../../interfaces/wallboards"
import { Button, Card } from "react-bootstrap";
import Loading from "../../components/loading";
import MySettings from "../../static/settings";
import App from "../../App";
import VipTimeFieldMui from "../../components/VipTimeFieldMui";
import { Grid } from "@material-ui/core";

interface IWallboardWidgetResetEventsFormProps {
    resetEvents:IWallboardWidgetResetEvent[]
    widgetId:string
}

const set = new MySettings();

const WallboardWidgetResetEventsForm: React.FC<IWallboardWidgetResetEventsFormProps> = (props:IWallboardWidgetResetEventsFormProps) => {
    const [loading, setLoading] = useState(false);
    const [resetEvents, setResetEvents] = useState(props.resetEvents)
    const [resetTime, setResetTime] = useState("06:00")

    const onAddClick = async () => {
        setLoading(true)
        try{
            let ourResetTime=resetTime;
            if(resetTime.length>5){
                ourResetTime=resetTime.substring(0,5)
            }
            const resetEventsResponse=await set.wallboardWidgetAddResetEvent(props.widgetId,ourResetTime)
            setResetEvents(resetEventsResponse.resetEvents)
            App.newNotificationSuccess('Reset time added.')
        }catch(err){
            App.newNotificationError("Failed to add the reset time " + err.data);
        }
        setLoading(false)
    }
    
    const onDeleteClick = async (event:IWallboardWidgetResetEvent) => {
        const confirmed=window.confirm('Are you sure you want to delete this reset time?')
        if(confirmed){
            setLoading(true)
            try{
                const resetEventsResponse=await set.wallboardWidgetDeleteResetEvent(event.wallboardWidgetSettingsId,event.id)
                setResetEvents(resetEventsResponse.resetEvents)
                App.newNotificationSuccess('Reset time deleted.')
            }catch(err){
                App.newNotificationError("Failed to delete the reset time " + err.data);
            }
            setLoading(false)
        }
    }

    const setField = (field: string, value: any) => {
        setResetTime(value)
    }

    if(loading){
        return <Loading />
    }

    return (<Card>
        <Card.Header as="h6">Reset Times ({props.widgetId})</Card.Header>
        <Card.Body>
          <table>
            <thead>
              <tr>
                </tr>
                </thead>
                <tbody>
                {resetEvents.map((event)=>{
                    return <tr>
                        <td>{event.time}</td>
                        <td><Button
                          onClick={() => { onDeleteClick(event) }}
                          variant="outline-danger"
                        >
                          Delete
                        </Button></td>
                    </tr>
                })}
                </tbody>
            </table>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <VipTimeFieldMui
                        field="resetTime"
                        value={resetTime}
                        updateField={setField}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={onAddClick}>Add Reset Time</Button>
                </Grid>
            </Grid>
        </Card.Body>
    </Card>
    )
}

export default WallboardWidgetResetEventsForm;
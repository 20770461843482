import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import App from "../../App";
import Error from "../../components/error";
import Loading from "../../components/loading";
import PersonForm from "../../components/oldForms/form-person";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { IAccessGroup } from "../../interfaces/access-group";
import { IExtension } from "../../interfaces/extension";
import { INotification } from "../../interfaces/notification";
import { IPageProps } from "../../interfaces/page-props";
import { IPerson } from "../../interfaces/people";
import { ISite } from "../../interfaces/site";
import { IWallboardLayout } from "../../interfaces/wallboards";
import MySettings, { IAppLoginAttempt } from "../../static/settings";
import SpeedDials from "./SpeedDials";

const set = new MySettings();

export interface IPersonState {
  data: IPerson;
  loginAttempts: IAppLoginAttempt[];
  accessGroups: IAccessGroup[];
  wallboards: IWallboardLayout[];
  sites: ISite[];
  extensions: IExtension[];
}
interface IPersonPage extends RouteComponentProps<any>, IPageProps { }
const Person: React.FC<IPersonPage> = (props) => {
  const [personData, setPersonData] = useState<IPersonState>({
    accessGroups: [], //potential access groups
    loginAttempts: [],
    data: {
      id: "new",
      lastName: "",
      title: "",
      name: "",
      emailAddress: "",
      firstName: "",
      telephone: "",
      salutation: "",
      line1: "",
      line2: "",
      line3: "",
      town: "",
      county: "",
      postCode: "",
      portalAccess: false,
      portalAccessGroups: [], //groups the user is currently in
      faxNumber: "",
      wallboards: [],
      customersId: props.resellerID ? props.resellerID : "",
      usesMFA: false,
      callRecordingsAllowedExtensions: [],
    },
    wallboards: [],
    sites: [],
    extensions: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // console.log("PROPS", props);
  // console.log("STATE", personData);

  useEffect(() => {
    let isMounted = true;

    const getPerson = async () => {
      try {
        const sites = await set.sitesGet(
          props.resellerID ? props.resellerID : "",
          true,
          false
        );
        const extensions = await set.extensionsGetForCustomer(
          props.resellerID ? props.resellerID : "", ['US', 'HD', 'EX'],''
        );

        const dataAccessGroups = await set.getAccessGroups(props.resellerID);
        const wallboards = await set.wallboardsGet(
          props.resellerID ? props.resellerID : ""
        );
        
        if (props.match.params.id !== "new" && isMounted) {
          const dataPersonGet = await set.personGet(props.match.params.id,true);
          // console.log("dataPersonGet", dataPersonGet);


          let failedLoginAttempts: IAppLoginAttempt[] = [];
          if (props.resellerID) {
            try {
              failedLoginAttempts = await (await set.resellerPersonAppLoginAttemptsGet(props.match.params.id)).items
              console.log("failedLoginAttempts", failedLoginAttempts)
            } catch (ex) {
              const notification: INotification = {
                title: "Error",
                data: "Failed to fetch failed login attempts",
                variant: NOTIFICATION_TYPE.danger,
                srcFunction: "load",
                srcData: window.location.pathname,
                srcComponent: "Person",
              };
              App.newNotification(notification);
            }

          }

          setPersonData((prevState) => {
            return {
              ...prevState,
              data: dataPersonGet,
              loginAttempts: failedLoginAttempts,
              accessGroups: dataAccessGroups,
              wallboards: wallboards.items,
              sites: sites.items,
              extensions: extensions.items,
            };
          });
        } else if (isMounted) {
          setPersonData((prevState) => {
            return {
              ...prevState,
              accessGroups: dataAccessGroups,
              wallboards: wallboards.items,
              sites: sites.items,
              extensions: extensions.items,
            };
          });
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };

    getPerson();

    return () => {
      isMounted = false;
    };
  }, [props.match.params, props.match.params.id, props.resellerID]);

  const handleSubmit = async (values: IPerson) => {
    try {
      setLoading(true);

      if (values.id !== "new") {
        await set.updatePerson(values);
        const notification: INotification = {
          title: "Success",
          data: "Person saved.",
          variant: NOTIFICATION_TYPE.success,
          srcFunction: "submitForm",
          srcData: window.location.pathname,
          srcComponent: "App",
        };
        App.newNotification(notification);
      } else {
        await set.addPerson(values);
        const notification: INotification = {
          title: "Success",
          data: "Person created.",
          variant: NOTIFICATION_TYPE.success,
          srcFunction: "submitForm",
          srcData: window.location.pathname,
          srcComponent: "Person",
        };
        App.newNotification(notification);
      }
      props.history.push("../people");
    } catch (error) {
      console.log("error", error);
      const notification: INotification = {
        title: "Warning",
        data: error.data,
        variant: NOTIFICATION_TYPE.warning,
        srcFunction: "submitForm",
        srcData: window.location.pathname,
        srcComponent: "Person",
      };
      App.newNotification(notification);
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <PageHead
        resellerID={props.resellerID}
        title={
          personData.data.title
            ? personData.data.title + " " + personData.data.name
            : "New Person"
        }
        parents={[{ title: "People", url: "../People" }]}
      ></PageHead>

      {error ? (
        <Error />
      ) : loading ? (
        <Loading />
      ) : (
        <Row>
          <Col md="12">
            <PersonForm
              resellerID={props.resellerID}
              loginAttempts={personData.loginAttempts}
              data={personData.data}
              accessGroups={personData.accessGroups}
              sites={personData.sites}
              wallboards={personData.wallboards}
              submitFunction={handleSubmit}
              extensions={personData.extensions}
            />
            {personData.data.speedDials && (<SpeedDials speedDials={personData.data.speedDials}></SpeedDials>)}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Person;

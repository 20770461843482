import React from "react";

type props = {
  children?: React.ReactNode;
  render?: any;
  mutators?: any;
};

export const WizardCleanPage: React.FC<props> = ({ children, render }) => {
  // console.log(children);

  if (typeof children === "function") {
    return children(render);
  }

  return children;
};

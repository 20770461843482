
import * as React from 'react';
import MySettings from '../static/settings';
import { IVoipServicesRequest, IVoipService } from '../interfaces/interfaces'
import Loading from './loading';

export interface IVoipServicesPickerProps {
  name:string,
  onServiceChosen:(chosenServiceId:string)=>void
}

export interface IVoipServicesPickerState {
  loading:boolean,
  services:IVoipService[]
}

export default class VoipServicesPicker extends React.Component <IVoipServicesPickerProps, IVoipServicesPickerState> {

  constructor(props:any) {
    super(props);
    this.onServiceChange=this.onServiceChange.bind(this)
    this.state = {
      loading:true,
      services:[]
    };
  }

  public componentDidMount = () => {
    this.getServices();
  }

  // get numbers list
  public getServices = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      const set = new MySettings();
      const url = set.getVoipServices();

      set.doAPIRequest<IVoipServicesRequest>(url, {})

        .then((data: IVoipServicesRequest) => {
          const stateUpdate: IVoipServicesPickerState = {
            loading:false,
            services:[]
          };
          if (data.services) {
            stateUpdate.services = data.services;
          }
          this.setState(stateUpdate);
          resolve(true);
          if(stateUpdate.services.length>0){
            this.props.onServiceChosen(stateUpdate.services[0].id)
          }
        })
        .catch((error) => {
          console.log("fetchError", error);
          //  this.setState({loggedIn:false});
          reject(error);
        });
    });
  }

  public onServiceChange(event:React.ChangeEvent<HTMLSelectElement>){
    this.props.onServiceChosen(event.target.value)
  }
 
  public render(){
    if (this.state.loading) {
      return <Loading />
    }
    const services:JSX.Element[] = []
    for(const service of this.state.services) {
      services.push(<option key={service.id} value={service.id}>{service.siteName}</option>);
    };
    return (
      <select className="form-control" name={this.props.name} onChange={this.onServiceChange}>
          {services}
      </select>
    );
  }

}
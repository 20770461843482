import React, { useContext } from "react";

import { AccordionContext, Card, useAccordionToggle } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const AccordionHeader = ({ children, eventKey, callback, onClick }: any) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    onClick && onClick();
    callback && callback(eventKey);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      // style={{ backgroundColor: isCurrentEventKey ? 'pink' : 'lavender' }}
      onClick={decoratedOnClick}
    >
      {isCurrentEventKey ? (
        <FontAwesomeIcon
          size="2x"
          icon={faChevronUp}
          style={{ position: "relative", float: "right" }}
        />
      ) : (
        <FontAwesomeIcon
          size="2x"
          icon={faChevronDown}
          style={{ position: "relative", float: "right" }}
        />
      )}
      {children}
    </Card.Header>
  );
};

export default AccordionHeader;


import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';



const ResellerRedirect = () => {
    return <Row>
        <Col>
            <h2>Please Select a Customer</h2>
            <Link to='/resellers'>click here</Link>
        </Col>
    </Row>
}


export default ResellerRedirect;
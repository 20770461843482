import { Box, Button } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useState } from "react"
import DateFnsUtils from '@date-io/date-fns';
import Loading from "../loading";
import MySettings from "../../static/settings";
import App from "../../App";
import { ICustomer } from "../../interfaces/customer";

interface IVipSidebarArchiveCustomerFormProps {
    customersId:string
    update:(customer:ICustomer) => void;
    setArchiveCustomerModalOpen:(open:boolean)=>void;
}

enum PageState {
    OK,
    LOADING,
    SUCCESS
}

const set=new MySettings()
const VipSidebarArchiveCustomerForm = (props:IVipSidebarArchiveCustomerFormProps) => {
    const [pageState,setPageState] = useState(PageState.OK)
    const [archiveDate,setArchiveDate] = useState(new Date())
    const [archiveOnDate,setArchiveOnDate] = useState('')
    const [errorMessage,setErrorMessage] = useState('')

    const onArchiveClick = async () => {
        //set loading
        setPageState(PageState.LOADING)
        setErrorMessage('')
        //send the request
        try{
            const archiveOnDate=archiveDate.toISOString()
            const customer=await set.resellerCustomerUpdate({id:props.customersId,archiveOnDate:archiveOnDate})
            setArchiveOnDate(customer.archiveOnDate ? customer.archiveOnDate : '')
            //update our parent
            props.update(customer)
            //show a success message
            setPageState(PageState.SUCCESS)
        }catch(err){
            let err2:any=err as any
            setErrorMessage(err2.data)
            App.newNotificationError(err2.data)
            setPageState(PageState.OK)
        }
    }

    const renderLoading = () => {
        return <Loading />
    }
    
    const renderOK = () => {
        return <>
                <Box style={{width:"100%",padding:10}}>
                    {errorMessage.length>0 && (<div style={{textAlign:'center',color:'red'}}>{errorMessage}<br /><br /></div>)}
                    Please choose the month you would like to archive the customer in.<br />
                    On the first of that month the customer will be archived and the following will occur:
                    <ul>
                        <li>All their numbers will be deactivated.</li>
                        <li>All their VoIP services will be cancelled.</li>
                        <li>All phone books will be deleted.</li>
                        <li>All call recordings will be removed.</li>
                        <li>All call logs will be removed.</li>
                    </ul>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker views={['month']} value={archiveDate} onChange={function (date: MaterialUiPickersDate): void {
                        if(date!=null){
                            setArchiveDate(date)
                        }
                    } }></DatePicker>
                    </MuiPickersUtilsProvider>
                    <Button fullWidth color="primary" style={{ marginTop: 10 }} variant="contained" onClick={() => {
                        onArchiveClick();
                    }}>Archive Customer</Button>
                </Box>
            </>
    }

    const renderSuccess = () => {
        return <>
            <Box style={{width:"100%",padding:10}}>
                The customer will be archived on {archiveOnDate}.
                <Button fullWidth color="primary" style={{ marginTop: 10 }} variant="contained" onClick={() => {
                    props.setArchiveCustomerModalOpen(false)
                }}>Done</Button>
            </Box>
        </>
    }

    switch(pageState){
        case PageState.OK:
            return renderOK()
        case PageState.LOADING:
            return renderLoading()
        case PageState.SUCCESS:
            return renderSuccess()
    }

    return <></>
}

export default VipSidebarArchiveCustomerForm
import * as React from "react";
import { IPageProps } from "../interfaces/page-props";
import Form from "react-bootstrap/Form";
import MySettings from "../static/settings";
// import { Button } from 'react-bootstrap';
import VoipServicesPicker from "../components/VoipServicesPicker";
import Loading from "../components/loading";
import { AddAreaCodeRoute } from "../components/AddAreaCodeRouting";
import { IAreaCodeRoutingsRoute } from "../components/AreaCodeRoutingTableRow";
import VoipTable from "../components/VoipTable";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import PageHead from "../components/PageHead";
import { RouteComponentProps } from "react-router-dom";
library.add(faMinus);

export interface IAreaCodeRoutingState {
  loadingRoutings: boolean;
  routings: IAreaCodeRoutingsRoute[];
}
interface IAreaCodeRouting extends RouteComponentProps<any>, IPageProps {
  /* other props for ChildComponent */
  // reloadMenu() :Promise<boolean> ;
  // menuOptions: IMenuOption[];
}
export default class AreaCodeRouting extends React.Component<
  IAreaCodeRouting,
  IAreaCodeRoutingState
> {
  private customersSitesServicesId: string = "";

  constructor(props: any) {
    super(props);
    this.onAdded = this.onAdded.bind(this);
    this.onDeleted = this.onDeleted.bind(this);
    this.onServiceChosen = this.onServiceChosen.bind(this);
    this.clickDeleteRoute = this.clickDeleteRoute.bind(this);
    this.state = {
      loadingRoutings: true,
      routings: [],
    };
  }

  // get numbers list
  public getRoutings = () => {
    this.setState({
      loadingRoutings: true,
    });
    const set = new MySettings();
    set
      .getAreaCodeRoutingsGet(this.customersSitesServicesId)
      .then((records) => {
        this.setState({
          loadingRoutings: false,
          routings: records,
        });
      })
      .catch((error) => {});
  };

  public onServiceChosen(serviceId: string) {
    this.customersSitesServicesId = serviceId;
    this.getRoutings();
  }

  public onAdded() {
    // get the routes again
    this.getRoutings();
  }

  public onDeleted() {
    this.getRoutings();
  }

  public clickDeleteRoute = (e: any, id: string) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this route?")) {
      this.setState({ loadingRoutings: true });
      const settings = new MySettings();
      settings
        .areaCodeRouteDelete(id)
        .then((success: boolean) => {
          this.getRoutings();
        })
        .catch((error) => {
          this.getRoutings();
        });
    }
  };

  public render() {
    // TODO get the download link for existing routings to be based on selected service
    // TODO get a list of existing routings to appear
    let changes = false;
    if (
      window.location.search.substring(1).includes("areaCodeRoutingChanges")
    ) {
      changes = true;
    }

    const col = [
      {
        Header: "Area Code",
        accessor: "prefix",
        sortable: true,
      },
      {
        Header: "Destination",
        accessor: "destination",
        sortable: true,
      },
      {
        Header: "",
        accessor: "id", // String-based value accessors!
        Cell: (props: any) => {
          return (
            <ButtonGroup aria-label="Basic example">
              <Button
                onClick={(event: any) => {
                  this.clickDeleteRoute(event, props.value);
                }}
                variant="danger"
              >
                <FaIcon icon="minus" color="white" />
              </Button>
            </ButtonGroup>
          );

          // <button class="btn"><i class="fa fa-home"></i></button>
        },
      },
    ];
    const voipDivStyle = {
      marginTop: "2em",
    };
    return (
      <>
        {changes && <h2>Your changes have been saved.</h2>}
        <PageHead title="Add A New Route" parents={[]}></PageHead>

        <Form.Group controlId="service">
          <Form.Label>Site:</Form.Label>
          <VoipServicesPicker
            name="serviceId"
            onServiceChosen={this.onServiceChosen}
          />
        </Form.Group>

        <div>
          <AddAreaCodeRoute
            customersSitesServicesId={this.customersSitesServicesId}
            onAdded={this.onAdded}
          />
        </div>
        {this.state.loadingRoutings ? (
          <Loading />
        ) : (
          <div style={voipDivStyle}>
            <VoipTable
              title="Existing Routes"
              data={this.state.routings}
              col={col}
              multiSelect={false}
            />
          </div>
        )}
      </>
    );
    /*<h3>Existing Routes</h3>
          {
            this.state.routings.map((value:IAreaCodeRoutingsRoute) => {
              return (<AreaCodeRoutingTableRow key={value.id} areaCodeRouting={value} onDeleted={this.onDeleted} />)
            })
          }*/
    /*
    const settings = new MySettings();
    <ol>
      <li><a href={settings.getAreaCodeRouting()} target="_blank">Download the existing routings</a></li>
      <li>Edit the CSV file with your changes</li>
      <li>Upload the CSV using the button below</li>
    </ol>
    <Form action={settings.getAreaCodeRouting()} method="POST" encType="multipart/form-data">
      <Form.Group controlId="service">
        <Form.Label>Site:</Form.Label><VoipServicesPicker name="serviceId" onServiceChosen={this.onServiceChosen} />
      </Form.Group>
        <Form.Control type="file" name="file" />
        <Button variant="primary" type="submit">
          Save
        </Button>
    </Form>*/
  }
}

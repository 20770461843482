import React, { useEffect, useState, useRef, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Field, Form } from "react-final-form";

import { NOTIFICATION_TYPE } from "../enum/notification-type";
import { INotification } from "../interfaces/notification";
import { IPageProps } from "../interfaces/page-props";

import App from "../App";
import MySettings, { IMFAAttemptResponse } from "../static/settings";
import mfaContext from "../context/mfa";
import Loading from "../components/loading";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import BrandedLogo from "../components/BrandedLogo";

interface IMFAPageProps extends RouteComponentProps<any>, IPageProps {
  /* other props for ChildComponent */
  reloadMenu(): Promise<boolean>;
}

const set = new MySettings();

const MFAAttemptPage: React.FC<IMFAPageProps> = ({
  reloadMenu,
  session,
  history,
}) => {
  const [loading, setLoading] = useState(false);
  const timer: any = useRef();

  if (session.loggedIn) {
    history.push(`/`);
  }

  const loggedIn = useCallback(
    async (response: IMFAAttemptResponse) => {
      mfaContext.loggedIn = true;
      if (response.redirectTo != null && response.redirectTo.length > 0) {
        window.location.href = response.redirectTo;
      } else {
        App.newNotification({
          title: "Success",
          data: "You have logged in.",
          variant: NOTIFICATION_TYPE.success,
          srcFunction: "",
          srcData: "",
          srcComponent: "",
        });

        try {
          const success = await reloadMenu();
          if (success) {
            history.push(`/`);
          } else {
            throw new Error("Could not reload the menu.");
          }
        } catch (err) {
          App.newNotificationError(
            "There was a problem loading the portal. Please try refreshing the page."
          );
        }
      }
    },
    [history, reloadMenu]
  );

  useEffect(() => {
    const checkMFAApprove = async () => {
      const loginRequest = {
        code: "",
        mfaAttemptToken: mfaContext.mfaAttemptToken,
        queryString: window.location.search,
      };

      try {
        const response: IMFAAttemptResponse = await set.mfaAttempt(
          loginRequest
        );

        if (response.success) {
          loggedIn(response);
        } else {
          timer.current = setTimeout(() => checkMFAApprove(), 1000);
        }
      } catch (error) {
        console.log(error);
      }
    };

    timer.current = setTimeout(() => checkMFAApprove(), 1000);

    return () => {
      clearTimeout(timer.current);
    };
  }, [loggedIn]);

  const postLogin = async (mfaCode: string) => {
    const loginRequest = {
      code: mfaCode,
      mfaAttemptToken: mfaContext.mfaAttemptToken,
      queryString: window.location.search,
    };

    setLoading(true);

    try {
      const response: IMFAAttemptResponse = await set.mfaAttempt(loginRequest);

      if (response.success) {
        loggedIn(response);
      } else {
        const alert: INotification = {
          title: "Failed",
          data: "Login Failed!",
          variant: NOTIFICATION_TYPE.warning,
          srcComponent: "Login",
          srcData: "success",
          srcFunction: "postLogin",
        };

        App.newNotification(alert);
      }
    } catch (error) {
      App.newNotificationError(
        "There was a problem logging in. Please try again."
      );
    }

    setLoading(false);
  };

  const qrURL =
    "https://mfa1.hatsoffsoftware.net/generateqrv1.php?app=VIP VoIP&label=" +
    mfaContext.label +
    "&secret=" +
    mfaContext.secret;

  return (
    <Form
      onSubmit={({ mfaCode }) => {
        postLogin(mfaCode);
      }}
      validate={(values) => {
        const errors: any = {};

        const requiredFields = ["mfaCode"];

        requiredFields.forEach((requiredField) => {
          if (!values[requiredField]) {
            errors[requiredField] = "Required.";
          }
        });

        return errors;
      }}
      render={({ handleSubmit, submitting, dirtySinceLastSubmit }) => (
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "368px", margin: "auto" }}
          noValidate
        >
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} style={{ marginBottom: "32px" }}>
              <Box display="flex" justifyContent="center">
                <BrandedLogo />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                {mfaContext.secret.length > 0 && (
                  <img alt="qrcode" src={qrURL} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: "16px" }}>
              <Typography variant="h6" align="center">
                Please enter your 2FA code.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Enter Code</Typography>
              <Field name="mfaCode">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="mfaCode"
                    fullWidth
                    required
                    type="email"
                    variant="outlined"
                    size="small"
                    error={
                      (meta.error && meta.touched) ||
                      (!!meta.submitError &&
                        !dirtySinceLastSubmit &&
                        !submitting)
                    }
                    helperText={
                      meta.error && meta.touched
                        ? meta.error
                        : !!meta.submitError &&
                          !dirtySinceLastSubmit &&
                          !submitting
                        ? meta.submitError
                        : ""
                    }
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              {loading ? (
                <Loading size="80px" />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  size="large"
                  style={{ height: "56px" }}
                >
                  Submit
                </Button>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                If you have lost your 2FA device please contact your reseller to
                reset your login.
              </Typography>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default MFAAttemptPage;

import React, { useEffect, useState } from "react";
import App from "../App";
import { NOTIFICATION_TYPE } from "../enum/notification-type";
import { INotification } from "../interfaces/notification";
import MySettings from "../static/settings";
import Loading from "./loading";


const set = new MySettings();
const GetSiteOptions = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const [siteOptions, setSiteOptions] = useState<any[]>([]);
  const [customerID, setCustomerID] = useState<string>("");

  useEffect(() => {
    let associatedOnly = false;
    if (props.associatedOnly !== undefined) {
      if (
        props.userType !== undefined &&
        props.userType === "customersContacts"
      ) {
        associatedOnly = props.associatedOnly;
      }
    }
    let singleCustomerMode = false;

    if (props.singleCustomerMode !== undefined) {
      singleCustomerMode = props.singleCustomerMode;
    }

    if (singleCustomerMode) {
      if (
        props.userType !== undefined &&
        props.userType !== "customersContacts"
      ) {
        singleCustomerMode = false;
      }
    }

    const getSiteOptions = async () => {

      if (
        (!isLoading &&
          props.customerID !== "" &&
          // eslint-disable-next-line
          props.customerID !== undefined &&
          props.customerID !== customerID) ||
        // eslint-disable-next-line
        ((singleCustomerMode || associatedOnly) && customerID !== customerID)
      ) {
        try {
          console.log("go", props.customerID);
          setLoading(true);
          setCustomerID(props.customerID);

          let actID = props.customerID;
          if (actID === "###") {
            actID = "";
          }
          let servicesOnly = false
          if (props.servicesOnly === true) {
            servicesOnly = true
          }
          const result = await set.sitesGet(actID, servicesOnly, associatedOnly);
          result.items.sort((a,b)=>{return a.description.localeCompare(b.description)})
          setSiteOptions(result.items);

          // console.log("hook fired", result);
          if (props.form && result.items.length === 1) {
            // i can update form

            props.form.change(props.input.name, result.items[0].id);
          }
          setLoading(false);
          if (result.items.length === 0 && props.onEmpty != null) {
            props.onEmpty()
          }
        } catch (err) {
          console.log(err);
          const alert: INotification = {
            title: "Error",
            data: "Error loading sites.",
            variant: NOTIFICATION_TYPE.warning,
            srcComponent: "GetSiteOptions",
            srcData: "warning",
            srcFunction: "getSiteOptions",
          };
          App.newNotification(alert);
          setLoading(false);
        }
      }
    };

    getSiteOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerID,
    isLoading,
    props.associatedOnly,
    props.customerID,
    props.form,
    props.input.name,
    props.singleCustomerMode,
    props.userType,
  ]);

  return isLoading ? (
    <Loading size="100px" />
  ) : (
    <>
      {siteOptions.length > 0 ? (
        <div>
          <select
            {...props.input}
            id={props.input.name.replace(".", "_")}
            className="form-control"
            onChange={(evt: any) => {
              props.input.onChange(evt);
              props.inputOnChange && props.inputOnChange(evt);
              if (props.onChange) {
                const val = evt.currentTarget.value;
                props.onChange(val);
              }
            }}
          >
            {props.addBlank && <option></option>}
            {siteOptions.map((ss: any) => (
              <option key={ss.id} value={ss.id}>
                {ss.description}
              </option>
            ))}
          </select>
          {props.meta.error && props.meta.touched && (
            <span>{props.meta.error}</span>
          )}
        </div>
      ) : !props.meta.touched ? (
        <><p>This customer is not currently associated with any sites.</p></>
      ) : (
        <p>This customer is not currently associated with any sites.</p>
      )}
    </>
  );
};

export default GetSiteOptions;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "react-table/react-table.css";
import MySettings from "../../static/settings";

import { Button } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import Loading from "../../components/loading";
import VoipTable from "../../components/VoipTable";
import { IPageProps } from "../../interfaces/page-props";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import App from "../../App";
import Error from "../../components/error";
import PageHead from "../../components/PageHead";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import { INumber } from "../../interfaces/number";
import { IPerson } from "../../interfaces/people";
import { IVirtualReceptionistMessage } from "../../interfaces/virtualreceptionist";
import FormNumberAnswer from "../Number/NumberAnswerAsForm";

library.add(faWindowClose, faEdit);

export interface IChatState {
  // newPerson: IPerson;
  loading: boolean;
  data: IVirtualReceptionistMessage[];
  error: boolean;
}

const set = new MySettings();
interface IChatPage extends RouteComponentProps<any>, IPageProps { }
interface ParamTypes {
  numberId: string;
}
const Chat: React.FC<IChatPage> = (props) => {
  let { numberId } = useParams<ParamTypes>();
  const [data, setData] = useState<IVirtualReceptionistMessage[]>([]);
  const [contacts, setContacts] = useState<IPerson[]>([]);
  const [number, setNumber] = useState<INumber>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  // console.log("STATE", resellerPeople);

  const saveNumber = async (number: INumber) => {
    try {
      setLoading(true)
      const nn = await set.numberNotesUpdate(number);
      console.log("saveNumber", number, nn)
      setNumber(nn);
      const notification: INotification = {
        title: "Success",
        data: "Answer Note Saved.",
        variant: NOTIFICATION_TYPE.info,
        srcFunction: "componentDidUpdate",
        srcData: window.location.pathname,
        srcComponent: "App",
      };
      App.newNotification(notification, true);
      setLoading(false)
    } catch (exception) {
      const notification: INotification = {
        title: "Error",
        data: "Api Error",
        variant: NOTIFICATION_TYPE.danger,
        srcFunction: "componentDidUpdate",
        srcData: window.location.pathname,
        srcComponent: "App",
      };
      App.newNotification(notification, true);
    }
  }

  const getChat = async () => {
    try {
      const message = await set.virtualReceptionistMessagesGet(numberId);
      const contact = await set.virtualReceptionistContactsGet(numberId);
      const numberA = await set.numberGet(numberId);
      setNumber(numberA.item)
      setData(message);
      setContacts(contact);
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    getChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const col = [
    {
      Header: "Message",
      sortable: true,
      accessor: "message",
      Cell: (props: any) => {
        const link: string = props.resellerID
          ? `/reseller/${props.resellerID}/virtualReceptionistMessage/${props.obj.id}`
          : "/virtualreceptionistmessage/" + props.obj.id;

        return (
          <Link style={{ cursor: "pointer", float: "left" }} to={link}>
            {props.value}
          </Link>
        );
      },
    },
    {
      Header: "Caller Name",
      accessor: "callersName",
      sortable: true,
      Cell: (props: any) => {
        const link: string = props.resellerID
          ? `/reseller/${props.resellerID}/virtualReceptionistMessage/${props.obj.id}`
          : "/virtualreceptionistmessage/" + props.obj.id;

        return (
          <Link style={{ cursor: "pointer", float: "left" }} to={link}>
            {props.value}
          </Link>
        );
      },
    },
  ];
  const contactCol = [
    {
      Header: "Name",
      sortable: true,
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "emailAddress",
      sortable: true,
    },
    {
      Header: "Phone",
      accessor: "telephone",
      sortable: true,
    },
    {
      Header: "EXT",
      accessor: "associatedExtension",
      sortable: true,
    },
  ];

  return (
    <>
      <div className="content">
        <PageHead
          resellerID={props.resellerID}
          title="Messages"
          parents={[
            {
              title: "VIP Receptionist",
              url: "../virtualreceptionist/homepage",
            },
          ]}
        ></PageHead>

        {loading ? (
          <Loading />
        ) : error ? (
          <Error />
        ) : (
          <>
            <VoipTable
              title="Messages"
              data={data}
              col={col}
              sortByStart="message"
              multiSelect={false}
            />

            <div style={{ height: "50px" }}></div>
            {props.resellerID ? (
              <Link to={`/reseller/${props.resellerID}/person/new`}>
                <Button style={{ marginBottom: "20px" }} variant="success">
                  New Person
                </Button>
              </Link>
            ) : (
              <Link to="/person/new/">
                <Button style={{ marginBottom: "20px" }} variant="success">
                  New Person
                </Button>
              </Link>
            )}

            {number && <div style={{ paddingBottom: "30px" }}>
              <FormNumberAnswer
                data={number}
                submitFunction={(d) => {
                  saveNumber(d);
                }}
              ></FormNumberAnswer>
            </div>}

            <VoipTable
              title="Contacts"
              data={contacts}
              col={contactCol}
              sortByStart="message"
              multiSelect={false}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Chat;

import React, { useEffect, useState } from "react";

import { Col } from "react-bootstrap";

import PageHead from "../../components/PageHead";
import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import MySettings from "../../static/settings";

import {
  IInvoiceGetResponse,
  IResellerInvoice,
} from "../../interfaces/reseller-invoice";
import Error from "../../components/error";
import ResellerInvoiceBasic from "./ResellerInvoiceBasic";
import ResellerInvoiceSummary from "./ResellerInvoiceSummary";
import { RouteComponentProps } from "react-router-dom";
import VipBurgerMenu, { IBurgerOption } from "../../components/VipBurgerMenu";
import ResellerInvoiceMinutesReport from "./ResellerInvoiceMinutesReport";

const set = new MySettings();

export interface IResellerInvoicePageState {
  loading: boolean;
  error: boolean;
  displayEditForm: boolean;
  data: IResellerInvoice;
  displayBasic: boolean;
  displaySummary: boolean;
}

interface IResellerInvoicePage extends RouteComponentProps<any>, IPageProps {}

const ResellerInvoicePage: React.FC<IResellerInvoicePage> = (props) => {
  const [invoice, setInvoice] = useState<IResellerInvoice>();

  const [displayMode, setDisplayMode] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const getInvoice = async () => {
      try {
        const invoiceGetResponse: IInvoiceGetResponse = await set.invoiceGet(
          props.match.params.id
        );

        if (isMounted) {
          setInvoice(invoiceGetResponse.invoice);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };

    getInvoice();

    return () => {
      isMounted = false;
    };
  }, [props.match.params.id]);

  let title = "Invoices";
  let dlLink = "";

  if (invoice !== undefined) {
    title = invoice.displayNumber;
    dlLink = "/voip/invoiceServlet?invoicesToReview=false&id=" + invoice.id;
  }

  let burgerOpts: IBurgerOption[] = [];
  burgerOpts.push({
    title: "PDF Download",
    link: dlLink,
    isExternal: true,
  });

  burgerOpts.push({
    title: displayMode===1 ? "Hide Summary" : "Customer Summary",
    onClick: () => {
      if(displayMode===1){
        setDisplayMode(0)
      }else{
        setDisplayMode(1);
      }
    },
    isExternal: false,
  });

  if(invoice!=null && invoice.minutesReport!=null){
    burgerOpts.push({
      title: displayMode===2 ? "Hide Call Minutes Report" : "Call Minutes Report",
      onClick:()=>{
        if(displayMode===2){
          setDisplayMode(0)
        }else{
          setDisplayMode(2)
        }
      },
      isExternal:false
    })
  }

  if(invoice!==undefined){
    let summaryDownloadLink="/voip/invoiceServlet?summary=true&invoicesToReview=false&id=" + invoice.id;
    burgerOpts.push({
      title:'Download Customer Summary',
      link:summaryDownloadLink,
      isExternal:true
    })
  }

  const getPage = (displayMode:number,invoice:IResellerInvoice) => {
    switch(displayMode){
      case 2:
        return <ResellerInvoiceMinutesReport items={invoice.minutesReport ? invoice.minutesReport : []} />
      case 1:
        return <ResellerInvoiceSummary invoice={invoice} />
      default:
        return <ResellerInvoiceBasic invoice={invoice} />
    }
  }

  return (
    <div className="content">
      <Col md="12" className="text-left">
        <PageHead
          resellerID={props.resellerID}
          isHome={false}
          customersMode={true}
          title={title}
          parents={[{ title: "Invoices", url: "../invoices" }]}
        >
          <VipBurgerMenu options={burgerOpts} />
        </PageHead>

        {loading && invoice === undefined ? (
          <Loading />
        ) : error ? (
          <Error />
        ) : (
          <>
            {!invoice ? (
              <></>
            ) : (
              getPage(displayMode,invoice)
            )}
          </>
        )}
      </Col>
    </div>
  );
};
export default ResellerInvoicePage;

import * as React from "react";
import Form from "react-bootstrap/Form";

import { INumberTracker } from "../../interfaces/number";
import Button from "react-bootstrap/Button";

import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";

import { IVoipSession } from "../../interfaces/interfaces";
library.add(faCheckSquare);

const updateState =
  <T extends string>(key: keyof INumberTracker, value: T) =>
  (prevState: INumberTracker): INumberTracker => ({
    ...prevState,
    [key]: value,
  });

export interface IFormNumberTrackerProp {
  data: INumberTracker;
  customers: string[];
  numbers?: string[];
  session: IVoipSession;
  resellerID?: string;
  submitFunction: (data: INumberTracker) => void;
}

export default class FormNumberTracker extends React.Component<
  IFormNumberTrackerProp,
  INumberTracker
> {
  constructor(props: any) {
    super(props);

    this.state = props.data;
  }

  public handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    this.props.submitFunction(this.state);
  };

  public handleChange = (event: any) => {
    this.setState(updateState(event.target.id.toString(), event.target.value));
  };

  public render() {
    const { uid, yhc, notes, area, owner } = this.state;
    const { numbers, customers } = this.props;

    // console.log("PROPS OF FORM", this.props);
    // console.log("STATE OF FORM", this.state);

    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          {numbers !== undefined ? (
            <Form.Group controlId="numbersId">
              <Form.Label>Number</Form.Label>
              <Typeahead
                id="numbersName"
                defaultInputValue={this.props.data.numbersName}
                placeholder="Choose a number..."
                onChange={(selected: any[]) => {
                  this.setState({ numbersName: selected[0] });
                }}
                options={numbers}
              />
            </Form.Group>
          ) : (
            <></>
          )}

          <Form.Group controlId="uid">
            <Form.Label>UID</Form.Label>
            <Form.Control
              type="text"
              placeholder="UID"
              value={uid}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group controlId="yhc">
            <Form.Label>YHC</Form.Label>
            <Form.Control
              type="text"
              placeholder="YHC"
              value={yhc}
              onChange={this.handleChange}
            />
          </Form.Group>
          {this.props.resellerID === undefined &&
            this.props.session.userType !== "customersContacts" && (
              <Form.Group controlId="owner">
                <Form.Label>Tracking Customer</Form.Label>
                <Typeahead
                  id="owner"
                  placeholder="Choose a Customer..."
                  defaultInputValue={owner}
                  onChange={(selected: any[]) => {
                    console.log("SELECTED", selected);
                    const selectedCustomer = selected[0];
                    if (selectedCustomer !== undefined) {
                      let customer;
                      if (typeof selectedCustomer == "string") {
                        customer = selectedCustomer;
                      } else {
                        customer = selectedCustomer.label;
                      }
                      this.setState({ owner: customer });
                    }
                  }}
                  options={customers}
                />
              </Form.Group>
            )}

          <Form.Group controlId="area">
            <Form.Label>Area</Form.Label>
            <Form.Control
              type="text"
              placeholder="Area"
              value={area}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group controlId="notes">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              type="text"
              placeholder="Notes"
              value={notes}
              onChange={this.handleChange}
            />
          </Form.Group>

          <div className="d-flex justify-content-between">
            <Button
              variant="outline-primary"
              type="submit"
              className="noleft mr-auto"
            >
              Save Tracker
            </Button>
            {this.props.children}
          </div>
        </Form>
      </>
    );
  }
}

import React, { useState, useEffect, useRef } from "react";
import MySettings from "../static/settings";
import Loading from "./loading";
import { ISitesGetHttpResponse, ISite } from "../interfaces/site";

const set = new MySettings();

export interface ISitesPickerProps {
  customersId: string
  onSiteChosen: (chosenSitesId: string) => void
  servicesOnly: boolean
  associatedOnly: boolean
}

const SitesPicker: React.FC<ISitesPickerProps> = (props: ISitesPickerProps) => {
  const [sites, setSites] = useState<ISite[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useRef(true);
  const isCurrent = useRef(true);

  useEffect(() => {
    const getSites = async () => {
      try {
        const result: ISitesGetHttpResponse = await set.sitesGet(props.customersId,props.servicesOnly,props.associatedOnly);
        if (isMounted.current) {
          setIsLoading(false);
          setSites(result.items);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSites();
    return () => {
      isMounted.current = false;
    };
  }, [props.customersId,props.servicesOnly,props.associatedOnly]);

  if (sites.length > 0 && isCurrent.current) {
    props.onSiteChosen(sites[0].id);
    isCurrent.current = false;
  }

  const onSiteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.onSiteChosen(event.target.value);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <select
        className="form-control"
        placeholder="name"
        onChange={onSiteChange}
      >
        {sites.map((site) => (
          <option key={site.id} value={site.id}>
            {site.description}
          </option>
        ))}
      </select>
    </>
  );
};

export default SitesPicker;
